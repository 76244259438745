import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "debounce";
import { useSelector, useDispatch } from "react-redux";
import {
    Paper,
    Box,
    Grid,
    Typography,
    Divider,
    TextField,
    Button,
    Chip,
    Stack,
    Autocomplete,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { navigate, useMatch } from "@gatsbyjs/reach-router";
import { ChromePicker } from "react-color";
import Filter from "./components/Filter";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import {
    adminSliceSelectors,
    setActiveStack,
    clearActiveStack,
    setStacksFilter,
} from "../../../slices/Admin/adminSlice";
import { getCompanyApi, getCompaniesApi, getTagsApi, createTagApi } from "../../../services/api/utils";
import { createStackApi, editStackApi } from "../../../services/api/Admin/stacks";
import { getStacks } from "../../../actions/Admin/Stacks";
import OrganizationAutocomplete from "../../AutocompleteInputs/OrganizationAutocomplete";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
    },
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
    chipSpacing: {
        margin: theme.spacing(0.5),
        marginTop: theme.spacing(1),
    },
    paperSize: {
        width: "50%",
    },
    popover: {
        position: "absolute",
        zIndex: "2",
    },
    cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Stack Create",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Stacks",
            color: "textSecondary",
            link: "/admin/stacks",
            icon: null,
        },
        {
            text: "Create",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

const MODE_CREATE = "create";
const MODE_EDIT = "edit";

const TAG_AUTOMOTIVE = "automotive";
const TAG_CONSULTING = "consulting";

const COLOR_GREY = "#808080";
const COLOR_BLUE = "#0000ff";
const COLOR_YELLOW = "#ffff00";

export default function SaveStack() {
    const { mode } = useMatch("/admin/stacks/:mode");
    const filter = useSelector(adminSliceSelectors.stacksFilter);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const { id, name, color, organization, company, tags, jira_ticket } = useSelector(
        adminSliceSelectors.stacksActiveStack,
    );
    const [stackCompanies, setStackCompanies] = useState([]);
    const [stackColor, setStackColor] = useState(color || COLOR_GREY);
    const [stackTags, setStackTags] = useState([]);
    const [isCompanyGroup, setIsCompanyGroup] = useState(false);
    const [companyFetched, setCompanyFetched] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(async () => {
        if (company.id) {
            const response = await getCompanyApi(company.id);
            const company_data = response.data;
            dispatch(setActiveStack({ company: { ...company, name: company_data.name } }));
            setCompanyFetched(true);
        }
    }, []);

    useEffect(() => {
        if (stackColor === COLOR_GREY || stackColor === COLOR_BLUE || stackColor === COLOR_YELLOW) {
            if (tags.find((item) => item.name === TAG_AUTOMOTIVE)) {
                setStackColor(COLOR_BLUE);
            } else if (tags.find((item) => item.name === TAG_CONSULTING)) {
                setStackColor(COLOR_YELLOW);
            } else {
                setStackColor(COLOR_GREY);
            }
        }
    }, [tags]);

    useEffect(
        () => () => {
            const organizationCopy = { ...organization };
            dispatch(clearActiveStack());
            if (organizationCopy.id) {
                dispatch(setActiveStack({ organization: organizationCopy }));
                dispatch(getStacks({ organization_id: organizationCopy.id }));
            }
        },
        [],
    );

    useEffect(() => {
        breadcrumbComponents.breadcrumbs[breadcrumbComponents.breadcrumbs.length - 1] = {
            ...breadcrumbComponents.breadcrumbs[breadcrumbComponents.breadcrumbs.length - 1],
            text: mode === MODE_CREATE ? "Create" : "Edit",
        };
    }, [mode]);

    const handleColorClick = () => {
        setDisplayColorPicker(true);
    };
    const handleColorClose = () => {
        setDisplayColorPicker(false);
    };

    const handleColorChange = (value) => {
        setStackColor(value.hex);
    };

    const handleNameOnChange = (e) => {
        const value = e.target.value;
        dispatch(setActiveStack({ name: value }));
    };

    const handleOrganizationOnSelected = (value) => {
        if (!value) return;
        dispatch(setActiveStack({ organization: value }));
    };

    const getCompanies = useMemo(() =>
        debounce(async (companyName, is_grouped) => {
            const { data } = await getCompaniesApi({ name: companyName, is_grouped });
            setStackCompanies(data.records);
        }, 300),
    );

    const handleCompanyInputChanged = async (e) => {
        if (!e) return;

        const value = e.target.value;
        if (!value) {
            setStackCompanies([]);
            return;
        }

        getCompanies(value, isCompanyGroup);
    };

    const handleCompanyOnChange = (e, companyName) => {
        if (!companyName) return;

        const selectedCompany = stackCompanies.filter((comp) => comp.name === companyName)[0];
        dispatch(setActiveStack({ company: selectedCompany }));
        setCompanyFetched(true);
    };

    const getTags = useMemo(
        () =>
            debounce(async (tagName) => {
                const { data } = await getTagsApi({ q: { name: tagName } });
                setStackTags(data.records);
            }, 300),
        [setStackTags],
    );

    const handleTagsInputChanged = async (e) => {
        if (!e) return;

        const tagName = e.target.value;
        if (!tagName) {
            setStackTags([]);
            return;
        }

        getTags(tagName);
    };

    const handleTagsOnChange = async (e, tagName) => {
        const filtered = stackTags.filter((tag) => tag.name === tagName);
        if (filtered.length === 0 && tagName) {
            const { data } = await createTagApi(tagName, organization.id);
            dispatch(setActiveStack({ tags: [data, ...tags] }));
        } else if (filtered.length > 0) {
            dispatch(setActiveStack({ tags: [filtered[0], ...tags] }));
        }
    };

    const removeTag = (index) => {
        const newTags = [...tags];
        newTags.splice(index, 1);
        dispatch(setActiveStack({ tags: newTags }));
    };

    const cancel = () => {
        navigate(-1);
    };

    const save = async (e) => {
        e.preventDefault();

        const payload = {
            name,
            color: stackColor,
            organization_id: organization.id,
            company_id: company.id,
            tags: tags.map((item) => item.id),
            jira_ticket: jira_ticket || undefined, // from data-requests only
        };
        try {
            if (mode === MODE_CREATE) {
                payload.is_grouped = isCompanyGroup;
                await createStackApi(organization.id, payload);
            } else if (mode === MODE_EDIT) {
                await editStackApi(organization.id, id, payload);
            } else {
                // eslint-disable-next-line
                console.error("Invalid mode");
                return;
            }
            dispatch(
                addMessage({
                    type: "success",
                    title: `Stack ${mode === MODE_CREATE ? "Created" : "Edited"} successfully!`,
                }),
            );
            setTimeout(() => navigate(-1), 2000);
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong :(",
                    message: error.response.data?.error?.message || error.response.data.message,
                    visibilityTime: 5000,
                }),
            );
        }
        dispatch(setStacksFilter({ ...filter, page: 1 }));
    };

    const disableEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
        }
    };

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Box sx={{ display: "flex", flexDirection: "column" }} p={3} pt={2}>
                    <Box sx={{ display: "flex", flexDirection: "column" }} mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Box>
                    <Paper elevation={1} className={classes.paperSize}>
                        <form onSubmit={save} onKeyDown={disableEnter}>
                            <Stack m={3} mt={2}>
                                <Typography variant="h6" color="primary" mb={1}>
                                    Stack configuration
                                </Typography>
                                <TextField
                                    label="Stack name"
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    color="primary"
                                    required
                                    defaultValue={name}
                                    fullWidth
                                    onChange={handleNameOnChange}
                                />
                                <OrganizationAutocomplete
                                    defaultValue={organization.full_name}
                                    label="Select organization"
                                    onSelected={handleOrganizationOnSelected}
                                    color="secondary"
                                    disabled={mode === MODE_EDIT}
                                    required
                                />
                                {company.id && !companyFetched ? undefined : (
                                    <Autocomplete
                                        disablePortal
                                        value={company.name}
                                        defaultValue={company.name === "" ? undefined : company.name}
                                        options={stackCompanies.map((item) => item.name)}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        renderInput={(params) => (
                                            <TextField required {...params} label="Select company" margin="dense" />
                                        )}
                                        onInputChange={handleCompanyInputChanged}
                                        onChange={handleCompanyOnChange}
                                        disabled={mode === MODE_EDIT}
                                    />
                                )}
                                {mode === MODE_CREATE && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="secondary"
                                                onClick={() => {
                                                    dispatch(setActiveStack({ company: { name: "", id: null } }));
                                                    setStackCompanies([]);
                                                    setIsCompanyGroup(!isCompanyGroup);
                                                }}
                                            />
                                        }
                                        label="Is company group"
                                    />
                                )}
                                <Typography variant="h6" color="primary" mt={3} mb={2}>
                                    Stack Tags & Color
                                </Typography>
                                <Stack direction="row">
                                    <Box sx={{ width: 30, height: 30, backgroundColor: stackColor }} mr={1} />
                                    <Stack direction="row" mb={2}>
                                        <Button
                                            onClick={handleColorClick}
                                            color="primary"
                                            variant="contained"
                                            size="smaill"
                                            disableElevation
                                        >
                                            Pick a color
                                        </Button>
                                        {displayColorPicker ? (
                                            <Box className={classes.popover}>
                                                <Box className={classes.cover} onClick={handleColorClose} />
                                                <ChromePicker
                                                    color={stackColor}
                                                    onChange={handleColorChange}
                                                    onChangeComplete={handleColorChange}
                                                />
                                            </Box>
                                        ) : null}
                                    </Stack>
                                </Stack>
                                <Autocomplete
                                    disablePortal
                                    freeSolo
                                    selectOnFocus
                                    options={stackTags.map((item) => item.name)}
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    renderInput={(params) => <TextField {...params} label="Tags" />}
                                    onInputChange={handleTagsInputChanged}
                                    onChange={handleTagsOnChange}
                                />
                                <Stack direction="row" flexWrap="wrap">
                                    {tags &&
                                        tags.map((tag, index) => (
                                            <Chip
                                                key={tag.name}
                                                label={tag.name}
                                                variant="outlined"
                                                className={classes.chipSpacing}
                                                color="primary"
                                                onDelete={() => removeTag(index)}
                                            />
                                        ))}
                                </Stack>
                            </Stack>
                            <Stack justifyContent="flex-end" direction="row" spacing={2} mx={3} mb={2}>
                                <Button
                                    className={classes.buttonPosition}
                                    color="primary"
                                    variant="contained"
                                    size="smaill"
                                    disableElevation
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.buttonPosition}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    size="smaill"
                                    disableElevation
                                >
                                    Save
                                </Button>
                            </Stack>
                        </form>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
}
