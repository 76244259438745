import React, { useState, useEffect, useMemo } from "react";
import Chart from "react-apexcharts";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import RadialBarChart from "../Charts/RadialBarChart";
import { SpeedDialMenu } from "../Charts/ChartContainer/SpeedDialMenu";
import {
    stackInsightsSliceSelector,
    ShowModes,
} from "../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

const generateChartOptions = ({ categories, series, barColors, barHeight, padding, stackname, reversed, max }) => {
    const show = useSelector(stackInsightsSliceSelector.show);
    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        fill: {
            opacity: 1,
        },
        colors: barColors,
        plotOptions: {
            bar: {
                barHeight,
                horizontal: true,
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
                borderRadius: [2],
            },
        },
        xaxis: {
            tickAmount: categories.length ? 5 : -1,
            categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            },
            max: max && max * 1.15,
        },
        dataLabels: {
            enabled: true,
            textAnchor: reversed ? "end" : "start",
            enabledOnSeries: [series.length - 1],
            style: {
                colors: ["#7B7B7B"],
            },
            formatter: (value, { dataPointIndex: index }) => {
                const sumPercent = series.reduce((prev, cur) => prev + cur.data[index], 0);
                // \u2000 = one of the Unicode spaces(apexcharts library trims regular spaces).
                const spacing = "\u2000";
                return show === ShowModes.durationYears
                    ? reversed
                        ? `${sumPercent.toFixed(2)}${spacing}`
                        : `${spacing}${sumPercent.toFixed(2)}`
                    : reversed
                    ? `${sumPercent.toFixed(2)}%${spacing}`
                    : `${spacing}${sumPercent.toFixed(2)}%`;
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: -20,
                left: reversed ? 20 : 0,
                right: reversed ? 0 : 20,
                ...padding,
            },
        },
        yaxis: {
            reversed,
            opposite: reversed,
            labels: {
                show: !!categories.length,
                minWidth: 105,
                maxWidth: 105,
                align: reversed ? "left" : "right",
            },
        },
        tooltip: {
            inverseOrder: true,
            shared: true,
            intersect: false,
            x: {
                formatter: () => stackname,
            },
            y: {
                title: {
                    formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
                        if (!series[seriesIndex].name) {
                            return categories[dataPointIndex];
                        }
                        return seriesName;
                    },
                },
                formatter: (val) => `${val}%`,
            },
        },
    };
    return [options];
};

export default function RadialChartWithBars({
    categories,
    countries,
    series,
    radialBarValues,
    radialBarHeight,
    radialBarColor,
    hollowSize = "50%",
    height = 170,
    barHeight = "59%",
    itemsPerPage = 5,
    barColors,
    stackname = "",
    showStackname = true,
    paddingBottomPerItem,
    reversed = false,
    showActions = true,
    stacks,
    max,
}) {
    const [countryPage, setCountryPage] = useState(0);
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [radialBarHovered, setRadialBarHovered] = useState(false);
    const [barsHovered, setBarsHovered] = useState(false);
    const [cityPage, setCityPage] = useState(false);
    const [newCategories, newSeries] = useMemo(() => {
        if (!itemsPerPage) {
            return [categories[countryPage], series];
        }
        const tempCategories = [...categories][countryPage].slice(
            cityPage * itemsPerPage,
            (cityPage + 1) * itemsPerPage,
        );
        const tempSeries = series[countryPage].map((item) => ({
            name: item.name,
            data: [...item.data].slice(cityPage * itemsPerPage, (cityPage + 1) * itemsPerPage),
        }));
        return [tempCategories, tempSeries];
    }, [cityPage, series, categories, countryPage]);

    const dataPointPadding = paddingBottomPerItem ?? 29;
    const missingItems = itemsPerPage - newSeries[0].data.length;
    const padding = { bottom: missingItems * dataPointPadding };

    const [options] = generateChartOptions({
        categories: newCategories,
        series: newSeries,
        barColors,
        barHeight,
        padding,
        stackname,
        reversed,
        max,
    });

    useEffect(() => {
        setCityPage(0);
        setCountryPage(0);
    }, [stacks, split, show]);

    useEffect(() => {
        setCityPage(0);
    }, [countryPage]);

    return (
        <Stack height="100%" width="100%" direction="row" pr={reversed ? 4 : 1} pl={reversed ? 1 : 4}>
            {reversed ? (
                <Stack direction="row" width="100%" alignItems="center">
                    <Stack flex={1} onMouseOver={() => setBarsHovered(true)} onMouseLeave={() => setBarsHovered(false)}>
                        <Stack width="100%">
                            {showStackname && (
                                <Typography variant="h6" color="textSecondary" align="right" mr={12.5}>
                                    {stackname}
                                </Typography>
                            )}
                            <SpeedDialMenu
                                nextPage={() => setCityPage(cityPage + 1)}
                                previousPage={() => setCityPage(cityPage - 1)}
                                page={cityPage}
                                lastPage={Math.ceil(categories[countryPage].length / 5)}
                                open={showActions && barsHovered}
                                actionsOffset={{ y: -40, x: -5 }}
                            />
                            <Chart type="bar" series={newSeries} options={options} width="100%" height={height} />
                        </Stack>
                    </Stack>
                    <Stack
                        flex="initial"
                        width={160}
                        onMouseOver={() => setRadialBarHovered(true)}
                        onMouseLeave={() => setRadialBarHovered(false)}
                    >
                        <SpeedDialMenu
                            nextPage={() => setCountryPage(countryPage + 1)}
                            previousPage={() => setCountryPage(countryPage - 1)}
                            page={countryPage}
                            lastPage={countries.length}
                            open={showActions && radialBarHovered}
                            actionsOffset={{ y: -10, x: -5 }}
                        />
                        <RadialBarChart
                            value={radialBarValues[countryPage]}
                            countries={countries}
                            height={radialBarHeight}
                            customLabelAndColor={{
                                label: countries[countryPage],
                                color: radialBarColor,
                            }}
                            hollowSize={hollowSize}
                        />
                    </Stack>
                </Stack>
            ) : (
                <Stack direction="row" width="100%" alignItems="center">
                    <Stack
                        flex="initial"
                        width={160}
                        onMouseOver={() => setRadialBarHovered(true)}
                        onMouseLeave={() => setRadialBarHovered(false)}
                    >
                        <SpeedDialMenu
                            nextPage={() => setCountryPage(countryPage + 1)}
                            previousPage={() => setCountryPage(countryPage - 1)}
                            page={countryPage}
                            lastPage={countries.length}
                            open={showActions && radialBarHovered}
                            actionsOffset={{ y: -10, x: 118 }}
                        />
                        <RadialBarChart
                            value={radialBarValues[countryPage]}
                            countries={countries}
                            height={radialBarHeight}
                            customLabelAndColor={{
                                label: countries[countryPage],
                                color: radialBarColor,
                            }}
                            hollowSize={hollowSize}
                        />
                    </Stack>
                    <Stack flex={1} onMouseOver={() => setBarsHovered(true)} onMouseLeave={() => setBarsHovered(false)}>
                        <Stack width="100%">
                            {showStackname && (
                                <Typography variant="h6" color="textSecondary" align="left" ml={12.5}>
                                    {stackname}
                                </Typography>
                            )}
                            <SpeedDialMenu
                                nextPage={() => setCityPage(cityPage + 1)}
                                previousPage={() => setCityPage(cityPage - 1)}
                                page={cityPage}
                                lastPage={Math.ceil(categories[countryPage].length / 5)}
                                open={showActions && barsHovered}
                                actionsOffset={{ y: -40, x: "unset", left: -35 }}
                            />
                            <Chart type="bar" series={newSeries} options={options} width="100%" height={height} />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
