import React, { useMemo } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialChartWithBars from "../../../MixedCharts/RadialChartWithBars";
import StackedBarChartWithDifference from "../../../MixedCharts/StackedBarChartWithDifference";
import ChartLegend from "../../../Charts/ChartLegend";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 0,
        height: 292,
    },
    paper: {
        width: "100%",
        height: "100%",
    },
}));

export default function Sections2({ data, stacks }) {
    const classes = useStyles();

    const firstStackCountries = data.countriesAndCitiesDistribution.stack1.map((item) => item.country);
    const firstStackValues = data.countriesAndCitiesDistribution.stack1.map((item) => item.value);
    const firstStackCities = data.countriesAndCitiesDistribution.stack1.map((item) => item.categories);
    const firstStackSeries = data.countriesAndCitiesDistribution.stack1.map((item) => [item.series]);

    const secondStackCountries = data.countriesAndCitiesDistribution.stack2.map((item) => item.country);
    const secondStackValues = data.countriesAndCitiesDistribution.stack2.map((item) => item.value);
    const secondStackCities = data.countriesAndCitiesDistribution.stack2.map((item) => item.categories);
    const secondStackSeries = data.countriesAndCitiesDistribution.stack2.map((item) => [item.series]);

    const maxValue = useMemo(() => {
        let max1 = 0;
        for (let i = 0; i < firstStackSeries.length; i += 1) {
            const rowSum = firstStackSeries[i].reduce((prev, cur) => prev + cur.data[0], 0);
            max1 = Math.max(max1, rowSum);
        }
        let max2 = 0;
        for (let i = 0; i < secondStackSeries.length; i += 1) {
            const rowSum = secondStackSeries[i].reduce((prev, cur) => prev + cur.data[0], 0);
            max2 = Math.max(max2, rowSum);
        }
        return Math.max(max1, max2);
    }, [firstStackSeries, secondStackSeries]);

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height={70} pl={1} pt={1}>
                        <Typography variant="h5" color="textSecondary">
                            Countries and Cities Distribution
                        </Typography>
                        <Stack mt={0.5}>
                            <ChartLegend
                                legend={[
                                    { name: stacks[0].stack.name, color: "#4973EB" },
                                    { name: stacks[1].stack.name, color: "#4AC086" },
                                ]}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" width="100%" height={200}>
                        <Stack flex={1}>
                            {firstStackCountries.length && firstStackCountries[0] !== null && (
                                <RadialChartWithBars
                                    countries={firstStackCountries}
                                    categories={firstStackCities}
                                    series={firstStackSeries}
                                    barColors={["#4973EB"]}
                                    height={170}
                                    radialBarHeight={250}
                                    radialBarValues={firstStackValues}
                                    stackname={stacks[0].stack.name}
                                    radialBarColor="#4973EB"
                                    max={maxValue}
                                />
                            )}
                        </Stack>
                        <Stack flex={1}>
                            {secondStackCountries.length && secondStackCountries[0] !== null && (
                                <RadialChartWithBars
                                    countries={secondStackCountries}
                                    categories={secondStackCities}
                                    series={secondStackSeries}
                                    barColors={["#4AC086"]}
                                    height={170}
                                    radialBarHeight={250}
                                    radialBarValues={secondStackValues}
                                    stackname={stacks[1].stack.name}
                                    radialBarColor="#4AC086"
                                    reversed
                                    max={maxValue}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <Stack height={240} width="100%">
                        <StackedBarChartWithDifference
                            showLegend={false}
                            stacks={stacks}
                            elevation={0}
                            chartInfo={{ title: "Age Groups", shortTitle: "Age Groups" }}
                            itemsPerPage={5}
                            categories={data.ageGroupDistribution.comparisonChart.categories}
                            stackedBarColors={["#D3E3E8", "#AEC7CF", "#7FA7B3", "#587F8B", "#3D636E"]}
                            diffBarColors={["#7645C3"]}
                            differenceSeries={[
                                {
                                    data: data.ageGroupDistribution.diffChart.series.data,
                                },
                            ]}
                            firstStackSeries={[
                                {
                                    data: data.ageGroupDistribution.comparisonChart.stack1.series[0].data,
                                },
                            ]}
                            secondStackSeries={[
                                {
                                    data: data.ageGroupDistribution.comparisonChart.stack2.series[0].data,
                                },
                            ]}
                            isDistributed
                            noXaxis
                            categoriesFlex={1.1}
                            containerStyle={{ pt: 5.75, width: 40, pl: 0.5 }}
                            categoriesStyle={{
                                fontSize: 12,
                                cursor: "pointer",
                                marginBottom: 7,
                            }}
                            showGrid={Boolean(false)}
                            actionsOffset={{ x: 60, y: -55 }}
                            diffHeight="98%"
                            diffBarHeight="67%"
                            padding={[
                                { top: -15, bottom: 30, right: 3 },
                                { top: -15, bottom: 30, left: -10, right: 25 },
                                { bottom: 29, top: -17, left: 20, right: 20 },
                            ]}
                            inPercentage={Boolean(false)}
                            showYears
                            categoriesHeight={165}
                        />
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
