import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Box, Grid, IconButton, Paper, Typography, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { navigate } from "@gatsbyjs/reach-router";
import { makeStyles } from "@mui/styles";
import TuneIcon from "@mui/icons-material/Tune";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "../../Container";
import { Header } from "../../Header";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import { Filter } from "./Filter";
import { stackSliceSelectors, updateFilter, setSelectedStacks } from "../../../slices/Stacks/stackSlice";
import { getAllByFilter } from "../../../actions/theLab/stacks";
import CompanyDataRequestModal from "./CompanyDataRequestModal";
import OsterusLoader from "../../Loader";
import { clearState } from "../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getTagsByFilter } from "../../../actions/tags/tags";
import FilterChips from "../../FilterChips";
import NoResult from "../../NoResult";
import { EMPLOYMENT_SINGLE_INSIGHT, EMPLOYMENT_COMPARISON_INSIGHT } from "./StackInsights/Views/viewConstants";
import Table from "../../Table/Table";
import PaginationComponent from "../../Pagination";
import { Title } from "../../Header/components/Title";
import { getTableColumns, updateTableColumns } from "../../../services/api/utils";
import { COLUMN_TYPES } from "../../Table/columnTypes";
import { getColor, getCategoryBySize } from "./utils";
import { renderStackName } from "../../Table/CellRenderers";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import EditModal from "./EditModal";
import NoRequestsAvailableModal from "./NoRequestsAvailableModal";
import { userSliceSelectors } from "../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
    dropDownIcon: {
        color: theme.palette.text.secondary,
        cursor: "pointer",
    },
    buttonInsights: {
        minWidth: 220,
    },
    stackInsightsButton: {
        width: theme.spacing(25),
        height: theme.spacing(5.5),
    },
    icon: {
        width: 20,
        height: 20,
    },
    tableSpacing: {
        padding: theme.spacing(2),
        paddingTop: 0,
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    loader: {
        display: "flex",
        minHeight: 473,
        justifyContent: "center",
        alignItems: "center",
    },
    limitNumber: {
        position: "absolute",
        cursor: "pointer",
        left: "89.5%",
        top: -5,
        color: ({ colorLimit }) => colorLimit,
        background: ({ backgroundLimit }) => backgroundLimit,
        border: ({ borderLimit }) => borderLimit,
        borderRadius: "50%",
        minWidth: 25,
        minHeight: 25,
        textAlign: "center",
        alignItems: "center",
        padding: "3px 5px 3px 5px",
        zIndex: 1,
    },
}));

const breadcrumbComponents = {
    breadcrumbs: [
        {
            text: "Stacks",
            color: "textSecondary",
            link: null,
            icon: null,
        },
        {
            text: "Stack Insights - Active",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

const SORTABLE_COLUMN_NAMES = {
    name: "name",
    workforceRange: "number_of_employees",
    updatedOn: "updated_at",
};

const defaultColumns = [
    {
        name: "Stack Name",
        align: "left",
        sortBy: SORTABLE_COLUMN_NAMES.name,
    },
    {
        name: "Workforce Range",
        sortBy: SORTABLE_COLUMN_NAMES.workforceRange,
    },
    {
        name: "Tags",
    },
    {
        name: "Industry",
    },
    {
        name: "Updated on",
        sortBy: SORTABLE_COLUMN_NAMES.updatedOn,
    },
];

const nameToProperties = {
    "Stack Name": { name: "Stack Name", align: "left", sortKey: SORTABLE_COLUMN_NAMES.name, renderer: renderStackName },
    "Workforce Range": { name: "Workforce Range", sortKey: SORTABLE_COLUMN_NAMES.workforceRange, draggable: true },
    Tags: { name: "Tags", type: COLUMN_TYPES.TAGS, draggable: true },
    Industry: { name: "Industry", draggable: true },
    "Updated on": { name: "Updated on", sortKey: SORTABLE_COLUMN_NAMES.updatedOn, draggable: true },
};

export default function Stacks() {
    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const stackList = useSelector(stackSliceSelectors.stackList);
    const stacks = useSelector(stackSliceSelectors.stackList);
    const loading = useSelector(stackSliceSelectors.loading);
    const filter = useSelector(stackSliceSelectors.getFilter);
    const organizations = useSelector(userSliceSelectors.organizations);
    const noResult = filter.q.name !== "" && stacks.totalRecords === 0 && !loading;
    const [modalOpen, setModalOpen] = useState(false);
    const [noRequestModalOpen, setNoRequestModalOpen] = useState(false);
    const [isTitleMenuOpen, setIsTitleMenuOpen] = useState(false);
    const [titleMenuAnchorEl, setTitleMenuAnchorEl] = useState(null);
    const [dataRequestLimit, setDataRequestLimit] = useState();
    const [showRow, setShowRow] = useState(false);
    const [activeStack, setActiveStack] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const dispatch = useDispatch();
    const props = {
        backgroundLimit: dataRequestLimit === "0" ? "#F64949" : "#E9EEF2",
        colorLimit: dataRequestLimit === "0" ? "#FDFDFD" : "#595959",
        borderLimit: dataRequestLimit === "0" ? 0 : " 1px solid rgba(0, 0, 0, 0.23)",
    };
    const classes = useStyles(props);

    // gather all filter data into one array
    const filterData = useMemo(() => {
        let filterArray = [];

        if (filter.tags?.length > 0) {
            filterArray = [...filter.tags];
        }

        return filterArray;
    }, [filter]);

    const handleRemoveFilterItem = (filterItem) => {
        const modifiedFilter = filter?.tags.filter((tag) => tag.id !== filterItem.id);
        dispatch(updateFilter({ ...filter, tags: modifiedFilter }));
    };

    const [columnOrder, setColumnOrder] = useState(defaultColumns);
    const [selectedRows, setSelectedRows] = useState([]);

    const columns = useMemo(() => {
        if (!columnOrder) return [];
        return columnOrder.map((col) => nameToProperties[col.name]);
    }, [columnOrder]);

    const stackToRow = (stack) => ({
        id: stack.id,
        "Stack Name": { color: getColor(stack.stack), name: stack.stack.name },
        "Workforce Range": getCategoryBySize(stack.numberOfEmployees),
        Tags: stack.stack.tags,
        Industry: " ",
        "Updated on": dayjs(stack.stack.updated_at).format("DD/MMM/YYYY"),
        actions: [{ icon: <EditIcon />, tooltip: "Edit Stack", callback: () => handleEditClicked(stack) }],
    });

    const rows = useMemo(() => {
        if (!columnOrder || !stackList?.records?.length) return [];

        let tempSelectedRows = selectedStacks.map((stack) => stackToRow(stack));
        tempSelectedRows = tempSelectedRows.map((row) => ({
            id: row.id,
            data: columnOrder.map((col) => row[col.name]),
            actions: row.actions,
        }));
        setSelectedRows(tempSelectedRows);

        const unorderedRows = stackList.records.map((stack) => stackToRow(stack));

        return unorderedRows.map((row) => ({
            id: row.id,
            data: columnOrder.map((col) => row[col.name]),
            actions: row.actions,
        }));
    }, [stackList, columnOrder]);

    const handleEditClicked = (stack) => {
        setActiveStack(stack);
        setEditModalOpen(true);
    };

    useEffect(async () => {
        try {
            const { data } = await getTableColumns("stacksTable");
            setColumnOrder(data.filter((col) => nameToProperties[col.name]));
        } catch {
            await updateTableColumns("stacksTable", defaultColumns);
        }
    }, []);

    useEffect(() => {
        dispatch(clearState());
        dispatch(getTagsByFilter());
    }, []);

    useEffect(() => {
        const params =
            filter.q.name || filter.tags?.length > 0
                ? filter
                : {
                      page: filter.start,
                      limit: filter.limit,
                      sort: filter.sort || undefined,
                      direction: filter.direction || undefined,
                  };
        dispatch(getAllByFilter(params));
    }, [filter]);

    useEffect(() => {
        if (
            organizations[0]?.limits &&
            organizations[0]?.limits?.length &&
            organizations[0].limits[1].initial_credits !== -1
        ) {
            setDataRequestLimit(organizations[0].limits[1].credits.toString());
        }
    }, []);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleTitleMenuClicked = (event) => {
        if (isTitleMenuOpen) {
            setTitleMenuAnchorEl(null);
            setIsTitleMenuOpen(false);
        } else {
            setTitleMenuAnchorEl(event.currentTarget);
            setIsTitleMenuOpen(true);
        }
    };

    const onPageChange = (start) => {
        dispatch(updateFilter({ ...filter, start }));
    };

    const handleDragEnd = async (e) => {
        if (!e.destination || e.destination.index === 0) return;

        const newOrder = Array.from(columnOrder.slice(0, 5));
        const [source_column] = newOrder.splice(e.source.index, 1);
        newOrder.splice(e.destination.index, 0, source_column);
        setColumnOrder(newOrder);

        await updateTableColumns("stacksTable", newOrder);
    };

    const handleSelected = (row, index, isSelected) => {
        if (!isSelected && selectedRows.length >= 2) {
            dispatch(
                addMessage({
                    type: "info",
                    title: "Too many Stacks selected",
                    message: [
                        "For now you can only select one or two Stacks for insights.",
                        "Please deselect one of the Stacks by clicking on them again and choose a different Stack you want to compare.",
                    ],
                    visibilityTime: 6000,
                }),
            );
            return;
        }

        if (isSelected) {
            setSelectedRows(selectedRows.filter((item) => item.id !== row.id));
            dispatch(setSelectedStacks([...selectedStacks.filter((item) => item.id !== row.id)]));
        } else if (selectedRows.filter((item) => item.id === row.id).length === 0) {
            setSelectedRows([...selectedRows, row]);
            dispatch(setSelectedStacks([...selectedStacks, ...stackList.records.filter((item) => item.id === row.id)]));
        }
    };

    const showLoader = loading && !stacks;

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Container>
                    <Header>
                        <Grid container>
                            <Grid item xs={3}>
                                <Grid
                                    container
                                    alignItems="center"
                                    onClick={handleTitleMenuClicked}
                                    sx={{ width: "max-content" }}
                                >
                                    <Title value="Stack Insights - Active" />
                                    <ArrowDropDownIcon className={classes.dropDownIcon} />
                                </Grid>
                                <Menu
                                    sx={{ mt: 0.5, ml: -1 }}
                                    anchorEl={titleMenuAnchorEl}
                                    open={isTitleMenuOpen}
                                    onClose={handleTitleMenuClicked}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <MenuItem
                                        sx={{ width: 200 }}
                                        onClick={() => navigate("/the-lab/requested-companies")}
                                    >
                                        Requested Companies
                                    </MenuItem>
                                </Menu>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={9} container alignItems="center" justifyContent="flex-end">
                                <Box pr={3}>
                                    {!selectedStacks.length ? (
                                        <Typography variant="caption" display="inline" color="textSecondary">
                                            You can select up to{" "}
                                            <Typography variant="body4" color="text.selected">
                                                2
                                            </Typography>{" "}
                                            Stacks
                                        </Typography>
                                    ) : selectedStacks.length === 1 ? (
                                        <Typography variant="caption" display="inline" color="textSecondary">
                                            You can select{" "}
                                            <Typography variant="body4" color="text.selected">
                                                1
                                            </Typography>{" "}
                                            more Stack
                                        </Typography>
                                    ) : null}
                                </Box>
                                <Box pr={2}>
                                    {selectedStacks?.length < 2 ? (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            className={classes.buttonInsights}
                                            onClick={() =>
                                                navigate(
                                                    `/the-lab/stacks/stack-insights/${EMPLOYMENT_SINGLE_INSIGHT}/${selectedStacks[0].id}`,
                                                )
                                            }
                                            disabled={selectedStacks?.length === 0}
                                            disableElevation
                                        >
                                            Company Details
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            onClick={() =>
                                                navigate(
                                                    `/the-lab/stacks/stack-insights/${EMPLOYMENT_COMPARISON_INSIGHT}/${selectedStacks[0].id}/${selectedStacks[1].id}`,
                                                )
                                            }
                                            className={classes.buttonInsights}
                                            disableElevation
                                        >
                                            Show Insights
                                        </Button>
                                    )}
                                </Box>
                                <Box pr={2} position="relative">
                                    {dataRequestLimit && (
                                        <Tooltip
                                            title={
                                                dataRequestLimit === "0"
                                                    ? "You have no requests available."
                                                    : `You have ${dataRequestLimit} more requests available.`
                                            }
                                        >
                                            {dataRequestLimit && (
                                                <Typography variant="body2" className={classes.limitNumber}>
                                                    {dataRequestLimit}
                                                </Typography>
                                            )}
                                        </Tooltip>
                                    )}

                                    <Button
                                        size="large"
                                        variant="vip"
                                        onClick={() =>
                                            dataRequestLimit === "0" ? setNoRequestModalOpen(true) : setModalOpen(true)
                                        }
                                    >
                                        Request Company Data
                                    </Button>
                                </Box>
                                <IconButton size="large">
                                    <TuneIcon className={classes.icon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Header>
                    <FilterChips
                        showRow={showRow}
                        setShowRow={setShowRow}
                        filterData={filterData}
                        onRemoveFilterItem={handleRemoveFilterItem}
                    />
                </Container>
                <Box m={3} mt={0}>
                    {noResult ? (
                        <NoResult />
                    ) : (
                        <Paper elevation={1}>
                            <Box>
                                <Table
                                    columns={columns}
                                    rows={rows}
                                    selectedRows={selectedRows}
                                    sortBy={filter.sort}
                                    direction={filter.direction}
                                    onSortBy={(columnName, direction) =>
                                        dispatch(updateFilter({ ...filter, sort: columnName, direction }))
                                    }
                                    onDragEnd={handleDragEnd}
                                    onSelected={handleSelected}
                                />
                            </Box>
                            {showLoader ? (
                                <Box className={classes.loader}>
                                    <OsterusLoader />
                                </Box>
                            ) : null}
                        </Paper>
                    )}
                    {stacks?.totalRecords > 0 && (
                        <PaginationComponent
                            onChange={onPageChange}
                            page={filter.start}
                            limit={filter.limit}
                            totalRecords={stacks.totalRecords}
                        />
                    )}
                </Box>
                {editModalOpen && (
                    <EditModal
                        listItem={activeStack}
                        onClose={() => {
                            setActiveStack(null);
                            setEditModalOpen(false);
                        }}
                        onSave={() => window.location.reload(false)}
                    />
                )}
            </Grid>
            <CompanyDataRequestModal
                open={modalOpen}
                handleClose={handleCloseModal}
                dataRequestLimit={dataRequestLimit}
                setDataRequestLimit={setDataRequestLimit}
            />
            <NoRequestsAvailableModal
                title="Request Company Data"
                open={noRequestModalOpen}
                handleClose={() => setNoRequestModalOpen(false)}
                message="You have reached your Company Data Request limit. If you wish to request more please contact Osterus team."
            />
        </Grid>
    );
}
