import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStacksApi, createStackApi, editStackApi, deleteStackApi } from "../../services/api/Admin/stacks";

export const getStacks = createAsyncThunk("admin/stacks", async ({ organization_id, name, page = 1 }) => {
    try {
        const { data } = await getStacksApi(organization_id, { name, page });
        return { data };
    } catch (error) {
        throw error;
    }
});

export const createStack = createAsyncThunk("admin/create-stack", async ({ stackData }) => {
    try {
        const { data } = await createStackApi(stackData);
        return { data };
    } catch (error) {
        throw error;
    }
});

export const editStack = createAsyncThunk("admin/edit-stack", async ({ id, stackData }) => {
    try {
        const { data } = await editStackApi(id, stackData);
        return { data };
    } catch (error) {
        throw error;
    }
});

export const deleteStack = createAsyncThunk("admin/delete-stack", async ({ id }) => {
    try {
        const { data } = await deleteStackApi(id);
        return { data };
    } catch (error) {
        throw error;
    }
});
