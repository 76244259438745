import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";
import { MetricGroup } from "../../../../components/MetricGroup";

export default function LanguagesSection({ data, currentCity, comparisonCity, theme, classes }) {
    const languages = useMemo(() => {
        const averageSpoken = data.languages.averageSpokenLanguages.data.map((value) => value || 0);
        const leftAverageSpokenSmaller = averageSpoken[0] < averageSpoken[1];
        const averageSpokenDiff = leftAverageSpokenSmaller
            ? averageSpoken[1] - averageSpoken[0]
            : averageSpoken[0] - averageSpoken[1];
        const left = data.languages.languagesSpoken.data[0];
        const right = data.languages.languagesSpoken.data[1];
        const longerList = left.length >= right.length ? left : right;

        return {
            numberOfSpokenLanguages: {
                values: averageSpoken,
                barPercentages: [
                    leftAverageSpokenSmaller ? (averageSpoken[0] / averageSpoken[1]) * 100 : 100,
                    leftAverageSpokenSmaller ? 100 : (averageSpoken[1] / averageSpoken[0]) * 100,
                ],
                colors: [
                    leftAverageSpokenSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAverageSpokenSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <LanguageIcon className={leftAverageSpokenSmaller ? classes.smallerScore : classes.higherScore} />,
                    <LanguageIcon className={leftAverageSpokenSmaller ? classes.higherScore : classes.smallerScore} />,
                ],
                message: `${comparisonCity.name} has ${parseFloat(averageSpokenDiff).toFixed(2)} ${
                    leftAverageSpokenSmaller ? "more" : "less"
                } spoken languages than ${currentCity.name}`,
            },
            mostSpokenLanguages: {
                values: longerList.map((_, index) => [left[index], right[index]]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Languages">
            <MetricItem
                name="The Average Number of Spoken Languages in the city"
                values={languages.numberOfSpokenLanguages.values}
                barPercentages={languages.numberOfSpokenLanguages.barPercentages}
                colors={languages.numberOfSpokenLanguages.colors}
                indicators={languages.numberOfSpokenLanguages.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {languages.numberOfSpokenLanguages.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Most Spoken Languages in the city"
                    values={languages.mostSpokenLanguages.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={LanguageIcon}
                />
            </Stack>
        </MetricSection>
    );
}
