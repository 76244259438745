import React from "react";
import { Typography } from "@mui/material";

export default function Title({ value }) {
    return (
        <Typography color="text.headlines" variant="h5">
            {value}
        </Typography>
    );
}
