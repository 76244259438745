import { axiosInstance } from "../../../config/api/Axios";

export const getStacksApi = async (organization_id, params = { page: 1 }) =>
    axiosInstance.get(`/organizations/${organization_id}/stacks`, { params });

export const createStackApi = async (organization_id, stackData) =>
    axiosInstance.post(`/organizations/${organization_id}/stacks`, stackData);

export const editStackApi = async (organization_id, id, stackData) =>
    axiosInstance.put(`/organizations/${organization_id}/stacks/${id}`, stackData);

export const deleteStackApi = async (organization_id, id) =>
    axiosInstance.delete(`/organizations/${organization_id}/stacks/${id}`);
