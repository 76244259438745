import React from "react";
import { Stack, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialBarChart from "../../../Charts/RadialBarChart";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";

const useStyles = makeStyles(() => ({
    loyaltyScoreText: {
        background: "white",
        color: "grey",
        fontSize: 10,
        width: "90%",
    },
    paper: {
        height: "100%",
    },
    subHeader: {
        fontWeight: 400,
    },
}));

const tooltips = [
    "Ratio between boomerang employees and the total number of employees. Having boomerang employees indicates a good company culture to which former employees wish to return.",
    "Ratio between the number of employees who are in high demand and the total number of employees.",
    "Numeric indicator of how good the employee retention rate is over time.",
    "Ratio between the number of tenured employees and the total number of employees.",
    "Ratio between the number of employees who have a Ph.D. and the total number of employees.",
    "Ratio between the number of employees who have a Master’s degree and the total number of employees.",
];

export function Section3({ stacks, data }) {
    const classes = useStyles();

    return (
        <Stack flex={1}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25}>
                    Competitiveness Score
                </Typography>

                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Stack flex={4} height={255} justifyContent="flex-end">
                        <RadialBarChart
                            value={data?.competitivenessAverageScoreSingle?.stack.series?.value}
                            rangeType="competitiveness"
                            height={250}
                        />
                    </Stack>
                    <Stack flex={6} height={255} justifyContent="center" pr={6}>
                        <Typography variant="h5" color="textSecondary" pt={1.25} pl={8} className={classes.subHeader}>
                            Score Metrics
                        </Typography>
                        <StackedBarWithCategories
                            stacks={stacks}
                            titleStyles={{ display: "none" }}
                            series={[data?.competitivenessAverageSubScoresSingle?.stack?.series]}
                            categories={[
                                "Boomerang Ratio",
                                "High Demand Ratio",
                                "Retention Growth Index",
                                "Tenured Employee Ratio",
                                "Ph.D. Ratio",
                                "Masters Ratio",
                            ]}
                            barColors={["#4973EB", "#668DFE", "#7397FD", "#7E9FFB", "#91ADFD", "#A1BAFF"]}
                            itemsPerPage={6}
                            inPercentage={false}
                            max={1}
                            tickAmount={4}
                            noFormats
                            isDistributed
                            showActions={false}
                            barHeight="65%"
                            categoriesStyle={{
                                textAlign: "right",
                                marginBottom: 8,
                            }}
                            tooltips={data.competitivenessAverageSubScoresSingle.categories.map((category, index) => ({
                                title: category,
                                message: tooltips[index],
                            }))}
                            enableCustomTooltip
                            v2
                        />
                    </Stack>
                </Stack>

                <Stack margin="auto" width="100%" backgroundColor="white" alignItems="center" mt={4} pb={4}>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        *Competitiveness Scores are calculated based on the Score Metrics, where each Score Metric has
                        its own contribution to the overall Competitiveness Score.
                    </Typography>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        Competitiveness Score Metrics in the chart are weighted and listed with the top metric having
                        the highest contribution and the bottom one having the lowest.
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );
}
