import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, Button, Divider, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../Table/Table";
import Filter from "./components/Filter";
import Pagination from "../../Pagination";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import { UserInviteModal } from "./components/UserInviteModal";
import { UserEditModal } from "./components/UserEditModal";
import { adminSliceSelectors, clearState, setUsersFilter } from "../../../slices/Admin/adminSlice";
import { getUsers } from "../../../actions/Admin/Users";
import { reinviteUserApi, deleteUserApi, updateUserApi } from "../../../services/api/Admin/users";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import OsterusLoader from "../../Loader";
import { COLUMN_TYPES } from "../../Table/columnTypes";
import { USER_TYPES } from "../../../slices/User/userSlice";
import { formatLastActive } from "../../Settings/Users/utils";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Users",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Users",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function AdminUsersComponent() {
    const loading = useSelector(adminSliceSelectors.usersLoading);
    const data = useSelector(adminSliceSelectors.usersData);
    const totalRecords = useSelector(adminSliceSelectors.usersTotalRecords);
    const filter = useSelector(adminSliceSelectors.usersFilter);
    const limit = filter.limit;
    const [dataSlice, setDataSlice] = useState([]);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(
        () => () => {
            dispatch(clearState());
        },
        [],
    );

    useEffect(() => {
        dispatch(
            getUsers({
                name: filter.q.name || undefined,
                status: filter.q.status || undefined,
                type: filter.q.type || undefined,
                organization_name: filter.q.organization?.full_name || undefined,
                department: filter.q.department || undefined,
                job_title: filter.q.job_title || undefined,
                base_in: filter.q.base_in || undefined,
                referral_note: filter.q.referral_note || undefined,
                sort: filter.sort || undefined,
                direction: filter.direction || undefined,
            }),
        );
        setPage(1);
    }, [filter]);

    useEffect(() => {
        setDataSlice(data.slice(0, Math.min(limit, data.length)));
    }, [data]);

    const onUserEdit = (index) => {
        const toEditUser = dataSlice[index];
        setUser(toEditUser);
        setEditModalOpen(true);
    };

    const onUserReinvite = async (index) => {
        const toReinviteUser = dataSlice[index];
        try {
            await reinviteUserApi(toReinviteUser.id);
            dispatch(
                addMessage({
                    type: "success",
                    title: "User reinvited",
                    message: "User successfully reinvited.",
                    visibilityTime: 5000,
                }),
            );
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Failed to reinvite the user",
                    message: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }
    };

    const handlePageChanged = (pageNum) => {
        setPage(pageNum);
        dispatch(
            getUsers({
                name: filter.q.name || undefined,
                status: filter.q.status || undefined,
                type: filter.q.type || undefined,
                organization_name: filter.q.organization?.full_name || undefined,
                department: filter.q.department || undefined,
                job_title: filter.q.job_title || undefined,
                referral_note: filter.q.referral_note || undefined,
                start: pageNum,
            }),
        );
    };
    const onUserEdited = async (newStatus, newOrganization) => {
        try {
            await updateUserApi(user.id, { status: newStatus, organization_id: newOrganization });
            setDataSlice(
                dataSlice.map((item) => {
                    if (item.id === user.id) return { ...item, status: newStatus, organization_id: newOrganization };
                    return item;
                }),
            );
            dispatch(
                addMessage({
                    type: "success",
                    title: "User edited",
                    message: "User successfully edited.",
                    visibilityTime: 5000,
                }),
            );
            dispatch(
                getUsers({
                    name: filter.q.name || undefined,
                    status: filter.q.status || undefined,
                    type: filter.q.type || undefined,
                    organization_name: filter.q.organization?.full_name || undefined,
                    department: filter.q.department || undefined,
                    job_title: filter.q.job_title || undefined,
                    base_in: filter.q.base_in || undefined,
                    referral_note: filter.q.referral_note || undefined,
                }),
            );
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }
        setEditModalOpen(false);
    };

    const onUserDeleted = async () => {
        try {
            await deleteUserApi(user.id);
            dispatch(
                addMessage({
                    type: "success",
                    title: "User deleted",
                    message: "User successfully deleted.",
                    visibilityTime: 5000,
                }),
            );
            dispatch(
                getUsers({
                    name: filter.q.name || undefined,
                    type: filter.q.type || undefined,
                    organization_name: filter.q.organization?.full_name || undefined,
                    department: filter.q.department || undefined,
                    job_title: filter.q.job_title || undefined,
                    start: page,
                }),
            );
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Failed to delete the user",
                    message: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }
    };

    const columns = useMemo(
        () => [
            { name: "Full Name", align: "left", sortKey: "first_name" },
            { name: "Email" },
            { name: "Access Level" },
            { name: "Status", type: COLUMN_TYPES.ICON, sortKey: "status" },
            { name: "Last Active", type: COLUMN_TYPES.TOOLTIP, sortKey: "last_login_at" },
            { name: "Type" },
            { name: "Organization" },
            { name: "Job Title", sortKey: "title" },
            { name: "Department", sortKey: "department" },
            { name: "Based in", sortKey: "city" },
            { name: "Created at", sortKey: "created_at" },
            { name: "Referral Note" },
        ],
        [],
    );

    const rows = useMemo(
        () =>
            dataSlice.map((item, index) => {
                const actions = [
                    { icon: <EditIcon />, tooltip: "Edit User", callback: () => onUserEdit(index) },
                    item.status !== "active" &&
                        item.status !== "deactivated" && {
                            icon: <PersonAddIcon />,
                            tooltip: "Reinvite User",
                            callback: () => onUserReinvite(index),
                        },
                ];
                return {
                    data: [
                        `${item.first_name || ""} ${item.last_name || ""}`,
                        item.email,
                        "",
                        item.status === "active" ? (
                            <Tooltip title="Active User">
                                <CheckCircleIcon color="success" />
                            </Tooltip>
                        ) : item.status === "pending" ? (
                            <Tooltip title="User Registration Pending">
                                <CheckCircleIcon sx={{ color: "#FFC700" }} />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Deactivated User">
                                <CheckCircleIcon color="disabled" />
                            </Tooltip>
                        ),
                        {
                            content: formatLastActive(item.last_login_at),
                            tooltip: dayjs(item.last_login_at).format("D MMMM, YYYY h:mm A"),
                        },
                        item.type === USER_TYPES.OSTERUS_ADMIN
                            ? "Osterus Admin"
                            : item.type === USER_TYPES.CLIENT_ADMIN
                            ? "Client Admin"
                            : "User",
                        item.organizations.length ? item.organizations[0].full_name : "",
                        item.title,
                        item.department,
                        item.city.name,
                        dayjs(item.created_at).format("DD/MMM/YYYY"),
                        item.referrals.length ? item.referrals[1]?.note : "",
                    ],
                    actions,
                };
            }),
        [dataSlice],
    );

    const onSortBy = async (sortKey, direction) => {
        dispatch(setUsersFilter({ ...filter, sort: sortKey, direction }));
    };

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Stack px={3} pt={2}>
                    <Stack mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    onClick={() => setInviteModalOpen(true)}
                                >
                                    Invite New User
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                    {loading && !dataSlice.length && (
                        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                            <OsterusLoader />
                        </Stack>
                    )}
                    <Stack display={loading && !dataSlice.length ? "none" : "flex"}>
                        <Table columns={columns} rows={rows} onSortBy={onSortBy} />
                        <Pagination
                            page={page}
                            totalRecords={totalRecords}
                            limit={limit}
                            onChange={handlePageChanged}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <UserInviteModal
                open={inviteModalOpen}
                onCancel={() => setInviteModalOpen(false)}
                onInvite={() => {
                    setInviteModalOpen(false);
                }}
            />
            {editModalOpen && (
                <UserEditModal
                    user={user}
                    open={editModalOpen}
                    onCancel={() => setEditModalOpen(false)}
                    onEdit={onUserEdited}
                    onDelete={onUserDeleted}
                />
            )}
        </Grid>
    );
}
