import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "debounce";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { getOrganizationsApi } from "../../../services/api/organization/organization";

export default function OrganizationAutocomplete({
    defaultValue,
    onSelected,
    color = "primary",
    label = "Organization",
    disabled = false,
    required = false,
}) {
    const [organizations, setOrganizations] = useState([]);
    const isPrimary = color === "primary";

    useEffect(async () => {
        const { data } = await getOrganizationsApi();
        setOrganizations(data.records);
    }, []);

    const getOrganizations = useMemo(
        () =>
            debounce(async (organizationName) => {
                const { data } = await getOrganizationsApi({ name: organizationName });
                setOrganizations(data.records);
            }, 300),
        [setOrganizations],
    );

    const handleOnInputChange = async (e) => {
        if (!e) return;

        const organizationName = e.target.value;
        if (!organizationName) {
            setOrganizations([]);
            return;
        }

        getOrganizations(organizationName);
    };

    const handleOnChange = (e, organizationName) => {
        if (!organizationName) {
            onSelected(null);
        }

        const org = organizations.filter((item) => item.full_name === organizationName)[0];
        if (onSelected) {
            onSelected(org);
        }
    };

    return (
        <Autocomplete
            options={organizations.map((org) => org.full_name)}
            fullWidth
            freeSolo
            forcePopupIcon
            value={defaultValue || ""}
            variant="outlined"
            size="small"
            disabled={disabled}
            color={color}
            renderInput={(params) => (
                <TextField
                    color={isPrimary ? "secondary" : "primary"}
                    {...params}
                    label={label}
                    margin="dense"
                    required={required}
                />
            )}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={
                isPrimary ? ({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper> : undefined
            }
        />
    );
}
