import React from "react";
import { TableCell, Typography, Tooltip, Button, IconButton, Chip, Stack, Avatar, Divider } from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import CustomTooltip from "../CustomTooltip";
import { HoverableText, NoteText } from "./CellComponents";

export const renderActions = (cell, actionIndex) => {
    const actions = cell.map((action, index) => {
        if (!action) return undefined;

        let component;
        if (action.text) {
            component = (
                <Tooltip key={index} title={action.tooltip || ""}>
                    <Button type="text" size="small" disableElevation startIcon={action.icon} onClick={action.callback}>
                        {action.text}
                    </Button>
                </Tooltip>
            );
        } else {
            component = (
                <Tooltip key={index} title={action.tooltip || ""}>
                    <IconButton onClick={action.callback}>{action.icon}</IconButton>
                </Tooltip>
            );
        }

        return component;
    });

    return (
        <TableCell key={actionIndex} align="right">
            <Stack direction="row" justifyContent="flex-end">
                {actions}
            </Stack>
        </TableCell>
    );
};

export const renderTags = (cell, columnIndex) => {
    let tags;
    if (cell?.length > 2) {
        tags = (
            <>
                <Chip label={cell[0].name} variant="outlined" sx={{ m: 0.5 }} color="primary" />
                <Chip label={cell[1].name} variant="outlined" sx={{ m: 0.5 }} color="primary" />{" "}
                <CustomTooltip
                    title="Tags attached to this Stack:"
                    message={cell.map((element) => (
                        <Chip
                            key={element.id}
                            label={element.name}
                            variant="outlined"
                            sx={{ m: 0.5 }}
                            color="primary"
                            component="span"
                        />
                    ))}
                    element={
                        <Typography variant="body2" color="textSecondary" display="inline">
                            +{cell.length - 2}
                        </Typography>
                    }
                />
            </>
        );
    } else {
        tags = (
            <>
                {cell &&
                    cell.map((element) => (
                        <Chip
                            key={element.id}
                            label={element.name}
                            variant="outlined"
                            sx={{ m: 0.5 }}
                            color="primary"
                        />
                    ))}
            </>
        );
    }

    return (
        <TableCell key={columnIndex} align="right">
            {tags}
        </TableCell>
    );
};

export const renderStackName = (cell, columnIndex) => (
    <TableCell key={columnIndex} align="left">
        <Stack direction="row" alignItems="center">
            <StorageIcon style={{ marginRight: 16, color: cell.color }} />
            <Typography variant="body2" color="textPrimary">
                {cell.name}
            </Typography>
        </Stack>
    </TableCell>
);

export const renderAvatar = (cell, columnIndex) => (
    <TableCell key={columnIndex} component="th" scope="row">
        {!cell ? (
            <Avatar sx={{ height: 38, width: 38 }} />
        ) : (
            <Avatar
                src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${cell}`}
                sx={{ height: 38, width: 38 }}
            />
        )}
    </TableCell>
);

export const renderLastActive = (cell, columnIndex) => (
    <TableCell key={columnIndex}>
        <Tooltip title={cell.title}>
            <Typography variant="body2" color="textSecondary">
                {cell.text}
            </Typography>
        </Tooltip>
    </TableCell>
);

export const renderEmail = (cell, columnIndex) => (
    <TableCell key={columnIndex} align="right">
        <a
            href={`mailto:${cell}`}
            style={{
                color: "#0052cc",
                textDecoration: "none",
            }}
        >
            {cell || "--"}
        </a>
    </TableCell>
);

export const renderHoverableText = (cell, index) => <HoverableText cell={cell} index={index} />;

export const renderNoteText = (cell, index) => <NoteText cell={cell} index={index} />;

export const renderMultiSeries = (cell, columnIndex, rowIndex) => {
    const dividerStyle = { borderColor: "#eaebed" };
    const content = cell.map((serie) =>
        rowIndex > 0 ? (
            <Stack direction="row">
                <Stack width={50} direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="body3">{serie}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
            </Stack>
        ) : (
            <Stack direction="row">
                <Stack width={50} direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="body10" color="textSecondary" textAlign="center">
                        {serie}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
            </Stack>
        ),
    );

    return (
        <TableCell key={columnIndex} style={{ padding: 0, paddingRight: 0, width: 180 }}>
            <Stack height={rowIndex > 0 ? 52 : 34} direction="row" justifyContent="center">
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
                {content}
            </Stack>
        </TableCell>
    );
};

export const renderWideMultiSeries = (cell, columnIndex, rowIndex) => {
    const dividerStyle = { borderColor: "#eaebed" };
    const content = cell.map((serie) =>
        rowIndex > 0 ? (
            <Stack direction="row">
                <Stack width={60} direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="body3">{serie}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
            </Stack>
        ) : (
            <Stack direction="row">
                <Stack width={60} direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="body10" color="textSecondary" textAlign="center">
                        {serie}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
            </Stack>
        ),
    );

    return (
        <TableCell key={columnIndex} style={{ padding: 0, paddingRight: 0, width: 280 }}>
            <Stack height={rowIndex > 0 ? 52 : 34} direction="row" justifyContent="center">
                <Divider orientation="vertical" flexItem sx={dividerStyle} />
                {content}
            </Stack>
        </TableCell>
    );
};

export const renderTableViewText = (cell, columnIndex) => (
    <TableCell index={columnIndex} sx={columnIndex === 0 ? { paddingLeft: 1 } : undefined} align={cell.align}>
        <Typography variant="body3">{cell.text}</Typography>
    </TableCell>
);
