import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ComparisonBarChart from "../../../MixedCharts/ComparisonBarChart";
import TableChart from "../../../Charts/TableChart";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors } from "../../../utils";

export function Section2({ data, stacks }) {
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [barColors, setBarColors] = useState(
        getColors(
            data.previousCompanies.comparisonChart.stack1.series.map((serie) => serie.name),
            show,
            split,
        ),
    );
    const chartInfo = { title: "Top Previous Companies", shortTitle: "Top Previous Companies" };

    useEffect(() => {
        setBarColors(
            getColors(
                data.previousCompanies.comparisonChart.stack1.series.map((serie) => serie.name),
                show,
                split,
            ),
        );
    }, [show, split, data]);

    const previousCompaniesFormatedData = [
        {
            series: data.previousCompanies.comparisonChart.stack1.series.filter((serie) => serie.name !== "Total"),
            categories: data.previousCompanies.comparisonChart.categories1,
        },
        {
            series: data.previousCompanies.comparisonChart.stack2.series.filter((serie) => serie.name !== "Total"),
            categories: data.previousCompanies.comparisonChart.categories2,
        },
    ];

    const tableChartLegend = [
        {
            name: stacks[0].stack.name,
            color: "#6181ea",
        },
        {
            name: stacks[1].stack.name,
            color: "#4ac086",
        },
    ];

    return (
        <Grid container spacing={1} mt={1}>
            <Grid item xs={8} style={{ paddingTop: 0 }}>
                <ComparisonBarChart
                    stacks={stacks}
                    stack1={previousCompaniesFormatedData[0]}
                    stack2={previousCompaniesFormatedData[1]}
                    chartInfo={chartInfo}
                    barColors={barColors}
                    itemsPerPage={10}
                    height={380}
                    distributed={split === SplitModes.none}
                />
            </Grid>
            <Grid item xs={4} style={{ paddingTop: 0 }}>
                <TableChart
                    title="Work Experience per Degree"
                    tableData={data.educationDistributionPerCompany.tableChart}
                    categories={tableChartLegend.map((item) => item.name)}
                    legend={tableChartLegend}
                    tooltips={[
                        "",
                        "Average Work Experience in Current Company",
                        "Average Work Experience in Previous Companies",
                        "Average Work Experience",
                    ]}
                    chartPadding={{ top: -13, left: -10, right: 0, bottom: -13 }}
                    barHeight="70%"
                    chartHeight={65}
                    stacks={stacks}
                />
            </Grid>
        </Grid>
    );
}
