import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dashboardLotie from "../assets/dashboard.json";

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        textAlign: "center",
    },
    lottieImage: {
        marginLeft: "auto",
        marginRight: "auto",
        width: theme.spacing(50),
    },
}));

export default function NoContent() {
    const classes = useStyles();
    const image = useRef(null);
    useEffect(() => {
        lottie.loadAnimation({
            container: image.current,
            render: "svg",
            loop: true,
            autoplay: true,
            animationData: dashboardLotie,
        });
    }, []);
    return (
        <Box className={classes.root}>
            <Box className={classes.lottieImage} ref={image} />
            <Typography variant="h4" className={classes.typographyPrimary}>
                USE THE DASHBOARD MENU
            </Typography>
            <Typography>to get a quick overview of what is happening in Osterus.</Typography>
            <Typography>Obviously, it's customizable to meet your personal needs.</Typography>
            <Typography>
                <strong>• coming soon •</strong>
            </Typography>
        </Box>
    );
}
