import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Divider,
    Box,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordSchema } from "./validationSchemas";
import { updateSettingsSecurity } from "../../../../actions/user/settings";
import { clearFormErrors, userSliceSelectors } from "../../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialogActions: {
        marginTop: 10,
    },
    field: {
        margin: "24px 0 24px 0",
    },
    text: {
        marginTop: 12,
    },
    boxContainer: {
        width: "100%",
    },
    titleContainer: {
        padding: "16px 0 16px 24px",
    },
    divider: {
        margin: "0 24px",
    },
    icon: {
        fill: theme.palette.success.main,
    },
}));

export default function ChangePasswordDialog({ open, handleClose }) {
    const { id } = useSelector(userSliceSelectors.defaultUserFields);
    const formErrors = useSelector(userSliceSelectors.formErrors);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { isValid, errors },
        reset,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(changePasswordSchema),
    });

    useEffect(() => {
        if (open && formErrors && Object.keys(formErrors).length > 0) {
            for (const [key, value] of Object.entries(formErrors)) {
                setError(key, { message: value, type: "manual" }, { shouldFocus: true });
                setValue(key, "");
            }
        }
    }, [formErrors]);

    const onClose = () => {
        reset();
        dispatch(clearFormErrors());
        handleClose();
    };

    const onSubmit = (data) => {
        const onDone = () => {
            onClose();
        };
        dispatch(updateSettingsSecurity({ id, userData: { user: { ...data } }, onDone }));
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.container}>
                <Box className={classes.boxContainer}>
                    <Box className={classes.titleContainer}>
                        <Typography variant="h6" color="primary">
                            Change Account Password
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div className={classes.field}>
                            <TextField
                                {...register("password")}
                                label="Old Password"
                                fullWidth
                                autoFocus
                                variant="outlined"
                                type="password"
                                error={Boolean(errors.password)}
                            />
                            {errors.password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.field}>
                            <TextField
                                {...register("new_password")}
                                label="New Password"
                                fullWidth
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                error={Boolean(errors.new_password)}
                                InputProps={
                                    isValid
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.new_password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.new_password.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.field}>
                            <TextField
                                {...register("new_password_confirmation")}
                                label="Confirm New Password"
                                fullWidth
                                variant="outlined"
                                type={showPassword ? "text" : "password"}
                                error={Boolean(errors.new_password_confirmation)}
                                InputProps={
                                    isValid
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.new_password_confirmation && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.new_password_confirmation.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.text}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Your password must be at least 8 characters long, have one uppercase letter, one
                                lowercase letter and at least one number or symbol.
                            </Typography>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" disabled={Object.keys(errors).length > 0}>
                            Save New Password
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
}
