import { createAsyncThunk } from "@reduxjs/toolkit";
import { bootstrapAction } from "../../../../actions/bootstrap";
import { axiosInstance } from "../../../../config/api/Axios";
import { addMessage } from "../../../../slices/NotificationSlice/GlobalNotificationSlice";

export const getMe = createAsyncThunk("me", async () => {
    const response = await axiosInstance.get("/me");
    return response.data;
});

export const logIn = createAsyncThunk("login", async (props, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosInstance.post("/login", {
            email: props[0],
            password: props[1],
        });
        dispatch(bootstrapAction());
        return response.data;
    } catch (err) {
        const error = err;
        if (error.response.status === 403) {
            dispatch(
                addMessage({
                    type: "warning",
                    title: "Hi there!",
                    message: `Your status is set to inactive and you can not access the interface. Please contact an Administrator if you want your account to be activated.`,
                    visibilityTime: 5000,
                }),
            );
            return;
        }
        if (!error.response) {
            throw err;
        }
        return rejectWithValue(error.response.data);
    }
});

export const logOut = createAsyncThunk("logout", async () => {
    const response = await axiosInstance.post("/logout");
    return response.data;
});
