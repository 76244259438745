import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Chip,
    Divider,
    Autocomplete,
    MenuItem,
    InputAdornment,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { makeStyles } from "@mui/styles";
import { getTagsApi, createTagApi } from "../../../../services/api/utils";
import { userSliceSelectors } from "../../../../slices/User/userSlice";
import { updateStackApi } from "../../../../services/api/TheLab/Stacks/Stacks";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 550,
        padding: theme.spacing(1),
    },
    titleContainer: {
        margin: "16px 24px",
    },
    dialogActions: {
        marginTop: 10,
    },
    industryField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "flex",
    },
    text: {
        fontSize: 9,
        marginTop: -10,
        marginBottom: theme.spacing(1),
        paddingLeft: 15,
        color: "gray",
    },
    boxField: {
        flex: 2,
    },
    colorField: {
        marginLeft: 10,
        flex: 1,
    },
    chipSpacing: {
        margin: theme.spacing(0.5),
    },
    resize: {
        height: 23,
    },
}));

export default function EditModal({ listItem, onClose, onSave }) {
    const { id } = listItem;
    const { color, name } = listItem.stack;
    const colors = ["#808080", "#6181EA", "#4AC086", "#7645C3", "#FFB03A", "#F64949"];
    const [selectedColor, setSelectedColor] = useState(color || "#808080");
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(listItem.stack.tags);
    const organization = useSelector(userSliceSelectors.organizations)[0];
    const classes = useStyles();

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleKeyDown = (e) => {
        const specialCharacters = /^[A-Za-z0-9]*$/;
        if (!specialCharacters.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleTagsInputChanged = async (e) => {
        const { data } = await getTagsApi({ q: { name: e.target.value } });
        setTags(data.records);
    };

    const handleTagsOnChange = async (e, tagName) => {
        const filtered = selectedTags.filter((tag) => tag.name === tagName);
        if (filtered.length > 0) {
            setSelectedTags([...selectedTags, filtered[0]]);
        } else {
            const { data } = await createTagApi(tagName, organization.id);
            setSelectedTags([...selectedTags, data]);
        }
    };

    const removeTag = (index) => {
        const newTags = selectedTags.slice();
        newTags.splice(index, 1);
        setSelectedTags(newTags);
    };

    const handleSave = async () => {
        const payload = {
            name,
            color: selectedColor,
            tags: selectedTags.map((item) => item.id),
        };
        try {
            await updateStackApi(id, payload);
        } catch (error) {
            console.error(error);
        }
        if (onSave) onSave();
    };

    return (
        <>
            <Dialog onClose={handleClose} open>
                <div className={classes.container}>
                    <div className={classes.titleContainer}>
                        <Typography variant="h6" color="primary">
                            Stack Configuration
                        </Typography>
                    </div>
                    <Divider variant="middle" />
                    <form>
                        <DialogContent>
                            <div className={classes.industryField}>
                                <TextField
                                    value={name}
                                    className={classes.boxField}
                                    label="Stack Name"
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{ classes: { input: classes.resize } }}
                                />
                                <TextField
                                    className={classes.colorField}
                                    label="Stack Color"
                                    fullWidth
                                    variant="outlined"
                                    select
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <div
                                                    style={{
                                                        background: selectedColor,
                                                        width: 30,
                                                        height: 30,
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                >
                                    <MenuItem>
                                        {colors.map((colorItem) => (
                                            <div
                                                style={{
                                                    background: colorItem,
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: "50%",
                                                    marginLeft: 5,
                                                }}
                                                onClick={() => setSelectedColor(colorItem)}
                                            >
                                                {colorItem === selectedColor && (
                                                    <DoneIcon style={{ color: "white", marginLeft: 3, marginTop: 3 }} />
                                                )}
                                            </div>
                                        ))}
                                    </MenuItem>
                                </TextField>
                            </div>
                            <div className={classes.industryField}>
                                <Autocomplete
                                    freeSolo
                                    color="secondary"
                                    options={tags.map((item) => item.name)}
                                    fullWidth
                                    variant="outlined"
                                    renderInput={(params) => {
                                        params.inputProps.onKeyDown = handleKeyDown;
                                        return <TextField {...params} label="Tags" margin="dense" />;
                                    }}
                                    onInputChange={handleTagsInputChanged}
                                    onChange={handleTagsOnChange}
                                />
                            </div>
                            <Typography className={classes.text}>Enter tag and press enter to commit</Typography>
                            {selectedTags.map((element, index) => (
                                <Chip
                                    label={element.name}
                                    variant="outlined"
                                    className={classes.chipSpacing}
                                    color="primary"
                                    onDelete={() => removeTag(index)}
                                />
                            ))}
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button color="primary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </div>
            </Dialog>
        </>
    );
}
