import React from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../../osterus.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "100vh",
    },
    box: {
        margin: "10% auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: 300,
        marginBottom: theme.spacing(2),
    },
}));

export default function Invited() {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={classes.box}>
                <img src={logo} className={classes.logo} alt="osterus logo" />
                <Typography className={classes.title} variant="h3" color="primary" mb={1}>
                    Invitation has been sent!
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    Please allow up to 30 minutes for the invitation to arrive and make sure to check the spam folder.
                    You know, just in case!
                </Typography>
            </Box>
        </Box>
    );
}
