import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, Stack, IconButton, Typography, Button, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    stackInsightsSliceSelector,
    setAppliedFilters,
    setShowAndSplit,
} from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import ShowAndSplitBy from "./ShowAndSplitBy";
import FilterAutocomplete from "./FilterAutocomplete";
import SelectedFilters from "./SelectedFilters";

const initialTopOffset = 132;

export default function FilterDrawer({ open, onClose, anchorElementId, viewType }) {
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    const allFilters = useSelector(stackInsightsSliceSelector.allFilters);
    const [tempFilters, setTempFilters] = useState(appliedFilters);

    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const [tempShow, setTempShow] = useState(null);
    const [tempSplit, setTempSplit] = useState(null);

    useEffect(() => {
        if (JSON.stringify(tempFilters) !== JSON.stringify(appliedFilters)) {
            setTempFilters(appliedFilters);
        }
    }, [appliedFilters]);

    const drawerRef = useRef(null);

    const dispatch = useDispatch();

    const removeFilterCategory = (category) => {
        setTempFilters({ ...tempFilters, [category]: [] });
    };

    const removeSingleFilter = (category, index) => {
        const newCategory = [...tempFilters[category]];
        newCategory.splice(index, 1);
        setTempFilters({ ...tempFilters, [category]: newCategory });
    };

    const handleOptionChanged = (category, option, isChecked) => {
        const filterCategory = tempFilters[category];
        if (isChecked) {
            const newCategory = [...filterCategory, option];
            setTempFilters({ ...tempFilters, [category]: newCategory });
        } else {
            const newCategory = [...filterCategory];
            newCategory.splice(
                newCategory.findIndex((filter) => filter.id === option.id),
                1,
            );
            setTempFilters({ ...tempFilters, [category]: newCategory });
        }
    };

    const clearAllFilters = () => {
        const emptyFilters = {};
        Object.keys(appliedFilters).forEach((key) => {
            emptyFilters[key] = [];
        });
        setTempFilters(emptyFilters);
    };

    const handleApply = () => {
        if (onClose) onClose();

        if (tempShow || tempSplit) {
            dispatch(setShowAndSplit({ show: tempShow, split: tempSplit }));
        }

        if (tempFilters === appliedFilters) {
            dispatch(setAppliedFilters({ ...tempFilters }));
        } else {
            dispatch(setAppliedFilters(tempFilters));
        }
    };

    useEffect(() => {
        if (!drawerRef.current) return;

        const scrollHandler = () => {
            if (!drawerRef.current) return;

            const element = drawerRef.current.firstChild;
            const anchorElement = document.getElementById(anchorElementId);

            const topOffset = anchorElement.getBoundingClientRect().bottom;
            element.style.top = `${topOffset}px`;
            element.style.height = `${window.innerHeight - topOffset}px`;
        };

        document.addEventListener("scroll", scrollHandler);

        return () => document.removeEventListener("scroll", scrollHandler);
    }, [drawerRef]);

    const handleMenuToggled = (isOpen) => {
        const element = drawerRef.current.firstChild;
        if (isOpen) {
            const scrollbarWidth = window.innerWidth - document.body.clientWidth;
            element.style.right = `${scrollbarWidth}px`;
        } else {
            element.style.right = "0px";
        }
    };

    return (
        <Drawer
            ref={drawerRef}
            anchor="right"
            open={open}
            onClose={onClose}
            hideBackdrop
            variant="persistent"
            PaperProps={{ sx: { height: `calc(100vh - ${initialTopOffset}px)`, top: initialTopOffset } }}
        >
            <Stack width="100%" direction="row" justifyContent="flex-end" mt={1} mb={2} pr={2}>
                <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Stack mx={3} flex={1}>
                <ShowAndSplitBy
                    show={tempShow || show}
                    split={tempSplit || split}
                    onShowSelected={(showSelected) => {
                        setTempShow(showSelected);
                    }}
                    onSplitBySelected={(splitSelected) => {
                        setTempSplit(splitSelected);
                    }}
                    onMenuToggled={handleMenuToggled}
                    viewType={viewType}
                />
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1.5}>
                    <Typography variant="h5" color="textSecondary">
                        Filters
                    </Typography>
                    <Button variant="text" size="small" onClick={clearAllFilters}>
                        Clear All
                    </Button>
                </Stack>
                <Stack spacing={2} width="75%" mt={2}>
                    <FilterAutocomplete
                        options={allFilters.jobTitles}
                        selectedOptions={tempFilters.jobTitles}
                        placeholder="Select Job Title..."
                        onOptionChanged={(...rest) => handleOptionChanged("jobTitles", ...rest)}
                    />
                    <FilterAutocomplete
                        options={allFilters.universities}
                        selectedOptions={tempFilters.universities}
                        placeholder="Select University..."
                        onOptionChanged={(...rest) => handleOptionChanged("universities", ...rest)}
                    />
                    <FilterAutocomplete
                        options={allFilters.languages}
                        selectedOptions={tempFilters.languages}
                        placeholder="Select Language..."
                        onOptionChanged={(...rest) => handleOptionChanged("languages", ...rest)}
                    />
                    <FilterAutocomplete
                        options={allFilters.cities}
                        selectedOptions={tempFilters.cities}
                        placeholder="Select City..."
                        onOptionChanged={(...rest) => handleOptionChanged("cities", ...rest)}
                    />
                </Stack>
                <Stack mt={3} mb={1}>
                    <Typography variant="body2" color="textSecondary">
                        SELECTED
                    </Typography>
                </Stack>
                <SelectedFilters
                    filters={tempFilters}
                    onRemoveCategory={removeFilterCategory}
                    onRemoveFilter={removeSingleFilter}
                />
                <Stack direction="row" justifyContent="flex-end" mt="auto" mb={2}>
                    <Button size="small" variant="text" onClick={handleApply} disableElevation>
                        Apply
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}
