import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialBarChart from "../../../Charts/RadialBarChart";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";

const useStyles = makeStyles(() => ({
    loyaltyScoreText: {
        background: "white",
        color: "grey",
        fontSize: 10,
        width: "90%",
    },
    paper: {
        height: "100%",
    },
    subHeader: {
        fontWeight: 400,
    },
}));

const tooltips = [
    "Average work experience of current employees in the company affects the overall Loyalty Score the most. It is an indication of how long employees are currently working in the company.",
    "Ratio between boomerang employees and the total number of employees. Having boomerang employees is an indication of a good company culture to which former employees wish to return to.",
    "Ratio between current employees in the company and the former employees within the same company. It shows how the average work experience changes between the two sets of employees.",
    "Ratio between the average work experience of the employees in the current company and the average work experience of these employees in previous companies.",
    "Ratio between current and former employees. Lower ratio signals that the number of current employees is significantly lower than the number of former employees, indicating that the company has a high turnover.",
    "Ratio between full-time employees and associate employees who are hired as consultants. Full-time employees are considered more loyal because they stick to one company.",
];

export function Section1({ stacks, data }) {
    const classes = useStyles();

    return (
        <Stack flex={1}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25}>
                    Loyalty Score
                </Typography>

                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Stack flex={4} height={255} justifyContent="flex-end">
                        <RadialBarChart value={data?.averageScore?.stack?.series?.value} height={250} />
                    </Stack>
                    <Stack flex={6} height={255} justifyContent="center" pr={6}>
                        <Typography variant="h5" color="textSecondary" pt={1.25} pl={8} className={classes.subHeader}>
                            Score Metrics
                        </Typography>
                        <StackedBarWithCategories
                            stacks={stacks}
                            titleStyles={{ display: "none" }}
                            series={[data?.averageSubScores?.stack?.series]}
                            categories={data?.averageSubScores?.categories}
                            barColors={["#4973EB", "#668DFE", "#7397FD", "#7E9FFB", "#91ADFD", "#A1BAFF"]}
                            itemsPerPage={6}
                            inPercentage={false}
                            max={1}
                            tickAmount={4}
                            noFormats
                            isDistributed
                            showActions={false}
                            barHeight="65%"
                            categoriesStyle={{
                                textAlign: "right",
                                marginBottom: 8,
                            }}
                            tooltips={data.averageSubScores.categories.map((category, index) => ({
                                title: category,
                                message: tooltips[index],
                            }))}
                            enableCustomTooltip
                            v2
                        />
                    </Stack>
                </Stack>

                <Stack margin="auto" width="100%" backgroundColor="white" alignItems="center" mt={4} pb={4}>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        *Loyalty Score metrics in the chart are weighted and listed with top metric having highest
                        contribution and bottom one having the lowest.
                    </Typography>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        Loyalty scores are calculated based on the score metrics, where each score metric has its own
                        contribution to the overall Loyalty score.
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );
}
