import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { MetricSection } from "../../../../components/MetricSection";

export default function ProfilesSection({ data, theme, classes }) {
    const profiles = useMemo(() => data.numberOfResumeProfiles.data, [data]);

    const numberOfProfilesToColor = (numberOfProfiles) => {
        if (numberOfProfiles < 100) return theme.palette.alert.main;
        else if (numberOfProfiles <= 500) return theme.palette.warning.main;
        return theme.palette.success.main;
    };

    const getIcons = (numberOfProfiles) => {
        const color = numberOfProfilesToColor(numberOfProfiles);
        if (numberOfProfiles < 100) {
            return (
                <>
                    <PersonIcon className={classes.icon} sx={{ color }} />
                    <PersonIcon className={classes.icon} color="disabled" />
                    <PersonIcon className={classes.icon} color="disabled" />
                </>
            );
        } else if (numberOfProfiles <= 500) {
            return (
                <>
                    <PersonIcon className={classes.icon} sx={{ color }} />
                    <PersonIcon className={classes.icon} sx={{ color }} />
                    <PersonIcon className={classes.icon} color="disabled" />
                </>
            );
        }
        return (
            <>
                <PersonIcon className={classes.icon} sx={{ color }} />
                <PersonIcon className={classes.icon} sx={{ color }} />
                <PersonIcon className={classes.icon} sx={{ color }} />
            </>
        );
    };

    return (
        <MetricSection>
            <Stack width="100%">
                <Typography variant="body2" textAlign="center">
                    Number of profiles in Osterus database in the city
                </Typography>
                <Stack direction="row" justifyContent="space-between" mt={1} px="18%">
                    <Stack alignItems="center">
                        <Stack direction="row">{getIcons(profiles[0])}</Stack>
                    </Stack>
                    <Stack alignItems="center">
                        <Stack direction="row">{getIcons(profiles[1])}</Stack>
                    </Stack>
                </Stack>
            </Stack>
        </MetricSection>
    );
}
