import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import welcomeLottie from "./assets/companies.json";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "85vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
    },
    companies: {
        justifyContent: "center",
        textAlign: "center",
    },
    lottieImage: {
        margin: "auto",
        width: theme.spacing(50),
    },
}));

export default function Companies() {
    const classes = useStyles();
    const image = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: image.current,
            render: "svg",
            loop: true,
            autoplay: true,
            animationData: welcomeLottie,
        });
    }, []);

    return (
        <Box className={classes.root}>
            <Box className={classes.companies}>
                <Box className={classes.lottieImage} ref={image} />
                <Typography variant="h4" className={classes.typographyPrimary}>
                    USE THE COMPANIES SUBMENU
                </Typography>
                <Typography>
                    to explore the Osterus database of companies of industry leaders, as well as companies you assign to
                    be scraped for the needs of your analytics team!
                </Typography>
                <Typography>• coming soon •</Typography>
            </Box>
        </Box>
    );
}
