import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import NoContentDashboard from "./components/NoContentDashboard";

const useStyles = makeStyles(() => ({
    root: {
        minHeight: "85vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function Dashboard() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <NoContentDashboard />
        </Grid>
    );
}
