import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Paper, Divider, Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "debounce";
import NoContent from "./components/NoContent";
import { Filter } from "./components/Filter";
import { Header } from "./components/Header";
import UniversityList from "./components/Info/List";
import UniversityBio from "./components/Info/UniversityBio";
import UniversityInfo from "./components/Info/UniversityInfo";
import NoResult from "../../NoResult";
import OsterusLoader from "../../Loader";
import PaginationComponent from "../../Pagination";
import { universitiesSelectors, updateFilter, clearData } from "../../../slices/Intelligence/UniversitiesSlice";
import { getUniversities } from "../../../actions/intelligence/Universities";
import { getUniversityApi } from "../../../services/api/Intelligence/Universities";

const useStyles = makeStyles(() => ({
    "@global": {
        body: {
            overflow: "hidden",
        },
    },
    noContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    filtersPaper: {
        overflowY: "auto",
        height: "95vh",
        scrollbarWidth: "thin",
        scrollbarColor: "grey",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            borderRadius: 3,
        },
    },
    universityListScrollbar: {
        overflowY: "scroll",
        maxHeight: "calc(100vh - 270px)",
        scrollbarWidth: "thin",
        scrollbarColor: "#cccccc",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccccc",
            borderRadius: 3,
        },
    },
    universityListScrollbarShowRow: {
        overflowY: "scroll",
        maxHeight: "calc(100vh - 300px)",
        scrollbarWidth: "thin",
        scrollbarColor: "#cccccc",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccccc",
            borderRadius: 3,
        },
    },
    UniversityBioScrollbar: {
        display: "flex",
        flexDirection: "column",
        height: "94vh",
    },
    stickyElement: {
        position: "sticky",
        top: 0,
    },
}));

export default function UniversitiesComponent() {
    const loading = useSelector(universitiesSelectors.loading);
    const data = useSelector(universitiesSelectors.data);
    const totalRecords = useSelector(universitiesSelectors.totalRecords);
    const filter = useSelector(universitiesSelectors.filter);
    const [showRow, setShowRow] = useState(false);
    const [iRating, setIRating] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [university, setUniversity] = useState({});

    useEffect(async () => {
        if (filter.search || filter.countries.length || filter.cities.length || filter.internal_rating.length) {
            debounce(
                dispatch(
                    getUniversities({
                        q: filter.search ? { name: filter.search } : undefined,
                        country: filter.countries.map((country) => country.iso2),
                        city: filter.cities.map((city) => city.id),
                        start: filter.start,
                        limit: filter.limit,
                        internal_rating: filter.internal_rating.length ? filter.internal_rating : undefined,
                    }),
                ),
            );
        } else {
            dispatch(clearData());
        }
        setUniversity({});
    }, [filter]);

    const onUniversitySelected = async (uni) => {
        if (uni.id === university.id) {
            setUniversity({});
        } else {
            const response = await getUniversityApi(uni.id);
            setUniversity(response.data);
        }
    };

    const onUniversityClosed = () => {
        setUniversity({});
    };

    const onPageChange = (pageNum) => {
        dispatch(updateFilter({ start: pageNum }));
    };

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter iRating={iRating} setIRating={setIRating} />
                </Paper>
            </Grid>
            {loading ? (
                <Grid item xs={10}>
                    <Header university={university} showRow={showRow} setShowRow={setShowRow} setIRating={setIRating} />
                    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                        <OsterusLoader />
                    </Stack>
                </Grid>
            ) : !data ? (
                <Grid item xs={10} className={classes.noContent}>
                    <NoContent />
                </Grid>
            ) : !university.id ? (
                <Grid item xs={10}>
                    <Header university={university} showRow={showRow} setShowRow={setShowRow} setIRating={setIRating} />
                    <Box className={showRow ? classes.universityListScrollbarShowRow : classes.universityListScrollbar}>
                        <UniversityList universities={data} university={university} onSelected={onUniversitySelected} />
                    </Box>
                    {totalRecords < 1 && <NoResult />}
                    {totalRecords > 0 && (
                        <Box mr={3.5}>
                            <PaginationComponent
                                onChange={onPageChange}
                                page={filter.start}
                                limit={filter.limit}
                                totalRecords={totalRecords}
                            />
                        </Box>
                    )}
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                        <Header
                            university={university}
                            showRow={showRow}
                            setShowRow={setShowRow}
                            setIRating={setIRating}
                        />
                        <Box
                            className={
                                showRow ? classes.universityListScrollbarShowRow : classes.universityListScrollbar
                            }
                        >
                            <UniversityList
                                universities={data}
                                university={university}
                                onSelected={onUniversitySelected}
                            />
                        </Box>
                        {totalRecords < 1 && <NoResult />}
                        {totalRecords > 0 && (
                            <PaginationComponent
                                onChange={onPageChange}
                                page={filter.start}
                                limit={filter.limit}
                                totalRecords={totalRecords}
                            />
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Box className={classes.UniversityBioScrollbar}>
                            <Box className={classes.stickyElement}>
                                <UniversityBio university={university} onClose={onUniversityClosed} />
                                <Divider variant="middle" />
                            </Box>
                            <UniversityInfo university={university} />
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
