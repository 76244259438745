import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialBarChart from "../../../Charts/RadialBarChart";
import StackedBarChartWithDifference from "../../../MixedCharts/StackedBarChartWithDifference";

const useStyles = makeStyles(() => ({
    loyaltyScoreText: {
        background: "white",
        color: "grey",
        fontSize: 10,
        width: "90%",
    },
    subHeader: {
        fontWeight: 400,
    },
}));

const tooltips = [
    "Ratio between boomerang employees and the total number of employees. Having boomerang employees indicates a good company culture to which former employees wish to return.",
    "Ratio between the number of employees who are in high demand and the total number of employees.",
    "Numeric indicator of how good the employee retention rate is over time.",
    "Ratio between the number of tenured employees and the total number of employees.",
    "Ratio between the number of employees who have a Ph.D. and the total number of employees.",
    "Ratio between the number of employees who have a Master’s degree and the total number of employees.",
];

export function Section3({ stacks, data }) {
    const classes = useStyles();

    return (
        <Grid pt={1}>
            <Paper>
                <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25}>
                    Competitiveness Score
                </Typography>
                <Stack>
                    <Stack width="100%" direction="row" mb={1} mt={3}>
                        <Stack flex={1} alignItems="center">
                            <Typography variant="h6" color="textSecondary" ml={5}>
                                {stacks[0].stack.name}
                            </Typography>
                        </Stack>

                        <Stack flex={1} alignItems="center">
                            <Typography variant="h6" color="textSecondary" mr={7}>
                                {stacks[1].stack.name}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="center">
                        <RadialBarChart
                            value={data?.competitivenessAverageScoreSingle?.stack1?.series?.value}
                            rangeType="competitiveness"
                        />
                        <RadialBarChart
                            value={data?.competitivenessAverageScoreSingle?.stack2?.series?.value}
                            rangeType="competitiveness"
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <Stack height={255}>
                        <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25} className={classes.subHeader}>
                            Score Metrics
                        </Typography>
                        <StackedBarChartWithDifference
                            chartInfo={{ title: "", shortTitle: "" }}
                            stacks={stacks}
                            firstStackSeries={[
                                data.competitivenessAverageSubScoresSingle.comparisonChart.stack1.series,
                            ]}
                            secondStackSeries={[
                                data.competitivenessAverageSubScoresSingle.comparisonChart.stack2.series,
                            ]}
                            categories={[
                                "Boomerang Ratio",
                                "High Demand Ratio",
                                "Retention Growth Index",
                                "Tenured Employee Ratio",
                                "Ph.D. Ratio",
                                "Masters Ratio",
                            ]}
                            differenceSeries={[
                                { data: data.competitivenessAverageSubScoresSingle.diffChart.series.data },
                            ]}
                            stackedBarColors={[
                                ["#4973EB", "#668DFE", "#7397FD", "#7E9FFB", "#91ADFD", "#A1BAFF"],
                                ["#4BC186", "#5DD097", "#59D497", "#79D9A9", "#81DBAE", "#94E9BF"],
                            ]}
                            diffBarColors={["#7645c3", "#8f5edd", "#9366d9", "#986cdc", "#a782e1", "#b692ef"]}
                            itemsPerPage={6}
                            distributed
                            inPercentage={false}
                            showLegend={false}
                            titles={["\u2000", "\u2000"]}
                            elevation={0}
                            tooltips={data.competitivenessAverageSubScoresSingle.comparisonChart.categories.map(
                                (category, index) => ({
                                    title: category,
                                    message: tooltips[index],
                                }),
                            )}
                            enableCustomTooltip
                            containerStyle={{ pt: 4.6, width: 186, mr: -1, px: 1 }}
                            padding={[null, null, { bottom: 5, top: -27 }]}
                            diffHeight="90%"
                            chartStyles={{ mt: 0, ml: 14 }}
                            flexRatios={[7, 2]}
                            max={1}
                            tickAmount={4}
                            noFormats
                            isDistributed
                            showActions={false}
                            v2
                        />
                    </Stack>
                    <Stack margin="auto" width="100%" backgroundColor="white" alignItems="center" mt={8} pb={4}>
                        <Typography
                            variant="body3"
                            align="center"
                            className={classes.loyaltyScoreText}
                            display="inline"
                        >
                            *Competitiveness Scores are calculated based on the Score Metrics, where each Score Metric
                            has its own contribution to the overall Competitiveness Score.Competitiveness Score Metrics
                            in the chart are weighted and listed with the top metric having the highest contribution and
                            the bottom one having the lowest.
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </Grid>
    );
}
