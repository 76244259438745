import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { navigate } from "@gatsbyjs/reach-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { codeVerificationSchema } from "./CodeVerificationSchema";
import { getResetPasswordToken } from "../../../actions/user/user";
import { userSliceSelectors, clearPasswordReset } from "../../../slices/User/userSlice";
import logo from "../../../osterus.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "100vh",
    },
    box: {
        margin: "10% auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    btn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: 300,
        marginBottom: theme.spacing(2),
    },
}));

export default function CodeVerification() {
    const { token } = useSelector(userSliceSelectors.passwordReset);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(codeVerificationSchema),
    });
    const classes = useStyles();

    const onSubmit = (data) => {
        const code = data.verification_code;
        dispatch(getResetPasswordToken(code));
    };

    useEffect(() => {
        if (token) navigate("/account/create-new-password");
        else if (token === false) {
            dispatch(clearPasswordReset());
            setError("verification_code", { type: "custom", message: "Invalid verification code" });
        }
    }, [token]);

    return (
        <Box className={classes.container}>
            <Box className={classes.box}>
                <img src={logo} className={classes.logo} alt="osterus logo" />
                <Typography className={classes.title} variant="h3" color="primary" mb={1}>
                    Check Your Email
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    We have sent password recover instructions to your Email.
                </Typography>
                <Typography mt={3} variant="caption2" color="primary">
                    Please enter 6-digit code that we've sent you
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <Box mt={1.5} mb={1}>
                        <TextField
                            {...register("verification_code")}
                            required
                            id="verification_code"
                            variant="outlined"
                            size="small"
                            label="Verification code"
                            sx={{ width: 250 }}
                            error={Boolean(errors.verification_code)}
                        />
                        {errors.verification_code && (
                            <Typography color="error" variant="subtitle2">
                                {errors.verification_code.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={2} className={classes.btn}>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            type="submit"
                            disableElevation
                            sx={{ width: 250 }}
                        >
                            Verify
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
}
