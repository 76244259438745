import React, { useState, useRef } from "react";
import {
    Dialog,
    Typography,
    TextField,
    Button,
    DialogTitle,
    Divider,
    DialogContent,
    MenuItem,
    DialogActions,
    Stack,
    Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { UserDeleteModal } from "./UserDeleteModal";
import OrganizationAutocomplete from "../../../AutocompleteInputs/OrganizationAutocomplete";

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
    },
    profilePicture: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: 80,
        height: 80,
        borderRadius: "50%",
    },
    textField: {
        paddingBottom: 12,
    },
}));

const USER_STATUS = {
    active: "Active",
    deactivated: "Deactivated",
};

export function UserEditModal({ user, open, onEdit, onCancel, onDelete }) {
    const statusRef = useRef(null);
    const [status, setStatus] = useState(user.status);
    const [organization, setOrganization] = useState(user.organizations[0]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const classes = useStyles();

    const onDeleteClicked = () => {
        setDeleteModalOpen(true);
    };

    const onUserDeleted = () => {
        setDeleteModalOpen(false);
        onCancel();
        if (onDelete) onDelete(user);
    };

    const onCancelClicked = () => {
        if (onCancel) onCancel();
    };

    const onSaveClicked = () => {
        if (onEdit) onEdit(status, organization?.id);
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }} maxWidth={false}>
            <Stack mx={2} my={2} width={580}>
                <DialogTitle>Edit User</DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            className={classes.profilePicture}
                            src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${user.profile_picture}`}
                        />
                        <Stack width="100%" ml={3} mb={2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                                <Typography variant="body1" color="textPrimary">
                                    {user.first_name || user.last_name
                                        ? `${user.first_name} ${user.last_name}`
                                        : "Mysterious"}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className={classes.button}
                                    onClick={onDeleteClicked}
                                    disableElevation
                                >
                                    Delete User
                                </Button>
                            </Stack>
                            <Typography variant="body3" color="textSecondary">
                                {user.title || "N/A"}, {user.department || "N/A"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {user.city?.name || "N/A"}, {user.city.country?.name || "N/A"}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack mt={1.5}>
                        <TextField
                            select
                            variant="outlined"
                            label="Access Level"
                            size="small"
                            fullWidth
                            disabled
                            className={classes.textField}
                        />
                    </Stack>
                    <Stack mt={1.5}>
                        <TextField
                            ref={statusRef}
                            select
                            defaultValue={status}
                            variant="outlined"
                            label="Status"
                            size="small"
                            fullWidth
                            disabled={status === "pending"}
                            onChange={(e) => setStatus(e.target.value)}
                            SelectProps={{
                                renderValue: (value) => (
                                    <Typography variant="body2">
                                        {value === "pending" ? "Pending" : USER_STATUS[value]}
                                    </Typography>
                                ),
                            }}
                        >
                            {Object.keys(USER_STATUS).map((key) => (
                                <MenuItem value={key}>
                                    <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                                        {USER_STATUS[key]}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                    <Stack mt={2.5}>
                        <OrganizationAutocomplete
                            defaultValue={organization?.full_name}
                            color="secondary"
                            onSelected={(selectedOrganization) => {
                                if (!selectedOrganization) return;
                                setOrganization(selectedOrganization);
                            }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onCancelClicked}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={onSaveClicked}>
                        Save Changes
                    </Button>
                </DialogActions>
            </Stack>
            <UserDeleteModal
                open={deleteModalOpen}
                onDelete={onUserDeleted}
                onCancel={() => setDeleteModalOpen(false)}
            />
        </Dialog>
    );
}
