import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Stack,
    Tooltip,
} from "@mui/material";
import Chart from "react-apexcharts";
import { makeStyles } from "@mui/styles";
import { ChartContainer } from "./ChartContainer";
import ChartLegend from "./ChartLegend";
import { getTooltip, tooltipStyles } from "./MapChart/Tooltip";

const useStyles = makeStyles((theme) => ({
    container: {
        overflowX: "auto",
        overflowY: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "#cccccc",
        "&::-webkit-scrollbar": {
            height: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccccc",
            borderRadius: 3,
        },
    },
    table: {
        maxHeight: 380,
        "& .MuiTableCell-root": {
            border: `1px solid ${theme.palette.divider}`,
        },
        "& .apexcharts-tooltip-title": {
            textAlign: "left",
        },
    },
    tableRow: {
        height: 10,
        padding: 0,
        margin: 0,
        "&  td:nth-child(2)": {
            "& .apexcharts-tooltip": {
                marginLeft: 80,
            },
        },
    },
    tableCell: {
        padding: 0,
        margin: 0,
    },
    degree: {
        fontSize: 13,
    },
    ...tooltipStyles(theme),
}));

export default function TableChart({
    title,
    tableData,
    categories,
    containerStyles,
    legend,
    tooltips,
    stacks,
    tooltip = " years",
    chartHeight = 65,
    chartPadding,
    barColors = ["#6080EA", "#4AC086"],
    customColors,
    barHeight = "70%",
}) {
    const inPercentage = tooltip === "%";
    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            offsetY: 15,
            text: title.text,
            align: "left",
            offsetX: title.offsetX ?? 0,
            style: {
                fontSize: 16,
                fontWeight: "bold",
                color: "#7F7F7F",
            },
        },
        plotOptions: {
            bar: {
                barHeight,
                borderRadius: 2,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
            },
        },
        fill: {
            opacity: 1,
        },
        colors: customColors ?? barColors,
        legend: {
            show: false,
        },
        grid: {
            show: false,
            padding: chartPadding || {
                top: -20,
                left: -10,
                right: 0,
                bottom: -20,
            },
        },
        xaxis: {
            show: false,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            categories: categories[1] ? [categories[0], categories[1]] : [categories[0]],
        },
        yaxis: {
            show: false,
        },
    };
    const chartInfo = { title, shortTitle: title, description: "" };
    const noResultStackHeight = stacks.length > 1 ? 80 : 50;
    const generateCellChart = (chartData, index, columnIndex) => {
        const maxValue = Math.max(...chartData.series[0].data);
        const noBars = chartData.series[0].data.every((item) => item === 0);
        if (noBars)
            return (
                <Stack height={noResultStackHeight} justifyContent="center">
                    <Typography variant="body5" color="textSecondary">
                        No results match filter criteria
                    </Typography>
                </Stack>
            );
        return (
            <Stack width="100%" margin={0} padding={0}>
                <Chart
                    type="bar"
                    series={chartData.series}
                    categories={categories}
                    options={{
                        ...options,
                        dataLabels: {
                            enabled: true,
                            textAnchor: "start",
                            enabledOnSeries: [chartData.series.length - 1],
                            style: {
                                colors: ["#7B7B7B"],
                            },
                            formatter: (value) =>
                                value === 0 ? "" : !inPercentage ? `\u2000${value}` : `\u2000${value}%`,
                        },
                        tooltip: {
                            custom: () =>
                                getTooltip("v4", {
                                    title: `${tableData.tableBody[0][index].data[0]} / ${tableData.tableHead[columnIndex]} `,
                                    cities: stacks.map((stack) => stack.stack.name),
                                    circleColorClass: "workforceLocationTooltipCircle2",
                                    stackValues: chartData.series[0].data.map((value) => `${value}${tooltip}`),
                                    colors: customColors ? [customColors[index]] : barColors,
                                }),
                        },
                        xaxis: {
                            ...options.xaxis,
                            max: inPercentage ? maxValue * 1.8 : maxValue * 1.5,
                        },
                        colors: customColors ? [customColors[index]] : barColors,
                    }}
                    width={115}
                    height={chartHeight}
                />
            </Stack>
        );
    };

    const classes = useStyles();
    return (
        <Stack width="100%" height="100%" mb={1}>
            <ChartContainer
                chartInfo={chartInfo}
                actionsOffset={{ y: -55 }}
                showTableView={false}
                containerStyles={containerStyles}
            >
                <Stack mb={2}>{legend && <ChartLegend legend={legend} />}</Stack>
                <TableContainer component={Paper} className={classes.container}>
                    <Table className={classes.table} size="small">
                        <TableHead size="small">
                            <TableRow className={classes.tableRow}>
                                {tableData.tableHead.map((item, headIndex) => (
                                    <Tooltip key={headIndex} title={tooltips[headIndex]}>
                                        <TableCell align="center">{item}</TableCell>
                                    </Tooltip>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.tableBody[0].map((row, headIndex) => (
                                <TableRow key={headIndex} className={classes.tableRow}>
                                    {row.data.map((cell, index) => {
                                        if (index === 0) {
                                            return (
                                                <TableCell key={index} className={classes.tableCell} align="center">
                                                    <Typography variant="body5" className={classes.degree}>
                                                        {cell}
                                                    </Typography>
                                                </TableCell>
                                            );
                                        }
                                        return (
                                            <TableCell key={index} className={classes.tableCell} align="center">
                                                {generateCellChart(cell, headIndex, index)}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ChartContainer>
        </Stack>
    );
}
