import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OrganizationCreateComponent from "../../../components/Organization/Create";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
    },
}));

export default function OrganizationCreatePage() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <OrganizationCreateComponent />
        </Box>
    );
}
