export const temporaryStacks = [
    { id: 27, stack: { name: "Tesla" } },
    { id: 29, stack: { name: "SpaceX" } },
];

export const temporarySingleOverviewData = {
    averageScore: { stack: { series: { value: 0.78 } } },
    averageSubScores: {
        categories: [
            "Current Employee Tenure Rate",
            "Boomerang Ratio",
            "Employee Tenure Ratio",
            "Current Employee Tenure Ratio",
            "Employee Ratio",
            "Employment Type Ratio",
        ],
        stack: { series: { data: [0.7, 0.31, 0.06, 0.45, 0.48, 0.14] } },
        diffChart: { series: { data: [-0.7, -0.31, -0.06, -0.45, -0.48, -0.14] } },
    },
    section1: {
        averageScore: { stack: { series: { value: 0.78 } } },
        averageSubScores: {
            categories: [
                "Current Employee Tenure Rate",
                "Boomerang Ratio",
                "Employee Tenure Ratio",
                "Current Employee Tenure Ratio",
                "Employee Ratio",
                "Employment Type Ratio",
            ],
            stack: { series: { data: [0.7, 0.31, 0.06, 0.45, 0.48, 0.14] } },
            diffChart: { series: { data: [-0.7, -0.31, -0.06, -0.45, -0.48, -0.14] } },
        },
    },
    section2: {
        diversityAverageFinalScoreSingle: { stack: { series: { value: 0.32 } } },
        diversityAverageSubScoresSingle: {
            categories: [
                "Gender Diversity",
                "Education Diversity",
                "Language Diversity",
                "Work Experience Group Diversity",
                "Age Group Diversity",
                "Location Diversity",
            ],
            stack: { series: { data: [0.98, 0.15, 0.31, 0.18, 0.7, 0.16] } },
            diffChart: { series: { data: [-0.98, -0.15, -0.31, -0.18, -0.7, -0.16] } },
        },
    },
    section3: {
        competitivenessAverageScoreSingle: { stack: { series: { value: 0.4 } } },
        competitivenessAverageSubScoresSingle: {
            categories: [
                "Boomerang Ratio",
                "High Demand Ratio",
                "Retention Growth Index",
                "Tenured Employees Ratio",
                "Ph D Ratio",
                "Masters Ratio",
            ],
            stack: { series: { data: [0.18, 0.44, 0.45, 0.68, 0.02, 0.26] } },
            diffChart: { series: { data: [-0.18, -0.44, -0.45, -0.68, -0.02, -0.26] } },
        },
    },
};
export const temporaryDemographicsViewData = {
    section1: {
        topCitiesPerCompany: {
            comparisonChart: {
                categories1: [
                    "Atlanta",
                    "New York",
                    "Seattle",
                    "Salt Lake",
                    "Los Angeles",
                    "Detroit",
                    "Minneapolis",
                    "Cincinnati",
                    "St. Paul",
                    "Fort Worth",
                    "Boston",
                    "Brooklyn",
                    "Queens",
                    "Marietta",
                    "Peachtree",
                    "Decatur",
                    "Washington",
                    "Chicago",
                    "Dallas",
                    "McDonough",
                    "Newnan",
                    "Smyrna",
                    "Fayetteville",
                    "San Francisco",
                    "Alpharetta",
                    "Lakeville",
                    "Dearborn",
                    "Indianapolis",
                    "Nashville",
                    "Long Beach",
                ],
                categories2: [
                    "Freiburg im Breisgau",
                    "Barcelona",
                    "Timu\u015f",
                    "Saint Gallen",
                    "W\u00fcrzburg",
                    "Bielefeld",
                    "Z\u00fcrich",
                    "Timi\u015foara",
                    "Frankfurt",
                    "Bonn",
                    "Leipzig",
                    "Berlin",
                    "Planegg",
                    "Emmendingen",
                    "Munich",
                    "Augsburg",
                    "Reutlingen",
                    "Stuttgart",
                    "Huntsville",
                    "Madison",
                    "Appenzell",
                    "Frauenfeld",
                    "Winterthur",
                    "Eschlikon",
                    "Horn",
                    "Alicante",
                    "Matar\u00f3",
                    "Cluj-Napoca",
                    "Nijmegen",
                    "Bad Krozingen",
                ],
                stack1: {
                    series: [
                        {
                            data: [
                                44.67, 8.77, 5.09, 4.83, 4.41, 4.41, 3.73, 2.78, 2.57, 2.47, 2.2, 1.94, 0.94, 0.94,
                                0.84, 0.84, 0.84, 0.84, 0.73, 0.68, 0.68, 0.68, 0.58, 0.52, 0.52, 0.52, 0.52, 0.47,
                                0.47, 0.47,
                            ],
                        },
                    ],
                },
                stack2: {
                    series: [
                        {
                            data: [
                                69.57, 6.79, 3.53, 2.72, 2.45, 1.9, 1.36, 1.36, 1.36, 0.82, 0.82, 0.82, 0.54, 0.54,
                                0.54, 0.54, 0.54, 0.54, 0.27, 0.27, 0.27, 0.27, 0.27, 0.27, 0.27, 0.27, 0.27, 0.27,
                                0.27, 0.27,
                            ],
                        },
                    ],
                },
            },
        },
        genderDistributionCurrent: {
            categories: ["Male", "Female", "Unknown"],
            stack1: { series: { data: [55.64, 42.31, 2.06] } },
            stack2: { series: { data: [61.63, 37.79, 0.58] } },
        },
    },
    section2: {
        countriesAndCitiesDistribution: {
            stack1: [
                {
                    country: "United States",
                    categories: [
                        "Atlanta",
                        "New York",
                        "Seattle",
                        "Salt Lake",
                        "Los Angeles",
                        "Detroit",
                        "Minneapolis",
                        "Cincinnati",
                        "St. Paul",
                        "Fort Worth",
                        "Boston",
                        "Brooklyn",
                        "Queens",
                        "Marietta",
                        "Washington",
                        "Peachtree",
                        "Decatur",
                        "Chicago",
                        "Dallas",
                        "Smyrna",
                        "Newnan",
                        "McDonough",
                        "Fayetteville",
                        "San Francisco",
                        "Lakeville",
                        "Dearborn",
                        "Alpharetta",
                        "Nashville",
                        "Long Beach",
                        "Indianapolis",
                        "Sharpsburg",
                        "Park",
                        "Hebron",
                        "Canton",
                        "Baltimore",
                        "Suwanee",
                        "Kennesaw",
                        "Houston",
                        "Fort Lauderdale",
                        "Columbus",
                        "Bronx",
                        "Bountiful",
                        "Ann Arbor",
                        "Stockbridge",
                        "South Jordan",
                        "Jacksonville",
                        "Charlotte",
                        "Athens",
                        "Ypsilanti",
                        "Virginia Beach",
                        "Roswell",
                        "Rosemount",
                        "North St. Paul",
                        "Norcross",
                        "Jonesboro",
                        "Inver Grove Heights",
                        "Gig Harbor",
                        "Federal Way",
                        "Fairburn",
                        "Conyers",
                        "Camp Washington",
                        "Arlington",
                        "West Palm Beach",
                        "Toledo",
                        "Southfield",
                        "Santa Monica",
                        "Redondo Beach",
                        "Providence",
                        "Prior Lake",
                        "Plymouth",
                        "Phoenix",
                        "Philadelphia",
                        "Orange",
                        "Ogden",
                        "Mableton",
                        "Livonia",
                        "Lawrenceville",
                        "Kansas",
                        "Hopkins",
                        "Hollywood",
                        "Herriman",
                        "Florence",
                        "Duluth",
                        "Draper",
                        "Denver",
                        "Cumming",
                        "Bellevue",
                        "Belleville",
                        "Woodstock",
                        "West Jordan",
                        "West Chester",
                        "Union",
                        "Tacoma",
                        "St. Petersburg",
                        "St. Louis",
                        "Silver Spring",
                        "San Diego",
                        "San Buenaventura",
                        "Olympia",
                        "Oceanside",
                        "Newport",
                        "Mason",
                        "Lithonia",
                        "Lilburn",
                        "Lexington",
                        "Jersey",
                        "Irving",
                        "Inglewood",
                        "Franklin",
                        "Florida",
                        "Douglasville",
                        "Covington",
                        "Corona",
                        "California",
                        "Burnsville",
                        "Austin",
                        "Auburn",
                        "Winter Park",
                        "Windermere",
                        "Wayzata",
                        "Trenton",
                        "Torrance",
                        "Tecumseh",
                        "Stone Mountain",
                        "Staten",
                        "Spanaway",
                        "Savannah",
                        "Santa Barbara",
                        "Royal Oak",
                        "Rome",
                        "Roanoke",
                        "Rancho Palos Verdes",
                        "Provo",
                        "Powder Springs",
                        "Pleasanton",
                        "Pensacola",
                        "Pasadena",
                        "Palm Springs",
                        "Olde West Chester",
                        "Novi",
                        "Northville",
                        "New Orleans",
                        "New Boston",
                        "M\u014d\u02bbili\u02bbili",
                        "Mount Vernon",
                        "Mount Pleasant",
                        "Morrisville",
                        "Minnetonka",
                        "Milton",
                        "Milford",
                        "Memphis",
                        "Maryville",
                        "Maple Valley",
                        "Malden",
                        "Madison",
                        "Macon",
                        "Louisville",
                        "Lithia Springs",
                        "Las Vegas",
                        "Lancaster",
                        "Keller",
                        "Issaquah",
                        "Huntsville",
                        "Howell",
                        "Hawthorne",
                        "Hastings",
                        "Griffin",
                        "Greenville",
                        "Greensboro",
                        "Grapevine",
                        "Glendale",
                        "Fort Myers",
                        "Forest Lake",
                        "Flowery Branch",
                        "Fenton",
                        "Farmington",
                        "Elmont",
                        "Edison",
                        "Eden Prairie",
                        "Denton",
                        "Daytona Beach",
                        "Dayton",
                        "Cottage Grove",
                        "Coppell",
                        "Conroe",
                        "Chino Hills",
                        "Chelsea",
                        "Chaska",
                        "Charleston",
                        "Chapel Hill",
                        "Centerville",
                        "Carrollton",
                        "Burlington",
                        "Boise",
                        "Beverly Hills",
                        "Anacortes",
                        "Allen",
                        "Youngstown",
                        "Yorktown",
                        "Wixom",
                        "Winthrop",
                        "Wilmington",
                        "Wichita",
                        "Whitmore Lake",
                        "Weston",
                        "Westlake Village",
                        "Westfield",
                        "Western Springs",
                        "West Haven",
                        "West Bloomfield",
                        "Waxahachie",
                        "Waterville",
                        "Warwick",
                        "Warren",
                        "Waconia",
                        "Villa Rica",
                        "Vienna",
                        "Victoria",
                        "Vancouver",
                        "Valparaiso",
                        "Vacaville",
                        "Utica",
                        "Utah",
                        "Tuscaloosa",
                        "Turlock",
                        "Tucson",
                        "Tucker",
                        "Troy",
                        "Tooele",
                        "Tomball",
                        "The Colony",
                        "Texarkana",
                        "Tempe",
                        "Tallahassee",
                        "Syracuse",
                        "Suffolk",
                        "Stillwater",
                        "Statesboro",
                        "St. Simons",
                        "St. Cloud",
                        "St. Albans",
                        "Springville",
                        "Springfield",
                        "Spanish Fork",
                        "Southlake",
                        "South St. Paul",
                        "Somerville",
                        "Social Circle",
                        "Snoqualmie",
                        "Snohomish",
                        "Snellville",
                        "Smithtown",
                        "Sioux",
                        "Sherwood",
                        "Shelton",
                        "Seabrook",
                        "Scottsdale",
                        "Scottdale",
                        "Savage",
                        "Saugus",
                        "Santa Cruz",
                        "San Marino",
                        "San Marcos",
                        "San Antonio",
                        "Salem",
                        "Romulus",
                        "Rogers",
                        "Rockwall",
                        "Riverview",
                        "Riverside",
                        "Riverdale",
                        "River Forest",
                        "Richmond Hill",
                        "Revere",
                        "Renton",
                        "Reno",
                        "Red Wing",
                        "Raleigh",
                        "Queen Creek",
                        "Ponte Vedra Beach",
                        "Pompano Beach",
                        "Pomona",
                        "Plano",
                        "Plainfield",
                        "Pittsburgh",
                        "Petaluma",
                        "Parlin",
                        "Park Ridge",
                        "Palos Verdes Peninsula",
                        "Orting",
                        "Onsted",
                        "Oklahoma",
                        "Ocoee",
                        "Oak Harbor",
                        "Northfield",
                        "North Port",
                        "North Little Rock",
                        "North Easton",
                        "North Druid Hills",
                        "Norfolk",
                        "New Windsor",
                        "New Haven",
                        "New Bern",
                        "Morgan",
                        "Moore",
                        "Montebello",
                        "Mobile",
                        "Missouri Branch",
                        "Minot",
                        "Milwaukee",
                        "Midvale",
                        "Middletown",
                        "Melvindale",
                        "Medford",
                        "Marion",
                        "Marina del Rey",
                        "Mankato",
                        "Manhattan Beach",
                        "Manhasset",
                        "Macomb",
                        "Lynn Haven",
                        "Longmeadow",
                        "Loganville",
                        "Logan",
                        "Locust Grove",
                        "Lincoln",
                        "Lewisville",
                        "Lewiston",
                        "Lebanon",
                        "Layton",
                        "Lawrenceburg",
                        "Latonia",
                        "Lake Orion",
                        "Lacey",
                        "Koloa",
                        "Key Largo",
                        "Kentucky",
                        "Kent",
                        "Kenmore",
                        "Kaysville",
                        "Jupiter",
                        "Jordan",
                        "Jacksonville Beach",
                        "Irvine",
                        "Irmo",
                        "Hurst",
                        "Huntington Station",
                        "Huntington Beach",
                        "Holliston",
                        "Hoboken",
                        "Hilton",
                        "Henderson",
                        "Hempstead",
                        "Heber",
                        "Hawaiian Acres",
                        "Hauula",
                        "Hanscom AFB",
                        "Gulf Breeze",
                        "Grovetown",
                        "Grosse Pointe Woods",
                        "Grosse Pointe",
                        "Grosse Ile",
                        "Granbury",
                        "Georgia",
                        "Garden Grove",
                        "Gainesville",
                        "Frisco",
                        "Franklin Park",
                        "Fort Walton Beach",
                        "Fort Pierce",
                        "Fort Mill",
                        "Folsom",
                        "Flower",
                        "Flint",
                        "Flat Rock",
                        "Fishkill",
                        "Farmingdale",
                        "Fairview",
                        "Fairbanks",
                        "Excelsior",
                        "Erlanger",
                        "Ephrata",
                        "El Adobe",
                        "Edwards",
                        "Eden",
                        "East Meadow",
                        "East Greenville",
                        "East Fayetteville",
                        "Eagle River",
                        "Duvall",
                        "Dumont",
                        "Dublin",
                        "Downey",
                        "Dexter",
                        "Delray Beach",
                        "Dearborn Heights",
                        "Danville",
                        "Dalton",
                        "Dahlonega",
                        "Dacula",
                        "Crown Point",
                        "Coronado",
                        "Conway",
                        "Connecticut Avenue Estates",
                        "Compton",
                        "Columbiaville",
                        "Colleyville",
                        "College Park",
                        "College Hill Station",
                        "Coeur d'Alene",
                        "Clermont",
                        "Clearwater",
                        "Claymont",
                        "Clarkston",
                        "Church",
                        "Chevy Chase",
                        "Chester",
                        "Central Islip",
                        "Cary",
                        "Carver",
                        "Carson",
                        "Carleton",
                        "Burbank",
                        "Broomfield",
                        "Bronxville",
                        "Brighton",
                        "Brick",
                        "Bremerton",
                        "Boulder",
                        "Bossier City",
                        "Bonney Lake",
                        "Bloomington",
                        "Bloomfield Hills",
                        "Bloomfield",
                        "Bismarck",
                        "Big Bear Lake",
                        "Bellmore",
                        "Beaufort",
                        "Baton Rouge",
                        "Batavia",
                        "Azle",
                        "Avondale Estates",
                        "Austell",
                        "Aurora",
                        "Augusta",
                        "Aubrey",
                        "Atlantic Beach",
                        "Astoria",
                        "Asheville",
                        "Ankeny",
                        "Allentown",
                        "Allen Park",
                        "Aliso Viejo",
                        "Albrightsville",
                        "Albany",
                        "Agoura Hills",
                        "Adrian",
                        "Addison",
                        "Acworth",
                    ],
                    series: {
                        data: [
                            31.05, 6.09, 3.54, 3.36, 3.06, 3.06, 2.59, 1.93, 1.79, 1.71, 1.53, 1.35, 0.66, 0.66, 0.58,
                            0.58, 0.58, 0.58, 0.51, 0.47, 0.47, 0.47, 0.4, 0.36, 0.36, 0.36, 0.36, 0.33, 0.33, 0.33,
                            0.29, 0.29, 0.29, 0.29, 0.29, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.22, 0.22,
                            0.22, 0.22, 0.22, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18,
                            0.18, 0.18, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15,
                            0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.11, 0.11,
                            0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11,
                            0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                        ],
                    },
                    value: 99.77,
                },
                {
                    country: "India",
                    categories: [
                        "Bangalore",
                        "Th\u0101ne",
                        "None",
                        "New Delhi",
                        "Mumbai",
                        "G\u016brgaon",
                        "Farid\u0101b\u0101d",
                    ],
                    series: { data: [0.18, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04] },
                    value: 0.42,
                },
                { country: "Philippines", categories: ["Manila"], series: { data: [0.07] }, value: 0.07 },
                {
                    country: "Canada",
                    categories: ["Woodbridge", "Montr\u00e9al", "Hilton"],
                    series: { data: [0.04, 0.04, 0.04] },
                    value: 0.12,
                },
                { country: "Australia", categories: ["Sydney"], series: { data: [0.04] }, value: 0.04 },
                { country: "Singapore", categories: ["Singapore"], series: { data: [0.04] }, value: 0.04 },
                { country: "Ecuador", categories: ["Quito"], series: { data: [0.04] }, value: 0.04 },
                { country: "France", categories: ["Paris"], series: { data: [0.04] }, value: 0.04 },
                { country: "Spain", categories: ["Madrid"], series: { data: [0.04] }, value: 0.04 },
                { country: "Switzerland", categories: ["Lugano"], series: { data: [0.04] }, value: 0.04 },
                { country: "Hong Kong", categories: ["Hong Kong"], series: { data: [0.04] }, value: 0.04 },
                { country: "China", categories: ["Changning"], series: { data: [0.04] }, value: 0.04 },
                { country: "Brazil", categories: ["Caatiba"], series: { data: [0.04] }, value: 0.04 },
                { country: "Germany", categories: ["Berlin"], series: { data: [0.04] }, value: 0.04 },
                { country: "Ghana", categories: ["Accra"], series: { data: [0.04] }, value: 0.04 },
            ],
            stack2: [
                {
                    country: "Germany",
                    categories: [
                        "Freiburg im Breisgau",
                        "W\u00fcrzburg",
                        "Bielefeld",
                        "Frankfurt",
                        "Leipzig",
                        "Bonn",
                        "Berlin",
                        "Stuttgart",
                        "Reutlingen",
                        "Planegg",
                        "Munich",
                        "Emmendingen",
                        "Augsburg",
                        "Wilhelmshaven",
                        "Waldshut-Tiengen",
                        "Ulm",
                        "Titisee-Neustadt",
                        "Straubing",
                        "Schopfheim",
                        "Schallstadt",
                        "Sankt Wolfgang",
                        "Sankt Peter",
                        "Regensburg",
                        "Olpe",
                        "Nuremberg",
                        "M\u00fcllheim",
                        "Merdingen",
                        "March",
                        "Mainz",
                        "Ludwigshafen",
                        "Karlsruhe",
                        "Kaiserstuhl",
                        "Hiddenhausen",
                        "Herbolzheim",
                        "Hann\u00f6ver",
                        "Gr\u00f6benzell",
                        "Grevenbroich",
                        "Gottenheim",
                        "Ettlingen",
                        "Ettenheim",
                        "Estenfeld",
                        "D\u00fcsseldorf",
                        "Dresden",
                        "Donaueschingen",
                        "Cologne",
                        "Buggingen",
                        "Breisach am Rhein",
                        "Bad Krozingen",
                        "Aschaffenburg",
                        "Ahlen",
                    ],
                    series: {
                        data: [
                            62.9, 2.21, 1.72, 1.23, 0.74, 0.74, 0.74, 0.49, 0.49, 0.49, 0.49, 0.49, 0.49, 0.25, 0.25,
                            0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25,
                            0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25,
                            0.25, 0.25, 0.25, 0.25, 0.25,
                        ],
                    },
                    value: 82.47,
                },
                {
                    country: "Spain",
                    categories: ["Barcelona", "Matar\u00f3", "Alicante"],
                    series: { data: [6.14, 0.25, 0.25] },
                    value: 6.64,
                },
                {
                    country: "Romania",
                    categories: ["Timu\u015f", "Timi\u015foara", "Cluj-Napoca"],
                    series: { data: [3.19, 1.23, 0.25] },
                    value: 4.67,
                },
                {
                    country: "Switzerland",
                    categories: [
                        "Saint Gallen",
                        "Z\u00fcrich",
                        "Winterthur",
                        "Horn",
                        "Frauenfeld",
                        "Eschlikon",
                        "Appenzell",
                    ],
                    series: { data: [2.46, 1.23, 0.25, 0.25, 0.25, 0.25, 0.25] },
                    value: 4.94,
                },
                { country: "Croatia", categories: ["Opatija"], series: { data: [0.25] }, value: 0.25 },
                { country: "Netherlands", categories: ["Nijmegen"], series: { data: [0.25] }, value: 0.25 },
                {
                    country: "United States",
                    categories: ["Madison", "Huntsville"],
                    series: { data: [0.25, 0.25] },
                    value: 0.5,
                },
                {
                    country: "Austria",
                    categories: ["Lustenau", "Innsbruck"],
                    series: { data: [0.25, 0.25] },
                    value: 0.5,
                },
            ],
        },
        ageGroupDistribution: {
            comparisonChart: {
                categories: ["18-25", "25-35", "35-55", "55+"],
                stack1: { series: [{ data: [5.27, 36.27, 43.6, 14.86] }] },
                stack2: { series: [{ data: [7.41, 47.09, 43.49, 2] }] },
            },
            diffChart: { series: { data: [2.14, 10.82, -0.11, -12.86] } },
        },
    },
    section3: {
        topEmployeesLocations: {
            stack1: [
                {
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    number: 851,
                    percent: 26.95,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    place: "New York, United States",
                    city: "New York, US",
                    cityId: 1840034016,
                    number: 167,
                    percent: 5.29,
                    lat: 40.6943,
                    lng: -73.9249,
                },
                {
                    place: "Seattle, United States",
                    city: "Seattle, US",
                    cityId: 1840021117,
                    number: 97,
                    percent: 3.07,
                    lat: 47.6211,
                    lng: -122.3244,
                },
                {
                    place: "Salt Lake, United States",
                    city: "Salt Lake, US",
                    cityId: 1840082816,
                    number: 92,
                    percent: 2.91,
                    lat: 34.4534,
                    lng: -108.7698,
                },
                {
                    place: "Los Angeles, United States",
                    city: "Los Angeles, US",
                    cityId: 1840020491,
                    number: 84,
                    percent: 2.66,
                    lat: 34.1139,
                    lng: -118.4068,
                },
                {
                    place: "Detroit, United States",
                    city: "Detroit, US",
                    cityId: 1840003971,
                    number: 84,
                    percent: 2.66,
                    lat: 42.3834,
                    lng: -83.1024,
                },
                {
                    place: "Minneapolis, United States",
                    city: "Minneapolis, US",
                    cityId: 1840003824,
                    number: 71,
                    percent: 2.25,
                    lat: 39.1243,
                    lng: -97.6997,
                },
                {
                    place: "Cincinnati, United States",
                    city: "Cincinnati, US",
                    cityId: 1840003814,
                    number: 53,
                    percent: 1.68,
                    lat: 39.1413,
                    lng: -84.5061,
                },
                {
                    place: "St. Paul, United States",
                    city: "St. Paul, US",
                    cityId: 1840006484,
                    number: 49,
                    percent: 1.55,
                    lat: 36.9068,
                    lng: -82.3162,
                },
                {
                    place: "Fort Worth, United States",
                    city: "Fort Worth, US",
                    cityId: 1840020696,
                    number: 47,
                    percent: 1.49,
                    lat: 32.7811,
                    lng: -97.3473,
                },
                {
                    place: "Boston, United States",
                    city: "Boston, US",
                    cityId: 1840000455,
                    number: 42,
                    percent: 1.33,
                    lat: 42.3188,
                    lng: -71.0846,
                },
                {
                    place: "Brooklyn, United States",
                    city: "Brooklyn, US",
                    cityId: 1840000592,
                    number: 37,
                    percent: 1.17,
                    lat: 41.4349,
                    lng: -81.7498,
                },
                {
                    place: "Marietta, United States",
                    city: "Marietta, US",
                    cityId: 1840001545,
                    number: 18,
                    percent: 0.57,
                    lat: 39.4241,
                    lng: -81.4465,
                },
                {
                    place: "Queens, United States",
                    city: "Queens, US",
                    cityId: 1840034002,
                    number: 18,
                    percent: 0.57,
                    lat: 40.7498,
                    lng: -73.7976,
                },
                {
                    place: "Washington, United States",
                    city: "Washington, US",
                    cityId: 1840000724,
                    number: 16,
                    percent: 0.51,
                    lat: 39.816,
                    lng: -97.0486,
                },
                {
                    place: "Decatur, United States",
                    city: "Decatur, US",
                    cityId: 1840001403,
                    number: 16,
                    percent: 0.51,
                    lat: 34.573,
                    lng: -86.9906,
                },
                {
                    place: "Peachtree, United States",
                    city: "Peachtree, US",
                    cityId: 1840089287,
                    number: 16,
                    percent: 0.51,
                    lat: 35.094,
                    lng: -83.9427,
                },
                {
                    place: "Chicago, United States",
                    city: "Chicago, US",
                    cityId: 1840000494,
                    number: 16,
                    percent: 0.51,
                    lat: 41.8373,
                    lng: -87.6862,
                },
                {
                    place: "Dallas, United States",
                    city: "Dallas, US",
                    cityId: 1840000364,
                    number: 14,
                    percent: 0.44,
                    lat: 43.2379,
                    lng: -99.5175,
                },
                {
                    place: "McDonough, United States",
                    city: "McDonough, US",
                    cityId: 1840015706,
                    number: 13,
                    percent: 0.41,
                    lat: 33.4399,
                    lng: -84.1509,
                },
                {
                    place: "Newnan, United States",
                    city: "Newnan, US",
                    cityId: 1840014833,
                    number: 13,
                    percent: 0.41,
                    lat: 33.3766,
                    lng: -84.7761,
                },
                {
                    place: "Smyrna, United States",
                    city: "Smyrna, US",
                    cityId: 1840004512,
                    number: 13,
                    percent: 0.41,
                    lat: 42.6914,
                    lng: -75.6152,
                },
                {
                    place: "Fayetteville, United States",
                    city: "Fayetteville, US",
                    cityId: 1840004320,
                    number: 11,
                    percent: 0.35,
                    lat: 43.0308,
                    lng: -75.9985,
                },
                {
                    place: "Dearborn, United States",
                    city: "Dearborn, US",
                    cityId: 1840003969,
                    number: 10,
                    percent: 0.32,
                    lat: 42.3127,
                    lng: -83.2129,
                },
                {
                    place: "Alpharetta, United States",
                    city: "Alpharetta, US",
                    cityId: 1840013659,
                    number: 10,
                    percent: 0.32,
                    lat: 34.0704,
                    lng: -84.2739,
                },
                {
                    place: "Lakeville, United States",
                    city: "Lakeville, US",
                    cityId: 1840007859,
                    number: 10,
                    percent: 0.32,
                    lat: 44.6774,
                    lng: -93.252,
                },
                {
                    place: "San Francisco, United States",
                    city: "San Francisco, US",
                    cityId: 1840021543,
                    number: 10,
                    percent: 0.32,
                    lat: 37.7562,
                    lng: -122.443,
                },
                {
                    place: "Long Beach, United States",
                    city: "Long Beach, US",
                    cityId: 1840000988,
                    number: 9,
                    percent: 0.28,
                    lat: 40.5887,
                    lng: -73.666,
                },
                {
                    place: "Nashville, United States",
                    city: "Nashville, US",
                    cityId: 1840008651,
                    number: 9,
                    percent: 0.28,
                    lat: 38.3541,
                    lng: -89.377,
                },
                {
                    place: "Indianapolis, United States",
                    city: "Indianapolis, US",
                    cityId: 1840030084,
                    number: 9,
                    percent: 0.28,
                    lat: 39.7771,
                    lng: -86.1458,
                },
            ],
            stack2: [
                {
                    place: "Freiburg im Breisgau, Germany",
                    city: "Freiburg im Breisgau, DE",
                    cityId: 1276371228,
                    number: 256,
                    percent: 49.61,
                    lat: 47.9947,
                    lng: 7.8497,
                },
                {
                    place: "Barcelona, Spain",
                    city: "Barcelona, ES",
                    cityId: 1724594040,
                    number: 25,
                    percent: 4.84,
                    lat: 41.3825,
                    lng: 2.1769,
                },
                {
                    place: "Timu\u015f, Romania",
                    city: "Timu\u015f, RO",
                    cityId: 1642421082,
                    number: 13,
                    percent: 2.52,
                    lat: 48.0316,
                    lng: 26.9949,
                },
                {
                    place: "Saint Gallen, Switzerland",
                    city: "Saint Gallen, CH",
                    cityId: 1756343098,
                    number: 10,
                    percent: 1.94,
                    lat: 47.4233,
                    lng: 9.3772,
                },
                {
                    place: "W\u00fcrzburg, Germany",
                    city: "W\u00fcrzburg, DE",
                    cityId: 1276233514,
                    number: 9,
                    percent: 1.74,
                    lat: 49.7944,
                    lng: 9.9294,
                },
                {
                    place: "Bielefeld, Germany",
                    city: "Bielefeld, DE",
                    cityId: 1276112885,
                    number: 7,
                    percent: 1.36,
                    lat: 52.0167,
                    lng: 8.5333,
                },
                {
                    place: "Timi\u015foara, Romania",
                    city: "Timi\u015foara, RO",
                    cityId: 1642603121,
                    number: 5,
                    percent: 0.97,
                    lat: 45.7597,
                    lng: 21.23,
                },
                {
                    place: "Frankfurt, Germany",
                    city: "Frankfurt, DE",
                    cityId: 1276054552,
                    number: 5,
                    percent: 0.97,
                    lat: 50.1136,
                    lng: 8.6797,
                },
                {
                    place: "Z\u00fcrich, Switzerland",
                    city: "Z\u00fcrich, CH",
                    cityId: 1756539143,
                    number: 5,
                    percent: 0.97,
                    lat: 47.3786,
                    lng: 8.54,
                },
                {
                    place: "Leipzig, Germany",
                    city: "Leipzig, DE",
                    cityId: 1276563678,
                    number: 3,
                    percent: 0.58,
                    lat: 51.3333,
                    lng: 12.3833,
                },
                {
                    place: "Berlin, Germany",
                    city: "Berlin, DE",
                    cityId: 1276451290,
                    number: 3,
                    percent: 0.58,
                    lat: 52.5167,
                    lng: 13.3833,
                },
                {
                    place: "Bonn, Germany",
                    city: "Bonn, DE",
                    cityId: 1276807850,
                    number: 3,
                    percent: 0.58,
                    lat: 50.7339,
                    lng: 7.0997,
                },
                {
                    place: "Reutlingen, Germany",
                    city: "Reutlingen, DE",
                    cityId: 1276256795,
                    number: 2,
                    percent: 0.39,
                    lat: 48.4833,
                    lng: 9.2167,
                },
                {
                    place: "Planegg, Germany",
                    city: "Planegg, DE",
                    cityId: 1276760945,
                    number: 2,
                    percent: 0.39,
                    lat: 48.1047,
                    lng: 11.4306,
                },
                {
                    place: "Stuttgart, Germany",
                    city: "Stuttgart, DE",
                    cityId: 1276171358,
                    number: 2,
                    percent: 0.39,
                    lat: 48.7761,
                    lng: 9.1775,
                },
                {
                    place: "Augsburg, Germany",
                    city: "Augsburg, DE",
                    cityId: 1276314100,
                    number: 2,
                    percent: 0.39,
                    lat: 48.3717,
                    lng: 10.8983,
                },
                {
                    place: "Munich, Germany",
                    city: "Munich, DE",
                    cityId: 1276692352,
                    number: 2,
                    percent: 0.39,
                    lat: 48.1372,
                    lng: 11.5755,
                },
                {
                    place: "Emmendingen, Germany",
                    city: "Emmendingen, DE",
                    cityId: 1276708263,
                    number: 2,
                    percent: 0.39,
                    lat: 48.1214,
                    lng: 7.8492,
                },
                {
                    place: "M\u00fcllheim, Germany",
                    city: "M\u00fcllheim, DE",
                    cityId: 1276925185,
                    number: 1,
                    percent: 0.19,
                    lat: 47.8083,
                    lng: 7.6308,
                },
                {
                    place: "Bad Krozingen, Germany",
                    city: "Bad Krozingen, DE",
                    cityId: 1276964772,
                    number: 1,
                    percent: 0.19,
                    lat: 47.9181,
                    lng: 7.7025,
                },
                {
                    place: "Innsbruck, Austria",
                    city: "Innsbruck, AT",
                    cityId: 1040038924,
                    number: 1,
                    percent: 0.19,
                    lat: 47.2683,
                    lng: 11.3933,
                },
                {
                    place: "Huntsville, United States",
                    city: "Huntsville, US",
                    cityId: 1840005061,
                    number: 1,
                    percent: 0.19,
                    lat: 34.6988,
                    lng: -86.6412,
                },
                {
                    place: "Karlsruhe, Germany",
                    city: "Karlsruhe, DE",
                    cityId: 1276229317,
                    number: 1,
                    percent: 0.19,
                    lat: 52.9145,
                    lng: 12.1873,
                },
                {
                    place: "Aschaffenburg, Germany",
                    city: "Aschaffenburg, DE",
                    cityId: 1276069907,
                    number: 1,
                    percent: 0.19,
                    lat: 49.9757,
                    lng: 9.1478,
                },
                {
                    place: "Ahlen, Germany",
                    city: "Ahlen, DE",
                    cityId: 1276256136,
                    number: 1,
                    percent: 0.19,
                    lat: 52.9393,
                    lng: 7.3188,
                },
                {
                    place: "Opatija, Croatia",
                    city: "Opatija, HR",
                    cityId: 1191093484,
                    number: 1,
                    percent: 0.19,
                    lat: 45.3169,
                    lng: 13.9378,
                },
                {
                    place: "Waldshut-Tiengen, Germany",
                    city: "Waldshut-Tiengen, DE",
                    cityId: 1276011078,
                    number: 1,
                    percent: 0.19,
                    lat: 47.6231,
                    lng: 8.2144,
                },
                {
                    place: "Olpe, Germany",
                    city: "Olpe, DE",
                    cityId: 1276042758,
                    number: 1,
                    percent: 0.19,
                    lat: 51.3569,
                    lng: 8.1688,
                },
                {
                    place: "Nijmegen, Netherlands",
                    city: "Nijmegen, NL",
                    cityId: 1528136727,
                    number: 1,
                    percent: 0.19,
                    lat: 51.8425,
                    lng: 5.8528,
                },
                {
                    place: "Titisee-Neustadt, Germany",
                    city: "Titisee-Neustadt, DE",
                    cityId: 1276001947,
                    number: 1,
                    percent: 0.19,
                    lat: 47.9122,
                    lng: 8.2147,
                },
            ],
            commonData: [],
        },
        percentageOfEmployeesLocationChangeAfterUniversityPerCountry: {
            categories: [
                "Transportation Services",
                "Other Occupations",
                "Customer Services",
                "Middle Management",
                "Marketing",
            ],
            stack1: { series: { data: [4.55, 3.61, 4.94, 7.36, 14.08] } },
            stack2: { series: { data: [0, 0, 0, 0, 7.69] } },
        },
        percentageOfEmployeesLocationChangeAfterUniversityPerCity: {
            categories: [
                "Transportation Services",
                "Other Occupations",
                "Customer Services",
                "Middle Management",
                "Marketing",
            ],
            stack1: { series: { data: [37.68, 40.11, 29.22, 35.5, 39.44] } },
            stack2: { series: { data: [0, 0, 0, 0, 23.08] } },
        },
    },
    filters: {
        jobTitles: [
            { id: 1, name: "Accounting" },
            { id: 4, name: "Art" },
            { id: 5, name: "Associate" },
            { id: 3, name: "Automation" },
            { id: 6, name: "Board Member" },
            { id: 2, name: "Business Administration" },
            { id: 7, name: "Business Development" },
            { id: 15, name: "C Level" },
            { id: 8, name: "Coach / Professor / Mentor" },
            { id: 9, name: "Consulting" },
            { id: 10, name: "Corporate Affairs" },
            { id: 11, name: "Craft Trade Workers" },
            { id: 12, name: "Customer Services" },
            { id: 14, name: "Data Science and Analysis" },
            { id: 18, name: "Design" },
            { id: 19, name: "Engineering" },
            { id: 20, name: "Finance" },
            { id: 17, name: "Founders / Shareholders" },
            { id: 22, name: "Human Resources" },
            { id: 23, name: "Information Technology" },
            { id: 24, name: "Insurance" },
            { id: 55, name: "Intern" },
            { id: 25, name: "Investors" },
            { id: 27, name: "Legal Services" },
            { id: 29, name: "Manufacturing" },
            { id: 30, name: "Marketing" },
            { id: 31, name: "Media and Entertainment" },
            { id: 32, name: "Medical / Wellness" },
            { id: 28, name: "Middle Management" },
            { id: 42, name: "Military" },
            { id: 64, name: "Movie" },
            { id: 34, name: "Operations" },
            { id: 67, name: "Other Occupations" },
            { id: 35, name: "Partner" },
            { id: 40, name: "Police" },
            { id: 36, name: "Product Development" },
            { id: 37, name: "Production" },
            { id: 38, name: "Project Management" },
            { id: 39, name: "Protection" },
            { id: 41, name: "Public Services" },
            { id: 43, name: "Quality Management" },
            { id: 44, name: "Real Estate" },
            { id: 45, name: "Research and Development" },
            { id: 46, name: "Retail" },
            { id: 47, name: "Retail Banking" },
            { id: 48, name: "Sales" },
            { id: 49, name: "Self Employed" },
            { id: 50, name: "Social Services" },
            { id: 58, name: "Software Development" },
            { id: 57, name: "Sound" },
            { id: 59, name: "Sports / Fitness / Trainer" },
            { id: 51, name: "Student" },
            { id: 53, name: "Supply Chain Management / Logistics" },
            { id: 61, name: "Team lead / Technical lead" },
            { id: 54, name: "Technician" },
            { id: 16, name: "Top Management" },
            { id: 56, name: "Trainee" },
            { id: 60, name: "Transportation Services" },
            { id: 62, name: "University / Academic Affairs" },
            { id: 63, name: "Visual Merchandising" },
            { id: 65, name: "Volunteering" },
            { id: 66, name: "Writer" },
            { id: 13, name: "Diplomacy" },
        ],
        schools: [
            { id: 10314, name: "Academy of Art University" },
            { id: 3461, name: "Acharya Nagarjuna University" },
            { id: 2576, name: "Addis Ababa University" },
            { id: 11226, name: "Adelphi University" },
            { id: 10538, name: "Agnes Scott College" },
            { id: 10553, name: "Albany State University" },
            { id: 10453, name: "American University" },
            { id: 21751, name: "Amity University" },
            { id: 4110, name: "Anna University" },
            { id: 11340, name: "Appalachian State University" },
            { id: 10234, name: "Arizona State University" },
            { id: 10244, name: "Arkansas State University" },
            { id: 10249, name: "Arkansas Tech University" },
            { id: 4991, name: "Art University of Isfahan" },
            { id: 10904, name: "Assumption College" },
            { id: 8178, name: "Ateneo de Davao University" },
            { id: 8215, name: "Ateneo de Manila University" },
            { id: 10572, name: "Atlanta Metropolitan State College" },
            { id: 10202, name: "Auburn University" },
            { id: 7484, name: "Auckland University of Technology" },
            { id: 11009, name: "Augsburg University" },
            { id: 10535, name: "Augusta University" },
            { id: 11669, name: "Augustana University" },
            { id: 11772, name: "Austin College" },
            { id: 2858, name: "Avignon Universit\u00e9" },
            { id: 10976, name: "Baker College" },
            { id: 10673, name: "Ball State University" },
            { id: 10484, name: "Barry University" },
            { id: 11371, name: "Barton College" },
            { id: 11730, name: "Baylor University" },
            { id: 1389, name: "Beijing Language and Culture University" },
            { id: 11895, name: "Bellevue College" },
            { id: 11113, name: "Bellevue University" },
            { id: 11685, name: "Belmont University" },
            { id: 11016, name: "Bemidji State University" },
            { id: 10759, name: "Benedictine College" },
            { id: 11361, name: "Bennett College" },
            { id: 10892, name: "Bentley University" },
            { id: 18597, name: "Berkeley College" },
            { id: 10880, name: "Berklee College of Music" },
            { id: 10757, name: "Bethel College" },
            { id: 10705, name: "Bethel University" },
            { id: 11012, name: "Bethel University" },
            { id: 10501, name: "Bethune-Cookman University" },
            { id: 10580, name: "Beulah Heights University" },
            { id: 10588, name: "Boise State University" },
            { id: 355, name: "Bond University" },
            { id: 10877, name: "Boston College" },
            { id: 10873, name: "Boston University" },
            { id: 10161, name: "Bournemouth University" },
            { id: 11403, name: "Bowling Green State University" },
            { id: 10611, name: "Bradley University" },
            { id: 10878, name: "Brandeis University" },
            { id: 10554, name: "Brenau University" },
            { id: 10796, name: "Brescia University" },
            { id: 10899, name: "Bridgewater State University" },
            { id: 11816, name: "Brigham Young University" },
            { id: 11267, name: "Brooklyn College" },
            { id: 11257, name: "Brooklyn Law School" },
            { id: 10479, name: "Broward College" },
            { id: 11622, name: "Brown University" },
            { id: 11627, name: "Bryant University" },
            { id: 3422, name: "Budapesti Muszaki \u00e9s Gazdas\u00e1gtudom\u00e1nyi Egyetem" },
            { id: 11274, name: "Buffalo State College" },
            { id: 10336, name: "California Baptist University" },
            { id: 10326, name: "California Institute of Integral Studies" },
            { id: 10320, name: "California Lutheran University" },
            { id: 10281, name: "California State University, Long Beach" },
            { id: 10318, name: "California State University, Monterey Bay" },
            { id: 10278, name: "California State University, Northridge" },
            { id: 11582, name: "California University of Pennsylvania" },
            { id: 11954, name: "Cardinal Stritch University" },
            { id: 11517, name: "Carnegie Mellon University" },
            { id: 11943, name: "Carthage College" },
            { id: 10959, name: "Central Michigan University" },
            { id: 11442, name: "Central State University" },
            { id: 11889, name: "Central Washington University" },
            { id: 10287, name: "Chapman University" },
            { id: 11696, name: "Christian Brothers University" },
            { id: 11857, name: "Christopher Newport University" },
            { id: 9451, name: "Chulalongkorn University" },
            { id: 1918, name: "Civil Aviation University of China" },
            { id: 10319, name: "Claremont McKenna College" },
            { id: 10544, name: "Clark Atlanta University" },
            { id: 10550, name: "Clayton State University" },
            { id: 11632, name: "Clemson University" },
            { id: 11409, name: "Cleveland State University" },
            { id: 11635, name: "College of Charleston" },
            { id: 11311, name: "College of Staten Island" },
            { id: 10895, name: "College of the Holy Cross" },
            { id: 10403, name: "Colorado State University" },
            { id: 10413, name: "Colorado Technical University" },
            { id: 11060, name: "Columbia College" },
            { id: 10612, name: "Columbia College Chicago" },
            { id: 18514, name: "Columbia University in the City of New York" },
            { id: 10547, name: "Columbus State University" },
            { id: 11017, name: "Concordia College" },
            { id: 1278, name: "Concordia University" },
            { id: 10867, name: "Coppin State University" },
            { id: 11186, name: "Cornell University" },
            { id: 10053, name: "Cranfield University" },
            { id: 1759, name: "Dalian Maritime University" },
            { id: 1755, name: "Dalian University of Technology" },
            { id: 11781, name: "Dallas Baptist University" },
            { id: 9259, name: "Damascus University" },
            { id: 10973, name: "Davenport University" },
            { id: 10502, name: "Daytona State College" },
            { id: 8115, name: "De La Salle Lipa" },
            { id: 10600, name: "DePaul University" },
            { id: 10608, name: "DeVry University" },
            { id: 364, name: "Deakin University" },
            { id: 10933, name: "Dean College" },
            { id: 11039, name: "Delta State University" },
            { id: 23102, name: "Deutsche Sporthochschule K\u00f6ln" },
            { id: 19148, name: "Dixie State University" },
            { id: 10623, name: "Dominican University" },
            { id: 11520, name: "Drexel University" },
            { id: 11337, name: "Duke University" },
            { id: 11527, name: "Duquesne University" },
            { id: 11343, name: "East Carolina University" },
            { id: 10567, name: "East Georgia State College" },
            { id: 11682, name: "East Tennessee State University" },
            { id: 10775, name: "Eastern Kentucky University" },
            { id: 10961, name: "Eastern Michigan University" },
            { id: 11888, name: "Eastern Washington University" },
            { id: 10489, name: "Eckerd College" },
            { id: 11567, name: "Edinboro University of Pennsylvania" },
            { id: 11344, name: "Elon University" },
            { id: 10472, name: "Embry-Riddle Aeronautical University" },
            { id: 10531, name: "Emory University" },
            { id: 7570, name: "Enugu State University of Science and Technology" },
            { id: 3054, name: "Europa-Universit\u00e4t Viadrina Frankfurt (Oder)" },
            { id: 10520, name: "Everglades University" },
            { id: 10431, name: "Fairfield University" },
            { id: 11158, name: "Fairleigh Dickinson University" },
            { id: 11266, name: "Farmingdale State College" },
            { id: 18535, name: "Fashion Institute of Technology" },
            { id: 3148, name: "FernUniversit\u00e4t in Hagen" },
            { id: 10962, name: "Ferris State University" },
            { id: 11693, name: "Fisk University" },
            { id: 10483, name: "Florida Agricultural and Mechanical University" },
            { id: 10470, name: "Florida Atlantic University" },
            { id: 10478, name: "Florida Gulf Coast University" },
            { id: 10474, name: "Florida Institute of Technology" },
            { id: 10468, name: "Florida International University" },
            { id: 10490, name: "Florida Southern College" },
            { id: 10465, name: "Florida State University" },
            { id: 18520, name: "Fordham University" },
            { id: 10557, name: "Fort Valley State University" },
            { id: 10918, name: "Framingham State University" },
            { id: 3019, name: "Freie Universit\u00e4t Berlin" },
            { id: 1843, name: "Fudan University" },
            { id: 11636, name: "Furman University" },
            { id: 11842, name: "George Mason University" },
            { id: 10451, name: "Georgetown University" },
            { id: 10542, name: "Georgia College and State University" },
            { id: 10560, name: "Georgia Gwinnett College" },
            { id: 10528, name: "Georgia Institute of Technology" },
            { id: 10534, name: "Georgia Southern University" },
            { id: 10530, name: "Georgia State University" },
            { id: 11532, name: "Gettysburg College" },
            { id: 11887, name: "Gonzaga University" },
            { id: 10901, name: "Gordon College" },
            { id: 10571, name: "Gordon State College" },
            { id: 10733, name: "Graceland University" },
            { id: 10237, name: "Grand Canyon University" },
            { id: 10956, name: "Grand Valley State University" },
            { id: 18333, name: "Gustavus Adolphus College" },
            { id: 11007, name: "Hamline University" },
            { id: 11856, name: "Hampton University" },
            { id: 2404, name: "Handelsh\u00f8jskolen i K\u00f8benhavn" },
            { id: 10248, name: "Harding University" },
            { id: 10872, name: "Harvard University" },
            { id: 10295, name: "Harvey Mudd College" },
            { id: 10583, name: "Hawaii Pacific University" },
            { id: 2619, name: "Helsingin yliopisto" },
            { id: 11953, name: "Herzing University" },
            { id: 11435, name: "Hiram College" },
            { id: 11212, name: "Hobart and William Smith Colleges" },
            { id: 2994, name: "Hochschule Weihenstephan-Triesdorf" },
            { id: 3025, name: "Hochschule f\u00fcr Technik und Wirtschaft Berlin" },
            { id: 11202, name: "Hofstra University" },
            { id: 7469, name: "Hogeschool Rotterdam" },
            { id: 8137, name: "Holy Angel University" },
            { id: 10454, name: "Howard University" },
            { id: 10897, name: "Hult International Business School" },
            { id: 3020, name: "Humboldt-Universit\u00e4t zu Berlin" },
            { id: 9122, name: "IE University" },
            { id: 3271, name: "IU Internationale Hochschule" },
            { id: 10590, name: "Idaho State University" },
            { id: 10601, name: "Illinois Institute of Technology" },
            { id: 10604, name: "Illinois State University" },
            { id: 10676, name: "Indiana State University" },
            { id: 10670, name: "Indiana University Bloomington" },
            { id: 10690, name: "Indiana University Southeast" },
            { id: 11530, name: "Indiana University of Pennsylvania" },
            { id: 10687, name: "Indiana Wesleyan University" },
            { id: 11260, name: "Iona College" },
            { id: 10713, name: "Iowa State University" },
            { id: 4986, name: "Isfahan University of Medical Sciences" },
            { id: 4984, name: "Isfahan University of Technology" },
            { id: 8903, name: "Islamic University of Madinah" },
            { id: 11036, name: "Jackson State University" },
            { id: 10209, name: "Jacksonville State University" },
            { id: 10488, name: "Jacksonville University" },
            { id: 11846, name: "James Madison University" },
            { id: 4164, name: "Jawaharlal Nehru Technological University" },
            { id: 1727, name: "Jiangxi University of Finance and Economics" },
            { id: 18610, name: "John Jay College of Criminal Justice" },
            { id: 11366, name: "Johnson C. Smith University" },
            { id: 11626, name: "Johnson and Wales University" },
            { id: 10975, name: "Kalamazoo College" },
            { id: 5829, name: "Kansai Gaidai University" },
            { id: 10747, name: "Kansas State University" },
            { id: 9452, name: "Kasetsart University" },
            { id: 11164, name: "Kean University" },
            { id: 11142, name: "Keene State College" },
            { id: 10532, name: "Kennesaw State University" },
            { id: 11402, name: "Kent State University" },
            { id: 10782, name: "Kentucky State University" },
            { id: 10968, name: "Kettering University" },
            { id: 9983, name: "Khalifa University" },
            { id: 11694, name: "King University" },
            { id: 10077, name: "Kingston University" },
            { id: 6332, name: "Korea National University of Transportation" },
            { id: 3637, name: "Kurukshetra University" },
            { id: 11741, name: "Lamar University" },
            { id: 11487, name: "Langston University" },
            { id: 443, name: "Lauder Business School" },
            { id: 11775, name: "LeTourneau University" },
            { id: 10900, name: "Lesley University" },
            { id: 3111, name: "Leuphana Universit\u00e4t L\u00fcneburg" },
            { id: 10624, name: "Lewis University" },
            { id: 5327, name: "Libera Universit\u00e0 di Lingue e Comunicazione IULM" },
            { id: 11845, name: "Liberty University" },
            { id: 8290, name: "Liceo de Cagayan University" },
            { id: 10551, name: "Life University" },
            { id: 18392, name: "Lindenwood University" },
            { id: 11381, name: "Livingstone College" },
            { id: 11855, name: "Longwood University" },
            { id: 10800, name: "Louisiana State University" },
            { id: 10805, name: "Louisiana Tech University" },
            { id: 10602, name: "Loyola University Chicago" },
            { id: 10802, name: "Loyola University New Orleans" },
            { id: 2976, name: "Ludwig-Maximilians-Universit\u00e4t M\u00fcnchen" },
            { id: 10719, name: "Luther College" },
            { id: 11002, name: "Macalester College" },
            { id: 10984, name: "Madonna University" },
            { id: 11930, name: "Marquette University" },
            { id: 11909, name: "Marshall University" },
            { id: 11876, name: "Mary Baldwin University" },
            { id: 10871, name: "Massachusetts Institute of Technology" },
            { id: 10930, name: "Massachusetts Maritime Academy" },
            { id: 10536, name: "Mercer University" },
            { id: 11252, name: "Mercy College" },
            { id: 11020, name: "Metropolitan State University" },
            { id: 10411, name: "Metropolitan State University of Denver" },
            { id: 10477, name: "Miami Dade College" },
            { id: 11406, name: "Miami University" },
            { id: 10952, name: "Michigan State University" },
            { id: 10556, name: "Middle Georgia State University" },
            { id: 11681, name: "Middle Tennessee State University" },
            { id: 470, name: "Milli Aviasiya Akademiyasi" },
            { id: 11001, name: "Minnesota State University, Mankato" },
            { id: 11032, name: "Mississippi State University" },
            { id: 11044, name: "Mississippi Valley State University" },
            { id: 11071, name: "Missouri Southern State University" },
            { id: 11055, name: "Missouri University of Science and Technology" },
            { id: 11021, name: "Mitchell Hamline School of Law" },
            { id: 11156, name: "Monmouth University" },
            { id: 18607, name: "Monroe College" },
            { id: 11155, name: "Montclair State University" },
            { id: 10781, name: "Morehead State University" },
            { id: 10540, name: "Morehouse College" },
            { id: 11431, name: "Muskingum University" },
            { id: 6295, name: "Myongji University" },
            { id: 1693, name: "Nanjing University" },
            { id: 1914, name: "Nankai University" },
            { id: 11672, name: "National American University" },
            { id: 17785, name: "National Defense University" },
            { id: 4114, name: "National Institute of Technology, Tiruchirappalli" },
            { id: 309, name: "National Polytechnic University of Armenia" },
            { id: 17643, name: "National University" },
            { id: 10309, name: "Naval Postgraduate School" },
            { id: 11265, name: "Nazareth College" },
            { id: 11182, name: "New Mexico Highlands University" },
            { id: 11236, name: "New York Institute of Technology" },
            { id: 18515, name: "New York University" },
            { id: 11348, name: "North Carolina Agricultural and Technical State University" },
            { id: 11349, name: "North Carolina Central University" },
            { id: 11338, name: "North Carolina State University" },
            { id: 11363, name: "North Carolina Wesleyan College" },
            { id: 11390, name: "North Dakota State University" },
            { id: 523, name: "North South University" },
            { id: 10876, name: "Northeastern University" },
            { id: 10236, name: "Northern Arizona University" },
            { id: 10777, name: "Northern Kentucky University" },
            { id: 10963, name: "Northern Michigan University" },
            { id: 11828, name: "Norwich University" },
            { id: 10471, name: "Nova Southeastern University" },
            { id: 10549, name: "Oglethorpe University" },
            { id: 11404, name: "Ohio University" },
            { id: 11422, name: "Ohio Wesleyan University" },
            { id: 11470, name: "Oklahoma State University" },
            { id: 11847, name: "Old Dominion University" },
            { id: 11493, name: "Oregon State University" },
            { id: 4161, name: "Osmania University" },
            { id: 18527, name: "Pace University" },
            { id: 10505, name: "Palm Beach Atlantic University" },
            { id: 10492, name: "Palm Beach State College" },
            { id: 3541, name: "Panjab University" },
            { id: 11064, name: "Park University" },
            { id: 11613, name: "Peirce College" },
            { id: 1374, name: "Peking University" },
            { id: 11516, name: "Penn State University" },
            { id: 10286, name: "Pepperdine University" },
            { id: 8245, name: "Philippine School of Business Administration" },
            { id: 10561, name: "Piedmont College" },
            { id: 5319, name: "Politecnico di Milano" },
            { id: 10506, name: "Polk State College" },
            { id: 11495, name: "Portland State University" },
            { id: 4006, name: "Punjab Technical University" },
            { id: 10669, name: "Purdue University" },
            { id: 10064, name: "Queen Mary University of London" },
            { id: 10427, name: "Quinnipiac University" },
            { id: 11850, name: "Radford University" },
            { id: 11167, name: "Ramapo College of New Jersey" },
            { id: 18326, name: "Rasmussen College" },
            { id: 10412, name: "Regis University" },
            { id: 1375, name: "Renmin University of China" },
            { id: 11194, name: "Rensselaer Polytechnic Institute" },
            { id: 3138, name: "Rheinisch-Westf\u00e4lische Technische Hochschule Aachen" },
            { id: 11687, name: "Rhodes College" },
            { id: 10094, name: "Richmond, The American International University in London" },
            { id: 18896, name: "Robert Morris University" },
            { id: 11192, name: "Rochester Institute of Technology" },
            { id: 11104, name: "Rocky Mountain College" },
            { id: 11628, name: "Roger Williams University" },
            { id: 10481, name: "Rollins College" },
            { id: 10617, name: "Roosevelt University" },
            { id: 11245, name: "SUNY Polytechnic Institute" },
            { id: 10972, name: "Saginaw Valley State University" },
            { id: 11536, name: "Saint Joseph's University" },
            { id: 18379, name: "Saint Louis University" },
            { id: 11900, name: "Saint Martin's University" },
            { id: 10693, name: "Saint Mary's College" },
            { id: 11018, name: "Saint Mary's University of Minnesota" },
            { id: 11168, name: "Saint Peter's University" },
            { id: 10903, name: "Salem State University" },
            { id: 10854, name: "Salisbury University" },
            { id: 10277, name: "San Diego State University" },
            { id: 10280, name: "San Francisco State University" },
            { id: 10487, name: "Santa Fe College" },
            { id: 5306, name: "Sapienza Universit\u00e0 di Roma" },
            { id: 10533, name: "Savannah College of Art and Design" },
            { id: 10555, name: "Savannah State University" },
            { id: 3869, name: "Savitribai Phule Pune University" },
            { id: 18536, name: "School of Visual Arts" },
            { id: 10614, name: "School of the Art Institute of Chicago" },
            { id: 11891, name: "Seattle Pacific University" },
            { id: 11886, name: "Seattle University" },
            { id: 1855, name: "Shanghai Normal University" },
            { id: 5128, name: "Sharif University of Technology" },
            { id: 10193, name: "Sheffield Hallam University" },
            { id: 11910, name: "Shepherd University" },
            { id: 11535, name: "Shippensburg University of Pennsylvania" },
            { id: 3878, name: "Shivaji University" },
            { id: 10987, name: "Siena Heights University" },
            { id: 9459, name: "Silpakorn University" },
            { id: 10726, name: "Simpson College" },
            { id: 11220, name: "Skidmore College" },
            { id: 5934, name: "Sophia University" },
            { id: 2719, name: "Sorbonne Universit\u00e9" },
            { id: 11643, name: "South Carolina State University" },
            { id: 11666, name: "South Dakota State University" },
            { id: 10807, name: "Southeastern Louisiana University" },
            { id: 10497, name: "Southeastern University" },
            { id: 10605, name: "Southern Illinois University Edwardsville" },
            { id: 11729, name: "Southern Methodist University" },
            { id: 11139, name: "Southern New Hampshire University" },
            { id: 11820, name: "Southern Utah University" },
            { id: 11083, name: "Southwest Baptist University" },
            { id: 10541, name: "Spelman College" },
            { id: 10977, name: "Spring Arbor University" },
            { id: 4149, name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya" },
            { id: 3469, name: "Sri Krishnadevaraya University" },
            { id: 10722, name: "St. Ambrose University" },
            { id: 11241, name: "St. Bonaventure University" },
            { id: 21940, name: "St. Bonaventure University" },
            { id: 11008, name: "St. Catherine University" },
            { id: 18332, name: "St. Cloud State University" },
            { id: 11305, name: "St. Francis College" },
            { id: 11215, name: "St. John's University" },
            { id: 11757, name: "St. Mary's University" },
            { id: 11945, name: "St. Norbert College" },
            { id: 11003, name: "St. Olaf College" },
            { id: 10265, name: "Stanford University" },
            { id: 11228, name: "State University of New York at New Paltz" },
            { id: 11748, name: "Stephen F. Austin State University" },
            { id: 11072, name: "Stephens College" },
            { id: 11163, name: "Stockton University" },
            { id: 11195, name: "Stony Brook University" },
            { id: 10457, name: "Strayer University" },
            { id: 10890, name: "Suffolk University" },
            { id: 2560, name: "Tallinna Tehnika\u00fclikool" },
            { id: 5263, name: "Technion - Israel Institute of Technology" },
            { id: 22937, name: "Technische Hochschule N\u00fcrnberg Georg Simon Ohm" },
            { id: 7444, name: "Technische Universiteit Eindhoven" },
            { id: 3021, name: "Technische Universit\u00e4t Berlin" },
            { id: 2975, name: "Technische Universit\u00e4t M\u00fcnchen" },
            { id: 423, name: "Technische Universit\u00e4t Wien" },
            { id: 11519, name: "Temple University" },
            { id: 11725, name: "Texas A&M University" },
            { id: 11756, name: "Texas A&M University-Commerce" },
            { id: 11747, name: "Texas A&M University-Corpus Christi" },
            { id: 11759, name: "Texas Southern University" },
            { id: 11736, name: "Texas State University" },
            { id: 11731, name: "Texas Tech University" },
            { id: 11783, name: "Texas Wesleyan University" },
            { id: 11752, name: "Texas Woman's University" },
            { id: 16421, name: "The American University in Cairo" },
            { id: 2748, name: "The American University of Paris" },
            { id: 10455, name: "The Catholic University of America" },
            { id: 18609, name: "The City College of New York" },
            { id: 11013, name: "The College of St. Scholastica" },
            { id: 18523, name: "The New School" },
            { id: 11399, name: "The Ohio State University" },
            { id: 10201, name: "The University of Alabama" },
            { id: 10120, name: "The University of Edinburgh" },
            { id: 2888, name: "The University of Georgia" },
            { id: 10195, name: "The University of Hull" },
            { id: 11680, name: "The University of Memphis" },
            { id: 11684, name: "The University of Tennessee at Chattanooga" },
            { id: 11734, name: "The University of Texas at Arlington" },
            { id: 11724, name: "The University of Texas at Austin" },
            { id: 11733, name: "The University of Texas at San Antonio" },
            { id: 11172, name: "Thomas Edison State University" },
            { id: 10790, name: "Thomas More University" },
            { id: 10700, name: "Trine University" },
            { id: 10430, name: "Trinity College" },
            { id: 8238, name: "Trinity University of Asia" },
            { id: 10206, name: "Troy University" },
            { id: 1373, name: "Tsinghua University" },
            { id: 10874, name: "Tufts University" },
            { id: 10801, name: "Tulane University" },
            { id: 10207, name: "Tuskegee University" },
            { id: 2739, name: "T\u00e9l\u00e9com Paris" },
            { id: 11223, name: "Union College" },
            { id: 10405, name: "United States Air Force Academy" },
            { id: 11254, name: "United States Merchant Marine Academy" },
            { id: 10849, name: "United States Naval Academy" },
            { id: 10222, name: "United States Sports Academy" },
            { id: 7212, name: "Universidad Aut\u00f3noma de Yucat\u00e1n" },
            { id: 256, name: "Universidad Blas Pascal" },
            { id: 9137, name: "Universidad Carlos III de Madrid" },
            { id: 2032, name: "Universidad Externado de Colombia" },
            { id: 3366, name: "Universidad Rafael Land\u00edvar" },
            { id: 221, name: "Universidad Torcuato di Tella" },
            { id: 222, name: "Universidad de Belgrano" },
            { id: 1320, name: "Universidad de Chile" },
            { id: 2450, name: "Universidad de Guayaquil" },
            { id: 9151, name: "Universidad de Navarra" },
            { id: 9115, name: "Universidad de Salamanca" },
            { id: 2054, name: "Universidad de San Buenaventura" },
            { id: 3365, name: "Universidad de San Carlos de Guatemala" },
            { id: 8029, name: "Universidad de San Mart\u00edn de Porres" },
            { id: 211, name: "Universidad del Este" },
            { id: 3370, name: "Universidad del Valle de Guatemala" },
            { id: 1032, name: "Universidade Anhembi Morumbi" },
            { id: 1019, name: "Universidade Estadual de Campinas" },
            { id: 863, name: "Universidade Federal de Alagoas" },
            { id: 882, name: "Universidade Federal do Cear\u00e1" },
            { id: 1029, name: "Universidade Metodista de S\u00e3o Paulo" },
            { id: 701, name: "Universiteit Gent" },
            { id: 7442, name: "Universiteit Maastricht" },
            { id: 7477, name: "Universiteit Utrecht" },
            { id: 87, name: "Universiteti i Tiran\u00ebs" },
            { id: 10203, name: "University of Alabama at Birmingham" },
            { id: 10242, name: "University of Arkansas" },
            { id: 7483, name: "University of Auckland" },
            { id: 10848, name: "University of Baltimore" },
            { id: 10197, name: "University of Bradford" },
            { id: 10266, name: "University of California, Berkeley" },
            { id: 10270, name: "University of California, Davis" },
            { id: 10271, name: "University of California, Irvine" },
            { id: 10267, name: "University of California, Los Angeles" },
            { id: 10274, name: "University of California, Riverside" },
            { id: 10273, name: "University of California, Santa Barbara" },
            { id: 10275, name: "University of California, Santa Cruz" },
            { id: 10048, name: "University of Cambridge" },
            { id: 9086, name: "University of Cape Town" },
            { id: 10467, name: "University of Central Florida" },
            { id: 11062, name: "University of Central Missouri" },
            { id: 10595, name: "University of Chicago" },
            { id: 11400, name: "University of Cincinnati" },
            { id: 10402, name: "University of Colorado Boulder" },
            { id: 10407, name: "University of Colorado Colorado Springs" },
            { id: 10426, name: "University of Connecticut" },
            { id: 11405, name: "University of Dayton" },
            { id: 10404, name: "University of Denver" },
            { id: 10969, name: "University of Detroit Mercy" },
            { id: 10729, name: "University of Dubuque" },
            { id: 10079, name: "University of East London" },
            { id: 10464, name: "University of Florida" },
            { id: 10529, name: "University of Georgia" },
            { id: 10433, name: "University of Hartford" },
            { id: 11727, name: "University of Houston" },
            { id: 11760, name: "University of Houston-Downtown" },
            { id: 10596, name: "University of Illinois at Urbana-Champaign" },
            { id: 10686, name: "University of Indianapolis" },
            { id: 10714, name: "University of Iowa" },
            { id: 4985, name: "University of Isfahan" },
            { id: 11396, name: "University of Jamestown" },
            { id: 10772, name: "University of Kentucky" },
            { id: 7610, name: "University of Lagos" },
            { id: 10190, name: "University of Leeds" },
            { id: 10042, name: "University of Leicester" },
            { id: 5256, name: "University of Limerick" },
            { id: 10054, name: "University of Lincoln" },
            { id: 10810, name: "University of Louisiana at Monroe" },
            { id: 10773, name: "University of Louisville" },
            { id: 11391, name: "University of Mary" },
            { id: 10875, name: "University of Massachusetts Amherst" },
            { id: 10882, name: "University of Massachusetts Boston" },
            { id: 10469, name: "University of Miami" },
            { id: 10951, name: "University of Michigan" },
            { id: 10958, name: "University of Michigan-Dearborn" },
            { id: 10997, name: "University of Minnesota-Twin Cities" },
            { id: 11033, name: "University of Mississippi" },
            { id: 11051, name: "University of Missouri-Kansas City" },
            { id: 10217, name: "University of Montevallo" },
            { id: 3872, name: "University of Mumbai" },
            { id: 1081, name: "University of National and World Economy" },
            { id: 11129, name: "University of Nevada, Reno" },
            { id: 10804, name: "University of New Orleans" },
            { id: 2311, name: "University of Nicosia" },
            { id: 11357, name: "University of North Carolina School of the Arts" },
            { id: 11352, name: "University of North Carolina at Asheville" },
            { id: 11336, name: "University of North Carolina at Chapel Hill" },
            { id: 11342, name: "University of North Carolina at Charlotte" },
            { id: 11341, name: "University of North Carolina at Greensboro" },
            { id: 11389, name: "University of North Dakota" },
            { id: 10545, name: "University of North Georgia" },
            { id: 11728, name: "University of North Texas" },
            { id: 10409, name: "University of Northern Colorado" },
            { id: 11023, name: "University of Northwestern - St. Paul" },
            { id: 10671, name: "University of Notre Dame" },
            { id: 10138, name: "University of Oxford" },
            { id: 11515, name: "University of Pennsylvania" },
            { id: 11518, name: "University of Pittsburgh" },
            { id: 10144, name: "University of Portsmouth" },
            { id: 11893, name: "University of Puget Sound" },
            { id: 11848, name: "University of Richmond" },
            { id: 11189, name: "University of Rochester" },
            { id: 8155, name: "University of San Carlos" },
            { id: 10285, name: "University of San Francisco" },
            { id: 10208, name: "University of South Alabama" },
            { id: 11633, name: "University of South Carolina" },
            { id: 10466, name: "University of South Florida" },
            { id: 10268, name: "University of Southern California" },
            { id: 10830, name: "University of Southern Maine" },
            { id: 20355, name: "University of St Andrews" },
            { id: 11776, name: "University of St. Thomas" },
            { id: 11000, name: "University of St. Thomas" },
            { id: 10127, name: "University of Stirling" },
            { id: 10142, name: "University of Surrey" },
            { id: 10141, name: "University of Sussex" },
            { id: 11410, name: "University of Toledo" },
            { id: 11840, name: "University of Virginia" },
            { id: 11882, name: "University of Washington" },
            { id: 10537, name: "University of West Georgia" },
            { id: 11931, name: "University of Wisconsin-Eau Claire" },
            { id: 11936, name: "University of Wisconsin-La Crosse" },
            { id: 11928, name: "University of Wisconsin-Madison" },
            { id: 11929, name: "University of Wisconsin-Milwaukee" },
            { id: 11947, name: "University of Wisconsin-Parkside" },
            { id: 11944, name: "University of Wisconsin-River Falls" },
            { id: 11938, name: "University of Wisconsin-Stout" },
            { id: 10187, name: "University of Worcester" },
            { id: 356, name: "University of the Sunshine Coast" },
            { id: 5323, name: "Universit\u00e0 Cattolica del Sacro Cuore" },
            { id: 5320, name: "Universit\u00e0 degli Studi di Milano" },
            { id: 5303, name: "Universit\u00e0 degli Studi di Trieste" },
            { id: 2918, name: "Universit\u00e4t Stuttgart" },
            { id: 2920, name: "Universit\u00e4t Ulm" },
            { id: 422, name: "Universit\u00e4t Wien" },
            { id: 2990, name: "Universit\u00e4t der Bundeswehr M\u00fcnchen" },
            { id: 3023, name: "Universit\u00e4t der K\u00fcnste Berlin" },
            { id: 2706, name: "Universit\u00e9 Catholique de Lille" },
            { id: 684, name: "Universit\u00e9 Libre de Bruxelles" },
            { id: 8958, name: "Univerzitet u Beogradu" },
            { id: 8339, name: "Uniwersytet Ekonomiczny w Krakowie" },
            { id: 15451, name: "Uniwersytet Ekonomiczny w Poznaniu" },
            { id: 9231, name: "Uppsala Universitet" },
            { id: 11817, name: "Utah State University" },
            { id: 11819, name: "Utah Valley University" },
            { id: 10539, name: "Valdosta State University" },
            { id: 10475, name: "Valencia College" },
            { id: 11677, name: "Vanderbilt University" },
            { id: 11312, name: "Vaughn College of Aeronautics and Technology" },
            { id: 11831, name: "Vermont Law School" },
            { id: 11523, name: "Villanova University" },
            { id: 10696, name: "Vincennes University" },
            { id: 11854, name: "Virginia Military Institute" },
            { id: 11872, name: "Virginia Union University" },
            { id: 11339, name: "Wake Forest University" },
            { id: 10985, name: "Walsh College" },
            { id: 5932, name: "Waseda University" },
            { id: 11883, name: "Washington State University" },
            { id: 18376, name: "Washington University in St. Louis" },
            { id: 11849, name: "Washington and Lee University" },
            { id: 11784, name: "Wayland Baptist University" },
            { id: 10954, name: "Wayne State University" },
            { id: 11818, name: "Weber State University" },
            { id: 18383, name: "Webster University" },
            { id: 10428, name: "Wesleyan University" },
            { id: 11911, name: "West Virginia State University" },
            { id: 11908, name: "West Virginia University" },
            { id: 10418, name: "Western Colorado University" },
            { id: 10774, name: "Western Kentucky University" },
            { id: 10955, name: "Western Michigan University" },
            { id: 11884, name: "Western Washington University" },
            { id: 11822, name: "Westminster College" },
            { id: 10609, name: "Wheaton College" },
            { id: 11537, name: "Widener University" },
            { id: 11455, name: "Wilberforce University" },
            { id: 11548, name: "Wilkes University" },
            { id: 11085, name: "William Woods University" },
            { id: 10881, name: "Williams College" },
            { id: 11014, name: "Winona State University" },
            { id: 11638, name: "Winthrop University" },
            { id: 424, name: "Wirtschaftsuniversit\u00e4t Wien" },
            { id: 11642, name: "Wofford College" },
            { id: 10345, name: "Woodbury University" },
            { id: 10879, name: "Worcester Polytechnic Institute" },
            { id: 11408, name: "Wright State University" },
            { id: 11413, name: "Xavier University" },
            { id: 10425, name: "Yale University" },
            { id: 1712, name: "Yangzhou University" },
            { id: 6368, name: "Yonsei University" },
            { id: 1253, name: "York University" },
            { id: 10573, name: "Young Harris College" },
            { id: 1581, name: "Zhengzhou University" },
            { id: 2700, name: "\u00c9cole des Hautes \u00c9tudes Commerciales du Nord" },
            { id: 10130, name: "Abertay University" },
            { id: 376, name: "Alpen-Adria-Universit\u00e4t Klagenfurt" },
            { id: 6524, name: "American University of Beirut" },
            { id: 3265, name: "Bauhaus-Universit\u00e4t Weimar" },
            { id: 3434, name: "Budapesti Gazdas\u00e1gi Egyetem" },
            { id: 3252, name: "Christian-Albrechts-Universit\u00e4t zu Kiel" },
            { id: 10044, name: "De Montfort University" },
            { id: 380, name: "Donau-Universit\u00e4t Krems" },
            { id: 3096, name: "EBS Universit\u00e4t f\u00fcr Wirtschaft und Recht" },
            { id: 2916, name: "Eberhard Karls Universit\u00e4t T\u00fcbingen" },
            { id: 3163, name: "FOM Hochschule f\u00fcr Oekonomie und Management" },
            { id: 3155, name: "Fachhochschule M\u00fcnster" },
            { id: 445, name: "Fachhochschule Vorarlberg" },
            { id: 3256, name: "Fachhochschule Wedel" },
            { id: 3089, name: "Frankfurt University of Applied Sciences" },
            { id: 2977, name: "Friedrich-Alexander-Universit\u00e4t Erlangen-N\u00fcrnberg" },
            { id: 3263, name: "Friedrich-Schiller-Universit\u00e4t Jena" },
            { id: 21786, name: "Gautam Buddha University" },
            { id: 3107, name: "Georg-August-Universit\u00e4t G\u00f6ttingen" },
            { id: 3143, name: "Heinrich-Heine-Universit\u00e4t D\u00fcsseldorf" },
            { id: 2960, name: "Hochschule Albstadt-Sigmaringen" },
            { id: 2926, name: "Hochschule Karlsruhe" },
            { id: 2928, name: "Hochschule Offenburg" },
            { id: 2930, name: "Hochschule Pforzheim" },
            { id: 3095, name: "Hochschule RheinMain" },
            { id: 3134, name: "Hochschule Wismar" },
            { id: 3123, name: "Hochschule f\u00fcr Bildende K\u00fcnste Braunschweig" },
            { id: 3028, name: "Hochschule f\u00fcr Wirtschaft und Recht Berlin" },
            { id: 2987, name: "Hochschule f\u00fcr angewandte Wissenschaften W\u00fcrzburg-Schweinfurt" },
            { id: 7455, name: "Hogeschool van Amsterdam" },
            { id: 7434, name: "Hogeschool van Arnhem en Nijmegen" },
            { id: 9216, name: "H\u00f6gskolan Kristianstad" },
            { id: 10645, name: "Illinois College" },
            { id: 2978, name: "Julius-Maximilians-Universit\u00e4t W\u00fcrzburg" },
            { id: 3088, name: "Justus-Liebig-Universit\u00e4t Giessen" },
            { id: 10063, name: "King's College London" },
            { id: 6592, name: "Kolpingo kolegija" },
            { id: 5062, name: "Mazandaran University of Science and Technology" },
            { id: 426, name: "Medizinische Universit\u00e4t Wien" },
            { id: 8670, name: "Mendeleev University of Chemical Technology of Russia" },
            { id: 10067, name: "Middlesex University" },
            { id: 9872, name: "National Aviation University" },
            { id: 2992, name: "Ostbayerische Technische Hochschule Regensburg" },
            { id: 2980, name: "Otto-Friedrich-Universit\u00e4t Bamberg" },
            { id: 3085, name: "Philipps-Universit\u00e4t Marburg" },
            { id: 2938, name: "P\u00e4dagogische Hochschule Karlsruhe" },
            { id: 23133, name: "Rheinische Fachhochschule K\u00f6ln" },
            { id: 3140, name: "Rheinische Friedrich-Wilhelms-Universit\u00e4t Bonn" },
            { id: 3139, name: "Ruhr-Universit\u00e4t Bochum" },
            { id: 2914, name: "Ruprecht-Karls-Universit\u00e4t Heidelberg" },
            { id: 2945, name: "SRH Hochschule Heidelberg" },
            { id: 9218, name: "Stockholms universitet" },
            { id: 366, name: "Swinburne University of Technology" },
            { id: 2561, name: "Tallinna \u00dclikool" },
            { id: 3086, name: "Technische Universit\u00e4t Darmstadt" },
            { id: 3224, name: "Technische Universit\u00e4t Dresden" },
            { id: 3264, name: "Technische Universit\u00e4t Ilmenau" },
            { id: 3203, name: "Technische Universit\u00e4t Kaiserslautern" },
            { id: 7003, name: "Tecnol\u00f3gico de Monterrey" },
            { id: 2043, name: "Universidad Militar Nueva Granada" },
            { id: 1987, name: "Universidad Pontificia Bolivariana" },
            { id: 7947, name: "Universidad San Ignacio de Loyola" },
            { id: 8030, name: "Universidad San Ignacio de Loyola" },
            { id: 20006, name: "Universidad Sim\u00f3n Bol\u00edvar" },
            { id: 9098, name: "Universidad de C\u00f3rdoba" },
            { id: 12077, name: "Universidad de La Laguna" },
            { id: 2122, name: "Universidad de La Sabana" },
            { id: 9095, name: "Universidad de Sevilla" },
            { id: 1323, name: "Universidad del Desarrollo" },
            { id: 8530, name: "Universitatea Politehnica din Timisoara" },
            { id: 7233, name: "Universitatea Tehnica a Moldovei" },
            { id: 8529, name: "Universitatea de Vest din Timisoara" },
            { id: 8511, name: "Universitatea din Craiova" },
            { id: 7460, name: "Universiteit Twente" },
            { id: 10291, name: "University of San Diego" },
            { id: 10159, name: "University of the West of England" },
            { id: 5364, name: "Universit\u00e0 Ca' Foscari di Venezia" },
            { id: 5363, name: "Universit\u00e0 degli Studi di Padova" },
            { id: 2982, name: "Universit\u00e4t Augsburg" },
            { id: 2981, name: "Universit\u00e4t Bayreuth" },
            { id: 3266, name: "Universit\u00e4t Erfurt" },
            { id: 3067, name: "Universit\u00e4t Hamburg" },
            { id: 2922, name: "Universit\u00e4t Hohenheim" },
            { id: 2921, name: "Universit\u00e4t Konstanz" },
            { id: 3225, name: "Universit\u00e4t Leipzig" },
            { id: 2919, name: "Universit\u00e4t Mannheim" },
            { id: 2985, name: "Universit\u00e4t Passau" },
            { id: 3132, name: "Universit\u00e4t Rostock" },
            { id: 3158, name: "Universit\u00e4t Witten/Herdecke" },
            { id: 2633, name: "Universit\u00e9 Lumi\u00e8re Lyon 2" },
            { id: 2967, name: "VWA-Hochschule f\u00fcr berufsbegleitendes Studium" },
            { id: 3741, name: "Visvesvaraya Technological University" },
            { id: 3141, name: "Westf\u00e4lische Wilhelms-Universit\u00e4t M\u00fcnster" },
        ],
        languages: [
            { id: 9, name: "Afrikaans" },
            { id: 14, name: "Albanian" },
            { id: 20, name: "Amharic" },
            { id: 23, name: "Arabic" },
            { id: 58, name: "Bengali" },
            { id: 70, name: "Bokm\u00e5l, Norwegian" },
            { id: 71, name: "Bosnian" },
            { id: 80, name: "Catalan" },
            { id: 95, name: "Chinese" },
            { id: 113, name: "Creoles and pidgins" },
            { id: 121, name: "Czech" },
            { id: 136, name: "Dutch" },
            { id: 146, name: "English" },
            { id: 159, name: "Finnish" },
            { id: 163, name: "French" },
            { id: 177, name: "German" },
            { id: 189, name: "Greek, Modern (1453-)" },
            { id: 191, name: "Guarani" },
            { id: 192, name: "Gujarati" },
            { id: 196, name: "Haitian Creole" },
            { id: 199, name: "Hebrew" },
            { id: 203, name: "Hindi" },
            { id: 207, name: "Hungarian" },
            { id: 212, name: "Igbo" },
            { id: 225, name: "Iranian languages" },
            { id: 230, name: "Italian" },
            { id: 231, name: "Japanese" },
            { id: 242, name: "Kannada" },
            { id: 266, name: "Korean" },
            { id: 281, name: "Lao" },
            { id: 282, name: "Latin" },
            { id: 312, name: "Malay" },
            { id: 313, name: "Malayalam" },
            { id: 325, name: "Marathi" },
            { id: 358, name: "Nepali" },
            { id: 372, name: "Norwegian" },
            { id: 405, name: "Panjabi" },
            { id: 410, name: "Persian" },
            { id: 416, name: "Polish" },
            { id: 417, name: "Portuguese" },
            { id: 428, name: "Romanian" },
            { id: 432, name: "Russian" },
            { id: 450, name: "Serbian" },
            { id: 467, name: "Slovak" },
            { id: 470, name: "Somali" },
            { id: 480, name: "Spanish" },
            { id: 489, name: "Swedish" },
            { id: 492, name: "Tagalog" },
            { id: 495, name: "Tajik" },
            { id: 497, name: "Tamil" },
            { id: 499, name: "Telugu" },
            { id: 502, name: "Thai" },
            { id: 512, name: "Tonga (Nyasa)" },
            { id: 513, name: "Tonga (Tonga Islands)" },
            { id: 519, name: "Turkish" },
            { id: 528, name: "Ukrainian" },
            { id: 533, name: "Urdu" },
            { id: 539, name: "Vietnamese" },
            { id: 551, name: "Wolof" },
            { id: 52, name: "Basque" },
            { id: 119, name: "Croatian" },
            { id: 123, name: "Danish" },
            { id: 219, name: "Indonesian" },
            { id: 373, name: "Norwegian Bokm\u00e5l" },
            { id: 490, name: "Swiss German" },
        ],
        cities: [
            { id: 1288001666, name: "Accra" },
            { id: 1840013688, name: "Acworth" },
            { id: 1840003729, name: "Addison" },
            { id: 1840003226, name: "Adrian" },
            { id: 1840019221, name: "Agoura Hills" },
            { id: 1276256136, name: "Ahlen" },
            { id: 1840000417, name: "Albany" },
            { id: 1840026377, name: "Albrightsville" },
            { id: 1724923163, name: "Alicante" },
            { id: 1840019321, name: "Aliso Viejo" },
            { id: 1840004256, name: "Allen" },
            { id: 1840003967, name: "Allen Park" },
            { id: 1840001044, name: "Allentown" },
            { id: 1840013659, name: "Alpharetta" },
            { id: 1840018366, name: "Anacortes" },
            { id: 1840007066, name: "Ankeny" },
            { id: 1840003172, name: "Ann Arbor" },
            { id: 1756770881, name: "Appenzell" },
            { id: 1840002413, name: "Arlington" },
            { id: 1276069907, name: "Aschaffenburg" },
            { id: 1840013411, name: "Asheville" },
            { id: 1840002274, name: "Astoria" },
            { id: 1840000510, name: "Athens" },
            { id: 1840004026, name: "Atlanta" },
            { id: 1840005335, name: "Atlantic Beach" },
            { id: 1840015535, name: "Aubrey" },
            { id: 1840000319, name: "Auburn" },
            { id: 1276314100, name: "Augsburg" },
            { id: 1840000302, name: "Augusta" },
            { id: 1840000806, name: "Aurora" },
            { id: 1840013689, name: "Austell" },
            { id: 1840003306, name: "Austin" },
            { id: 1840013702, name: "Avondale Estates" },
            { id: 1840019423, name: "Azle" },
            { id: 1276964772, name: "Bad Krozingen" },
            { id: 1840001592, name: "Baltimore" },
            { id: 1356410365, name: "Bangalore" },
            { id: 1724594040, name: "Barcelona" },
            { id: 1840000385, name: "Batavia" },
            { id: 1840013941, name: "Baton Rouge" },
            { id: 1840013818, name: "Beaufort" },
            { id: 1840000723, name: "Belleville" },
            { id: 1840000308, name: "Bellevue" },
            { id: 1840005232, name: "Bellmore" },
            { id: 1276451290, name: "Berlin" },
            { id: 1840007451, name: "Beverly Hills" },
            { id: 1276112885, name: "Bielefeld" },
            { id: 1840019119, name: "Big Bear Lake" },
            { id: 1840001926, name: "Bismarck" },
            { id: 1840000415, name: "Bloomfield" },
            { id: 1840002439, name: "Bloomfield Hills" },
            { id: 1840002970, name: "Bloomington" },
            { id: 1840027142, name: "Boise" },
            { id: 1276807850, name: "Bonn" },
            { id: 1840018454, name: "Bonney Lake" },
            { id: 1840013790, name: "Bossier City" },
            { id: 1840000455, name: "Boston" },
            { id: 1840017449, name: "Boulder" },
            { id: 1840018737, name: "Bountiful" },
            { id: 1276880866, name: "Breisach am Rhein" },
            { id: 1840018410, name: "Bremerton" },
            { id: 1840081606, name: "Brick" },
            { id: 1840003099, name: "Brighton" },
            { id: 1840033999, name: "Bronx" },
            { id: 1840004949, name: "Bronxville" },
            { id: 1840000592, name: "Brooklyn" },
            { id: 1840018781, name: "Broomfield" },
            { id: 1276790584, name: "Buggingen" },
            { id: 1840007022, name: "Burbank" },
            { id: 1840000046, name: "Burlington" },
            { id: 1840006115, name: "Burnsville" },
            { id: 1076562452, name: "Caatiba" },
            { id: 1840003642, name: "California" },
            { id: 1840092506, name: "Camp Washington" },
            { id: 1840000512, name: "Canton" },
            { id: 1840010473, name: "Carleton" },
            { id: 1840004806, name: "Carrollton" },
            { id: 1840000201, name: "Carson" },
            { id: 1840006758, name: "Carver" },
            { id: 1840010109, name: "Cary" },
            { id: 1840001006, name: "Centerville" },
            { id: 1840005028, name: "Central Islip" },
            { id: 1156154785, name: "Changning" },
            { id: 1840016152, name: "Chapel Hill" },
            { id: 1840006196, name: "Charleston" },
            { id: 1840003107, name: "Charlotte" },
            { id: 1840006760, name: "Chaska" },
            { id: 1840000282, name: "Chelsea" },
            { id: 1840000690, name: "Chester" },
            { id: 1840031323, name: "Chevy Chase" },
            { id: 1840000494, name: "Chicago" },
            { id: 1840019115, name: "Chino Hills" },
            { id: 1840057308, name: "Church" },
            { id: 1840003814, name: "Cincinnati" },
            { id: 1840014776, name: "Clarkston" },
            { id: 1840005570, name: "Claymont" },
            { id: 1840006704, name: "Clearwater" },
            { id: 1840006887, name: "Clermont" },
            { id: 1642503974, name: "Cluj-Napoca" },
            { id: 1840018402, name: "Coeur d'Alene" },
            { id: 1840074067, name: "College Hill Station" },
            { id: 1840005971, name: "College Park" },
            { id: 1840019430, name: "Colleyville" },
            { id: 1276015998, name: "Cologne" },
            { id: 1840010999, name: "Columbiaville" },
            { id: 1840001715, name: "Columbus" },
            { id: 1840011429, name: "Compton" },
            { id: 1840067102, name: "Connecticut Avenue Estates" },
            { id: 1840019586, name: "Conroe" },
            { id: 1840001015, name: "Conway" },
            { id: 1840014806, name: "Conyers" },
            { id: 1840019439, name: "Coppell" },
            { id: 1840000264, name: "Corona" },
            { id: 1840019354, name: "Coronado" },
            { id: 1840002910, name: "Cottage Grove" },
            { id: 1840001688, name: "Covington" },
            { id: 1840007091, name: "Crown Point" },
            { id: 1840007103, name: "Cumming" },
            { id: 1840014743, name: "Dacula" },
            { id: 1840014672, name: "Dahlonega" },
            { id: 1840000364, name: "Dallas" },
            { id: 1840003379, name: "Dalton" },
            { id: 1840000854, name: "Danville" },
            { id: 1840000885, name: "Dayton" },
            { id: 1840015068, name: "Daytona Beach" },
            { id: 1840003969, name: "Dearborn" },
            { id: 1840003970, name: "Dearborn Heights" },
            { id: 1840001403, name: "Decatur" },
            { id: 1840015123, name: "Delray Beach" },
            { id: 1840005932, name: "Denton" },
            { id: 1840003714, name: "Denver" },
            { id: 1840003971, name: "Detroit" },
            { id: 1840004129, name: "Dexter" },
            { id: 1276107502, name: "Donaueschingen" },
            { id: 1840014804, name: "Douglasville" },
            { id: 1840003340, name: "Downey" },
            { id: 1840000330, name: "Draper" },
            { id: 1276561036, name: "Dresden" },
            { id: 1840001321, name: "Dublin" },
            { id: 1840007685, name: "Duluth" },
            { id: 1840003541, name: "Dumont" },
            { id: 1840018427, name: "Duvall" },
            { id: 1276615258, name: "D\u00fcsseldorf" },
            { id: 1840001982, name: "Eagle River" },
            { id: 1840089730, name: "East Fayetteville" },
            { id: 1840003698, name: "East Greenville" },
            { id: 1840005236, name: "East Meadow" },
            { id: 1840002043, name: "Eden" },
            { id: 1840007821, name: "Eden Prairie" },
            { id: 1840012021, name: "Edison" },
            { id: 1840016991, name: "Edwards" },
            { id: 1840150306, name: "El Adobe" },
            { id: 1840005226, name: "Elmont" },
            { id: 1276708263, name: "Emmendingen" },
            { id: 1840003717, name: "Ephrata" },
            { id: 1840013168, name: "Erlanger" },
            { id: 1756008843, name: "Eschlikon" },
            { id: 1276811337, name: "Estenfeld" },
            { id: 1276445594, name: "Ettenheim" },
            { id: 1276126657, name: "Ettlingen" },
            { id: 1840007823, name: "Excelsior" },
            { id: 1840023463, name: "Fairbanks" },
            { id: 1840002639, name: "Fairburn" },
            { id: 1840000865, name: "Fairview" },
            { id: 1356078517, name: "Farid\u0101b\u0101d" },
            { id: 1840003686, name: "Farmingdale" },
            { id: 1840002441, name: "Farmington" },
            { id: 1840004320, name: "Fayetteville" },
            { id: 1840019826, name: "Federal Way" },
            { id: 1840002948, name: "Fenton" },
            { id: 1840004748, name: "Fishkill" },
            { id: 1840003973, name: "Flat Rock" },
            { id: 1840002949, name: "Flint" },
            { id: 1840001660, name: "Florence" },
            { id: 1840004867, name: "Florida" },
            { id: 1840121450, name: "Flower" },
            { id: 1840013611, name: "Flowery Branch" },
            { id: 1840003799, name: "Folsom" },
            { id: 1840004636, name: "Forest Lake" },
            { id: 1840014236, name: "Fort Lauderdale" },
            { id: 1840016479, name: "Fort Mill" },
            { id: 1840014226, name: "Fort Myers" },
            { id: 1840014178, name: "Fort Pierce" },
            { id: 1840013923, name: "Fort Walton Beach" },
            { id: 1840020696, name: "Fort Worth" },
            { id: 1276054552, name: "Frankfurt" },
            { id: 1840002748, name: "Franklin" },
            { id: 1840001198, name: "Franklin Park" },
            { id: 1756399413, name: "Frauenfeld" },
            { id: 1276371228, name: "Freiburg im Breisgau" },
            { id: 1840020654, name: "Frisco" },
            { id: 1840004468, name: "Gainesville" },
            { id: 1840008311, name: "Garden Grove" },
            { id: 1840056116, name: "Georgia" },
            { id: 1840019855, name: "Gig Harbor" },
            { id: 1840003037, name: "Glendale" },
            { id: 1276142884, name: "Gottenheim" },
            { id: 1840020749, name: "Granbury" },
            { id: 1840020697, name: "Grapevine" },
            { id: 1840000719, name: "Greensboro" },
            { id: 1840000659, name: "Greenville" },
            { id: 1276215861, name: "Grevenbroich" },
            { id: 1840010823, name: "Griffin" },
            { id: 1840133971, name: "Grosse Ile" },
            { id: 1840003976, name: "Grosse Pointe" },
            { id: 1840003979, name: "Grosse Pointe Woods" },
            { id: 1840013748, name: "Grovetown" },
            { id: 1276398825, name: "Gr\u00f6benzell" },
            { id: 1840013920, name: "Gulf Breeze" },
            { id: 1356406239, name: "G\u016brgaon" },
            { id: 1276905869, name: "Hann\u00f6ver" },
            { id: 1840043425, name: "Hanscom AFB" },
            { id: 1840001115, name: "Hastings" },
            { id: 1840029479, name: "Hauula" },
            { id: 1840029572, name: "Hawaiian Acres" },
            { id: 1840003513, name: "Hawthorne" },
            { id: 1840018034, name: "Heber" },
            { id: 1840000198, name: "Hebron" },
            { id: 1840005293, name: "Hempstead" },
            { id: 1840005936, name: "Henderson" },
            { id: 1276492137, name: "Herbolzheim" },
            { id: 1840020155, name: "Herriman" },
            { id: 1276748088, name: "Hiddenhausen" },
            { id: 1124001665, name: "Hilton" },
            { id: 1840004281, name: "Hilton" },
            { id: 1840003599, name: "Hoboken" },
            { id: 1840053479, name: "Holliston" },
            { id: 1840015144, name: "Hollywood" },
            { id: 1344982653, name: "Hong Kong" },
            { id: 1840006749, name: "Hopkins" },
            { id: 1756005911, name: "Horn" },
            { id: 1840003657, name: "Houston" },
            { id: 1840001778, name: "Howell" },
            { id: 1840020578, name: "Huntington Beach" },
            { id: 1840005081, name: "Huntington Station" },
            { id: 1840005061, name: "Huntsville" },
            { id: 1840007577, name: "Hurst" },
            { id: 1840030084, name: "Indianapolis" },
            { id: 1840011457, name: "Inglewood" },
            { id: 1040038924, name: "Innsbruck" },
            { id: 1840007858, name: "Inver Grove Heights" },
            { id: 1840016723, name: "Irmo" },
            { id: 1840014344, name: "Irvine" },
            { id: 1840012637, name: "Irving" },
            { id: 1840018422, name: "Issaquah" },
            { id: 1840004335, name: "Jacksonville" },
            { id: 1840015032, name: "Jacksonville Beach" },
            { id: 1840014787, name: "Jersey" },
            { id: 1840008340, name: "Jonesboro" },
            { id: 1840004321, name: "Jordan" },
            { id: 1840017255, name: "Jupiter" },
            { id: 1276581014, name: "Kaiserstuhl" },
            { id: 1840012501, name: "Kansas" },
            { id: 1276229317, name: "Karlsruhe" },
            { id: 1840018738, name: "Kaysville" },
            { id: 1840006324, name: "Keller" },
            { id: 1840004386, name: "Kenmore" },
            { id: 1840014756, name: "Kennesaw" },
            { id: 1840007751, name: "Kent" },
            { id: 1840040003, name: "Kentucky" },
            { id: 1840014253, name: "Key Largo" },
            { id: 1840029510, name: "Koloa" },
            { id: 1840019866, name: "Lacey" },
            { id: 1840011054, name: "Lake Orion" },
            { id: 1840007859, name: "Lakeville" },
            { id: 1840002969, name: "Lancaster" },
            { id: 1840020364, name: "Las Vegas" },
            { id: 1840074639, name: "Latonia" },
            { id: 1840008523, name: "Lawrenceburg" },
            { id: 1840003299, name: "Lawrenceville" },
            { id: 1840015162, name: "Layton" },
            { id: 1840001040, name: "Lebanon" },
            { id: 1276563678, name: "Leipzig" },
            { id: 1840000320, name: "Lewiston" },
            { id: 1840007924, name: "Lewisville" },
            { id: 1840001689, name: "Lexington" },
            { id: 1840015613, name: "Lilburn" },
            { id: 1840000158, name: "Lincoln" },
            { id: 1840013740, name: "Lithia Springs" },
            { id: 1840015644, name: "Lithonia" },
            { id: 1840003985, name: "Livonia" },
            { id: 1840015705, name: "Locust Grove" },
            { id: 1840006336, name: "Logan" },
            { id: 1840001454, name: "Loganville" },
            { id: 1840000988, name: "Long Beach" },
            { id: 1840053448, name: "Longmeadow" },
            { id: 1840020491, name: "Los Angeles" },
            { id: 1840000964, name: "Louisville" },
            { id: 1276912330, name: "Ludwigshafen" },
            { id: 1756503816, name: "Lugano" },
            { id: 1040745157, name: "Lustenau" },
            { id: 1840015922, name: "Lynn Haven" },
            { id: 1840013690, name: "Mableton" },
            { id: 1840009411, name: "Macomb" },
            { id: 1840009485, name: "Macon" },
            { id: 1840000538, name: "Madison" },
            { id: 1724616994, name: "Madrid" },
            { id: 1276385664, name: "Mainz" },
            { id: 1840000430, name: "Malden" },
            { id: 1840005273, name: "Manhasset" },
            { id: 1840008844, name: "Manhattan Beach" },
            { id: 1608254376, name: "Manila" },
            { id: 1840008975, name: "Mankato" },
            { id: 1840019828, name: "Maple Valley" },
            { id: 1276898599, name: "March" },
            { id: 1840001545, name: "Marietta" },
            { id: 1840017920, name: "Marina del Rey" },
            { id: 1840000220, name: "Marion" },
            { id: 1840008349, name: "Maryville" },
            { id: 1840003104, name: "Mason" },
            { id: 1724901780, name: "Matar\u00f3" },
            { id: 1840015706, name: "McDonough" },
            { id: 1840000431, name: "Medford" },
            { id: 1840003986, name: "Melvindale" },
            { id: 1840002422, name: "Memphis" },
            { id: 1276315921, name: "Merdingen" },
            { id: 1840000585, name: "Middletown" },
            { id: 1840012091, name: "Midvale" },
            { id: 1840001041, name: "Milford" },
            { id: 1840000029, name: "Milton" },
            { id: 1840003046, name: "Milwaukee" },
            { id: 1840003824, name: "Minneapolis" },
            { id: 1840007831, name: "Minnetonka" },
            { id: 1840000053, name: "Minot" },
            { id: 1840122841, name: "Missouri Branch" },
            { id: 1840006009, name: "Mobile" },
            { id: 1840004978, name: "Montebello" },
            { id: 1124586170, name: "Montr\u00e9al" },
            { id: 1840019685, name: "Moore" },
            { id: 1840007872, name: "Morgan" },
            { id: 1840001326, name: "Morrisville" },
            { id: 1840000888, name: "Mount Pleasant" },
            { id: 1840000338, name: "Mount Vernon" },
            { id: 1356226629, name: "Mumbai" },
            { id: 1276692352, name: "Munich" },
            { id: 1276925185, name: "M\u00fcllheim" },
            { id: 1840050164, name: "M\u014d\u02bbili\u02bbili" },
            { id: 1840008651, name: "Nashville" },
            { id: 1840014591, name: "New Bern" },
            { id: 1840001645, name: "New Boston" },
            { id: 1356215164, name: "New Delhi" },
            { id: 1840004850, name: "New Haven" },
            { id: 1840001839, name: "New Orleans" },
            { id: 1840005704, name: "New Windsor" },
            { id: 1840034016, name: "New York" },
            { id: 1840014833, name: "Newnan" },
            { id: 1840002270, name: "Newport" },
            { id: 1528136727, name: "Nijmegen" },
            { id: 1356149101, name: "None" },
            { id: 1840007767, name: "Norcross" },
            { id: 1840003869, name: "Norfolk" },
            { id: 1840029449, name: "North Druid Hills" },
            { id: 1840069459, name: "North Easton" },
            { id: 1840014632, name: "North Little Rock" },
            { id: 1840015120, name: "North Port" },
            { id: 1840007843, name: "North St. Paul" },
            { id: 1840000799, name: "Northfield" },
            { id: 1840000288, name: "Northville" },
            { id: 1840002423, name: "Novi" },
            { id: 1276166609, name: "Nuremberg" },
            { id: 1840011503, name: "Oak Harbor" },
            { id: 1840005246, name: "Oceanside" },
            { id: 1840015098, name: "Ocoee" },
            { id: 1840008120, name: "Ogden" },
            { id: 1840061984, name: "Oklahoma" },
            { id: 1840034375, name: "Olde West Chester" },
            { id: 1276042758, name: "Olpe" },
            { id: 1840019865, name: "Olympia" },
            { id: 1840011374, name: "Onsted" },
            { id: 1191093484, name: "Opatija" },
            { id: 1840003405, name: "Orange" },
            { id: 1840019858, name: "Orting" },
            { id: 1840018308, name: "Palm Springs" },
            { id: 1840140102, name: "Palos Verdes Peninsula" },
            { id: 1250015082, name: "Paris" },
            { id: 1840009701, name: "Park" },
            { id: 1840000917, name: "Park Ridge" },
            { id: 1840028462, name: "Parlin" },
            { id: 1840005914, name: "Pasadena" },
            { id: 1840089287, name: "Peachtree" },
            { id: 1840015005, name: "Pensacola" },
            { id: 1840020244, name: "Petaluma" },
            { id: 1840000673, name: "Philadelphia" },
            { id: 1840004193, name: "Phoenix" },
            { id: 1840001254, name: "Pittsburgh" },
            { id: 1840001090, name: "Plainfield" },
            { id: 1276760945, name: "Planegg" },
            { id: 1840009258, name: "Plano" },
            { id: 1840009393, name: "Pleasanton" },
            { id: 1840000767, name: "Plymouth" },
            { id: 1840004983, name: "Pomona" },
            { id: 1840015143, name: "Pompano Beach" },
            { id: 1840028957, name: "Ponte Vedra Beach" },
            { id: 1840014755, name: "Powder Springs" },
            { id: 1840008956, name: "Prior Lake" },
            { id: 1840003289, name: "Providence" },
            { id: 1840001807, name: "Provo" },
            { id: 1840021944, name: "Queen Creek" },
            { id: 1840034002, name: "Queens" },
            { id: 1218441993, name: "Quito" },
            { id: 1840012975, name: "Raleigh" },
            { id: 1840020508, name: "Rancho Palos Verdes" },
            { id: 1840008959, name: "Red Wing" },
            { id: 1840020509, name: "Redondo Beach" },
            { id: 1276000325, name: "Regensburg" },
            { id: 1840020121, name: "Reno" },
            { id: 1840019827, name: "Renton" },
            { id: 1276256795, name: "Reutlingen" },
            { id: 1840000457, name: "Revere" },
            { id: 1840014913, name: "Richmond Hill" },
            { id: 1840011284, name: "River Forest" },
            { id: 1840000114, name: "Riverdale" },
            { id: 1840000848, name: "Riverside" },
            { id: 1840003961, name: "Riverview" },
            { id: 1840003858, name: "Roanoke" },
            { id: 1840020717, name: "Rockwall" },
            { id: 1840001930, name: "Rogers" },
            { id: 1840000349, name: "Rome" },
            { id: 1840003963, name: "Romulus" },
            { id: 1840006054, name: "Rosemount" },
            { id: 1840002541, name: "Roswell" },
            { id: 1840002430, name: "Royal Oak" },
            { id: 1840000410, name: "Salem" },
            { id: 1840082816, name: "Salt Lake" },
            { id: 1840015969, name: "San Antonio" },
            { id: 1840037414, name: "San Buenaventura" },
            { id: 1840021990, name: "San Diego" },
            { id: 1840021543, name: "San Francisco" },
            { id: 1840021991, name: "San Marcos" },
            { id: 1840021863, name: "San Marino" },
            { id: 1756343098, name: "Sankt Gallen" },
            { id: 1276113823, name: "Sankt Peter" },
            { id: 1276245699, name: "Sankt Wolfgang" },
            { id: 1840021829, name: "Santa Barbara" },
            { id: 1840019103, name: "Santa Cruz" },
            { id: 1840019736, name: "Santa Monica" },
            { id: 1840053670, name: "Saugus" },
            { id: 1840008957, name: "Savage" },
            { id: 1840009477, name: "Savannah" },
            { id: 1276569928, name: "Schallstadt" },
            { id: 1276002233, name: "Schopfheim" },
            { id: 1840001157, name: "Scottdale" },
            { id: 1840021940, name: "Scottsdale" },
            { id: 1840022197, name: "Seabrook" },
            { id: 1840021117, name: "Seattle" },
            { id: 1840001262, name: "Sharpsburg" },
            { id: 1840004840, name: "Shelton" },
            { id: 1840001864, name: "Sherwood" },
            { id: 1840005845, name: "Silver Spring" },
            { id: 1702341327, name: "Singapore" },
            { id: 1840090595, name: "Sioux" },
            { id: 1840041703, name: "Smithtown" },
            { id: 1840004512, name: "Smyrna" },
            { id: 1840015614, name: "Snellville" },
            { id: 1840021078, name: "Snohomish" },
            { id: 1840021119, name: "Snoqualmie" },
            { id: 1840015658, name: "Social Circle" },
            { id: 1840001056, name: "Somerville" },
            { id: 1840021385, name: "South Jordan" },
            { id: 1840008948, name: "South St. Paul" },
            { id: 1840002431, name: "Southfield" },
            { id: 1840022048, name: "Southlake" },
            { id: 1840018451, name: "Spanaway" },
            { id: 1840021398, name: "Spanish Fork" },
            { id: 1840000466, name: "Springfield" },
            { id: 1840004391, name: "Springville" },
            { id: 1840002267, name: "St. Albans" },
            { id: 1840002611, name: "St. Cloud" },
            { id: 1840001651, name: "St. Louis" },
            { id: 1840006484, name: "St. Paul" },
            { id: 1840003461, name: "St. Petersburg" },
            { id: 1840013874, name: "St. Simons" },
            { id: 1840123202, name: "Staten" },
            { id: 1840015801, name: "Statesboro" },
            { id: 1840000816, name: "Stillwater" },
            { id: 1840002248, name: "Stockbridge" },
            { id: 1840015645, name: "Stone Mountain" },
            { id: 1276278805, name: "Straubing" },
            { id: 1276171358, name: "Stuttgart" },
            { id: 1840003873, name: "Suffolk" },
            { id: 1840015616, name: "Suwanee" },
            { id: 1036074917, name: "Sydney" },
            { id: 1840000378, name: "Syracuse" },
            { id: 1840021129, name: "Tacoma" },
            { id: 1840015913, name: "Tallahassee" },
            { id: 1840003228, name: "Tecumseh" },
            { id: 1840021942, name: "Tempe" },
            { id: 1840015708, name: "Texarkana" },
            { id: 1840022005, name: "The Colony" },
            { id: 1356681152, name: "Th\u0101ne" },
            { id: 1642603121, name: "Timi\u015foara" },
            { id: 1642421082, name: "Timu\u015f" },
            { id: 1276001947, name: "Titisee-Neustadt" },
            { id: 1840000572, name: "Toledo" },
            { id: 1840022203, name: "Tomball" },
            { id: 1840021356, name: "Tooele" },
            { id: 1840021873, name: "Torrance" },
            { id: 1840001383, name: "Trenton" },
            { id: 1840000521, name: "Troy" },
            { id: 1840013701, name: "Tucker" },
            { id: 1840022101, name: "Tucson" },
            { id: 1840021533, name: "Turlock" },
            { id: 1840005563, name: "Tuscaloosa" },
            { id: 1276002212, name: "Ulm" },
            { id: 1840006386, name: "Union" },
            { id: 1840052612, name: "Utah" },
            { id: 1840002417, name: "Utica" },
            { id: 1840021498, name: "Vacaville" },
            { id: 1840011638, name: "Valparaiso" },
            { id: 1840021189, name: "Vancouver" },
            { id: 1840003865, name: "Victoria" },
            { id: 1840002150, name: "Vienna" },
            { id: 1840015679, name: "Villa Rica" },
            { id: 1840003871, name: "Virginia Beach" },
            { id: 1840010008, name: "Waconia" },
            { id: 1276011078, name: "Waldshut-Tiengen" },
            { id: 1840000652, name: "Warren" },
            { id: 1840001889, name: "Warwick" },
            { id: 1840000724, name: "Washington" },
            { id: 1840000305, name: "Waterville" },
            { id: 1840022083, name: "Waxahachie" },
            { id: 1840010000, name: "Wayzata" },
            { id: 1840038051, name: "West Bloomfield" },
            { id: 1840001436, name: "West Chester" },
            { id: 1840004852, name: "West Haven" },
            { id: 1840021388, name: "West Jordan" },
            { id: 1840015993, name: "West Palm Beach" },
            { id: 1840011333, name: "Western Springs" },
            { id: 1840000463, name: "Westfield" },
            { id: 1840037458, name: "Westlake Village" },
            { id: 1840005930, name: "Weston" },
            { id: 1840006923, name: "Whitmore Lake" },
            { id: 1840001686, name: "Wichita" },
            { id: 1276324633, name: "Wilhelmshaven" },
            { id: 1840005579, name: "Wilmington" },
            { id: 1840017238, name: "Windermere" },
            { id: 1840015966, name: "Winter Park" },
            { id: 1756362130, name: "Winterthur" },
            { id: 1840010013, name: "Winthrop" },
            { id: 1840000407, name: "Wixom" },
            { id: 1124375825, name: "Woodbridge" },
            { id: 1840004161, name: "Woodstock" },
            { id: 1276233514, name: "W\u00fcrzburg" },
            { id: 1840006444, name: "Yorktown" },
            { id: 1840001166, name: "Youngstown" },
            { id: 1840000459, name: "Ypsilanti" },
            { id: 1756539143, name: "Z\u00fcrich" },
        ],
    },
};

export const temporaryOverviewData = {
    averageScore: {
        stack1: {
            series: {
                value: 0.68,
            },
        },
        stack2: {
            series: {
                value: 0.43,
            },
        },
    },
    averageSubScores: {
        comparisonChart: {
            categories: [
                "Current Employee Tenure Rate",
                "Boomerang Ratio",
                "Employee Tenure Ratio",
                "Current Employee Tenure Ratio",
                "Employee Ratio",
                "Employment Type Ratio",
            ],
            stack1: {
                series: {
                    data: [0.71, 0.31, 0.11, 0.45, 0.48, 0.16],
                },
            },
            stack2: {
                series: {
                    data: [0.43, 0.14, 0.08, 0.34, 0.46, 0.29],
                },
            },
        },
        diffChart: {
            series: {
                data: [-0.28, -0.17, -0.03, -0.11, -0.02, 0.13],
            },
        },
    },
    section1: {
        averageScore: {
            stack1: {
                series: {
                    value: 0.68,
                },
            },
            stack2: {
                series: {
                    value: 0.43,
                },
            },
        },
        averageSubScores: {
            comparisonChart: {
                categories: [
                    "Current Employee Tenure Rate",
                    "Boomerang Ratio",
                    "Employee Tenure Ratio",
                    "Current Employee Tenure Ratio",
                    "Employee Ratio",
                    "Employment Type Ratio",
                ],
                stack1: {
                    series: {
                        data: [0.71, 0.31, 0.11, 0.45, 0.48, 0.16],
                    },
                },
                stack2: {
                    series: {
                        data: [0.43, 0.14, 0.08, 0.34, 0.46, 0.29],
                    },
                },
            },
            diffChart: {
                series: {
                    data: [-0.28, -0.17, -0.03, -0.11, -0.02, 0.13],
                },
            },
        },
    },
    section2: {
        diversityAverageFinalScoreSingle: {
            stack1: {
                series: {
                    value: 0.82,
                },
            },
            stack2: {
                series: {
                    value: 0.58,
                },
            },
        },
        diversityAverageSubScoresSingle: {
            comparisonChart: {
                categories: [
                    "Gender Diversity",
                    "Education Diversity",
                    "Language Diversity",
                    "Work Experience Group Diversity",
                    "Age Group Diversity",
                    "Location Diversity",
                ],
                stack1: {
                    series: {
                        data: [0.98, 0.89, 0.75, 0.21, 0.84, 0.7],
                    },
                },
                stack2: {
                    series: {
                        data: [0.93, 0.71, 0.68, 0.55, 0.7, 0.34],
                    },
                },
            },
            diffChart: {
                series: {
                    data: [-0.05, -0.18, -0.07, 0.34, -0.14, -0.36],
                },
            },
        },
    },
    section3: {
        competitivenessAverageFinalScoreSingle: {
            stack1: {
                series: {
                    value: 0.82,
                },
            },
            stack2: {
                series: {
                    value: 0.58,
                },
            },
        },
        competitivenessAverageSubScoresSingle: {
            comparisonChart: {
                categories: [
                    "Boomerang Ratio",
                    "High Demand Ratio",
                    "Retention Growth Index",
                    "Tenured Employee Ratio",
                    "Ph.D. Ratio",
                    "Masters Ratio",
                ],
                stack1: {
                    series: {
                        data: [0.98, 0.89, 0.74, 0.21, 0.84, 0.71],
                    },
                },
                stack2: {
                    series: {
                        data: [0.92, 0.71, 0.66, 0.54, 0.7, 0.33],
                    },
                },
            },
            diffChart: {
                series: {
                    data: [-0.06, -0.18, -0.08, 0.33, -0.14, -0.38],
                },
            },
        },
    },
};

export const temporaryEmploymentSingleViewData = {
    section1: {
        jobTitles: {
            categoriesId: [
                60, 67, 55, 28, 12, 48, 8, 34, 30, 14, 23, 10, 9, 58, 61, 2, 20, 7, 22, 16, 38, 19, 31, 15, 51, 46, 5,
                53, 36, 45, 32, 37, 47, 39, 1, 35, 54, 43, 11, 18, 6, 17, 27, 59, 42, 44, 25, 65, 24, 49, 4, 62, 66, 3,
                41, 29, 63, 40, 50, 56, 64, 57,
            ],
            categories: [
                "Transportation Services",
                "Other Occupations",
                "Intern",
                "Middle Management",
                "Customer Services",
                "Sales",
                "Coach / Professor / Mentor",
                "Operations",
                "Marketing",
                "Data Science and Analysis",
                "Information Technology",
                "Corporate Affairs",
                "Consulting",
                "Software Development",
                "Team lead / Technical lead",
                "Business Administration",
                "Finance",
                "Business Development",
                "Human Resources",
                "Top Management",
                "Project Management",
                "Engineering",
                "Media and Entertainment",
                "C Level",
                "Student",
                "Retail",
                "Associate",
                "Supply Chain Management / Logistics",
                "Product Development",
                "Research and Development",
                "Medical / Wellness",
                "Production",
                "Retail Banking",
                "Protection",
                "Accounting",
                "Partner",
                "Technician",
                "Quality Management",
                "Craft Trade Workers",
                "Design",
                "Board Member",
                "Founders / Shareholders",
                "Legal Services",
                "Sports / Fitness / Trainer",
                "Military",
                "Real Estate",
                "Investors",
                "Volunteering",
                "Insurance",
                "Self Employed",
                "Art",
                "University / Academic Affairs",
                "Writer",
                "Automation",
                "Public Services",
                "Manufacturing",
                "Visual Merchandising",
                "Police",
                "Social Services",
                "Trainee",
                "Movie",
                "Sound",
            ],
            stack: {
                series: [
                    {
                        data: [
                            11.63, 10.45, 5.87, 5.71, 5.56, 4.76, 3.56, 3.34, 3.14, 2.87, 2.77, 2.69, 2.6, 2.52, 2.31,
                            2.26, 2.2, 2.02, 2.01, 1.93, 1.74, 1.49, 1.14, 1.14, 0.88, 0.87, 0.81, 0.78, 0.74, 0.66,
                            0.65, 0.64, 0.62, 0.61, 0.61, 0.55, 0.54, 0.53, 0.53, 0.5, 0.41, 0.34, 0.32, 0.3, 0.29,
                            0.27, 0.26, 0.24, 0.23, 0.2, 0.14, 0.13, 0.12, 0.1, 0.07, 0.07, 0.06, 0.06, 0.05, 0.04,
                            0.04, 0.01,
                        ],
                    },
                ],
            },
        },
        previousCompanies: {
            categories: [
                "Northwest Airlines",
                "United States Air Force",
                "Us Navy",
                "The Home Depot",
                "Skywest Airlines",
                "United Airlines",
                "Expressjet Airlines",
                "Jetblue",
                "Target",
                "American Airlines",
                "Wells Fargo",
                "Walt Disney World",
                "Bank Of America",
                "At&T",
                "Georgia Institute Of Technology",
                "Compass Airlines",
                "University Of Georgia",
                "United States Marine Corps",
                "Best Buy",
                "Delta Global Services – Staffing",
                "Southwest Airlines",
                "Mesaba Airlines",
                "Ups",
                "Nordstrom",
                "Ihg Hotels & Resorts",
                "Accenture",
                "Apple",
                "Us Air Force Reserve",
                "University Of North Dakota",
                "Sun Country Airlines",
            ],
            stack: {
                series: [
                    {
                        data: [
                            1.58, 1.55, 0.69, 0.67, 0.64, 0.63, 0.53, 0.48, 0.45, 0.44, 0.39, 0.39, 0.39, 0.39, 0.36,
                            0.34, 0.33, 0.33, 0.31, 0.28, 0.27, 0.27, 0.25, 0.25, 0.25, 0.25, 0.23, 0.22, 0.22, 0.22,
                        ],
                    },
                ],
            },
        },
        averageWorkExperience: {
            categories: ["Current", "Previous", "Total"],
            stack: {
                series: {
                    data: [9.74, 16.95, 24.12],
                },
            },
            benchmark: [4.63, 9.86, 12.44],
        },
        averageWorkExperiencePerGender: {
            categories: ["Male", "Female", "Unknown"],
            stack: {
                series: {
                    data: [19.62, 17.4, 16.95],
                },
            },
            benchmark: [13.62, 16.54, 19.13],
        },
        averageWorkExperiencePerDegree: {
            categories: ["Bachelor's Degree", "Master's Degree", "Doctoral Degree", "Total"],
            stack: {
                series: {
                    data: [24.97, 25.88, 47.62, 31.08],
                },
            },
            benchmark: [18.83, 17.91, 23.58, 19.78],
        },
    },
    section2: {
        topPreviousCompanies: [
            {
                categories: [
                    "Transportation Services",
                    "Coach / Professor / Mentor",
                    "Other Occupations",
                    "C Level",
                    "Software Development",
                    "Operations",
                    "Top Management",
                    "Military",
                    "Student",
                    "Quality Management",
                    "Middle Management",
                    "Medical / Wellness",
                    "Information Technology",
                    "Corporate Affairs",
                    "Business Administration",
                    "Technician",
                    "Team lead / Technical lead",
                    "Sports / Fitness / Trainer",
                    "Self Employed",
                    "Research and Development",
                    "Protection",
                    "Project Management",
                    "Human Resources",
                    "Finance",
                    "Engineering",
                    "Data Science and Analysis",
                    "Craft Trade Workers",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.55, 0.53, 0.44, 0.37, 0.19, 0.17, 0.11, 0.09, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03,
                                0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "United States Air Force",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Middle Management",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Operations",
                    "Sales",
                    "Top Management",
                    "Software Development",
                    "Other Occupations",
                    "Finance",
                    "Coach / Professor / Mentor",
                    "Information Technology",
                    "Intern",
                    "Consulting",
                    "Business Development",
                    "Accounting",
                    "Project Management",
                    "Marketing",
                    "Corporate Affairs",
                    "Supply Chain Management / Logistics",
                    "Business Administration",
                    "Technician",
                    "Quality Management",
                    "Production",
                    "Human Resources",
                    "Engineering",
                    "Automation",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.27, 0.2, 0.16, 0.16, 0.12, 0.09, 0.08, 0.08, 0.08, 0.08, 0.08, 0.07, 0.05, 0.05, 0.05,
                                0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Northwest Airlines",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Coach / Professor / Mentor",
                    "Transportation Services",
                    "Operations",
                    "Technician",
                    "Craft Trade Workers",
                    "Student",
                    "Top Management",
                    "Team lead / Technical lead",
                    "Software Development",
                    "Quality Management",
                    "Project Management",
                    "Military",
                    "Middle Management",
                    "Medical / Wellness",
                    "Engineering",
                    "Business Administration",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.39, 0.11, 0.09, 0.07, 0.04, 0.04, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Us Navy",
                },
            },
            {
                categories: [
                    "Data Science and Analysis",
                    "Marketing",
                    "Intern",
                    "Transportation Services",
                    "Information Technology",
                    "Finance",
                    "Supply Chain Management / Logistics",
                    "Quality Management",
                    "Middle Management",
                    "Media and Entertainment",
                    "Legal Services",
                    "Human Resources",
                    "Design",
                    "Corporate Affairs",
                    "Consulting",
                    "Top Management",
                    "Team lead / Technical lead",
                    "Sales",
                    "Retail",
                    "Project Management",
                    "Other Occupations",
                    "Customer Services",
                    "Business Development",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.11, 0.07, 0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "The Home Depot",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Intern",
                    "Sales",
                    "Operations",
                    "Information Technology",
                    "Middle Management",
                    "Software Development",
                    "Project Management",
                    "Other Occupations",
                    "Finance",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Top Management",
                    "Supply Chain Management / Logistics",
                    "Product Development",
                    "Investors",
                    "Engineering",
                    "Craft Trade Workers",
                    "Corporate Affairs",
                    "C Level",
                    "Business Development",
                    "Accounting",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.09, 0.08, 0.07, 0.07, 0.05, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "United Airlines",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Customer Services",
                    "Transportation Services",
                    "Operations",
                    "Intern",
                    "Team lead / Technical lead",
                    "Middle Management",
                    "Human Resources",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "Top Management",
                    "Quality Management",
                    "Project Management",
                    "Marketing",
                    "Investors",
                    "Finance",
                    "Data Science and Analysis",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.16, 0.08, 0.07, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Jetblue",
                },
            },
            {
                categories: ["Other Occupations", "Transportation Services", "Human Resources", "Customer Services"],
                stack: {
                    series: [
                        {
                            data: [0.29, 0.27, 0.01, 0.01],
                        },
                    ],
                    name: "Skywest Airlines",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Sales",
                    "Intern",
                    "Other Occupations",
                    "Customer Services",
                    "Corporate Affairs",
                    "Operations",
                    "Middle Management",
                    "Marketing",
                    "Finance",
                    "Team lead / Technical lead",
                    "Protection",
                    "Product Development",
                    "Human Resources",
                    "Data Science and Analysis",
                    "Consulting",
                    "Coach / Professor / Mentor",
                    "Business Development",
                    "Accounting",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.09, 0.08, 0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "American Airlines",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "Middle Management",
                    "Intern",
                    "Information Technology",
                    "Human Resources",
                    "C Level",
                ],
                stack: {
                    series: [
                        {
                            data: [0.2, 0.19, 0.05, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Expressjet Airlines",
                },
            },
            {
                categories: [
                    "Team lead / Technical lead",
                    "Other Occupations",
                    "Middle Management",
                    "Intern",
                    "Finance",
                    "Data Science and Analysis",
                    "Transportation Services",
                    "Software Development",
                    "Sales",
                    "Retail",
                    "Protection",
                    "Project Management",
                    "Marketing",
                    "Legal Services",
                    "Human Resources",
                    "Business Development",
                    "Accounting",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.15, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Target",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Military",
                    "Transportation Services",
                    "Quality Management",
                    "Operations",
                    "Craft Trade Workers",
                    "Coach / Professor / Mentor",
                    "Technician",
                    "Student",
                    "Middle Management",
                    "Legal Services",
                    "Information Technology",
                    "Finance",
                    "Corporate Affairs",
                    "Business Administration",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.11, 0.07, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01,
                            ],
                        },
                    ],
                    name: "United States Marine Corps",
                },
            },
            {
                categories: [
                    "Coach / Professor / Mentor",
                    "Research and Development",
                    "Student",
                    "Information Technology",
                    "Other Occupations",
                    "Human Resources",
                    "Consulting",
                    "Top Management",
                    "Protection",
                    "Middle Management",
                    "Marketing",
                    "Intern",
                    "Engineering",
                    "Business Development",
                ],
                stack: {
                    series: [
                        {
                            data: [0.09, 0.08, 0.05, 0.04, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Georgia Institute Of Technology",
                },
            },
            {
                categories: [
                    "Retail Banking",
                    "Human Resources",
                    "Other Occupations",
                    "Operations",
                    "Top Management",
                    "Middle Management",
                    "Customer Services",
                    "Business Development",
                    "Accounting",
                    "Marketing",
                    "Information Technology",
                    "Data Science and Analysis",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "C Level",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.07, 0.05, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01,
                            ],
                        },
                    ],
                    name: "Bank Of America",
                },
            },
            {
                categories: [
                    "Information Technology",
                    "Business Development",
                    "Sales",
                    "Software Development",
                    "Middle Management",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Corporate Affairs",
                    "Technician",
                    "Retail",
                    "Project Management",
                    "Production",
                    "Other Occupations",
                    "Media and Entertainment",
                    "Marketing",
                    "Intern",
                    "Finance",
                    "Design",
                    "Consulting",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.05, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "At&T",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Partner",
                    "Intern",
                    "Customer Services",
                    "Information Technology",
                    "Sales",
                    "Media and Entertainment",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [0.13, 0.08, 0.07, 0.04, 0.03, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Walt Disney World",
                },
            },
            {
                categories: [
                    "Retail Banking",
                    "Human Resources",
                    "Other Occupations",
                    "Quality Management",
                    "Project Management",
                    "Intern",
                    "Finance",
                    "Software Development",
                    "Operations",
                    "Middle Management",
                    "Information Technology",
                    "Design",
                    "Business Development",
                ],
                stack: {
                    series: [
                        {
                            data: [0.07, 0.05, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Wells Fargo",
                },
            },
            {
                categories: [
                    "Partner",
                    "Intern",
                    "Customer Services",
                    "Other Occupations",
                    "Engineering",
                    "Corporate Affairs",
                    "Team lead / Technical lead",
                    "Sports / Fitness / Trainer",
                    "Operations",
                    "Middle Management",
                    "Media and Entertainment",
                    "Marketing",
                    "Human Resources",
                    "Finance",
                    "Data Science and Analysis",
                    "Craft Trade Workers",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.04, 0.04, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01,
                            ],
                        },
                    ],
                    name: "The Walt Disney Company",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Coach / Professor / Mentor",
                    "Finance",
                    "C Level",
                ],
                stack: {
                    series: [
                        {
                            data: [0.13, 0.12, 0.03, 0.01, 0.01],
                        },
                    ],
                    name: "Compass Airlines",
                },
            },
            {
                categories: [
                    "Student",
                    "Other Occupations",
                    "Intern",
                    "Middle Management",
                    "Customer Services",
                    "Coach / Professor / Mentor",
                    "Business Administration",
                    "Top Management",
                    "Legal Services",
                    "Data Science and Analysis",
                    "Corporate Affairs",
                ],
                stack: {
                    series: [
                        {
                            data: [0.05, 0.05, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "University Of Georgia",
                },
            },
            {
                categories: [
                    "Marketing",
                    "Project Management",
                    "Other Occupations",
                    "Intern",
                    "Corporate Affairs",
                    "Top Management",
                    "Software Development",
                    "Sales",
                    "Product Development",
                    "Middle Management",
                    "Legal Services",
                    "Information Technology",
                    "Customer Services",
                    "Business Development",
                ],
                stack: {
                    series: [
                        {
                            data: [0.07, 0.04, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Ihg Hotels & Resorts",
                },
            },
            {
                categories: [
                    "Sales",
                    "Project Management",
                    "Middle Management",
                    "Consulting",
                    "Customer Services",
                    "Software Development",
                    "Product Development",
                    "Other Occupations",
                    "Media and Entertainment",
                ],
                stack: {
                    series: [
                        {
                            data: [0.08, 0.04, 0.04, 0.04, 0.03, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Best Buy",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Coach / Professor / Mentor",
                    "Operations",
                    "Customer Services",
                    "Intern",
                    "Finance",
                    "Craft Trade Workers",
                    "C Level",
                ],
                stack: {
                    series: [
                        {
                            data: [0.08, 0.05, 0.04, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Mesaba Airlines",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Team lead / Technical lead",
                    "Other Occupations",
                    "Engineering",
                    "Protection",
                    "Project Management",
                    "Middle Management",
                    "Information Technology",
                    "Consulting",
                ],
                stack: {
                    series: [
                        {
                            data: [0.05, 0.05, 0.04, 0.04, 0.03, 0.03, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Delta Global Services – Staffing",
                },
            },
            {
                categories: [
                    "Software Development",
                    "Middle Management",
                    "Intern",
                    "Information Technology",
                    "Quality Management",
                    "Data Science and Analysis",
                    "Consulting",
                    "Human Resources",
                    "Customer Services",
                ],
                stack: {
                    series: [
                        {
                            data: [0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.01, 0.01],
                        },
                    ],
                    name: "Accenture",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Coach / Professor / Mentor",
                    "Transportation Services",
                    "Software Development",
                    "Operations",
                    "Medical / Wellness",
                    "Team lead / Technical lead",
                    "Engineering",
                    "C Level",
                ],
                stack: {
                    series: [
                        {
                            data: [0.07, 0.04, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Us Air Force Reserve",
                },
            },
            {
                categories: [
                    "Intern",
                    "Consulting",
                    "Quality Management",
                    "Data Science and Analysis",
                    "Accounting",
                    "Other Occupations",
                    "Middle Management",
                    "Information Technology",
                    "Finance",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [0.05, 0.05, 0.04, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Ey",
                },
            },
            {
                categories: [
                    "Intern",
                    "Transportation Services",
                    "Other Occupations",
                    "Middle Management",
                    "Marketing",
                    "Engineering",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Business Administration",
                ],
                stack: {
                    series: [
                        {
                            data: [0.11, 0.05, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Southwest Airlines",
                },
            },
            {
                categories: [
                    "Coach / Professor / Mentor",
                    "Student",
                    "Research and Development",
                    "Business Administration",
                    "Transportation Services",
                    "Software Development",
                    "Other Occupations",
                ],
                stack: {
                    series: [
                        {
                            data: [0.11, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Purdue University",
                },
            },
            {
                categories: [
                    "Team lead / Technical lead",
                    "Consulting",
                    "Other Occupations",
                    "Software Development",
                    "Sales",
                    "Middle Management",
                    "Coach / Professor / Mentor",
                ],
                stack: {
                    series: [
                        {
                            data: [0.08, 0.08, 0.03, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Apple",
                },
            },
            {
                categories: [
                    "Sales",
                    "Retail",
                    "Transportation Services",
                    "Top Management",
                    "Other Occupations",
                    "Middle Management",
                    "Intern",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [0.12, 0.04, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01],
                        },
                    ],
                    name: "Nordstrom",
                },
            },
        ],
        topJobTitles: [
            {
                categories: [
                    "United States Air Force",
                    "Us Navy",
                    "Skywest Airlines",
                    "Expressjet Airlines",
                    "Jetblue",
                    "Walt Disney World",
                    "Compass Airlines",
                    "United States Marine Corps",
                    "Atlas Air",
                    "Sun Country Airlines",
                    "Ups",
                    "Northwest Airlines",
                    "Mesaba Airlines",
                    "Us Army",
                    "Us Air Force Reserve",
                    "Spirit Airlines",
                    "Republic Airways",
                    "Air Line Pilots Association",
                    "Virgin America",
                    "University Of Georgia",
                    "Psa Airlines, Inc.",
                    "Piedmont Airlines",
                    "Horizon Air",
                    "Fedex",
                    "Cape Air",
                    "Wells Fargo",
                    "United States Navy Reserve",
                    "Target",
                    "Georgia State University",
                    "Envoy Air",
                    "Emory University",
                    "Delta Global Services – Staffing",
                    "Bank Of America",
                    "American Eagle Airlines",
                    "American Airlines",
                    "Air Wisconsin Airlines",
                    "United States Navy",
                    "United Airlines",
                    "U.S. Coast Guard",
                    "Turner Broadcasting Systems",
                    "The Walt Disney Company",
                    "The University Of Alabama",
                    "Suntrust Bank",
                    "Southern Utah University",
                    "Shuttle America",
                    "Self-Employed",
                    "Ruffalo Noel Levitz",
                    "Republic Airways Holdings, Inc.",
                    "Publix Super Markets",
                    "Private Family",
                    "Northwestern Mutual",
                    "Northeastern University",
                    "Netjets",
                    "Marriott Hotels",
                    "Lincoln Property Company",
                    "Kroger",
                    "Jll",
                    "Ihg Hotels & Resorts",
                    "Iheartmedia",
                    "Hyatt Regency",
                    "Goizueta Business School",
                    "Georgia Southern University",
                    "Georgia Institute Of Technology",
                    "General Motors",
                    "Enterprise Rent-A-Car",
                    "Disney Parks & Resorts",
                    "Delta Community Credit Union",
                    "Comair",
                    "Colgan Air, Inc.",
                    "Cold Stone Creamery",
                    "Children'S Healthcare Of Atlanta",
                    "Boy Scouts Of America",
                    "Banana Republic",
                    "Atlantic Southeast Airlines",
                    "Atlanta Braves",
                    "Apple",
                    "American Apparel",
                    "Alaska Airlines",
                    "Zuhair Fayez Partnership Consultants",
                    "Youth Health Associates",
                    "Ymca Of San Francisco",
                    "Ymca Of Greater Cleveland",
                    "Ymca",
                    "Yimby Action",
                    "Wyndham Hotel Group",
                    "Wyndham Grand Orlando Resort, Bonnet Creek",
                    "Wusf Public Media",
                    "Writers Guild Of America East - Wgae",
                    "Wow! Internet, Cable & Phone",
                    "World Gym",
                    "World Airways",
                    "Wisconsin Aviation, Inc.",
                    "Wisconsin Army National Guard",
                    "Wheels Up",
                    "Wework",
                    "Westwind School Of Aeronautics, Phoenix Llc",
                    "Western Michigan University",
                    "Wehmann Models/Talent, Inc",
                    "Weber State University",
                    "Wayne Farms",
                    "Washington Dc",
                    "Walker Methodist",
                    "Waldorf Astoria Park City",
                    "Waldorf Astoria Hotels & Resorts",
                    "Wake Forest University School Of Business",
                    "W Hotels",
                    "Vistage Worldwide, Inc.",
                    "Virgin Hotels",
                    "Victoria'S Secret Pink",
                    "Victoria'S Secret",
                    "Ventura Foods",
                    "Velez Law Firm",
                    "Varig",
                    "Utah Highway Patrol",
                    "Usmc",
                    "Usaa",
                    "Us Airways",
                    "Upfundraising",
                    "Up With People",
                    "University Union",
                    "University Of Southern California",
                    "University Of South Carolina",
                    "University Of Minnesota",
                    "University Of Kentucky",
                    "University Of Illinois At Urbana-Champaign",
                    "University Inn",
                    "Universal Pictures",
                    "Universal Orlando Resort",
                    "United States Senate",
                    "United States Postal Service",
                    "United States Indo-Pacific Command",
                    "United States Department Of Veterans Affairs",
                    "United States Department Of The Navy",
                    "United States Army Reserve",
                    "Unisource Worldwide, Inc.",
                    "Unifi",
                    "Unboundary",
                    "Ultimate Aircraft Appearance",
                    "U.S. House Of Representatives",
                    "U.S. Department Of State",
                    "U.S. Army",
                    "U+Mag",
                    "Turner Broadcasting System",
                    "Tuggle Duggins P.A.",
                    "Trumaker",
                    "Tropical Smoothie Cafe",
                    "Trego-Dugan Aviation Inc",
                    "Treasure Island Resort & Casino",
                    "Travel Of Orange",
                    "Transportation Security Administration (Tsa)",
                    "Transparent Container",
                    "Tradewind Aviation",
                    "Town Of Yarmouth, Ma",
                    "Town And Country Veterinary Clinic",
                    "Town & Country Club",
                    "Tmx Finance Family Of Companies",
                    "Tim Hortons",
                    "The University Of Chicago",
                    "The Home Depot",
                    "The Henry Ford",
                    "The Creative Circus",
                    "The Cooperative Bank Of Cape Cod",
                    "The Coca-Cola Company",
                    "The Classic Center",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "The Beer Growler",
                    "The Art Of Touch",
                    "Textron Aviation",
                    "Texas Air National Guard",
                    "Terrapin Beer Co.",
                    "Teleperformance Usa",
                    "Technica Corporation",
                    "Td",
                    "Tau Kappa Epsilon Fraternity",
                    "Tata Consultancy Services",
                    "T.J. Maxx",
                    "T-Mobile",
                    "Swissport Usa",
                    "Swissport",
                    "Swagger Gifts",
                    "Sundance Institute",
                    "Stitch Fix",
                    "Stevens Water Monitoring Systems, Inc.",
                    "Stem Academy",
                    "State Of Utah",
                    "State Of Minnesota",
                    "Sta Jets",
                    "St. Cloud State University",
                    "Sprint",
                    "Spotify",
                    "Space Generation Advisory Council",
                    "Southwest Airlines",
                    "South By Sea",
                    "Sodexo",
                    "Social Security Administration",
                    "Social Development Commission",
                    "Six Flags Over Georgia",
                    "Six Flags",
                    "Shepherd Center",
                    "Sheehy Construction Company",
                    "Senate Of Puerto Rico",
                    "Self Employed",
                    "See.Spark.Go",
                    "Sedgwick",
                    "Seattle Mariners",
                    "Sears Holdings Corporation",
                    "Sea Island Resorts",
                    "Sea Island Company",
                    "Sbarro",
                    "Savannah College Of Art And Design (Scad)",
                    "Salon 124 Group",
                    "Saginaw Valley State University",
                    "Rydell Chevrolet",
                    "Royal Marechaussee",
                    "Royal Caribbean International",
                    "Ross Stores, Inc.",
                    "Rose International",
                    "Rolls-Royce",
                    "Riverstone Residential Group",
                    "Ricoh Americas Corporation",
                    "Richland Aviation",
                    "Rhode Island State Police",
                    "Reynolds Lake Oconee",
                    "Residence Inn By Marriott",
                    "Republic Airlines",
                    "Remax",
                    "Rbc Bank",
                    "Ray Meyer Fitness And Recreation Center",
                    "Randstad Nv",
                    "Randstad",
                    "Quince",
                    "Purdue University",
                    "Public Relations Student Society Of America",
                    "Psa Airlines",
                    "Progressive Insurance",
                    "Private School Village",
                    "Primeflight Aviation Services",
                    "Prepa",
                    "Pontoon Solutions",
                    "Planet Fitness",
                    "Planesense, Inc.",
                    "Pinnacle Airlines, Inc.",
                    "Phi Mu Fraternity",
                    "Phi Eta Sigma National Honor Society, Inc.",
                    "Petland Retail Stores",
                    "Pershing, A Bny Mellon Company",
                    "Peabody Awards",
                    "Patrol Squadron 9",
                    "Park Ridge Recreation And Park District",
                    "Panasonic Avionics Corporation",
                    "Pacific Aviation",
                    "Ohio National Financial Services",
                    "Office Of E-Learning | North Carolina Central University",
                    "Office Of Defense Cooperation Turkey",
                    "Nrcc",
                    "Northwell Health",
                    "Northern Aviation",
                    "North American Airlines",
                    "Normandale Community College",
                    "Norfolk Southern Corporation",
                    "Nordstrom",
                    "Nike",
                    "Nato",
                    "Nationwide Group",
                    "National Orange Show",
                    "NASA",
                    "Mpi Research",
                    "Moveplan",
                    "Moore Creative Talent, Inc.",
                    "Monterey Bay Aquarium",
                    "Money360",
                    "Monaco",
                    "Mississippi Department Of Human Services",
                    "Minnesota National Guard",
                    "Minnesota Army National Guard",
                    "Minnesota Alliance With Youth",
                    "Minnesota Air National Guard",
                    "Mid Michigan Health",
                    "Microsoft",
                    "Michelin",
                    "Michael Kors",
                    "Mental Health Association Of Nassau County",
                    "Mens Senior Baseball League",
                    "Memorial Healthcare System",
                    "Mce Conferences",
                    "Mccormick & Company",
                    "Maurices",
                    "Marriott International",
                    "Marathon Petroleum Corporation",
                    "Mankato Public Safety Dept",
                    "Manhattan College",
                    "Management Leadership For Tomorrow (Mlt)",
                    "Maersk Line, Limited",
                    "Macy'S",
                    "Mack/Crounse Group",
                    "Macalester College",
                    "Lyon Aviation",
                    "Lululemon Athletica",
                    "Lowe'S Companies, Inc.",
                    "Love Your Melon",
                    "Lloyd Staffing",
                    "Lifespan",
                    "Liberty In North Korea",
                    "Lg",
                    "Legal Aid Society",
                    "Legacy Golf Management",
                    "Lefavor Envelope Company",
                    "Laz Parking",
                    "Lakeview Hills Golf Resort",
                    "Lakeridge Paving Co. Llc",
                    "La Fitness",
                    "Kumon",
                    "Kualoa Ranch Hawaii, Inc.",
                    "Krs - Kwajalein Range Services, Llc",
                    "Kmart",
                    "King Kullen",
                    "Kilpatrick Townsend & Stockton Llp",
                    "Kennesaw State University",
                    "Keene State College",
                    "Karmaloop.Com",
                    "Kama'Aina Kids",
                    "Johnson & Wales University",
                    "John Hancock Observatory",
                    "Jet Aviation",
                    "Jenner & Block",
                    "Jared The Galleria Of Jewelry",
                    "Japan Airlines",
                    "Jan Smith Studios",
                    "J.Hilburn",
                    "J. Mack Robinson College Of Business",
                    "Its",
                    "International Speedway Corporation (Isc)",
                    "International Rescue Committee",
                    "Interglobe Technologies",
                    "Interexchange",
                    "Innisfree Hotels",
                    "Infosys",
                    "Individual Provider",
                    "Indianapolis Colts",
                    "Indiana University",
                    "Indiana Farm Bureau Insurance",
                    "Independence Air",
                    "Ikon Office Solutions",
                    "Ids",
                    "Hyatt Hotels Corporation",
                    "Huron Valley Ambulance",
                    "Humanity In Action",
                    "Hughes Hubbard & Reed Llp",
                    "Hughes Aerospace Corporation",
                    "Howard University",
                    "Houssiere, Durant, Houssiere Llp",
                    "Hope Community Church",
                    "Hooters Of America",
                    "Homewood Suites By Hilton",
                    "Home Instead Senior Care",
                    "Home Depot",
                    "Hollister Co",
                    "Holland America Line",
                    "Holiday Inn Express Manhattan Midtown West",
                    "Hoag Industries, Inc",
                    "Hirschfeld Properties Llc",
                    "Hilton Grand Vacations",
                    "Hilton Cincinnati Netherland Plaza",
                    "Hi Country Stables",
                    "Hertz",
                    "Heritage Schools Inc.",
                    "Hello Florida! Inc.",
                    "Healthy Pets Animal Hospital",
                    "Headway Emotional Health Services",
                    "Hcr Manorcare",
                    "Harvard Kennedy School",
                    "Harbour Town Golf Links",
                    "Hanesbrands Inc.",
                    "Gwinnett County Government",
                    "Gwilliam Ivary Chiosso Cavalli & Brewer",
                    "Gw Alternative Breaks",
                    "Gulfstream Int'L Airline",
                    "Gulfstream Aerospace",
                    "Guild For Exceptional Chldrn",
                    "Group Health Cooperative",
                    "Greenpeace",
                    "Greater Twin Cities United Way",
                    "Great Lakes Airlines",
                    "Grandview Marketing",
                    "Graceland University",
                    "Gmr Marketing",
                    "Globeop Financial Services Sa",
                    "Glide",
                    "Gihoa",
                    "Gerald R. Ford School Of Public Policy",
                    "Georgia State Capitol",
                    "Georgia Power Company",
                    "Georgia Jet",
                    "Georgia House Of Representatives",
                    "Georgia Department Of Public Health",
                    "Georgia Department Of Human Services",
                    "Genesys Works",
                    "Geico",
                    "Gattitown",
                    "Garland Journal",
                    "G2 Secure Staff",
                    "Frontier Airlines",
                    "Freelance, Self-Employed",
                    "Freelance",
                    "Freedom Airlines Inc.",
                    "Forbes Travel Guide",
                    "Focus Hope",
                    "Flywheel Sports",
                    "Flydubai",
                    "Flexjet",
                    "Flex Ltd.",
                    "First National Bank Of Omaha",
                    "First American Title",
                    "Findspark",
                    "Feld Entertainment",
                    "Fedex Ground",
                    "Federal Bureau Of Investigation (Fbi)",
                    "Farmingdale State College",
                    "Falcon Aviation Academy",
                    "Fairview Health Services",
                    "Ey",
                    "Express Employment Professionals",
                    "Express Courier Systems, Inc.",
                    "Experis",
                    "Ethiopian Airlines",
                    "Epilepsy Foundation Of Virginia",
                    "Enterprise Holdings",
                    "Entercom",
                    "Encore Jet Center",
                    "Emtec Inc.",
                    "Employment Plus",
                    "Emirates",
                    "Eli Lilly And Company",
                    "Ef High School Exchange Year",
                    "Edelman Public Relations",
                    "Eastport Holdings, Llc",
                    "Eastern Washington University",
                    "Duke University",
                    "Drai'S Enterprises",
                    "Dr. Phillips Center For The Performing Arts",
                    "Dominion Aviation",
                    "Dispute Resolution Center",
                    "Disneyland Resort",
                    "Discovery World At Pier Wisconsin",
                    "Dialoguedirect",
                    "Deutsche Bank",
                    "Detroit Wayne Mental Health Authority",
                    "Detroit Medical Center",
                    "Desert Jet",
                    "Dependable Cleaners",
                    "Depauw University",
                    "Department Of National Defence",
                    "Department Of Eduacation",
                    "Delta Sigma Pi",
                    "Delta Global Staffing",
                    "Delta Global Services",
                    "Deca Dental Group",
                    "Dc Cosmetics",
                    "Dayton Dragons Professional Baseball",
                    "Dallas Baptist University",
                    "Cyracom Careers",
                    "Cyber Skyline",
                    "Cwt",
                    "Cutter Aviation",
                    "Csc",
                    "Crown Automotive Group",
                    "Crowell & Moring Llp",
                    "Cox Media Group",
                    "Courtyard By Marriott",
                    "Conn'S Homeplus",
                    "Concordia University",
                    "Commutair Dba United Express",
                    "Community Management Associates",
                    "Community Involvement Programs",
                    "Command And General Staff College (Cgsc)",
                    "Comcast Cable Communications, Llc",
                    "College Of Liberal Arts At The University Of Minnesota (Twin Cities)",
                    "Coco Eco Magazine",
                    "Cobb County School District",
                    "Coast To Coast Mortgage",
                    "Cnn",
                    "Cnet",
                    "Club Monaco",
                    "Club Jet",
                    "Clay Lacy Aviation",
                    "Clawmoney",
                    "Civil Air Patrol",
                    "City Of Portland",
                    "City Of Ocoee",
                    "City Of Los Angeles",
                    "City Of Atlanta",
                    "Cirque Du Soleil",
                    "Circuit City",
                    "Church Of Jesus Christ Of Latter-Day Saints",
                    "Chipotle Mexican Grill",
                    "Children'S Defense Fund",
                    "Chick-Fil-A",
                    "Chicago Children'S Museum",
                    "Chic Models",
                    "Chi Health",
                    "Challenge Detroit",
                    "Centers For Disease Control And Prevention",
                    "Cedar Fair Entertainment Company",
                    "Cbs Corporation",
                    "Cbre",
                    "Carrefour Global Sourcing",
                    "Carmel Clay Schools",
                    "Caresource",
                    "Canyon Ranch",
                    "Canadian Pacific Railway",
                    "Camp Ozark",
                    "Camp Chatuga",
                    "Camp Barney Medintz",
                    "California Suites Hotel",
                    "Business Forward",
                    "Burlington Northern",
                    "Burger 21",
                    "Buckle",
                    "Bruster'S Real Ice Cream",
                    "Brigham Young University",
                    "Bridgewater State University",
                    "Brain Balance Georgia",
                    "Bowling Green State University",
                    "Boston Scientific",
                    "Borgata Pizza Cafe",
                    "Booz Allen Hamilton",
                    "Boeing Commercial Airplanes",
                    "Boeing",
                    "Beulah Baptist Church",
                    "Better Business Bureau",
                    "Best Buy",
                    "Beehive Clothing Distribution",
                    "Beaumont Health",
                    "Beasley Forest Products Inc",
                    "Basin Electric Power Cooperative",
                    "Balfour Beatty Plc",
                    "Bailiwick",
                    "Avis Budget Group",
                    "Avis",
                    "Aviation Technical Services",
                    "Aviance Uk",
                    "Avianca",
                    "Aupairworld",
                    "Auburn University Dance Marathon",
                    "Auburn University",
                    "At&T",
                    "Army National Guard",
                    "Arizona State University",
                    "Argonne National Laboratory",
                    "Appleone",
                    "Apple Village Assisted Living",
                    "Ansley Golf Club",
                    "Ann Taylor",
                    "Ann Arbor Public Schools",
                    "Amgen",
                    "Amerijet International",
                    "Americorps",
                    "American Red Cross",
                    "American Friends Of The Alexander Von Humboldt Foundation",
                    "American Bureau Of Shipping (Abs)",
                    "Amazon",
                    "Alstom Group",
                    "Alstom",
                    "Alpha Delta Pi",
                    "Allworld Language Consultants, Inc.",
                    "Allina Health",
                    "Allegiant",
                    "Airtran Airways",
                    "Airbnb",
                    "Air National Guard",
                    "Air France Klm Martinair Cargo",
                    "Air France",
                    "Aetc Inc",
                    "Aerotek",
                    "Aeroméxico",
                    "Advocare",
                    "Adventures In Missions",
                    "Adp",
                    "A Taste Of Excellence",
                    "41St Airlift Squadron",
                    "24 Hour Fitness",
                    "21St Century Leaders, Inc.",
                    "21St Century Fox",
                    "133Rd Airlift Wing",
                    "'47",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.44, 0.39, 0.29, 0.2, 0.16, 0.13, 0.13, 0.11, 0.11, 0.09, 0.08, 0.08, 0.08, 0.07, 0.07,
                                0.07, 0.07, 0.07, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04,
                                0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Other Occupations",
                },
            },
            {
                categories: [
                    "Southwest Airlines",
                    "United Airlines",
                    "Pwc",
                    "Walt Disney World",
                    "U.S. House Of Representatives",
                    "The Home Depot",
                    "Northwest Airlines",
                    "Ibm",
                    "Georgia Aquarium",
                    "Ey",
                    "American Airlines",
                    "University Of Georgia",
                    "The Walt Disney Company",
                    "The Coca-Cola Company",
                    "Newell Rubbermaid",
                    "Nbc News",
                    "Merrill Lynch",
                    "Medtronic",
                    "Jetblue",
                    "Fiserv",
                    "Enterprise Rent-A-Car",
                    "Edelman",
                    "Deloitte",
                    "Children'S Healthcare Of Atlanta",
                    "American Red Cross",
                    "Aflac",
                    "Accenture",
                    "Wells Fargo",
                    "Usda",
                    "Ups",
                    "U.S. Department Of Homeland Security",
                    "Tivoli Software",
                    "The White House",
                    "The Metropolitan Museum Of Art",
                    "The Bert Show",
                    "Target",
                    "Symantec",
                    "State Farm",
                    "Sodexo",
                    "Shaw Industries",
                    "Scoutmob",
                    "Raymond James",
                    "Protiviti",
                    "Pricewaterhousecoopers",
                    "Pratt & Whitney",
                    "Norfolk Southern Corporation",
                    "Nike",
                    "Newell Brands",
                    "Nestlé Purina North America",
                    "Milliken & Company",
                    "Mercer",
                    "Lockheed Martin",
                    "Kimberly-Clark Professional",
                    "Ihg Hotels & Resorts",
                    "General Mills",
                    "Ge",
                    "Ford Motor Company",
                    "Florida Hospital",
                    "Fleishmanhillard",
                    "Ernst & Young",
                    "Epting Events",
                    "Deutsche Bank",
                    "Cumulus Media",
                    "Cnn",
                    "Cmc",
                    "Citi",
                    "C.H. Robinson",
                    "Boeing",
                    "Artists First",
                    "Amazon",
                    "Allied Integrated Marketing",
                    "Alaska Airlines",
                    "“Airnet”",
                    "Young Audiences New York",
                    "Youfirst Sports",
                    "Ymca Of San Francisco",
                    "Year Up",
                    "Xcel Talent Agency",
                    "Wyndham Championship",
                    "Wvon 1690 Am",
                    "Wupw Fox Toledo",
                    "Wsbe Rhode Island Pbs",
                    "Wrapsody",
                    "World Team",
                    "Women In Need, Inc. (Win)",
                    "Wofford College",
                    "Wisteria",
                    "Wise Ink Creative Publishing",
                    "Winterthur Museum, Garden And Library",
                    "Wilkes County School Distric",
                    "Widener University",
                    "White Whale Solutions",
                    "West Virginia University",
                    "Werner Real Estate Holdings",
                    "Wellstar Health System",
                    "Watauga County Schools",
                    "Washington Jewish Week",
                    "Warner Music Group",
                    "Vr Networks Inc.",
                    "Vmware",
                    "Vision Critical",
                    "Virgin Islands Water And Power Authority",
                    "Vibe Media",
                    "Vibe Magazine",
                    "Vertone",
                    "Vanderlande",
                    "Us Environmental Protection Agency (Epa)",
                    "Us Airways",
                    "Ups Capital®",
                    "Univision Communications",
                    "Univision",
                    "University Presbyterian Church",
                    "University Of Utah",
                    "University Of St. Thomas",
                    "University Of Louisiana At Lafayette",
                    "Universal Music Group",
                    "Unitedhealth Group",
                    "United Way Of Northwest Georgia",
                    "United Way Of Central Indiana",
                    "United States House Of Representatives",
                    "United States Department Of The Army",
                    "United States Department Of Defense",
                    "United States Attorneys' Offices",
                    "Unified - Hiv Health And Beyond",
                    "Unicef",
                    "Umass Memorial Medical Center",
                    "Ubs Wealth Management",
                    "U.S. Bank",
                    "Turner Broadcasting Systems",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Tribeca Film Institute",
                    "Trelleborg Group",
                    "Travelers",
                    "Travel Incorporated",
                    "Tracy Nicole Clothing",
                    "Tourico Holidays",
                    "Toledo Mud Hens",
                    "Timesys Corporation",
                    "Tiffany & Co.",
                    "Tiaa",
                    "Three Rivers Park District",
                    "Three",
                    "Thermo Fisher Scientific",
                    "The Yankee Candle Company, Inc.",
                    "The Whiting-Turner Contracting Company",
                    "The University Of Oklahoma",
                    "The University Of Auckland",
                    "The Real News Network",
                    "The Printing Port",
                    "The New York Times",
                    "The National Basketball Academy",
                    "The Muddy Water Group",
                    "The Leukemia & Lymphoma Society",
                    "The Leahy Center",
                    "The John F. Kennedy Center For The Performing Arts",
                    "The Hershey Company",
                    "The Hawkins Foundation",
                    "The Goodyear Tire & Rubber Company",
                    "The Georgia Club",
                    "The Estée Lauder Companies Inc.",
                    "The Clorox Company",
                    "The Classic Center",
                    "The Cincinnati Insurance Companies",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "Textron Aviation",
                    "Texas Department Of Family And Protective Services",
                    "Terry Hines & Associates",
                    "Tempe Police Dept",
                    "Tata Teleservices",
                    "Target Corporation",
                    "Tangu Inc",
                    "Tampa Bay Lightning",
                    "Talon Air, Inc.",
                    "Talenthouse",
                    "T-Mobile",
                    "Synovus",
                    "Swagger Gifts",
                    "Svb Financial Group",
                    "Suntrust Robinson Humphrey",
                    "Suntrust Bank",
                    "Student Quarters",
                    "Stratus Management Group Inc",
                    "Stockton University",
                    "Steptoe & Johnson Llp",
                    "State Bank Of Southern Utah",
                    "Staples",
                    "St. Mary'S Health Care System",
                    "St. Louis Blues",
                    "St. Jude Children'S Research Hospital",
                    "Spylight",
                    "Sponsors For Educational Opportunity",
                    "Spelman College",
                    "Spartanburg Herald-Journal",
                    "Spanx",
                    "South Carolina Gamecocks",
                    "Sony Music Entertainment",
                    "Sonic Automotive",
                    "Solar Turbines",
                    "Smith Barney",
                    "Smatsa",
                    "Sixthman",
                    "Sirius Xm Radio Inc.",
                    "Simpler Consulting, An Ibm Company",
                    "Silicon Valley Power",
                    "Signature Properties, Llc",
                    "Shopify",
                    "Sherwin-Williams",
                    "Shell Oil Company",
                    "Shanghai Jinjiang Intl. Travel",
                    "Sesac",
                    "See.Spark.Go",
                    "Seaworld Parks & Resorts Orlando",
                    "Seaworld",
                    "Seattle Pacific University",
                    "Sears Holdings Corporation",
                    "Scripps Networks Interactive",
                    "Scottish Parliament",
                    "Scana Corporation",
                    "Savannah State University",
                    "Sas",
                    "Santa Fe College",
                    "San Diego County Regional Airport Authority",
                    "Sam'S Club",
                    "Salvex",
                    "Saft",
                    "Sacatelle",
                    "Ryan Seacrest Foundation",
                    "Rt Specialty",
                    "Roswell Presbyterian Church",
                    "Rolls-Royce North America",
                    "Rogers Electric",
                    "Rockwell Collins",
                    "Rochester Downtown Alliance",
                    "Revere Data, Llc",
                    "Resolution",
                    "Republic Airways Holdings, Inc.",
                    "Repnation",
                    "Reinsurance Group Of America",
                    "Regional Plan Association",
                    "Red Ventures",
                    "Rcw Media Group",
                    "Raymond James Financial, Inc.",
                    "Ralph Lauren",
                    "Rainforest Films",
                    "Radio One",
                    "R&R Resorts",
                    "Qualcomm",
                    "Push",
                    "Prospect Infosys Inc.",
                    "Privet App",
                    "Prestige Promotions",
                    "Porter Novelli",
                    "Porsche Cars North America",
                    "Pomegranate",
                    "Plus Communications",
                    "Plug And Play Tech Center",
                    "Pittsburgh Clo",
                    "Pioneer Hi Bred International",
                    "Piedmont Airlines",
                    "Pga Tour",
                    "Pfizer",
                    "Perspectives",
                    "Pepsico",
                    "Penn State University",
                    "Penguin Random House",
                    "Pencil, Inc.",
                    "Pearl White Events",
                    "Patten & Patten Inc",
                    "Paste Magazine",
                    "Palm Beach State College",
                    "Otp Industrial Solutions",
                    "Oto Development",
                    "Otis Elevator Co.",
                    "Orlando Repertory Theatre",
                    "Optime Consulting",
                    "Opm",
                    "Openstudy.Com",
                    "Opelika-Auburn News",
                    "Onedigital",
                    "Onecare",
                    "Obama For America",
                    "Northwestern Mutual",
                    "Northrop Grumman",
                    "Northern Jet Management",
                    "Northeastern University",
                    "North Highland",
                    "Nordstrom",
                    "Nob Hill Gazette",
                    "Nigro Karlin Segal Feldstein & Bolno",
                    "Niche Media",
                    "Nextera Energy Resources",
                    "New York Speech Coaching",
                    "New York City Council",
                    "New Life Village",
                    "Netjets",
                    "Nestlé Purina Petcare",
                    "Nemer Fieger",
                    "Nbc Universal",
                    "Nautadutilh",
                    "Nationwide",
                    "National Vision Inc.",
                    "Nasa Langley Research Center",
                    "Nasa Johnson Space Center",
                    "Nasa Ames Research Center",
                    "NASA",
                    "Music@Menlo",
                    "Music World Entertainment",
                    "Museum Of Contemporary Art Chicago",
                    "Mugwump Productions",
                    "Mslgroup",
                    "Mpls.St.Paul Magazine",
                    "Moxie",
                    "Morris, Manning & Martin, Llp",
                    "Morgan Stanley",
                    "Moog Inc.",
                    "Mondelēz International",
                    "Momentum Worldwide",
                    "Moffitt Cancer Center",
                    "Modularity Space",
                    "Modern Luxury",
                    "Mission Health",
                    "Milkglass Creative",
                    "Middle Tennessee State University",
                    "Microsoft",
                    "Michigan Technological University",
                    "Michael Kors",
                    "Metro Quadrado Arquitetura",
                    "Mesaba Airlines",
                    "Mercer University",
                    "Menchie'S Frozen Yogurt",
                    "Melissa Libby & Associates",
                    "Medica",
                    "Me Marketing Services, Llc",
                    "Mckesson",
                    "Mcdonald'S Corporation",
                    "Mccoll Partners",
                    "Mcafee",
                    "Maverick Software Consulting",
                    "Massachusetts Port Authority",
                    "Masco Corporation",
                    "Marlowe",
                    "Marathon Petroleum Corporation",
                    "Mansfield Oil Company Of Gainesville, Inc.",
                    "Manheim",
                    "Make-A-Wish Georgia",
                    "Make-A-Wish Foundation",
                    "Magnolia Coastlands Area Health Education Center, Inc.",
                    "Macy'S",
                    "Lufthansa Cargo",
                    "Lufthansa",
                    "Live Nation Entertainment",
                    "Lift International",
                    "Life Teen, Inc.",
                    "Liberty Mutual Insurance",
                    "Lexington Legends",
                    "Leadingagile",
                    "Landstar",
                    "Landmark Properties",
                    "Lancair International, Llc",
                    "Lakeland Electric",
                    "L3 Technologies",
                    "Krupp Group",
                    "Knowles Corporation",
                    "Khq-Tv",
                    "Ketchum",
                    "Kenney Orthopedics",
                    "Keegan Associates",
                    "Kearney",
                    "Just Got 2 Have It !",
                    "Judson Center",
                    "Jpmorgan Chase & Co.",
                    "Jpmorgan Chase",
                    "Jordan School District",
                    "Johnson Controls",
                    "Johnson & Johnson",
                    "John Hancock Financial Services",
                    "Joe'S Coffee House",
                    "Jll",
                    "Jeff Belzers Chevrolet",
                    "Jbt Aerotech",
                    "Jamestown",
                    "Jacksonville Transportation Authority",
                    "Jackson Spalding",
                    "Jabil",
                    "J.P. Morgan",
                    "J.Mclaughlin",
                    "J.M. Huber Corporation",
                    "Itw",
                    "Islami Bank Bangladesh Limited",
                    "Isaac Mizrahi",
                    "Iris Worldwide",
                    "Ipsos",
                    "International Justice Mission",
                    "International Junior Golf Tour",
                    "Interfaith Hospitality Network",
                    "Intel Corporation",
                    "Inroads",
                    "Innisfree Hotels",
                    "Industrial Measurement Systems",
                    "Indian Institute Of Technology Bombay",
                    "Independent Wealth Tax & Advisory",
                    "Indeed.Com",
                    "Iheartmedia",
                    "Ignition",
                    "Icm Partners",
                    "Hw Creative Marketing",
                    "Human Resource Dimensions",
                    "Hubbard Broadcasting",
                    "Hsbc Bank Usa",
                    "Hope-Beckham, Inc.",
                    "Hope Community Center",
                    "Homrich Berg",
                    "Homewood Suites By Hilton",
                    "Home Depot/Your Other Warehouse",
                    "Hollywood Branded",
                    "Hilton Hotels & Resorts",
                    "Highline Storage Partners",
                    "Hewlett-Packard",
                    "Heritage Financial Consultants, Llc",
                    "Hennepin County",
                    "Health & Science Innovations",
                    "Hd Counseling, Llc",
                    "Hcvt",
                    "Hay Group",
                    "Hawker Beechcraft",
                    "Hartfield Insurance Group, Inc.",
                    "Harley Marine Services, Inc.",
                    "Hanesbrands Inc.",
                    "Hai Group",
                    "Habitat For Humanity Of Northern Virginia",
                    "Habitat For Humanity International",
                    "Haas Center For Public Service",
                    "Groove Commerce",
                    "Greenville-Spartanburg International Airport",
                    "Greenville Hospital System",
                    "Greencastle Development Center",
                    "Green Tree Media",
                    "Green Olive Media",
                    "Great Florida Bank",
                    "Graylion Logistics, Llc",
                    "Grady Health System",
                    "Govyrl, Inc.",
                    "Good Spread",
                    "Goldman Sachs",
                    "Godaddy Bowl",
                    "Glenn C. Jones Middle School",
                    "Give Kids The World Village",
                    "Gilbarco Veeder-Root",
                    "Giant Spoon Llc",
                    "Georgia-Pacific Llc",
                    "Georgia Power Company",
                    "Georgia Pacific",
                    "Georgia Lottery Corporation",
                    "Georgia Institute Of Technology",
                    "Georgia House Of Representatives",
                    "Georgia Dome",
                    "Georgia Conservancy",
                    "Georgia Chapter Of The Sierra Club",
                    "Georgia Association Of Water Professionals (Gawp)",
                    "Ge Power",
                    "Ge Capital",
                    "Ge Aviation",
                    "Gaveteiro.Com.Br",
                    "Gas South",
                    "Gamestop",
                    "Gallman Personnel Services Inc.",
                    "G&K Services",
                    "Furrion",
                    "Fulton County Government",
                    "Fresenius Medical Care",
                    "French/West/Vaughan",
                    "Foxwoods Resort Casino",
                    "Fox Television Stations",
                    "Fox",
                    "Forbes Travel Guide",
                    "Foot Solutions Franchise Company",
                    "Foot Locker",
                    "Flughafen Wien",
                    "Florida State University",
                    "Florida State College At Jacksonville",
                    "Florida House On Capitol Hill",
                    "Florida Blue",
                    "Fitzgerald & Company",
                    "Fifth Third Bank",
                    "Feld Entertainment",
                    "Feeding America",
                    "Federal Express",
                    "Federal Bureau Of Investigation (Fbi)",
                    "Federal Aviation Administration",
                    "Fast Enterprises, Llc",
                    "Fan Experiences",
                    "Fairwinds Credit Union",
                    "Fadó Irish Pub",
                    "Fab'Rik",
                    "Faa",
                    "Expressjet Airlines",
                    "Experience Llc",
                    "Evonik Industries",
                    "Evonik",
                    "Events Dc",
                    "Euroimmun Us",
                    "Espn",
                    "Equifax",
                    "Enterprise",
                    "Enpol Llc",
                    "Endeavor",
                    "Emory University Hospital",
                    "Emory Healthcare",
                    "Emily'S List",
                    "Embrace Home Loans",
                    "Elma Electronic",
                    "El Colombiano",
                    "Eggs Up Grill",
                    "Edmonds Entertainment",
                    "Eaton Vance",
                    "East Orlando Chamber Of Commerce",
                    "Dunwoody Baptist Church",
                    "Dunkin'​ Brands",
                    "Domain Integrated",
                    "Disneyland Resort",
                    "Dish Network",
                    "Discovery World At Pier Wisconsin",
                    "Discovery Communications",
                    "Direct Technology Solutions",
                    "Digital Insurance",
                    "Diesel",
                    "Diamond In The Rough",
                    "Dfw International Community Alliance",
                    "Detroit Wayne Mental Health Authority",
                    "Des Moines Airport Authority",
                    "Dell Emc",
                    "Dea",
                    "Dayta Marketing",
                    "Days End Farm Horse Rescue, Inc.",
                    "Cure Childhood Cancer",
                    "Crowe",
                    "Crowdpac",
                    "Creative Loafing Atlanta",
                    "Cr Bard",
                    "Correctional Medical Associates, Inc. (Cma)",
                    "Coral Springs Center For The Arts",
                    "Continental Airlines",
                    "Congressional Black Caucus Foundation",
                    "Congress Asset Management",
                    "Condé Nast",
                    "Computer Reach",
                    "Common Cause North Carolina",
                    "Commercial Collection Bureau",
                    "Comcast",
                    "Comair",
                    "Collegehumor.Com",
                    "Collegefashionista",
                    "Coca-Cola Enterprises",
                    "Cobb Travel & Tourism",
                    "Cobb Energy Performing Arts Centre",
                    "Coach",
                    "Cmx",
                    "Clifton Cultural Arts Center",
                    "Cleveland Airport System",
                    "Clearvision Optical",
                    "Clayton County Public Schools",
                    "City Of Atlanta",
                    "Cirruslabs",
                    "Cirque Du Soleil",
                    "Ciner Resources",
                    "Cincinnati Reds Hall Of Fame And Museum",
                    "Chrysler Group Llc",
                    "Chooseatl",
                    "Children'S Hospital Of Georgia",
                    "Childfund International",
                    "Chick-Fil-A Corporate Support Center",
                    "Chick-Fil-A",
                    "Chicago Humanities Festival",
                    "Cherry Bekaert Llp",
                    "Charlotte Hornets",
                    "Charlotte Douglas International Airport",
                    "Charlotte Checkers",
                    "Chabot College",
                    "Center For The Development Of Recycling (Cdr)",
                    "Cbs Studios",
                    "Caterpillar Inc.",
                    "Casa Of San Luis Obispo County",
                    "Cartoon Network Latin America",
                    "Carrier",
                    "Carnival Cruise Line",
                    "Carestream",
                    "Cardinal Health",
                    "Capital Results",
                    "Capital Pacific Homes",
                    "Capella University",
                    "Canyon Consulting",
                    "Campus Pride",
                    "Burson-Marsteller",
                    "Buck",
                    "Bridgestone Americas Tire Operations, Llc",
                    "Breensmith",
                    "Brandeis University",
                    "Brain Power",
                    "Bosch",
                    "Booz Allen Hamilton",
                    "Bomgar Corporation",
                    "Boeing Commercial Airplanes",
                    "Boathouse",
                    "Blue Skies Consulting",
                    "Blue Grass Community Foundation",
                    "Blue Cross And Blue Shield Of Minnesota",
                    "Blinq Media",
                    "Black Retail Action Group Inc",
                    "Biofilter Környezetvédelmi Zrt.",
                    "Binghamton University",
                    "Big City Mountaineers",
                    "Big Brothers Big Sisters Of Washtenaw County",
                    "Big Brothers Big Sisters Of The Big Bend",
                    "Bgc Partners",
                    "Beazer Homes",
                    "Beau Box Commercial Real Estate",
                    "Bbwh Insurors",
                    "Bbdo Minneapolis",
                    "Bbdo",
                    "Barrow County Schools",
                    "Bae Systems, Inc.",
                    "Bae Systems",
                    "Automobili Lamborghini S.P.A.",
                    "Audi Ag",
                    "Auburn University",
                    "Atlantic Southeast Airlines",
                    "Atlantic Council",
                    "Atlanta Botanical Garden",
                    "Athens Ford",
                    "Athens Convention & Visitors Bureau",
                    "Athens Banner-Herald",
                    "At&T",
                    "Ashland",
                    "Ashford Advisors",
                    "Arup",
                    "Arm",
                    "Arkansas Blue Cross Blue Shield",
                    "Arizona State University",
                    "Aptean",
                    "Aprio",
                    "Applerouth Tutoring Services",
                    "Applebee'S Neighborhood Grill + Bar",
                    "Apollo Hospitals",
                    "Apogee Enterprises, Inc.",
                    "Animed Products",
                    "Anheuser-Busch Inbev",
                    "Americorps",
                    "American Kidney Stone Management Company",
                    "American Heart Association",
                    "American Flyers",
                    "American Express",
                    "American Enterprise Institute",
                    "American Cancer Society",
                    "American Boa Inc.",
                    "Alu Like, Inc.",
                    "Allegheny County Airport Authority",
                    "Allconnect",
                    "Alixpartners",
                    "Aldi Usa",
                    "Air France-Klm-Martinair Cargo",
                    "Aipac",
                    "Aig",
                    "Aetc Inc",
                    "Aerotek",
                    "Aegean Airlines",
                    "Acxiom",
                    "Actia Electronics",
                    "Acg Group",
                    "Access Media 3",
                    "Academy Of Art University",
                    "Abbott Mead Vickers Bbdo",
                    "Abb Robotics",
                    "Ab Inbev",
                    "72Andsunny",
                    "20Th Century Studios",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.11, 0.08, 0.08, 0.07, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.04, 0.04, 0.04,
                                0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Intern",
                },
            },
            {
                categories: [
                    "Nordstrom",
                    "Northwest Airlines",
                    "Victoria'S Secret",
                    "Best Buy",
                    "American Airlines",
                    "United Airlines",
                    "Macy'S",
                    "Edelman",
                    "Coach",
                    "American Eagle Outfitters Inc.",
                    "Verizon Wireless",
                    "Microsoft",
                    "Kohl'S",
                    "At&T",
                    "Anthropologie",
                    "Air France",
                    "Walmart",
                    "Virgin Atlantic",
                    "Verizon",
                    "Vera Bradley",
                    "Us Airways",
                    "Urban Outfitters",
                    "Total Quality Logistics",
                    "Tmp Worldwide",
                    "Tiffany & Co.",
                    "Terminix",
                    "T-Mobile",
                    "Starwood Hotels & Resorts Worldwide, Inc.",
                    "Sprint",
                    "Spectrum",
                    "Radancy",
                    "Pan Am",
                    "Pacsun",
                    "Octagon",
                    "Mslgroup",
                    "Lufthansa",
                    "Ketchum",
                    "Jcpenney",
                    "Hollister Co.",
                    "Guess?, Inc.",
                    "Gnc",
                    "Gap Inc.",
                    "Forever 21",
                    "Foot Locker",
                    "Delta Private Jets",
                    "Charlotte Russe",
                    "Carmax",
                    "Campus Special",
                    "C.H. Robinson",
                    "Bloomingdale'S",
                    "Belk",
                    "American Eagle Outfitters, Inc.",
                    "Aeropostale",
                    "Aeroméxico",
                    "Yrc Freight",
                    "Yp Marketing Solutions",
                    "Ymca Of Metro Atlanta",
                    "Yext",
                    "Yelp",
                    "Wyndham Worldwide",
                    "Wrigley",
                    "Wow! Internet, Cable & Phone",
                    "Wmc Mortgage",
                    "Williams-Sonoma, Inc.",
                    "Williams And Associates",
                    "West Marine",
                    "Weber Shandwick",
                    "Walt Disney World",
                    "Wallace Carlson Printing",
                    "W Hotels",
                    "Virgin Australia Airlines",
                    "Vilebrequin",
                    "Victoria Secret",
                    "Viant Technology",
                    "Veritas Technologies Llc",
                    "Vector Marketing",
                    "Varsity Painting",
                    "Varig",
                    "Ups Freight",
                    "University Of Minnesota",
                    "Ugg Australia",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Trump International Hotel & Tower Chicago",
                    "Travelex",
                    "Tracylocke",
                    "Tommy Hilfiger",
                    "Time Warner Cable",
                    "The Standard",
                    "The Home Depot",
                    "The Children'S Place",
                    "The Chic Boutique",
                    "The Andersons, Inc.",
                    "Teksystems",
                    "Ted Baker",
                    "Target",
                    "Tamarack Resort",
                    "Swiss International Air Lines",
                    "State Street",
                    "State Farm ®",
                    "Staples",
                    "Sproutloud Media Networks",
                    "Sports Imports, Inc.",
                    "Sport Chalet",
                    "Speedway Llc",
                    "Southern Glazer'S Wine And Spirits",
                    "Soho Clothing",
                    "Social Graces",
                    "Simply Southern",
                    "Sherwin-Williams",
                    "Sebamed Usa",
                    "Sears",
                    "Saxon Fleet Services",
                    "Saudia Cargo",
                    "Samba Tv",
                    "Saks Fifth Avenue",
                    "S & S Business Consultants, Llc",
                    "Ryad Mogador Hotels",
                    "Ross Stores, Inc.",
                    "Rogers Electric",
                    "Ritz-Carlton Hotel Company",
                    "Reunion Resort",
                    "Renaware International",
                    "Relx Group Plc",
                    "Relus Technologies",
                    "Reiss",
                    "Rei",
                    "Red And Black The",
                    "Rc Special Events",
                    "Randstad Technologies Us",
                    "Rag & Bone",
                    "Radio Shack",
                    "R1 Rcm",
                    "Quality Distribution",
                    "Psav",
                    "Prudential California Realty",
                    "Proshares",
                    "Procter & Gamble",
                    "Priester Aviation",
                    "Premium Retail Services",
                    "Pratt & Whitney",
                    "Pr Newswire",
                    "Pottery Barn",
                    "Porter Novelli",
                    "Pier 1",
                    "Phi Mu Fraternity",
                    "Pfizer",
                    "Pearson",
                    "Pan American World Airways",
                    "Pace",
                    "Original Penguin",
                    "Orient Marketing & Trading Company Llc",
                    "Orgsync",
                    "Orangetheory Fitness",
                    "Oncourse Learning",
                    "Old Navy",
                    "Old Guard Graphics",
                    "Ogilvy & Mather",
                    "Nurun",
                    "Nissan Motor Corporation",
                    "Nike",
                    "Newell Brands",
                    "New York Marriott Marquis",
                    "Neiman Marcus",
                    "Ncg Cinemas",
                    "Nationwide",
                    "National Portrait Gallery",
                    "National Fitness Center",
                    "Mullinax Ford Of Kissimmee",
                    "Moxie",
                    "Morrison",
                    "Monumental Sports & Entertainment",
                    "Momentum Worldwide",
                    "Modern Luxury",
                    "Mlt Vacations",
                    "Mktg",
                    "Mitchell'S Fish Market",
                    "Mintel",
                    "Millennium Innovations",
                    "Milano Formals Llc",
                    "Michaels",
                    "Metropcs",
                    "Merck",
                    "Mcmaster-Carr",
                    "Matlen Silver",
                    "Match Eyewear, Llc",
                    "Masimo",
                    "Mary Kay",
                    "Marriott International",
                    "Marketsource, Inc. Careers",
                    "Marissa Collections",
                    "Marietta Daily Journal",
                    "Manning Selvage And Lee",
                    "Macmillan",
                    "Lytx, Inc.",
                    "Lush",
                    "Lufthansa Group",
                    "Lucky Brand",
                    "Lowe'S Companies, Inc.",
                    "Life Time Inc.",
                    "Liberty Mutual Insurance",
                    "Leslie'S",
                    "Le Creuset",
                    "Launch Technical Workforce Solutions",
                    "Lancome",
                    "Lan Airlines",
                    "L.C. Williams & Associates",
                    "Korean Air",
                    "Kmart Corporation",
                    "Kleber & Associates",
                    "Kensington Investment Company",
                    "Kb Toys",
                    "Katz Media Group",
                    "K&G Fashion Superstore",
                    "Journeys",
                    "Jos. A. Bank Clothiers",
                    "Johnson Controls",
                    "Jll",
                    "Jet Aviation",
                    "Japan Airlines",
                    "Intergrupo",
                    "Impressions",
                    "Ihg Hotels & Resorts",
                    "Ibm",
                    "Ibahn",
                    "House Of Harlow 1960",
                    "Honeywell Aerospace",
                    "Hm Wallace, Inc.",
                    "Hilton Worldwide Holdings Inc.",
                    "Hilton Worldwide",
                    "Highland Presents, Inc.",
                    "Henri Bendel",
                    "Havas Sports Entertainment Sas",
                    "Harper'S Bazaar",
                    "Hallmark Cards",
                    "Greystar",
                    "Greenix",
                    "Google",
                    "Goodwill Of Western And Northern Connecticut",
                    "Golin",
                    "Global Aviation",
                    "Georgia Pool Supply Inc",
                    "Gci Health",
                    "Garage Isla Verde, Llc",
                    "Gap",
                    "Gamestop",
                    "Galleria Riverside",
                    "G&S Business Communications",
                    "Funeral Home",
                    "Frito-Lay",
                    "Fox Sports University",
                    "Flynn Transportation Services, Inc.",
                    "First Acceptance Insurance Company Of Tennessee, Inc.",
                    "Finish Line",
                    "Fastenal Company",
                    "Fagron, Inc.",
                    "Express Employment Professionals",
                    "Express",
                    "Evolve Discovery",
                    "Eliassen Group",
                    "Eli Lilly And Company",
                    "Electronic Data Systems",
                    "Dsw",
                    "Drei Österreich",
                    "Dpm Mellon",
                    "Dish Network",
                    "Discovery Inc",
                    "Dillard'S",
                    "Diageo",
                    "Dhl Express",
                    "Ddb",
                    "Cydcor",
                    "Crossmark",
                    "Cos",
                    "Compusa",
                    "Comcast Spotlight",
                    "Client Command",
                    "Clearent",
                    "Clearchannel Radio Inc",
                    "Cintas First Aid & Safety",
                    "Chico'S Fas, Inc.",
                    "Chicken Salad Chick",
                    "Chanel",
                    "Champs Sports",
                    "Champion Air",
                    "Centerpoint Marketing",
                    "Cellular Sales",
                    "Ceb",
                    "Cathay Pacific",
                    "Carters Inc.",
                    "Carlson Rezidor Hotel Group",
                    "Carlson Print Group",
                    "Capitol Auto Group",
                    "Canon Solutions America",
                    "Calvert Research And Management",
                    "Cablevision",
                    "Buzz Points, Inc.",
                    "Bumble And Bumble",
                    "British Airways",
                    "Boathouse",
                    "Bnc National Bank",
                    "Bleacher Report",
                    "Bkv",
                    "Biomashinostroene Ad",
                    "Big Ass Fans",
                    "Bergdorf Goodman",
                    "Benetton Group",
                    "Bellsouth",
                    "Bed Bath & Beyond",
                    "Bbdo Worldwide",
                    "Bbdo",
                    "Bath & Body Works",
                    "Banyan Air Service",
                    "B/E Aerospace",
                    "B In The Walt Disney World® Resort",
                    "Autozone",
                    "Atlanta Braves",
                    "Arizona Cardinals Football Club",
                    "Appleone",
                    "Apple Market",
                    "Apple",
                    "Apex Systems",
                    "Ann Inc.",
                    "American Virtual Cloud Technologies",
                    "American Traveler",
                    "American Express",
                    "Allsaints",
                    "Allina Health",
                    "Allen Edmonds",
                    "All Golf Services",
                    "Aldo Group",
                    "Airbnb",
                    "Air Canada",
                    "Aerotek",
                    "Advantage Solutions: Sales, Marketing, Technology",
                    "Advanced Tissue",
                    "Adidas Group",
                    "Acuity Brands",
                    "Accor",
                    "Acceptance Insurance",
                    "Abercrombie & Fitch",
                    "A-A Island Auto Rental",
                    "39Th Street Auto",
                    "22Squared",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.12, 0.09, 0.08, 0.08, 0.08, 0.07, 0.05, 0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04,
                                0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Sales",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "Skywest Airlines",
                    "Northwest Airlines",
                    "Expressjet Airlines",
                    "Compass Airlines",
                    "Atlantic Southeast Airlines",
                    "Us Navy",
                    "United Airlines",
                    "American Airlines",
                    "Republic Airways Holdings, Inc.",
                    "Trans States Airlines",
                    "Sun Country Airlines",
                    "Republic Airways",
                    "Jetblue",
                    "Continental Airlines",
                    "Alaska Airlines",
                    "Southwest Airlines",
                    "North American Airlines",
                    "Mesaba Airlines",
                    "Envoy Air",
                    "Delta Global Services – Staffing",
                    "Atlas Air",
                    "American Eagle Airlines",
                    "Western Airlines",
                    "United States Marine Corps",
                    "The Home Depot",
                    "Swissport",
                    "Psa Airlines, Inc.",
                    "Pan Am",
                    "Horizon Air",
                    "Frontier Airlines",
                    "Worldwide Flight Services",
                    "Virgin Atlantic",
                    "Us Air Force Reserve",
                    "Trans World Airlines",
                    "Spirit Airlines",
                    "Prospect Airport Services, Inc.",
                    "Piedmont Airlines",
                    "Mesa Airlines, Inc.",
                    "Lyft",
                    "Kalitta Air",
                    "Gojet Airlines",
                    "Comair",
                    "Atlantic Coast Airlines",
                    "Ata Airlines",
                    "Allegiant",
                    "“Airnet”",
                    "Vision Airlines",
                    "Virgin America",
                    "Vfa-106 Gladiators",
                    "Vfa-106",
                    "Us Navy Reserves",
                    "Us Army",
                    "Us Airways",
                    "University Of North Dakota",
                    "Universal Staffing Services",
                    "United States Special Operations Command",
                    "United States Navy Reserve",
                    "United States Navy",
                    "Uber",
                    "U.S. Navy",
                    "U.S. Coast Guard",
                    "Transpac Aviation Academy",
                    "Trans World Airlines (Twa)",
                    "The Advocate & Democrat",
                    "Texas Air National Guard",
                    "Teradata",
                    "Target",
                    "Surf Air",
                    "Silverhawk Aviation",
                    "Sheppard Air Force Base",
                    "Self Employed",
                    "S&K Aerospace, Llc",
                    "Royal Air Force",
                    "Qatar Airways",
                    "Purdue University",
                    "Priester Aviation",
                    "Pinnacle Airlines, Inc.",
                    "Patrol Squadron 9",
                    "Pan American World Airways",
                    "Pan American Airways Global Holdings",
                    "Pacific Aviation",
                    "Omni Air International (Oai)",
                    "Old Navy",
                    "Norwegian Air Shuttle",
                    "Northwest Airlink",
                    "Nordstrom",
                    "New York Army National Guard",
                    "Minnesota National Guard",
                    "Minnesota Air National Guard",
                    "Middle Tennessee State University",
                    "Mercury Air Cargo",
                    "Menzies Aviation",
                    "Menards Inc",
                    "Mc Aviation",
                    "Lsg Sky Chefs",
                    "Logisticare",
                    "L3 Technologies",
                    "L-3 Communications",
                    "Korean Air",
                    "Kiwi International Air Lines Inc",
                    "K&S Aviation Services, Inc.",
                    "Japan Airlines",
                    "Independence Air",
                    "Hopscotch Air",
                    "Hill Aircraft & Leasing Corporation",
                    "High Exposure Aerial Advertisting",
                    "Great Lakes Airlines",
                    "Geovantage",
                    "Georgia-Pacific Llc",
                    "Gat Airline Ground Support Inc.",
                    "Freelance",
                    "Flight Services International, Llc",
                    "Fedex Services",
                    "Fedex",
                    "Federal Aviation Administration",
                    "Eos Airlines",
                    "Edwards Jet Center",
                    "Ecolab",
                    "Dgs",
                    "Delta Private Jets",
                    "Delta Global Staffing",
                    "Defense Contract Management Agency (Dcma)",
                    "Deer-Grove Emergency Medical Services",
                    "Czarnowski",
                    "Continental Express",
                    "Compass Collective",
                    "Comair Airlines",
                    "Cologne Bonn Airport (Köln Bonn Airport)",
                    "Cirrus Aircraft",
                    "Chautauqua Airlines",
                    "Central Air Southwest Inc",
                    "Cathay Pacific",
                    "Cardinal Health",
                    "Canada 3000 Airlines",
                    "Boeing",
                    "Bg Group",
                    "Bemidji Aviation",
                    "Beechcraft",
                    "Avera Mckennan Hospital & University Health Center",
                    "Avenge Inc.",
                    "Atp Flight School",
                    "Ameriflight, Llc",
                    "American Kidney Stone Management Company",
                    "America West Airlines",
                    "Alpha Industries, Inc.",
                    "Allegiant Air",
                    "Airline",
                    "Air New Zealand",
                    "Air National Guard",
                    "Air France",
                    "Air Force Retiree Services",
                    "Air Force Reserve Command",
                    "Air America Flight Center",
                    "Aeropostal Alas De Venezuela",
                    "Aero Air",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.55, 0.27, 0.27, 0.19, 0.12, 0.11, 0.09, 0.09, 0.09, 0.08, 0.07, 0.07, 0.07, 0.07,
                                0.07, 0.07, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04,
                                0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Transportation Services",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "University Of North Dakota",
                    "Us Navy",
                    "Purdue University",
                    "Georgia Institute Of Technology",
                    "Northwest Airlines",
                    "Western Michigan University",
                    "Atp Flight School",
                    "Us Air Force Reserve",
                    "Transpac Aviation Academy",
                    "Mesaba Airlines",
                    "Flightsafety International",
                    "Embry-Riddle Aeronautical University",
                    "Varsity Spirit",
                    "University Of Illinois At Urbana-Champaign",
                    "University Of Georgia",
                    "United States Marine Corps",
                    "Teach For America",
                    "Southern Illinois University",
                    "Self-Employed",
                    "Republic Airways Holdings, Inc.",
                    "Middle Tennessee State University",
                    "Louisiana Tech University",
                    "Kennesaw State University",
                    "Jetblue",
                    "Independence Aviation",
                    "Granite School District",
                    "Fulton County Schools",
                    "Farmingdale State College",
                    "Expressjet Airlines",
                    "Epik (English Program In Korea)",
                    "Dct Aviation",
                    "Compass Airlines",
                    "Bridgewater State University",
                    "Auburn University",
                    "American Flyers",
                    "Youth Digital",
                    "Ymca",
                    "Yes Program",
                    "Yakult Usa",
                    "Wichita Public Schools - Usd259",
                    "White Bear Lake Area Schools, Isd #624",
                    "Western Governors University",
                    "Wesley",
                    "Weatherstone Dolphins Swim Team",
                    "Wake Forest University",
                    "Vorys, Sater, Seymour And Pease Llp",
                    "Vmfat-101 Sharpshooters",
                    "Virgin America",
                    "Village Leadership Academy",
                    "Vanderbilt University",
                    "Utah Valley University",
                    "Utah State University",
                    "Utah Speed Academy",
                    "Us Army Reserve",
                    "University Place School District",
                    "University Of Texas At Dallas",
                    "University Of Southern Mississippi",
                    "University Of South Carolina",
                    "University Of Northwestern – St. Paul",
                    "University Of North Carolina At Chapel Hill",
                    "University Of Minnesota",
                    "University Of Michigan-Dearborn",
                    "University Of Michigan Flyers Inc",
                    "University Of Massachusetts",
                    "University Of Florida",
                    "University Of Dayton",
                    "University Of Central Missouri",
                    "University Of Central Florida",
                    "Universidad San Sebastián",
                    "Universal Cheerleaders Association",
                    "Universal Air Academy",
                    "United Studios Of Self Defense",
                    "United States Navy Reserve",
                    "United States Navy",
                    "United Flight Systems",
                    "U.S. Navy Reserve",
                    "Tutor Doctor",
                    "Trident Aircraft",
                    "Trans World Airlines",
                    "Training Squadron 27 (Vt-27)",
                    "Tom Wood Aviation",
                    "Thunderbird Aviation, Inc",
                    "Thinkful",
                    "Think Together",
                    "The Sipping Plant",
                    "The Salvation Army Kroc Center Coeur D'Alene",
                    "The Pantry",
                    "The Ohio State University",
                    "The Museum Of Flight",
                    "The Goddard School",
                    "Texas A&M University",
                    "Super Soccer Stars",
                    "Suntrust Bank",
                    "Sunset Ridge Racquet Swim Clb",
                    "Sun Country Airlines",
                    "Start The Heart Foundation Inc",
                    "Starbucks",
                    "Stafford County Public Schools",
                    "St. Cloud State University",
                    "Spanaflight",
                    "Six Flags",
                    "Silver Airways",
                    "Sheppard Air Force Base",
                    "Shelby County Schools",
                    "Shahrood University Of Technology",
                    "Self Employed",
                    "Seattle Public Schools",
                    "Salt Lake City School District",
                    "Royal Navy",
                    "Rockwood School District",
                    "Pure Barre",
                    "Psa Airlines, Inc.",
                    "Primrose Schools",
                    "Pricewaterhousecoopers",
                    "Porter Hospital",
                    "Planesense, Inc.",
                    "Pinellas County Schools",
                    "Perimeter College At Georgia State University",
                    "Penn State University",
                    "Paul Pellicoro'S Dancesport Ballroom Llc",
                    "Pan Am International Flight Academy",
                    "Old Dominion University",
                    "Oklahoma State University",
                    "Novell",
                    "Notre Dame High School",
                    "Northwest Airlines, Inc",
                    "Northside Achievement Zone (Naz)",
                    "Northeastern University",
                    "North-Aire Aviation",
                    "North Eastern Services",
                    "North Central University",
                    "New Mission High School",
                    "Netjets",
                    "Naval Air Systems Command",
                    "Nassau Flyers",
                    "Minnesota State University, Mankato",
                    "Milford Mill Academy Marching Band",
                    "Metropolitan State University",
                    "Mesa Airlines, Inc.",
                    "Mcdonald'S",
                    "Mcair Aviation",
                    "Mathnasium - The Math Learning Center",
                    "Marinello Schools Of Beauty",
                    "Madison City Schools, Madison, Al",
                    "Louisiana State University",
                    "Life Time Fitness",
                    "Liberty University",
                    "Learn Behavioral",
                    "Lanier Flight Center",
                    "Lamar University",
                    "L-3 Communications",
                    "Kumon North America, Inc.",
                    "Kumon",
                    "Kent State University",
                    "Kansas State University",
                    "Jordan School District",
                    "James Madison University",
                    "Jacksonville University",
                    "Isd 196",
                    "Interactive College Of Technology",
                    "Integration Innovation, Inc.",
                    "Inflight Pilot Training",
                    "Illinois State University",
                    "Illinois Aviation Academy",
                    "Homann Karate Do Inc",
                    "Heritage Flight Academy",
                    "Heritage E-Stem Magnet Middle School",
                    "Henry County Public Schools",
                    "Haslam College Of Business At The University Of Tennessee",
                    "Hale",
                    "Gwinnett Ballet Theatre",
                    "Guyana Airways",
                    "Greenville Recreation Department",
                    "Green River College",
                    "Gotta Dance Atlanta",
                    "Goddard-Riverside Head Start",
                    "Global Youth Ambassadors Program",
                    "Glendale Community College",
                    "Georgia State University College Of Arts & Sciences",
                    "Georgia Southern University",
                    "Georgia School Boards Association",
                    "Georgia Department Of Human Resources",
                    "Georgia College & State University",
                    "Georgia College",
                    "Georgia Christian University",
                    "Geico",
                    "Garden City High School",
                    "Galaxy Fbo",
                    "G4S",
                    "Freelance",
                    "Freedom Aviation",
                    "Fred Astaire Dance Studio",
                    "English For Internationals",
                    "East Coast Aero Club",
                    "Drake School Of Irish Dance",
                    "Dominion Aviation",
                    "Dodea",
                    "Dexis",
                    "Developmental Disabilities Institute (Ddi)",
                    "Delta Global Services",
                    "Delta Connection Academy",
                    "Delaware Air National Guard",
                    "Dc Reads",
                    "Crabapple Academy",
                    "Cornell University",
                    "Corepower Yoga",
                    "Community Mainstreaming Associates",
                    "Comcast",
                    "Columbia Middle School",
                    "College Prep Elementary",
                    "Clayton State University",
                    "Clark County School District",
                    "Ck Danceworks",
                    "Charlotte-Mecklenburg Schools",
                    "Chandler Public Library",
                    "Cerner Corporation",
                    "Central Flying Service, Inc.",
                    "Caribou Coffee",
                    "Cae",
                    "Bucknell University",
                    "Bryan Glazer Family Jcc",
                    "Brody Square - Michigan State University",
                    "Boeing",
                    "Blackbaud",
                    "Beachbody",
                    "Barbizon Usa",
                    "Bank Of America",
                    "Avon United Methodist Church",
                    "Avon Community School Corporation",
                    "Augusta University",
                    "Atlanta Swim Academy",
                    "Atlanta Public Schools",
                    "Arizona State University",
                    "Apx Strength",
                    "Apple",
                    "Anoka-Hennepin School District",
                    "Amway",
                    "Ameriflight, Llc",
                    "American University Of Armenia",
                    "American Theater Company",
                    "American Express",
                    "American Eagle Outfitters, Inc.",
                    "American Airlines",
                    "America West Airlines",
                    "Amc Test And Evaluation Squadron",
                    "Alaska Airlines",
                    "Air Wisconsin Airlines",
                    "Air Test And Evaluation Squadron 30 (Vx-30)",
                    "Air Force Reserve Command",
                    "Air Force Global Strike Command",
                    "Aids Center Of Queens County",
                    "Abbott Middle School",
                    "89Th Airlift Wing",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.53, 0.16, 0.11, 0.11, 0.09, 0.08, 0.07, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Coach / Professor / Mentor",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "Walmart",
                    "Us Airways",
                    "United States Air Force",
                    "United Airlines",
                    "Enterprise Rent-A-Car",
                    "Dgs",
                    "Best Buy",
                    "Accenture",
                    "Ups",
                    "University Of Georgia",
                    "The Home Depot",
                    "Tcf Bank",
                    "Target",
                    "Swissport",
                    "Starbucks",
                    "Sprint",
                    "Prospect Airport Services, Inc.",
                    "Macy'S",
                    "Jetblue",
                    "Hertz",
                    "Eenhoorn Llc",
                    "Dollar Tree Stores",
                    "Deloitte",
                    "Booz Allen Hamilton",
                    "Bank Of America",
                    "At&T",
                    "American Airlines",
                    "Yoforia",
                    "Wow! Internet, Cable & Phone",
                    "Wow Air",
                    "Worldpay",
                    "World Ice Arena",
                    "Wi Memphis Llc",
                    "Wet Willie'S",
                    "Wendy'S",
                    "Wells Fargo",
                    "Weber State University",
                    "Wallace Library",
                    "Waldorf Astoria Park City",
                    "Waldorf Astoria Hotels & Resorts",
                    "Virgin Atlantic",
                    "Virgin America",
                    "Vie De France",
                    "Victoria'S Secret",
                    "Verizon Connect",
                    "Verizon",
                    "Vector Marketing",
                    "Utah Shakespeare Festival",
                    "Us Navy",
                    "Us Army",
                    "Upfundraising",
                    "University Of Richmond",
                    "United States Marine Corps",
                    "United Parcel Service",
                    "Ulta Beauty",
                    "U.S. Customs And Border Protection",
                    "Turner Broadcasting System",
                    "Tumi",
                    "Tucanos Brazilian Grill",
                    "Transportation Security Administration (Tsa)",
                    "Transpac Aviation Academy",
                    "Tom Thumb Supermarket",
                    "The Walt Disney Company",
                    "The University Of Oklahoma",
                    "The Siegfried Group",
                    "The Porch Restaurant",
                    "The Coca-Cola Company",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "The Chocolate A Dessert Cafe",
                    "Terminus Real Estate",
                    "Teach For America",
                    "Tarom",
                    "Tag Education Collaborative",
                    "Surfacing Solution",
                    "Suntrust Bank",
                    "Sun Country Airlines",
                    "Subway",
                    "Sta Travel",
                    "Southwest Airlines",
                    "Southern Utah University",
                    "Southern Belle Farms",
                    "Souper Salad",
                    "Smcp Group",
                    "Skip Of Ny",
                    "Six Flags",
                    "Signature Consultants",
                    "Sigma Nu Gamma Alpha",
                    "Shell",
                    "Shake Shack",
                    "Segs In The City",
                    "Schurman Retail Group",
                    "Samaritan'S Purse",
                    "Sam'S Club",
                    "Salad Creations",
                    "S.C. Department Of Health & Environmental Control",
                    "Royal Navy",
                    "Rizzetta & Company",
                    "Riviera Hotel & Casino",
                    "Ritz-Carlton Hotel Company",
                    "Ripple Rock Fish Farms, Llc",
                    "Ricoh Americas Corporation",
                    "Revenue Analytics, Inc.",
                    "Restorative Justice Community Action",
                    "Reston Association",
                    "Republic Finance",
                    "Reef Radio Electronics",
                    "Red State Bbq",
                    "Rbc Global Asset Management",
                    "Ray Meyer Fitness And Recreation Center",
                    "Ramco Systems",
                    "Raisin Charter Township",
                    "Pruitthealth",
                    "Premier Parking",
                    "Pratt & Whitney",
                    "Political Connection",
                    "Pfs - Professional Financial Services",
                    "Pepperidge Farm",
                    "Penske Truck Leasing",
                    "Payless",
                    "Parkway Corporation",
                    "Park Place Hotel",
                    "Panera Bread",
                    "Pace Organization Of Rhode Island",
                    "Pablo G. Miranda Painting Contractor Llc",
                    "Outback Steakhouse",
                    "Nrg Energy",
                    "Norwegian Cruise Line",
                    "Northern Trust Corporation",
                    "Northeastern University",
                    "Norfolk Southern Corporation",
                    "Nordstrom",
                    "Nike",
                    "Nicholas House, Inc.",
                    "Nice Ltd",
                    "Nestlé Usa",
                    "Nail Time Salon",
                    "Motorola",
                    "Morphotrust Usa",
                    "Mohawk Industries",
                    "Mmr",
                    "Millercoors",
                    "Microsoft",
                    "Michigan Medicine",
                    "Mckinsey & Company",
                    "Mcdonald'S",
                    "Mccormack Baron Companies",
                    "Marsh",
                    "Marriott International",
                    "Marine Corps Base Quantico",
                    "Marathon Petroleum Corporation",
                    "Mansfield Oil Company Of Gainesville, Inc.",
                    "Lyon Living",
                    "Los Angeles Unified School District",
                    "Loft",
                    "Lockheed Martin Aeronautics",
                    "Lindy Promotions",
                    "Levy Restaurants",
                    "Leon County Government",
                    "Launch Technical Workforce Solutions",
                    "Lands' End",
                    "Lan Airlines",
                    "Kraft Foods",
                    "Kpmg",
                    "Kimpton Hotels & Restaurants",
                    "Kfs Industries Inc.",
                    "Ken Garff Automotive Group",
                    "Kaplan Test Prep",
                    "Kalitta Air",
                    "Judson Center",
                    "Journeys",
                    "Jervis B. Webb Company",
                    "Jcpenney",
                    "James Madison University",
                    "Ist Management",
                    "Irs Volunteer Income Tax Assistance Program",
                    "Intuit",
                    "Interim Healthcare Inc.",
                    "Interface",
                    "Intel Corporation",
                    "Innovative Consultants",
                    "Iif Data Solutions",
                    "Ihg Hotels & Resorts",
                    "Ideal Dental Services",
                    "Hyatt Regency",
                    "Hudson River Group",
                    "Hotel Bel-Air",
                    "Hooters Of America",
                    "Holy Family University",
                    "Hollstadt & Associates",
                    "Hka Strategy",
                    "Hewitt Associates",
                    "Hale",
                    "Guppy Media | Guppy Games",
                    "Guess?, Inc.",
                    "Guaranty Bank Fsb",
                    "Guaranty Bank",
                    "Greentreepc",
                    "Greater Southeast Management",
                    "Graylion Logistics, Llc",
                    "Grand Hyatt Kauai Resort & Spa",
                    "Global Eagle",
                    "Georiga Tech Campus Recreation Center",
                    "Georgia Southern University",
                    "Georgia Institute Of Technology",
                    "Georgia Division Of Family & Children Services",
                    "Geometric Construction Ltd.",
                    "Genuine Parts Company",
                    "General Motors",
                    "Ge Capital",
                    "Gap Inc.",
                    "Gap",
                    "Fremantle",
                    "Footlocker.Com",
                    "Foot Locker",
                    "Firestone Walker Brewing Company",
                    "Fidelity Investments",
                    "Federal Home Loan Bank Of Atlanta",
                    "Federal Bureau Of Investigation (Fbi)",
                    "Fca Fiat Chrysler Automobiles",
                    "Farmington Centers, Inc.",
                    "Fairway Market",
                    "Ey",
                    "Expressjet Airlines",
                    "Executive Office Of The President",
                    "Ernst & Young",
                    "Encompass Digital Media, Inc.",
                    "Emergency Management Services International, Inc.",
                    "Elevation, Inc.",
                    "Eglobaltech",
                    "Efunds",
                    "Eac Network (Formerly Education & Assistance Corp.)",
                    "Disney Cruise Line",
                    "Delta Private Jets",
                    "Delta Global Services – Staffing",
                    "Dekalb County Government",
                    "Dct Aviation",
                    "Datasite",
                    "Dairy Queen",
                    "Daimler Chrysler",
                    "Cvs Health",
                    "Cse",
                    "Cox Communications",
                    "Council Travel",
                    "Cortland",
                    "Contract Business Interiors, Inc.",
                    "Condor",
                    "Compass Collective",
                    "Comcast",
                    "College Pro",
                    "Coffee Exchange",
                    "Clydes Market",
                    "Club Monaco",
                    "Clove + Hallow",
                    "Cloud Logistics By E2Open",
                    "Clinique",
                    "City Of Atlanta",
                    "City Furniture",
                    "Citifinancial",
                    "Cinemagic",
                    "Chase",
                    "Champion Air",
                    "Central Parking System",
                    "Caterpillar Inc.",
                    "Carnival Cruise Line",
                    "Carnegie Mellon University",
                    "Caribou Coffee",
                    "Cargolux",
                    "Cargill",
                    "Capannari Ice Cream",
                    "Canyon Ranch",
                    "C3",
                    "Bureau Of Education & Research",
                    "Brookfield Country Club",
                    "Big Brothers Big Sisters Of The Great Lakes Bay Region",
                    "Bellsouth",
                    "Bell Flight",
                    "Bds Marketing",
                    "Bcbgmaxazriagroup",
                    "Bcbg Max Azria",
                    "Baja Sol Restaurant Group, Inc.",
                    "Aviation Port Services",
                    "Avera Mckennan Hospital & University Health Center",
                    "Aver, Inc.",
                    "Arthur Andersen Business Consulting",
                    "Arizona State University",
                    "Arby'S",
                    "Aramark",
                    "Appleone",
                    "Apple",
                    "American Sales And Management Organization Corporation",
                    "American Eagle Outfitters, Inc.",
                    "American Apparel",
                    "America West Airlines",
                    "Amazon",
                    "Alumax",
                    "Alabama Shakespeare Festival",
                    "Airtran Airways",
                    "Air Arabia",
                    "Aig",
                    "Aeroméxico",
                    "Aeg",
                    "Action For Boston Community Development, Inc.",
                    "Act Services",
                    "Achieve3000",
                    "Acco Brands",
                    "Abercrombie & Fitch",
                    "22Squared",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.2, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Middle Management",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "Starbucks",
                    "Jetblue",
                    "Alaska Airlines",
                    "Walt Disney World",
                    "The Walt Disney Company",
                    "Outback Steakhouse",
                    "American Airlines",
                    "Whole Foods Market",
                    "University Of Georgia",
                    "United Airlines",
                    "The Coffee Bean & Tea Leaf",
                    "Steak N Shake",
                    "Patina Restaurant Group",
                    "Mesaba Airlines",
                    "Buffalo Wild Wings",
                    "Best Buy",
                    "Bank Of America",
                    "Banana Republic",
                    "At&T",
                    "Aramark",
                    "Applebee’S International, Inc.",
                    "Allstate",
                    "Airtran Airways",
                    "Aeroméxico",
                    "“Airnet”",
                    "Zinburger Wine And Burger Bar",
                    "Yard House",
                    "Xerox",
                    "Walser Automotive Group",
                    "Walmart",
                    "Walgreens",
                    "Virgin Atlantic",
                    "Virgin America",
                    "Vanderlande",
                    "Urban Growler Brewing Company",
                    "Ups",
                    "Universal Studios",
                    "Union Bank",
                    "Uline",
                    "U.S. Cellular",
                    "Tutta Bella Neapolitan Pizzeria",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Tribridge",
                    "Trego-Dugan Aviation Inc",
                    "Tokyo Japanese Steak House",
                    "Time Warner Cable",
                    "Thirsty Lion Gastropub & Grill",
                    "The Ups Store",
                    "The Snip-Its Franchise Company",
                    "The Rock Sports Complex",
                    "The Pub",
                    "The Pinery Country Club",
                    "The Ohio State University Airport",
                    "The Nomad World Pub",
                    "The National",
                    "The Home Depot",
                    "The Collier Companies",
                    "Tgi Fridays",
                    "Telelogic",
                    "Teakwoods Tavern And Grill",
                    "Taliya",
                    "Swissport",
                    "Sunfish Grill",
                    "Suncadia Resort",
                    "Sun Country Airlines",
                    "Steve Madden",
                    "Steritech",
                    "State Farm ®",
                    "Spirit Airlines",
                    "Spherion",
                    "Southwest Airlines",
                    "Snooze An A.M. Eatery",
                    "Smart & Final",
                    "Skywest Airlines",
                    "Six Flags",
                    "Signature Flight Support",
                    "Siemens Digital Industries Software",
                    "Sheraton Duluth Hotel",
                    "Sedgwick",
                    "Sears Holdings Corporation",
                    "Schlotzsky'S",
                    "Sand Castle Inc",
                    "Samba Tv",
                    "Ruth'S Chris Steak House",
                    "Royal Caribbean International",
                    "Rocket Farm Restaurants Llc",
                    "Riverwalk Restaurant",
                    "Ritz-Carlton Hotel Company",
                    "Reiss",
                    "Publix Super Markets",
                    "Prospect Airport Services, Inc.",
                    "Primerevenue",
                    "Portofino Island Resort",
                    "Polynesian Cultural Center",
                    "Pnc",
                    "Pizza Hut",
                    "Pizza Bar",
                    "Piedmont Airlines",
                    "Paul Hastings",
                    "Partners Ii Pizza",
                    "Park City Coffee Roaster",
                    "Panera Bread",
                    "Pacific Rim Bistro",
                    "Osteomed",
                    "Olive Garden",
                    "Old Chicago Restaurants",
                    "Northwestern Mutual",
                    "Newco Airport Services S.A.",
                    "Netflix",
                    "Nco Group",
                    "Murphy'S Restaurant, Llc",
                    "Mountain America Credit Union",
                    "Morgan White Group",
                    "Morgan Stanley",
                    "Mintel",
                    "Metropolitan Airports Commission",
                    "Messaging Architects",
                    "Merrill Lynch",
                    "Mellow Mushroom",
                    "Max & Erma'S",
                    "Matlen Silver",
                    "Marsh Mclennan",
                    "Marriott International",
                    "Marriott Hotels",
                    "Manheim",
                    "Manhattan Associates",
                    "Lowe'S Companies, Inc.",
                    "Louis Vuitton",
                    "Lord & Taylor",
                    "Liberty Alliance",
                    "Lenovo",
                    "Learningrx",
                    "Le Peep Restaurant",
                    "Kuehne + Nagel",
                    "Kohl'S",
                    "Kantar",
                    "Jetro Restaurant Depot",
                    "Jeff Ruby Culinary Entertainment",
                    "Jamaica National Building Society",
                    "International Airline Services Ltd",
                    "Intercruises Shoreside & Portside",
                    "Instacart",
                    "Independence Aviation",
                    "Ihg Hotels & Resorts",
                    "Ibahn",
                    "Hyatt Regency Boston",
                    "Hyatt Hotels Corporation",
                    "Humana",
                    "Hilton",
                    "Hertz",
                    "Healthcare It Leaders",
                    "Harris Teeter",
                    "Hard Rock Cafe",
                    "Hallmark Aviation",
                    "Ghyslain Chocolatier",
                    "Georgia-Pacific Llc",
                    "Georgia State University",
                    "General Dynamics Information Technology",
                    "Gateway Group One",
                    "Gap",
                    "Gabbar Restaurant",
                    "Freshens",
                    "Follett Higher Education Group",
                    "Firstline Creative & Media, Llc",
                    "Fedex Trade Networks Transport & Brokerage",
                    "Fedex Trade Networks",
                    "Faneuil Inc",
                    "Expedia, Inc.",
                    "Enterprise Rent-A-Car",
                    "Edible Arrangements",
                    "Earls Kitchen + Bar",
                    "E2Open",
                    "Dos Caminos Mexican Restaurant",
                    "Domino'S",
                    "Dnata",
                    "Dgs",
                    "Deutsche Bank",
                    "Dell Emc",
                    "Decisionone",
                    "Dave & Buster'S Inc.",
                    "Darden Restaurants",
                    "Crowdpac",
                    "Crossroads Cafe",
                    "Cracker Barrel",
                    "Costco Wholesale",
                    "Conseco Finance Service Corp",
                    "Conn'S Homeplus",
                    "Conduent Hr Services",
                    "Compass Group",
                    "Common Roots Cafe",
                    "Comcast Cable Communications, Llc",
                    "Comcast",
                    "Comair",
                    "Collaborative Real Estate",
                    "Coffee Rush",
                    "Cmt Agency",
                    "Cinemark",
                    "Cinco Mexican Cantina",
                    "Chevys Fresh Mex Sol Llc",
                    "Chartwells Higher Education Dining Services",
                    "Centurylink",
                    "Centerplate",
                    "Catholic Charities Usa",
                    "Carvana",
                    "Carrabba'S Italian Grill",
                    "Cardinal Health",
                    "Cape Air",
                    "Calvert Research And Management",
                    "California State University Long Beach",
                    "Burke, Inc.",
                    "British Airways",
                    "Brio Tuscan Grille",
                    "Brash Coffee Roasters",
                    "Bny Mellon",
                    "Bluegrass Hospitality Group",
                    "Bluecross Blueshield Of South Carolina",
                    "Bistro Niko",
                    "Beavex, Inc.",
                    "Bay Head Yacht Club",
                    "Barnes And Noble Book Store",
                    "Avianca",
                    "Augusta National Golf Club",
                    "Atlantic Aviation",
                    "Athens Country Club",
                    "Asurion",
                    "Assurant",
                    "Aspca",
                    "Aramark Refreshment Services",
                    "Anthem, Inc.",
                    "Anthem",
                    "American Express Global Business Travel",
                    "Allen Health Care, Inc.",
                    "Alitalia Compagnia Aerea Italiana Spa",
                    "Air France",
                    "Aimbridge Hospitality",
                    "Achieve3000",
                    "Accenture",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.16, 0.12, 0.08, 0.05, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Customer Services",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "Northwest Airlines",
                    "Cognizant",
                    "Travelport",
                    "Marriott International",
                    "Doubletree By Hilton",
                    "Accenture",
                    "Westin Hotels & Resorts",
                    "Usa Jet Airlines",
                    "Us Air Force Reserve",
                    "United Airlines",
                    "Thomson Reuters",
                    "Tech Mahindra",
                    "Pointwest Technologies Corporation",
                    "Mlt Vacations",
                    "Manhattan Associates",
                    "Loews Hotels & Co",
                    "Ibm",
                    "Ge",
                    "Dell Emc",
                    "Courtyard By Marriott",
                    "Code Ninjas",
                    "Cgi",
                    "At&T",
                    "Ameriprise Financial",
                    "Adobe",
                    "Wyndham Hotels & Resorts",
                    "Worldspan",
                    "Witmer Public Safety Group, Inc",
                    "Wipro",
                    "Wellstar Health System",
                    "Wells Fargo",
                    "Waldorf Astoria Beverly Hills",
                    "Wake Forest University School Of Business",
                    "Wag Labs Inc.",
                    "Virtucom, Inc.",
                    "Verizon",
                    "Valdosta State University",
                    "Ust",
                    "Us Navy",
                    "University Of Stuttgart",
                    "University Of Minnesota",
                    "University Of Louisville",
                    "Unitedhealth Group",
                    "United States Postal Service",
                    "United States Navy Reserve",
                    "Unisys",
                    "Ubs",
                    "U.S. Coast Guard",
                    "Turner Sports",
                    "Triage Consulting Group",
                    "Trans States Airlines",
                    "Tjkworks, Llc",
                    "Thrivent",
                    "Thought Logic Consulting",
                    "Thermo Fisher Scientific",
                    "The University Of Toledo",
                    "The University Of Alabama",
                    "The St. Regis Princeville Resort",
                    "The Ritz-Carlton Hotel Company, L.L.C.",
                    "The Ritz-Carlton Hotel Company",
                    "The Hotel Group",
                    "The Depository Trust & Clearing Corporation (Dtcc)",
                    "The Classic Center",
                    "Thai Airways International",
                    "Texas Roadhouse",
                    "Tesco",
                    "Teradata",
                    "Teksystems",
                    "Target",
                    "Syntel",
                    "Symantec",
                    "Steppenwolf Theatre Company",
                    "State Farm Insurance",
                    "Starwood Hotels & Resorts Worldwide, Inc.",
                    "Staples",
                    "Spectrus",
                    "Sogeti",
                    "Software Ventures International",
                    "Snapdeal",
                    "Slalom Consulting, Llc",
                    "Simplepart Llc",
                    "Sheppard Air Force Base",
                    "Sgi",
                    "Sandvine",
                    "Saggezza",
                    "Sagavisions",
                    "Sabre Corporation",
                    "Ross Stores, Inc.",
                    "Rolls-Royce",
                    "Revcor",
                    "Revature",
                    "Republic Services",
                    "Repliweb",
                    "Regalix",
                    "Realogy",
                    "Qssi",
                    "Purdue University",
                    "Psa Airlines, Inc.",
                    "Pro Unlimited",
                    "Privredna Banka Zagreb",
                    "Prime Therapeutics",
                    "Pr Newswire",
                    "Pointwest-Us",
                    "Pointwest",
                    "Pentagon Technologies",
                    "Payless",
                    "Orange",
                    "Oracle",
                    "Optum",
                    "Oconee Gymnastics Center",
                    "Oati",
                    "Northwestern Mutual",
                    "North Highland",
                    "Nike",
                    "Nielsen",
                    "New York Sports Clubs",
                    "Netjets",
                    "Navistar Inc",
                    "Mindtree",
                    "Mindsharp",
                    "Michigan State University",
                    "Metric Motors",
                    "Mesa Airlines, Inc.",
                    "Mercy Ships",
                    "Melyx Corporation",
                    "Medtronic",
                    "Medix™",
                    "Mcleod Software",
                    "Maritzcx",
                    "Macalester College",
                    "Lpl Financial",
                    "Logicbay",
                    "Loews Hotels",
                    "Lincoln Financial Group",
                    "Liberty Mutual Insurance",
                    "Larsen & Toubro Infotech",
                    "Komatsu Construction",
                    "Kaiser Permanente",
                    "Jet Connection",
                    "Jeff Belzers Chevrolet",
                    "Interglobe Technologies",
                    "Indecomm Global Services",
                    "Incomm Payments",
                    "Imation",
                    "Ikon Office Solutions",
                    "Ihg Hotels & Resorts",
                    "Igt Solutions",
                    "Ibm Consulting",
                    "Hyatt Regency",
                    "Huntington National Bank",
                    "Huawei",
                    "Howard Johnson",
                    "Houghton Mifflin Harcourt",
                    "Hotel Bel-Air",
                    "Hooper Software Llc",
                    "Holiday Inn Resort",
                    "Holiday Inn",
                    "Hiser Helicopters Inc",
                    "Hilton Worldwide Holdings Inc.",
                    "Hilton Worldwide",
                    "Hilton Garden Inn",
                    "Hennepin County",
                    "Hdfc Bank Limited",
                    "Goldman Sachs",
                    "Godaddy",
                    "Geospoc",
                    "Genuine Parts Company",
                    "General Assembly",
                    "Ge Healthcare",
                    "Gamma Phi Beta International Sorority",
                    "Freelance Web Developer",
                    "Flatiron School",
                    "Fiserv",
                    "Fintech Company",
                    "Fidelity Investments",
                    "Ee",
                    "Disneyland Resort",
                    "Digital Media Company",
                    "Digital Insight",
                    "Devry Education Group",
                    "Deluxe Entertainment Services Group",
                    "Delta Technology",
                    "Dave & Buster'S Inc.",
                    "Datasite",
                    "Data Recognition Corporation",
                    "Cyc Fitness",
                    "Csx",
                    "Csc",
                    "Costco Wholesale",
                    "Concentra",
                    "Comsat",
                    "Cognizant Technology Solutions",
                    "Children'S Hospitals And Clinics Of Minnesota",
                    "Chevron",
                    "Cerner Corporation",
                    "Capital One",
                    "Canyon Ranch",
                    "Busy Human",
                    "British Airways",
                    "Brandeis University",
                    "Blink Fitness",
                    "Best Buy",
                    "Aptean",
                    "Apple",
                    "American Red Cross",
                    "Amazon Web Services (Aws)",
                    "Amara Day Spa",
                    "Air National Guard",
                    "Aerospace",
                    "Advance Auto Parts",
                    "Abn Amro Bank N.V.",
                    "Abn Amro Bank",
                    "Aamc",
                    "89Th Airlift Wing",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.19, 0.08, 0.05, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Software Development",
                },
            },
            {
                categories: [
                    "The Home Depot",
                    "Newell Brands",
                    "Ihg Hotels & Resorts",
                    "Northwest Airlines",
                    "Ups Capital®",
                    "Ups",
                    "Tween Brands, Inc.",
                    "Turner (Turner Broadcasting System, Inc)",
                    "The Coca-Cola Company",
                    "Sodexo",
                    "Microsoft",
                    "American Airlines",
                    "Ymca Of San Francisco",
                    "Xcel Energy",
                    "Wgbh",
                    "Washington University In St. Louis",
                    "Warnermedia",
                    "Walmart",
                    "Visiting Angels",
                    "Virgin Atlantic",
                    "Virgin America",
                    "Verizon Connect",
                    "Varig",
                    "Utah Valley University",
                    "Utah Valley Chamber Of Commerce",
                    "Univision Communications",
                    "University Of Utah - Employment",
                    "University Of Northwestern – St. Paul",
                    "University Of Minnesota",
                    "University Of Florida",
                    "University Of Central Florida",
                    "University Of Alabama",
                    "Ufone 4G",
                    "Ubisoft Toronto",
                    "U.S. Bank",
                    "Turner Broadcasting Systems",
                    "Turner",
                    "Triangle",
                    "Travelport",
                    "Transamerica Financial Advisors, Inc.",
                    "Tracfone Wireless",
                    "Thyssenkrupp",
                    "The Walt Disney Company",
                    "The Tko Group",
                    "The Kraft Heinz Company",
                    "The Black Sheep",
                    "The Big Fake Wedding",
                    "The Adecco Group",
                    "Target",
                    "Tahoe'S Connection For Families",
                    "Swagger Gifts",
                    "St. Catherine University",
                    "Speakalytics",
                    "Southwest Airlines",
                    "South African Airways",
                    "Song",
                    "Society For American Archaeology",
                    "Skyteam",
                    "Simply The Best Magazine",
                    "Signature Brands, Llc",
                    "Self-Employed",
                    "Self Employed",
                    "Securian Financial",
                    "Scoutmob",
                    "Rödl & Partner",
                    "Royal Caribbean Cruises",
                    "Red Bull",
                    "Rauhaus Freedenfeld & Associates, Llp",
                    "Quirky",
                    "Projectline Solutions Inc.",
                    "Procession Systems",
                    "Pride Garden Products",
                    "Pratt & Whitney",
                    "Pr Newswire",
                    "Power Home Remodeling Group",
                    "Points Of Light",
                    "Pi Sigma Epsilon, University Of Georgia",
                    "Philips Lighting",
                    "Philips",
                    "Petsmart",
                    "Pepsico",
                    "Penn State Lion Scouts",
                    "Passion Planner",
                    "Our Lucaya Beach And Golf Resort",
                    "Orlando Sentinel",
                    "Orangetheory Fitness",
                    "Opal Bar & Restaurant",
                    "Omaha World-Herald",
                    "Ogilvy",
                    "Odyssey Online",
                    "Nutrishop Usa",
                    "Northern Kentucky University",
                    "Nike",
                    "New Perspectives Media Wocs 93.7 Fm",
                    "Nebo Agency",
                    "Ncr Corporation",
                    "N/A",
                    "Murphy'S Restaurant, Llc",
                    "Mountain America Credit Union",
                    "Mktg, Inc",
                    "Mingl Marketing Group",
                    "Millerclapperton",
                    "Middle Tennessee State University",
                    "Michigan State University",
                    "Michelin",
                    "Michaels",
                    "Meclabs Institute",
                    "Mcg",
                    "Mattel, Inc.",
                    "Maker Studios Inc",
                    "Lufthansa Group",
                    "Lexisnexis",
                    "Latino Economic Development Center (Ledc)",
                    "Kb Home",
                    "Jubilee Monuments Corp.",
                    "Jpmorgan Chase & Co.",
                    "Jpmorgan Chase",
                    "Jetblue",
                    "Japan Airlines",
                    "Indie",
                    "Indiana State University",
                    "In Touch Ministries",
                    "Hudson River Group",
                    "House Of Harley-Davidson",
                    "Hot 97",
                    "Honeywell Federal Credit Union",
                    "Hint Inc.",
                    "Hilton",
                    "Hill'S Pet Nutrition",
                    "Healthlink Dimensions, Llc",
                    "Havas Sports Entertainment Sas",
                    "Hallmark Marketing",
                    "H&R Block",
                    "Gsd&M",
                    "Grand Trunk",
                    "Gr Auto Gallery",
                    "Goody Products, Inc.",
                    "Gmr Marketing",
                    "Gerdau North America",
                    "Gerdau",
                    "Georgia-Pacific Llc",
                    "Georgia Tech College Of Sciences",
                    "Georgia Institute Of Technology",
                    "Geometry Global",
                    "Gbl Communications",
                    "Garnet & Black Magazine",
                    "Gap",
                    "Gainesville High Field Mri",
                    "Fuzebox, Inc.",
                    "Fullscreen",
                    "Fox",
                    "Foreign Affairs Magazine",
                    "Flyer Enterprises",
                    "Florida State University",
                    "Flightsafety International",
                    "Fire & Flavor",
                    "Feld Entertainment",
                    "Feet For Life",
                    "Fan Experiences",
                    "Fadó Irish Pub",
                    "Expedia",
                    "Everspark Interactive",
                    "Epic Productions",
                    "Emory University",
                    "Emirates Today",
                    "Ebay",
                    "East Cobber",
                    "Digitalsherpa",
                    "Designer Tan",
                    "Design Essentials",
                    "Delta Private Jets",
                    "Delta Airelite Business Jets",
                    "Dayton Development Coalition",
                    "Datacamp",
                    "Cricket Wireless",
                    "Cox Automotive Inc.",
                    "Correia-Xavier, Inc.",
                    "Continental Airlines",
                    "Conservation Corps Minnesota & Iowa",
                    "Coho Real Estate Llc",
                    "Cnn",
                    "Cisco",
                    "Chungdahm Learning",
                    "Children'S Healthcare Of Atlanta",
                    "Chanel",
                    "Cartoon Network Latin America",
                    "Carrington Case",
                    "Cannondesign",
                    "Campus Special",
                    "Calvert Research And Management",
                    "Cafe Iguana Pines",
                    "Bridgestone",
                    "Block, Inc.",
                    "Bleacher Report",
                    "Bissell Homecare, Inc.",
                    "Bell Microproducts",
                    "Beauty By Imagination (Bbi)",
                    "Bank Of America Merrill Lynch",
                    "Bank Of America",
                    "Banana Republic",
                    "Backpackers.Com",
                    "Atlanta Gastroenterology Associates",
                    "Atlanta Braves",
                    "At&T",
                    "Asurion",
                    "Asset Marketing Services, Llc.",
                    "Aroma360",
                    "Arena Water Instinct",
                    "American Express",
                    "American Cancer Society",
                    "American Campus Communities",
                    "Allen & Ohara Education Services Inc",
                    "Air France-Klm-Martinair Cargo",
                    "Aiesec",
                    "Aflac",
                    "Affinity Federal Credit Union",
                    "Adams And Reese Llp",
                    "Abercrombie Kids",
                    "Abercrombie & Fitch Co.",
                    "Abb",
                    "4 Rivers Smokehouse",
                    "22Squared, Inc.",
                    "(Usta) United States Tennis Association",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.07, 0.07, 0.07, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Marketing",
                },
            },
            {
                categories: [
                    "University Of Georgia",
                    "United States Air Force",
                    "Tata Consultancy Services",
                    "Purdue University",
                    "Northwest Airlines",
                    "Nbcuniversal",
                    "Kennesaw State University",
                    "Aramark",
                    "Young Harris College",
                    "Wltz Nbc 38 And The Cw Ga-Bama",
                    "Widener University",
                    "Western Washington University",
                    "Western Michigan University",
                    "Wells Fargo Home Mortgage",
                    "Walser Automotive Group",
                    "Wake Forest University",
                    "Vanderlande",
                    "Valencia College",
                    "Utah Valley University",
                    "Utah Transit Authority",
                    "Usa Network",
                    "Us Navy",
                    "Us Airways",
                    "Ups",
                    "University Of Tennessee, Knoxville",
                    "University Of South Carolina",
                    "University Of Puget Sound",
                    "University Of Puerto Rico",
                    "University Of North Texas",
                    "University Of North Georgia",
                    "University Of North Dakota",
                    "University Of North Carolina At Charlotte",
                    "University Of Kansas Medical Center",
                    "University Of Hartford",
                    "University Of California, Los Angeles",
                    "Unitedhealth Group",
                    "United States Senate",
                    "United States Mint",
                    "United States Marine Corps",
                    "United Kennel Club",
                    "Uhy Llp",
                    "U.S. House Of Representatives",
                    "U.S. Census Bureau",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Trimet",
                    "Transbrasil S/A Linhas Aereas",
                    "Tiaa",
                    "Thinkimpact",
                    "The White House",
                    "The Tallahassee Ballet",
                    "The School Of Visual Arts",
                    "The Peoples Bank",
                    "The Ohio State University Airport",
                    "The Ohio State University",
                    "The Office Coworking",
                    "The Fortress Golf Course",
                    "The Century Foundation",
                    "The Bywater Company",
                    "The Atlanta Beat",
                    "Tempstaff",
                    "Taskaway",
                    "Synovus",
                    "State Farm ®",
                    "State Farm Insurance",
                    "St. Catherine University",
                    "Sport Clips Haircuts",
                    "Southwest Airlines",
                    "South Davis Community Hospital",
                    "Smart Resources",
                    "Skidmore College",
                    "Skanska",
                    "Single Stop",
                    "Sentry Management Inc.",
                    "Self Employed",
                    "Seaworld Parks & Entertainment",
                    "Sealed Air Corporation",
                    "Saint Martin'S University",
                    "Rooms To Go",
                    "Riverside Dental Llc",
                    "Reveille",
                    "Renegade",
                    "Regis University",
                    "Ralph Lauren",
                    "Presbyterian Homes Of Georgia",
                    "Pfizer",
                    "Perimeter College At Georgia State University",
                    "Pensacola Christian College",
                    "Penn State University",
                    "Park Nicollet Health Services",
                    "Oral Arts Dental Laboratories, Inc",
                    "Officeteam",
                    "Oceana",
                    "Nyc Department Of Health And Mental Hygiene",
                    "Nyc Department Of Environmental Protection",
                    "Nyc Department Of Education",
                    "Nova Southeastern University",
                    "Northwestern University",
                    "North Oaks Home Owners Assoc",
                    "Newark Public Schools",
                    "New York University",
                    "New York State Nurses Assoc",
                    "New Adventures Learning Center",
                    "Mount Sinai Health System",
                    "Ministry Of National Education (France)",
                    "Micron Technology",
                    "Michilli Inc.",
                    "Michigan State University",
                    "Michelin",
                    "Metropolitan Police",
                    "Medx Global Healthcare Solutions",
                    "Medcenter One",
                    "Md Anderson Cancer Center",
                    "Mckenzie Health System",
                    "Masonic Pathways",
                    "Marriott International",
                    "Maharishi Foundation Usa",
                    "Logicsource, Inc.",
                    "Kumon",
                    "Krs - Kwajalein Range Services, Llc",
                    "Korean Air",
                    "Kia Motors America",
                    "Keene State College",
                    "Karmaloop.Com",
                    "Kansas State University",
                    "Johns Hopkins Center For Talented Youth",
                    "Jason'S Deli",
                    "Japan Exchange And Teaching (Jet) Programme",
                    "James G. Davis Construction (Davis)",
                    "Isabella Medical Care Facility",
                    "Intel Corporation",
                    "Imedx",
                    "Ibm Global Services-Network Services",
                    "Hospitality House Youth Development",
                    "Hospital Auxilio Mutuo",
                    "Horseshoe Bend Country Club",
                    "Hirestrategy",
                    "Henry Mayo Newhall Hospital Emergency Room",
                    "Hennesey'S Tavern",
                    "Hcr Manorcare",
                    "Hawaii Department Of Transportation",
                    "Harveys Supermarkets",
                    "Harding University",
                    "Gregory Rowcliffe Milners",
                    "Grand Valley State University",
                    "Grand Blanc Community Schools",
                    "Global Events International Inc",
                    "Gibson Brands, Inc.",
                    "Georgia State University School Of Public Health",
                    "Georgia State University",
                    "George Washington University",
                    "Geocing",
                    "Fulton County Government",
                    "First Interstate Bank",
                    "First Interstate Bancsystem",
                    "First American",
                    "Ferris State University",
                    "Fedex",
                    "Federal Home Loan Bank Of Atlanta",
                    "Farmingdale State College",
                    "Family Life Center",
                    "Faa",
                    "Express Employment Professionals",
                    "Emerald Meadows",
                    "Embassy Of The Republic Of Korea",
                    "Eagle Administrative Services",
                    "Dreamworks Animation",
                    "Disneyland Resort",
                    "Deutsche Bank",
                    "Designer Furniture Galleries",
                    "Delta",
                    "Dekalb County School District",
                    "Dc Office Of The State Superintendent Of Education",
                    "Cox Radio",
                    "Coral Shores Realty",
                    "Consulting Firm",
                    "Conagra Foods",
                    "Commercial Steam Team",
                    "Comcast",
                    "City Of Carson, California",
                    "Cincinnati Children'S Hospital Medical Center",
                    "Christ Episcopal Church, Ponte Vedra Beach, Fl",
                    "Chi Omega Fraternity",
                    "Chatham Capital",
                    "Center For Strategic And International Studies (Csis)",
                    "Cartoon Network Latin America",
                    "Carnegie Mellon University",
                    "Carlbyrd&Co.",
                    "Cape Environmental Management Inc",
                    "Brownsburg Community School Corporation",
                    "Brookfield Properties",
                    "Bridgewater State University",
                    "Brakebush Irving",
                    "Blr Aerospace",
                    "Bethesda Hospital",
                    "Berliner Cohen, Llp",
                    "Bealls, Inc.",
                    "Barnes Wendling Cpas",
                    "Bare Escentuals",
                    "Baltimore Ravens",
                    "Auburn University Dance Marathon",
                    "Atlanta Hawks, Llc",
                    "Artists First",
                    "Ameriprise Financial",
                    "Americasmart Atlanta",
                    "Amazon",
                    "Alpha Xi Delta Fraternity",
                    "Alliancebernstein",
                    "Allen & Ohara Education Services Inc",
                    "Alabama A&M University",
                    "Air National Guard",
                    "Air France–Klm",
                    "Aig",
                    "Aeg",
                    "Acworth Animal Hospital",
                    "Abused Adult Resource Center",
                    "360 Media, Inc.",
                    "20Th Century Studios",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Business Administration",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "The Home Depot",
                    "Us Airways",
                    "United Airlines",
                    "The Coca-Cola Company",
                    "Target",
                    "Georgia-Pacific Llc",
                    "Geico",
                    "Ey",
                    "Equifax",
                    "Cognizant",
                    "Boeing",
                    "Bgi, Llc",
                    "At&T",
                    "Accenture",
                    "Yp",
                    "Xpo Logistics, Inc.",
                    "Wyndham Vacation Ownership",
                    "World Compliance Technologies",
                    "Wesfam Restaurants, Inc.",
                    "Washington Metropolitan Area Transit Authority (Wmata)",
                    "Voya Financial",
                    "Val Health, Llc",
                    "Us Geological Survey",
                    "Us Army",
                    "Ups",
                    "University Of Rochester",
                    "University Of Georgia",
                    "Unitedhealth Group",
                    "United States Postal Service",
                    "United States Air Force",
                    "Unfi",
                    "Unc Portfolio Management Team",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Toyota North America",
                    "The Walt Disney Company",
                    "The Adecco Group",
                    "Terry College Of Business At The University Of Georgia",
                    "Tata Consultancy Services",
                    "T-Mobile",
                    "Suntrust",
                    "Sun Country Airlines",
                    "State Road & Tollway Authority",
                    "Startek",
                    "Staples",
                    "Southwest Airlines",
                    "Scotiabank",
                    "Scientific Games",
                    "Sara Lee Branded Apparel",
                    "Sapientrazorfish",
                    "Saint Louis University",
                    "Saic",
                    "Rubicon Technologies",
                    "Robert Half",
                    "Rhode Island Army National Guard",
                    "Relayhealth",
                    "Racetrac",
                    "Pwc",
                    "Porter Novelli",
                    "Pontoon Solutions",
                    "Paul Hastings",
                    "Nyu Tandon School Of Engineering",
                    "Nyu Hyperloop",
                    "Nucleus Software Exports",
                    "Nielsen",
                    "New York University Tandon School Of Engineering",
                    "Nestlé Purina Petcare Company",
                    "Nestlé Purina North America",
                    "Ncr Corporation",
                    "Naval Air Systems Command",
                    "National Grid",
                    "Mu Sigma",
                    "Moxie",
                    "Mol (America) Inc.",
                    "Mlf Lexserv, Lp",
                    "Minnesota Power",
                    "Microsoft",
                    "Mercer",
                    "Mercantil Banco",
                    "Mediterranean Shipping Company",
                    "Mazliah Analytics",
                    "Material Handling Systems, Inc.",
                    "Marriott International",
                    "Manhattan Associates",
                    "Mailco Productions",
                    "Lockheed Martin",
                    "Jpmorgan Chase & Co.",
                    "Jpmorgan Chase",
                    "John Deere",
                    "Jetblue",
                    "J.P Morgan",
                    "Intellyk Inc.",
                    "Inspire Brands",
                    "Insight Data Science",
                    "Illinois State Geological Survey",
                    "Honda Aircraft Company",
                    "Hojeij Branded Foods",
                    "Hewitt Associates",
                    "Hennessy Automobile Companies",
                    "Hay Group",
                    "Havas",
                    "Guitar Center",
                    "Green Tree Financial Corporation",
                    "Goldman Sachs",
                    "Georgia Tech Office Of Information Technology",
                    "Georgia Power Company",
                    "Genpact Headstrong Capital Markets",
                    "General Electric",
                    "Ge Power",
                    "G&K Services",
                    "Fulton County Schools",
                    "Fsa",
                    "Frontier Communications",
                    "Frontier Airlines",
                    "Fiserv",
                    "Fca",
                    "Farmingdale State College",
                    "Express",
                    "Experity",
                    "Eurostar",
                    "Ernst & Young",
                    "Equal Opportunity Schools",
                    "Ellucian",
                    "Dynata",
                    "Docutap",
                    "Diocese Of Fargo",
                    "Digitaslbi",
                    "Digitas",
                    "Deloitte Consulting",
                    "Deloitte",
                    "Dell",
                    "Daimler Ag",
                    "Daimler",
                    "Custom Molded Products, Llc",
                    "Cox Communications",
                    "Corning Incorporated",
                    "Control Data Corporation",
                    "Commonwealth Bank",
                    "Coca-Cola Enterprises",
                    "Cliftonlarsonallen",
                    "Cingular",
                    "Centurylink Business",
                    "Carlson Rezidor Hotel Group",
                    "Cardlytics",
                    "Capitol Airspace Group",
                    "Canon Business Solutions",
                    "Candidus",
                    "California Earthquake Authority",
                    "Buzz Points, Inc.",
                    "Burke, Inc.",
                    "Brillio",
                    "Brigham Young University",
                    "Bridgestone",
                    "Bosch Usa",
                    "Boehringer Ingelheim",
                    "Bkv",
                    "Bealls, Inc.",
                    "Bank Of America",
                    "Avianca",
                    "Auburn Student Investment Fund",
                    "Atos Syntel",
                    "Atlantic Southeast Airlines",
                    "Atac",
                    "Arizona State University",
                    "Anderson Press, Inc.",
                    "American Residential Services",
                    "American Airlines",
                    "Airtouch",
                    "Aeroméxico",
                    "Adp",
                    "Acco Brands",
                    "A-Team Solutions, Llc",
                    "22Squared, Inc.",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.16, 0.11, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Data Science and Analysis",
                },
            },
            {
                categories: [
                    "Apple",
                    "Northwest Airlines",
                    "Ey",
                    "Manhattan Associates",
                    "Deloitte",
                    "Best Buy",
                    "Unisys",
                    "The Home Depot",
                    "Georgia-Pacific Llc",
                    "Georgia Institute Of Technology",
                    "Gamestop",
                    "Ecolab",
                    "Delta Community Credit Union",
                    "Deloitte Consulting",
                    "Aflac",
                    "Accenture",
                    "Wis International",
                    "Wells Fargo Home Mortgage",
                    "Warner Bros. Entertainment",
                    "Walser Automotive Group",
                    "Walmart Ecommerce",
                    "Walmart",
                    "Walgreens",
                    "Vida Fitness & Aura Spa",
                    "Victoria'S Secret",
                    "Verizon Wireless",
                    "Val Health, Llc",
                    "Ups",
                    "University Of Washington",
                    "University Of Rhode Island",
                    "University Of North Carolina At Chapel Hill",
                    "University Of Massachusetts Amherst",
                    "University Of Arizona",
                    "Unitedhealth Group",
                    "United States Mint",
                    "United States Department Of Energy",
                    "Ulta Beauty",
                    "U.S. Senate Committee On Small Business & Entrepreneurship",
                    "U.S. Army Reserve",
                    "Twitter",
                    "Triage Consulting Group",
                    "Traveling Vineyard",
                    "The University Of Arizona",
                    "The Alinea Group",
                    "Texas Department Of Family And Protective Services",
                    "Tata Infotech Ltd.",
                    "Synovus",
                    "Sykes",
                    "Sun Tan City",
                    "State Of Minnesota",
                    "Staffing Technologies",
                    "Spectrum Health",
                    "Shanghai Wison Chemical Engineering Co. Ltd.",
                    "Ser Metro-Detroit",
                    "Sephora",
                    "Self-Employed",
                    "Segal Rogerscasey",
                    "Sedgwick",
                    "Sealed Air Corporation",
                    "Sanford-Brown College",
                    "Samsung Electronics America",
                    "Sammons Financial Group, Inc.",
                    "Samet Corporation",
                    "Revel Consulting",
                    "Randstad Technologies Us",
                    "Porsche Cars North America",
                    "Plannet Marketing",
                    "Pepsico",
                    "Pepperdine Graziadio Business School",
                    "Pep Boys",
                    "Pebra Inc.",
                    "Pattern89",
                    "Nova Southeastern University",
                    "North Highland",
                    "Nextraq",
                    "Nextel",
                    "National Park Service",
                    "National Entertainment Network, Llc",
                    "National American University",
                    "Minneapolis Park & Recreation Board",
                    "Michaels",
                    "Merrill Lynch",
                    "Mercy Health",
                    "Mercer",
                    "Mercedes-Benz Research And Development India",
                    "Massachusetts Executive Office Of Health And Human Services",
                    "Mary Kay",
                    "Macy'S",
                    "Lyft",
                    "Lufthansa Technik Component Services",
                    "Lancome",
                    "Laforce",
                    "L'Oréal",
                    "Kroger",
                    "Korn Ferry",
                    "Kenneth Cole Productions",
                    "Kennesaw State University",
                    "Kelly",
                    "Keane",
                    "Kapp Industries, Inc.",
                    "Kaiser Permanente",
                    "John Hancock",
                    "Insight Global",
                    "Independent",
                    "Illinois Power Company",
                    "Ikon Office Solutions",
                    "Ikea",
                    "Ibm",
                    "Hni Corporation",
                    "Hejun Business School",
                    "Harvard Vanguard Medical Associates",
                    "Guidehouse",
                    "Gsi Office Environments",
                    "Grubhub",
                    "Group Usa",
                    "Gold House",
                    "Georgia State University",
                    "Georgia College & State University",
                    "Ge Power",
                    "First Niagara Bank",
                    "Fidelity Investments",
                    "Fedex",
                    "Federal Bureau Of Investigation (Fbi)",
                    "Fathom Solutions",
                    "Fairfield University",
                    "Factset",
                    "Executive Office Of The President",
                    "Eversana",
                    "Equifax",
                    "Epsilon",
                    "Epic",
                    "Emory University",
                    "Elizabeth Arden",
                    "Eastern Michigan University",
                    "Domino'S Pizza",
                    "Detroit Employment Solutions Corporation",
                    "Depaul University",
                    "Delta Global Services – Staffing",
                    "Cushman & Wakefield - Formerly Dtz",
                    "Cumulus Media",
                    "Covance Clinical Research Unit, Inc.",
                    "Conn'S Homeplus",
                    "Conduent",
                    "Colorado State University",
                    "Collinson Group",
                    "City National Bank",
                    "Ciber",
                    "Cerner Corporation",
                    "Centex Homes",
                    "Carlson School Of Management",
                    "Capgemini",
                    "California State University, Chico",
                    "Cadence Design Systems",
                    "Bowling Green State University",
                    "Booz Allen Hamilton",
                    "Bombardier Aerospace",
                    "Blue Elephant",
                    "Blue Cross Blue Shield Of Massachusetts",
                    "Bg Group",
                    "Bell",
                    "Beaumont Health System",
                    "Bdo Usa, Llp",
                    "Baylor University",
                    "Bain & Company",
                    "Aviation Consulting",
                    "At&T",
                    "Apple Store",
                    "Apple Inc.",
                    "Andersen Consulting",
                    "American Airlines",
                    "Alpha Omicron Pi Fraternity",
                    "Allina Health",
                    "Advance Auto Parts",
                    "Adp",
                    "Acp",
                    "Acendas Travel",
                    "Accenture Technology Solutions Sas",
                    "7Th Special Forces Group (Airborne)",
                    "\tApple Inc.",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.08, 0.05, 0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Consulting",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "United Airlines",
                    "At&T",
                    "The Home Depot",
                    "Texas Roadhouse",
                    "Mlt Vacations",
                    "Ibm",
                    "Georgia Institute Of Technology",
                    "Accenture",
                    "Walt Disney World",
                    "Unitedhealth Group",
                    "United States Air Force",
                    "The Cheesecake Factory",
                    "Optum",
                    "Marriott International",
                    "Kaiser Permanente",
                    "Chili'S",
                    "Yard House Restaurants",
                    "Wipro",
                    "Wings Plus",
                    "Westin Hotels & Resorts",
                    "Wellstar Health System",
                    "Wells Fargo",
                    "Warner Lambert",
                    "Warner Connect",
                    "Walt Disney World Swan And Dolphin",
                    "Waldorf Astoria New York",
                    "Wake Forest University",
                    "Vorys, Sater, Seymour And Pease Llp",
                    "Virgin Atlantic",
                    "Villa St. Benedict",
                    "Verizon",
                    "Varig",
                    "Ust",
                    "University Of Rochester",
                    "University Of Minnesota",
                    "United States Marine Corps",
                    "Unisys Corporation",
                    "Umb Bank",
                    "Tuckaway Country Club",
                    "Travelport",
                    "Tpf Software",
                    "Tin Lizzy'S Cantina",
                    "Thomson Reuters",
                    "The Travelers Companies",
                    "The Ritz-Carlton Hotel Company, L.L.C.",
                    "The Pillsbury Company",
                    "The Dominion Club",
                    "The Chocolate A Dessert Cafe",
                    "The Advocate & Democrat",
                    "Tgi Fridays",
                    "Ted'S Montana Grill",
                    "Tavern Restaurant Group",
                    "Sublime Ale House",
                    "St. Cloud State University",
                    "St. Catherine University",
                    "Southern Company",
                    "Smarter Agent, Inc.",
                    "Siac",
                    "Sara Lee Corporation",
                    "Sap",
                    "Rudees On The Inlet",
                    "Rosen Shingle Creek",
                    "Republican National Committee",
                    "Repliweb",
                    "Re/Max Advantage Plus",
                    "Pwc",
                    "Prime Therapeutics",
                    "Pratt & Whitney",
                    "Pinellas County Schools",
                    "P.F. Chang'S China Bistro, Inc.",
                    "Olive Garden",
                    "Nissan Of Mobile",
                    "Nash Finch Company",
                    "Mwr",
                    "Motorworks Brewing",
                    "Minnesota Life Insurance Company",
                    "Mimi'S Cafe",
                    "Midwave",
                    "Metropolitan State University",
                    "Mellow Mushroom",
                    "Medtronic",
                    "Mays Business School",
                    "Mattel, Inc.",
                    "Marker 7 Coastal Grill",
                    "Maggiano'S Little Italy",
                    "Magfa",
                    "Lyon Living",
                    "Longhorn Steakhouse",
                    "Loews Hotels",
                    "Lockheed Martin",
                    "Livetv",
                    "Le Pain Quotidien",
                    "Kpmg Us",
                    "Kforce Inc",
                    "Keene State College",
                    "Johnson Controls",
                    "Jammin' Bread",
                    "J.Crew",
                    "Insight Global",
                    "Infinity Consulting",
                    "Ihg Hotels & Resorts",
                    "Ibm Global Business Services",
                    "Homeward Residential, Inc.",
                    "Hillstone Restaurant Group",
                    "Hellenic Army / Special Forces",
                    "Healthport Technologies",
                    "Hd Supply",
                    "Hallmark Cards",
                    "Gulfstream Aerospace",
                    "Groucho'S Deli",
                    "Golden Pond Assisted Living",
                    "Global Crossing",
                    "Givergy",
                    "Georgia Tech Research Institute",
                    "Georgia Piedmont Technical College",
                    "Georgia Department Of Public Health",
                    "Fred Meyer",
                    "Flayvors Of Cook Farm",
                    "Fiserv",
                    "Fis",
                    "Fedex Ground",
                    "Federal Aviation Administration",
                    "Ey",
                    "Expressjet Airlines",
                    "Epic",
                    "Entrepreneurs' Organization",
                    "Enterprise Rent-A-Car",
                    "Empire State South",
                    "Emc",
                    "Eliassen Group",
                    "Down Syndrome Network Of Montgomery County (Dsnmc)",
                    "Design Cuisine",
                    "Delta Global Services – Staffing",
                    "Deloitte Consulting",
                    "Dell",
                    "Dairy Queen",
                    "Creative Cuisine",
                    "Cox Communications",
                    "Contech Systems Inc.",
                    "Conexant",
                    "Community Advocates, Inc.",
                    "Comm-Works",
                    "Comair",
                    "Colgate-Palmolive",
                    "Cognizant",
                    "Cobb & Douglas Public Health",
                    "Cnn",
                    "Cigma Shades & Blinds",
                    "Chick-Fil-A-Franchise",
                    "Cgi",
                    "Central Washington University",
                    "Central Queens Academy Charter School",
                    "Cellular Sales",
                    "Carrabba'S Italian Grill",
                    "Camp Tel Noar",
                    "Brigham Young University",
                    "Bonefish Grill",
                    "Bnsf Railway",
                    "Bmw Group",
                    "Blue Cross And Blue Shield Of Minnesota",
                    "Blackboxnetworkservices",
                    "Bj'S Restaurants, Inc.",
                    "Bellsouth",
                    "Bank Of America",
                    "Ashton Gardens",
                    "Applied Fluid Power",
                    "Applebee’S International, Inc.",
                    "Antares",
                    "Animal Medical Center",
                    "American Tesol Institute",
                    "American Samoa Coastal Management Program",
                    "American Cancer Society",
                    "Affinity Plus Federal Credit Union",
                    "Abigail Kirsch",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.07, 0.05, 0.05, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Information Technology",
                },
            },
            {
                categories: [
                    "Wells Fargo",
                    "Bank Of America",
                    "Adp",
                    "Robert Half",
                    "Cox Enterprises",
                    "Aerotek",
                    "Vxi Global Solutions, Llc",
                    "The Home Depot",
                    "Pontoon Solutions",
                    "Jetblue",
                    "Insight Global",
                    "Georgia-Pacific Llc",
                    "Georgia Institute Of Technology",
                    "Workday",
                    "Wells Fargo Bank",
                    "Walmart Ecommerce",
                    "Walmart",
                    "Vpne Parking Solutions",
                    "Vistana Signature Experiences",
                    "Victoria'S Secret",
                    "Vendormate, Inc.",
                    "Varsity Brands",
                    "Us Airways",
                    "University Of Washington",
                    "University Of Utah",
                    "University Of South Florida",
                    "University Of South Carolina",
                    "University Of Massachusetts",
                    "University Of Arkansas",
                    "United States Census Bureau",
                    "United States Army",
                    "United States Air Force",
                    "U.S. House Of Representatives",
                    "True Partners Consulting",
                    "True North Communications",
                    "Trc Staffing Services, Inc.",
                    "Total Sports Enterprises, Llc",
                    "Thomas County Federal Savings & Loan Association",
                    "The Walt Disney Company",
                    "The Toledo Zoo & Aquarium",
                    "The Princeton Review",
                    "The Goodyear Tire & Rubber Company",
                    "The Coca-Cola Company",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "Teksystems",
                    "Target",
                    "Swissport",
                    "Suntrust Bank",
                    "Steptoe & Johnson Llp",
                    "State Of Minnesota",
                    "Starwood Hotels & Resorts Worldwide, Inc.",
                    "Staffing Solutions Of Hawaii",
                    "South Carolina Department Of Social Services",
                    "Snap Fitness 24/7",
                    "Slapfish Restaurant Group",
                    "Skywest Airlines",
                    "Servicon Systems Inc.",
                    "Self-Employed",
                    "Security Finance",
                    "Seaworld Parks & Entertainment",
                    "Saks Fifth Avenue",
                    "Righteous Talent Llc",
                    "Rgp",
                    "Rcn",
                    "Raytheon",
                    "Radioshack",
                    "Quantum Radiology",
                    "Qgenda",
                    "Primerevenue",
                    "Pepsico",
                    "Penske Logistics",
                    "Oral Arts Dental Laboratories, Inc",
                    "On-Site Computer Training",
                    "Northwest Airlines",
                    "Northside Hospital",
                    "North Dakota Department Of Commerce",
                    "Nike",
                    "Newell Rubbermaid",
                    "Newell Brands",
                    "New Wincup Holdings, Inc.",
                    "National Veterinary Associates",
                    "Morgan Stanley",
                    "Minnesota Department Of Transportation",
                    "Millercoors",
                    "Medtox Laboratories",
                    "Marketsource Inc.",
                    "Manpowergroup",
                    "M.C. Dean, Inc.",
                    "Lsg Sky Chefs",
                    "Linkedin",
                    "Lanier Worldwide",
                    "Kroger",
                    "Koch Industries",
                    "Kilpatrick Townsend & Stockton Llp",
                    "Kforce Inc",
                    "Jpmorgan Chase & Co.",
                    "Jpmorgan Chase",
                    "Jll",
                    "Jcpenney",
                    "Instacart",
                    "Independent Hr Consultant",
                    "In Good Company",
                    "Hyatt Regency",
                    "Hotel Californian",
                    "Holland America Line",
                    "Hire Velocity",
                    "Guaranty Bank Fsb",
                    "Groome Transportation",
                    "Georgia Aquarium",
                    "General Mills",
                    "Geico",
                    "Ge",
                    "Funeral Home",
                    "Fairview Health Services",
                    "Fab'Rik",
                    "Expressjet Airlines",
                    "Ericsson",
                    "Equinox",
                    "Equifax",
                    "Eenhoorn Llc",
                    "Eaton'S Crouse-Hinds Business",
                    "Eaton - Lighting",
                    "Disneyland Resort",
                    "Delta Global Staffing",
                    "Deloitte",
                    "Crunch Fitness",
                    "Cox Communications",
                    "Covington & Burling Llp",
                    "Cortland",
                    "Corning Incorporated",
                    "Core Staffing",
                    "Cooper Lighting Solutions",
                    "Con-Way",
                    "Comerica Bank",
                    "Comcast Cable Communications, Llc",
                    "Comair",
                    "Colgate-Palmolive",
                    "Coding Dojo",
                    "Citi",
                    "Children'S Hospital Los Angeles (Chla)",
                    "Cell Nation, Inc.",
                    "Cdi Corporation",
                    "Cbs Corporation",
                    "Care",
                    "Canam Care",
                    "Campuspoint",
                    "Caesars Entertainment",
                    "By The Yard, Inc. - Maintenance-Free Outdoor Furniture",
                    "Brody Square - Michigan State University",
                    "Bristol Myers Squibb",
                    "Bridgewater State University",
                    "Bluewave Resource Partners",
                    "Big 5 Sporting Goods",
                    "Betances Health Center",
                    "Banana Republic",
                    "Bain & Company",
                    "Auburn University",
                    "Atlantic Southeast Airlines",
                    "Atlanta Hawks, Llc",
                    "Appleone",
                    "Apple Inc.",
                    "Ams",
                    "American Traveler",
                    "American Red Cross",
                    "American Express",
                    "American Eagle Outfitters Inc.",
                    "American Cancer Society",
                    "American Airlines",
                    "Amazon",
                    "Aiesec",
                    "Aflac",
                    "Affiliated Computer Services",
                    "Advantage Building Contractors, Inc.",
                    "Accenture",
                    "24 Hour Fitness",
                    "\tApple Inc.",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.05, 0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Human Resources",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "Northwest Airlines",
                    "Us Navy",
                    "United Airlines",
                    "Panasonic Avionics Corporation",
                    "Jetblue",
                    "Bank Of America",
                    "Uscentcom",
                    "Us Air Force Reserve",
                    "United States Marine Corps",
                    "Mesaba Airlines",
                    "Hawaiian Airlines",
                    "Aon Hewitt",
                    "American Airlines",
                    "Zocdoc",
                    "World Airways",
                    "Workday",
                    "Wisconsin Army National Guard",
                    "Wells Fargo",
                    "Washington Mutual Savings Bank",
                    "Warnermedia",
                    "Walmart",
                    "Vmfa(Aw)-533",
                    "Visa",
                    "Virgin America",
                    "Utah Air National Guard",
                    "Universal Orlando Resort",
                    "United States Special Operations Command",
                    "United States Navy Reserve",
                    "United States Army",
                    "U.S. Navy Reserve",
                    "U.S. Army",
                    "Twin Cities Flight Training",
                    "Turner Broadcasting Systems",
                    "Trego-Dugan Aviation",
                    "Transpac Aviation Academy",
                    "The Walt Disney Company",
                    "The Dinerstein Companies",
                    "The Classic Center",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "Tarom",
                    "T-Mobile",
                    "Sundance Mountain Resort",
                    "Success Academy Charter Schools",
                    "Subway",
                    "Strategic Asset Services",
                    "Spirit Airlines",
                    "Sony Dadc",
                    "Sikorsky",
                    "Shofur",
                    "Sears Holdings Corporation",
                    "Samar Hospitality",
                    "Royal Netherlands Navy",
                    "Royal Caribbean International",
                    "Roma Enterprises Intl Corp",
                    "Republic Airways Holdings, Inc.",
                    "Republic Airways",
                    "Qatar Airways",
                    "Ptc",
                    "Premier Orthopaedics And Sports Medicine",
                    "Phoenix-Mesa Gateway Airport Authority",
                    "Patrol Squadron 9",
                    "Panera Bread",
                    "Norfolk Southern Corporation",
                    "Norad",
                    "Netjets",
                    "Nato",
                    "Mlt Vacations",
                    "Mitsubishi Electric Classic",
                    "Michelin",
                    "Maximus",
                    "Massage Heights",
                    "Martinair",
                    "Marlboro Group International Llc",
                    "Majestic Property Management Corp",
                    "Macerich",
                    "Lufthansa",
                    "Le Duff America, Inc.",
                    "La Fitness",
                    "Kualoa Ranch Hawaii, Inc.",
                    "Kate Spade New York",
                    "Kalitta Air",
                    "Innovative Network Solutions Corp",
                    "Ibm",
                    "Houze Of Dereon Ltd",
                    "Higher Standards",
                    "Herring Creative, Llc",
                    "Hampton Inn And Suites",
                    "Guppy Media | Guppy Games",
                    "Green Leaves Lawn And Landscape",
                    "Global Aviation Holdings",
                    "Giant Eagle",
                    "Georgia Interfaith Power & Light",
                    "General Mills",
                    "Ge Intelligent Platforms",
                    "Gas German Aviation Service Gmbh",
                    "Gagwear Inc",
                    "Furman University",
                    "Floyd",
                    "Feam Maintenance/Engineering",
                    "Executive Office Of The President",
                    "Envoy Air",
                    "Dtouch",
                    "Disneyland Resort",
                    "Dhl",
                    "Delta Global Services",
                    "Dct Aviation",
                    "Cox Communications",
                    "Commutair Dba United Express",
                    "Comair",
                    "Clutch Technologies",
                    "City Of Lakeland",
                    "Cincinnati Children'S Hospital Medical Center",
                    "Chj Aircraft Maintenance, Inc",
                    "Chartwells Higher Education Dining Services",
                    "Cedar Fair Entertainment Company",
                    "Carters Inc.",
                    "Capgemini",
                    "Banana Republic",
                    "At&T Mobility",
                    "Assurant",
                    "Arrow International",
                    "Aristocrat Fundraising Group",
                    "Arby'S",
                    "Aramark Engineering And Asset Solutions",
                    "American Transport, Inc.",
                    "American Red Cross",
                    "American Girl",
                    "Amc Test And Evaluation Squadron",
                    "Amazon",
                    "Alfred P. Sloan Foundation",
                    "Airtran Airways",
                    "Air National Guard",
                    "Aflac",
                    "Aeroméxico",
                    "Aed Institute Of America",
                    "Acs",
                    "Aaa Parking",
                    "7Th Special Forces Group (Airborne)",
                    1,
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.17, 0.12, 0.07, 0.07, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Operations",
                },
            },
            {
                categories: [
                    "Expressjet Airlines",
                    "Universal Orlando Resort",
                    "The Coca-Cola Company",
                    "Northwest Airlines",
                    "American Airlines",
                    "United States Air Force",
                    "The Walt Disney Company",
                    "The Home Depot",
                    "Marriott International",
                    "Jetblue",
                    "Ihg Hotels & Resorts",
                    "Edelman",
                    "At&T",
                    "Ymca Of Greater Louisville",
                    "X Games",
                    "Women In Business",
                    "Whole Foods Market",
                    "Westrock Company",
                    "Weber State University",
                    "Virgin Atlantic",
                    "Vip Events, Inc.",
                    "Victory Automotive Group",
                    "Verizon",
                    "Varig",
                    "Us Army",
                    "Us Airways",
                    "Unocal",
                    "University Of Georgia",
                    "United States Senate",
                    "United States Marine Corps",
                    "United States Army",
                    "United Entertainment Group",
                    "United Airlines",
                    "Uline",
                    "U.S. Bank",
                    "Transocean",
                    "Toyota North America",
                    "Tmp Worldwide",
                    "Tk Elevator",
                    "Thermotech, Inc",
                    "The Colonnade",
                    "The Classic Center",
                    "Tennessee Valley Authority",
                    "Target Corporation",
                    "Synovus Bank",
                    "Supervalu",
                    "State Farm ®",
                    "Southern Utah University",
                    "Southern Crescent Personnel",
                    "Southern Company",
                    "Six Month Smiles",
                    "Sheraton",
                    "Seattle King County Dental Society",
                    "Satterfield & Pontikes Construction",
                    "San Diego Fleet Week Foundation",
                    "Saginaw Valley State University",
                    "Ryman Hospitality Properties",
                    "Royal Caribbean International",
                    "Rockwell Collins",
                    "Regus",
                    "Regions Financial Corporation",
                    "Red Butte Garden",
                    "Racetrac",
                    "Pulmonary Fibrosis Foundation",
                    "Psav",
                    "Porsche Cars North America",
                    "Points Of Light",
                    "Pivot3",
                    "Phi Eta Sigma National Honor Society, Inc.",
                    "Pause 4 Paws",
                    "Oxiteno",
                    "Orange Bowl Committee",
                    "Nyu School Of Law",
                    "Nu Skin Enterprises",
                    "Northwest Medstar",
                    "Nolans Rental",
                    "Nike",
                    "Nielsen",
                    "New York University",
                    "National Orange Show",
                    "Nami Minnesota",
                    "Michael Page",
                    "Messe Frankfurt North America",
                    "Melissa Libby & Associates",
                    "Megaplex Theatres At The Junction",
                    "Masco Corporation",
                    "Marriott Hotels",
                    "Makar Properties",
                    "Lufthansa Group",
                    "Lindner Student Association",
                    "Leadmn (Minnesota State College Student Association)",
                    "Lan Airlines",
                    "Kpmg Us",
                    "Kimberly-Clark",
                    "Kappa Kappa Gamma",
                    "Johnson & Wales University",
                    "John Hancock",
                    "Jdrf",
                    "Japan Airlines",
                    "Institute For International Research",
                    "Independent Contractor",
                    "Ies",
                    "Ibm Ix",
                    "House Of Blues Orlando",
                    "Hooters Of America",
                    "Historic Motor Sports Association",
                    "Hip Hop Weekly",
                    "Guardian Industries",
                    "Grant Thornton Llp",
                    "Goizueta Business School",
                    "Georgia-Pacific Llc",
                    "Georgia Tech College Of Sciences",
                    "Georgia State University",
                    "Georgia House Of Representatives",
                    "General Motors",
                    "Gaylord Hotels",
                    "Frontdoor, Inc.",
                    "Ford Motor Company",
                    "Flydubai",
                    "Flexjet",
                    "Fleishmanhillard",
                    "Fema",
                    "Fedex",
                    "Federal Home Loan Bank Of Atlanta",
                    "Fca Fiat Chrysler Automobiles",
                    "Evermore Park",
                    "Equifax",
                    "Ems Technologies",
                    "Emory University",
                    "Easley Winery",
                    "Duke University",
                    "Datasite",
                    "Crystal Stairs, Inc.",
                    "Crawford & Company",
                    "Cox Radio",
                    "Cox Communications",
                    "Continental Airlines",
                    "Columbia Business School",
                    "Ciox Health",
                    "Central Rock Gym",
                    "Cbs Television",
                    "Campus Special",
                    "Bartendergirl.Com",
                    "Bank Of America",
                    "Balboa Capital",
                    "Bacardi",
                    "Auburn University",
                    "Atrium Hospitality",
                    "Atlanta Braves",
                    "Arthur Andersen",
                    "Apco Worldwide",
                    "American Medical Response",
                    "American Bankers Association",
                    "Alliance Pointe, Llc",
                    "Airtran Airways",
                    "Air Line Pilots Association",
                    "Air France",
                    "Ahrc New York City",
                    "Aflac",
                    "Adp",
                    "201St Regional Support Group",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.04, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Corporate Affairs",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "At&T",
                    "Verizon Wireless",
                    "Ncr Corporation",
                    "Fiserv",
                    "General Mills",
                    "Delta Technology",
                    "Deloitte",
                    "Bank Of America",
                    "American Express",
                    "Amazon",
                    "[Tc]2",
                    "Wna Ltd",
                    "Wells Fargo",
                    "Washington University In St. Louis",
                    "Virgin Australia Airlines",
                    "Virgin America",
                    "Us Airways",
                    "Upwork",
                    "University Of Cincinnati",
                    "United Parcel Service",
                    "United Airlines",
                    "Unisys",
                    "Unilever",
                    "Ubs Financial Services Inc.",
                    "U.S. Bank",
                    "Turner Broadcasting System",
                    "Travelport",
                    "The List",
                    "The Intersect Group",
                    "The Home Depot",
                    "The Hanover Insurance Group",
                    "The Dow Chemical Company",
                    "The Coca-Cola Company",
                    "Terry College Of Business",
                    "Tata Consultancy Services",
                    "Target",
                    "T-Mobile",
                    "Suntrust Bank",
                    "Suntrust",
                    "Starkey Labs",
                    "Staples",
                    "Sprint",
                    "Sonata Software",
                    "Smarte Carte",
                    "Skidmore College",
                    "Sita",
                    "Shaw Industries",
                    "Salvex",
                    "Sabre Corporation",
                    "Royal Cleaners And Laundry",
                    "Rodan + Fields Dermatologists",
                    "Rockwell Collins",
                    "Rhodes College",
                    "Restaurant Technologies, Inc.",
                    "Ramco Systems",
                    "Qwest Communications",
                    "Prolangco Gmbh",
                    "Procter & Gamble",
                    "Prgx Global Inc.",
                    "Pratt & Whitney",
                    "Pnc",
                    "Oracle",
                    "Omni",
                    "Northside Hospital",
                    "Northrop Grumman",
                    "North Highland",
                    "Nike",
                    "Newgistics",
                    "Neiman Marcus",
                    "Nashville Banner",
                    "Mol (America) Inc.",
                    "Mlt Vacations",
                    "Minnesota Secretary Of State",
                    "Minnesota Office Of The Secretary Of State",
                    "Micfo",
                    "Mercedes-Benz Usa",
                    "Medstar Health",
                    "Masimo",
                    "Manhattan Associates",
                    "Macadamia Beauty, Llc",
                    "Lyft",
                    "Lufthansa Group",
                    "Lennar",
                    "Launch Technical Workforce Solutions",
                    "Knight Hall And Bauer Hall, John M. Olin School Of Business",
                    "King & Spalding Llp",
                    "Kaizen International",
                    "Kaiser Permanente",
                    "Jpmorgan Chase & Co.",
                    "Jpmorgan Chase",
                    "Jcpenney",
                    "International Paper",
                    "Ihg Hotels & Resorts",
                    "Igigi",
                    "Ideo",
                    "Icici Bank Limited",
                    "Icici Bank",
                    "Hrg North America",
                    "Hiregenics, Inc.",
                    "Henry Schein",
                    "Grace Presbyterian Village",
                    "Gourmet Catering",
                    "Goldman Sachs",
                    "Golden Krust Bakery",
                    "Goizueta Business School",
                    "Global Payments Inc.",
                    "Global Integrated Security Solutions",
                    "Georgia Institute Of Technology",
                    "General Motors",
                    "Ge Healthcare",
                    "Fort Worth Star-Telegram",
                    "Foodbuy Usa",
                    "Flughafen Wien",
                    "Fiverr",
                    "First Data Corporation",
                    "Factset",
                    "Equifax",
                    "Elavon, Inc.",
                    "Dxc Technology",
                    "Docusign",
                    "Delta Global Staffing",
                    "Deloitte Consulting",
                    "Daugherty Business Solutions",
                    "D & P Communications",
                    "Cox Enterprises",
                    "Cox Communications",
                    "Consult Your Community - Georgia Tech Chapter",
                    "Citi",
                    "Chs",
                    "Cerner Corporation",
                    "Centers For Disease Control And Prevention",
                    "Cbre",
                    "Caterpillar Inc.",
                    "Cargill",
                    "Capstone Logistics, Llc",
                    "British American Tobacco",
                    "Brickell Travel Management",
                    "Branning Group",
                    "Boeing",
                    "Bmw Group",
                    "Bcforward",
                    "At&T Cybersecurity",
                    "Arthur Andersen",
                    "Anthem",
                    "Ameriprise Financial",
                    "American Airlines",
                    "Amadeus Labs",
                    "Amadeus It Group, S.A.",
                    "Allstate",
                    "Air France–Klm",
                    "Air Canada",
                    "Aiesec",
                    "Agilysys",
                    "Advance Auto Parts",
                    "Adc Telecommunications",
                    "Adacel",
                    "Acs",
                    "Aci Worldwide",
                    "Accessathletes, Llc",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.05, 0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Business Development",
                },
            },
            {
                categories: [
                    "Target",
                    "Apple",
                    "Delta Global Services – Staffing",
                    "Whole Foods Market",
                    "Jetblue",
                    "General Motors",
                    "Chick-Fil-A-Franchise",
                    "Abercrombie & Fitch",
                    "Ymca",
                    "Wow! Internet, Cable & Phone",
                    "Wicb 91.7 Fm",
                    "Western Governors University",
                    "Walmart Distribution Center",
                    "Walgreens",
                    "Visa",
                    "Virtucom, Inc.",
                    "Virgin America",
                    "Verizon Wireless",
                    "Veritas Technologies Llc",
                    "Utrs Inc",
                    "Us Navy",
                    "Us Army",
                    "Us Air Force Reserve",
                    "University Of Puerto Rico",
                    "University Of Florida",
                    "United States Air Force",
                    "Ulta Beauty",
                    "Uga Miracle",
                    "U.S. Customs And Border Protection",
                    "Travelport",
                    "Tk Elevator",
                    "The Walt Disney Company",
                    "The Leukemia & Lymphoma Society",
                    "The Home Depot",
                    "The Gatorade Company",
                    "The Fox Broadcasting Company",
                    "The Coca-Cola Company",
                    "The Church Of Jesus Christ Of Latter-Day Saints",
                    "Technipower, Inc.",
                    "Technical Resources | Specialist Recruitment Agency",
                    "Tbg Financial",
                    "Tarom",
                    "Suntrust Bank",
                    "Sun Country Airlines",
                    "Sts Line Maintenance",
                    "Siliconindia",
                    "Shanghai Disney Resort",
                    "Sdi Media",
                    "Savannah Air Center",
                    "Saint Paul Public Schools",
                    "Royal Navy",
                    "Rita'S Italian Ice",
                    "Resideo",
                    "Repliweb",
                    "Relus Technologies",
                    "Redbull",
                    "Protiviti",
                    "Prospect Airport Services, Inc.",
                    "Prime Therapeutics",
                    "Petco",
                    "Perficient",
                    "Peoplescout",
                    "Paragon Systems Inc",
                    "Oxford Airport Technical Services",
                    "Oracle Financial Services Software",
                    "Ny Air National Guard",
                    "Mphasis",
                    "Motorola",
                    "Morehouse College",
                    "Minnesota Air National Guard",
                    "Michigan Department Of Transportation",
                    "Metlife",
                    "Mentor Graphics",
                    "Menchie'S Frozen Yogurt",
                    "Martinair The Americas",
                    "Manhattan Associates",
                    "Lockheed Martin Aeronautics",
                    "Life Time Fitness",
                    "Lamar University",
                    "Kohl'S",
                    "Keene State College",
                    "Jo-Ann Stores",
                    "Jets Pizza",
                    "Jeremiah'S Italian Ice",
                    "Instacart",
                    "Indecomm Global Services",
                    "Ibm",
                    "Hollister Co.",
                    "Hire Velocity",
                    "Health & Science Innovations",
                    "Hca Healthcare",
                    "Hands On Atlanta",
                    "Georgia Tech Create-X",
                    "Genesis Networks Enterprises",
                    "General Mills",
                    "Follett Higher Education Group",
                    "Floyd",
                    "Flik Hospitality Group",
                    "Fidelity Bank",
                    "Farmers Insurance",
                    "Fairfield University",
                    "F-35 Joint Program Office",
                    "Epic",
                    "Empire Promotions",
                    "Elon University",
                    "Duke University",
                    "Directv",
                    "Dgs",
                    "Dematic",
                    "Delta Global Services",
                    "Convergys",
                    "Continental Airlines",
                    "Cold Stone Creamery",
                    "Cognizant Technology Solutions",
                    "Cognizant",
                    "Cirqit",
                    "Chick-Fil-A",
                    "Centurylink",
                    "Capricorn Systems, Inc.",
                    "Buffalo Wild Wings",
                    "Brocade",
                    "Ben & Jerry'S",
                    "Bass Pro Shops",
                    "Banana Republic",
                    "Avjet - A Jet Aviation Company",
                    "Asig",
                    "Arby'S",
                    "Apex Systems",
                    "Analysts International Corporation",
                    "Ameriprise Financial",
                    "Americorps",
                    "American Eagle Outfitters Inc.",
                    "American Airlines",
                    "America First Credit Union",
                    "Aerotek",
                    "24-7 Intouch",
                    "1Path",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.15, 0.08, 0.05, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Team lead / Technical lead",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "Northwest Airlines",
                    "Nike",
                    "Bank Of America",
                    "Zoom Photography",
                    "Zeta Beta Tau Fraternity",
                    "Yuri'S Night",
                    "Yimby Action",
                    "Xerox Business Solutions",
                    "Westlb",
                    "Westin Hotels & Resorts",
                    "Wellstar Health System",
                    "Vmfa(Aw)-533",
                    "Virginia Interfaith Power & Light",
                    "Virgin Atlantic",
                    "Venice Presbyterian Church",
                    "Us Navy",
                    "University Of Washington",
                    "University Of Georgia",
                    "University Of Alabama Student Government Association",
                    "United Technologies",
                    "United States Senate Committee On Foreign Relations",
                    "United States Senate",
                    "United States Air Force Academy",
                    "United Airlines",
                    "Uga Prssa",
                    "U.S. Senate Committee On Small Business & Entrepreneurship",
                    "U.S. Navy Reserve",
                    "Turner Broadcasting Systems",
                    "Tremendous! Entertainment",
                    "Transmeridian Airlines",
                    "Timco",
                    "The University Of Alabama",
                    "The National Society Of Collegiate Scholars",
                    "The Mercury Group",
                    "The Joint Staff",
                    "The Home Depot",
                    "The Carlyle Group",
                    "Terry Student Consulting (Tsc)",
                    "Ted Conferences",
                    "Technology Student Association",
                    "Suntrust Bank",
                    "Sterling National Bank",
                    "Starwood Hotels & Resorts Worldwide, Inc.",
                    "Standardaero",
                    "Sta Jets",
                    "Spelman College",
                    "Society Of Women Engineers",
                    "Royal Caribbean Cruises",
                    "Rockwell Collins",
                    "Rbc Capital Markets",
                    "Public Relations Student Society Of America",
                    "Psr Associates, Inc.",
                    "Proag",
                    "Pratt & Whitney Canada",
                    "Pratt & Whitney",
                    "Pnc",
                    "Phi Eta Sigma National Honor Society, Inc.",
                    "Perficient",
                    "Ortho Sport & Spine Physicians",
                    "Omni",
                    "Office Of The Secretary Of Defense",
                    "Octagon",
                    "Nordstrom",
                    "New York Life Insurance Company",
                    "Navy League Of The United States",
                    "Naval Air Systems Command",
                    "National Society Of Black Engineers",
                    "National Black Mba Association, Boston Chapter",
                    "Morehouse College",
                    "Mohawk Industries",
                    "Minnesota Systems International",
                    "Merrill Lynch",
                    "Massage Heights",
                    "Marsh",
                    "Marriott International",
                    "Levy Restaurants",
                    "Lehman Brothers",
                    "Keene State College",
                    "Jimmy John'S",
                    "Jetblue",
                    "Jbt Corporation",
                    "J.P Morgan",
                    "J.M. Huber Corporation",
                    "Integrity Media",
                    "Iiba Minneapolis St Paul Chapter",
                    "Ihg Hotels & Resorts",
                    "Hughes Aerospace Corporation",
                    "Horseshoe Bend Country Club",
                    "Healthspot",
                    "Hamilton Sundstrand",
                    "Greater Rome Chamber Of Commerce",
                    "Grady Health System",
                    "Glock, Inc.",
                    "Girl Scouts Of Orange County",
                    "Georgia State Defense Force",
                    "Georgia Institute Of Technology",
                    "Federated Media",
                    "Expedia, Inc.",
                    "Expedia",
                    "Emerald City Football Club",
                    "Design U",
                    "Delta Phi Epsilon Sorority",
                    "Curtiss-Wright Defense Solutions",
                    "Credit Suisse",
                    "Converse",
                    "Continental Airlines",
                    "City Of Boston",
                    "Citi",
                    "Children'S Friend, Inc.",
                    "Career Explorations",
                    "Caliber Construction Llc",
                    "Brigham Young University",
                    "Bowling Green State University",
                    "Bgr Group",
                    "Barclays",
                    "Bank Of America Merrill Lynch",
                    "Avgroup, Inc.",
                    "Atlanta Interactive Marketing Association (Aima)",
                    "Aristocrat Fundraising Group",
                    "Aramark",
                    "Api",
                    "American Heart Association",
                    "American Friends Of The Alexander Von Humboldt Foundation",
                    "American Express",
                    "Amc Test And Evaluation Squadron",
                    "Alpha Gamma Delta",
                    "Alpha Eta Rho",
                    "Alpha Delta Pi Sorority",
                    "Alpha Chi Omega Fraternity, Inc.",
                    "Alpfa, Inc - Association Of Latino Professionals For America",
                    "Air Serv Corporation",
                    "Air Line Pilots Association",
                    "Aiesec",
                    "Aeroméxico",
                    "Advantage Sales & Marketing",
                    "Adp",
                    "Acco Brands",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.11, 0.08, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Top Management",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "The Home Depot",
                    "E-Trade",
                    "Wells Fargo",
                    "United Airlines",
                    "Target",
                    "Protiviti",
                    "Northwestern Mutual",
                    "Kpmg",
                    "Csx",
                    "American Airlines",
                    "Zodiac Aerospace",
                    "Worldcom",
                    "Westin Hotels & Resorts",
                    "Walt Disney World Co.",
                    "Waddell & Reed",
                    "Ups Supply Chain Solutions",
                    "United States Marine Corps",
                    "United States Air Force",
                    "Unilever",
                    "Ubs Investment Bank",
                    "U.S. Department Of Commerce",
                    "U.S. Commercial Service",
                    "U.S. Bank",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Tucson Electric Power",
                    "True North Hotel Group",
                    "The Walt Disney Company",
                    "The Dolan Company",
                    "Sterling Credit Group",
                    "Spelman College",
                    "Spalding",
                    "Southern Power",
                    "Southern Company",
                    "Southeastern Grocers",
                    "Société Générale",
                    "Sheraton Hotels & Resorts",
                    "Shelter For Life International",
                    "Scientific Atlanta",
                    "Sanford Brown Institute",
                    "Royal Caribbean International",
                    "Rosetta Stone",
                    "Rockwell Collins",
                    "Robert Half",
                    "Rhodes College",
                    "Regions Financial Corporation",
                    "Qwest Communications",
                    "Pwc",
                    "Pricewaterhousecoopers",
                    "Premier Orthopaedics And Sports Medicine",
                    "Port Of Seattle",
                    "Pier 1",
                    "Pebra Inc.",
                    "National Society Of Black Engineers",
                    "Mtn",
                    "Mol (America) Inc.",
                    "Mlt Vacations",
                    "Minnesota Department Of Natural Resource",
                    "Miller Transporters, Inc.",
                    "Mesaba Airlines",
                    "Merrill Lynch",
                    "Masterplan",
                    "Mars",
                    "Marriott International",
                    "Marathon Petroleum Corporation",
                    "Loews Hotels & Co",
                    "Lockheed Martin Corporation",
                    "L-3 Communications",
                    "Kpmg Audit",
                    "Karmaloop.Com",
                    "Kalitta Air",
                    "Jp Morgan",
                    "Jetblue",
                    "Independent Contractor",
                    "Iif Data Solutions",
                    "Hilton Worldwide Holdings Inc.",
                    "Hilton Worldwide",
                    "Hewlett-Packard Company",
                    "Hd Supply",
                    "Goosehead Insurance Agency",
                    "Georgia-Pacific Llc",
                    "General Electric Company",
                    "Gas South",
                    "Fiserv",
                    "First Data Corporation",
                    "First American Team Realty",
                    "Fedex",
                    "Ey",
                    "Emirates",
                    "Emily'S List",
                    "Eaton",
                    "Ds Smith",
                    "Discover Financial Services",
                    "Directv",
                    "Deutsche Bank",
                    "Delta Community Credit Union",
                    "Deloitte",
                    "Db Schenker",
                    "Crowne Plaza® Hotels & Resorts",
                    "Cox Enterprises",
                    "Compass Airlines",
                    "Cnn",
                    "Citi",
                    "Children'S Healthcare Of Atlanta",
                    "Chicken Salad Chick",
                    "Chartwells Higher Education Dining Services",
                    "Champion Air",
                    "Cbre",
                    "Caterpillar Inc.",
                    "Cardinal Health",
                    "Capstone Financial, Llc",
                    "British Airways",
                    "Boston Park Plaza",
                    "Bny Mellon",
                    "Bnp Paribas",
                    "Axa Advisors",
                    "At&T",
                    "Asian Americans For Equality",
                    "American Tower",
                    "Aig",
                    "Aiesec Serbia",
                    "Aetna, A Cvs Health Company",
                    "Ab Mauri North America",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.08, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Finance",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "Ihg Hotels & Resorts",
                    "Best Buy",
                    "Wells Fargo",
                    "United Airlines",
                    "National Center For Freight And Infrastructure Research And Education",
                    "Delta Global Staffing",
                    "Delta Global Services – Staffing",
                    "Zions Bancorporation",
                    "Wincup",
                    "Vigor",
                    "Us Navy",
                    "University Of Minnesota",
                    "United States Department Of Labor",
                    "United States Air Force",
                    "Turner Broadcasting Systems",
                    "Turner Broadcasting System",
                    "Tracylocke",
                    "Tmp Worldwide",
                    "The Home Depot",
                    "The Black Star Project",
                    "Thales",
                    "Tech Beautification Day",
                    "Target",
                    "T-Mobile",
                    "Syntel",
                    "Suntrust Bank",
                    "Solarcity",
                    "Skanska",
                    "Sekisui Aerospace Corporation",
                    "Sears Home Improvement Products",
                    "Saltwater Inc.",
                    "Sac Wireless",
                    "Ron Logistics Inc",
                    "Ramco Systems",
                    "Q² Solutions",
                    "Pratt & Whitney",
                    "Possible",
                    "Pomeroy",
                    "Plant Engineering Services Llc (A Fluor Company)",
                    "Pivotal Corporation",
                    "Pc Group Holdings Llc",
                    "Oracle",
                    "Optum Technology Inc",
                    "Optimal Solutions Group",
                    "O Positive Films",
                    "Netsolace, Inc.",
                    "Nebo Agency",
                    "Ncr Corporation",
                    "Mugwump Productions",
                    "Mercer",
                    "Meggitt",
                    "Medtronic",
                    "Medidata Solutions",
                    "Martinair",
                    "Makar Properties",
                    "Livetv",
                    "Jll",
                    "Jetblue",
                    "Jeppesen, A Boeing Company",
                    "Jas Worldwide",
                    "International Paper",
                    "Interglobe Technologies",
                    "Hughes Aerospace Corporation",
                    "Hollstadt & Associates",
                    "Georgia-Pacific Llc",
                    "Georgia Tech Research Institute",
                    "Georgia Department Of Agriculture",
                    "Ge Aviation",
                    "Frontier Airlines",
                    "Fooji",
                    "Fdh Infrastructure Services",
                    "European Commission",
                    "Europe Unlimited",
                    "Emory University",
                    "Edgeconnex",
                    "Duke Media",
                    "Duke Energy Corporation",
                    "Digital Insight",
                    "Dhi Computing Service, Inc.",
                    "Delta Technology",
                    "Danieli & C. S.P.A.",
                    "Cummins Inc.",
                    "Compucredit Corporation",
                    "Clyde Bergemann Power Group",
                    "Ciscrp",
                    "Boeing",
                    "Bnsf Railway",
                    "Bissell Homecare, Inc.",
                    "Atlanta Public Schools",
                    "At&T",
                    "Americorps Vista",
                    "Amadeus",
                    "Alexander Haas",
                    "Aim Partners Global",
                    "Advanced Vessel & Alloy, Inc.",
                    "Adecco",
                    "7Th Special Forces Group (Airborne)",
                    "75Th Ranger Regiment, U.S. Army",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01,
                            ],
                        },
                    ],
                    name: "Project Management",
                },
            },
            {
                categories: [
                    "Delta Global Services – Staffing",
                    "Cognizant",
                    "Us Airways",
                    "The Walt Disney Company",
                    "Royal Navy",
                    "Honeywell Aerospace",
                    "Delta Global Staffing",
                    "Chromalloy",
                    "Boeing",
                    "Zurich Insurance Company Ltd",
                    "Williams International",
                    "Walgreens",
                    "Viasat",
                    "Us Navy",
                    "Us Air Force Reserve",
                    "University Of Toronto",
                    "University Of Tennessee",
                    "University Of Michigan College Of Engineering",
                    "United States Air Force",
                    "United Airlines",
                    "Uk Ministry Of Defence",
                    "Timco",
                    "Tier3Md",
                    "Textron",
                    "Teksystems",
                    "Tata Consultancy Services",
                    "Srg Global",
                    "Sovran, Inc.",
                    "Southwest Airlines",
                    "Slumberger Lumber",
                    "Slingshot Product Development Group",
                    "Siemens Usa",
                    "Seagate Technology",
                    "San-I-Pak",
                    "Rolls-Royce",
                    "Republic Airways Holdings, Inc.",
                    "Precision Aircraft Solutions",
                    "Pratt & Whitney",
                    "Porsche Cars North America",
                    "Paypal",
                    "Pakistan Air Force",
                    "Ny Waterway",
                    "Northwest Airlines",
                    "Northrop Grumman",
                    "Norfolk Southern Corporation",
                    "Newport News Shipbuilding, A Division Of Huntington Ingalls Industries",
                    "Newell Brands",
                    "Neptune Technology Group",
                    "Nagarro",
                    "Metlife",
                    "Meggitt",
                    "Mckenney'S, Inc.",
                    "Marriott International",
                    "Lockheed Martin Aeronautics",
                    "Kraft Foods",
                    "Kimberly-Clark Professional",
                    "Kimberly-Clark",
                    "J.F. Shea Co., Inc.",
                    "Intuit",
                    "Intertec Systems",
                    "Honda Aircraft Company",
                    "Gulfstream Aerospace",
                    "Georgia Vocational Rehabilitation Agency",
                    "Georgia Institute Of Technology",
                    "General Motors",
                    "Ge Power",
                    "Ge Oil & Gas",
                    "Ge Aviation",
                    "G&K Services",
                    "Fujitsu",
                    "Ford Motor Company",
                    "Fmc Technologies",
                    "Fedex",
                    "Exxonmobil",
                    "Ellie Mae",
                    "Electromech Technologies",
                    "Eastman Chemical Company",
                    "Duke Energy Corporation",
                    "Deloitte",
                    "Cr Bard",
                    "Clyde Bergemann Power Group",
                    "Carter'S / Oshkosh B'Gosh",
                    "Bmw Manufacturing Co., Llc",
                    "Baker Hughes",
                    "America West Airlines",
                    "Altuscio Networks",
                    "Alpine Engineered Products",
                    "Allsourcepps, Inc.",
                    "Aac Engineering",
                    "3M",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Engineering",
                },
            },
            {
                categories: [
                    "Publix Super Markets",
                    "Nordstrom",
                    "Gamestop",
                    "Whole Foods Market",
                    "Walmart",
                    "Starbucks",
                    "Kroger",
                    "Cvs Health",
                    "Aramark",
                    "Wendy'S/Arby'S Group, Inc.",
                    "Vineyard Vines",
                    "Vera Bradley",
                    "Ulta Beauty",
                    "Tj Maxx",
                    "The Sipping Plant",
                    "The Metropolitan Museum Of Art",
                    "The Home Depot",
                    "The Fragrance Outlet",
                    "Target",
                    "T-Mobile",
                    "Sun River Gardens, Inc.",
                    "Sprouts Farmers Market",
                    "Sole Boutique",
                    "Sherwin-Williams",
                    "Sephora",
                    "Self Esteem Brands, Llc",
                    "Ross Stores, Inc.",
                    "Racetrac Petroleum",
                    "Procter & Gamble",
                    "Pnc",
                    "Petsmart",
                    "Pathmark Stores, Inc.",
                    "Pacsun",
                    "Officemax",
                    "Newell Brands",
                    "Nantahala Outdoor Center",
                    "Metropcs",
                    "Mcdonald'S Corporation",
                    "Marshalls",
                    "Macy'S",
                    "Lowe'S Companies, Inc.",
                    "Lenscrafters",
                    "Lane Bryant",
                    "L'Oréal",
                    "Jimmy John'S",
                    "Hollywood Drive-In Golf",
                    "Harris Teeter",
                    "Gymboree",
                    "Freddy'S Frozen Custard & Steakburgers",
                    "Foot Locker",
                    "Family Dollar",
                    "Ebay",
                    "Dsw",
                    "Dollar Tree Stores",
                    "Disneyland Resort",
                    "Dick'S Sporting Goods",
                    "Delaware North",
                    "Deckers Brands",
                    "Coldwater Creek",
                    "Citizen Supply",
                    "Chick-Fil-A-Franchise",
                    "Chick-Fil-A Restaurants",
                    "Chick-Fil-A",
                    "Cacao Atlanta Chocolate Co.",
                    "Burger King®",
                    "Burger King",
                    "Bath & Body Works",
                    "Atlanta Journal-Constitution",
                    "At&T Mobility",
                    "At&T",
                    "Arena Softball",
                    "American Eagle Outfitters Inc.",
                    "Aeropostale",
                    "Abercrombie & Fitch Co.",
                    "Abercrombie & Fitch",
                    "7-Eleven",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.04, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Retail",
                },
            },
            {
                categories: [
                    "University Of Georgia",
                    "Georgia Institute Of Technology",
                    "United States Air Force",
                    "Kennesaw State University",
                    "Western Michigan University",
                    "Us Navy",
                    "Purdue University",
                    "Howard University",
                    "Georgia State University",
                    "Youth With A Mission Los Angeles",
                    "Young Harris College",
                    "Western Kentucky University",
                    "Wake Forest University School Of Business",
                    "Valdosta State University",
                    "University Of Wisconsin Hospital And Clinics",
                    "University Of Washington",
                    "University Of North Georgia",
                    "University Of North Dakota",
                    "University Of Mississippi",
                    "University Of Minnesota Libraries",
                    "University Of Massachusetts Amherst",
                    "University Of Illinois Urbana-Champaign",
                    "University Of Illinois At Urbana-Champaign",
                    "University Of Florida",
                    "United States Marine Corps",
                    "U.S. Department Of State",
                    "The Ohio State University Center For Aviation Studies",
                    "Texas A&M University System",
                    "St. Cloud State University",
                    "Special Events",
                    "South Puget Sound Community College",
                    "Savannah State University",
                    "Saint Martin'S University",
                    "Peach State Pride",
                    "Old Dominion University",
                    "Naval War College",
                    "Naval Air Systems Command (Navair)",
                    "National Committee On U.S.-China Relations",
                    "Murray State University",
                    "Mt. San Antonio College",
                    "Minnesota Department Of Health",
                    "Middle Tennessee State University",
                    "Mercer University",
                    "Loyola Marymount University",
                    "Johnson & Wales University",
                    "I3 Electronics, Inc",
                    "Hillsborough Community College",
                    "Haslam College Of Business At The University Of Tennessee",
                    "General Assembly",
                    "East Carolina University",
                    "Developintelligence, A Pluralsight Company",
                    "Deutsche Bahn Ag",
                    "Deutsche Bahn",
                    "Columbus State University",
                    "Chartwells Higher Education Dining Services",
                    "Chapman University",
                    "Central State University",
                    "Carlson School Of Management",
                    "Bucknell University",
                    "Bridgewater State University",
                    "Barbizon Modeling And Entertainment",
                    "Bangladesh University Of Engineering And Technology",
                    "Auburn University",
                    "Auburn Athletics Department",
                    "Army Special Forces (Airborne)",
                    "Alstom Power",
                    "Air Command And Staff College",
                    "3M",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.05, 0.04, 0.04, 0.03, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Student",
                },
            },
            {
                categories: [
                    "Cnn",
                    "The Home Depot",
                    "Nebo Agency",
                    "22Squared",
                    "Wuog 90.5 Fm",
                    "Wuft-Tv",
                    "Women In Business",
                    "Wisconsin State Journal",
                    "Wild Adventures Theme Park",
                    "White Tangerine | A Creative Studio",
                    "Webmd",
                    "Walt Disney World",
                    "Vogue",
                    "Verbalizeit",
                    "Upstairs Circus",
                    "University Of South Carolina",
                    "Universal Studios",
                    "Undp",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Tracylocke",
                    "The Walt Disney Company",
                    "The Mill Magazine",
                    "The Daily Gamecock",
                    "Temporary Emergency Services",
                    "State Of Georgia",
                    "Soccer In The Streets",
                    "Rolling Out",
                    "Rcn Radio",
                    "Raise The Barr Founded By Nfl Player Anthony Barr",
                    "Radio Depaul",
                    "Phi Eta Sigma National Honor Society, Inc.",
                    "Patientpoint®",
                    "One Hundred Days",
                    "Norwegian Cruise Line Holdings Ltd.",
                    "Nike",
                    "National Language Service Corps (Nlsc)",
                    "Museum Of Science & Industry (Mosi)",
                    "Multiple Employers",
                    "Mslgroup",
                    "Mr",
                    "Mindshare",
                    "Michelin",
                    "Maxus",
                    "Lyfe Marketing",
                    "Lindner Student Association",
                    "Leo Burnett",
                    "Lalive Sa",
                    "Jcpenney",
                    "Ixl Learning",
                    "Imperial Valley Press",
                    "Idaho Women'S Journal",
                    "Hype Hair Magazine",
                    "Hope Lutheran Church",
                    "Hawaiian Tropic",
                    "Greater Rising Star Missionary Baptist Church",
                    "Grand River Hospital District",
                    "Grady Health System",
                    "Grace Church",
                    "Georgia House Of Representatives",
                    "Georgia Aquarium",
                    "General Motors",
                    "Freelancer",
                    "Freelance",
                    "Fox News Channel",
                    "Everywhere Agency",
                    "Encompass Digital Media, Inc.",
                    "Diversity Abroad",
                    "Digital Agency",
                    "Cranbrook Educational Community",
                    "Cpi Corp.",
                    "Congressional Quarterly",
                    "Chick-Fil-A Corporate Support Center",
                    "Chick-Fil-A",
                    "California State University, Fresno",
                    "Bleacher Report",
                    "Best Buy",
                    "At&T",
                    "Allina Hospitals & Clinics",
                    "Albertsons",
                    "Alan Weiss Productions",
                    "Academy Of Art University",
                    "360I",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.04, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Media and Entertainment",
                },
            },
            {
                categories: [
                    "United States Air Force",
                    "Air National Guard",
                    "Wings Of Park Rapids, Inc",
                    "Wefeel",
                    "Us Air Force Reserve",
                    "United Technologies",
                    "United States Special Operations Command",
                    "United States Senate",
                    "United States Navy Reserve",
                    "United States Department Of Energy",
                    "United States Air Force Security Forces",
                    "United Airlines",
                    "Trouble Child",
                    "Teamsai, Inc.",
                    "Skyteam",
                    "Samsung Electronics",
                    "Rsui Group, Inc.",
                    "Robins Air Force Base, Georgia",
                    "Resurgens Hospitality Group",
                    "Psr Associates, Inc.",
                    "Phi Eta Sigma National Honor Society, Inc.",
                    "Pedal Brain",
                    "Page10",
                    "Omni",
                    "Oficina Nacional De Estadística",
                    "North American Airlines",
                    "Nato",
                    "Minnesota Air National Guard",
                    "Mesaba Airlines",
                    "Mcdonald'S",
                    "Lyft",
                    "Kahuku Grill",
                    "International Women'S Forum",
                    "Hope Funding, Llc",
                    "Griffin, Kubik, Stephens & Thompson",
                    "Garnish Catering",
                    "G-Science, Inc.",
                    "Expressjet Airlines",
                    "Compass Airlines",
                    "Business24-7",
                    "Bombardier",
                    "Bank Of America",
                    "Arrivia, Inc.",
                    "Army National Guard",
                    "Amc Test And Evaluation Squadron",
                    "Air Mobility Command",
                    "Aig",
                    "Aerosur Líneas Aéreas De Bolivia",
                    "89Th Airlift Wing",
                    "388Th Fighter Wing",
                    "366Th Fighter Wing",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.37, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "C Level",
                },
            },
            {
                categories: [
                    "Georgia Institute Of Technology",
                    "University Of Minnesota",
                    "Turner (Turner Broadcasting System, Inc)",
                    "Purdue University",
                    "Embry-Riddle Aeronautical University",
                    "Wilmington Trust",
                    "Wayne State University School Of Medicine",
                    "Vision Critical",
                    "Venturelab At Georgia Tech",
                    "Vanderbilt University",
                    "Upmc",
                    "University Of Washington",
                    "University Of Richmond",
                    "University Of North Dakota",
                    "University Of North Carolina Greensboro",
                    "University Of Illinois At Urbana-Champaign",
                    "University Of Florida",
                    "University Of Colorado Denver",
                    "University Of Colorado Anschutz Medical Campus",
                    "United States Air Force",
                    "U.S. Department Of State",
                    "The University Of Chicago",
                    "The Leahy Center",
                    "Terry College Of Business At The University Of Georgia",
                    "Telsey Advisory Group",
                    "Symantec",
                    "Southern Utah University",
                    "Signify",
                    "Samsung Medical Center",
                    "Righteye",
                    "Portland State University",
                    "Perimeter College At Georgia State University",
                    "North Carolina State University",
                    "Middle Tennessee State University",
                    "Merck",
                    "Lauder Business School",
                    "Kennesaw State University",
                    "Kelley School Of Business At Indiana University",
                    "Keene State College",
                    "Kantar",
                    "Kamehameha Schools",
                    "Itre",
                    "Goizueta Business School",
                    "Georgia Tech Research Institute",
                    "Georgia Tech Athletic Association",
                    "Georgia State University - Andrew Young School Of Policy Studies",
                    "Fusion Hill",
                    "Franklin College Of Arts And Sciences",
                    "Eurofins Central Laboratory",
                    "Emory University",
                    "Earthwatch Institute",
                    "Credit Suisse",
                    "Cnpq - Conselho Nacional De Desenvolvimento Científico E Tecnológico",
                    "Brody Square - Michigan State University",
                    "Bon Secours Health System",
                    "Auburn University",
                    "Amirkabir University Of Technology",
                    "Aerospace Systems Design Laboratory",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.08, 0.03, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Research and Development",
                },
            },
            {
                categories: [
                    "Cognizant",
                    "Panera Bread",
                    "Guidehouse",
                    "Cnn",
                    "Wolfgang Puck Catering",
                    "Winston Flowers",
                    "Walt Disney World",
                    "Victoria'S Secret",
                    "Uniqlo",
                    "Turner Broadcasting Systems",
                    "Triage Consulting Group",
                    "The White House",
                    "The Home Depot",
                    "Simeio Solutions",
                    "Showtime Networks Inc.",
                    "Prometric",
                    "Pottery Barn",
                    "Plus Communications",
                    "Olmstead, Lynch & Curtis, Llc",
                    "Northwest Airlines",
                    "Noresco",
                    "Nordstrom",
                    "Newell Rubbermaid",
                    "Nbc News",
                    "Nami Minnesota",
                    "Msc Industrial Supply Co.",
                    "Mount Sinai Health System",
                    "Milbank Llp",
                    "Mckinsey & Company",
                    "Lyft",
                    "L&W Engineering",
                    "Kpmg Us",
                    "Kpmg",
                    "Kilpatrick Townsend & Stockton Llp",
                    "Kantar",
                    "Jpmorgan Chase & Co.",
                    "Isg Partners",
                    "International Rescue Committee",
                    "Insight Television Productions, Llc",
                    "Igt",
                    "Hughes Hubbard & Reed Llp",
                    "Hannaford Supermarkets",
                    "Goizueta Business School",
                    "Fox 5 Atlanta, Waga-Tv",
                    "Fortress Investment Group",
                    "Foot Locker",
                    "Ey",
                    "Davis Brand Capital",
                    "Csx",
                    "Crate & Barrel",
                    "Core (Community Organized Relief Effort)",
                    "Cole, Scott & Kissane, P.A.",
                    "Cognizant Technology Solutions",
                    "Boy Scouts Of America",
                    "Atlanta Botanical Garden",
                    "Asw Distillery",
                    "Americasmart Atlanta",
                    "Air Force Association",
                    "21 Choices Frozen Yogurt",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.03, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Associate",
                },
            },
            {
                categories: [
                    "Northwest Airlines",
                    "Ey",
                    "Bank Of America",
                    "Zeta Tau Alpha Fraternity",
                    "Wayne State University",
                    "Walmart",
                    "Wachovia Corp",
                    "Volunteer Income Tax Assistance",
                    "Vodacom Congo (Rdc) S.A",
                    "Virgin America",
                    "University Of California",
                    "United Airlines",
                    "U.S. Bank",
                    "Thomson Reuters",
                    "The Phoenix Group",
                    "The Dragonfly Foundation",
                    "Target",
                    "Suntrust Bank",
                    "State Of Washington",
                    "Starwood Hotels & Resorts Worldwide, Inc.",
                    "Southwind Hoa",
                    "Society Of Women Engineers",
                    "Self Employed",
                    "Rosetta Stone",
                    "Rockwell Collins",
                    "Procter & Gamble",
                    "Nationsbank",
                    "Nasa Goddard Space Flight Center",
                    "Marriott International",
                    "Liberty Tax Service",
                    "Kensington Investment Company",
                    "Kaplan Higher Education Corporation",
                    "Irs Volunteer Income Tax Assistance Program",
                    "Inform",
                    "H&R Block",
                    "H & R Block",
                    "Globex Travels And Exchange Limited",
                    "German American Chamber Of Commerce",
                    "Freeborn & Peters Llp",
                    "Equity Bank",
                    "Enterprise Rent-A-Car",
                    "Emory University",
                    "Ecobank Transnational Incorporated",
                    "Delta Community Credit Union",
                    "Deloitte",
                    "Comerica Bank",
                    "Comair",
                    "Clackamas County",
                    "Bnsf Railway",
                    "Banning Lewis Ranch",
                    "Atlanta Interactive Marketing Association (Aima)",
                    "American Airlines",
                    "Ally Financial",
                    "Air Line Pilots Association",
                    "Ab Mauri North America",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.05, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Accounting",
                },
            },
            {
                categories: [
                    "Wells Fargo",
                    "Bank Of America",
                    "Chase",
                    "Jpmorgan Chase & Co.",
                    "Guardian Savings Bank",
                    "Usf Federal Credit Union",
                    "U.S. Bank",
                    "The Preiss Company",
                    "The Peoples Bank",
                    "Td",
                    "Synovus",
                    "Synchrony",
                    "Stonehenge Nyc",
                    "Southeast Mortgage Of Georgia, Inc.",
                    "Residential Home Funding Corp.",
                    "Rbs Global Banking And Markets",
                    "Pwc",
                    "Pnc",
                    "Ogden Cap Properties, Llc",
                    "National Credit Union Administration",
                    "National Bank Of Arizona",
                    "Lincoln Property Company",
                    "Landmark Properties, Inc.",
                    "Jpmorgan Chase",
                    "Huntington National Bank",
                    "Homelink Mortgage Inc.",
                    "Herzog Heine Geduld, Inc.",
                    "Guaranty Bank/ Best Bank",
                    "Gte Financial",
                    "Greystar",
                    "Goldman Sachs",
                    "Gilman Ventures Llc",
                    "Flagstar Bank",
                    "Elite Property Management",
                    "Directions Credit Union",
                    "Citi",
                    "Cingular Wireless",
                    "Caruso",
                    "Asset Living",
                    "Arbor Property Management",
                    "American Nationwide Mortgage",
                    "Allen & Ohara Education Services Inc",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.07, 0.07, 0.05, 0.03, 0.03, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                                0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01, 0.01,
                            ],
                        },
                    ],
                    name: "Retail Banking",
                },
            },
        ],
        educationDistributionPerCompany: {
            tableChart: {
                tableHead: ["Edu/Stat", "Current", "Previous", "Total"],
                tableBody: [
                    [
                        {
                            data: [
                                "Bachelor's Degree",
                                {
                                    series: [
                                        {
                                            data: [9.24],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [17.78],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [24.97],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Master's Degree",
                                {
                                    series: [
                                        {
                                            data: [8.1],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [19.26],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [25.88],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Doctoral Degree",
                                {
                                    series: [
                                        {
                                            data: [22.13],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [33.6],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [47.62],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Total",
                                {
                                    series: [
                                        {
                                            data: [12.39],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [22.01],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [31.08],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                ],
            },
        },
    },
    section3: {
        workExperienceCurrent: {
            categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            stack: {
                series: {
                    data: [
                        0.03, 10.92, 6.05, 9.4, 7.44, 8.07, 5.35, 5.73, 5.7, 3.8, 2.53, 1.96, 2.88, 1.42, 1.58, 1.71,
                        1.27, 0.73, 0.32, 0.44, 12.07,
                    ],
                },
            },
        },
        workExperiencePrevious: {
            categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            stack: {
                series: {
                    data: [
                        0.54, 2.03, 2.34, 2.82, 3.61, 3.13, 3.39, 4.34, 3.93, 3.67, 3.83, 3.77, 2.75, 2.6, 2.25, 1.93,
                        2.47, 1.99, 1.55, 2.28, 23.84,
                    ],
                },
            },
        },
        workExperienceTotal: {
            categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
            stack: {
                series: {
                    data: [
                        0, 0.47, 0.19, 0.54, 1.2, 1.23, 1.42, 2.63, 2.75, 3.13, 3.39, 3.74, 3.86, 3.42, 3.45, 3.26,
                        3.42, 3.04, 2.44, 2.25, 44.1,
                    ],
                },
            },
        },
        workExperienceCurrentGrouped: {
            categories: ["0-3", "3-5", "5-10", "10+"],
            stack: {
                series: {
                    data: [26.4, 15.51, 23.11, 24.38],
                },
            },
        },
        workExperiencePreviousGrouped: {
            categories: ["0-3", "3-5", "5-10", "10+"],
            stack: {
                series: {
                    data: [7.73, 6.74, 19.16, 45.43],
                },
            },
        },
        workExperienceTotalGrouped: {
            categories: ["0-3", "3-5", "5-10", "10+"],
            stack: {
                series: {
                    data: [1.2, 2.43, 13.32, 72.98],
                },
            },
        },
    },
    filters: {
        jobTitles: [
            {
                id: 1,
                name: "Accounting",
            },
            {
                id: 4,
                name: "Art",
            },
            {
                id: 5,
                name: "Associate",
            },
            {
                id: 3,
                name: "Automation",
            },
            {
                id: 6,
                name: "Board Member",
            },
            {
                id: 2,
                name: "Business Administration",
            },
            {
                id: 7,
                name: "Business Development",
            },
            {
                id: 15,
                name: "C Level",
            },
            {
                id: 8,
                name: "Coach / Professor / Mentor",
            },
            {
                id: 9,
                name: "Consulting",
            },
            {
                id: 10,
                name: "Corporate Affairs",
            },
            {
                id: 11,
                name: "Craft Trade Workers",
            },
            {
                id: 12,
                name: "Customer Services",
            },
            {
                id: 14,
                name: "Data Science and Analysis",
            },
            {
                id: 18,
                name: "Design",
            },
            {
                id: 19,
                name: "Engineering",
            },
            {
                id: 20,
                name: "Finance",
            },
            {
                id: 17,
                name: "Founders / Shareholders",
            },
            {
                id: 22,
                name: "Human Resources",
            },
            {
                id: 23,
                name: "Information Technology",
            },
            {
                id: 24,
                name: "Insurance",
            },
            {
                id: 55,
                name: "Intern",
            },
            {
                id: 25,
                name: "Investors",
            },
            {
                id: 27,
                name: "Legal Services",
            },
            {
                id: 29,
                name: "Manufacturing",
            },
            {
                id: 30,
                name: "Marketing",
            },
            {
                id: 31,
                name: "Media and Entertainment",
            },
            {
                id: 32,
                name: "Medical / Wellness",
            },
            {
                id: 28,
                name: "Middle Management",
            },
            {
                id: 42,
                name: "Military",
            },
            {
                id: 64,
                name: "Movie",
            },
            {
                id: 34,
                name: "Operations",
            },
            {
                id: 67,
                name: "Other Occupations",
            },
            {
                id: 35,
                name: "Partner",
            },
            {
                id: 40,
                name: "Police",
            },
            {
                id: 36,
                name: "Product Development",
            },
            {
                id: 37,
                name: "Production",
            },
            {
                id: 38,
                name: "Project Management",
            },
            {
                id: 39,
                name: "Protection",
            },
            {
                id: 41,
                name: "Public Services",
            },
            {
                id: 43,
                name: "Quality Management",
            },
            {
                id: 44,
                name: "Real Estate",
            },
            {
                id: 45,
                name: "Research and Development",
            },
            {
                id: 46,
                name: "Retail",
            },
            {
                id: 47,
                name: "Retail Banking",
            },
            {
                id: 48,
                name: "Sales",
            },
            {
                id: 49,
                name: "Self Employed",
            },
            {
                id: 50,
                name: "Social Services",
            },
            {
                id: 58,
                name: "Software Development",
            },
            {
                id: 57,
                name: "Sound",
            },
            {
                id: 59,
                name: "Sports / Fitness / Trainer",
            },
            {
                id: 51,
                name: "Student",
            },
            {
                id: 53,
                name: "Supply Chain Management / Logistics",
            },
            {
                id: 61,
                name: "Team lead / Technical lead",
            },
            {
                id: 54,
                name: "Technician",
            },
            {
                id: 16,
                name: "Top Management",
            },
            {
                id: 56,
                name: "Trainee",
            },
            {
                id: 60,
                name: "Transportation Services",
            },
            {
                id: 62,
                name: "University / Academic Affairs",
            },
            {
                id: 63,
                name: "Visual Merchandising",
            },
            {
                id: 65,
                name: "Volunteering",
            },
            {
                id: 66,
                name: "Writer",
            },
        ],
        schools: [
            {
                id: 10314,
                name: "Academy of Art University",
            },
            {
                id: 3461,
                name: "Acharya Nagarjuna University",
            },
            {
                id: 2576,
                name: "Addis Ababa University",
            },
            {
                id: 11226,
                name: "Adelphi University",
            },
            {
                id: 10538,
                name: "Agnes Scott College",
            },
            {
                id: 10553,
                name: "Albany State University",
            },
            {
                id: 10453,
                name: "American University",
            },
            {
                id: 21751,
                name: "Amity University",
            },
            {
                id: 4110,
                name: "Anna University",
            },
            {
                id: 11340,
                name: "Appalachian State University",
            },
            {
                id: 10234,
                name: "Arizona State University",
            },
            {
                id: 10244,
                name: "Arkansas State University",
            },
            {
                id: 10249,
                name: "Arkansas Tech University",
            },
            {
                id: 4991,
                name: "Art University of Isfahan",
            },
            {
                id: 10904,
                name: "Assumption College",
            },
            {
                id: 8178,
                name: "Ateneo de Davao University",
            },
            {
                id: 8215,
                name: "Ateneo de Manila University",
            },
            {
                id: 10572,
                name: "Atlanta Metropolitan State College",
            },
            {
                id: 10202,
                name: "Auburn University",
            },
            {
                id: 7484,
                name: "Auckland University of Technology",
            },
            {
                id: 11009,
                name: "Augsburg University",
            },
            {
                id: 10535,
                name: "Augusta University",
            },
            {
                id: 11669,
                name: "Augustana University",
            },
            {
                id: 11772,
                name: "Austin College",
            },
            {
                id: 2858,
                name: "Avignon Université",
            },
            {
                id: 10976,
                name: "Baker College",
            },
            {
                id: 10673,
                name: "Ball State University",
            },
            {
                id: 10484,
                name: "Barry University",
            },
            {
                id: 11371,
                name: "Barton College",
            },
            {
                id: 11730,
                name: "Baylor University",
            },
            {
                id: 1389,
                name: "Beijing Language and Culture University",
            },
            {
                id: 11895,
                name: "Bellevue College",
            },
            {
                id: 11113,
                name: "Bellevue University",
            },
            {
                id: 11685,
                name: "Belmont University",
            },
            {
                id: 11016,
                name: "Bemidji State University",
            },
            {
                id: 10759,
                name: "Benedictine College",
            },
            {
                id: 11361,
                name: "Bennett College",
            },
            {
                id: 10892,
                name: "Bentley University",
            },
            {
                id: 18597,
                name: "Berkeley College",
            },
            {
                id: 10880,
                name: "Berklee College of Music",
            },
            {
                id: 10757,
                name: "Bethel College",
            },
            {
                id: 10705,
                name: "Bethel University",
            },
            {
                id: 11012,
                name: "Bethel University",
            },
            {
                id: 10501,
                name: "Bethune-Cookman University",
            },
            {
                id: 10580,
                name: "Beulah Heights University",
            },
            {
                id: 10588,
                name: "Boise State University",
            },
            {
                id: 355,
                name: "Bond University",
            },
            {
                id: 10877,
                name: "Boston College",
            },
            {
                id: 10873,
                name: "Boston University",
            },
            {
                id: 10161,
                name: "Bournemouth University",
            },
            {
                id: 11403,
                name: "Bowling Green State University",
            },
            {
                id: 10611,
                name: "Bradley University",
            },
            {
                id: 10878,
                name: "Brandeis University",
            },
            {
                id: 10554,
                name: "Brenau University",
            },
            {
                id: 10796,
                name: "Brescia University",
            },
            {
                id: 10899,
                name: "Bridgewater State University",
            },
            {
                id: 11816,
                name: "Brigham Young University",
            },
            {
                id: 11267,
                name: "Brooklyn College",
            },
            {
                id: 11257,
                name: "Brooklyn Law School",
            },
            {
                id: 10479,
                name: "Broward College",
            },
            {
                id: 11622,
                name: "Brown University",
            },
            {
                id: 11627,
                name: "Bryant University",
            },
            {
                id: 3422,
                name: "Budapesti Muszaki és Gazdaságtudományi Egyetem",
            },
            {
                id: 11274,
                name: "Buffalo State College",
            },
            {
                id: 10336,
                name: "California Baptist University",
            },
            {
                id: 10326,
                name: "California Institute of Integral Studies",
            },
            {
                id: 10320,
                name: "California Lutheran University",
            },
            {
                id: 10281,
                name: "California State University, Long Beach",
            },
            {
                id: 10318,
                name: "California State University, Monterey Bay",
            },
            {
                id: 10278,
                name: "California State University, Northridge",
            },
            {
                id: 11582,
                name: "California University of Pennsylvania",
            },
            {
                id: 11954,
                name: "Cardinal Stritch University",
            },
            {
                id: 11517,
                name: "Carnegie Mellon University",
            },
            {
                id: 11943,
                name: "Carthage College",
            },
            {
                id: 10959,
                name: "Central Michigan University",
            },
            {
                id: 11442,
                name: "Central State University",
            },
            {
                id: 11889,
                name: "Central Washington University",
            },
            {
                id: 10287,
                name: "Chapman University",
            },
            {
                id: 11696,
                name: "Christian Brothers University",
            },
            {
                id: 11857,
                name: "Christopher Newport University",
            },
            {
                id: 9451,
                name: "Chulalongkorn University",
            },
            {
                id: 1918,
                name: "Civil Aviation University of China",
            },
            {
                id: 10319,
                name: "Claremont McKenna College",
            },
            {
                id: 10544,
                name: "Clark Atlanta University",
            },
            {
                id: 10550,
                name: "Clayton State University",
            },
            {
                id: 11632,
                name: "Clemson University",
            },
            {
                id: 11409,
                name: "Cleveland State University",
            },
            {
                id: 11635,
                name: "College of Charleston",
            },
            {
                id: 11311,
                name: "College of Staten Island",
            },
            {
                id: 10895,
                name: "College of the Holy Cross",
            },
            {
                id: 10403,
                name: "Colorado State University",
            },
            {
                id: 10413,
                name: "Colorado Technical University",
            },
            {
                id: 11060,
                name: "Columbia College",
            },
            {
                id: 10612,
                name: "Columbia College Chicago",
            },
            {
                id: 18514,
                name: "Columbia University in the City of New York",
            },
            {
                id: 10547,
                name: "Columbus State University",
            },
            {
                id: 11017,
                name: "Concordia College",
            },
            {
                id: 1278,
                name: "Concordia University",
            },
            {
                id: 10867,
                name: "Coppin State University",
            },
            {
                id: 11186,
                name: "Cornell University",
            },
            {
                id: 10053,
                name: "Cranfield University",
            },
            {
                id: 1759,
                name: "Dalian Maritime University",
            },
            {
                id: 1755,
                name: "Dalian University of Technology",
            },
            {
                id: 11781,
                name: "Dallas Baptist University",
            },
            {
                id: 9259,
                name: "Damascus University",
            },
            {
                id: 10973,
                name: "Davenport University",
            },
            {
                id: 10502,
                name: "Daytona State College",
            },
            {
                id: 8115,
                name: "De La Salle Lipa",
            },
            {
                id: 10600,
                name: "DePaul University",
            },
            {
                id: 10608,
                name: "DeVry University",
            },
            {
                id: 364,
                name: "Deakin University",
            },
            {
                id: 10933,
                name: "Dean College",
            },
            {
                id: 11039,
                name: "Delta State University",
            },
            {
                id: 23102,
                name: "Deutsche Sporthochschule Köln",
            },
            {
                id: 19148,
                name: "Dixie State University",
            },
            {
                id: 10623,
                name: "Dominican University",
            },
            {
                id: 11520,
                name: "Drexel University",
            },
            {
                id: 11337,
                name: "Duke University",
            },
            {
                id: 11527,
                name: "Duquesne University",
            },
            {
                id: 11343,
                name: "East Carolina University",
            },
            {
                id: 10567,
                name: "East Georgia State College",
            },
            {
                id: 11682,
                name: "East Tennessee State University",
            },
            {
                id: 10775,
                name: "Eastern Kentucky University",
            },
            {
                id: 10961,
                name: "Eastern Michigan University",
            },
            {
                id: 11888,
                name: "Eastern Washington University",
            },
            {
                id: 10489,
                name: "Eckerd College",
            },
            {
                id: 11567,
                name: "Edinboro University of Pennsylvania",
            },
            {
                id: 11344,
                name: "Elon University",
            },
            {
                id: 10472,
                name: "Embry-Riddle Aeronautical University",
            },
            {
                id: 10531,
                name: "Emory University",
            },
            {
                id: 7570,
                name: "Enugu State University of Science and Technology",
            },
            {
                id: 3054,
                name: "Europa-Universität Viadrina Frankfurt (Oder)",
            },
            {
                id: 10520,
                name: "Everglades University",
            },
            {
                id: 10431,
                name: "Fairfield University",
            },
            {
                id: 11158,
                name: "Fairleigh Dickinson University",
            },
            {
                id: 11266,
                name: "Farmingdale State College",
            },
            {
                id: 18535,
                name: "Fashion Institute of Technology",
            },
            {
                id: 3148,
                name: "FernUniversität in Hagen",
            },
            {
                id: 10962,
                name: "Ferris State University",
            },
            {
                id: 11693,
                name: "Fisk University",
            },
            {
                id: 10483,
                name: "Florida Agricultural and Mechanical University",
            },
            {
                id: 10470,
                name: "Florida Atlantic University",
            },
            {
                id: 10478,
                name: "Florida Gulf Coast University",
            },
            {
                id: 10474,
                name: "Florida Institute of Technology",
            },
            {
                id: 10468,
                name: "Florida International University",
            },
            {
                id: 10490,
                name: "Florida Southern College",
            },
            {
                id: 10465,
                name: "Florida State University",
            },
            {
                id: 18520,
                name: "Fordham University",
            },
            {
                id: 10557,
                name: "Fort Valley State University",
            },
            {
                id: 10918,
                name: "Framingham State University",
            },
            {
                id: 3019,
                name: "Freie Universität Berlin",
            },
            {
                id: 1843,
                name: "Fudan University",
            },
            {
                id: 11636,
                name: "Furman University",
            },
            {
                id: 11842,
                name: "George Mason University",
            },
            {
                id: 10451,
                name: "Georgetown University",
            },
            {
                id: 10542,
                name: "Georgia College and State University",
            },
            {
                id: 10560,
                name: "Georgia Gwinnett College",
            },
            {
                id: 10528,
                name: "Georgia Institute of Technology",
            },
            {
                id: 10534,
                name: "Georgia Southern University",
            },
            {
                id: 10530,
                name: "Georgia State University",
            },
            {
                id: 11532,
                name: "Gettysburg College",
            },
            {
                id: 11887,
                name: "Gonzaga University",
            },
            {
                id: 10901,
                name: "Gordon College",
            },
            {
                id: 10571,
                name: "Gordon State College",
            },
            {
                id: 10733,
                name: "Graceland University",
            },
            {
                id: 10237,
                name: "Grand Canyon University",
            },
            {
                id: 10956,
                name: "Grand Valley State University",
            },
            {
                id: 18333,
                name: "Gustavus Adolphus College",
            },
            {
                id: 11007,
                name: "Hamline University",
            },
            {
                id: 11856,
                name: "Hampton University",
            },
            {
                id: 2404,
                name: "Handelshøjskolen i København",
            },
            {
                id: 10248,
                name: "Harding University",
            },
            {
                id: 10872,
                name: "Harvard University",
            },
            {
                id: 10295,
                name: "Harvey Mudd College",
            },
            {
                id: 10583,
                name: "Hawaii Pacific University",
            },
            {
                id: 2619,
                name: "Helsingin yliopisto",
            },
            {
                id: 11953,
                name: "Herzing University",
            },
            {
                id: 11435,
                name: "Hiram College",
            },
            {
                id: 11212,
                name: "Hobart and William Smith Colleges",
            },
            {
                id: 2994,
                name: "Hochschule Weihenstephan-Triesdorf",
            },
            {
                id: 3025,
                name: "Hochschule für Technik und Wirtschaft Berlin",
            },
            {
                id: 11202,
                name: "Hofstra University",
            },
            {
                id: 7469,
                name: "Hogeschool Rotterdam",
            },
            {
                id: 8137,
                name: "Holy Angel University",
            },
            {
                id: 10454,
                name: "Howard University",
            },
            {
                id: 10897,
                name: "Hult International Business School",
            },
            {
                id: 3020,
                name: "Humboldt-Universität zu Berlin",
            },
            {
                id: 9122,
                name: "IE University",
            },
            {
                id: 3271,
                name: "IU Internationale Hochschule",
            },
            {
                id: 10590,
                name: "Idaho State University",
            },
            {
                id: 10601,
                name: "Illinois Institute of Technology",
            },
            {
                id: 10604,
                name: "Illinois State University",
            },
            {
                id: 10676,
                name: "Indiana State University",
            },
            {
                id: 10670,
                name: "Indiana University Bloomington",
            },
            {
                id: 10690,
                name: "Indiana University Southeast",
            },
            {
                id: 11530,
                name: "Indiana University of Pennsylvania",
            },
            {
                id: 10687,
                name: "Indiana Wesleyan University",
            },
            {
                id: 11260,
                name: "Iona College",
            },
            {
                id: 10713,
                name: "Iowa State University",
            },
            {
                id: 4986,
                name: "Isfahan University of Medical Sciences",
            },
            {
                id: 4984,
                name: "Isfahan University of Technology",
            },
            {
                id: 8903,
                name: "Islamic University of Madinah",
            },
            {
                id: 11036,
                name: "Jackson State University",
            },
            {
                id: 10209,
                name: "Jacksonville State University",
            },
            {
                id: 10488,
                name: "Jacksonville University",
            },
            {
                id: 11846,
                name: "James Madison University",
            },
            {
                id: 4164,
                name: "Jawaharlal Nehru Technological University",
            },
            {
                id: 1727,
                name: "Jiangxi University of Finance and Economics",
            },
            {
                id: 18610,
                name: "John Jay College of Criminal Justice",
            },
            {
                id: 11366,
                name: "Johnson C. Smith University",
            },
            {
                id: 11626,
                name: "Johnson and Wales University",
            },
            {
                id: 10975,
                name: "Kalamazoo College",
            },
            {
                id: 5829,
                name: "Kansai Gaidai University",
            },
            {
                id: 10747,
                name: "Kansas State University",
            },
            {
                id: 9452,
                name: "Kasetsart University",
            },
            {
                id: 11164,
                name: "Kean University",
            },
            {
                id: 11142,
                name: "Keene State College",
            },
            {
                id: 10532,
                name: "Kennesaw State University",
            },
            {
                id: 11402,
                name: "Kent State University",
            },
            {
                id: 10782,
                name: "Kentucky State University",
            },
            {
                id: 10968,
                name: "Kettering University",
            },
            {
                id: 9983,
                name: "Khalifa University",
            },
            {
                id: 11694,
                name: "King University",
            },
            {
                id: 10077,
                name: "Kingston University",
            },
            {
                id: 6332,
                name: "Korea National University of Transportation",
            },
            {
                id: 3637,
                name: "Kurukshetra University",
            },
            {
                id: 11741,
                name: "Lamar University",
            },
            {
                id: 11487,
                name: "Langston University",
            },
            {
                id: 443,
                name: "Lauder Business School",
            },
            {
                id: 11775,
                name: "LeTourneau University",
            },
            {
                id: 10900,
                name: "Lesley University",
            },
            {
                id: 3111,
                name: "Leuphana Universität Lüneburg",
            },
            {
                id: 10624,
                name: "Lewis University",
            },
            {
                id: 5327,
                name: "Libera Università di Lingue e Comunicazione IULM",
            },
            {
                id: 11845,
                name: "Liberty University",
            },
            {
                id: 8290,
                name: "Liceo de Cagayan University",
            },
            {
                id: 10551,
                name: "Life University",
            },
            {
                id: 18392,
                name: "Lindenwood University",
            },
            {
                id: 11381,
                name: "Livingstone College",
            },
            {
                id: 11855,
                name: "Longwood University",
            },
            {
                id: 10800,
                name: "Louisiana State University",
            },
            {
                id: 10805,
                name: "Louisiana Tech University",
            },
            {
                id: 10602,
                name: "Loyola University Chicago",
            },
            {
                id: 10802,
                name: "Loyola University New Orleans",
            },
            {
                id: 2976,
                name: "Ludwig-Maximilians-Universität München",
            },
            {
                id: 10719,
                name: "Luther College",
            },
            {
                id: 11002,
                name: "Macalester College",
            },
            {
                id: 10984,
                name: "Madonna University",
            },
            {
                id: 11930,
                name: "Marquette University",
            },
            {
                id: 11909,
                name: "Marshall University",
            },
            {
                id: 11876,
                name: "Mary Baldwin University",
            },
            {
                id: 10871,
                name: "Massachusetts Institute of Technology",
            },
            {
                id: 10930,
                name: "Massachusetts Maritime Academy",
            },
            {
                id: 10536,
                name: "Mercer University",
            },
            {
                id: 11252,
                name: "Mercy College",
            },
            {
                id: 11020,
                name: "Metropolitan State University",
            },
            {
                id: 10411,
                name: "Metropolitan State University of Denver",
            },
            {
                id: 10477,
                name: "Miami Dade College",
            },
            {
                id: 11406,
                name: "Miami University",
            },
            {
                id: 10952,
                name: "Michigan State University",
            },
            {
                id: 10556,
                name: "Middle Georgia State University",
            },
            {
                id: 11681,
                name: "Middle Tennessee State University",
            },
            {
                id: 470,
                name: "Milli Aviasiya Akademiyasi",
            },
            {
                id: 11001,
                name: "Minnesota State University, Mankato",
            },
            {
                id: 11032,
                name: "Mississippi State University",
            },
            {
                id: 11044,
                name: "Mississippi Valley State University",
            },
            {
                id: 11071,
                name: "Missouri Southern State University",
            },
            {
                id: 11055,
                name: "Missouri University of Science and Technology",
            },
            {
                id: 11021,
                name: "Mitchell Hamline School of Law",
            },
            {
                id: 11156,
                name: "Monmouth University",
            },
            {
                id: 18607,
                name: "Monroe College",
            },
            {
                id: 11155,
                name: "Montclair State University",
            },
            {
                id: 10781,
                name: "Morehead State University",
            },
            {
                id: 10540,
                name: "Morehouse College",
            },
            {
                id: 11431,
                name: "Muskingum University",
            },
            {
                id: 6295,
                name: "Myongji University",
            },
            {
                id: 1693,
                name: "Nanjing University",
            },
            {
                id: 1914,
                name: "Nankai University",
            },
            {
                id: 11672,
                name: "National American University",
            },
            {
                id: 17785,
                name: "National Defense University",
            },
            {
                id: 4114,
                name: "National Institute of Technology, Tiruchirappalli",
            },
            {
                id: 309,
                name: "National Polytechnic University of Armenia",
            },
            {
                id: 17643,
                name: "National University",
            },
            {
                id: 10309,
                name: "Naval Postgraduate School",
            },
            {
                id: 11265,
                name: "Nazareth College",
            },
            {
                id: 11182,
                name: "New Mexico Highlands University",
            },
            {
                id: 11236,
                name: "New York Institute of Technology",
            },
            {
                id: 18515,
                name: "New York University",
            },
            {
                id: 11348,
                name: "North Carolina Agricultural and Technical State University",
            },
            {
                id: 11349,
                name: "North Carolina Central University",
            },
            {
                id: 11338,
                name: "North Carolina State University",
            },
            {
                id: 11363,
                name: "North Carolina Wesleyan College",
            },
            {
                id: 11390,
                name: "North Dakota State University",
            },
            {
                id: 523,
                name: "North South University",
            },
            {
                id: 10876,
                name: "Northeastern University",
            },
            {
                id: 10236,
                name: "Northern Arizona University",
            },
            {
                id: 10777,
                name: "Northern Kentucky University",
            },
            {
                id: 10963,
                name: "Northern Michigan University",
            },
            {
                id: 11828,
                name: "Norwich University",
            },
            {
                id: 10471,
                name: "Nova Southeastern University",
            },
            {
                id: 10549,
                name: "Oglethorpe University",
            },
            {
                id: 11404,
                name: "Ohio University",
            },
            {
                id: 11422,
                name: "Ohio Wesleyan University",
            },
            {
                id: 11470,
                name: "Oklahoma State University",
            },
            {
                id: 11847,
                name: "Old Dominion University",
            },
            {
                id: 11493,
                name: "Oregon State University",
            },
            {
                id: 4161,
                name: "Osmania University",
            },
            {
                id: 18527,
                name: "Pace University",
            },
            {
                id: 10505,
                name: "Palm Beach Atlantic University",
            },
            {
                id: 10492,
                name: "Palm Beach State College",
            },
            {
                id: 3541,
                name: "Panjab University",
            },
            {
                id: 11064,
                name: "Park University",
            },
            {
                id: 11613,
                name: "Peirce College",
            },
            {
                id: 1374,
                name: "Peking University",
            },
            {
                id: 11516,
                name: "Penn State University",
            },
            {
                id: 10286,
                name: "Pepperdine University",
            },
            {
                id: 8245,
                name: "Philippine School of Business Administration",
            },
            {
                id: 10561,
                name: "Piedmont College",
            },
            {
                id: 5319,
                name: "Politecnico di Milano",
            },
            {
                id: 10506,
                name: "Polk State College",
            },
            {
                id: 11495,
                name: "Portland State University",
            },
            {
                id: 4006,
                name: "Punjab Technical University",
            },
            {
                id: 10669,
                name: "Purdue University",
            },
            {
                id: 10064,
                name: "Queen Mary University of London",
            },
            {
                id: 10427,
                name: "Quinnipiac University",
            },
            {
                id: 11850,
                name: "Radford University",
            },
            {
                id: 11167,
                name: "Ramapo College of New Jersey",
            },
            {
                id: 18326,
                name: "Rasmussen College",
            },
            {
                id: 10412,
                name: "Regis University",
            },
            {
                id: 1375,
                name: "Renmin University of China",
            },
            {
                id: 11194,
                name: "Rensselaer Polytechnic Institute",
            },
            {
                id: 3138,
                name: "Rheinisch-Westfälische Technische Hochschule Aachen",
            },
            {
                id: 11687,
                name: "Rhodes College",
            },
            {
                id: 10094,
                name: "Richmond, The American International University in London",
            },
            {
                id: 18896,
                name: "Robert Morris University",
            },
            {
                id: 11192,
                name: "Rochester Institute of Technology",
            },
            {
                id: 11104,
                name: "Rocky Mountain College",
            },
            {
                id: 11628,
                name: "Roger Williams University",
            },
            {
                id: 10481,
                name: "Rollins College",
            },
            {
                id: 10617,
                name: "Roosevelt University",
            },
            {
                id: 11245,
                name: "SUNY Polytechnic Institute",
            },
            {
                id: 10972,
                name: "Saginaw Valley State University",
            },
            {
                id: 11536,
                name: "Saint Joseph's University",
            },
            {
                id: 18379,
                name: "Saint Louis University",
            },
            {
                id: 11900,
                name: "Saint Martin's University",
            },
            {
                id: 10693,
                name: "Saint Mary's College",
            },
            {
                id: 11018,
                name: "Saint Mary's University of Minnesota",
            },
            {
                id: 11168,
                name: "Saint Peter's University",
            },
            {
                id: 10903,
                name: "Salem State University",
            },
            {
                id: 10854,
                name: "Salisbury University",
            },
            {
                id: 10277,
                name: "San Diego State University",
            },
            {
                id: 10280,
                name: "San Francisco State University",
            },
            {
                id: 10487,
                name: "Santa Fe College",
            },
            {
                id: 5306,
                name: "Sapienza Università di Roma",
            },
            {
                id: 10533,
                name: "Savannah College of Art and Design",
            },
            {
                id: 10555,
                name: "Savannah State University",
            },
            {
                id: 3869,
                name: "Savitribai Phule Pune University",
            },
            {
                id: 18536,
                name: "School of Visual Arts",
            },
            {
                id: 10614,
                name: "School of the Art Institute of Chicago",
            },
            {
                id: 11891,
                name: "Seattle Pacific University",
            },
            {
                id: 11886,
                name: "Seattle University",
            },
            {
                id: 1855,
                name: "Shanghai Normal University",
            },
            {
                id: 5128,
                name: "Sharif University of Technology",
            },
            {
                id: 10193,
                name: "Sheffield Hallam University",
            },
            {
                id: 11910,
                name: "Shepherd University",
            },
            {
                id: 11535,
                name: "Shippensburg University of Pennsylvania",
            },
            {
                id: 3878,
                name: "Shivaji University",
            },
            {
                id: 10987,
                name: "Siena Heights University",
            },
            {
                id: 9459,
                name: "Silpakorn University",
            },
            {
                id: 10726,
                name: "Simpson College",
            },
            {
                id: 11220,
                name: "Skidmore College",
            },
            {
                id: 5934,
                name: "Sophia University",
            },
            {
                id: 2719,
                name: "Sorbonne Université",
            },
            {
                id: 11643,
                name: "South Carolina State University",
            },
            {
                id: 11666,
                name: "South Dakota State University",
            },
            {
                id: 10807,
                name: "Southeastern Louisiana University",
            },
            {
                id: 10497,
                name: "Southeastern University",
            },
            {
                id: 10605,
                name: "Southern Illinois University Edwardsville",
            },
            {
                id: 11729,
                name: "Southern Methodist University",
            },
            {
                id: 11139,
                name: "Southern New Hampshire University",
            },
            {
                id: 11820,
                name: "Southern Utah University",
            },
            {
                id: 11083,
                name: "Southwest Baptist University",
            },
            {
                id: 10541,
                name: "Spelman College",
            },
            {
                id: 10977,
                name: "Spring Arbor University",
            },
            {
                id: 4149,
                name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
            },
            {
                id: 3469,
                name: "Sri Krishnadevaraya University",
            },
            {
                id: 10722,
                name: "St. Ambrose University",
            },
            {
                id: 11241,
                name: "St. Bonaventure University",
            },
            {
                id: 21940,
                name: "St. Bonaventure University",
            },
            {
                id: 11008,
                name: "St. Catherine University",
            },
            {
                id: 18332,
                name: "St. Cloud State University",
            },
            {
                id: 11305,
                name: "St. Francis College",
            },
            {
                id: 11215,
                name: "St. John's University",
            },
            {
                id: 11757,
                name: "St. Mary's University",
            },
            {
                id: 11945,
                name: "St. Norbert College",
            },
            {
                id: 11003,
                name: "St. Olaf College",
            },
            {
                id: 10265,
                name: "Stanford University",
            },
            {
                id: 11228,
                name: "State University of New York at New Paltz",
            },
            {
                id: 11748,
                name: "Stephen F. Austin State University",
            },
            {
                id: 11072,
                name: "Stephens College",
            },
            {
                id: 11163,
                name: "Stockton University",
            },
            {
                id: 11195,
                name: "Stony Brook University",
            },
            {
                id: 10457,
                name: "Strayer University",
            },
            {
                id: 10890,
                name: "Suffolk University",
            },
            {
                id: 2560,
                name: "Tallinna Tehnikaülikool",
            },
            {
                id: 5263,
                name: "Technion - Israel Institute of Technology",
            },
            {
                id: 22937,
                name: "Technische Hochschule Nürnberg Georg Simon Ohm",
            },
            {
                id: 7444,
                name: "Technische Universiteit Eindhoven",
            },
            {
                id: 3021,
                name: "Technische Universität Berlin",
            },
            {
                id: 2975,
                name: "Technische Universität München",
            },
            {
                id: 423,
                name: "Technische Universität Wien",
            },
            {
                id: 11519,
                name: "Temple University",
            },
            {
                id: 11725,
                name: "Texas A&M University",
            },
            {
                id: 11756,
                name: "Texas A&M University-Commerce",
            },
            {
                id: 11747,
                name: "Texas A&M University-Corpus Christi",
            },
            {
                id: 11759,
                name: "Texas Southern University",
            },
            {
                id: 11736,
                name: "Texas State University",
            },
            {
                id: 11731,
                name: "Texas Tech University",
            },
            {
                id: 11783,
                name: "Texas Wesleyan University",
            },
            {
                id: 11752,
                name: "Texas Woman's University",
            },
            {
                id: 16421,
                name: "The American University in Cairo",
            },
            {
                id: 2748,
                name: "The American University of Paris",
            },
            {
                id: 10455,
                name: "The Catholic University of America",
            },
            {
                id: 18609,
                name: "The City College of New York",
            },
            {
                id: 11013,
                name: "The College of St. Scholastica",
            },
            {
                id: 18523,
                name: "The New School",
            },
            {
                id: 11399,
                name: "The Ohio State University",
            },
            {
                id: 10201,
                name: "The University of Alabama",
            },
            {
                id: 10120,
                name: "The University of Edinburgh",
            },
            {
                id: 2888,
                name: "The University of Georgia",
            },
            {
                id: 10195,
                name: "The University of Hull",
            },
            {
                id: 11680,
                name: "The University of Memphis",
            },
            {
                id: 11684,
                name: "The University of Tennessee at Chattanooga",
            },
            {
                id: 11734,
                name: "The University of Texas at Arlington",
            },
            {
                id: 11724,
                name: "The University of Texas at Austin",
            },
            {
                id: 11733,
                name: "The University of Texas at San Antonio",
            },
            {
                id: 11172,
                name: "Thomas Edison State University",
            },
            {
                id: 10790,
                name: "Thomas More University",
            },
            {
                id: 10700,
                name: "Trine University",
            },
            {
                id: 10430,
                name: "Trinity College",
            },
            {
                id: 8238,
                name: "Trinity University of Asia",
            },
            {
                id: 10206,
                name: "Troy University",
            },
            {
                id: 1373,
                name: "Tsinghua University",
            },
            {
                id: 10874,
                name: "Tufts University",
            },
            {
                id: 10801,
                name: "Tulane University",
            },
            {
                id: 10207,
                name: "Tuskegee University",
            },
            {
                id: 2739,
                name: "Télécom Paris",
            },
            {
                id: 11223,
                name: "Union College",
            },
            {
                id: 10405,
                name: "United States Air Force Academy",
            },
            {
                id: 11254,
                name: "United States Merchant Marine Academy",
            },
            {
                id: 10849,
                name: "United States Naval Academy",
            },
            {
                id: 10222,
                name: "United States Sports Academy",
            },
            {
                id: 7212,
                name: "Universidad Autónoma de Yucatán",
            },
            {
                id: 256,
                name: "Universidad Blas Pascal",
            },
            {
                id: 9137,
                name: "Universidad Carlos III de Madrid",
            },
            {
                id: 2032,
                name: "Universidad Externado de Colombia",
            },
            {
                id: 3366,
                name: "Universidad Rafael Landívar",
            },
            {
                id: 221,
                name: "Universidad Torcuato di Tella",
            },
            {
                id: 222,
                name: "Universidad de Belgrano",
            },
            {
                id: 1320,
                name: "Universidad de Chile",
            },
            {
                id: 2450,
                name: "Universidad de Guayaquil",
            },
            {
                id: 9151,
                name: "Universidad de Navarra",
            },
            {
                id: 9115,
                name: "Universidad de Salamanca",
            },
            {
                id: 2054,
                name: "Universidad de San Buenaventura",
            },
            {
                id: 3365,
                name: "Universidad de San Carlos de Guatemala",
            },
            {
                id: 8029,
                name: "Universidad de San Martín de Porres",
            },
            {
                id: 211,
                name: "Universidad del Este",
            },
            {
                id: 3370,
                name: "Universidad del Valle de Guatemala",
            },
            {
                id: 1032,
                name: "Universidade Anhembi Morumbi",
            },
            {
                id: 1019,
                name: "Universidade Estadual de Campinas",
            },
            {
                id: 863,
                name: "Universidade Federal de Alagoas",
            },
            {
                id: 882,
                name: "Universidade Federal do Ceará",
            },
            {
                id: 1029,
                name: "Universidade Metodista de São Paulo",
            },
            {
                id: 701,
                name: "Universiteit Gent",
            },
            {
                id: 7442,
                name: "Universiteit Maastricht",
            },
            {
                id: 7477,
                name: "Universiteit Utrecht",
            },
            {
                id: 87,
                name: "Universiteti i Tiranës",
            },
            {
                id: 10203,
                name: "University of Alabama at Birmingham",
            },
            {
                id: 10242,
                name: "University of Arkansas",
            },
            {
                id: 7483,
                name: "University of Auckland",
            },
            {
                id: 10848,
                name: "University of Baltimore",
            },
            {
                id: 10197,
                name: "University of Bradford",
            },
            {
                id: 10266,
                name: "University of California, Berkeley",
            },
            {
                id: 10270,
                name: "University of California, Davis",
            },
            {
                id: 10271,
                name: "University of California, Irvine",
            },
            {
                id: 10267,
                name: "University of California, Los Angeles",
            },
            {
                id: 10274,
                name: "University of California, Riverside",
            },
            {
                id: 10273,
                name: "University of California, Santa Barbara",
            },
            {
                id: 10275,
                name: "University of California, Santa Cruz",
            },
            {
                id: 10048,
                name: "University of Cambridge",
            },
            {
                id: 9086,
                name: "University of Cape Town",
            },
            {
                id: 10467,
                name: "University of Central Florida",
            },
            {
                id: 11062,
                name: "University of Central Missouri",
            },
            {
                id: 10595,
                name: "University of Chicago",
            },
            {
                id: 11400,
                name: "University of Cincinnati",
            },
            {
                id: 10402,
                name: "University of Colorado Boulder",
            },
            {
                id: 10407,
                name: "University of Colorado Colorado Springs",
            },
            {
                id: 10426,
                name: "University of Connecticut",
            },
            {
                id: 11405,
                name: "University of Dayton",
            },
            {
                id: 10404,
                name: "University of Denver",
            },
            {
                id: 10969,
                name: "University of Detroit Mercy",
            },
            {
                id: 10729,
                name: "University of Dubuque",
            },
            {
                id: 10079,
                name: "University of East London",
            },
            {
                id: 10464,
                name: "University of Florida",
            },
            {
                id: 10529,
                name: "University of Georgia",
            },
            {
                id: 10433,
                name: "University of Hartford",
            },
            {
                id: 11727,
                name: "University of Houston",
            },
            {
                id: 11760,
                name: "University of Houston-Downtown",
            },
            {
                id: 10596,
                name: "University of Illinois at Urbana-Champaign",
            },
            {
                id: 10686,
                name: "University of Indianapolis",
            },
            {
                id: 10714,
                name: "University of Iowa",
            },
            {
                id: 4985,
                name: "University of Isfahan",
            },
            {
                id: 11396,
                name: "University of Jamestown",
            },
            {
                id: 10772,
                name: "University of Kentucky",
            },
            {
                id: 7610,
                name: "University of Lagos",
            },
            {
                id: 10190,
                name: "University of Leeds",
            },
            {
                id: 10042,
                name: "University of Leicester",
            },
            {
                id: 5256,
                name: "University of Limerick",
            },
            {
                id: 10054,
                name: "University of Lincoln",
            },
            {
                id: 10810,
                name: "University of Louisiana at Monroe",
            },
            {
                id: 10773,
                name: "University of Louisville",
            },
            {
                id: 11391,
                name: "University of Mary",
            },
            {
                id: 10875,
                name: "University of Massachusetts Amherst",
            },
            {
                id: 10882,
                name: "University of Massachusetts Boston",
            },
            {
                id: 10469,
                name: "University of Miami",
            },
            {
                id: 10951,
                name: "University of Michigan",
            },
            {
                id: 10958,
                name: "University of Michigan-Dearborn",
            },
            {
                id: 10997,
                name: "University of Minnesota-Twin Cities",
            },
            {
                id: 11033,
                name: "University of Mississippi",
            },
            {
                id: 11051,
                name: "University of Missouri-Kansas City",
            },
            {
                id: 10217,
                name: "University of Montevallo",
            },
            {
                id: 3872,
                name: "University of Mumbai",
            },
            {
                id: 1081,
                name: "University of National and World Economy",
            },
            {
                id: 11129,
                name: "University of Nevada, Reno",
            },
            {
                id: 10804,
                name: "University of New Orleans",
            },
            {
                id: 2311,
                name: "University of Nicosia",
            },
            {
                id: 11357,
                name: "University of North Carolina School of the Arts",
            },
            {
                id: 11352,
                name: "University of North Carolina at Asheville",
            },
            {
                id: 11336,
                name: "University of North Carolina at Chapel Hill",
            },
            {
                id: 11342,
                name: "University of North Carolina at Charlotte",
            },
            {
                id: 11341,
                name: "University of North Carolina at Greensboro",
            },
            {
                id: 11389,
                name: "University of North Dakota",
            },
            {
                id: 10545,
                name: "University of North Georgia",
            },
            {
                id: 11728,
                name: "University of North Texas",
            },
            {
                id: 10409,
                name: "University of Northern Colorado",
            },
            {
                id: 11023,
                name: "University of Northwestern - St. Paul",
            },
            {
                id: 10671,
                name: "University of Notre Dame",
            },
            {
                id: 10138,
                name: "University of Oxford",
            },
            {
                id: 11515,
                name: "University of Pennsylvania",
            },
            {
                id: 11518,
                name: "University of Pittsburgh",
            },
            {
                id: 10144,
                name: "University of Portsmouth",
            },
            {
                id: 11893,
                name: "University of Puget Sound",
            },
            {
                id: 11848,
                name: "University of Richmond",
            },
            {
                id: 11189,
                name: "University of Rochester",
            },
            {
                id: 8155,
                name: "University of San Carlos",
            },
            {
                id: 10285,
                name: "University of San Francisco",
            },
            {
                id: 10208,
                name: "University of South Alabama",
            },
            {
                id: 11633,
                name: "University of South Carolina",
            },
            {
                id: 10466,
                name: "University of South Florida",
            },
            {
                id: 10268,
                name: "University of Southern California",
            },
            {
                id: 10830,
                name: "University of Southern Maine",
            },
            {
                id: 20355,
                name: "University of St Andrews",
            },
            {
                id: 11776,
                name: "University of St. Thomas",
            },
            {
                id: 11000,
                name: "University of St. Thomas",
            },
            {
                id: 10127,
                name: "University of Stirling",
            },
            {
                id: 10142,
                name: "University of Surrey",
            },
            {
                id: 10141,
                name: "University of Sussex",
            },
            {
                id: 11410,
                name: "University of Toledo",
            },
            {
                id: 11840,
                name: "University of Virginia",
            },
            {
                id: 11882,
                name: "University of Washington",
            },
            {
                id: 10537,
                name: "University of West Georgia",
            },
            {
                id: 11931,
                name: "University of Wisconsin-Eau Claire",
            },
            {
                id: 11936,
                name: "University of Wisconsin-La Crosse",
            },
            {
                id: 11928,
                name: "University of Wisconsin-Madison",
            },
            {
                id: 11929,
                name: "University of Wisconsin-Milwaukee",
            },
            {
                id: 11947,
                name: "University of Wisconsin-Parkside",
            },
            {
                id: 11944,
                name: "University of Wisconsin-River Falls",
            },
            {
                id: 11938,
                name: "University of Wisconsin-Stout",
            },
            {
                id: 10187,
                name: "University of Worcester",
            },
            {
                id: 356,
                name: "University of the Sunshine Coast",
            },
            {
                id: 5323,
                name: "Università Cattolica del Sacro Cuore",
            },
            {
                id: 5320,
                name: "Università degli Studi di Milano",
            },
            {
                id: 5303,
                name: "Università degli Studi di Trieste",
            },
            {
                id: 2918,
                name: "Universität Stuttgart",
            },
            {
                id: 2920,
                name: "Universität Ulm",
            },
            {
                id: 422,
                name: "Universität Wien",
            },
            {
                id: 2990,
                name: "Universität der Bundeswehr München",
            },
            {
                id: 3023,
                name: "Universität der Künste Berlin",
            },
            {
                id: 2706,
                name: "Université Catholique de Lille",
            },
            {
                id: 684,
                name: "Université Libre de Bruxelles",
            },
            {
                id: 8958,
                name: "Univerzitet u Beogradu",
            },
            {
                id: 8339,
                name: "Uniwersytet Ekonomiczny w Krakowie",
            },
            {
                id: 15451,
                name: "Uniwersytet Ekonomiczny w Poznaniu",
            },
            {
                id: 9231,
                name: "Uppsala Universitet",
            },
            {
                id: 11817,
                name: "Utah State University",
            },
            {
                id: 11819,
                name: "Utah Valley University",
            },
            {
                id: 10539,
                name: "Valdosta State University",
            },
            {
                id: 10475,
                name: "Valencia College",
            },
            {
                id: 11677,
                name: "Vanderbilt University",
            },
            {
                id: 11312,
                name: "Vaughn College of Aeronautics and Technology",
            },
            {
                id: 11831,
                name: "Vermont Law School",
            },
            {
                id: 11523,
                name: "Villanova University",
            },
            {
                id: 10696,
                name: "Vincennes University",
            },
            {
                id: 11854,
                name: "Virginia Military Institute",
            },
            {
                id: 11872,
                name: "Virginia Union University",
            },
            {
                id: 11339,
                name: "Wake Forest University",
            },
            {
                id: 10985,
                name: "Walsh College",
            },
            {
                id: 5932,
                name: "Waseda University",
            },
            {
                id: 11883,
                name: "Washington State University",
            },
            {
                id: 18376,
                name: "Washington University in St. Louis",
            },
            {
                id: 11849,
                name: "Washington and Lee University",
            },
            {
                id: 11784,
                name: "Wayland Baptist University",
            },
            {
                id: 10954,
                name: "Wayne State University",
            },
            {
                id: 11818,
                name: "Weber State University",
            },
            {
                id: 18383,
                name: "Webster University",
            },
            {
                id: 10428,
                name: "Wesleyan University",
            },
            {
                id: 11911,
                name: "West Virginia State University",
            },
            {
                id: 11908,
                name: "West Virginia University",
            },
            {
                id: 10418,
                name: "Western Colorado University",
            },
            {
                id: 10774,
                name: "Western Kentucky University",
            },
            {
                id: 10955,
                name: "Western Michigan University",
            },
            {
                id: 11884,
                name: "Western Washington University",
            },
            {
                id: 11822,
                name: "Westminster College",
            },
            {
                id: 10609,
                name: "Wheaton College",
            },
            {
                id: 11537,
                name: "Widener University",
            },
            {
                id: 11455,
                name: "Wilberforce University",
            },
            {
                id: 11548,
                name: "Wilkes University",
            },
            {
                id: 11085,
                name: "William Woods University",
            },
            {
                id: 10881,
                name: "Williams College",
            },
            {
                id: 11014,
                name: "Winona State University",
            },
            {
                id: 11638,
                name: "Winthrop University",
            },
            {
                id: 424,
                name: "Wirtschaftsuniversität Wien",
            },
            {
                id: 11642,
                name: "Wofford College",
            },
            {
                id: 10345,
                name: "Woodbury University",
            },
            {
                id: 10879,
                name: "Worcester Polytechnic Institute",
            },
            {
                id: 11408,
                name: "Wright State University",
            },
            {
                id: 11413,
                name: "Xavier University",
            },
            {
                id: 10425,
                name: "Yale University",
            },
            {
                id: 1712,
                name: "Yangzhou University",
            },
            {
                id: 6368,
                name: "Yonsei University",
            },
            {
                id: 1253,
                name: "York University",
            },
            {
                id: 10573,
                name: "Young Harris College",
            },
            {
                id: 1581,
                name: "Zhengzhou University",
            },
            {
                id: 2700,
                name: "École des Hautes Études Commerciales du Nord",
            },
        ],
        languages: [
            {
                id: 9,
                name: "Afrikaans",
            },
            {
                id: 14,
                name: "Albanian",
            },
            {
                id: 20,
                name: "Amharic",
            },
            {
                id: 23,
                name: "Arabic",
            },
            {
                id: 58,
                name: "Bengali",
            },
            {
                id: 70,
                name: "Bokmål, Norwegian",
            },
            {
                id: 71,
                name: "Bosnian",
            },
            {
                id: 80,
                name: "Catalan",
            },
            {
                id: 95,
                name: "Chinese",
            },
            {
                id: 113,
                name: "Creoles and pidgins",
            },
            {
                id: 121,
                name: "Czech",
            },
            {
                id: 136,
                name: "Dutch",
            },
            {
                id: 146,
                name: "English",
            },
            {
                id: 159,
                name: "Finnish",
            },
            {
                id: 163,
                name: "French",
            },
            {
                id: 177,
                name: "German",
            },
            {
                id: 189,
                name: "Greek, Modern (1453-)",
            },
            {
                id: 191,
                name: "Guarani",
            },
            {
                id: 192,
                name: "Gujarati",
            },
            {
                id: 196,
                name: "Haitian Creole",
            },
            {
                id: 199,
                name: "Hebrew",
            },
            {
                id: 203,
                name: "Hindi",
            },
            {
                id: 207,
                name: "Hungarian",
            },
            {
                id: 212,
                name: "Igbo",
            },
            {
                id: 225,
                name: "Iranian languages",
            },
            {
                id: 230,
                name: "Italian",
            },
            {
                id: 231,
                name: "Japanese",
            },
            {
                id: 242,
                name: "Kannada",
            },
            {
                id: 266,
                name: "Korean",
            },
            {
                id: 281,
                name: "Lao",
            },
            {
                id: 282,
                name: "Latin",
            },
            {
                id: 312,
                name: "Malay",
            },
            {
                id: 313,
                name: "Malayalam",
            },
            {
                id: 325,
                name: "Marathi",
            },
            {
                id: 358,
                name: "Nepali",
            },
            {
                id: 372,
                name: "Norwegian",
            },
            {
                id: 405,
                name: "Panjabi",
            },
            {
                id: 410,
                name: "Persian",
            },
            {
                id: 416,
                name: "Polish",
            },
            {
                id: 417,
                name: "Portuguese",
            },
            {
                id: 428,
                name: "Romanian",
            },
            {
                id: 432,
                name: "Russian",
            },
            {
                id: 450,
                name: "Serbian",
            },
            {
                id: 467,
                name: "Slovak",
            },
            {
                id: 470,
                name: "Somali",
            },
            {
                id: 480,
                name: "Spanish",
            },
            {
                id: 489,
                name: "Swedish",
            },
            {
                id: 492,
                name: "Tagalog",
            },
            {
                id: 495,
                name: "Tajik",
            },
            {
                id: 497,
                name: "Tamil",
            },
            {
                id: 499,
                name: "Telugu",
            },
            {
                id: 502,
                name: "Thai",
            },
            {
                id: 512,
                name: "Tonga (Nyasa)",
            },
            {
                id: 513,
                name: "Tonga (Tonga Islands)",
            },
            {
                id: 519,
                name: "Turkish",
            },
            {
                id: 528,
                name: "Ukrainian",
            },
            {
                id: 533,
                name: "Urdu",
            },
            {
                id: 539,
                name: "Vietnamese",
            },
            {
                id: 551,
                name: "Wolof",
            },
        ],
        cities: [
            {
                id: 1288001666,
                name: "Accra",
            },
            {
                id: 1840013688,
                name: "Acworth",
            },
            {
                id: 1840003729,
                name: "Addison",
            },
            {
                id: 1840003226,
                name: "Adrian",
            },
            {
                id: 1840019221,
                name: "Agoura Hills",
            },
            {
                id: 1840000417,
                name: "Albany",
            },
            {
                id: 1840026377,
                name: "Albrightsville",
            },
            {
                id: 1840019321,
                name: "Aliso Viejo",
            },
            {
                id: 1840004256,
                name: "Allen",
            },
            {
                id: 1840003967,
                name: "Allen Park",
            },
            {
                id: 1840001044,
                name: "Allentown",
            },
            {
                id: 1840013659,
                name: "Alpharetta",
            },
            {
                id: 1840018366,
                name: "Anacortes",
            },
            {
                id: 1840007066,
                name: "Ankeny",
            },
            {
                id: 1840003172,
                name: "Ann Arbor",
            },
            {
                id: 1840002413,
                name: "Arlington",
            },
            {
                id: 1840013411,
                name: "Asheville",
            },
            {
                id: 1840002274,
                name: "Astoria",
            },
            {
                id: 1840000510,
                name: "Athens",
            },
            {
                id: 1840004026,
                name: "Atlanta",
            },
            {
                id: 1840005335,
                name: "Atlantic Beach",
            },
            {
                id: 1840015535,
                name: "Aubrey",
            },
            {
                id: 1840000319,
                name: "Auburn",
            },
            {
                id: 1840000302,
                name: "Augusta",
            },
            {
                id: 1840000806,
                name: "Aurora",
            },
            {
                id: 1840013689,
                name: "Austell",
            },
            {
                id: 1840003306,
                name: "Austin",
            },
            {
                id: 1840013702,
                name: "Avondale Estates",
            },
            {
                id: 1840019423,
                name: "Azle",
            },
            {
                id: 1840001592,
                name: "Baltimore",
            },
            {
                id: 1356410365,
                name: "Bangalore",
            },
            {
                id: 1840000385,
                name: "Batavia",
            },
            {
                id: 1840013941,
                name: "Baton Rouge",
            },
            {
                id: 1840013818,
                name: "Beaufort",
            },
            {
                id: 1840000723,
                name: "Belleville",
            },
            {
                id: 1840000308,
                name: "Bellevue",
            },
            {
                id: 1840005232,
                name: "Bellmore",
            },
            {
                id: 1276451290,
                name: "Berlin",
            },
            {
                id: 1840007451,
                name: "Beverly Hills",
            },
            {
                id: 1840019119,
                name: "Big Bear Lake",
            },
            {
                id: 1840001926,
                name: "Bismarck",
            },
            {
                id: 1840000415,
                name: "Bloomfield",
            },
            {
                id: 1840002439,
                name: "Bloomfield Hills",
            },
            {
                id: 1840002970,
                name: "Bloomington",
            },
            {
                id: 1840027142,
                name: "Boise",
            },
            {
                id: 1840018454,
                name: "Bonney Lake",
            },
            {
                id: 1840013790,
                name: "Bossier City",
            },
            {
                id: 1840000455,
                name: "Boston",
            },
            {
                id: 1840017449,
                name: "Boulder",
            },
            {
                id: 1840018737,
                name: "Bountiful",
            },
            {
                id: 1840018410,
                name: "Bremerton",
            },
            {
                id: 1840081606,
                name: "Brick",
            },
            {
                id: 1840003099,
                name: "Brighton",
            },
            {
                id: 1840033999,
                name: "Bronx",
            },
            {
                id: 1840004949,
                name: "Bronxville",
            },
            {
                id: 1840000592,
                name: "Brooklyn",
            },
            {
                id: 1840018781,
                name: "Broomfield",
            },
            {
                id: 1840007022,
                name: "Burbank",
            },
            {
                id: 1840000046,
                name: "Burlington",
            },
            {
                id: 1840006115,
                name: "Burnsville",
            },
            {
                id: 1076562452,
                name: "Caatiba",
            },
            {
                id: 1840003642,
                name: "California",
            },
            {
                id: 1840092506,
                name: "Camp Washington",
            },
            {
                id: 1840000512,
                name: "Canton",
            },
            {
                id: 1840010473,
                name: "Carleton",
            },
            {
                id: 1840004806,
                name: "Carrollton",
            },
            {
                id: 1840000201,
                name: "Carson",
            },
            {
                id: 1840006758,
                name: "Carver",
            },
            {
                id: 1840010109,
                name: "Cary",
            },
            {
                id: 1840001006,
                name: "Centerville",
            },
            {
                id: 1840005028,
                name: "Central Islip",
            },
            {
                id: 1156154785,
                name: "Changning",
            },
            {
                id: 1840016152,
                name: "Chapel Hill",
            },
            {
                id: 1840006196,
                name: "Charleston",
            },
            {
                id: 1840003107,
                name: "Charlotte",
            },
            {
                id: 1840006760,
                name: "Chaska",
            },
            {
                id: 1840000282,
                name: "Chelsea",
            },
            {
                id: 1840000690,
                name: "Chester",
            },
            {
                id: 1840031323,
                name: "Chevy Chase",
            },
            {
                id: 1840000494,
                name: "Chicago",
            },
            {
                id: 1840019115,
                name: "Chino Hills",
            },
            {
                id: 1840057308,
                name: "Church",
            },
            {
                id: 1840003814,
                name: "Cincinnati",
            },
            {
                id: 1840014776,
                name: "Clarkston",
            },
            {
                id: 1840005570,
                name: "Claymont",
            },
            {
                id: 1840006704,
                name: "Clearwater",
            },
            {
                id: 1840006887,
                name: "Clermont",
            },
            {
                id: 1840018402,
                name: "Coeur d'Alene",
            },
            {
                id: 1840074067,
                name: "College Hill Station",
            },
            {
                id: 1840005971,
                name: "College Park",
            },
            {
                id: 1840019430,
                name: "Colleyville",
            },
            {
                id: 1840010999,
                name: "Columbiaville",
            },
            {
                id: 1840001715,
                name: "Columbus",
            },
            {
                id: 1840011429,
                name: "Compton",
            },
            {
                id: 1840067102,
                name: "Connecticut Avenue Estates",
            },
            {
                id: 1840019586,
                name: "Conroe",
            },
            {
                id: 1840001015,
                name: "Conway",
            },
            {
                id: 1840014806,
                name: "Conyers",
            },
            {
                id: 1840019439,
                name: "Coppell",
            },
            {
                id: 1840000264,
                name: "Corona",
            },
            {
                id: 1840019354,
                name: "Coronado",
            },
            {
                id: 1840002910,
                name: "Cottage Grove",
            },
            {
                id: 1840001688,
                name: "Covington",
            },
            {
                id: 1840007091,
                name: "Crown Point",
            },
            {
                id: 1840007103,
                name: "Cumming",
            },
            {
                id: 1840014743,
                name: "Dacula",
            },
            {
                id: 1840014672,
                name: "Dahlonega",
            },
            {
                id: 1840000364,
                name: "Dallas",
            },
            {
                id: 1840003379,
                name: "Dalton",
            },
            {
                id: 1840000854,
                name: "Danville",
            },
            {
                id: 1840000885,
                name: "Dayton",
            },
            {
                id: 1840015068,
                name: "Daytona Beach",
            },
            {
                id: 1840003969,
                name: "Dearborn",
            },
            {
                id: 1840003970,
                name: "Dearborn Heights",
            },
            {
                id: 1840001403,
                name: "Decatur",
            },
            {
                id: 1840015123,
                name: "Delray Beach",
            },
            {
                id: 1840005932,
                name: "Denton",
            },
            {
                id: 1840003714,
                name: "Denver",
            },
            {
                id: 1840003971,
                name: "Detroit",
            },
            {
                id: 1840004129,
                name: "Dexter",
            },
            {
                id: 1840014804,
                name: "Douglasville",
            },
            {
                id: 1840003340,
                name: "Downey",
            },
            {
                id: 1840000330,
                name: "Draper",
            },
            {
                id: 1840001321,
                name: "Dublin",
            },
            {
                id: 1840007685,
                name: "Duluth",
            },
            {
                id: 1840003541,
                name: "Dumont",
            },
            {
                id: 1840018427,
                name: "Duvall",
            },
            {
                id: 1840001982,
                name: "Eagle River",
            },
            {
                id: 1840089730,
                name: "East Fayetteville",
            },
            {
                id: 1840003698,
                name: "East Greenville",
            },
            {
                id: 1840005236,
                name: "East Meadow",
            },
            {
                id: 1840002043,
                name: "Eden",
            },
            {
                id: 1840007821,
                name: "Eden Prairie",
            },
            {
                id: 1840012021,
                name: "Edison",
            },
            {
                id: 1840016991,
                name: "Edwards",
            },
            {
                id: 1840150306,
                name: "El Adobe",
            },
            {
                id: 1840005226,
                name: "Elmont",
            },
            {
                id: 1840003717,
                name: "Ephrata",
            },
            {
                id: 1840013168,
                name: "Erlanger",
            },
            {
                id: 1840007823,
                name: "Excelsior",
            },
            {
                id: 1840023463,
                name: "Fairbanks",
            },
            {
                id: 1840002639,
                name: "Fairburn",
            },
            {
                id: 1840000865,
                name: "Fairview",
            },
            {
                id: 1356078517,
                name: "Faridābād",
            },
            {
                id: 1840003686,
                name: "Farmingdale",
            },
            {
                id: 1840002441,
                name: "Farmington",
            },
            {
                id: 1840004320,
                name: "Fayetteville",
            },
            {
                id: 1840019826,
                name: "Federal Way",
            },
            {
                id: 1840002948,
                name: "Fenton",
            },
            {
                id: 1840004748,
                name: "Fishkill",
            },
            {
                id: 1840003973,
                name: "Flat Rock",
            },
            {
                id: 1840002949,
                name: "Flint",
            },
            {
                id: 1840001660,
                name: "Florence",
            },
            {
                id: 1840004867,
                name: "Florida",
            },
            {
                id: 1840121450,
                name: "Flower",
            },
            {
                id: 1840013611,
                name: "Flowery Branch",
            },
            {
                id: 1840003799,
                name: "Folsom",
            },
            {
                id: 1840004636,
                name: "Forest Lake",
            },
            {
                id: 1840014236,
                name: "Fort Lauderdale",
            },
            {
                id: 1840016479,
                name: "Fort Mill",
            },
            {
                id: 1840014226,
                name: "Fort Myers",
            },
            {
                id: 1840014178,
                name: "Fort Pierce",
            },
            {
                id: 1840013923,
                name: "Fort Walton Beach",
            },
            {
                id: 1840020696,
                name: "Fort Worth",
            },
            {
                id: 1840002748,
                name: "Franklin",
            },
            {
                id: 1840001198,
                name: "Franklin Park",
            },
            {
                id: 1840020654,
                name: "Frisco",
            },
            {
                id: 1840004468,
                name: "Gainesville",
            },
            {
                id: 1840008311,
                name: "Garden Grove",
            },
            {
                id: 1840056116,
                name: "Georgia",
            },
            {
                id: 1840019855,
                name: "Gig Harbor",
            },
            {
                id: 1840003037,
                name: "Glendale",
            },
            {
                id: 1840020749,
                name: "Granbury",
            },
            {
                id: 1840020697,
                name: "Grapevine",
            },
            {
                id: 1840000719,
                name: "Greensboro",
            },
            {
                id: 1840000659,
                name: "Greenville",
            },
            {
                id: 1840010823,
                name: "Griffin",
            },
            {
                id: 1840133971,
                name: "Grosse Ile",
            },
            {
                id: 1840003976,
                name: "Grosse Pointe",
            },
            {
                id: 1840003979,
                name: "Grosse Pointe Woods",
            },
            {
                id: 1840013748,
                name: "Grovetown",
            },
            {
                id: 1840013920,
                name: "Gulf Breeze",
            },
            {
                id: 1356406239,
                name: "Gūrgaon",
            },
            {
                id: 1840043425,
                name: "Hanscom AFB",
            },
            {
                id: 1840001115,
                name: "Hastings",
            },
            {
                id: 1840029479,
                name: "Hauula",
            },
            {
                id: 1840029572,
                name: "Hawaiian Acres",
            },
            {
                id: 1840003513,
                name: "Hawthorne",
            },
            {
                id: 1840018034,
                name: "Heber",
            },
            {
                id: 1840000198,
                name: "Hebron",
            },
            {
                id: 1840005293,
                name: "Hempstead",
            },
            {
                id: 1840005936,
                name: "Henderson",
            },
            {
                id: 1840020155,
                name: "Herriman",
            },
            {
                id: 1124001665,
                name: "Hilton",
            },
            {
                id: 1840004281,
                name: "Hilton",
            },
            {
                id: 1840003599,
                name: "Hoboken",
            },
            {
                id: 1840053479,
                name: "Holliston",
            },
            {
                id: 1840015144,
                name: "Hollywood",
            },
            {
                id: 1344982653,
                name: "Hong Kong",
            },
            {
                id: 1840006749,
                name: "Hopkins",
            },
            {
                id: 1840003657,
                name: "Houston",
            },
            {
                id: 1840001778,
                name: "Howell",
            },
            {
                id: 1840020578,
                name: "Huntington Beach",
            },
            {
                id: 1840005081,
                name: "Huntington Station",
            },
            {
                id: 1840005061,
                name: "Huntsville",
            },
            {
                id: 1840007577,
                name: "Hurst",
            },
            {
                id: 1840030084,
                name: "Indianapolis",
            },
            {
                id: 1840011457,
                name: "Inglewood",
            },
            {
                id: 1840007858,
                name: "Inver Grove Heights",
            },
            {
                id: 1840016723,
                name: "Irmo",
            },
            {
                id: 1840014344,
                name: "Irvine",
            },
            {
                id: 1840012637,
                name: "Irving",
            },
            {
                id: 1840018422,
                name: "Issaquah",
            },
            {
                id: 1840004335,
                name: "Jacksonville",
            },
            {
                id: 1840015032,
                name: "Jacksonville Beach",
            },
            {
                id: 1840014787,
                name: "Jersey",
            },
            {
                id: 1840008340,
                name: "Jonesboro",
            },
            {
                id: 1840004321,
                name: "Jordan",
            },
            {
                id: 1840017255,
                name: "Jupiter",
            },
            {
                id: 1840012501,
                name: "Kansas",
            },
            {
                id: 1840018738,
                name: "Kaysville",
            },
            {
                id: 1840006324,
                name: "Keller",
            },
            {
                id: 1840004386,
                name: "Kenmore",
            },
            {
                id: 1840014756,
                name: "Kennesaw",
            },
            {
                id: 1840007751,
                name: "Kent",
            },
            {
                id: 1840040003,
                name: "Kentucky",
            },
            {
                id: 1840014253,
                name: "Key Largo",
            },
            {
                id: 1840029510,
                name: "Koloa",
            },
            {
                id: 1840019866,
                name: "Lacey",
            },
            {
                id: 1840011054,
                name: "Lake Orion",
            },
            {
                id: 1840007859,
                name: "Lakeville",
            },
            {
                id: 1840002969,
                name: "Lancaster",
            },
            {
                id: 1840020364,
                name: "Las Vegas",
            },
            {
                id: 1840074639,
                name: "Latonia",
            },
            {
                id: 1840008523,
                name: "Lawrenceburg",
            },
            {
                id: 1840003299,
                name: "Lawrenceville",
            },
            {
                id: 1840015162,
                name: "Layton",
            },
            {
                id: 1840001040,
                name: "Lebanon",
            },
            {
                id: 1840000320,
                name: "Lewiston",
            },
            {
                id: 1840007924,
                name: "Lewisville",
            },
            {
                id: 1840001689,
                name: "Lexington",
            },
            {
                id: 1840015613,
                name: "Lilburn",
            },
            {
                id: 1840000158,
                name: "Lincoln",
            },
            {
                id: 1840013740,
                name: "Lithia Springs",
            },
            {
                id: 1840015644,
                name: "Lithonia",
            },
            {
                id: 1840003985,
                name: "Livonia",
            },
            {
                id: 1840015705,
                name: "Locust Grove",
            },
            {
                id: 1840006336,
                name: "Logan",
            },
            {
                id: 1840001454,
                name: "Loganville",
            },
            {
                id: 1840000988,
                name: "Long Beach",
            },
            {
                id: 1840053448,
                name: "Longmeadow",
            },
            {
                id: 1840020491,
                name: "Los Angeles",
            },
            {
                id: 1840000964,
                name: "Louisville",
            },
            {
                id: 1756503816,
                name: "Lugano",
            },
            {
                id: 1840015922,
                name: "Lynn Haven",
            },
            {
                id: 1840013690,
                name: "Mableton",
            },
            {
                id: 1840009411,
                name: "Macomb",
            },
            {
                id: 1840009485,
                name: "Macon",
            },
            {
                id: 1840000538,
                name: "Madison",
            },
            {
                id: 1724616994,
                name: "Madrid",
            },
            {
                id: 1840000430,
                name: "Malden",
            },
            {
                id: 1840005273,
                name: "Manhasset",
            },
            {
                id: 1840008844,
                name: "Manhattan Beach",
            },
            {
                id: 1608254376,
                name: "Manila",
            },
            {
                id: 1840008975,
                name: "Mankato",
            },
            {
                id: 1840019828,
                name: "Maple Valley",
            },
            {
                id: 1840001545,
                name: "Marietta",
            },
            {
                id: 1840017920,
                name: "Marina del Rey",
            },
            {
                id: 1840000220,
                name: "Marion",
            },
            {
                id: 1840008349,
                name: "Maryville",
            },
            {
                id: 1840003104,
                name: "Mason",
            },
            {
                id: 1840015706,
                name: "McDonough",
            },
            {
                id: 1840000431,
                name: "Medford",
            },
            {
                id: 1840003986,
                name: "Melvindale",
            },
            {
                id: 1840002422,
                name: "Memphis",
            },
            {
                id: 1840000585,
                name: "Middletown",
            },
            {
                id: 1840012091,
                name: "Midvale",
            },
            {
                id: 1840001041,
                name: "Milford",
            },
            {
                id: 1840000029,
                name: "Milton",
            },
            {
                id: 1840003046,
                name: "Milwaukee",
            },
            {
                id: 1840003824,
                name: "Minneapolis",
            },
            {
                id: 1840007831,
                name: "Minnetonka",
            },
            {
                id: 1840000053,
                name: "Minot",
            },
            {
                id: 1840122841,
                name: "Missouri Branch",
            },
            {
                id: 1840006009,
                name: "Mobile",
            },
            {
                id: 1840004978,
                name: "Montebello",
            },
            {
                id: 1124586170,
                name: "Montréal",
            },
            {
                id: 1840019685,
                name: "Moore",
            },
            {
                id: 1840007872,
                name: "Morgan",
            },
            {
                id: 1840001326,
                name: "Morrisville",
            },
            {
                id: 1840000888,
                name: "Mount Pleasant",
            },
            {
                id: 1840000338,
                name: "Mount Vernon",
            },
            {
                id: 1356226629,
                name: "Mumbai",
            },
            {
                id: 1840050164,
                name: "Mōʻiliʻili",
            },
            {
                id: 1840008651,
                name: "Nashville",
            },
            {
                id: 1840014591,
                name: "New Bern",
            },
            {
                id: 1840001645,
                name: "New Boston",
            },
            {
                id: 1356215164,
                name: "New Delhi",
            },
            {
                id: 1840004850,
                name: "New Haven",
            },
            {
                id: 1840001839,
                name: "New Orleans",
            },
            {
                id: 1840005704,
                name: "New Windsor",
            },
            {
                id: 1840034016,
                name: "New York",
            },
            {
                id: 1840014833,
                name: "Newnan",
            },
            {
                id: 1840002270,
                name: "Newport",
            },
            {
                id: 1356149101,
                name: "None",
            },
            {
                id: 1840007767,
                name: "Norcross",
            },
            {
                id: 1840003869,
                name: "Norfolk",
            },
            {
                id: 1840029449,
                name: "North Druid Hills",
            },
            {
                id: 1840069459,
                name: "North Easton",
            },
            {
                id: 1840014632,
                name: "North Little Rock",
            },
            {
                id: 1840015120,
                name: "North Port",
            },
            {
                id: 1840007843,
                name: "North St. Paul",
            },
            {
                id: 1840000799,
                name: "Northfield",
            },
            {
                id: 1840000288,
                name: "Northville",
            },
            {
                id: 1840002423,
                name: "Novi",
            },
            {
                id: 1840011503,
                name: "Oak Harbor",
            },
            {
                id: 1840005246,
                name: "Oceanside",
            },
            {
                id: 1840015098,
                name: "Ocoee",
            },
            {
                id: 1840008120,
                name: "Ogden",
            },
            {
                id: 1840061984,
                name: "Oklahoma",
            },
            {
                id: 1840034375,
                name: "Olde West Chester",
            },
            {
                id: 1840019865,
                name: "Olympia",
            },
            {
                id: 1840011374,
                name: "Onsted",
            },
            {
                id: 1840003405,
                name: "Orange",
            },
            {
                id: 1840019858,
                name: "Orting",
            },
            {
                id: 1840018308,
                name: "Palm Springs",
            },
            {
                id: 1840140102,
                name: "Palos Verdes Peninsula",
            },
            {
                id: 1250015082,
                name: "Paris",
            },
            {
                id: 1840009701,
                name: "Park",
            },
            {
                id: 1840000917,
                name: "Park Ridge",
            },
            {
                id: 1840028462,
                name: "Parlin",
            },
            {
                id: 1840005914,
                name: "Pasadena",
            },
            {
                id: 1840089287,
                name: "Peachtree",
            },
            {
                id: 1840015005,
                name: "Pensacola",
            },
            {
                id: 1840020244,
                name: "Petaluma",
            },
            {
                id: 1840000673,
                name: "Philadelphia",
            },
            {
                id: 1840004193,
                name: "Phoenix",
            },
            {
                id: 1840001254,
                name: "Pittsburgh",
            },
            {
                id: 1840001090,
                name: "Plainfield",
            },
            {
                id: 1840009258,
                name: "Plano",
            },
            {
                id: 1840009393,
                name: "Pleasanton",
            },
            {
                id: 1840000767,
                name: "Plymouth",
            },
            {
                id: 1840004983,
                name: "Pomona",
            },
            {
                id: 1840015143,
                name: "Pompano Beach",
            },
            {
                id: 1840028957,
                name: "Ponte Vedra Beach",
            },
            {
                id: 1840014755,
                name: "Powder Springs",
            },
            {
                id: 1840008956,
                name: "Prior Lake",
            },
            {
                id: 1840003289,
                name: "Providence",
            },
            {
                id: 1840001807,
                name: "Provo",
            },
            {
                id: 1840021944,
                name: "Queen Creek",
            },
            {
                id: 1840034002,
                name: "Queens",
            },
            {
                id: 1218441993,
                name: "Quito",
            },
            {
                id: 1840012975,
                name: "Raleigh",
            },
            {
                id: 1840020508,
                name: "Rancho Palos Verdes",
            },
            {
                id: 1840008959,
                name: "Red Wing",
            },
            {
                id: 1840020509,
                name: "Redondo Beach",
            },
            {
                id: 1840020121,
                name: "Reno",
            },
            {
                id: 1840019827,
                name: "Renton",
            },
            {
                id: 1840000457,
                name: "Revere",
            },
            {
                id: 1840014913,
                name: "Richmond Hill",
            },
            {
                id: 1840011284,
                name: "River Forest",
            },
            {
                id: 1840000114,
                name: "Riverdale",
            },
            {
                id: 1840000848,
                name: "Riverside",
            },
            {
                id: 1840003961,
                name: "Riverview",
            },
            {
                id: 1840003858,
                name: "Roanoke",
            },
            {
                id: 1840020717,
                name: "Rockwall",
            },
            {
                id: 1840001930,
                name: "Rogers",
            },
            {
                id: 1840000349,
                name: "Rome",
            },
            {
                id: 1840003963,
                name: "Romulus",
            },
            {
                id: 1840006054,
                name: "Rosemount",
            },
            {
                id: 1840002541,
                name: "Roswell",
            },
            {
                id: 1840002430,
                name: "Royal Oak",
            },
            {
                id: 1840000410,
                name: "Salem",
            },
            {
                id: 1840082816,
                name: "Salt Lake",
            },
            {
                id: 1840015969,
                name: "San Antonio",
            },
            {
                id: 1840037414,
                name: "San Buenaventura",
            },
            {
                id: 1840021990,
                name: "San Diego",
            },
            {
                id: 1840021543,
                name: "San Francisco",
            },
            {
                id: 1840021991,
                name: "San Marcos",
            },
            {
                id: 1840021863,
                name: "San Marino",
            },
            {
                id: 1840021829,
                name: "Santa Barbara",
            },
            {
                id: 1840019103,
                name: "Santa Cruz",
            },
            {
                id: 1840019736,
                name: "Santa Monica",
            },
            {
                id: 1840053670,
                name: "Saugus",
            },
            {
                id: 1840008957,
                name: "Savage",
            },
            {
                id: 1840009477,
                name: "Savannah",
            },
            {
                id: 1840001157,
                name: "Scottdale",
            },
            {
                id: 1840021940,
                name: "Scottsdale",
            },
            {
                id: 1840022197,
                name: "Seabrook",
            },
            {
                id: 1840021117,
                name: "Seattle",
            },
            {
                id: 1840001262,
                name: "Sharpsburg",
            },
            {
                id: 1840004840,
                name: "Shelton",
            },
            {
                id: 1840001864,
                name: "Sherwood",
            },
            {
                id: 1840005845,
                name: "Silver Spring",
            },
            {
                id: 1702341327,
                name: "Singapore",
            },
            {
                id: 1840090595,
                name: "Sioux",
            },
            {
                id: 1840041703,
                name: "Smithtown",
            },
            {
                id: 1840004512,
                name: "Smyrna",
            },
            {
                id: 1840015614,
                name: "Snellville",
            },
            {
                id: 1840021078,
                name: "Snohomish",
            },
            {
                id: 1840021119,
                name: "Snoqualmie",
            },
            {
                id: 1840015658,
                name: "Social Circle",
            },
            {
                id: 1840001056,
                name: "Somerville",
            },
            {
                id: 1840021385,
                name: "South Jordan",
            },
            {
                id: 1840008948,
                name: "South St. Paul",
            },
            {
                id: 1840002431,
                name: "Southfield",
            },
            {
                id: 1840022048,
                name: "Southlake",
            },
            {
                id: 1840018451,
                name: "Spanaway",
            },
            {
                id: 1840021398,
                name: "Spanish Fork",
            },
            {
                id: 1840000466,
                name: "Springfield",
            },
            {
                id: 1840004391,
                name: "Springville",
            },
            {
                id: 1840002267,
                name: "St. Albans",
            },
            {
                id: 1840002611,
                name: "St. Cloud",
            },
            {
                id: 1840001651,
                name: "St. Louis",
            },
            {
                id: 1840006484,
                name: "St. Paul",
            },
            {
                id: 1840003461,
                name: "St. Petersburg",
            },
            {
                id: 1840013874,
                name: "St. Simons",
            },
            {
                id: 1840123202,
                name: "Staten",
            },
            {
                id: 1840015801,
                name: "Statesboro",
            },
            {
                id: 1840000816,
                name: "Stillwater",
            },
            {
                id: 1840002248,
                name: "Stockbridge",
            },
            {
                id: 1840015645,
                name: "Stone Mountain",
            },
            {
                id: 1840003873,
                name: "Suffolk",
            },
            {
                id: 1840015616,
                name: "Suwanee",
            },
            {
                id: 1036074917,
                name: "Sydney",
            },
            {
                id: 1840000378,
                name: "Syracuse",
            },
            {
                id: 1840021129,
                name: "Tacoma",
            },
            {
                id: 1840015913,
                name: "Tallahassee",
            },
            {
                id: 1840003228,
                name: "Tecumseh",
            },
            {
                id: 1840021942,
                name: "Tempe",
            },
            {
                id: 1840015708,
                name: "Texarkana",
            },
            {
                id: 1840022005,
                name: "The Colony",
            },
            {
                id: 1356681152,
                name: "Thāne",
            },
            {
                id: 1840000572,
                name: "Toledo",
            },
            {
                id: 1840022203,
                name: "Tomball",
            },
            {
                id: 1840021356,
                name: "Tooele",
            },
            {
                id: 1840021873,
                name: "Torrance",
            },
            {
                id: 1840001383,
                name: "Trenton",
            },
            {
                id: 1840000521,
                name: "Troy",
            },
            {
                id: 1840013701,
                name: "Tucker",
            },
            {
                id: 1840022101,
                name: "Tucson",
            },
            {
                id: 1840021533,
                name: "Turlock",
            },
            {
                id: 1840005563,
                name: "Tuscaloosa",
            },
            {
                id: 1840006386,
                name: "Union",
            },
            {
                id: 1840052612,
                name: "Utah",
            },
            {
                id: 1840002417,
                name: "Utica",
            },
            {
                id: 1840021498,
                name: "Vacaville",
            },
            {
                id: 1840011638,
                name: "Valparaiso",
            },
            {
                id: 1840021189,
                name: "Vancouver",
            },
            {
                id: 1840003865,
                name: "Victoria",
            },
            {
                id: 1840002150,
                name: "Vienna",
            },
            {
                id: 1840015679,
                name: "Villa Rica",
            },
            {
                id: 1840003871,
                name: "Virginia Beach",
            },
            {
                id: 1840010008,
                name: "Waconia",
            },
            {
                id: 1840000652,
                name: "Warren",
            },
            {
                id: 1840001889,
                name: "Warwick",
            },
            {
                id: 1840000724,
                name: "Washington",
            },
            {
                id: 1840000305,
                name: "Waterville",
            },
            {
                id: 1840022083,
                name: "Waxahachie",
            },
            {
                id: 1840010000,
                name: "Wayzata",
            },
            {
                id: 1840038051,
                name: "West Bloomfield",
            },
            {
                id: 1840001436,
                name: "West Chester",
            },
            {
                id: 1840004852,
                name: "West Haven",
            },
            {
                id: 1840021388,
                name: "West Jordan",
            },
            {
                id: 1840015993,
                name: "West Palm Beach",
            },
            {
                id: 1840011333,
                name: "Western Springs",
            },
            {
                id: 1840000463,
                name: "Westfield",
            },
            {
                id: 1840037458,
                name: "Westlake Village",
            },
            {
                id: 1840005930,
                name: "Weston",
            },
            {
                id: 1840006923,
                name: "Whitmore Lake",
            },
            {
                id: 1840001686,
                name: "Wichita",
            },
            {
                id: 1840005579,
                name: "Wilmington",
            },
            {
                id: 1840017238,
                name: "Windermere",
            },
            {
                id: 1840015966,
                name: "Winter Park",
            },
            {
                id: 1840010013,
                name: "Winthrop",
            },
            {
                id: 1840000407,
                name: "Wixom",
            },
            {
                id: 1124375825,
                name: "Woodbridge",
            },
            {
                id: 1840004161,
                name: "Woodstock",
            },
            {
                id: 1840006444,
                name: "Yorktown",
            },
            {
                id: 1840001166,
                name: "Youngstown",
            },
            {
                id: 1840000459,
                name: "Ypsilanti",
            },
        ],
    },
};

export const temporaryEducationSingleViewData = {
    section1: {
        topUniversitiesAttended: {
            categories: [
                "Georgia Institute of Technology",
                "The University of Georgia",
                "Embry-Riddle Aeronautical University",
                "Georgia State University",
                "United States Air Force Academy",
                "Kennesaw State University",
                "Purdue University",
                "Georgia Southern University",
                "Florida State University",
                "University of Central Florida",
                "University of Florida",
                "Western Michigan University",
                "Emory University",
                "University of Cincinnati",
                "Metropolitan State University",
                "University of St. Thomas",
                "Auburn University",
                "University of Washington",
                "University of West Georgia",
                "Penn State University",
                "Arizona State University",
                "University of Wisconsin-Madison",
                "United States Naval Academy",
                "Utah State University",
                "University of Michigan",
                "Vaughn College of Aeronautics and Technology",
                "Saint Mary's University of Minnesota",
                "Valdosta State University",
                "Clayton State University",
                "Eastern Michigan University",
            ],
            stack: {
                series: [
                    {
                        data: [
                            2, 1.42, 1.36, 1.32, 1.03, 0.87, 0.84, 0.61, 0.55, 0.55, 0.52, 0.45, 0.42, 0.39, 0.36, 0.32,
                            0.32, 0.32, 0.32, 0.32, 0.32, 0.29, 0.29, 0.29, 0.26, 0.26, 0.26, 0.23, 0.23, 0.23,
                        ],
                    },
                ],
            },
        },
        jobTitles: [
            {
                categories: [
                    "Kennesaw State University",
                    "Georgia State University",
                    "University of Central Florida",
                    "The University of Georgia",
                ],
                stack: { series: [{ data: [0.48, 0.39, 0.29, 0.36] }], name: "Transportation Services" },
            },
            {
                categories: [
                    "United States Air Force Academy",
                    "Embry-Riddle Aeronautical University",
                    "Purdue University",
                    "Western Michigan University",
                ],
                stack: { series: [{ data: [0.58, 0.39, 0.36, 0.19] }], name: "Other Occupations" },
            },
            {
                categories: [
                    "University of Mumbai",
                    "Metropolitan State University",
                    "Central Washington University",
                    "Brock University",
                ],
                stack: { series: [{ data: [0.03, 0.13, 0.06, 0.03] }], name: "Customer Services" },
            },
            {
                categories: [
                    "Georgia State University",
                    "Embry-Riddle Aeronautical University",
                    "Bowling Green State University",
                    "Georgia Institute of Technology",
                ],
                stack: { series: [{ data: [0.13, 0.16, 0.06, 0.16] }], name: "Middle Management" },
            },
            {
                categories: [
                    "The University of Georgia",
                    "Emory University",
                    "Georgia State University",
                    "Kennesaw State University",
                ],
                stack: { series: [{ data: [0.39, 0.1, 0.13, 0.13] }], name: "Marketing" },
            },
            {
                categories: [
                    "Penn State University",
                    "Michigan State University",
                    "Georgia State University",
                    "Lewis University",
                ],
                stack: { series: [{ data: [0.03, 0.1, 0.03, 0.03] }], name: "Sales" },
            },
            {
                categories: [
                    "Northeastern University",
                    "Clayton State University",
                    "Harvard University",
                    "Vaughn College of Aeronautics and Technology",
                ],
                stack: { series: [{ data: [0.03, 0.03, 0.06, 0.06] }], name: "Operations" },
            },
            {
                categories: [
                    "Georgia Institute of Technology",
                    "The University of Georgia",
                    "Northeastern University",
                    "Georgia State University",
                ],
                stack: { series: [{ data: [0.16, 0.1, 0.03, 0.06] }], name: "Data Science and Analysis" },
            },
            {
                categories: [
                    "University of Mumbai",
                    "University of Florida",
                    "Metropolitan State University",
                    "Central Washington University",
                ],
                stack: { series: [{ data: [0.03, 0.06, 0.06, 0.03] }], name: "Software Development" },
            },
            {
                categories: [
                    "Florida State University",
                    "Georgia State University",
                    "The University of Alabama",
                    "North Carolina State University",
                ],
                stack: { series: [{ data: [0.03, 0.1, 0.03, 0.06] }], name: "Human Resources" },
            },
        ],
        universities: [
            {
                categories: [
                    "Engineering",
                    "Other Occupations",
                    "Middle Management",
                    "Data Science and Analysis",
                    "Top Management",
                    "Project Management",
                    "Operations",
                    "Information Technology",
                    "Finance",
                    "Design",
                    "Customer Services",
                    "Transportation Services",
                    "Consulting",
                    "C Level",
                    "Student",
                    "Software Development",
                    "Quality Management",
                    "Marketing",
                    "Intern",
                    "Founders / Shareholders",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "Business Development",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.23, 0.19, 0.16, 0.16, 0.13, 0.13, 0.1, 0.1, 0.1, 0.1, 0.1, 0.06, 0.06, 0.06, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                    name: "Georgia Institute of Technology",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Middle Management",
                    "Marketing",
                    "Human Resources",
                    "Finance",
                    "Top Management",
                    "Other Occupations",
                    "Engineering",
                    "Design",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Corporate Affairs",
                    "Consulting",
                    "Supply Chain Management / Logistics",
                    "Software Development",
                    "Self Employed",
                    "Sales",
                    "Operations",
                    "Information Technology",
                    "Coach / Professor / Mentor",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.39, 0.13, 0.13, 0.1, 0.1, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                    name: "Georgia State University",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Middle Management",
                    "Customer Services",
                    "Engineering",
                    "Operations",
                    "Coach / Professor / Mentor",
                    "Software Development",
                    "Project Management",
                    "Partner",
                    "Marketing",
                    "Human Resources",
                    "Founders / Shareholders",
                    "Finance",
                    "Corporate Affairs",
                    "C Level",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.39, 0.23, 0.16, 0.13, 0.1, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03,
                            ],
                        },
                    ],
                    name: "Embry-Riddle Aeronautical University",
                },
            },
            {
                categories: [
                    "Marketing",
                    "Transportation Services",
                    "Other Occupations",
                    "Human Resources",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Corporate Affairs",
                    "Middle Management",
                    "Media and Entertainment",
                    "Consulting",
                    "Top Management",
                    "Software Development",
                    "Project Management",
                    "Insurance",
                    "Founders / Shareholders",
                    "Business Development",
                    "Automation",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.39, 0.36, 0.13, 0.1, 0.1, 0.1, 0.1, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03,
                            ],
                        },
                    ],
                    name: "The University of Georgia",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Marketing",
                    "Data Science and Analysis",
                    "Middle Management",
                    "Founders / Shareholders",
                    "Customer Services",
                    "Coach / Professor / Mentor",
                    "Top Management",
                    "Technician",
                    "Team lead / Technical lead",
                    "Sales",
                    "Retail",
                    "Quality Management",
                    "Media and Entertainment",
                    "Information Technology",
                    "Human Resources",
                    "Engineering",
                    "Design",
                    "Corporate Affairs",
                    "Consulting",
                    "Associate",
                ],
                stack: {
                    series: [
                        {
                            data: [
                                0.48, 0.16, 0.13, 0.1, 0.06, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                    name: "Kennesaw State University",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Human Resources",
                    "Software Development",
                    "Project Management",
                    "Operations",
                    "Middle Management",
                    "Founders / Shareholders",
                    "Corporate Affairs",
                    "Accounting",
                ],
                stack: {
                    series: [{ data: [0.36, 0.16, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03] }],
                    name: "Purdue University",
                },
            },
            {
                categories: [
                    "Other Occupations",
                    "Transportation Services",
                    "Coach / Professor / Mentor",
                    "C Level",
                    "Software Development",
                    "Operations",
                    "Investors",
                    "Information Technology",
                    "Finance",
                ],
                stack: {
                    series: [{ data: [0.58, 0.16, 0.1, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03] }],
                    name: "United States Air Force Academy",
                },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Marketing",
                    "Project Management",
                    "Information Technology",
                    "Human Resources",
                    "Corporate Affairs",
                    "Business Administration",
                ],
                stack: {
                    series: [{ data: [0.23, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03] }],
                    name: "Florida State University",
                },
            },
            {
                categories: [
                    "Marketing",
                    "Engineering",
                    "Transportation Services",
                    "Software Development",
                    "Middle Management",
                    "Sales",
                    "Other Occupations",
                    "Human Resources",
                    "Finance",
                    "Corporate Affairs",
                    "Consulting",
                ],
                stack: {
                    series: [{ data: [0.1, 0.1, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03] }],
                    name: "University of Florida",
                },
            },
        ],
        universityLocation: {
            stack: [
                {
                    universityId: 10528,
                    title: "Georgia Institute of Technology",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 2,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 2888,
                    title: "The University of Georgia",
                    place: "Tbilisi, Georgia",
                    city: "Tbilisi, GE",
                    cityId: 1268203191,
                    percent: 1.42,
                    lat: 41.7225,
                    lng: 44.7925,
                },
                {
                    universityId: 10472,
                    title: "Embry-Riddle Aeronautical University",
                    place: "Daytona Beach, United States",
                    city: "Daytona Beach, US",
                    cityId: 1840015068,
                    percent: 1.36,
                    lat: 29.1994,
                    lng: -81.0982,
                },
                {
                    universityId: 10530,
                    title: "Georgia State University",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 1.32,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 10405,
                    title: "United States Air Force Academy",
                    place: "Colorado Springs, United States",
                    city: "Colorado Springs, US",
                    cityId: 1840018825,
                    percent: 1.03,
                    lat: 38.8674,
                    lng: -104.7606,
                },
                {
                    universityId: 10532,
                    title: "Kennesaw State University",
                    place: "Kennesaw, United States",
                    city: "Kennesaw, US",
                    cityId: 1840014756,
                    percent: 0.87,
                    lat: 34.026,
                    lng: -84.6177,
                },
                {
                    universityId: 10669,
                    title: "Purdue University",
                    place: "West Lafayette, United States",
                    city: "West Lafayette, US",
                    cityId: 1840010430,
                    percent: 0.84,
                    lat: 40.4432,
                    lng: -86.9239,
                },
                {
                    universityId: 10534,
                    title: "Georgia Southern University",
                    place: "Statesboro, United States",
                    city: "Statesboro, US",
                    cityId: 1840015801,
                    percent: 0.61,
                    lat: 32.4376,
                    lng: -81.775,
                },
                {
                    universityId: 10465,
                    title: "Florida State University",
                    place: "Tallahassee, United States",
                    city: "Tallahassee, US",
                    cityId: 1840015913,
                    percent: 0.55,
                    lat: 30.4551,
                    lng: -84.2526,
                },
                {
                    universityId: 10467,
                    title: "University of Central Florida",
                    place: "Orlando, United States",
                    city: "Orlando, US",
                    cityId: 1840015099,
                    percent: 0.55,
                    lat: 28.4772,
                    lng: -81.3369,
                },
                {
                    universityId: 10464,
                    title: "University of Florida",
                    place: "Gainesville, United States",
                    city: "Gainesville, US",
                    cityId: 1840004468,
                    percent: 0.52,
                    lat: 42.6521,
                    lng: -78.1326,
                },
                {
                    universityId: 10955,
                    title: "Western Michigan University",
                    place: "Kalamazoo, United States",
                    city: "Kalamazoo, US",
                    cityId: 1840003185,
                    percent: 0.45,
                    lat: 42.2749,
                    lng: -85.5882,
                },
                {
                    universityId: 10531,
                    title: "Emory University",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 0.42,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 11400,
                    title: "University of Cincinnati",
                    place: "Cincinnati, United States",
                    city: "Cincinnati, US",
                    cityId: 1840003814,
                    percent: 0.39,
                    lat: 39.1413,
                    lng: -84.5061,
                },
                {
                    universityId: 11020,
                    title: "Metropolitan State University",
                    place: "St. Paul, United States",
                    city: "St. Paul, US",
                    cityId: 1840006484,
                    percent: 0.36,
                    lat: 36.9068,
                    lng: -82.3162,
                },
                {
                    universityId: 10202,
                    title: "Auburn University",
                    place: "Auburn, United States",
                    city: "Auburn, US",
                    cityId: 1840000319,
                    percent: 0.32,
                    lat: 44.085,
                    lng: -70.2492,
                },
                {
                    universityId: 10234,
                    title: "Arizona State University",
                    place: "Tempe, United States",
                    city: "Tempe, US",
                    cityId: 1840021942,
                    percent: 0.32,
                    lat: 33.3881,
                    lng: -111.9318,
                },
                {
                    universityId: 11000,
                    title: "University of St. Thomas",
                    place: "St. Paul, United States",
                    city: "St. Paul, US",
                    cityId: 1840006484,
                    percent: 0.32,
                    lat: 36.9068,
                    lng: -82.3162,
                },
                {
                    universityId: 11882,
                    title: "University of Washington",
                    place: "Seattle, United States",
                    city: "Seattle, US",
                    cityId: 1840021117,
                    percent: 0.32,
                    lat: 47.6211,
                    lng: -122.3244,
                },
                {
                    universityId: 10537,
                    title: "University of West Georgia",
                    place: "Carrollton, United States",
                    city: "Carrollton, US",
                    cityId: 1840004806,
                    percent: 0.32,
                    lat: 33.2619,
                    lng: -88.0951,
                },
                {
                    universityId: 11516,
                    title: "Penn State University",
                    place: "University Park, United States",
                    city: "University Park, US",
                    cityId: 1840003827,
                    percent: 0.32,
                    lat: 38.9719,
                    lng: -76.9445,
                },
                {
                    universityId: 11776,
                    title: "University of St. Thomas",
                    place: "Houston, United States",
                    city: "Houston, US",
                    cityId: 1840003657,
                    percent: 0.32,
                    lat: 40.2494,
                    lng: -80.211,
                },
                {
                    universityId: 11928,
                    title: "University of Wisconsin-Madison",
                    place: "Madison, United States",
                    city: "Madison, US",
                    cityId: 1840000538,
                    percent: 0.29,
                    lat: 41.7722,
                    lng: -81.053,
                },
                {
                    universityId: 11817,
                    title: "Utah State University",
                    place: "Logan, United States",
                    city: "Logan, US",
                    cityId: 1840006336,
                    percent: 0.29,
                    lat: 37.8509,
                    lng: -81.9857,
                },
                {
                    universityId: 10849,
                    title: "United States Naval Academy",
                    place: "Annapolis, United States",
                    city: "Annapolis, US",
                    cityId: 1840005928,
                    percent: 0.29,
                    lat: 38.9706,
                    lng: -76.5047,
                },
                {
                    universityId: 11018,
                    title: "Saint Mary's University of Minnesota",
                    place: "Winona, United States",
                    city: "Winona, US",
                    cityId: 1840010034,
                    percent: 0.26,
                    lat: 44.0505,
                    lng: -91.6684,
                },
                {
                    universityId: 10951,
                    title: "University of Michigan",
                    place: "Ann Arbor, United States",
                    city: "Ann Arbor, US",
                    cityId: 1840003172,
                    percent: 0.26,
                    lat: 42.2755,
                    lng: -83.7312,
                },
                {
                    universityId: 11312,
                    title: "Vaughn College of Aeronautics and Technology",
                    place: "Flushing, United States",
                    city: "Flushing, US",
                    cityId: 1840002950,
                    percent: 0.26,
                    lat: 43.0637,
                    lng: -83.8403,
                },
                {
                    universityId: 10952,
                    title: "Michigan State University",
                    place: "East Lansing, United States",
                    city: "East Lansing, US",
                    cityId: 1840003101,
                    percent: 0.23,
                    lat: 42.748,
                    lng: -84.4835,
                },
                {
                    universityId: 10539,
                    title: "Valdosta State University",
                    place: "Valdosta, United States",
                    city: "Valdosta, US",
                    cityId: 1840015894,
                    percent: 0.23,
                    lat: 30.8502,
                    lng: -83.2788,
                },
            ],
        },
    },
    section2: {
        degreeDistribution: {
            categories: ["Bachelor's Degree", "Master's Degree", "Doctoral Degree", "Unknown"],
            stack: { series: { data: [63.4, 14.18, 0.65, 21.77] } },
            benchmark: [
                { number: 703635, percent: 52.87 },
                { number: 197957, percent: 14.88 },
                { number: 17316, percent: 1.3 },
                { number: 411851, percent: 30.95 },
            ],
        },
        degreeDistributionPerGender: {
            tableChart: {
                tableHead: ["Edu/Stat", "Male", "Female", "Total"],
                tableBody: [
                    [
                        {
                            data: [
                                "Bachelor's Degree",
                                { series: [{ data: [43.14] }] },
                                { series: [{ data: [34.44] }] },
                                { series: [{ data: [77.59] }] },
                            ],
                        },
                        {
                            data: [
                                "Master's Degree",
                                { series: [{ data: [13.33] }] },
                                { series: [{ data: [8.2] }] },
                                { series: [{ data: [21.53] }] },
                            ],
                        },
                        {
                            data: [
                                "Doctoral Degree",
                                { series: [{ data: [0.59] }] },
                                { series: [{ data: [0.29] }] },
                                { series: [{ data: [0.88] }] },
                            ],
                        },
                        {
                            data: [
                                "Total",
                                { series: [{ data: [57.06] }] },
                                { series: [{ data: [42.94] }] },
                                { series: [{ data: [100] }] },
                            ],
                        },
                    ],
                ],
            },
        },
        languagesSpoken: {
            categories: [
                "English",
                "Spanish",
                "French",
                "German",
                "Hindi",
                "Portuguese",
                "Chinese",
                "Japanese",
                "Arabic",
                "Italian",
                "Russian",
                "Telugu",
                "Korean",
                "Urdu",
                "Dutch",
                "Ukrainian",
                "Tamil",
                "Tagalog",
                "Panjabi",
                "Haitian Creole",
            ],
            stack: {
                series: [
                    {
                        data: [
                            38.52, 22.68, 8.66, 5.64, 2.82, 2.21, 2.21, 1.88, 1.61, 1.48, 1.34, 0.87, 0.87, 0.74, 0.67,
                            0.6, 0.6, 0.54, 0.4, 0.4,
                        ],
                    },
                ],
            },
        },
        numberOfSpokenLanguagesPerJobTitle: {
            categories: [
                "Customer Services",
                "Sales",
                "Other Occupations",
                "Middle Management",
                "Transportation Services",
            ],
            stack: { series: [{ data: [3.308, 2.871, 2.853, 2.696, 2.595] }], name: "Delta Air Lines" },
        },
    },
    filters: {
        jobTitles: [
            { id: 1, name: "Accounting" },
            { id: 4, name: "Art" },
            { id: 5, name: "Associate" },
            { id: 3, name: "Automation" },
            { id: 6, name: "Board Member" },
            { id: 2, name: "Business Administration" },
            { id: 7, name: "Business Development" },
            { id: 15, name: "C Level" },
            { id: 8, name: "Coach / Professor / Mentor" },
            { id: 9, name: "Consulting" },
            { id: 10, name: "Corporate Affairs" },
            { id: 11, name: "Craft Trade Workers" },
            { id: 12, name: "Customer Services" },
            { id: 14, name: "Data Science and Analysis" },
            { id: 18, name: "Design" },
            { id: 13, name: "Diplomacy" },
            { id: 19, name: "Engineering" },
            { id: 20, name: "Finance" },
            { id: 17, name: "Founders / Shareholders" },
            { id: 22, name: "Human Resources" },
            { id: 23, name: "Information Technology" },
            { id: 24, name: "Insurance" },
            { id: 55, name: "Intern" },
            { id: 25, name: "Investors" },
            { id: 27, name: "Legal Services" },
            { id: 29, name: "Manufacturing" },
            { id: 30, name: "Marketing" },
            { id: 31, name: "Media and Entertainment" },
            { id: 32, name: "Medical / Wellness" },
            { id: 28, name: "Middle Management" },
            { id: 42, name: "Military" },
            { id: 64, name: "Movie" },
            { id: 34, name: "Operations" },
            { id: 67, name: "Other Occupations" },
            { id: 35, name: "Partner" },
            { id: 40, name: "Police" },
            { id: 36, name: "Product Development" },
            { id: 37, name: "Production" },
            { id: 38, name: "Project Management" },
            { id: 39, name: "Protection" },
            { id: 41, name: "Public Services" },
            { id: 43, name: "Quality Management" },
            { id: 44, name: "Real Estate" },
            { id: 45, name: "Research and Development" },
            { id: 46, name: "Retail" },
            { id: 47, name: "Retail Banking" },
            { id: 48, name: "Sales" },
            { id: 49, name: "Self Employed" },
            { id: 50, name: "Social Services" },
            { id: 58, name: "Software Development" },
            { id: 57, name: "Sound" },
            { id: 59, name: "Sports / Fitness / Trainer" },
            { id: 51, name: "Student" },
            { id: 53, name: "Supply Chain Management / Logistics" },
            { id: 61, name: "Team lead / Technical lead" },
            { id: 54, name: "Technician" },
            { id: 16, name: "Top Management" },
            { id: 56, name: "Trainee" },
            { id: 60, name: "Transportation Services" },
            { id: 62, name: "University / Academic Affairs" },
            { id: 63, name: "Visual Merchandising" },
            { id: 65, name: "Volunteering" },
            { id: 66, name: "Writer" },
        ],
        schools: [
            { id: 10314, name: "Academy of Art University" },
            { id: 7761, name: "Air University" },
            { id: 4120, name: "Alagappa University" },
            { id: 10553, name: "Albany State University" },
            { id: 10970, name: "Albion College" },
            { id: 10979, name: "Alma College" },
            { id: 10453, name: "American University" },
            { id: 21751, name: "Amity University" },
            { id: 3459, name: "Andhra University" },
            { id: 11765, name: "Angelo State University" },
            { id: 11340, name: "Appalachian State University" },
            { id: 10234, name: "Arizona State University" },
            { id: 10244, name: "Arkansas State University" },
            { id: 10904, name: "Assumption College" },
            { id: 8215, name: "Ateneo de Manila University" },
            { id: 10219, name: "Athens State University" },
            { id: 10572, name: "Atlanta Metropolitan State College" },
            { id: 10202, name: "Auburn University" },
            { id: 10211, name: "Auburn University at Montgomery" },
            { id: 7484, name: "Auckland University of Technology" },
            { id: 11009, name: "Augsburg University" },
            { id: 11669, name: "Augustana University" },
            { id: 10635, name: "Aurora University" },
            { id: 10888, name: "Babson College" },
            { id: 10754, name: "Baker University" },
            { id: 10673, name: "Ball State University" },
            { id: 11371, name: "Barton College" },
            { id: 11730, name: "Baylor University" },
            { id: 1380, name: "Beijing Foreign Studies University" },
            { id: 1398, name: "Beijing International Studies University" },
            { id: 1381, name: "Beijing University of Posts and Telecommunications" },
            { id: 11895, name: "Bellevue College" },
            { id: 10620, name: "Benedictine University" },
            { id: 18597, name: "Berkeley College" },
            { id: 10546, name: "Berry College" },
            { id: 11012, name: "Bethel University" },
            { id: 10705, name: "Bethel University" },
            { id: 10501, name: "Bethune-Cookman University" },
            { id: 10588, name: "Boise State University" },
            { id: 355, name: "Bond University" },
            { id: 10877, name: "Boston College" },
            { id: 10873, name: "Boston University" },
            { id: 11403, name: "Bowling Green State University" },
            { id: 10611, name: "Bradley University" },
            { id: 10878, name: "Brandeis University" },
            { id: 10554, name: "Brenau University" },
            { id: 10796, name: "Brescia University" },
            { id: 11816, name: "Brigham Young University" },
            { id: 16023, name: "Brock University" },
            { id: 11267, name: "Brooklyn College" },
            { id: 11622, name: "Brown University" },
            { id: 11627, name: "Bryant University" },
            { id: 11525, name: "Bucknell University" },
            { id: 3424, name: "Budapesti Corvinus Egyetem" },
            { id: 10320, name: "California Lutheran University" },
            { id: 10290, name: "California State University, Chico" },
            { id: 10278, name: "California State University, Northridge" },
            { id: 10998, name: "Carleton College" },
            { id: 11943, name: "Carthage College" },
            { id: 10959, name: "Central Michigan University" },
            { id: 11442, name: "Central State University" },
            { id: 11889, name: "Central Washington University" },
            { id: 11696, name: "Christian Brothers University" },
            { id: 11857, name: "Christopher Newport University" },
            { id: 9451, name: "Chulalongkorn University" },
            { id: 11896, name: "City University of Seattle" },
            { id: 10065, name: "City, University of London" },
            { id: 10319, name: "Claremont McKenna College" },
            { id: 10544, name: "Clark Atlanta University" },
            { id: 10550, name: "Clayton State University" },
            { id: 11632, name: "Clemson University" },
            { id: 11409, name: "Cleveland State University" },
            { id: 3795, name: "Cochin University of Science and Technology" },
            { id: 11635, name: "College of Charleston" },
            { id: 10895, name: "College of the Holy Cross" },
            { id: 10403, name: "Colorado State University" },
            { id: 10413, name: "Colorado Technical University" },
            { id: 11060, name: "Columbia College" },
            { id: 10547, name: "Columbus State University" },
            { id: 11017, name: "Concordia College" },
            { id: 11651, name: "Converse College" },
            { id: 11186, name: "Cornell University" },
            { id: 10053, name: "Cranfield University" },
            { id: 1755, name: "Dalian University of Technology" },
            { id: 11781, name: "Dallas Baptist University" },
            { id: 11346, name: "Davidson College" },
            { id: 10502, name: "Daytona State College" },
            { id: 10600, name: "DePaul University" },
            { id: 10677, name: "DePauw University" },
            { id: 10608, name: "DeVry University" },
            { id: 10933, name: "Dean College" },
            { id: 11039, name: "Delta State University" },
            { id: 19148, name: "Dixie State University" },
            { id: 10623, name: "Dominican University" },
            { id: 11520, name: "Drexel University" },
            { id: 11337, name: "Duke University" },
            { id: 11527, name: "Duquesne University" },
            { id: 11343, name: "East Carolina University" },
            { id: 10567, name: "East Georgia State College" },
            { id: 11682, name: "East Tennessee State University" },
            { id: 10610, name: "Eastern Illinois University" },
            { id: 10775, name: "Eastern Kentucky University" },
            { id: 10961, name: "Eastern Michigan University" },
            { id: 11507, name: "Eastern Oregon University" },
            { id: 10489, name: "Eckerd College" },
            { id: 11951, name: "Edgewood College" },
            { id: 11344, name: "Elon University" },
            { id: 10472, name: "Embry-Riddle Aeronautical University" },
            { id: 10531, name: "Emory University" },
            { id: 10520, name: "Everglades University" },
            { id: 11266, name: "Farmingdale State College" },
            { id: 18535, name: "Fashion Institute of Technology" },
            { id: 10216, name: "Faulkner University" },
            { id: 10962, name: "Ferris State University" },
            { id: 11693, name: "Fisk University" },
            { id: 359, name: "Flinders University" },
            { id: 10470, name: "Florida Atlantic University" },
            { id: 10478, name: "Florida Gulf Coast University" },
            { id: 10474, name: "Florida Institute of Technology" },
            { id: 10468, name: "Florida International University" },
            { id: 10490, name: "Florida Southern College" },
            { id: 10465, name: "Florida State University" },
            { id: 18520, name: "Fordham University" },
            { id: 10918, name: "Framingham State University" },
            { id: 11636, name: "Furman University" },
            { id: 11842, name: "George Mason University" },
            { id: 10451, name: "Georgetown University" },
            { id: 10542, name: "Georgia College and State University" },
            { id: 10560, name: "Georgia Gwinnett College" },
            { id: 10528, name: "Georgia Institute of Technology" },
            { id: 10534, name: "Georgia Southern University" },
            { id: 10530, name: "Georgia State University" },
            { id: 10321, name: "Golden Gate University" },
            { id: 11887, name: "Gonzaga University" },
            { id: 10901, name: "Gordon College" },
            { id: 10571, name: "Gordon State College" },
            { id: 10733, name: "Graceland University" },
            { id: 10237, name: "Grand Canyon University" },
            { id: 10956, name: "Grand Valley State University" },
            { id: 18333, name: "Gustavus Adolphus College" },
            { id: 11007, name: "Hamline University" },
            { id: 11856, name: "Hampton University" },
            { id: 10248, name: "Harding University" },
            { id: 10872, name: "Harvard University" },
            { id: 11953, name: "Herzing University" },
            { id: 3063, name: "Hochschule Bremen" },
            { id: 11202, name: "Hofstra University" },
            { id: 7434, name: "Hogeschool van Arnhem en Nijmegen" },
            { id: 8137, name: "Holy Angel University" },
            { id: 10454, name: "Howard University" },
            { id: 3020, name: "Humboldt-Universit\u00e4t zu Berlin" },
            { id: 10590, name: "Idaho State University" },
            { id: 10601, name: "Illinois Institute of Technology" },
            { id: 10604, name: "Illinois State University" },
            { id: 10062, name: "Imperial College London" },
            { id: 10676, name: "Indiana State University" },
            { id: 10670, name: "Indiana University Bloomington" },
            { id: 10688, name: "Indiana University South Bend" },
            { id: 11530, name: "Indiana University of Pennsylvania" },
            { id: 10687, name: "Indiana Wesleyan University" },
            { id: 11260, name: "Iona College" },
            { id: 10713, name: "Iowa State University" },
            { id: 11206, name: "Ithaca College" },
            { id: 10488, name: "Jacksonville University" },
            { id: 11846, name: "James Madison University" },
            { id: 4164, name: "Jawaharlal Nehru Technological University" },
            { id: 11626, name: "Johnson and Wales University" },
            { id: 3971, name: "KIIT University" },
            { id: 10747, name: "Kansas State University" },
            { id: 10532, name: "Kennesaw State University" },
            { id: 11402, name: "Kent State University" },
            { id: 10968, name: "Kettering University" },
            { id: 10063, name: "King's College London" },
            { id: 10077, name: "Kingston University" },
            { id: 11741, name: "Lamar University" },
            { id: 11640, name: "Lander University" },
            { id: 10900, name: "Lesley University" },
            { id: 10624, name: "Lewis University" },
            { id: 11845, name: "Liberty University" },
            { id: 10551, name: "Life University" },
            { id: 18392, name: "Lindenwood University" },
            { id: 11381, name: "Livingstone College" },
            { id: 10069, name: "London Business School" },
            { id: 10080, name: "London Metropolitan University" },
            { id: 10800, name: "Louisiana State University" },
            { id: 10805, name: "Louisiana Tech University" },
            { id: 10292, name: "Loyola Marymount University" },
            { id: 10602, name: "Loyola University Chicago" },
            { id: 10802, name: "Loyola University New Orleans" },
            { id: 10719, name: "Luther College" },
            { id: 11002, name: "Macalester College" },
            { id: 10984, name: "Madonna University" },
            { id: 4119, name: "Madurai Kamaraj University" },
            { id: 10835, name: "Maine Maritime Academy" },
            { id: 3753, name: "Mangalore University" },
            { id: 11253, name: "Manhattan College" },
            { id: 18643, name: "Marymount Manhattan College" },
            { id: 11586, name: "Marywood University" },
            { id: 10871, name: "Massachusetts Institute of Technology" },
            { id: 10942, name: "Massachusetts School of Law" },
            { id: 11790, name: "McMurry University" },
            { id: 10536, name: "Mercer University" },
            { id: 11252, name: "Mercy College" },
            { id: 11020, name: "Metropolitan State University" },
            { id: 10411, name: "Metropolitan State University of Denver" },
            { id: 10477, name: "Miami Dade College" },
            { id: 11406, name: "Miami University" },
            { id: 10952, name: "Michigan State University" },
            { id: 10953, name: "Michigan Technological University" },
            { id: 10556, name: "Middle Georgia State University" },
            { id: 11681, name: "Middle Tennessee State University" },
            { id: 11826, name: "Middlebury College" },
            { id: 470, name: "Milli Aviasiya Akademiyasi" },
            { id: 11001, name: "Minnesota State University, Mankato" },
            { id: 11392, name: "Minot State University" },
            { id: 11037, name: "Mississippi College" },
            { id: 11032, name: "Mississippi State University" },
            { id: 11055, name: "Missouri University of Science and Technology" },
            { id: 362, name: "Monash University" },
            { id: 18607, name: "Monroe College" },
            { id: 11367, name: "Montreat College" },
            { id: 10781, name: "Morehead State University" },
            { id: 10540, name: "Morehouse College" },
            { id: 10851, name: "Morgan State University" },
            { id: 11541, name: "Muhlenberg College" },
            { id: 6295, name: "Myongji University" },
            { id: 1693, name: "Nanjing University" },
            { id: 1914, name: "Nankai University" },
            { id: 17785, name: "National Defense University" },
            { id: 10621, name: "National Louis University" },
            { id: 309, name: "National Polytechnic University of Armenia" },
            { id: 14893, name: "National Taiwan University" },
            { id: 17643, name: "National University" },
            { id: 10309, name: "Naval Postgraduate School" },
            { id: 11265, name: "Nazareth College" },
            { id: 11166, name: "New Jersey City University" },
            { id: 11236, name: "New York Institute of Technology" },
            { id: 18577, name: "New York Law School" },
            { id: 18515, name: "New York University" },
            { id: 11348, name: "North Carolina Agricultural and Technical State University" },
            { id: 11349, name: "North Carolina Central University" },
            { id: 11338, name: "North Carolina State University" },
            { id: 11363, name: "North Carolina Wesleyan College" },
            { id: 10630, name: "North Central College" },
            { id: 11390, name: "North Dakota State University" },
            { id: 10626, name: "North Park University" },
            { id: 10876, name: "Northeastern University" },
            { id: 10236, name: "Northern Arizona University" },
            { id: 10599, name: "Northern Illinois University" },
            { id: 10777, name: "Northern Kentucky University" },
            { id: 10517, name: "Northwest Florida State College" },
            { id: 10597, name: "Northwestern University" },
            { id: 11828, name: "Norwich University" },
            { id: 10471, name: "Nova Southeastern University" },
            { id: 10960, name: "Oakland University" },
            { id: 10549, name: "Oglethorpe University" },
            { id: 11470, name: "Oklahoma State University" },
            { id: 11847, name: "Old Dominion University" },
            { id: 11473, name: "Oral Roberts University" },
            { id: 11493, name: "Oregon State University" },
            { id: 4161, name: "Osmania University" },
            { id: 10753, name: "Ottawa University" },
            { id: 11892, name: "Pacific Lutheran University" },
            { id: 10492, name: "Palm Beach State College" },
            { id: 3541, name: "Panjab University" },
            { id: 11064, name: "Park University" },
            { id: 11613, name: "Peirce College" },
            { id: 11516, name: "Penn State University" },
            { id: 10521, name: "Pensacola State College" },
            { id: 10286, name: "Pepperdine University" },
            { id: 10561, name: "Piedmont College" },
            { id: 10506, name: "Polk State College" },
            { id: 2441, name: "Pontificia Universidad Cat\u00f3lica Madre y Maestra" },
            { id: 11495, name: "Portland State University" },
            { id: 11151, name: "Princeton University" },
            { id: 10669, name: "Purdue University" },
            { id: 10064, name: "Queen Mary University of London" },
            { id: 350, name: "Queensland University of Technology" },
            { id: 10427, name: "Quinnipiac University" },
            { id: 9527, name: "Rangsit University" },
            { id: 11851, name: "Regent University" },
            { id: 10412, name: "Regis University" },
            { id: 11194, name: "Rensselaer Polytechnic Institute" },
            { id: 10094, name: "Richmond, The American International University in London" },
            { id: 11161, name: "Rider University" },
            { id: 18896, name: "Robert Morris University" },
            { id: 11192, name: "Rochester Institute of Technology" },
            { id: 11104, name: "Rocky Mountain College" },
            { id: 11628, name: "Roger Williams University" },
            { id: 10481, name: "Rollins College" },
            { id: 2703, name: "SKEMA Business School" },
            { id: 2945, name: "SRH Hochschule Heidelberg" },
            { id: 10972, name: "Saginaw Valley State University" },
            { id: 18379, name: "Saint Louis University" },
            { id: 11018, name: "Saint Mary's University of Minnesota" },
            { id: 11829, name: "Saint Michael's College" },
            { id: 10903, name: "Salem State University" },
            { id: 10277, name: "San Diego State University" },
            { id: 10280, name: "San Francisco State University" },
            { id: 10487, name: "Santa Fe College" },
            { id: 10533, name: "Savannah College of Art and Design" },
            { id: 10555, name: "Savannah State University" },
            { id: 18536, name: "School of Visual Arts" },
            { id: 10614, name: "School of the Art Institute of Chicago" },
            { id: 11891, name: "Seattle Pacific University" },
            { id: 11154, name: "Seton Hall University" },
            { id: 5128, name: "Sharif University of Technology" },
            { id: 11910, name: "Shepherd University" },
            { id: 11535, name: "Shippensburg University of Pennsylvania" },
            { id: 1884, name: "Sichuan University" },
            { id: 11229, name: "Siena College" },
            { id: 10987, name: "Siena Heights University" },
            { id: 9459, name: "Silpakorn University" },
            { id: 11220, name: "Skidmore College" },
            { id: 11823, name: "Snow College" },
            { id: 6377, name: "Sookmyung Women's University" },
            { id: 5934, name: "Sophia University" },
            { id: 11643, name: "South Carolina State University" },
            { id: 11667, name: "South Dakota School of Mines and Technology" },
            { id: 11666, name: "South Dakota State University" },
            { id: 10807, name: "Southeastern Louisiana University" },
            { id: 348, name: "Southern Cross University" },
            { id: 10605, name: "Southern Illinois University Edwardsville" },
            { id: 11729, name: "Southern Methodist University" },
            { id: 11139, name: "Southern New Hampshire University" },
            { id: 11820, name: "Southern Utah University" },
            { id: 11083, name: "Southwest Baptist University" },
            { id: 10541, name: "Spelman College" },
            { id: 3469, name: "Sri Krishnadevaraya University" },
            { id: 9454, name: "Srinakharinwirot University" },
            { id: 9092, name: "St Mary's College" },
            { id: 10722, name: "St. Ambrose University" },
            { id: 11008, name: "St. Catherine University" },
            { id: 18332, name: "St. Cloud State University" },
            { id: 11305, name: "St. Francis College" },
            { id: 11215, name: "St. John's University" },
            { id: 11296, name: "St. Joseph's College" },
            { id: 18187, name: "St. Mary's College of Maryland" },
            { id: 11945, name: "St. Norbert College" },
            { id: 11003, name: "St. Olaf College" },
            { id: 10265, name: "Stanford University" },
            { id: 11218, name: "State University of New York at Oswego" },
            { id: 11072, name: "Stephens College" },
            { id: 11159, name: "Stevens Institute of Technology" },
            { id: 10866, name: "Stevenson University" },
            { id: 11195, name: "Stony Brook University" },
            { id: 10457, name: "Strayer University" },
            { id: 10890, name: "Suffolk University" },
            { id: 11796, name: "Sul Ross State University" },
            { id: 11853, name: "Sweet Briar College" },
            { id: 11191, name: "Syracuse University" },
            { id: 2560, name: "Tallinna Tehnika\u00fclikool" },
            { id: 11766, name: "Tarleton State University" },
            { id: 10689, name: "Taylor University" },
            { id: 423, name: "Technische Universit\u00e4t Wien" },
            { id: 7003, name: "Tecnol\u00f3gico de Monterrey" },
            { id: 11686, name: "Tennessee State University" },
            { id: 11725, name: "Texas A&M University" },
            { id: 11756, name: "Texas A&M University-Commerce" },
            { id: 11739, name: "Texas Christian University" },
            { id: 11759, name: "Texas Southern University" },
            { id: 11736, name: "Texas State University" },
            { id: 11731, name: "Texas Tech University" },
            { id: 11752, name: "Texas Woman's University" },
            { id: 2748, name: "The American University of Paris" },
            { id: 18609, name: "The City College of New York" },
            { id: 11013, name: "The College of St. Scholastica" },
            { id: 18552, name: "The Cooper Union for the Advancement of Science and Art" },
            { id: 11885, name: "The Evergreen State College" },
            { id: 18523, name: "The New School" },
            { id: 11399, name: "The Ohio State University" },
            { id: 10201, name: "The University of Alabama" },
            { id: 10120, name: "The University of Edinburgh" },
            { id: 2888, name: "The University of Georgia" },
            { id: 11680, name: "The University of Memphis" },
            { id: 16392, name: "The University of Queensland" },
            { id: 10192, name: "The University of Sheffield" },
            { id: 11684, name: "The University of Tennessee at Chattanooga" },
            { id: 11734, name: "The University of Texas at Arlington" },
            { id: 11724, name: "The University of Texas at Austin" },
            { id: 11738, name: "The University of Texas at El Paso" },
            { id: 11733, name: "The University of Texas at San Antonio" },
            { id: 11172, name: "Thomas Edison State University" },
            { id: 10790, name: "Thomas More University" },
            { id: 1844, name: "Tongji University" },
            { id: 11040, name: "Tougaloo College" },
            { id: 18558, name: "Touro College" },
            { id: 10847, name: "Towson University" },
            { id: 8238, name: "Trinity University of Asia" },
            { id: 10206, name: "Troy University" },
            { id: 10874, name: "Tufts University" },
            { id: 10801, name: "Tulane University" },
            { id: 10405, name: "United States Air Force Academy" },
            { id: 10849, name: "United States Naval Academy" },
            { id: 7212, name: "Universidad Aut\u00f3noma de Yucat\u00e1n" },
            { id: 9137, name: "Universidad Carlos III de Madrid" },
            { id: 2032, name: "Universidad Externado de Colombia" },
            { id: 221, name: "Universidad Torcuato di Tella" },
            { id: 222, name: "Universidad de Belgrano" },
            { id: 213, name: "Universidad de Buenos Aires" },
            { id: 9099, name: "Universidad de C\u00e1diz" },
            { id: 2450, name: "Universidad de Guayaquil" },
            { id: 7005, name: "Universidad de Monterrey" },
            { id: 9151, name: "Universidad de Navarra" },
            { id: 9115, name: "Universidad de Salamanca" },
            { id: 2054, name: "Universidad de San Buenaventura" },
            { id: 3365, name: "Universidad de San Carlos de Guatemala" },
            { id: 8029, name: "Universidad de San Mart\u00edn de Porres" },
            { id: 211, name: "Universidad del Este" },
            { id: 863, name: "Universidade Federal de Alagoas" },
            { id: 960, name: "Universidade Federal do Rio de Janeiro" },
            { id: 1018, name: "Universidade de S\u00e3o Paulo" },
            { id: 701, name: "Universiteit Gent" },
            { id: 10203, name: "University of Alabama at Birmingham" },
            { id: 10242, name: "University of Arkansas" },
            { id: 10438, name: "University of Bridgeport" },
            { id: 10266, name: "University of California, Berkeley" },
            { id: 10270, name: "University of California, Davis" },
            { id: 10271, name: "University of California, Irvine" },
            { id: 10267, name: "University of California, Los Angeles" },
            { id: 10274, name: "University of California, Riverside" },
            { id: 10269, name: "University of California, San Diego" },
            { id: 10273, name: "University of California, Santa Barbara" },
            { id: 10245, name: "University of Central Arkansas" },
            { id: 10467, name: "University of Central Florida" },
            { id: 11062, name: "University of Central Missouri" },
            { id: 10595, name: "University of Chicago" },
            { id: 11400, name: "University of Cincinnati" },
            { id: 10426, name: "University of Connecticut" },
            { id: 11405, name: "University of Dayton" },
            { id: 10404, name: "University of Denver" },
            { id: 10729, name: "University of Dubuque" },
            { id: 10079, name: "University of East London" },
            { id: 10464, name: "University of Florida" },
            { id: 10529, name: "University of Georgia" },
            { id: 3379, name: "University of Guyana" },
            { id: 10433, name: "University of Hartford" },
            { id: 10586, name: "University of Hawaii at Hilo" },
            { id: 10582, name: "University of Hawaii at Manoa" },
            { id: 11727, name: "University of Houston" },
            { id: 10596, name: "University of Illinois at Urbana-Champaign" },
            { id: 10714, name: "University of Iowa" },
            { id: 11396, name: "University of Jamestown" },
            { id: 10772, name: "University of Kentucky" },
            { id: 7610, name: "University of Lagos" },
            { id: 10042, name: "University of Leicester" },
            { id: 10075, name: "University of London" },
            { id: 10803, name: "University of Louisiana at Lafayette" },
            { id: 10810, name: "University of Louisiana at Monroe" },
            { id: 10773, name: "University of Louisville" },
            { id: 11391, name: "University of Mary" },
            { id: 10844, name: "University of Maryland" },
            { id: 10875, name: "University of Massachusetts Amherst" },
            { id: 10882, name: "University of Massachusetts Boston" },
            { id: 10885, name: "University of Massachusetts Lowell" },
            { id: 10951, name: "University of Michigan" },
            { id: 10958, name: "University of Michigan-Dearborn" },
            { id: 10997, name: "University of Minnesota-Twin Cities" },
            { id: 11051, name: "University of Missouri-Kansas City" },
            { id: 3872, name: "University of Mumbai" },
            { id: 1081, name: "University of National and World Economy" },
            { id: 11110, name: "University of Nebraska at Omaha" },
            { id: 11108, name: "University of Nebraska-Lincoln" },
            { id: 11129, name: "University of Nevada, Reno" },
            { id: 11138, name: "University of New Hampshire" },
            { id: 11336, name: "University of North Carolina at Chapel Hill" },
            { id: 11342, name: "University of North Carolina at Charlotte" },
            { id: 11389, name: "University of North Dakota" },
            { id: 10473, name: "University of North Florida" },
            { id: 10545, name: "University of North Georgia" },
            { id: 11728, name: "University of North Texas" },
            { id: 11023, name: "University of Northwestern - St. Paul" },
            { id: 10671, name: "University of Notre Dame" },
            { id: 10138, name: "University of Oxford" },
            { id: 11515, name: "University of Pennsylvania" },
            { id: 11518, name: "University of Pittsburgh" },
            { id: 11893, name: "University of Puget Sound" },
            { id: 11848, name: "University of Richmond" },
            { id: 11189, name: "University of Rochester" },
            { id: 8155, name: "University of San Carlos" },
            { id: 10285, name: "University of San Francisco" },
            { id: 8216, name: "University of Santo Tomas" },
            { id: 10208, name: "University of South Alabama" },
            { id: 11633, name: "University of South Carolina" },
            { id: 11647, name: "University of South Carolina-Aiken" },
            { id: 10466, name: "University of South Florida" },
            { id: 10268, name: "University of Southern California" },
            { id: 10830, name: "University of Southern Maine" },
            { id: 11000, name: "University of St. Thomas" },
            { id: 11776, name: "University of St. Thomas" },
            { id: 10141, name: "University of Sussex" },
            { id: 14911, name: "University of Taipei" },
            { id: 11410, name: "University of Toledo" },
            { id: 11840, name: "University of Virginia" },
            { id: 11882, name: "University of Washington" },
            { id: 10537, name: "University of West Georgia" },
            { id: 11936, name: "University of Wisconsin-La Crosse" },
            { id: 11928, name: "University of Wisconsin-Madison" },
            { id: 11929, name: "University of Wisconsin-Milwaukee" },
            { id: 11944, name: "University of Wisconsin-River Falls" },
            { id: 11938, name: "University of Wisconsin-Stout" },
            { id: 10787, name: "University of the Cumberlands" },
            { id: 11762, name: "University of the Incarnate Word" },
            { id: 356, name: "University of the Sunshine Coast" },
            { id: 5323, name: "Universit\u00e0 Cattolica del Sacro Cuore" },
            { id: 5348, name: "Universit\u00e0 degli Studi di Palermo" },
            { id: 5337, name: "Universit\u00e0 degli Studi di Torino" },
            { id: 5303, name: "Universit\u00e0 degli Studi di Trieste" },
            { id: 422, name: "Universit\u00e4t Wien" },
            { id: 684, name: "Universit\u00e9 Libre de Bruxelles" },
            { id: 9241, name: "Universit\u00e9 de Gen\u00e8ve" },
            { id: 2839, name: "Universit\u00e9 de Nantes" },
            { id: 11817, name: "Utah State University" },
            { id: 11819, name: "Utah Valley University" },
            { id: 10539, name: "Valdosta State University" },
            { id: 10475, name: "Valencia College" },
            { id: 11677, name: "Vanderbilt University" },
            { id: 11312, name: "Vaughn College of Aeronautics and Technology" },
            { id: 11523, name: "Villanova University" },
            { id: 10696, name: "Vincennes University" },
            { id: 11843, name: "Virginia Commonwealth University" },
            { id: 11854, name: "Virginia Military Institute" },
            { id: 11863, name: "Virginia State University" },
            { id: 11872, name: "Virginia Union University" },
            { id: 3741, name: "Visvesvaraya Technological University" },
            { id: 11339, name: "Wake Forest University" },
            { id: 11883, name: "Washington State University" },
            { id: 10954, name: "Wayne State University" },
            { id: 11818, name: "Weber State University" },
            { id: 18383, name: "Webster University" },
            { id: 11908, name: "West Virginia University" },
            { id: 10774, name: "Western Kentucky University" },
            { id: 10955, name: "Western Michigan University" },
            { id: 11884, name: "Western Washington University" },
            { id: 11822, name: "Westminster College" },
            { id: 10609, name: "Wheaton College" },
            { id: 10333, name: "Whittier College" },
            { id: 11898, name: "Whitworth University" },
            { id: 1263, name: "Wilfrid Laurier University" },
            { id: 11014, name: "Winona State University" },
            { id: 11638, name: "Winthrop University" },
            { id: 10345, name: "Woodbury University" },
            { id: 11408, name: "Wright State University" },
            { id: 10809, name: "Xavier University of Louisiana" },
            { id: 10425, name: "Yale University" },
            { id: 6368, name: "Yonsei University" },
            { id: 10573, name: "Young Harris College" },
        ],
        languages: [
            { id: 20, name: "Amharic" },
            { id: 23, name: "Arabic" },
            { id: 27, name: "Armenian" },
            { id: 58, name: "Bengali" },
            { id: 70, name: "Bokm\u00e5l, Norwegian" },
            { id: 75, name: "Bulgarian" },
            { id: 95, name: "Chinese" },
            { id: 113, name: "Creoles and pidgins" },
            { id: 121, name: "Czech" },
            { id: 136, name: "Dutch" },
            { id: 146, name: "English" },
            { id: 159, name: "Finnish" },
            { id: 163, name: "French" },
            { id: 177, name: "German" },
            { id: 189, name: "Greek, Modern (1453-)" },
            { id: 191, name: "Guarani" },
            { id: 192, name: "Gujarati" },
            { id: 196, name: "Haitian Creole" },
            { id: 203, name: "Hindi" },
            { id: 207, name: "Hungarian" },
            { id: 230, name: "Italian" },
            { id: 231, name: "Japanese" },
            { id: 242, name: "Kannada" },
            { id: 266, name: "Korean" },
            { id: 281, name: "Lao" },
            { id: 282, name: "Latin" },
            { id: 291, name: "Lithuanian" },
            { id: 313, name: "Malayalam" },
            { id: 325, name: "Marathi" },
            { id: 372, name: "Norwegian" },
            { id: 405, name: "Panjabi" },
            { id: 408, name: "Pashto" },
            { id: 410, name: "Persian" },
            { id: 416, name: "Polish" },
            { id: 417, name: "Portuguese" },
            { id: 432, name: "Russian" },
            { id: 459, name: "Sindhi" },
            { id: 467, name: "Slovak" },
            { id: 470, name: "Somali" },
            { id: 480, name: "Spanish" },
            { id: 489, name: "Swedish" },
            { id: 492, name: "Tagalog" },
            { id: 497, name: "Tamil" },
            { id: 499, name: "Telugu" },
            { id: 502, name: "Thai" },
            { id: 512, name: "Tonga (Nyasa)" },
            { id: 513, name: "Tonga (Tonga Islands)" },
            { id: 519, name: "Turkish" },
            { id: 528, name: "Ukrainian" },
            { id: 533, name: "Urdu" },
            { id: 539, name: "Vietnamese" },
            { id: 557, name: "Yoruba" },
        ],
        cities: [
            { id: 1288001666, name: "Accra" },
            { id: 1840013688, name: "Acworth" },
            { id: 1840003226, name: "Adrian" },
            { id: 1840019221, name: "Agoura Hills" },
            { id: 1840026377, name: "Albrightsville" },
            { id: 1840019321, name: "Aliso Viejo" },
            { id: 1840004256, name: "Allen" },
            { id: 1840003967, name: "Allen Park" },
            { id: 1840001044, name: "Allentown" },
            { id: 1840000195, name: "Almont" },
            { id: 1840013659, name: "Alpharetta" },
            { id: 1840018366, name: "Anacortes" },
            { id: 1840007066, name: "Ankeny" },
            { id: 1840003172, name: "Ann Arbor" },
            { id: 1840015355, name: "Apex" },
            { id: 1840002413, name: "Arlington" },
            { id: 1840002274, name: "Astoria" },
            { id: 1840000510, name: "Athens" },
            { id: 1840004026, name: "Atlanta" },
            { id: 1840005335, name: "Atlantic Beach" },
            { id: 1840015535, name: "Aubrey" },
            { id: 1840000319, name: "Auburn" },
            { id: 1840000302, name: "Augusta" },
            { id: 1840000806, name: "Aurora" },
            { id: 1840003306, name: "Austin" },
            { id: 1840013702, name: "Avondale Estates" },
            { id: 1840001592, name: "Baltimore" },
            { id: 1356410365, name: "Bangalore" },
            { id: 1840000385, name: "Batavia" },
            { id: 1840013818, name: "Beaufort" },
            { id: 1840000723, name: "Belleville" },
            { id: 1840000308, name: "Bellevue" },
            { id: 1840005232, name: "Bellmore" },
            { id: 1840007451, name: "Beverly Hills" },
            { id: 1840001926, name: "Bismarck" },
            { id: 1840011518, name: "Blakeslee" },
            { id: 1840000415, name: "Bloomfield" },
            { id: 1840002439, name: "Bloomfield Hills" },
            { id: 1840004794, name: "Bloomingburg" },
            { id: 1840002970, name: "Bloomington" },
            { id: 1840027142, name: "Boise" },
            { id: 1840018454, name: "Bonney Lake" },
            { id: 1840000946, name: "Boonton" },
            { id: 1840013790, name: "Bossier City" },
            { id: 1840000455, name: "Boston" },
            { id: 1840018737, name: "Bountiful" },
            { id: 1840018509, name: "Bozeman" },
            { id: 1840018410, name: "Bremerton" },
            { id: 1840081606, name: "Brick" },
            { id: 1840003099, name: "Brighton" },
            { id: 1840033999, name: "Bronx" },
            { id: 1840000592, name: "Brooklyn" },
            { id: 1840018781, name: "Broomfield" },
            { id: 1840013678, name: "Buford" },
            { id: 1840007022, name: "Burbank" },
            { id: 1840002862, name: "Burke" },
            { id: 1840000046, name: "Burlington" },
            { id: 1840006115, name: "Burnsville" },
            { id: 1840003642, name: "California" },
            { id: 1840092506, name: "Camp Washington" },
            { id: 1840000512, name: "Canton" },
            { id: 1840004806, name: "Carrollton" },
            { id: 1840014701, name: "Cartersville" },
            { id: 1840006758, name: "Carver" },
            { id: 1840001006, name: "Centerville" },
            { id: 1840005028, name: "Central Islip" },
            { id: 1840006759, name: "Chanhassen" },
            { id: 1840016152, name: "Chapel Hill" },
            { id: 1840006196, name: "Charleston" },
            { id: 1840003107, name: "Charlotte" },
            { id: 1840006760, name: "Chaska" },
            { id: 1840014569, name: "Chattanooga" },
            { id: 1840000282, name: "Chelsea" },
            { id: 1840000690, name: "Chester" },
            { id: 1840031323, name: "Chevy Chase" },
            { id: 1840000494, name: "Chicago" },
            { id: 1840019115, name: "Chino Hills" },
            { id: 1840057308, name: "Church" },
            { id: 1840003814, name: "Cincinnati" },
            { id: 1840005570, name: "Claymont" },
            { id: 1840006704, name: "Clearwater" },
            { id: 1840018402, name: "Coeur d'Alene" },
            { id: 1840074067, name: "College Hill Station" },
            { id: 1840019430, name: "Colleyville" },
            { id: 1840000262, name: "Columbia" },
            { id: 1840010999, name: "Columbiaville" },
            { id: 1840001715, name: "Columbus" },
            { id: 1840011429, name: "Compton" },
            { id: 1840019586, name: "Conroe" },
            { id: 1840001015, name: "Conway" },
            { id: 1840014806, name: "Conyers" },
            { id: 1840019439, name: "Coppell" },
            { id: 1840000264, name: "Corona" },
            { id: 1840019354, name: "Coronado" },
            { id: 1840002910, name: "Cottage Grove" },
            { id: 1840001688, name: "Covington" },
            { id: 1840007091, name: "Crown Point" },
            { id: 1840007382, name: "Culver" },
            { id: 1840007103, name: "Cumming" },
            { id: 1840014743, name: "Dacula" },
            { id: 1840014672, name: "Dahlonega" },
            { id: 1840000364, name: "Dallas" },
            { id: 1840015135, name: "Dania Beach" },
            { id: 1840000854, name: "Danville" },
            { id: 1840000885, name: "Dayton" },
            { id: 1840015068, name: "Daytona Beach" },
            { id: 1840003969, name: "Dearborn" },
            { id: 1840003970, name: "Dearborn Heights" },
            { id: 1840001403, name: "Decatur" },
            { id: 1840015123, name: "Delray Beach" },
            { id: 1840003540, name: "Demarest" },
            { id: 1840005932, name: "Denton" },
            { id: 1840003714, name: "Denver" },
            { id: 1840003971, name: "Detroit" },
            { id: 1840014804, name: "Douglasville" },
            { id: 1840003340, name: "Downey" },
            { id: 1840000330, name: "Draper" },
            { id: 1840013179, name: "Dry Ridge" },
            { id: 1840001321, name: "Dublin" },
            { id: 1840007685, name: "Duluth" },
            { id: 1840006017, name: "Dunn Loring" },
            { id: 1840018427, name: "Duvall" },
            { id: 1840001982, name: "Eagle River" },
            { id: 1840089730, name: "East Fayetteville" },
            { id: 1840003698, name: "East Greenville" },
            { id: 1840005236, name: "East Meadow" },
            { id: 1840007821, name: "Eden Prairie" },
            { id: 1840012021, name: "Edison" },
            { id: 1840150306, name: "El Adobe" },
            { id: 1840029248, name: "Ellenwood" },
            { id: 1840020291, name: "Emeryville" },
            { id: 1840003717, name: "Ephrata" },
            { id: 1840013168, name: "Erlanger" },
            { id: 1756020822, name: "Erlenbach" },
            { id: 1840023463, name: "Fairbanks" },
            { id: 1840002639, name: "Fairburn" },
            { id: 1840003686, name: "Farmingdale" },
            { id: 1840002441, name: "Farmington" },
            { id: 1840004320, name: "Fayetteville" },
            { id: 1840019826, name: "Federal Way" },
            { id: 1840002948, name: "Fenton" },
            { id: 1840004748, name: "Fishkill" },
            { id: 1840003973, name: "Flat Rock" },
            { id: 1840002949, name: "Flint" },
            { id: 1840001660, name: "Florence" },
            { id: 1840004867, name: "Florida" },
            { id: 1840004636, name: "Forest Lake" },
            { id: 1840014236, name: "Fort Lauderdale" },
            { id: 1840014226, name: "Fort Myers" },
            { id: 1840013163, name: "Fort Thomas" },
            { id: 1840013923, name: "Fort Walton Beach" },
            { id: 1840020696, name: "Fort Worth" },
            { id: 1840002748, name: "Franklin" },
            { id: 1840001198, name: "Franklin Park" },
            { id: 1840020654, name: "Frisco" },
            { id: 1840016197, name: "Fuquay-Varina" },
            { id: 1840004468, name: "Gainesville" },
            { id: 1840008311, name: "Garden Grove" },
            { id: 1840019855, name: "Gig Harbor" },
            { id: 1840011405, name: "Glen Ellyn" },
            { id: 1840003037, name: "Glendale" },
            { id: 1840020749, name: "Granbury" },
            { id: 1840020697, name: "Grapevine" },
            { id: 1840000719, name: "Greensboro" },
            { id: 1840000659, name: "Greenville" },
            { id: 1840010823, name: "Griffin" },
            { id: 1840133971, name: "Grosse Ile" },
            { id: 1356406239, name: "G\u016brgaon" },
            { id: 1840043425, name: "Hanscom AFB" },
            { id: 1840000119, name: "Harvey" },
            { id: 1840001115, name: "Hastings" },
            { id: 1840004932, name: "Hastings-on-Hudson" },
            { id: 1840029479, name: "Hauula" },
            { id: 1840003513, name: "Hawthorne" },
            { id: 1840018034, name: "Heber" },
            { id: 1840000198, name: "Hebron" },
            { id: 1840005936, name: "Henderson" },
            { id: 1840006037, name: "Herndon" },
            { id: 1840020155, name: "Herriman" },
            { id: 1124001665, name: "Hilton" },
            { id: 1840004281, name: "Hilton" },
            { id: 1840003599, name: "Hoboken" },
            { id: 1840053479, name: "Holliston" },
            { id: 1840015144, name: "Hollywood" },
            { id: 1344982653, name: "Hong Kong" },
            { id: 1840006749, name: "Hopkins" },
            { id: 1840003657, name: "Houston" },
            { id: 1840001778, name: "Howell" },
            { id: 1840020578, name: "Huntington Beach" },
            { id: 1840005081, name: "Huntington Station" },
            { id: 1840005061, name: "Huntsville" },
            { id: 1840007577, name: "Hurst" },
            { id: 1840000607, name: "Independence" },
            { id: 1840017231, name: "Indialantic" },
            { id: 1840030084, name: "Indianapolis" },
            { id: 1840011457, name: "Inglewood" },
            { id: 1840007858, name: "Inver Grove Heights" },
            { id: 1840016723, name: "Irmo" },
            { id: 1840012637, name: "Irving" },
            { id: 1840004335, name: "Jacksonville" },
            { id: 1840015032, name: "Jacksonville Beach" },
            { id: 1840014787, name: "Jersey" },
            { id: 1840008340, name: "Jonesboro" },
            { id: 1840004321, name: "Jordan" },
            { id: 1840017255, name: "Jupiter" },
            { id: 1840012501, name: "Kansas" },
            { id: 1840018738, name: "Kaysville" },
            { id: 1840006324, name: "Keller" },
            { id: 1840014756, name: "Kennesaw" },
            { id: 1840000505, name: "Kensington" },
            { id: 1840007751, name: "Kent" },
            { id: 1840040003, name: "Kentucky" },
            { id: 1840015161, name: "Key West" },
            { id: 1840003298, name: "Knoxville" },
            { id: 1840017524, name: "La Porte" },
            { id: 1840020559, name: "La Quinta" },
            { id: 1840019866, name: "Lacey" },
            { id: 1840020589, name: "Laguna Niguel" },
            { id: 1840011054, name: "Lake Orion" },
            { id: 1840007859, name: "Lakeville" },
            { id: 1840002969, name: "Lancaster" },
            { id: 1840020364, name: "Las Vegas" },
            { id: 1840074639, name: "Latonia" },
            { id: 1840008523, name: "Lawrenceburg" },
            { id: 1840003299, name: "Lawrenceville" },
            { id: 1840015162, name: "Layton" },
            { id: 1840007924, name: "Lewisville" },
            { id: 1840001689, name: "Lexington" },
            { id: 1840015613, name: "Lilburn" },
            { id: 1604415678, name: "Lima" },
            { id: 1840000158, name: "Lincoln" },
            { id: 1840013740, name: "Lithia Springs" },
            { id: 1840015644, name: "Lithonia" },
            { id: 1840020640, name: "Little Elm" },
            { id: 1840003985, name: "Livonia" },
            { id: 1840015705, name: "Locust Grove" },
            { id: 1840006336, name: "Logan" },
            { id: 1840011402, name: "Lombard" },
            { id: 1840000988, name: "Long Beach" },
            { id: 1840053448, name: "Longmeadow" },
            { id: 1840020491, name: "Los Angeles" },
            { id: 1840000964, name: "Louisville" },
            { id: 1840003855, name: "Lynchburg" },
            { id: 1840015922, name: "Lynn Haven" },
            { id: 1840013690, name: "Mableton" },
            { id: 1840009411, name: "Macomb" },
            { id: 1840009485, name: "Macon" },
            { id: 1840000538, name: "Madison" },
            { id: 1840012613, name: "Maineville" },
            { id: 1840000430, name: "Malden" },
            { id: 1840001455, name: "Manchester" },
            { id: 1840005273, name: "Manhasset" },
            { id: 1840001589, name: "Manhattan" },
            { id: 1840008844, name: "Manhattan Beach" },
            { id: 1608254376, name: "Manila" },
            { id: 1840008975, name: "Mankato" },
            { id: 1840019828, name: "Maple Valley" },
            { id: 1840011502, name: "Marblehead" },
            { id: 1840001545, name: "Marietta" },
            { id: 1840017920, name: "Marina del Rey" },
            { id: 1840000220, name: "Marion" },
            { id: 1840008349, name: "Maryville" },
            { id: 1840003104, name: "Mason" },
            { id: 1840015706, name: "McDonough" },
            { id: 1840020657, name: "McKinney" },
            { id: 1840000431, name: "Medford" },
            { id: 1840003986, name: "Melvindale" },
            { id: 1840002422, name: "Memphis" },
            { id: 1840012091, name: "Midvale" },
            { id: 1840001041, name: "Milford" },
            { id: 1840000029, name: "Milton" },
            { id: 1840003046, name: "Milwaukee" },
            { id: 1840003824, name: "Minneapolis" },
            { id: 1840007831, name: "Minnetonka" },
            { id: 1840000053, name: "Minot" },
            { id: 1840122841, name: "Missouri Branch" },
            { id: 1840006009, name: "Mobile" },
            { id: 1840004978, name: "Montebello" },
            { id: 1840019685, name: "Moore" },
            { id: 1840020472, name: "Moorpark" },
            { id: 1840007872, name: "Morgan" },
            { id: 1840000888, name: "Mount Pleasant" },
            { id: 1840000338, name: "Mount Vernon" },
            { id: 1356226629, name: "Mumbai" },
            { id: 1840014495, name: "Murfreesboro" },
            { id: 1840014717, name: "Myrtle Beach" },
            { id: 1840050164, name: "M\u014d\u02bbili\u02bbili" },
            { id: 1840008651, name: "Nashville" },
            { id: 1840014591, name: "New Bern" },
            { id: 1356215164, name: "New Delhi" },
            { id: 1840004850, name: "New Haven" },
            { id: 1840001839, name: "New Orleans" },
            { id: 1840005704, name: "New Windsor" },
            { id: 1840034016, name: "New York" },
            { id: 1840014833, name: "Newnan" },
            { id: 1840002270, name: "Newport" },
            { id: 1840003862, name: "Newport News" },
            { id: 1840015009, name: "Niceville" },
            { id: 1840007767, name: "Norcross" },
            { id: 1840014788, name: "North Augusta" },
            { id: 1840029449, name: "North Druid Hills" },
            { id: 1840069459, name: "North Easton" },
            { id: 1840020363, name: "North Las Vegas" },
            { id: 1840014632, name: "North Little Rock" },
            { id: 1840015120, name: "North Port" },
            { id: 1840007843, name: "North St. Paul" },
            { id: 1840000799, name: "Northfield" },
            { id: 1840000288, name: "Northville" },
            { id: 1840000817, name: "Norwalk" },
            { id: 1840002423, name: "Novi" },
            { id: 1688169087, name: "Novi Sad" },
            { id: 1840011503, name: "Oak Harbor" },
            { id: 1840005246, name: "Oceanside" },
            { id: 1840015098, name: "Ocoee" },
            { id: 1840008120, name: "Ogden" },
            { id: 1840020473, name: "Ojai" },
            { id: 1840061984, name: "Oklahoma" },
            { id: 1840034375, name: "Olde West Chester" },
            { id: 1840019865, name: "Olympia" },
            { id: 1840009315, name: "Omaha" },
            { id: 1840011374, name: "Onsted" },
            { id: 1840003405, name: "Orange" },
            { id: 1840019858, name: "Orting" },
            { id: 1840008896, name: "Ortonville" },
            { id: 1840002392, name: "Osseo" },
            { id: 1840018308, name: "Palm Springs" },
            { id: 1840020502, name: "Palmdale" },
            { id: 1840140102, name: "Palos Verdes Peninsula" },
            { id: 1250015082, name: "Paris" },
            { id: 1840009701, name: "Park" },
            { id: 1840000917, name: "Park Ridge" },
            { id: 1840028462, name: "Parlin" },
            { id: 1840005914, name: "Pasadena" },
            { id: 1840004751, name: "Pawling" },
            { id: 1840089287, name: "Peachtree" },
            { id: 1840015005, name: "Pensacola" },
            { id: 1840000673, name: "Philadelphia" },
            { id: 1840004193, name: "Phoenix" },
            { id: 1840001254, name: "Pittsburgh" },
            { id: 1840001090, name: "Plainfield" },
            { id: 1840009258, name: "Plano" },
            { id: 1840009393, name: "Pleasanton" },
            { id: 1840000767, name: "Plymouth" },
            { id: 1840015143, name: "Pompano Beach" },
            { id: 1840014755, name: "Powder Springs" },
            { id: 1840008956, name: "Prior Lake" },
            { id: 1840003289, name: "Providence" },
            { id: 1840019859, name: "Puyallup" },
            { id: 1840021944, name: "Queen Creek" },
            { id: 1840034002, name: "Queens" },
            { id: 1218441993, name: "Quito" },
            { id: 1840012975, name: "Raleigh" },
            { id: 1840008959, name: "Red Wing" },
            { id: 1840019835, name: "Redmond" },
            { id: 1840020509, name: "Redondo Beach" },
            { id: 1840020121, name: "Reno" },
            { id: 1840019827, name: "Renton" },
            { id: 1840001698, name: "Richmond" },
            { id: 1124364273, name: "Richmond Hill" },
            { id: 1840014913, name: "Richmond Hill" },
            { id: 1840011284, name: "River Forest" },
            { id: 1840000114, name: "Riverdale" },
            { id: 1840003858, name: "Roanoke" },
            { id: 1840000373, name: "Rochester" },
            { id: 1840001375, name: "Rockledge" },
            { id: 1840020717, name: "Rockwall" },
            { id: 1840000349, name: "Rome" },
            { id: 1840003963, name: "Romulus" },
            { id: 1840006054, name: "Rosemount" },
            { id: 1840002541, name: "Roswell" },
            { id: 1840020711, name: "Rowlett" },
            { id: 1840019856, name: "Roy" },
            { id: 1840002430, name: "Royal Oak" },
            { id: 1840000410, name: "Salem" },
            { id: 1840003171, name: "Saline" },
            { id: 1840082816, name: "Salt Lake" },
            { id: 1840015969, name: "San Antonio" },
            { id: 1840037414, name: "San Buenaventura" },
            { id: 1840021990, name: "San Diego" },
            { id: 1840021543, name: "San Francisco" },
            { id: 1840021991, name: "San Marcos" },
            { id: 1840021863, name: "San Marino" },
            { id: 1840024611, name: "San Pedro" },
            { id: 1840021829, name: "Santa Barbara" },
            { id: 1840019103, name: "Santa Cruz" },
            { id: 1840019736, name: "Santa Monica" },
            { id: 1840015988, name: "Sarasota" },
            { id: 1840000369, name: "Saratoga Springs" },
            { id: 1840053670, name: "Saugus" },
            { id: 1840008957, name: "Savage" },
            { id: 1840009477, name: "Savannah" },
            { id: 1840073774, name: "Scott AFB" },
            { id: 1840001157, name: "Scottdale" },
            { id: 1840021117, name: "Seattle" },
            { id: 1840001262, name: "Sharpsburg" },
            { id: 1840004840, name: "Shelton" },
            { id: 1840001864, name: "Sherwood" },
            { id: 1840005845, name: "Silver Spring" },
            { id: 1702341327, name: "Singapore" },
            { id: 1840090595, name: "Sioux" },
            { id: 1840041703, name: "Smithtown" },
            { id: 1840004512, name: "Smyrna" },
            { id: 1840015614, name: "Snellville" },
            { id: 1840021078, name: "Snohomish" },
            { id: 1840015658, name: "Social Circle" },
            { id: 1840001056, name: "Somerville" },
            { id: 1840021385, name: "South Jordan" },
            { id: 1840021552, name: "South San Francisco" },
            { id: 1840008948, name: "South St. Paul" },
            { id: 1840002431, name: "Southfield" },
            { id: 1840022048, name: "Southlake" },
            { id: 1840018451, name: "Spanaway" },
            { id: 1840021398, name: "Spanish Fork" },
            { id: 1840000466, name: "Springfield" },
            { id: 1840004391, name: "Springville" },
            { id: 1840002267, name: "St. Albans" },
            { id: 1840003093, name: "St. Clair Shores" },
            { id: 1840002611, name: "St. Cloud" },
            { id: 1840001651, name: "St. Louis" },
            { id: 1840006484, name: "St. Paul" },
            { id: 1840003461, name: "St. Petersburg" },
            { id: 1840123202, name: "Staten" },
            { id: 1840015801, name: "Statesboro" },
            { id: 1840003094, name: "Sterling Heights" },
            { id: 1840000816, name: "Stillwater" },
            { id: 1840002248, name: "Stockbridge" },
            { id: 1840015645, name: "Stone Mountain" },
            { id: 1840010741, name: "Sunnyside" },
            { id: 1840015616, name: "Suwanee" },
            { id: 1036074917, name: "Sydney" },
            { id: 1840000378, name: "Syracuse" },
            { id: 1840021129, name: "Tacoma" },
            { id: 1840015913, name: "Tallahassee" },
            { id: 1840003228, name: "Tecumseh" },
            { id: 1840021942, name: "Tempe" },
            { id: 1840015708, name: "Texarkana" },
            { id: 1840022005, name: "The Colony" },
            { id: 1840000572, name: "Toledo" },
            { id: 1840022203, name: "Tomball" },
            { id: 1840021356, name: "Tooele" },
            { id: 1840021873, name: "Torrance" },
            { id: 1840001383, name: "Trenton" },
            { id: 1840000521, name: "Troy" },
            { id: 1840022101, name: "Tucson" },
            { id: 1840021533, name: "Turlock" },
            { id: 1840001087, name: "Tyrone" },
            { id: 1840006386, name: "Union" },
            { id: 1840052612, name: "Utah" },
            { id: 1840002417, name: "Utica" },
            { id: 1840021498, name: "Vacaville" },
            { id: 1840011638, name: "Valparaiso" },
            { id: 1840021189, name: "Vancouver" },
            { id: 1840002150, name: "Vienna" },
            { id: 1840015679, name: "Villa Rica" },
            { id: 1840003871, name: "Virginia Beach" },
            { id: 1840000652, name: "Warren" },
            { id: 1840000724, name: "Washington" },
            { id: 1840000305, name: "Waterville" },
            { id: 1840011184, name: "Wauconda" },
            { id: 1840010000, name: "Wayzata" },
            { id: 1840038051, name: "West Bloomfield" },
            { id: 1840001436, name: "West Chester" },
            { id: 1840004852, name: "West Haven" },
            { id: 1840015993, name: "West Palm Beach" },
            { id: 1840011333, name: "Western Springs" },
            { id: 1840000463, name: "Westfield" },
            { id: 1840044788, name: "Westford" },
            { id: 1840037458, name: "Westlake Village" },
            { id: 1840005930, name: "Weston" },
            { id: 1840006923, name: "Whitmore Lake" },
            { id: 1840021878, name: "Whittier" },
            { id: 1840001686, name: "Wichita" },
            { id: 1840005579, name: "Wilmington" },
            { id: 1840017238, name: "Windermere" },
            { id: 1840015966, name: "Winter Park" },
            { id: 1840000407, name: "Wixom" },
            { id: 1840004161, name: "Woodstock" },
            { id: 1840006444, name: "Yorktown" },
            { id: 1840000459, name: "Ypsilanti" },
        ],
    },
};

export const temporaryEmploymentViewData = {
    section1: {
        jobTitles: {
            comparisonChart: {
                categoriesId: [
                    19, 58, 43, 54, 61, 67, 29, 34, 53, 37, 23, 28, 18, 25, 11, 9, 3, 39, 22, 14, 55, 16, 17, 15, 32,
                    20, 60, 45, 35, 10, 31, 12, 5, 48, 38, 8, 6, 7, 1, 30, 2, 36, 26, 49, 62, 51, 27, 4, 56, 59, 47, 41,
                    24,
                ],
                categories: [
                    "Engineering",
                    "Software Development",
                    "Quality Management",
                    "Technician",
                    "Team lead / Technical lead",
                    "Other Occupations",
                    "Manufacturing",
                    "Operations",
                    "Supply Chain Management / Logistics",
                    "Production",
                    "Information Technology",
                    "Middle Management",
                    "Design",
                    "Investors",
                    "Craft Trade Workers",
                    "Consulting",
                    "Automation",
                    "Protection",
                    "Human Resources",
                    "Data Science and Analysis",
                    "Intern",
                    "Top Management",
                    "Founders / Shareholders",
                    "C Level",
                    "Medical / Wellness",
                    "Finance",
                    "Transportation Services",
                    "Research and Development",
                    "Partner",
                    "Corporate Affairs",
                    "Media and Entertainment",
                    "Customer Services",
                    "Associate",
                    "Sales",
                    "Project Management",
                    "Coach / Professor / Mentor",
                    "Board Member",
                    "Business Development",
                    "Accounting",
                    "Marketing",
                    "Business Administration",
                    "Product Development",
                    "Instrumentation",
                    "Self Employed",
                    "University / Academic Affairs",
                    "Student",
                    "Legal Services",
                    "Art",
                    "Trainee",
                    "Sports / Fitness / Trainer",
                    "Retail Banking",
                    "Public Services",
                    "Insurance",
                ],
                stack1: {
                    series: [
                        {
                            data: [
                                20.15, 7.89, 7.79, 6.93, 5.79, 5.03, 4.3, 3.93, 3.86, 3.83, 3.23, 3.03, 2.13, 1.76,
                                1.67, 1.53, 1.43, 1.13, 1.1, 1.07, 0.97, 0.87, 0.83, 0.73, 0.63, 0.63, 0.6, 0.6, 0.57,
                                0.57, 0.47, 0.47, 0.47, 0.43, 0.43, 0.37, 0.37, 0.33, 0.33, 0.3, 0.3, 0.27, 0.27, 0.17,
                                0.1, 0.07, 0.07, 0.07, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    series: [
                        {
                            data: [
                                4.95, 15.43, 0.39, 0.01, 2.44, 3.16, 0, 2.1, 0.19, 0.1, 0.82, 4.24, 1.11, 0.33, 0.06,
                                1.86, 0.07, 0.17, 6.47, 3.84, 0.61, 1.38, 0.79, 0.81, 0.11, 0.99, 0.01, 0.64, 1.45,
                                7.03, 1.48, 5.23, 0.58, 10.1, 0.42, 2.12, 0.79, 3.69, 0.31, 9.59, 0.5, 1.46, 0, 0.33,
                                0.07, 0.08, 0.21, 0.06, 0, 0.36, 0.06, 0, 0.03,
                            ],
                        },
                    ],
                },
            },
            diffChart: {
                series: {
                    data: [
                        -15.2, 7.54, -7.4, -6.92, -3.35, -1.87, -4.3, -1.83, -3.67, -3.73, -2.41, 1.21, -1.02, -1.43,
                        -1.61, 0.33, -1.36, -0.96, 5.37, 2.77, -0.36, 0.51, -0.04, 0.08, -0.52, 0.36, -0.59, 0.04, 0.88,
                        6.46, 1.01, 4.76, 0.11, 9.67, -0.01, 1.75, 0.42, 3.36, -0.02, 9.29, 0.2, 1.19, -0.27, 0.16,
                        -0.03, 0.01, 0.14, -0.01, -0.03, 0.33, 0.03, -0.03, 0,
                    ],
                },
            },
        },
        averageWorkExperience: {
            comparisonChart: {
                categories: ["Current", "Previous", "Total"],
                stack1: {
                    series: {
                        data: [4.76, 14.07, 17.58],
                    },
                },
                stack2: {
                    series: {
                        data: [3.38, 15.97, 18.29],
                    },
                },
                benchmark: [4.63, 9.86, 12.44],
            },
            diffChart: {
                series: {
                    data: [-1.38, 1.9, 0.71],
                },
            },
        },
        averageWorkExperiencePerGender: {
            comparisonChart: {
                categories: ["Male", "Female", "Unknown"],
                stack1: {
                    series: {
                        data: [13.06, 10.93, 12.75],
                    },
                },
                stack2: {
                    series: {
                        data: [12.93, 12.31, 10.89],
                    },
                },
                benchmark: [13.62, 16.54, 19.13],
            },
            diffChart: {
                series: {
                    data: [-0.13, 1.38, -1.86],
                },
            },
        },
        averageWorkExperiencePerDegree: {
            comparisonChart: {
                categories: ["Bachelor's Degree", "Master's Degree", "Doctoral Degree", "Total"],
                stack1: {
                    series: {
                        data: [18, 20.09, 26.19, 21.22],
                    },
                },
                stack2: {
                    series: {
                        data: [19.25, 19.08, 23.63, 20.94],
                    },
                },
                benchmark: [18.83, 17.91, 23.58, 19.78],
            },
            diffChart: {
                series: {
                    data: [1.25, -1.01, -2.56, -0.28],
                },
            },
        },
    },
    section2: {
        previousCompanies: {
            comparisonChart: {
                categories1: [
                    "Us Navy",
                    "Boeing",
                    "United States Marine Corps",
                    "NASA",
                    "United States Air Force",
                    "Lockheed Martin",
                    "Tesla",
                    "Ge Aviation",
                    "Northrop Grumman",
                    "Us Army",
                    "Microsoft",
                    "Nasa Jet Propulsion Laboratory",
                    "Georgia Institute Of Technology",
                    "Apple",
                    "Raytheon",
                    "Intel Corporation",
                    "Blue Origin",
                    "Massachusetts Institute Of Technology",
                    "Google",
                    "Northrop Grumman Corporation",
                    "Nasa Goddard Space Flight Center",
                    "Amazon",
                    "Texas A&M University",
                    "Self-Employed",
                    "Schlumberger",
                    "Nasa",
                    "Embry-Riddle Aeronautical University",
                    "University Of California, Los Angeles",
                    "Nasa Ames Research Center",
                    "Honeywell Aerospace",
                ],
                categories2: [
                    "Google",
                    "Amazon",
                    "Oracle",
                    "Accenture",
                    "Ibm",
                    "Facebook",
                    "Apple",
                    "Yahoo",
                    "Salesforce",
                    "Cisco",
                    "Yahoo!",
                    "Deloitte",
                    "University Of California, Berkeley",
                    "Ey",
                    "Paypal",
                    "Sap",
                    "Stanford University",
                    "Goldman Sachs",
                    "Carnegie Mellon University",
                    "Adobe",
                    "Pwc",
                    "Uc Berkeley",
                    "Uber",
                    "Tata Consultancy Services",
                    "Lynda.Com",
                    "Amazon Web Services (Aws)",
                    "Infosys",
                    "Gartner",
                    "Cisco Systems",
                    "Vmware",
                ],
                stack1: {
                    series: [
                        {
                            data: [
                                1.09, 1, 0.91, 0.87, 0.86, 0.83, 0.75, 0.7, 0.64, 0.59, 0.51, 0.48, 0.45, 0.39, 0.34,
                                0.34, 0.33, 0.3, 0.3, 0.28, 0.27, 0.27, 0.25, 0.25, 0.25, 0.25, 0.25, 0.23, 0.2, 0.2,
                            ],
                        },
                    ],
                },
                stack2: {
                    series: [
                        {
                            data: [
                                1.14, 1.08, 0.72, 0.64, 0.63, 0.56, 0.51, 0.44, 0.4, 0.39, 0.38, 0.38, 0.35, 0.35, 0.32,
                                0.27, 0.26, 0.26, 0.26, 0.25, 0.24, 0.23, 0.23, 0.23, 0.23, 0.22, 0.21, 0.21, 0.21,
                                0.21,
                            ],
                        },
                    ],
                },
            },
        },
        educationDistributionPerCompany: {
            tableChart: {
                tableHead: ["Edu/Stat", "Current", "Previous", "Total"],
                tableBody: [
                    [
                        {
                            data: [
                                "Bachelor's Degree",
                                {
                                    series: [
                                        {
                                            data: [4.25, 3.36],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [14.54, 16.54],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [18, 19.25],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Master's Degree",
                                {
                                    series: [
                                        {
                                            data: [4.34, 3.33],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [16.56, 16.4],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [20.09, 19.08],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Doctoral Degree",
                                {
                                    series: [
                                        {
                                            data: [6.84, 4.78],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [20.48, 19.76],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [26.19, 23.63],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Total",
                                {
                                    series: [
                                        {
                                            data: [4.87, 3.93],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [17.4, 17.74],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [21.22, 20.94],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                ],
            },
        },
    },
    section3: {
        workExperienceCurrent: {
            comparisonChart: {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                stack1: {
                    series: {
                        data: [
                            0, 20.72, 27.04, 12.28, 7.12, 5.88, 5.12, 3.28, 3.13, 2.64, 2.9, 1.73, 1.24, 0.64, 0.75,
                            0.53, 0.56, 0.34, 0.49, 0.15, 1.69,
                        ],
                    },
                },
                stack2: {
                    series: {
                        data: [
                            0.13, 40.28, 20.28, 14.39, 7.49, 4.21, 2.71, 1.71, 1.61, 1.04, 0.78, 0.65, 0.52, 0.34, 0.29,
                            0.37, 0.23, 0.24, 0.24, 0.15, 1.66,
                        ],
                    },
                },
            },
        },
        workExperiencePrevious: {
            comparisonChart: {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                stack1: {
                    series: {
                        data: [
                            0.45, 1.69, 3.16, 3.92, 4.9, 5.99, 6.21, 5.8, 5.31, 4.44, 3.95, 3.88, 4.18, 3.54, 3.16,
                            3.05, 2.3, 2.64, 1.77, 1.51, 19.16,
                        ],
                    },
                },
                stack2: {
                    series: {
                        data: [
                            0.37, 1.43, 2.24, 3.04, 3.33, 4.35, 4.76, 4.5, 5.07, 5.49, 4.66, 4.78, 4.13, 3.46, 3.43,
                            3.2, 2.99, 2.68, 2.13, 2.45, 25.15,
                        ],
                    },
                },
            },
        },
        workExperienceTotal: {
            comparisonChart: {
                categories: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
                stack1: {
                    series: {
                        data: [
                            0, 0.68, 1.62, 1.62, 2.07, 3.05, 4.14, 4.44, 5.08, 5.46, 6.37, 4.78, 5.27, 4.86, 4.33, 3.43,
                            3.62, 3.54, 2.75, 3.24, 28.11,
                        ],
                    },
                },
                stack2: {
                    series: {
                        data: [
                            0.02, 1.87, 1.33, 2.23, 2.6, 2.96, 3.41, 4.11, 4.14, 5.2, 5.07, 4.94, 4.83, 4.24, 4.01,
                            3.85, 3.15, 3.41, 3.12, 2.63, 32.2,
                        ],
                    },
                },
            },
        },
        workExperienceCurrentGrouped: {
            comparisonChart: {
                categories: ["0-3", "3-5", "5-10", "10+"],
                stack1: {
                    series: {
                        data: [60.04, 13, 17.07, 8.12],
                    },
                },
                stack2: {
                    series: {
                        data: [75.08, 11.7, 7.85, 4.69],
                    },
                },
            },
        },
        workExperiencePreviousGrouped: {
            comparisonChart: {
                categories: ["0-3", "3-5", "5-10", "10+"],
                stack1: {
                    series: {
                        data: [9.22, 10.89, 25.71, 45.19],
                    },
                },
                stack2: {
                    series: {
                        data: [7.08, 7.68, 24.48, 54.4],
                    },
                },
            },
        },
        workExperienceTotalGrouped: {
            comparisonChart: {
                categories: ["0-3", "3-5", "5-10", "10+"],
                stack1: {
                    series: {
                        data: [3.92, 5.12, 25.49, 63.93],
                    },
                },
                stack2: {
                    series: {
                        data: [5.45, 5.56, 21.93, 66.38],
                    },
                },
            },
        },
    },
    filters: {
        jobTitles: [
            {
                id: 1,
                name: "Accounting",
            },
            {
                id: 4,
                name: "Art",
            },
            {
                id: 5,
                name: "Associate",
            },
            {
                id: 3,
                name: "Automation",
            },
            {
                id: 6,
                name: "Board Member",
            },
            {
                id: 2,
                name: "Business Administration",
            },
            {
                id: 7,
                name: "Business Development",
            },
            {
                id: 15,
                name: "C Level",
            },
            {
                id: 8,
                name: "Coach / Professor / Mentor",
            },
            {
                id: 9,
                name: "Consulting",
            },
            {
                id: 10,
                name: "Corporate Affairs",
            },
            {
                id: 11,
                name: "Craft Trade Workers",
            },
            {
                id: 12,
                name: "Customer Services",
            },
            {
                id: 14,
                name: "Data Science and Analysis",
            },
            {
                id: 18,
                name: "Design",
            },
            {
                id: 19,
                name: "Engineering",
            },
            {
                id: 20,
                name: "Finance",
            },
            {
                id: 17,
                name: "Founders / Shareholders",
            },
            {
                id: 22,
                name: "Human Resources",
            },
            {
                id: 23,
                name: "Information Technology",
            },
            {
                id: 26,
                name: "Instrumentation",
            },
            {
                id: 24,
                name: "Insurance",
            },
            {
                id: 55,
                name: "Intern",
            },
            {
                id: 25,
                name: "Investors",
            },
            {
                id: 27,
                name: "Legal Services",
            },
            {
                id: 29,
                name: "Manufacturing",
            },
            {
                id: 30,
                name: "Marketing",
            },
            {
                id: 31,
                name: "Media and Entertainment",
            },
            {
                id: 32,
                name: "Medical / Wellness",
            },
            {
                id: 28,
                name: "Middle Management",
            },
            {
                id: 42,
                name: "Military",
            },
            {
                id: 64,
                name: "Movie",
            },
            {
                id: 34,
                name: "Operations",
            },
            {
                id: 67,
                name: "Other Occupations",
            },
            {
                id: 35,
                name: "Partner",
            },
            {
                id: 40,
                name: "Police",
            },
            {
                id: 36,
                name: "Product Development",
            },
            {
                id: 37,
                name: "Production",
            },
            {
                id: 38,
                name: "Project Management",
            },
            {
                id: 39,
                name: "Protection",
            },
            {
                id: 41,
                name: "Public Services",
            },
            {
                id: 43,
                name: "Quality Management",
            },
            {
                id: 44,
                name: "Real Estate",
            },
            {
                id: 45,
                name: "Research and Development",
            },
            {
                id: 46,
                name: "Retail",
            },
            {
                id: 47,
                name: "Retail Banking",
            },
            {
                id: 48,
                name: "Sales",
            },
            {
                id: 49,
                name: "Self Employed",
            },
            {
                id: 50,
                name: "Social Services",
            },
            {
                id: 58,
                name: "Software Development",
            },
            {
                id: 57,
                name: "Sound",
            },
            {
                id: 59,
                name: "Sports / Fitness / Trainer",
            },
            {
                id: 51,
                name: "Student",
            },
            {
                id: 53,
                name: "Supply Chain Management / Logistics",
            },
            {
                id: 61,
                name: "Team lead / Technical lead",
            },
            {
                id: 54,
                name: "Technician",
            },
            {
                id: 16,
                name: "Top Management",
            },
            {
                id: 56,
                name: "Trainee",
            },
            {
                id: 60,
                name: "Transportation Services",
            },
            {
                id: 62,
                name: "University / Academic Affairs",
            },
            {
                id: 63,
                name: "Visual Merchandising",
            },
            {
                id: 65,
                name: "Volunteering",
            },
            {
                id: 66,
                name: "Writer",
            },
            {
                id: 21,
                name: "Franchising",
            },
            {
                id: 52,
                name: "Supervision",
            },
        ],
        schools: [
            {
                id: 11226,
                name: "Adelphi University",
            },
            {
                id: 10445,
                name: "Albertus Magnus College",
            },
            {
                id: 1065,
                name: "American University in Bulgaria",
            },
            {
                id: 9655,
                name: "Anadolu Üniversitesi",
            },
            {
                id: 10234,
                name: "Arizona State University",
            },
            {
                id: 10244,
                name: "Arkansas State University",
            },
            {
                id: 10202,
                name: "Auburn University",
            },
            {
                id: 10673,
                name: "Ball State University",
            },
            {
                id: 522,
                name: "Bangladesh University of Engineering and Technology",
            },
            {
                id: 10921,
                name: "Bard College at Simon's Rock",
            },
            {
                id: 11730,
                name: "Baylor University",
            },
            {
                id: 1381,
                name: "Beijing University of Posts and Telecommunications",
            },
            {
                id: 11895,
                name: "Bellevue College",
            },
            {
                id: 11113,
                name: "Bellevue University",
            },
            {
                id: 18597,
                name: "Berkeley College",
            },
            {
                id: 10880,
                name: "Berklee College of Music",
            },
            {
                id: 10546,
                name: "Berry College",
            },
            {
                id: 10705,
                name: "Bethel University",
            },
            {
                id: 11012,
                name: "Bethel University",
            },
            {
                id: 10301,
                name: "Biola University",
            },
            {
                id: 10588,
                name: "Boise State University",
            },
            {
                id: 10873,
                name: "Boston University",
            },
            {
                id: 10611,
                name: "Bradley University",
            },
            {
                id: 10878,
                name: "Brandeis University",
            },
            {
                id: 11803,
                name: "Brazosport College",
            },
            {
                id: 11816,
                name: "Brigham Young University",
            },
            {
                id: 1214,
                name: "British Columbia Institute of Technology",
            },
            {
                id: 11622,
                name: "Brown University",
            },
            {
                id: 3422,
                name: "Budapesti Muszaki és Gazdaságtudományi Egyetem",
            },
            {
                id: 10272,
                name: "California Institute of Technology",
            },
            {
                id: 10320,
                name: "California Lutheran University",
            },
            {
                id: 10290,
                name: "California State University, Chico",
            },
            {
                id: 10298,
                name: "California State University, Fresno",
            },
            {
                id: 10283,
                name: "California State University, Fullerton",
            },
            {
                id: 10281,
                name: "California State University, Long Beach",
            },
            {
                id: 10302,
                name: "California State University, Los Angeles",
            },
            {
                id: 10278,
                name: "California State University, Northridge",
            },
            {
                id: 6316,
                name: "Calvin University",
            },
            {
                id: 10957,
                name: "Calvin University",
            },
            {
                id: 1221,
                name: "Capilano University",
            },
            {
                id: 1257,
                name: "Carleton University",
            },
            {
                id: 11517,
                name: "Carnegie Mellon University",
            },
            {
                id: 11948,
                name: "Carroll University",
            },
            {
                id: 11401,
                name: "Case Western Reserve University",
            },
            {
                id: 10429,
                name: "Central Connecticut State University",
            },
            {
                id: 10959,
                name: "Central Michigan University",
            },
            {
                id: 11889,
                name: "Central Washington University",
            },
            {
                id: 13894,
                name: "Centrale Lille",
            },
            {
                id: 11906,
                name: "Centralia College",
            },
            {
                id: 2359,
                name: "Ceské vysoké ucení technické v Praze",
            },
            {
                id: 10287,
                name: "Chapman University",
            },
            {
                id: 11896,
                name: "City University of Seattle",
            },
            {
                id: 10319,
                name: "Claremont McKenna College",
            },
            {
                id: 10884,
                name: "Clark University",
            },
            {
                id: 11214,
                name: "Clarkson University",
            },
            {
                id: 11632,
                name: "Clemson University",
            },
            {
                id: 11409,
                name: "Cleveland State University",
            },
            {
                id: 11637,
                name: "Coastal Carolina University",
            },
            {
                id: 11635,
                name: "College of Charleston",
            },
            {
                id: 11131,
                name: "College of Southern Nevada",
            },
            {
                id: 10410,
                name: "Colorado College",
            },
            {
                id: 10417,
                name: "Colorado Mesa University",
            },
            {
                id: 10408,
                name: "Colorado School of Mines",
            },
            {
                id: 10403,
                name: "Colorado State University",
            },
            {
                id: 10413,
                name: "Colorado Technical University",
            },
            {
                id: 18514,
                name: "Columbia University in the City of New York",
            },
            {
                id: 10547,
                name: "Columbus State University",
            },
            {
                id: 1278,
                name: "Concordia University",
            },
            {
                id: 11186,
                name: "Cornell University",
            },
            {
                id: 10053,
                name: "Cranfield University",
            },
            {
                id: 11109,
                name: "Creighton University",
            },
            {
                id: 1242,
                name: "Dalhousie University",
            },
            {
                id: 11137,
                name: "Dartmouth College",
            },
            {
                id: 10600,
                name: "DePaul University",
            },
            {
                id: 10608,
                name: "DeVry University",
            },
            {
                id: 19148,
                name: "Dixie State University",
            },
            {
                id: 1758,
                name: "Dongbei University of Finance and Economics",
            },
            {
                id: 11520,
                name: "Drexel University",
            },
            {
                id: 11337,
                name: "Duke University",
            },
            {
                id: 11527,
                name: "Duquesne University",
            },
            {
                id: 10439,
                name: "Eastern Connecticut State University",
            },
            {
                id: 10775,
                name: "Eastern Kentucky University",
            },
            {
                id: 10472,
                name: "Embry-Riddle Aeronautical University",
            },
            {
                id: 10531,
                name: "Emory University",
            },
            {
                id: 1990,
                name: "Escuela de Ingeniería de Antioquia",
            },
            {
                id: 10431,
                name: "Fairfield University",
            },
            {
                id: 5085,
                name: "Ferdowsi University of Mashhad",
            },
            {
                id: 10962,
                name: "Ferris State University",
            },
            {
                id: 10470,
                name: "Florida Atlantic University",
            },
            {
                id: 10478,
                name: "Florida Gulf Coast University",
            },
            {
                id: 10474,
                name: "Florida Institute of Technology",
            },
            {
                id: 10468,
                name: "Florida International University",
            },
            {
                id: 10491,
                name: "Florida State College at Jacksonville",
            },
            {
                id: 10465,
                name: "Florida State University",
            },
            {
                id: 10918,
                name: "Framingham State University",
            },
            {
                id: 11426,
                name: "Franklin University",
            },
            {
                id: 1843,
                name: "Fudan University",
            },
            {
                id: 11587,
                name: "Geneva College",
            },
            {
                id: 11504,
                name: "George Fox University",
            },
            {
                id: 11842,
                name: "George Mason University",
            },
            {
                id: 10451,
                name: "Georgetown University",
            },
            {
                id: 10528,
                name: "Georgia Institute of Technology",
            },
            {
                id: 10530,
                name: "Georgia State University",
            },
            {
                id: 10321,
                name: "Golden Gate University",
            },
            {
                id: 11887,
                name: "Gonzaga University",
            },
            {
                id: 10237,
                name: "Grand Canyon University",
            },
            {
                id: 351,
                name: "Griffith University",
            },
            {
                id: 10872,
                name: "Harvard University",
            },
            {
                id: 10295,
                name: "Harvey Mudd College",
            },
            {
                id: 10124,
                name: "Heriot-Watt University",
            },
            {
                id: 3171,
                name: "Hochschule Bochum",
            },
            {
                id: 3063,
                name: "Hochschule Bremen",
            },
            {
                id: 10601,
                name: "Illinois Institute of Technology",
            },
            {
                id: 10062,
                name: "Imperial College London",
            },
            {
                id: 10670,
                name: "Indiana University Bloomington",
            },
            {
                id: 2637,
                name: "Institut National des Sciences Appliquées de Lyon",
            },
            {
                id: 10713,
                name: "Iowa State University",
            },
            {
                id: 9664,
                name: "Istanbul Teknik Üniversitesi",
            },
            {
                id: 11206,
                name: "Ithaca College",
            },
            {
                id: 10488,
                name: "Jacksonville University",
            },
            {
                id: 352,
                name: "James Cook University",
            },
            {
                id: 1744,
                name: "Jilin University",
            },
            {
                id: 10747,
                name: "Kansas State University",
            },
            {
                id: 10499,
                name: "Keiser University",
            },
            {
                id: 10532,
                name: "Kennesaw State University",
            },
            {
                id: 10968,
                name: "Kettering University",
            },
            {
                id: 9217,
                name: "Kungliga Tekniska högskolan",
            },
            {
                id: 6266,
                name: "Kyungpook National University",
            },
            {
                id: 11534,
                name: "Lafayette College",
            },
            {
                id: 10388,
                name: "Laguna College of Art and Design",
            },
            {
                id: 10971,
                name: "Lawrence Technological University",
            },
            {
                id: 11775,
                name: "LeTourneau University",
            },
            {
                id: 11521,
                name: "Lehigh University",
            },
            {
                id: 11374,
                name: "Lenoir-Rhyne University",
            },
            {
                id: 11845,
                name: "Liberty University",
            },
            {
                id: 11224,
                name: "Long Island University",
            },
            {
                id: 10800,
                name: "Louisiana State University",
            },
            {
                id: 10805,
                name: "Louisiana Tech University",
            },
            {
                id: 10292,
                name: "Loyola Marymount University",
            },
            {
                id: 10909,
                name: "Massachusetts College of Art and Design",
            },
            {
                id: 10871,
                name: "Massachusetts Institute of Technology",
            },
            {
                id: 1275,
                name: "McGill University",
            },
            {
                id: 10813,
                name: "McNeese State University",
            },
            {
                id: 10536,
                name: "Mercer University",
            },
            {
                id: 18641,
                name: "Metropolitan College of New York",
            },
            {
                id: 10477,
                name: "Miami Dade College",
            },
            {
                id: 10952,
                name: "Michigan State University",
            },
            {
                id: 10953,
                name: "Michigan Technological University",
            },
            {
                id: 11681,
                name: "Middle Tennessee State University",
            },
            {
                id: 470,
                name: "Milli Aviasiya Akademiyasi",
            },
            {
                id: 11941,
                name: "Milwaukee School of Engineering",
            },
            {
                id: 11011,
                name: "Minnesota State University Moorhead",
            },
            {
                id: 11001,
                name: "Minnesota State University, Mankato",
            },
            {
                id: 11032,
                name: "Mississippi State University",
            },
            {
                id: 11055,
                name: "Missouri University of Science and Technology",
            },
            {
                id: 362,
                name: "Monash University",
            },
            {
                id: 11102,
                name: "Montana State University Billings",
            },
            {
                id: 10735,
                name: "Mount Mercy University",
            },
            {
                id: 17785,
                name: "National Defense University",
            },
            {
                id: 3340,
                name: "National Technical University of Athens",
            },
            {
                id: 17643,
                name: "National University",
            },
            {
                id: 11631,
                name: "New England Institute of Technology",
            },
            {
                id: 11153,
                name: "New Jersey Institute of Technology",
            },
            {
                id: 11178,
                name: "New Mexico State University",
            },
            {
                id: 9981,
                name: "New York University Abu Dhabi",
            },
            {
                id: 11338,
                name: "North Carolina State University",
            },
            {
                id: 10876,
                name: "Northeastern University",
            },
            {
                id: 10236,
                name: "Northern Arizona University",
            },
            {
                id: 10599,
                name: "Northern Illinois University",
            },
            {
                id: 10597,
                name: "Northwestern University",
            },
            {
                id: 11828,
                name: "Norwich University",
            },
            {
                id: 10373,
                name: "Notre Dame de Namur University",
            },
            {
                id: 10471,
                name: "Nova Southeastern University",
            },
            {
                id: 10960,
                name: "Oakland University",
            },
            {
                id: 10310,
                name: "Occidental College",
            },
            {
                id: 11476,
                name: "Oklahoma City University",
            },
            {
                id: 11470,
                name: "Oklahoma State University",
            },
            {
                id: 11847,
                name: "Old Dominion University",
            },
            {
                id: 11505,
                name: "Oregon Institute of Technology",
            },
            {
                id: 11493,
                name: "Oregon State University",
            },
            {
                id: 10146,
                name: "Oxford Brookes University",
            },
            {
                id: 10343,
                name: "Pacific Union College",
            },
            {
                id: 11064,
                name: "Park University",
            },
            {
                id: 1374,
                name: "Peking University",
            },
            {
                id: 11516,
                name: "Penn State University",
            },
            {
                id: 11560,
                name: "Pennsylvania College of Technology",
            },
            {
                id: 10521,
                name: "Pensacola State College",
            },
            {
                id: 10286,
                name: "Pepperdine University",
            },
            {
                id: 10312,
                name: "Pitzer College",
            },
            {
                id: 10331,
                name: "Point Loma Nazarene University",
            },
            {
                id: 5319,
                name: "Politecnico di Milano",
            },
            {
                id: 5338,
                name: "Politecnico di Torino",
            },
            {
                id: 10506,
                name: "Polk State College",
            },
            {
                id: 11495,
                name: "Portland State University",
            },
            {
                id: 11770,
                name: "Prairie View A&M University",
            },
            {
                id: 11151,
                name: "Princeton University",
            },
            {
                id: 10669,
                name: "Purdue University",
            },
            {
                id: 10697,
                name: "Purdue University Northwest",
            },
            {
                id: 10064,
                name: "Queen Mary University of London",
            },
            {
                id: 1254,
                name: "Queen's University",
            },
            {
                id: 363,
                name: "RMIT University",
            },
            {
                id: 18326,
                name: "Rasmussen College",
            },
            {
                id: 7645,
                name: "Redeemer's University",
            },
            {
                id: 10412,
                name: "Regis University",
            },
            {
                id: 11194,
                name: "Rensselaer Polytechnic Institute",
            },
            {
                id: 3138,
                name: "Rheinisch-Westfälische Technische Hochschule Aachen",
            },
            {
                id: 7440,
                name: "Rijksuniversiteit Groningen",
            },
            {
                id: 18896,
                name: "Robert Morris University",
            },
            {
                id: 11192,
                name: "Rochester Institute of Technology",
            },
            {
                id: 11104,
                name: "Rocky Mountain College",
            },
            {
                id: 11628,
                name: "Roger Williams University",
            },
            {
                id: 10681,
                name: "Rose-Hulman Institute of Technology",
            },
            {
                id: 3139,
                name: "Ruhr-Universität Bochum",
            },
            {
                id: 10486,
                name: "Saint Leo University",
            },
            {
                id: 18379,
                name: "Saint Louis University",
            },
            {
                id: 10324,
                name: "Saint Mary's College of California",
            },
            {
                id: 10903,
                name: "Salem State University",
            },
            {
                id: 10277,
                name: "San Diego State University",
            },
            {
                id: 10280,
                name: "San Francisco State University",
            },
            {
                id: 10282,
                name: "Santa Clara University",
            },
            {
                id: 10487,
                name: "Santa Fe College",
            },
            {
                id: 11897,
                name: "Seattle Central College",
            },
            {
                id: 11891,
                name: "Seattle Pacific University",
            },
            {
                id: 11886,
                name: "Seattle University",
            },
            {
                id: 11154,
                name: "Seton Hall University",
            },
            {
                id: 5124,
                name: "Shahid Beheshti University",
            },
            {
                id: 1855,
                name: "Shanghai Normal University",
            },
            {
                id: 11667,
                name: "South Dakota School of Mines and Technology",
            },
            {
                id: 11787,
                name: "South Texas College",
            },
            {
                id: 10807,
                name: "Southeastern Louisiana University",
            },
            {
                id: 10348,
                name: "Southern California Institute of Architecture",
            },
            {
                id: 10435,
                name: "Southern Connecticut State University",
            },
            {
                id: 11729,
                name: "Southern Methodist University",
            },
            {
                id: 11139,
                name: "Southern New Hampshire University",
            },
            {
                id: 10756,
                name: "Southwestern College",
            },
            {
                id: 11758,
                name: "Southwestern University",
            },
            {
                id: 11754,
                name: "St. Edward's University",
            },
            {
                id: 8769,
                name: "St. Petersburg State University",
            },
            {
                id: 10265,
                name: "Stanford University",
            },
            {
                id: 11748,
                name: "Stephen F. Austin State University",
            },
            {
                id: 11159,
                name: "Stevens Institute of Technology",
            },
            {
                id: 11522,
                name: "Swarthmore College",
            },
            {
                id: 11191,
                name: "Syracuse University",
            },
            {
                id: 7465,
                name: "Technische Universiteit Delft",
            },
            {
                id: 3086,
                name: "Technische Universität Darmstadt",
            },
            {
                id: 5277,
                name: "Tel Aviv University",
            },
            {
                id: 11519,
                name: "Temple University",
            },
            {
                id: 11725,
                name: "Texas A&M University",
            },
            {
                id: 11764,
                name: "Texas A&M University-Kingsville",
            },
            {
                id: 11736,
                name: "Texas State University",
            },
            {
                id: 11731,
                name: "Texas Tech University",
            },
            {
                id: 18552,
                name: "The Cooper Union for the Advancement of Science and Art",
            },
            {
                id: 18549,
                name: "The Juilliard School",
            },
            {
                id: 11399,
                name: "The Ohio State University",
            },
            {
                id: 10201,
                name: "The University of Alabama",
            },
            {
                id: 1211,
                name: "The University of British Columbia",
            },
            {
                id: 10120,
                name: "The University of Edinburgh",
            },
            {
                id: 2888,
                name: "The University of Georgia",
            },
            {
                id: 16392,
                name: "The University of Queensland",
            },
            {
                id: 11684,
                name: "The University of Tennessee at Chattanooga",
            },
            {
                id: 11734,
                name: "The University of Texas at Arlington",
            },
            {
                id: 11724,
                name: "The University of Texas at Austin",
            },
            {
                id: 11732,
                name: "The University of Texas at Dallas",
            },
            {
                id: 11733,
                name: "The University of Texas at San Antonio",
            },
            {
                id: 5929,
                name: "The University of Tokyo",
            },
            {
                id: 11172,
                name: "Thomas Edison State University",
            },
            {
                id: 1915,
                name: "Tianjin University",
            },
            {
                id: 10206,
                name: "Troy University",
            },
            {
                id: 11223,
                name: "Union College",
            },
            {
                id: 10405,
                name: "United States Air Force Academy",
            },
            {
                id: 10849,
                name: "United States Naval Academy",
            },
            {
                id: 1986,
                name: "Universidad EAFIT",
            },
            {
                id: 9141,
                name: "Universidad Internacional Menéndez Pelayo",
            },
            {
                id: 9135,
                name: "Universidad Politécnica de Madrid",
            },
            {
                id: 2201,
                name: "Universidad Veritas",
            },
            {
                id: 9098,
                name: "Universidad de Córdoba",
            },
            {
                id: 9094,
                name: "Universidad de Granada",
            },
            {
                id: 9095,
                name: "Universidad de Sevilla",
            },
            {
                id: 5238,
                name: "University College Cork",
            },
            {
                id: 10203,
                name: "University of Alabama at Birmingham",
            },
            {
                id: 5126,
                name: "University of Applied Science and Technology",
            },
            {
                id: 10242,
                name: "University of Arkansas",
            },
            {
                id: 7483,
                name: "University of Auckland",
            },
            {
                id: 10438,
                name: "University of Bridgeport",
            },
            {
                id: 10157,
                name: "University of Bristol",
            },
            {
                id: 1200,
                name: "University of Calgary",
            },
            {
                id: 10266,
                name: "University of California, Berkeley",
            },
            {
                id: 10270,
                name: "University of California, Davis",
            },
            {
                id: 10271,
                name: "University of California, Irvine",
            },
            {
                id: 10267,
                name: "University of California, Los Angeles",
            },
            {
                id: 10299,
                name: "University of California, Merced",
            },
            {
                id: 10274,
                name: "University of California, Riverside",
            },
            {
                id: 10269,
                name: "University of California, San Diego",
            },
            {
                id: 10273,
                name: "University of California, Santa Barbara",
            },
            {
                id: 10275,
                name: "University of California, Santa Cruz",
            },
            {
                id: 10048,
                name: "University of Cambridge",
            },
            {
                id: 10467,
                name: "University of Central Florida",
            },
            {
                id: 10595,
                name: "University of Chicago",
            },
            {
                id: 11400,
                name: "University of Cincinnati",
            },
            {
                id: 10402,
                name: "University of Colorado Boulder",
            },
            {
                id: 10406,
                name: "University of Colorado Denver",
            },
            {
                id: 10426,
                name: "University of Connecticut",
            },
            {
                id: 11405,
                name: "University of Dayton",
            },
            {
                id: 10447,
                name: "University of Delaware",
            },
            {
                id: 10404,
                name: "University of Denver",
            },
            {
                id: 10156,
                name: "University of Exeter",
            },
            {
                id: 10464,
                name: "University of Florida",
            },
            {
                id: 10121,
                name: "University of Glasgow",
            },
            {
                id: 10582,
                name: "University of Hawaii at Manoa",
            },
            {
                id: 11727,
                name: "University of Houston",
            },
            {
                id: 10587,
                name: "University of Idaho",
            },
            {
                id: 10598,
                name: "University of Illinois at Chicago",
            },
            {
                id: 10596,
                name: "University of Illinois at Urbana-Champaign",
            },
            {
                id: 10714,
                name: "University of Iowa",
            },
            {
                id: 10746,
                name: "University of Kansas",
            },
            {
                id: 10772,
                name: "University of Kentucky",
            },
            {
                id: 10323,
                name: "University of La Verne",
            },
            {
                id: 10190,
                name: "University of Leeds",
            },
            {
                id: 10773,
                name: "University of Louisville",
            },
            {
                id: 11761,
                name: "University of Mary Hardin-Baylor",
            },
            {
                id: 10844,
                name: "University of Maryland",
            },
            {
                id: 10875,
                name: "University of Massachusetts Amherst",
            },
            {
                id: 10885,
                name: "University of Massachusetts Lowell",
            },
            {
                id: 10469,
                name: "University of Miami",
            },
            {
                id: 10951,
                name: "University of Michigan",
            },
            {
                id: 10958,
                name: "University of Michigan-Dearborn",
            },
            {
                id: 10997,
                name: "University of Minnesota-Twin Cities",
            },
            {
                id: 11033,
                name: "University of Mississippi",
            },
            {
                id: 11108,
                name: "University of Nebraska-Lincoln",
            },
            {
                id: 10437,
                name: "University of New Haven",
            },
            {
                id: 10804,
                name: "University of New Orleans",
            },
            {
                id: 11345,
                name: "University of North Carolina Wilmington",
            },
            {
                id: 11342,
                name: "University of North Carolina at Charlotte",
            },
            {
                id: 11389,
                name: "University of North Dakota",
            },
            {
                id: 11728,
                name: "University of North Texas",
            },
            {
                id: 10715,
                name: "University of Northern Iowa",
            },
            {
                id: 10671,
                name: "University of Notre Dame",
            },
            {
                id: 11494,
                name: "University of Oregon",
            },
            {
                id: 10138,
                name: "University of Oxford",
            },
            {
                id: 11515,
                name: "University of Pennsylvania",
            },
            {
                id: 11518,
                name: "University of Pittsburgh",
            },
            {
                id: 10327,
                name: "University of Redlands",
            },
            {
                id: 11623,
                name: "University of Rhode Island",
            },
            {
                id: 11189,
                name: "University of Rochester",
            },
            {
                id: 10285,
                name: "University of San Francisco",
            },
            {
                id: 10208,
                name: "University of South Alabama",
            },
            {
                id: 10466,
                name: "University of South Florida",
            },
            {
                id: 10139,
                name: "University of Southampton",
            },
            {
                id: 10268,
                name: "University of Southern California",
            },
            {
                id: 10142,
                name: "University of Surrey",
            },
            {
                id: 10141,
                name: "University of Sussex",
            },
            {
                id: 5121,
                name: "University of Tehran",
            },
            {
                id: 11410,
                name: "University of Toledo",
            },
            {
                id: 1251,
                name: "University of Toronto",
            },
            {
                id: 1213,
                name: "University of Victoria",
            },
            {
                id: 11840,
                name: "University of Virginia",
            },
            {
                id: 11882,
                name: "University of Washington",
            },
            {
                id: 10076,
                name: "University of Westminster",
            },
            {
                id: 11928,
                name: "University of Wisconsin-Madison",
            },
            {
                id: 11942,
                name: "University of Wisconsin-Platteville",
            },
            {
                id: 11938,
                name: "University of Wisconsin-Stout",
            },
            {
                id: 342,
                name: "University of Wollongong",
            },
            {
                id: 5353,
                name: "Università degli Studi di Firenze",
            },
            {
                id: 5300,
                name: "Università degli Studi di Modena e Reggio Emilia",
            },
            {
                id: 5361,
                name: "Università degli Studi di Perugia",
            },
            {
                id: 5337,
                name: "Università degli Studi di Torino",
            },
            {
                id: 2918,
                name: "Universität Stuttgart",
            },
            {
                id: 2684,
                name: "Université de Strasbourg",
            },
            {
                id: 11817,
                name: "Utah State University",
            },
            {
                id: 10475,
                name: "Valencia College",
            },
            {
                id: 11677,
                name: "Vanderbilt University",
            },
            {
                id: 11312,
                name: "Vaughn College of Aeronautics and Technology",
            },
            {
                id: 11523,
                name: "Villanova University",
            },
            {
                id: 10696,
                name: "Vincennes University",
            },
            {
                id: 11843,
                name: "Virginia Commonwealth University",
            },
            {
                id: 11841,
                name: "Virginia Polytechnic Institute and State University",
            },
            {
                id: 683,
                name: "Vrije Universiteit Brussel",
            },
            {
                id: 2360,
                name: "Vysoká škola ekonomická v Praze",
            },
            {
                id: 11883,
                name: "Washington State University",
            },
            {
                id: 18376,
                name: "Washington University in St. Louis",
            },
            {
                id: 11849,
                name: "Washington and Lee University",
            },
            {
                id: 11818,
                name: "Weber State University",
            },
            {
                id: 18383,
                name: "Webster University",
            },
            {
                id: 10889,
                name: "Wellesley College",
            },
            {
                id: 10908,
                name: "Wentworth Institute of Technology",
            },
            {
                id: 10428,
                name: "Wesleyan University",
            },
            {
                id: 11531,
                name: "West Chester University of Pennsylvania",
            },
            {
                id: 11908,
                name: "West Virginia University",
            },
            {
                id: 11920,
                name: "West Virginia Wesleyan College",
            },
            {
                id: 10955,
                name: "Western Michigan University",
            },
            {
                id: 10911,
                name: "Western New England University",
            },
            {
                id: 11884,
                name: "Western Washington University",
            },
            {
                id: 11548,
                name: "Wilkes University",
            },
            {
                id: 10881,
                name: "Williams College",
            },
            {
                id: 10449,
                name: "Wilmington University",
            },
            {
                id: 10345,
                name: "Woodbury University",
            },
            {
                id: 10879,
                name: "Worcester Polytechnic Institute",
            },
            {
                id: 11413,
                name: "Xavier University",
            },
            {
                id: 10425,
                name: "Yale University",
            },
            {
                id: 6368,
                name: "Yonsei University",
            },
            {
                id: 1955,
                name: "Zhejiang University",
            },
            {
                id: 1581,
                name: "Zhengzhou University",
            },
            {
                id: 2700,
                name: "École des Hautes Études Commerciales du Nord",
            },
            {
                id: 2620,
                name: "Aalto-yliopisto",
            },
            {
                id: 2413,
                name: "Aarhus Universitet",
            },
            {
                id: 10314,
                name: "Academy of Art University",
            },
            {
                id: 2856,
                name: "Aix-Marseille Université",
            },
            {
                id: 23383,
                name: "Ajou University",
            },
            {
                id: 9644,
                name: "Akdeniz Üniversitesi",
            },
            {
                id: 3752,
                name: "Alliance University",
            },
            {
                id: 10453,
                name: "American University",
            },
            {
                id: 6524,
                name: "American University of Beirut",
            },
            {
                id: 10883,
                name: "Amherst College",
            },
            {
                id: 21751,
                name: "Amity University",
            },
            {
                id: 3459,
                name: "Andhra University",
            },
            {
                id: 11765,
                name: "Angelo State University",
            },
            {
                id: 4110,
                name: "Anna University",
            },
            {
                id: 4122,
                name: "Annamalai University",
            },
            {
                id: 681,
                name: "Antwerp Management School",
            },
            {
                id: 10306,
                name: "ArtCenter College of Design",
            },
            {
                id: 3640,
                name: "Ashoka University",
            },
            {
                id: 8222,
                name: "Asian Institute of Management",
            },
            {
                id: 9460,
                name: "Assumption University",
            },
            {
                id: 10183,
                name: "Aston University",
            },
            {
                id: 8215,
                name: "Ateneo de Manila University",
            },
            {
                id: 3341,
                name: "Athens University of Economics and Business",
            },
            {
                id: 11009,
                name: "Augsburg University",
            },
            {
                id: 10311,
                name: "Azusa Pacific University",
            },
            {
                id: 10888,
                name: "Babson College",
            },
            {
                id: 21600,
                name: "Banasthali Vidyapith",
            },
            {
                id: 3739,
                name: "Bangalore University",
            },
            {
                id: 5278,
                name: "Bar-Ilan University",
            },
            {
                id: 18537,
                name: "Barnard College",
            },
            {
                id: 10828,
                name: "Bates College",
            },
            {
                id: 3265,
                name: "Bauhaus-Universität Weimar",
            },
            {
                id: 1376,
                name: "Beihang University",
            },
            {
                id: 1377,
                name: "Beijing Institute of Technology",
            },
            {
                id: 1389,
                name: "Beijing Language and Culture University",
            },
            {
                id: 1379,
                name: "Beijing Normal University",
            },
            {
                id: 1410,
                name: "Beijing University of Technology",
            },
            {
                id: 640,
                name: "Belarusian State University of Informatics and Radioelectronics",
            },
            {
                id: 10780,
                name: "Bellarmine University",
            },
            {
                id: 20240,
                name: "Ben-Gurion University of the Negev",
            },
            {
                id: 10892,
                name: "Bentley University",
            },
            {
                id: 11030,
                name: "Bethany Lutheran College",
            },
            {
                id: 9631,
                name: "Bilkent Üniversitesi",
            },
            {
                id: 10071,
                name: "Birkbeck, University of London",
            },
            {
                id: 3715,
                name: "Birla Institute of Technology",
            },
            {
                id: 4029,
                name: "Birla Institute of Technology and Science",
            },
            {
                id: 10181,
                name: "Birmingham City University",
            },
            {
                id: 355,
                name: "Bond University",
            },
            {
                id: 10877,
                name: "Boston College",
            },
            {
                id: 10161,
                name: "Bournemouth University",
            },
            {
                id: 10827,
                name: "Bowdoin College",
            },
            {
                id: 10862,
                name: "Bowie State University",
            },
            {
                id: 11403,
                name: "Bowling Green State University",
            },
            {
                id: 10739,
                name: "Briar Cliff University",
            },
            {
                id: 10070,
                name: "Brunel University London",
            },
            {
                id: 11524,
                name: "Bryn Mawr College",
            },
            {
                id: 11525,
                name: "Bucknell University",
            },
            {
                id: 3424,
                name: "Budapesti Corvinus Egyetem",
            },
            {
                id: 4218,
                name: "Bundelkhand University",
            },
            {
                id: 11598,
                name: "Cabrini University",
            },
            {
                id: 2516,
                name: "Cairo University",
            },
            {
                id: 10322,
                name: "California College of the Arts",
            },
            {
                id: 10326,
                name: "California Institute of Integral Studies",
            },
            {
                id: 10303,
                name: "California State University San Marcos",
            },
            {
                id: 10307,
                name: "California State University, Stanislaus",
            },
            {
                id: 11351,
                name: "Campbell University",
            },
            {
                id: 11248,
                name: "Canisius College",
            },
            {
                id: 1408,
                name: "Capital University of Economics and Business",
            },
            {
                id: 10174,
                name: "Cardiff Metropolitan University",
            },
            {
                id: 11943,
                name: "Carthage College",
            },
            {
                id: 1656,
                name: "Central South University",
            },
            {
                id: 1392,
                name: "Central University of Finance and Economics",
            },
            {
                id: 10783,
                name: "Centre College",
            },
            {
                id: 10585,
                name: "Chaminade University of Honolulu",
            },
            {
                id: 11827,
                name: "Champlain College",
            },
            {
                id: 10629,
                name: "Chicago State University",
            },
            {
                id: 1429,
                name: "Chongqing Technology and Business University",
            },
            {
                id: 1424,
                name: "Chongqing University",
            },
            {
                id: 8187,
                name: "Christ the King College",
            },
            {
                id: 3252,
                name: "Christian-Albrechts-Universität zu Kiel",
            },
            {
                id: 9451,
                name: "Chulalongkorn University",
            },
            {
                id: 10065,
                name: "City, University of London",
            },
            {
                id: 11652,
                name: "Claflin University",
            },
            {
                id: 10315,
                name: "Claremont Graduate University",
            },
            {
                id: 10544,
                name: "Clark Atlanta University",
            },
            {
                id: 3795,
                name: "Cochin University of Science and Technology",
            },
            {
                id: 10826,
                name: "Colby College",
            },
            {
                id: 11213,
                name: "Colgate University",
            },
            {
                id: 10612,
                name: "Columbia College Chicago",
            },
            {
                id: 10432,
                name: "Connecticut College",
            },
            {
                id: 3505,
                name: "Cotton University",
            },
            {
                id: 369,
                name: "Curtin University",
            },
            {
                id: 1755,
                name: "Dalian University of Technology",
            },
            {
                id: 11346,
                name: "Davidson College",
            },
            {
                id: 4210,
                name: "Dayalbagh Educational Institute",
            },
            {
                id: 8217,
                name: "De La Salle University",
            },
            {
                id: 10044,
                name: "De Montfort University",
            },
            {
                id: 10677,
                name: "DePauw University",
            },
            {
                id: 11580,
                name: "DeSales University",
            },
            {
                id: 364,
                name: "Deakin University",
            },
            {
                id: 11418,
                name: "Denison University",
            },
            {
                id: 23102,
                name: "Deutsche Sporthochschule Köln",
            },
            {
                id: 3816,
                name: "Devi Ahilya Vishwavidyalaya",
            },
            {
                id: 3593,
                name: "Dharmsinh Desai University",
            },
            {
                id: 11528,
                name: "Dickinson College",
            },
            {
                id: 10623,
                name: "Dominican University",
            },
            {
                id: 380,
                name: "Donau-Universität Krems",
            },
            {
                id: 5693,
                name: "Doshisha University",
            },
            {
                id: 11160,
                name: "Drew University",
            },
            {
                id: 5242,
                name: "Dublin City University",
            },
            {
                id: 6392,
                name: "Duksung Women's University",
            },
            {
                id: 9081,
                name: "Durban University of Technology",
            },
            {
                id: 10098,
                name: "Durham University",
            },
            {
                id: 23862,
                name: "Dún Laoghaire Institute of Art, Design and Technology",
            },
            {
                id: 3096,
                name: "EBS Universität für Wirtschaft und Recht",
            },
            {
                id: 2736,
                name: "ESCP Business School",
            },
            {
                id: 1845,
                name: "East China Normal University",
            },
            {
                id: 10610,
                name: "Eastern Illinois University",
            },
            {
                id: 10961,
                name: "Eastern Michigan University",
            },
            {
                id: 11888,
                name: "Eastern Washington University",
            },
            {
                id: 10128,
                name: "Edinburgh Napier University",
            },
            {
                id: 16415,
                name: "Edith Cowan University",
            },
            {
                id: 9253,
                name: "Eidgenössische Technische Hochschule Zürich",
            },
            {
                id: 8989,
                name: "Ekonomická univerzita v Bratislave",
            },
            {
                id: 10613,
                name: "Elmhurst University",
            },
            {
                id: 11344,
                name: "Elon University",
            },
            {
                id: 10896,
                name: "Emerson College",
            },
            {
                id: 7467,
                name: "Erasmus Universiteit Rotterdam",
            },
            {
                id: 686,
                name: "Erasmushogeschool Brussel",
            },
            {
                id: 2312,
                name: "European University Cyprus",
            },
            {
                id: 6369,
                name: "Ewha Womans University",
            },
            {
                id: 401,
                name: "FH Joanneum",
            },
            {
                id: 3163,
                name: "FOM Hochschule für Oekonomie und Management",
            },
            {
                id: 3267,
                name: "Fachhochschule Erfurt",
            },
            {
                id: 377,
                name: "Fachhochschule Kärnten",
            },
            {
                id: 3148,
                name: "FernUniversität in Hagen",
            },
            {
                id: 10350,
                name: "Fielding Graduate University",
            },
            {
                id: 10483,
                name: "Florida Agricultural and Mechanical University",
            },
            {
                id: 7446,
                name: "Fontys Hogescholen",
            },
            {
                id: 18520,
                name: "Fordham University",
            },
            {
                id: 3019,
                name: "Freie Universität Berlin",
            },
            {
                id: 10344,
                name: "Fresno Pacific University",
            },
            {
                id: 2977,
                name: "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            },
            {
                id: 3263,
                name: "Friedrich-Schiller-Universität Jena",
            },
            {
                id: 1438,
                name: "Fuzhou University",
            },
            {
                id: 9681,
                name: "Galatasaray Üniversitesi",
            },
            {
                id: 21769,
                name: "Galgotias University",
            },
            {
                id: 10456,
                name: "Gallaudet University",
            },
            {
                id: 5253,
                name: "Galway-Mayo Institute of Technology",
            },
            {
                id: 3774,
                name: "Garden City University",
            },
            {
                id: 3107,
                name: "Georg-August-Universität Göttingen",
            },
            {
                id: 10534,
                name: "Georgia Southern University",
            },
            {
                id: 3567,
                name: "Goa University",
            },
            {
                id: 10956,
                name: "Grand Valley State University",
            },
            {
                id: 10717,
                name: "Grinnell College",
            },
            {
                id: 1498,
                name: "Guangxi University",
            },
            {
                id: 3575,
                name: "Gujarat University",
            },
            {
                id: 3952,
                name: "Guru Gobind Singh Indraprastha University",
            },
            {
                id: 4003,
                name: "Guru Nanak Dev University",
            },
            {
                id: 18333,
                name: "Gustavus Adolphus College",
            },
            {
                id: 2623,
                name: "HAAGA-HELIA ammattikorkeakoulu",
            },
            {
                id: 1281,
                name: "HEC Montréal",
            },
            {
                id: 6655,
                name: "HELP University",
            },
            {
                id: 3230,
                name: "HHL",
            },
            {
                id: 9630,
                name: "Hacettepe Üniversitesi",
            },
            {
                id: 7871,
                name: "Hamdard University",
            },
            {
                id: 11219,
                name: "Hamilton College",
            },
            {
                id: 10898,
                name: "Hampshire College",
            },
            {
                id: 11856,
                name: "Hampton University",
            },
            {
                id: 9220,
                name: "Handelshögskolan i Stockholm",
            },
            {
                id: 2404,
                name: "Handelshøjskolen i København",
            },
            {
                id: 7682,
                name: "Handelshøyskolen BI",
            },
            {
                id: 2625,
                name: "Hanken Svenska handelshögskolan",
            },
            {
                id: 6370,
                name: "Hanyang University",
            },
            {
                id: 7441,
                name: "Hanzehogeschool Groningen",
            },
            {
                id: 1559,
                name: "Harbin Institute of Technology",
            },
            {
                id: 11600,
                name: "Harrisburg University of Science and Technology",
            },
            {
                id: 11120,
                name: "Hastings College",
            },
            {
                id: 11533,
                name: "Haverford College",
            },
            {
                id: 11438,
                name: "Heidelberg University",
            },
            {
                id: 2619,
                name: "Helsingin yliopisto",
            },
            {
                id: 10247,
                name: "Hendrix College",
            },
            {
                id: 3161,
                name: "Hochschule Düsseldorf",
            },
            {
                id: 3094,
                name: "Hochschule Fresenius",
            },
            {
                id: 9244,
                name: "Hochschule Luzern",
            },
            {
                id: 3003,
                name: "Hochschule Neu-Ulm",
            },
            {
                id: 3000,
                name: "Hochschule Rosenheim",
            },
            {
                id: 3134,
                name: "Hochschule Wismar",
            },
            {
                id: 2925,
                name: "Hochschule der Medien",
            },
            {
                id: 3123,
                name: "Hochschule für Bildende Künste Braunschweig",
            },
            {
                id: 2939,
                name: "Hochschule für Technik Stuttgart",
            },
            {
                id: 3025,
                name: "Hochschule für Technik und Wirtschaft Berlin",
            },
            {
                id: 3028,
                name: "Hochschule für Wirtschaft und Recht Berlin",
            },
            {
                id: 2986,
                name: "Hochschule für angewandte Wissenschaften München",
            },
            {
                id: 2987,
                name: "Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt",
            },
            {
                id: 3011,
                name: "Hochschule für angewandtes Management",
            },
            {
                id: 11202,
                name: "Hofstra University",
            },
            {
                id: 702,
                name: "Hogeschool Gent",
            },
            {
                id: 7456,
                name: "Hogeschool INHolland",
            },
            {
                id: 7469,
                name: "Hogeschool Rotterdam",
            },
            {
                id: 7478,
                name: "Hogeschool Utrecht",
            },
            {
                id: 7455,
                name: "Hogeschool van Amsterdam",
            },
            {
                id: 10361,
                name: "Holy Names University",
            },
            {
                id: 7473,
                name: "Hotelschool The Hague",
            },
            {
                id: 10454,
                name: "Howard University",
            },
            {
                id: 1607,
                name: "Huazhong University of Science and Technology",
            },
            {
                id: 10897,
                name: "Hult International Business School",
            },
            {
                id: 10293,
                name: "Humboldt State University",
            },
            {
                id: 3020,
                name: "Humboldt-Universität zu Berlin",
            },
            {
                id: 1657,
                name: "Hunan University",
            },
            {
                id: 3453,
                name: "Háskóli Íslands",
            },
            {
                id: 9238,
                name: "Högskolan Väst",
            },
            {
                id: 2422,
                name: "IBA Erhvervsakademi Kolding",
            },
            {
                id: 2691,
                name: "ICN Business School",
            },
            {
                id: 10604,
                name: "Illinois State University",
            },
            {
                id: 10615,
                name: "Illinois Wesleyan University",
            },
            {
                id: 3736,
                name: "Indian Institute of Science",
            },
            {
                id: 3571,
                name: "Indian Institute of Technology Gandhinagar",
            },
            {
                id: 10676,
                name: "Indiana State University",
            },
            {
                id: 11530,
                name: "Indiana University of Pennsylvania",
            },
            {
                id: 3969,
                name: "Indira Gandhi Delhi Technical University for Women",
            },
            {
                id: 2754,
                name: "Institut Catholique de Paris",
            },
            {
                id: 9242,
                name: "Institut de hautes études internationales et du développement",
            },
            {
                id: 7275,
                name: "Institute of Engineering and Technology",
            },
            {
                id: 5958,
                name: "International Christian University",
            },
            {
                id: 3744,
                name: "International Institute of Information Technology Bangalore",
            },
            {
                id: 3175,
                name: "International School of Management",
            },
            {
                id: 3714,
                name: "Islamic University of Science and Technology",
            },
            {
                id: 9700,
                name: "Istanbul Ayvansaray Üniversitesi",
            },
            {
                id: 2705,
                name: "IÉSEG School of Management",
            },
            {
                id: 4301,
                name: "Jadavpur University",
            },
            {
                id: 11846,
                name: "James Madison University",
            },
            {
                id: 4164,
                name: "Jawaharlal Nehru Technological University",
            },
            {
                id: 21779,
                name: "Jaypee Institute of Information Technology",
            },
            {
                id: 1469,
                name: "Jinan University",
            },
            {
                id: 3202,
                name: "Johannes Gutenberg-Universität Mainz",
            },
            {
                id: 11424,
                name: "John Carroll University",
            },
            {
                id: 10362,
                name: "John F. Kennedy University",
            },
            {
                id: 5435,
                name: "Josai International University",
            },
            {
                id: 3088,
                name: "Justus-Liebig-Universität Giessen",
            },
            {
                id: 2805,
                name: "KEDGE Business School",
            },
            {
                id: 10975,
                name: "Kalamazoo College",
            },
            {
                id: 5458,
                name: "Kanda University of International Studies",
            },
            {
                id: 398,
                name: "Karl-Franzens-Universität Graz",
            },
            {
                id: 705,
                name: "Katholieke Universiteit Leuven",
            },
            {
                id: 10184,
                name: "Keele University",
            },
            {
                id: 5930,
                name: "Keio University",
            },
            {
                id: 8918,
                name: "King Saud University",
            },
            {
                id: 10063,
                name: "King's College London",
            },
            {
                id: 10077,
                name: "Kingston University",
            },
            {
                id: 10619,
                name: "Knox College",
            },
            {
                id: 6367,
                name: "Korea University",
            },
            {
                id: 3637,
                name: "Kurukshetra University",
            },
            {
                id: 11550,
                name: "Kutztown University of Pennsylvania",
            },
            {
                id: 6372,
                name: "Kyung Hee University",
            },
            {
                id: 3421,
                name: "Közép-Európai Egyetem",
            },
            {
                id: 365,
                name: "La Trobe University",
            },
            {
                id: 7803,
                name: "Lahore University of Management Sciences",
            },
            {
                id: 10105,
                name: "Lancaster University",
            },
            {
                id: 1459,
                name: "Lanzhou University",
            },
            {
                id: 6525,
                name: "Lebanese American University",
            },
            {
                id: 10194,
                name: "Leeds Beckett University",
            },
            {
                id: 3106,
                name: "Leibniz Universität Hannover",
            },
            {
                id: 10624,
                name: "Lewis University",
            },
            {
                id: 5327,
                name: "Libera Università di Lingue e Comunicazione IULM",
            },
            {
                id: 5257,
                name: "Limerick Institute of Technology",
            },
            {
                id: 18392,
                name: "Lindenwood University",
            },
            {
                id: 10107,
                name: "Liverpool John Moores University",
            },
            {
                id: 10069,
                name: "London Business School",
            },
            {
                id: 10080,
                name: "London Metropolitan University",
            },
            {
                id: 11855,
                name: "Longwood University",
            },
            {
                id: 10737,
                name: "Loras College",
            },
            {
                id: 10043,
                name: "Loughborough University",
            },
            {
                id: 10602,
                name: "Loyola University Chicago",
            },
            {
                id: 2976,
                name: "Ludwig-Maximilians-Universität München",
            },
            {
                id: 9213,
                name: "Lunds Universitet",
            },
            {
                id: 11002,
                name: "Macalester College",
            },
            {
                id: 6618,
                name: "Macau University of Science and Technology",
            },
            {
                id: 340,
                name: "Macquarie University",
            },
            {
                id: 4119,
                name: "Madurai Kamaraj University",
            },
            {
                id: 10716,
                name: "Maharishi International University",
            },
            {
                id: 3797,
                name: "Mahatma Gandhi University",
            },
            {
                id: 9512,
                name: "Mahidol University",
            },
            {
                id: 9214,
                name: "Malmö högskola",
            },
            {
                id: 3753,
                name: "Mangalore University",
            },
            {
                id: 10769,
                name: "Manhattan Christian College",
            },
            {
                id: 3737,
                name: "Manipal Academy of Higher Education",
            },
            {
                id: 11205,
                name: "Marist College",
            },
            {
                id: 9665,
                name: "Marmara Üniversitesi",
            },
            {
                id: 11930,
                name: "Marquette University",
            },
            {
                id: 3244,
                name: "Martin-Luther-Universität Halle-Wittenberg",
            },
            {
                id: 11876,
                name: "Mary Baldwin University",
            },
            {
                id: 18643,
                name: "Marymount Manhattan College",
            },
            {
                id: 3819,
                name: "Maulana Azad National Institute of Technology",
            },
            {
                id: 5254,
                name: "Maynooth University",
            },
            {
                id: 1255,
                name: "McMaster University",
            },
            {
                id: 5957,
                name: "Meiji Gakuin University",
            },
            {
                id: 16001,
                name: "Memorial University of Newfoundland",
            },
            {
                id: 10356,
                name: "Menlo College",
            },
            {
                id: 11356,
                name: "Meredith College",
            },
            {
                id: 11406,
                name: "Miami University",
            },
            {
                id: 11826,
                name: "Middlebury College",
            },
            {
                id: 10067,
                name: "Middlesex University",
            },
            {
                id: 11542,
                name: "Millersville University of Pennsylvania",
            },
            {
                id: 10633,
                name: "Millikin University",
            },
            {
                id: 10325,
                name: "Mills College",
            },
            {
                id: 11156,
                name: "Monmouth University",
            },
            {
                id: 11155,
                name: "Montclair State University",
            },
            {
                id: 2830,
                name: "Montpellier Business School",
            },
            {
                id: 10634,
                name: "Moody Bible Institute",
            },
            {
                id: 11561,
                name: "Moravian College",
            },
            {
                id: 10851,
                name: "Morgan State University",
            },
            {
                id: 10736,
                name: "Morningside College",
            },
            {
                id: 10887,
                name: "Mount Holyoke College",
            },
            {
                id: 1246,
                name: "Mount Saint Vincent University",
            },
            {
                id: 16414,
                name: "Murdoch University",
            },
            {
                id: 2796,
                name: "NEOMA Business School",
            },
            {
                id: 1693,
                name: "Nanjing University",
            },
            {
                id: 1701,
                name: "Nanjing University of Posts and Telecommunications",
            },
            {
                id: 1697,
                name: "Nanjing University of Science and Technology",
            },
            {
                id: 1914,
                name: "Nankai University",
            },
            {
                id: 11672,
                name: "National American University",
            },
            {
                id: 14920,
                name: "National Central University",
            },
            {
                id: 14879,
                name: "National Cheng Kung University",
            },
            {
                id: 14895,
                name: "National Chengchi University",
            },
            {
                id: 9287,
                name: "National Chiao Tung University",
            },
            {
                id: 9281,
                name: "National Chung Cheng University",
            },
            {
                id: 5245,
                name: "National College of Ireland",
            },
            {
                id: 3569,
                name: "National Institute of Design",
            },
            {
                id: 4114,
                name: "National Institute of Technology, Tiruchirappalli",
            },
            {
                id: 3746,
                name: "National Law School of India University",
            },
            {
                id: 14825,
                name: "National Sun Yat-Sen University",
            },
            {
                id: 14894,
                name: "National Taiwan Normal University",
            },
            {
                id: 14893,
                name: "National Taiwan University",
            },
            {
                id: 9288,
                name: "National Tsing Hua University",
            },
            {
                id: 5252,
                name: "National University of Ireland, Galway",
            },
            {
                id: 12939,
                name: "National University of Science and Technology",
            },
            {
                id: 7717,
                name: "National University of Science and Technology",
            },
            {
                id: 9408,
                name: "National Yunlin University of Science and Technology",
            },
            {
                id: 3620,
                name: "Navrachana University",
            },
            {
                id: 11117,
                name: "Nebraska Wesleyan University",
            },
            {
                id: 18515,
                name: "New York University",
            },
            {
                id: 20330,
                name: "Newcastle University",
            },
            {
                id: 3573,
                name: "Nirma University",
            },
            {
                id: 11348,
                name: "North Carolina Agricultural and Technical State University",
            },
            {
                id: 10630,
                name: "North Central College",
            },
            {
                id: 11390,
                name: "North Dakota State University",
            },
            {
                id: 10626,
                name: "North Park University",
            },
            {
                id: 10616,
                name: "Northeastern Illinois University",
            },
            {
                id: 20332,
                name: "Northumbria University",
            },
            {
                id: 10980,
                name: "Northwood University",
            },
            {
                id: 10045,
                name: "Nottingham Trent University",
            },
            {
                id: 7480,
                name: "Nyenrode Business Universiteit",
            },
            {
                id: 685,
                name: "Odisee",
            },
            {
                id: 11404,
                name: "Ohio University",
            },
            {
                id: 10622,
                name: "Olivet Nazarene University",
            },
            {
                id: 1266,
                name: "Ontario Tech University",
            },
            {
                id: 5820,
                name: "Osaka City University",
            },
            {
                id: 4161,
                name: "Osmania University",
            },
            {
                id: 2992,
                name: "Ostbayerische Technische Hochschule Regensburg",
            },
            {
                id: 10332,
                name: "Otis College of Art and Design",
            },
            {
                id: 10753,
                name: "Ottawa University",
            },
            {
                id: 2611,
                name: "Oulun yliopisto",
            },
            {
                id: 11091,
                name: "Ozark Christian College",
            },
            {
                id: 3750,
                name: "PES University",
            },
            {
                id: 18527,
                name: "Pace University",
            },
            {
                id: 3541,
                name: "Panjab University",
            },
            {
                id: 11551,
                name: "Pennsylvania Academy of the Fine Arts",
            },
            {
                id: 4128,
                name: "Periyar University",
            },
            {
                id: 5343,
                name: "Politecnico di Bari",
            },
            {
                id: 10300,
                name: "Pomona College",
            },
            {
                id: 2441,
                name: "Pontificia Universidad Católica Madre y Maestra",
            },
            {
                id: 1321,
                name: "Pontificia Universidad Católica de Chile",
            },
            {
                id: 8021,
                name: "Pontificia Universidad Católica del Perú",
            },
            {
                id: 1030,
                name: "Pontifícia Universidade Católica de Campinas",
            },
            {
                id: 910,
                name: "Pontifícia Universidade Católica de Minas Gerais",
            },
            {
                id: 1021,
                name: "Pontifícia Universidade Católica de São Paulo",
            },
            {
                id: 941,
                name: "Pontifícia Universidade Católica do Paraná",
            },
            {
                id: 983,
                name: "Pontifícia Universidade Católica do Rio Grande do Sul",
            },
            {
                id: 961,
                name: "Pontifícia Universidade Católica do Rio de Janeiro",
            },
            {
                id: 11629,
                name: "Providence College",
            },
            {
                id: 6253,
                name: "Pukyong National University",
            },
            {
                id: 4006,
                name: "Punjab Technical University",
            },
            {
                id: 8673,
                name: "Pushkin State Russian Language Institute",
            },
            {
                id: 1808,
                name: "Qingdao University",
            },
            {
                id: 350,
                name: "Queensland University of Technology",
            },
            {
                id: 3754,
                name: "REVA University",
            },
            {
                id: 4312,
                name: "Rabindra Bharati University",
            },
            {
                id: 21404,
                name: "Rabindranath Tagore University",
            },
            {
                id: 11850,
                name: "Radford University",
            },
            {
                id: 3755,
                name: "Rajiv Gandhi University of Health Sciences",
            },
            {
                id: 11167,
                name: "Ramapo College of New Jersey",
            },
            {
                id: 3822,
                name: "Rani Durgavati Vishwavidyalaya",
            },
            {
                id: 11497,
                name: "Reed College",
            },
            {
                id: 1375,
                name: "Renmin University of China",
            },
            {
                id: 11687,
                name: "Rhodes College",
            },
            {
                id: 5790,
                name: "Ritsumeikan Asia Pacific University",
            },
            {
                id: 10481,
                name: "Rollins College",
            },
            {
                id: 10617,
                name: "Roosevelt University",
            },
            {
                id: 10078,
                name: "Royal College of Art",
            },
            {
                id: 10145,
                name: "Royal Holloway, University of London",
            },
            {
                id: 1258,
                name: "Ryerson University",
            },
            {
                id: 5694,
                name: "Ryukoku University",
            },
            {
                id: 2703,
                name: "SKEMA Business School",
            },
            {
                id: 2945,
                name: "SRH Hochschule Heidelberg",
            },
            {
                id: 11292,
                name: "SUNY Canton",
            },
            {
                id: 11227,
                name: "SUNY Cortland",
            },
            {
                id: 9667,
                name: "Sabanci Üniversitesi",
            },
            {
                id: 9491,
                name: "Saint John's University",
            },
            {
                id: 11536,
                name: "Saint Joseph's University",
            },
            {
                id: 10693,
                name: "Saint Mary's College",
            },
            {
                id: 10632,
                name: "Saint Xavier University",
            },
            {
                id: 11379,
                name: "Salem College",
            },
            {
                id: 5306,
                name: "Sapienza Università di Roma",
            },
            {
                id: 3577,
                name: "Sardar Patel University",
            },
            {
                id: 10533,
                name: "Savannah College of Art and Design",
            },
            {
                id: 3869,
                name: "Savitribai Phule Pune University",
            },
            {
                id: 18536,
                name: "School of Visual Arts",
            },
            {
                id: 6365,
                name: "Seoul National University",
            },
            {
                id: 1806,
                name: "Shandong University",
            },
            {
                id: 1842,
                name: "Shanghai Jiao Tong University",
            },
            {
                id: 1848,
                name: "Shanghai University",
            },
            {
                id: 10193,
                name: "Sheffield Hallam University",
            },
            {
                id: 1769,
                name: "Shenyang University of Technology",
            },
            {
                id: 1468,
                name: "Shenzhen University",
            },
            {
                id: 1889,
                name: "Sichuan Normal University",
            },
            {
                id: 1884,
                name: "Sichuan University",
            },
            {
                id: 1212,
                name: "Simon Fraser University",
            },
            {
                id: 4070,
                name: "Sir Padampat Singhania University",
            },
            {
                id: 11220,
                name: "Skidmore College",
            },
            {
                id: 11552,
                name: "Slippery Rock University of Pennsylvania",
            },
            {
                id: 10886,
                name: "Smith College",
            },
            {
                id: 1078,
                name: "Sofia University St. Kliment Ohridski",
            },
            {
                id: 6366,
                name: "Sogang University",
            },
            {
                id: 10297,
                name: "Sonoma State University",
            },
            {
                id: 5934,
                name: "Sophia University",
            },
            {
                id: 1467,
                name: "South China University of Technology",
            },
            {
                id: 1694,
                name: "Southeast University",
            },
            {
                id: 10541,
                name: "Spelman College",
            },
            {
                id: 4127,
                name: "Sri Ramachandra Institute of Higher Education and Research",
            },
            {
                id: 3987,
                name: "Sri Sri University",
            },
            {
                id: 3464,
                name: "Sri Venkateswara University",
            },
            {
                id: 10861,
                name: "St John's College",
            },
            {
                id: 1245,
                name: "St. Francis Xavier University",
            },
            {
                id: 11215,
                name: "St. John's University",
            },
            {
                id: 11296,
                name: "St. Joseph's College",
            },
            {
                id: 11235,
                name: "St. Lawrence University",
            },
            {
                id: 18187,
                name: "St. Mary's College of Maryland",
            },
            {
                id: 8260,
                name: "St. Paul University Manila",
            },
            {
                id: 1236,
                name: "St. Thomas University",
            },
            {
                id: 10494,
                name: "St. Thomas University",
            },
            {
                id: 10186,
                name: "Staffordshire University",
            },
            {
                id: 9218,
                name: "Stockholms universitet",
            },
            {
                id: 11195,
                name: "Stony Brook University",
            },
            {
                id: 9180,
                name: "Sudan University of Science and Technology",
            },
            {
                id: 1466,
                name: "Sun Yat-Sen University",
            },
            {
                id: 6371,
                name: "Sungkyunkwan University",
            },
            {
                id: 6674,
                name: "Sunway University",
            },
            {
                id: 3876,
                name: "Symbiosis International University",
            },
            {
                id: 14846,
                name: "Tamkang University",
            },
            {
                id: 10689,
                name: "Taylor University",
            },
            {
                id: 2996,
                name: "Technische Hochschule Ingolstadt",
            },
            {
                id: 22937,
                name: "Technische Hochschule Nürnberg Georg Simon Ohm",
            },
            {
                id: 3147,
                name: "Technische Universität Dortmund",
            },
            {
                id: 3224,
                name: "Technische Universität Dresden",
            },
            {
                id: 397,
                name: "Technische Universität Graz",
            },
            {
                id: 2975,
                name: "Technische Universität München",
            },
            {
                id: 5243,
                name: "Technological University Dublin",
            },
            {
                id: 7003,
                name: "Tecnológico de Monterrey",
            },
            {
                id: 11756,
                name: "Texas A&M University-Commerce",
            },
            {
                id: 11739,
                name: "Texas Christian University",
            },
            {
                id: 4002,
                name: "Thapar Institute of Engineering and Technology",
            },
            {
                id: 11565,
                name: "The American College of Financial Services",
            },
            {
                id: 2748,
                name: "The American University of Paris",
            },
            {
                id: 18609,
                name: "The City College of New York",
            },
            {
                id: 11271,
                name: "The College of Saint Rose",
            },
            {
                id: 10012,
                name: "The Emirates Academy of Hospitality Management",
            },
            {
                id: 4039,
                name: "The LNM Institute of Information Technology",
            },
            {
                id: 18523,
                name: "The New School",
            },
            {
                id: 10195,
                name: "The University of Hull",
            },
            {
                id: 10153,
                name: "The University of Law",
            },
            {
                id: 10103,
                name: "The University of Manchester",
            },
            {
                id: 11100,
                name: "The University of Montana",
            },
            {
                id: 10192,
                name: "The University of Sheffield",
            },
            {
                id: 11744,
                name: "The University of Texas Health Science Center at Houston",
            },
            {
                id: 16413,
                name: "The University of Western Australia",
            },
            {
                id: 1228,
                name: "The University of Winnipeg",
            },
            {
                id: 1932,
                name: "Tianjin Foreign Studies University",
            },
            {
                id: 7445,
                name: "Tilburg University",
            },
            {
                id: 6002,
                name: "Tokyo Woman's Christian University",
            },
            {
                id: 1844,
                name: "Tongji University",
            },
            {
                id: 2827,
                name: "Toulouse Business School",
            },
            {
                id: 1373,
                name: "Tsinghua University",
            },
            {
                id: 10874,
                name: "Tufts University",
            },
            {
                id: 10801,
                name: "Tulane University",
            },
            {
                id: 10207,
                name: "Tuskegee University",
            },
            {
                id: 2747,
                name: "Télécom SudParis",
            },
            {
                id: 706,
                name: "UC Leuven-Limburg",
            },
            {
                id: 12112,
                name: "Universidad Alfonso X el Sabio",
            },
            {
                id: 9142,
                name: "Universidad Antonio de Nebrija",
            },
            {
                id: 9136,
                name: "Universidad Autónoma de Madrid",
            },
            {
                id: 9144,
                name: "Universidad CEU San Pablo",
            },
            {
                id: 9137,
                name: "Universidad Carlos III de Madrid",
            },
            {
                id: 9134,
                name: "Universidad Complutense de Madrid",
            },
            {
                id: 1325,
                name: "Universidad Diego Portales",
            },
            {
                id: 9146,
                name: "Universidad Francisco de Vitoria",
            },
            {
                id: 2059,
                name: "Universidad La Gran Colombia",
            },
            {
                id: 2196,
                name: "Universidad Latina de Costa Rica",
            },
            {
                id: 2066,
                name: "Universidad Manuela Beltrán",
            },
            {
                id: 2028,
                name: "Universidad Nacional de Colombia",
            },
            {
                id: 252,
                name: "Universidad Nacional de Córdoba",
            },
            {
                id: 8032,
                name: "Universidad Nacional de Ingeniería",
            },
            {
                id: 288,
                name: "Universidad Nacional de Rosario",
            },
            {
                id: 7984,
                name: "Universidad Nacional del Callao",
            },
            {
                id: 20008,
                name: "Universidad Nueva Esparta",
            },
            {
                id: 9102,
                name: "Universidad Pablo de Olavide",
            },
            {
                id: 8023,
                name: "Universidad Peruana de Ciencias Aplicadas",
            },
            {
                id: 9140,
                name: "Universidad Pontificia Comillas",
            },
            {
                id: 9139,
                name: "Universidad Rey Juan Carlos",
            },
            {
                id: 221,
                name: "Universidad Torcuato di Tella",
            },
            {
                id: 9100,
                name: "Universidad de Almería",
            },
            {
                id: 213,
                name: "Universidad de Buenos Aires",
            },
            {
                id: 19999,
                name: "Universidad de Carabobo",
            },
            {
                id: 1320,
                name: "Universidad de Chile",
            },
            {
                id: 2194,
                name: "Universidad de Costa Rica",
            },
            {
                id: 9099,
                name: "Universidad de Cádiz",
            },
            {
                id: 6835,
                name: "Universidad de Guanajuato",
            },
            {
                id: 2285,
                name: "Universidad de La Habana",
            },
            {
                id: 7005,
                name: "Universidad de Monterrey",
            },
            {
                id: 9153,
                name: "Universidad de Murcia",
            },
            {
                id: 9096,
                name: "Universidad de Málaga",
            },
            {
                id: 9151,
                name: "Universidad de Navarra",
            },
            {
                id: 8079,
                name: "Universidad de Piura",
            },
            {
                id: 9115,
                name: "Universidad de Salamanca",
            },
            {
                id: 8029,
                name: "Universidad de San Martín de Porres",
            },
            {
                id: 9105,
                name: "Universidad de Zaragoza",
            },
            {
                id: 226,
                name: "Universidad del CEMA",
            },
            {
                id: 2031,
                name: "Universidad del Rosario",
            },
            {
                id: 220,
                name: "Universidad del Salvador",
            },
            {
                id: 20036,
                name: "Universidad del Zulia",
            },
            {
                id: 1032,
                name: "Universidade Anhembi Morumbi",
            },
            {
                id: 15387,
                name: "Universidade Católica Portuguesa",
            },
            {
                id: 1025,
                name: "Universidade Cruzeiro do Sul",
            },
            {
                id: 1019,
                name: "Universidade Estadual de Campinas",
            },
            {
                id: 962,
                name: "Universidade Estácio de Sá",
            },
            {
                id: 963,
                name: "Universidade Federal Fluminense",
            },
            {
                id: 892,
                name: "Universidade Federal de Goiás",
            },
            {
                id: 907,
                name: "Universidade Federal de Minas Gerais",
            },
            {
                id: 914,
                name: "Universidade Federal de Ouro Preto",
            },
            {
                id: 953,
                name: "Universidade Federal de Pernambuco",
            },
            {
                id: 1022,
                name: "Universidade Federal de São Carlos",
            },
            {
                id: 1024,
                name: "Universidade Federal de São Paulo",
            },
            {
                id: 938,
                name: "Universidade Federal do Paraná",
            },
            {
                id: 929,
                name: "Universidade Federal do Pará",
            },
            {
                id: 1055,
                name: "Universidade Ibirapuera",
            },
            {
                id: 1029,
                name: "Universidade Metodista de São Paulo",
            },
            {
                id: 946,
                name: "Universidade Norte do Paraná",
            },
            {
                id: 15385,
                name: "Universidade Nova de Lisboa",
            },
            {
                id: 1023,
                name: "Universidade Paulista",
            },
            {
                id: 7344,
                name: "Universidade Politécnica",
            },
            {
                id: 1026,
                name: "Universidade Presbiteriana Mackenzie",
            },
            {
                id: 1043,
                name: "Universidade Santa Cecília",
            },
            {
                id: 1034,
                name: "Universidade São Judas Tadeu",
            },
            {
                id: 923,
                name: "Universidade Vale do Rio Verde",
            },
            {
                id: 1014,
                name: "Universidade da Região de Joinville",
            },
            {
                id: 8377,
                name: "Universidade de Coimbra",
            },
            {
                id: 6616,
                name: "Universidade de Macau",
            },
            {
                id: 1052,
                name: "Universidade de Marília",
            },
            {
                id: 1018,
                name: "Universidade de São Paulo",
            },
            {
                id: 9148,
                name: "Universidade de Vigo",
            },
            {
                id: 1007,
                name: "Universidade do Estado de Santa Catarina",
            },
            {
                id: 8420,
                name: "Universidade do Porto",
            },
            {
                id: 1008,
                name: "Universidade do Vale do Itajaí",
            },
            {
                id: 4353,
                name: "Universitas Udayana",
            },
            {
                id: 8476,
                name: "Universitatea din Bucuresti",
            },
            {
                id: 676,
                name: "Universiteit Antwerpen",
            },
            {
                id: 7466,
                name: "Universiteit Leiden",
            },
            {
                id: 7442,
                name: "Universiteit Maastricht",
            },
            {
                id: 7477,
                name: "Universiteit Utrecht",
            },
            {
                id: 7453,
                name: "Universiteit van Amsterdam",
            },
            {
                id: 6665,
                name: "Universiti Teknologi MARA",
            },
            {
                id: 5241,
                name: "University College Dublin",
            },
            {
                id: 10060,
                name: "University College London",
            },
            {
                id: 10151,
                name: "University for the Creative Arts",
            },
            {
                id: 10123,
                name: "University of Aberdeen",
            },
            {
                id: 1199,
                name: "University of Alberta",
            },
            {
                id: 10243,
                name: "University of Arkansas at Little Rock",
            },
            {
                id: 10158,
                name: "University of Bath",
            },
            {
                id: 10180,
                name: "University of Birmingham",
            },
            {
                id: 10147,
                name: "University of Brighton",
            },
            {
                id: 4298,
                name: "University of Calcutta",
            },
            {
                id: 21362,
                name: "University of Calicut",
            },
            {
                id: 10313,
                name: "University of California, Hastings College of the Law",
            },
            {
                id: 9086,
                name: "University of Cape Town",
            },
            {
                id: 10046,
                name: "University of Derby",
            },
            {
                id: 10079,
                name: "University of East London",
            },
            {
                id: 1887,
                name: "University of Electronic Science and Technology of China",
            },
            {
                id: 10050,
                name: "University of Essex",
            },
            {
                id: 10163,
                name: "University of Gloucestershire",
            },
            {
                id: 10074,
                name: "University of Greenwich",
            },
            {
                id: 1259,
                name: "University of Guelph",
            },
            {
                id: 10051,
                name: "University of Hertfordshire",
            },
            {
                id: 1395,
                name: "University of International Business and Economics",
            },
            {
                id: 1411,
                name: "University of International Relations",
            },
            {
                id: 10143,
                name: "University of Kent",
            },
            {
                id: 3796,
                name: "University of Kerala",
            },
            {
                id: 7610,
                name: "University of Lagos",
            },
            {
                id: 10042,
                name: "University of Leicester",
            },
            {
                id: 5256,
                name: "University of Limerick",
            },
            {
                id: 10104,
                name: "University of Liverpool",
            },
            {
                id: 10075,
                name: "University of London",
            },
            {
                id: 4190,
                name: "University of Lucknow",
            },
            {
                id: 3351,
                name: "University of Macedonia",
            },
            {
                id: 4113,
                name: "University of Madras",
            },
            {
                id: 11051,
                name: "University of Missouri-Kansas City",
            },
            {
                id: 3872,
                name: "University of Mumbai",
            },
            {
                id: 3742,
                name: "University of Mysore",
            },
            {
                id: 11112,
                name: "University of Nebraska at Kearney",
            },
            {
                id: 11110,
                name: "University of Nebraska at Omaha",
            },
            {
                id: 1232,
                name: "University of New Brunswick",
            },
            {
                id: 11138,
                name: "University of New Hampshire",
            },
            {
                id: 11336,
                name: "University of North Carolina at Chapel Hill",
            },
            {
                id: 11341,
                name: "University of North Carolina at Greensboro",
            },
            {
                id: 1260,
                name: "University of Ottawa",
            },
            {
                id: 4265,
                name: "University of Petroleum and Energy Studies",
            },
            {
                id: 10160,
                name: "University of Plymouth",
            },
            {
                id: 11503,
                name: "University of Portland",
            },
            {
                id: 10144,
                name: "University of Portsmouth",
            },
            {
                id: 4030,
                name: "University of Rajasthan",
            },
            {
                id: 10082,
                name: "University of Roehampton",
            },
            {
                id: 10291,
                name: "University of San Diego",
            },
            {
                id: 1382,
                name: "University of Science and Technology Beijing",
            },
            {
                id: 1357,
                name: "University of Science and Technology of China",
            },
            {
                id: 10360,
                name: "University of Silicon Valley",
            },
            {
                id: 358,
                name: "University of South Australia",
            },
            {
                id: 11633,
                name: "University of South Carolina",
            },
            {
                id: 10173,
                name: "University of South Wales",
            },
            {
                id: 20355,
                name: "University of St Andrews",
            },
            {
                id: 10127,
                name: "University of Stirling",
            },
            {
                id: 10125,
                name: "University of Strathclyde",
            },
            {
                id: 10100,
                name: "University of Sunderland",
            },
            {
                id: 343,
                name: "University of Technology Sydney",
            },
            {
                id: 10178,
                name: "University of Wales",
            },
            {
                id: 1252,
                name: "University of Waterloo",
            },
            {
                id: 1261,
                name: "University of Windsor",
            },
            {
                id: 11931,
                name: "University of Wisconsin-Eau Claire",
            },
            {
                id: 11935,
                name: "University of Wisconsin-Oshkosh",
            },
            {
                id: 11937,
                name: "University of Wisconsin-Whitewater",
            },
            {
                id: 10187,
                name: "University of Worcester",
            },
            {
                id: 12928,
                name: "University of Zimbabwe",
            },
            {
                id: 10066,
                name: "University of the Arts London",
            },
            {
                id: 8221,
                name: "University of the Philippines Manila",
            },
            {
                id: 356,
                name: "University of the Sunshine Coast",
            },
            {
                id: 10159,
                name: "University of the West of England",
            },
            {
                id: 9075,
                name: "University of the Witwatersrand",
            },
            {
                id: 5364,
                name: "Università Ca' Foscari di Venezia",
            },
            {
                id: 5323,
                name: "Università Cattolica del Sacro Cuore",
            },
            {
                id: 5334,
                name: "Università Politecnica delle Marche",
            },
            {
                id: 5347,
                name: "Università degli Studi di Cagliari",
            },
            {
                id: 5302,
                name: "Università degli Studi di Ferrara",
            },
            {
                id: 5350,
                name: "Università degli Studi di Messina",
            },
            {
                id: 5363,
                name: "Università degli Studi di Padova",
            },
            {
                id: 5348,
                name: "Università degli Studi di Palermo",
            },
            {
                id: 5305,
                name: "Università degli Studi di Udine",
            },
            {
                id: 5333,
                name: "Università degli Studi di Urbino Carlo Bo",
            },
            {
                id: 5342,
                name: "Università del Salento",
            },
            {
                id: 2982,
                name: "Universität Augsburg",
            },
            {
                id: 2981,
                name: "Universität Bayreuth",
            },
            {
                id: 3144,
                name: "Universität Bielefeld",
            },
            {
                id: 3061,
                name: "Universität Bremen",
            },
            {
                id: 3266,
                name: "Universität Erfurt",
            },
            {
                id: 3067,
                name: "Universität Hamburg",
            },
            {
                id: 3117,
                name: "Universität Hildesheim",
            },
            {
                id: 2922,
                name: "Universität Hohenheim",
            },
            {
                id: 2921,
                name: "Universität Konstanz",
            },
            {
                id: 2919,
                name: "Universität Mannheim",
            },
            {
                id: 2985,
                name: "Universität Passau",
            },
            {
                id: 3150,
                name: "Universität Siegen",
            },
            {
                id: 422,
                name: "Universität Wien",
            },
            {
                id: 9254,
                name: "Universität Zürich",
            },
            {
                id: 3023,
                name: "Universität der Künste Berlin",
            },
            {
                id: 3220,
                name: "Universität des Saarlandes",
            },
            {
                id: 23088,
                name: "Universität zu Köln",
            },
            {
                id: 3253,
                name: "Universität zu Lübeck",
            },
            {
                id: 2706,
                name: "Université Catholique de Lille",
            },
            {
                id: 729,
                name: "Université Catholique de Louvain",
            },
            {
                id: 2644,
                name: "Université Catholique de Lyon",
            },
            {
                id: 1277,
                name: "Université Laval",
            },
            {
                id: 2740,
                name: "Université Paris 2 Panthéon-Assas",
            },
            {
                id: 2728,
                name: "Université Paris Nanterre",
            },
            {
                id: 2737,
                name: "Université Paris-Est Marne-la-Vallée",
            },
            {
                id: 6526,
                name: "Université Saint-Joseph de Beyrouth",
            },
            {
                id: 2816,
                name: "Université Toulouse - Jean Jaurès",
            },
            {
                id: 2840,
                name: "Université d'Angers",
            },
            {
                id: 2681,
                name: "Université d'Orléans",
            },
            {
                id: 2658,
                name: "Université de Bourgogne",
            },
            {
                id: 9241,
                name: "Université de Genève",
            },
            {
                id: 714,
                name: "Université de Liège",
            },
            {
                id: 2801,
                name: "Université de Poitiers",
            },
            {
                id: 1280,
                name: "Université de Sherbrooke",
            },
            {
                id: 2701,
                name: "Université du Littoral Côte d'Opale",
            },
            {
                id: 2358,
                name: "Univerzita Karlova",
            },
            {
                id: 8958,
                name: "Univerzitet u Beogradu",
            },
            {
                id: 8339,
                name: "Uniwersytet Ekonomiczny w Krakowie",
            },
            {
                id: 9231,
                name: "Uppsala Universitet",
            },
            {
                id: 11819,
                name: "Utah Valley University",
            },
            {
                id: 3978,
                name: "Utkal University",
            },
            {
                id: 4274,
                name: "Uttarakhand Technical University",
            },
            {
                id: 10365,
                name: "Vanguard University of Southern California",
            },
            {
                id: 11204,
                name: "Vassar College",
            },
            {
                id: 4108,
                name: "Vellore Institute of Technology",
            },
            {
                id: 698,
                name: "Vesalius College",
            },
            {
                id: 3741,
                name: "Visvesvaraya Technological University",
            },
            {
                id: 703,
                name: "Vlerick Business School",
            },
            {
                id: 7454,
                name: "Vrije Universiteit Amsterdam",
            },
            {
                id: 11272,
                name: "Wagner College",
            },
            {
                id: 11339,
                name: "Wake Forest University",
            },
            {
                id: 5932,
                name: "Waseda University",
            },
            {
                id: 11116,
                name: "Wayne State College",
            },
            {
                id: 10954,
                name: "Wayne State University",
            },
            {
                id: 5258,
                name: "Weizmann Institute of Science",
            },
            {
                id: 10606,
                name: "Western Illinois University",
            },
            {
                id: 10774,
                name: "Western Kentucky University",
            },
            {
                id: 11501,
                name: "Western Oregon University",
            },
            {
                id: 9504,
                name: "Western University",
            },
            {
                id: 1256,
                name: "Western University",
            },
            {
                id: 3141,
                name: "Westfälische Wilhelms-Universität Münster",
            },
            {
                id: 10609,
                name: "Wheaton College",
            },
            {
                id: 1263,
                name: "Wilfrid Laurier University",
            },
            {
                id: 10370,
                name: "William Jessup University",
            },
            {
                id: 424,
                name: "Wirtschaftsuniversität Wien",
            },
            {
                id: 1606,
                name: "Wuhan University",
            },
            {
                id: 10809,
                name: "Xavier University of Louisiana",
            },
            {
                id: 1785,
                name: "Xi'an Jiaotong University",
            },
            {
                id: 1711,
                name: "Xi'an Jiaotong-Liverpool University",
            },
            {
                id: 1437,
                name: "Xiamen University",
            },
            {
                id: 1786,
                name: "Xidian University",
            },
            {
                id: 9671,
                name: "Yeditepe Üniversitesi",
            },
            {
                id: 1253,
                name: "York University",
            },
            {
                id: 2626,
                name: "Yrkeshögskolan Arcada",
            },
            {
                id: 2661,
                name: "École Supérieure de Commerce de Dijon-Bourgogne",
            },
            {
                id: 2731,
                name: "École Supérieure des Sciences Économiques et Commerciales",
            },
            {
                id: 13930,
                name: "École des Ponts ParisTech",
            },
        ],
        languages: [
            {
                id: 9,
                name: "Afrikaans",
            },
            {
                id: 14,
                name: "Albanian",
            },
            {
                id: 20,
                name: "Amharic",
            },
            {
                id: 23,
                name: "Arabic",
            },
            {
                id: 27,
                name: "Armenian",
            },
            {
                id: 41,
                name: "Azerbaijani",
            },
            {
                id: 52,
                name: "Basque",
            },
            {
                id: 56,
                name: "Belarusian",
            },
            {
                id: 58,
                name: "Bengali",
            },
            {
                id: 71,
                name: "Bosnian",
            },
            {
                id: 75,
                name: "Bulgarian",
            },
            {
                id: 80,
                name: "Catalan",
            },
            {
                id: 85,
                name: "Central Khmer",
            },
            {
                id: 95,
                name: "Chinese",
            },
            {
                id: 119,
                name: "Croatian",
            },
            {
                id: 121,
                name: "Czech",
            },
            {
                id: 123,
                name: "Danish",
            },
            {
                id: 136,
                name: "Dutch",
            },
            {
                id: 146,
                name: "English",
            },
            {
                id: 163,
                name: "French",
            },
            {
                id: 177,
                name: "German",
            },
            {
                id: 189,
                name: "Greek, Modern (1453-)",
            },
            {
                id: 192,
                name: "Gujarati",
            },
            {
                id: 196,
                name: "Haitian Creole",
            },
            {
                id: 199,
                name: "Hebrew",
            },
            {
                id: 203,
                name: "Hindi",
            },
            {
                id: 207,
                name: "Hungarian",
            },
            {
                id: 210,
                name: "Icelandic",
            },
            {
                id: 219,
                name: "Indonesian",
            },
            {
                id: 225,
                name: "Iranian languages",
            },
            {
                id: 230,
                name: "Italian",
            },
            {
                id: 231,
                name: "Japanese",
            },
            {
                id: 266,
                name: "Korean",
            },
            {
                id: 291,
                name: "Lithuanian",
            },
            {
                id: 304,
                name: "Luxembourgish",
            },
            {
                id: 306,
                name: "Macedonian",
            },
            {
                id: 313,
                name: "Malayalam",
            },
            {
                id: 325,
                name: "Marathi",
            },
            {
                id: 394,
                name: "Oriya",
            },
            {
                id: 405,
                name: "Panjabi",
            },
            {
                id: 410,
                name: "Persian",
            },
            {
                id: 416,
                name: "Polish",
            },
            {
                id: 417,
                name: "Portuguese",
            },
            {
                id: 428,
                name: "Romanian",
            },
            {
                id: 432,
                name: "Russian",
            },
            {
                id: 435,
                name: "Samaritan Aramaic",
            },
            {
                id: 450,
                name: "Serbian",
            },
            {
                id: 461,
                name: "Sinhalese",
            },
            {
                id: 480,
                name: "Spanish",
            },
            {
                id: 487,
                name: "Swahili",
            },
            {
                id: 489,
                name: "Swedish",
            },
            {
                id: 492,
                name: "Tagalog",
            },
            {
                id: 497,
                name: "Tamil",
            },
            {
                id: 499,
                name: "Telugu",
            },
            {
                id: 502,
                name: "Thai",
            },
            {
                id: 505,
                name: "Tigrinya",
            },
            {
                id: 519,
                name: "Turkish",
            },
            {
                id: 528,
                name: "Ukrainian",
            },
            {
                id: 533,
                name: "Urdu",
            },
            {
                id: 539,
                name: "Vietnamese",
            },
            {
                id: 31,
                name: "Assamese",
            },
            {
                id: 59,
                name: "Berber languages",
            },
            {
                id: 70,
                name: "Bokmål, Norwegian",
            },
            {
                id: 77,
                name: "Burmese",
            },
            {
                id: 113,
                name: "Creoles and pidgins",
            },
            {
                id: 114,
                name: "Creoles and pidgins, English based",
            },
            {
                id: 134,
                name: "Dravidian languages",
            },
            {
                id: 151,
                name: "Estonian",
            },
            {
                id: 159,
                name: "Finnish",
            },
            {
                id: 169,
                name: "Gaelic",
            },
            {
                id: 171,
                name: "Galician",
            },
            {
                id: 181,
                name: "Germanic languages",
            },
            {
                id: 212,
                name: "Igbo",
            },
            {
                id: 226,
                name: "Irish",
            },
            {
                id: 242,
                name: "Kannada",
            },
            {
                id: 249,
                name: "Kashmiri",
            },
            {
                id: 258,
                name: "Kinyarwanda",
            },
            {
                id: 265,
                name: "Konkani",
            },
            {
                id: 281,
                name: "Lao",
            },
            {
                id: 282,
                name: "Latin",
            },
            {
                id: 290,
                name: "Lingala",
            },
            {
                id: 311,
                name: "Malagasy",
            },
            {
                id: 312,
                name: "Malay",
            },
            {
                id: 328,
                name: "Marwari",
            },
            {
                id: 358,
                name: "Nepali",
            },
            {
                id: 372,
                name: "Norwegian",
            },
            {
                id: 373,
                name: "Norwegian Bokmål",
            },
            {
                id: 440,
                name: "Sanskrit",
            },
            {
                id: 453,
                name: "Shona",
            },
            {
                id: 459,
                name: "Sindhi",
            },
            {
                id: 467,
                name: "Slovak",
            },
            {
                id: 468,
                name: "Slovenian",
            },
            {
                id: 516,
                name: "Tswana",
            },
            {
                id: 524,
                name: "Twi",
            },
            {
                id: 535,
                name: "Uzbek",
            },
            {
                id: 547,
                name: "Western Frisian",
            },
            {
                id: 557,
                name: "Yoruba",
            },
        ],
        cities: [
            {
                id: 1276805572,
                name: "Aachen",
            },
            {
                id: 1840017917,
                name: "Acton",
            },
            {
                id: 1036538171,
                name: "Adelaide",
            },
            {
                id: 1356135122,
                name: "Agra",
            },
            {
                id: 1356304381,
                name: "Ahmedabad",
            },
            {
                id: 1124382916,
                name: "Ajax",
            },
            {
                id: 1840000791,
                name: "Akron",
            },
            {
                id: 1840000417,
                name: "Albany",
            },
            {
                id: 1840006706,
                name: "Albertville",
            },
            {
                id: 1840019176,
                name: "Albuquerque",
            },
            {
                id: 1356065718,
                name: "Allahābād",
            },
            {
                id: 1840017918,
                name: "Altadena",
            },
            {
                id: 1840014214,
                name: "Alva",
            },
            {
                id: 1840019322,
                name: "Anaheim",
            },
            {
                id: 1840003172,
                name: "Ann Arbor",
            },
            {
                id: 1056168623,
                name: "Antwerp",
            },
            {
                id: 1840006768,
                name: "Apple Valley",
            },
            {
                id: 1840017648,
                name: "Aptos",
            },
            {
                id: 1840002383,
                name: "Arcadia",
            },
            {
                id: 1840002413,
                name: "Arlington",
            },
            {
                id: 1372105783,
                name: "Ashbourne",
            },
            {
                id: 1840013411,
                name: "Asheville",
            },
            {
                id: 1840000510,
                name: "Athens",
            },
            {
                id: 1840004026,
                name: "Atlanta",
            },
            {
                id: 1840000319,
                name: "Auburn",
            },
            {
                id: 1840000302,
                name: "Augusta",
            },
            {
                id: 1840003306,
                name: "Austin",
            },
            {
                id: 1840000641,
                name: "Avon",
            },
            {
                id: 1276844408,
                name: "Bad Aibling",
            },
            {
                id: 1276292004,
                name: "Bad Sassendorf",
            },
            {
                id: 1372885521,
                name: "Baile Átha Luain",
            },
            {
                id: 1840013060,
                name: "Bakersfield",
            },
            {
                id: 1840012840,
                name: "Baldwin Park",
            },
            {
                id: 1840001592,
                name: "Baltimore",
            },
            {
                id: 1356410365,
                name: "Bangalore",
            },
            {
                id: 1840052424,
                name: "Bar Harbor",
            },
            {
                id: 1724594040,
                name: "Barcelona",
            },
            {
                id: 1840013941,
                name: "Baton Rouge",
            },
            {
                id: 1840003176,
                name: "Battle Creek",
            },
            {
                id: 1840002563,
                name: "Beaverton",
            },
            {
                id: 1840000308,
                name: "Bellevue",
            },
            {
                id: 1840007406,
                name: "Bellflower",
            },
            {
                id: 1840003095,
                name: "Belmont",
            },
            {
                id: 1076001376,
                name: "Belo Horizonte",
            },
            {
                id: 1840007463,
                name: "Belton",
            },
            {
                id: 1840018618,
                name: "Bend",
            },
            {
                id: 1840007137,
                name: "Bennington",
            },
            {
                id: 1276002149,
                name: "Bergheim",
            },
            {
                id: 1840007450,
                name: "Berkeley",
            },
            {
                id: 1276451290,
                name: "Berlin",
            },
            {
                id: 1756374318,
                name: "Bern",
            },
            {
                id: 1840003484,
                name: "Berwick",
            },
            {
                id: 1840000986,
                name: "Bethlehem",
            },
            {
                id: 1840007451,
                name: "Beverly Hills",
            },
            {
                id: 1356015758,
                name: "Bhopāl",
            },
            {
                id: 1276112885,
                name: "Bielefeld",
            },
            {
                id: 1840019119,
                name: "Big Bear Lake",
            },
            {
                id: 1840006446,
                name: "Blacksburg",
            },
            {
                id: 1840002970,
                name: "Bloomington",
            },
            {
                id: 1276807850,
                name: "Bonn",
            },
            {
                id: 1840018454,
                name: "Bonney Lake",
            },
            {
                id: 1840000455,
                name: "Boston",
            },
            {
                id: 1840018419,
                name: "Bothell",
            },
            {
                id: 1276473363,
                name: "Bottrop",
            },
            {
                id: 1250693512,
                name: "Bourg-la-Reine",
            },
            {
                id: 1840018509,
                name: "Bozeman",
            },
            {
                id: 1076474746,
                name: "Brasil",
            },
            {
                id: 1076000641,
                name: "Brasília",
            },
            {
                id: 1840019323,
                name: "Brea",
            },
            {
                id: 1036192929,
                name: "Brisbane",
            },
            {
                id: 1840033999,
                name: "Bronx",
            },
            {
                id: 1840000592,
                name: "Brooklyn",
            },
            {
                id: 1840001500,
                name: "Brownsville",
            },
            {
                id: 1840009730,
                name: "Bruceville",
            },
            {
                id: 1056469830,
                name: "Brussels",
            },
            {
                id: 1348611435,
                name: "Budapest",
            },
            {
                id: 1840019324,
                name: "Buena Park",
            },
            {
                id: 1840007022,
                name: "Burbank",
            },
            {
                id: 1840002862,
                name: "Burke",
            },
            {
                id: 1124802540,
                name: "Burlington",
            },
            {
                id: 1076562452,
                name: "Caatiba",
            },
            {
                id: 1840019234,
                name: "Calabasas",
            },
            {
                id: 1124070007,
                name: "Caledon",
            },
            {
                id: 1124690423,
                name: "Calgary",
            },
            {
                id: 1840000512,
                name: "Canton",
            },
            {
                id: 1840015091,
                name: "Cape Canaveral",
            },
            {
                id: 1840018959,
                name: "Capitola",
            },
            {
                id: 1840019352,
                name: "Carlsbad",
            },
            {
                id: 1840018421,
                name: "Carnation",
            },
            {
                id: 1840010169,
                name: "Carol Stream",
            },
            {
                id: 1840010149,
                name: "Carpentersville",
            },
            {
                id: 1840000201,
                name: "Carson",
            },
            {
                id: 1840022826,
                name: "Castaic",
            },
            {
                id: 1840017620,
                name: "Castro Valley",
            },
            {
                id: 1076809957,
                name: "Caxias do Sul",
            },
            {
                id: 1840002624,
                name: "Cedar Grove",
            },
            {
                id: 1076117670,
                name: "Cerquilho Velho",
            },
            {
                id: 1840019215,
                name: "Cerritos",
            },
            {
                id: 1840004916,
                name: "Chappaqua",
            },
            {
                id: 1840003107,
                name: "Charlotte",
            },
            {
                id: 1840014569,
                name: "Chattanooga",
            },
            {
                id: 1826876670,
                name: "Chelmsford",
            },
            {
                id: 1276519956,
                name: "Chemnitz",
            },
            {
                id: 1356374944,
                name: "Chennai",
            },
            {
                id: 1840000494,
                name: "Chicago",
            },
            {
                id: 1840007024,
                name: "Chicago Heights",
            },
            {
                id: 1840019114,
                name: "Chino",
            },
            {
                id: 1840019115,
                name: "Chino Hills",
            },
            {
                id: 1840003814,
                name: "Cincinnati",
            },
            {
                id: 1840018845,
                name: "Citrus Heights",
            },
            {
                id: 1840000261,
                name: "Claremont",
            },
            {
                id: 1840003537,
                name: "Cliffside Park",
            },
            {
                id: 1372974181,
                name: "Clonmel",
            },
            {
                id: 1840015092,
                name: "Cocoa",
            },
            {
                id: 1840015093,
                name: "Cocoa Beach",
            },
            {
                id: 1840005971,
                name: "College Park",
            },
            {
                id: 1840013083,
                name: "College Station",
            },
            {
                id: 1276015998,
                name: "Cologne",
            },
            {
                id: 1840000262,
                name: "Columbia",
            },
            {
                id: 1840001715,
                name: "Columbus",
            },
            {
                id: 1840011429,
                name: "Compton",
            },
            {
                id: 1840002747,
                name: "Concord",
            },
            {
                id: 1840019439,
                name: "Coppell",
            },
            {
                id: 1840019538,
                name: "Copperas Cove",
            },
            {
                id: 1372221853,
                name: "Cork",
            },
            {
                id: 1840000264,
                name: "Corona",
            },
            {
                id: 1840019718,
                name: "Corpus Christi",
            },
            {
                id: 1840019505,
                name: "Corsicana",
            },
            {
                id: 1840017381,
                name: "Corvallis",
            },
            {
                id: 1840019326,
                name: "Costa Mesa",
            },
            {
                id: 1840019241,
                name: "Covina",
            },
            {
                id: 1840001330,
                name: "Crawford",
            },
            {
                id: 1036587398,
                name: "Crows Nest",
            },
            {
                id: 1840000506,
                name: "Crystal Lake",
            },
            {
                id: 1840007382,
                name: "Culver",
            },
            {
                id: 1840018950,
                name: "Cupertino",
            },
            {
                id: 1356872061,
                name: "Cuttack",
            },
            {
                id: 1840013004,
                name: "Cypress",
            },
            {
                id: 1840000364,
                name: "Dallas",
            },
            {
                id: 1840018926,
                name: "Daly City",
            },
            {
                id: 1036497565,
                name: "Darwin",
            },
            {
                id: 1840000356,
                name: "Davis",
            },
            {
                id: 1840015068,
                name: "Daytona Beach",
            },
            {
                id: 1840015070,
                name: "DeBary",
            },
            {
                id: 1840003969,
                name: "Dearborn",
            },
            {
                id: 1840003970,
                name: "Dearborn Heights",
            },
            {
                id: 1528448452,
                name: "Delft",
            },
            {
                id: 1356121047,
                name: "Delhi",
            },
            {
                id: 1840003714,
                name: "Denver",
            },
            {
                id: 1840007069,
                name: "Des Moines",
            },
            {
                id: 1840007032,
                name: "Des Plaines",
            },
            {
                id: 1840003971,
                name: "Detroit",
            },
            {
                id: 1076867279,
                name: "Diadema",
            },
            {
                id: 1372576120,
                name: "Donegal",
            },
            {
                id: 1840011404,
                name: "Downers Grove",
            },
            {
                id: 1840003340,
                name: "Downey",
            },
            {
                id: 1276561036,
                name: "Dresden",
            },
            {
                id: 1752780088,
                name: "Drottningholm",
            },
            {
                id: 1840019246,
                name: "Duarte",
            },
            {
                id: 1372595407,
                name: "Dublin",
            },
            {
                id: 1840001321,
                name: "Dublin",
            },
            {
                id: 1276977172,
                name: "Duisburg",
            },
            {
                id: 1840007685,
                name: "Duluth",
            },
            {
                id: 1276615258,
                name: "Düsseldorf",
            },
            {
                id: 1840003101,
                name: "East Lansing",
            },
            {
                id: 1840099147,
                name: "East Titusville",
            },
            {
                id: 1840058058,
                name: "Eastchester",
            },
            {
                id: 1840000077,
                name: "Edinburg",
            },
            {
                id: 1840012021,
                name: "Edison",
            },
            {
                id: 1840027133,
                name: "Edmonds",
            },
            {
                id: 1528636212,
                name: "Eindhoven",
            },
            {
                id: 1840150306,
                name: "El Adobe",
            },
            {
                id: 1840020275,
                name: "El Cerrito",
            },
            {
                id: 1840017565,
                name: "El Dorado Hills",
            },
            {
                id: 1840020480,
                name: "El Monte",
            },
            {
                id: 1840001765,
                name: "El Paso",
            },
            {
                id: 1840020245,
                name: "Elk Grove",
            },
            {
                id: 1840011271,
                name: "Elk Grove Village",
            },
            {
                id: 1840005801,
                name: "Elkridge",
            },
            {
                id: 1840008142,
                name: "Elmhurst",
            },
            {
                id: 1840003546,
                name: "Englewood",
            },
            {
                id: 1840000478,
                name: "Erie",
            },
            {
                id: 1840012032,
                name: "Eustis",
            },
            {
                id: 1840008129,
                name: "Evanston",
            },
            {
                id: 1840000425,
                name: "Everett",
            },
            {
                id: 1840000177,
                name: "Fargo",
            },
            {
                id: 1356078517,
                name: "Faridābād",
            },
            {
                id: 1840002441,
                name: "Farmington",
            },
            {
                id: 1840020335,
                name: "Flagstaff",
            },
            {
                id: 1076140504,
                name: "Florianópolis",
            },
            {
                id: 1840004867,
                name: "Florida",
            },
            {
                id: 1840020151,
                name: "Fort Collins",
            },
            {
                id: 1840020696,
                name: "Fort Worth",
            },
            {
                id: 1840020575,
                name: "Fountain Valley",
            },
            {
                id: 1840002125,
                name: "Frankfort",
            },
            {
                id: 1276054552,
                name: "Frankfurt",
            },
            {
                id: 1840005256,
                name: "Franklin Square",
            },
            {
                id: 1840000653,
                name: "Fremont",
            },
            {
                id: 1840020654,
                name: "Frisco",
            },
            {
                id: 1840000253,
                name: "Fullerton",
            },
            {
                id: 1840004468,
                name: "Gainesville",
            },
            {
                id: 1276544473,
                name: "Garching bei München",
            },
            {
                id: 1840008311,
                name: "Garden Grove",
            },
            {
                id: 1840000004,
                name: "Gardena",
            },
            {
                id: 1840016033,
                name: "Gatesville",
            },
            {
                id: 1840000393,
                name: "Geneva",
            },
            {
                id: 1356691871,
                name: "Ghāziābād",
            },
            {
                id: 1840006358,
                name: "Gilbert",
            },
            {
                id: 1840011405,
                name: "Glen Ellyn",
            },
            {
                id: 1840003037,
                name: "Glendale",
            },
            {
                id: 1840012361,
                name: "Golden",
            },
            {
                id: 1840001783,
                name: "Goleta",
            },
            {
                id: 1040184559,
                name: "Graz",
            },
            {
                id: 1372569009,
                name: "Greystones",
            },
            {
                id: 1840020467,
                name: "Guadalupe",
            },
            {
                id: 1076001461,
                name: "Guarulhos",
            },
            {
                id: 1124968815,
                name: "Guelph",
            },
            {
                id: 1356091187,
                name: "Gulbarga",
            },
            {
                id: 1840011152,
                name: "Gurnee",
            },
            {
                id: 1356406239,
                name: "Gūrgaon",
            },
            {
                id: 1840017921,
                name: "Hacienda Heights",
            },
            {
                id: 1840013556,
                name: "Half Moon",
            },
            {
                id: 1276905869,
                name: "Hannöver",
            },
            {
                id: 1840017458,
                name: "Harbor",
            },
            {
                id: 1840021036,
                name: "Harlingen",
            },
            {
                id: 1840001288,
                name: "Harrisburg",
            },
            {
                id: 1840004921,
                name: "Hartsdale",
            },
            {
                id: 1840013614,
                name: "Hartwell",
            },
            {
                id: 1840003513,
                name: "Hawthorne",
            },
            {
                id: 1840001236,
                name: "Haysville",
            },
            {
                id: 1840001997,
                name: "Hayward",
            },
            {
                id: 1840020487,
                name: "Hermosa Beach",
            },
            {
                id: 1840006037,
                name: "Herndon",
            },
            {
                id: 1840002334,
                name: "Hewitt",
            },
            {
                id: 1840012758,
                name: "Hidalgo",
            },
            {
                id: 1840014479,
                name: "High Point",
            },
            {
                id: 1528160832,
                name: "Hilversum",
            },
            {
                id: 1840003599,
                name: "Hoboken",
            },
            {
                id: 1840015144,
                name: "Hollywood",
            },
            {
                id: 1344982653,
                name: "Hong Kong",
            },
            {
                id: 1840006934,
                name: "Hopkinton",
            },
            {
                id: 1036322611,
                name: "Hornsby",
            },
            {
                id: 1840003657,
                name: "Houston",
            },
            {
                id: 1840020578,
                name: "Huntington Beach",
            },
            {
                id: 1840019235,
                name: "Huntington Park",
            },
            {
                id: 1356871768,
                name: "Hyderābād",
            },
            {
                id: 1840030084,
                name: "Indianapolis",
            },
            {
                id: 1356402812,
                name: "Indore",
            },
            {
                id: 1840011457,
                name: "Inglewood",
            },
            {
                id: 1276803150,
                name: "Ingolstadt",
            },
            {
                id: 1840014344,
                name: "Irvine",
            },
            {
                id: 1840018422,
                name: "Issaquah",
            },
            {
                id: 1792756324,
                name: "Istanbul",
            },
            {
                id: 1840000442,
                name: "Ithaca",
            },
            {
                id: 1840004335,
                name: "Jacksonville",
            },
            {
                id: 1840014787,
                name: "Jersey",
            },
            {
                id: 1050244487,
                name: "Jessore",
            },
            {
                id: 1710550792,
                name: "Johannesburg",
            },
            {
                id: 1840043419,
                name: "Jurupa Valley",
            },
            {
                id: 1752079041,
                name: "Jönköping",
            },
            {
                id: 1840008260,
                name: "Kankakee",
            },
            {
                id: 1840012501,
                name: "Kansas",
            },
            {
                id: 1158820647,
                name: "Keelung",
            },
            {
                id: 1840001428,
                name: "Kennett Square",
            },
            {
                id: 1840007751,
                name: "Kent",
            },
            {
                id: 1840015049,
                name: "Keystone Heights",
            },
            {
                id: 1372940357,
                name: "Kildare",
            },
            {
                id: 1372778543,
                name: "Kilkenny",
            },
            {
                id: 1840020854,
                name: "Killeen",
            },
            {
                id: 1840015109,
                name: "Kissimmee",
            },
            {
                id: 1124158530,
                name: "Kitchener",
            },
            {
                id: 1356274846,
                name: "Kochi",
            },
            {
                id: 1840013844,
                name: "Kokomo",
            },
            {
                id: 1356060520,
                name: "Kolkāta",
            },
            {
                id: 1840004199,
                name: "Kyle",
            },
            {
                id: 1840028246,
                name: "La Crescenta",
            },
            {
                id: 1840020585,
                name: "La Habra",
            },
            {
                id: 1840020625,
                name: "La Mesa",
            },
            {
                id: 1840020512,
                name: "La Mirada",
            },
            {
                id: 1840020513,
                name: "La Puente",
            },
            {
                id: 1840007897,
                name: "Lafayette",
            },
            {
                id: 1840013957,
                name: "Laguna Beach",
            },
            {
                id: 1840020588,
                name: "Laguna Hills",
            },
            {
                id: 1840020589,
                name: "Laguna Niguel",
            },
            {
                id: 1840015039,
                name: "Lake Charles",
            },
            {
                id: 1840015090,
                name: "Lake Mary",
            },
            {
                id: 1840019794,
                name: "Lake Stevens",
            },
            {
                id: 1840000608,
                name: "Lakewood",
            },
            {
                id: 1840002969,
                name: "Lancaster",
            },
            {
                id: 1840001590,
                name: "Lansing",
            },
            {
                id: 1840020683,
                name: "Las Cruces",
            },
            {
                id: 1840020364,
                name: "Las Vegas",
            },
            {
                id: 1756055099,
                name: "Lausanne",
            },
            {
                id: 1124922301,
                name: "Laval",
            },
            {
                id: 1840016331,
                name: "Lawndale",
            },
            {
                id: 1840005872,
                name: "Leesburg",
            },
            {
                id: 1276563678,
                name: "Leipzig",
            },
            {
                id: 1372452484,
                name: "Letterkenny",
            },
            {
                id: 1276002465,
                name: "Leverkusen",
            },
            {
                id: 1372126011,
                name: "Limerick",
            },
            {
                id: 1840000158,
                name: "Lincoln",
            },
            {
                id: 1840002710,
                name: "Lodi",
            },
            {
                id: 1840020489,
                name: "Lomita",
            },
            {
                id: 1840020468,
                name: "Lompoc",
            },
            {
                id: 1826645935,
                name: "London",
            },
            {
                id: 1840000988,
                name: "Long Beach",
            },
            {
                id: 1840020811,
                name: "Lorena",
            },
            {
                id: 1840020328,
                name: "Los Altos",
            },
            {
                id: 1840020491,
                name: "Los Angeles",
            },
            {
                id: 1840021037,
                name: "Los Fresnos",
            },
            {
                id: 1840021575,
                name: "Los Gatos",
            },
            {
                id: 1840020842,
                name: "Lott",
            },
            {
                id: 1840020604,
                name: "Lubbock",
            },
            {
                id: 1356891790,
                name: "Lucknow",
            },
            {
                id: 1250196189,
                name: "Lyon",
            },
            {
                id: 1840000538,
                name: "Madison",
            },
            {
                id: 1724616994,
                name: "Madrid",
            },
            {
                id: 1840001589,
                name: "Manhattan",
            },
            {
                id: 1840008844,
                name: "Manhattan Beach",
            },
            {
                id: 1840008937,
                name: "Maplewood",
            },
            {
                id: 1840004498,
                name: "Marathon",
            },
            {
                id: 1276012023,
                name: "Marburg",
            },
            {
                id: 1840017920,
                name: "Marina del Rey",
            },
            {
                id: 1840000427,
                name: "Marlborough",
            },
            {
                id: 1840001303,
                name: "Marysville",
            },
            {
                id: 1840003675,
                name: "Matawan",
            },
            {
                id: 1840003536,
                name: "Maywood",
            },
            {
                id: 1840021024,
                name: "McAllen",
            },
            {
                id: 1840007733,
                name: "McGregor",
            },
            {
                id: 1036533631,
                name: "Melbourne",
            },
            {
                id: 1840008113,
                name: "Melbourne",
            },
            {
                id: 1840016372,
                name: "Menifee",
            },
            {
                id: 1840020305,
                name: "Menlo Park",
            },
            {
                id: 1840020313,
                name: "Merced",
            },
            {
                id: 1840005238,
                name: "Merrick",
            },
            {
                id: 1840037795,
                name: "Merritt",
            },
            {
                id: 1840003633,
                name: "Metuchen",
            },
            {
                id: 1250087226,
                name: "Metz-en-Couture",
            },
            {
                id: 1840002798,
                name: "Middleburg",
            },
            {
                id: 1380724377,
                name: "Milan",
            },
            {
                id: 1840003170,
                name: "Milan",
            },
            {
                id: 1380682007,
                name: "Milano",
            },
            {
                id: 1840020259,
                name: "Mill Valley",
            },
            {
                id: 1840020306,
                name: "Millbrae",
            },
            {
                id: 1840020329,
                name: "Milpitas",
            },
            {
                id: 1840003046,
                name: "Milwaukee",
            },
            {
                id: 1840014078,
                name: "Mims",
            },
            {
                id: 1840003824,
                name: "Minneapolis",
            },
            {
                id: 1840020580,
                name: "Mission Viejo",
            },
            {
                id: 1124112672,
                name: "Mississauga",
            },
            {
                id: 1840002464,
                name: "Monroe",
            },
            {
                id: 1840009667,
                name: "Monrovia",
            },
            {
                id: 1840004961,
                name: "Monsey",
            },
            {
                id: 1840006214,
                name: "Monterey",
            },
            {
                id: 1840002659,
                name: "Montrose",
            },
            {
                id: 1124586170,
                name: "Montréal",
            },
            {
                id: 1380622727,
                name: "Monza",
            },
            {
                id: 1840020472,
                name: "Moorpark",
            },
            {
                id: 1840020552,
                name: "Moreno Valley",
            },
            {
                id: 1840020331,
                name: "Morgan Hill",
            },
            {
                id: 1840001326,
                name: "Morrisville",
            },
            {
                id: 1840008791,
                name: "Mountain View",
            },
            {
                id: 1840019793,
                name: "Mukilteo",
            },
            {
                id: 1372249762,
                name: "Mullach Íde",
            },
            {
                id: 1356226629,
                name: "Mumbai",
            },
            {
                id: 1276692352,
                name: "Munich",
            },
            {
                id: 1356779323,
                name: "Mysore",
            },
            {
                id: 1356320498,
                name: "Nagaur",
            },
            {
                id: 1840020241,
                name: "Napa",
            },
            {
                id: 1840008143,
                name: "Naperville",
            },
            {
                id: 1356215164,
                name: "New Delhi",
            },
            {
                id: 1840001839,
                name: "New Orleans",
            },
            {
                id: 1840001095,
                name: "New Providence",
            },
            {
                id: 1840015073,
                name: "New Smyrna Beach",
            },
            {
                id: 1840034016,
                name: "New York",
            },
            {
                id: 1840001419,
                name: "Newark",
            },
            {
                id: 1372186150,
                name: "Newbridge",
            },
            {
                id: 1124400266,
                name: "Newmarket",
            },
            {
                id: 1840020581,
                name: "Newport Beach",
            },
            {
                id: 1360587910,
                name: "None",
            },
            {
                id: 1356149101,
                name: "None",
            },
            {
                id: 1840003705,
                name: "Norristown",
            },
            {
                id: 1840011304,
                name: "Northbrook",
            },
            {
                id: 1840000817,
                name: "Norwalk",
            },
            {
                id: 1840020260,
                name: "Novato",
            },
            {
                id: 1840002423,
                name: "Novi",
            },
            {
                id: 1840019199,
                name: "Oak View",
            },
            {
                id: 1840008471,
                name: "Oakley",
            },
            {
                id: 1124001038,
                name: "Oakville",
            },
            {
                id: 1840020473,
                name: "Ojai",
            },
            {
                id: 1840019738,
                name: "Olmito",
            },
            {
                id: 1840019865,
                name: "Olympia",
            },
            {
                id: 1840009315,
                name: "Omaha",
            },
            {
                id: 1840002696,
                name: "Ontario",
            },
            {
                id: 1840003405,
                name: "Orange",
            },
            {
                id: 1076786452,
                name: "Osasco",
            },
            {
                id: 1124399363,
                name: "Ottawa",
            },
            {
                id: 1840015089,
                name: "Oviedo",
            },
            {
                id: 1840020474,
                name: "Oxnard",
            },
            {
                id: 1840100711,
                name: "Palm",
            },
            {
                id: 1840014143,
                name: "Palm Harbor",
            },
            {
                id: 1840018308,
                name: "Palm Springs",
            },
            {
                id: 1840020502,
                name: "Palmdale",
            },
            {
                id: 1840002771,
                name: "Palo Alto",
            },
            {
                id: 1840140102,
                name: "Palos Verdes Peninsula",
            },
            {
                id: 1840009548,
                name: "Pana",
            },
            {
                id: 1356152025,
                name: "Panchkula",
            },
            {
                id: 1250015082,
                name: "Paris",
            },
            {
                id: 1840005914,
                name: "Pasadena",
            },
            {
                id: 1356878237,
                name: "Pathānkot",
            },
            {
                id: 1840012770,
                name: "Peebles",
            },
            {
                id: 1840015005,
                name: "Pensacola",
            },
            {
                id: 1840020557,
                name: "Perris",
            },
            {
                id: 1840000673,
                name: "Philadelphia",
            },
            {
                id: 1840004193,
                name: "Phoenix",
            },
            {
                id: 1840020506,
                name: "Pico Rivera",
            },
            {
                id: 1840001254,
                name: "Pittsburgh",
            },
            {
                id: 1840001090,
                name: "Plainfield",
            },
            {
                id: 1840009219,
                name: "Pleasant Hill",
            },
            {
                id: 1840009393,
                name: "Pleasanton",
            },
            {
                id: 1840004983,
                name: "Pomona",
            },
            {
                id: 1840015143,
                name: "Pompano Beach",
            },
            {
                id: 1840028957,
                name: "Ponte Vedra Beach",
            },
            {
                id: 1840019821,
                name: "Port Orchard",
            },
            {
                id: 1840002826,
                name: "Port Washington",
            },
            {
                id: 1076627207,
                name: "Porto Alegre do Tocantins",
            },
            {
                id: 1840002288,
                name: "Prescott",
            },
            {
                id: 1840001381,
                name: "Princeton",
            },
            {
                id: 1840003289,
                name: "Providence",
            },
            {
                id: 1840001807,
                name: "Provo",
            },
            {
                id: 1356081074,
                name: "Pune",
            },
            {
                id: 1840034002,
                name: "Queens",
            },
            {
                id: 1840012975,
                name: "Raleigh",
            },
            {
                id: 1840020248,
                name: "Rancho Cordova",
            },
            {
                id: 1840020558,
                name: "Rancho Mirage",
            },
            {
                id: 1840020508,
                name: "Rancho Palos Verdes",
            },
            {
                id: 1840020584,
                name: "Rancho Santa Margarita",
            },
            {
                id: 1840002180,
                name: "Rapid City",
            },
            {
                id: 1840018412,
                name: "Ravensdale",
            },
            {
                id: 1840019835,
                name: "Redmond",
            },
            {
                id: 1840020509,
                name: "Redondo Beach",
            },
            {
                id: 1840004108,
                name: "Redwood",
            },
            {
                id: 1840020121,
                name: "Reno",
            },
            {
                id: 1840019827,
                name: "Renton",
            },
            {
                id: 1840006025,
                name: "Reston",
            },
            {
                id: 1840020407,
                name: "Rialto",
            },
            {
                id: 1840020710,
                name: "Richardson",
            },
            {
                id: 1840001698,
                name: "Richmond",
            },
            {
                id: 1840020812,
                name: "Riesel",
            },
            {
                id: 1428586192,
                name: "Riga",
            },
            {
                id: 1076887657,
                name: "Rio de Janeiro",
            },
            {
                id: 1840000848,
                name: "Riverside",
            },
            {
                id: 1840000373,
                name: "Rochester",
            },
            {
                id: 1840001375,
                name: "Rockledge",
            },
            {
                id: 1840003274,
                name: "Rockville",
            },
            {
                id: 1840003092,
                name: "Roseville",
            },
            {
                id: 1840002430,
                name: "Royal Oak",
            },
            {
                id: 1840000902,
                name: "Rutherford",
            },
            {
                id: 1840000784,
                name: "Rye",
            },
            {
                id: 1250935069,
                name: "Saint-Maur-des-Bois",
            },
            {
                id: 1840000410,
                name: "Salem",
            },
            {
                id: 1840082816,
                name: "Salt Lake",
            },
            {
                id: 1840015969,
                name: "San Antonio",
            },
            {
                id: 1840037414,
                name: "San Buenaventura",
            },
            {
                id: 1840019263,
                name: "San Carlos",
            },
            {
                id: 1840021962,
                name: "San Clemente",
            },
            {
                id: 1840021990,
                name: "San Diego",
            },
            {
                id: 1840021860,
                name: "San Dimas",
            },
            {
                id: 1840021543,
                name: "San Francisco",
            },
            {
                id: 1840021862,
                name: "San Gabriel",
            },
            {
                id: 1840012209,
                name: "San Jose",
            },
            {
                id: 1840022256,
                name: "San Juan",
            },
            {
                id: 1840021734,
                name: "San Luis Obispo",
            },
            {
                id: 1840021551,
                name: "San Mateo",
            },
            {
                id: 1840024611,
                name: "San Pedro",
            },
            {
                id: 1840021513,
                name: "San Rafael",
            },
            {
                id: 1840002672,
                name: "Sanford",
            },
            {
                id: 1840021964,
                name: "Santa Ana",
            },
            {
                id: 1840021829,
                name: "Santa Barbara",
            },
            {
                id: 1076001366,
                name: "Santa Catarina",
            },
            {
                id: 1840021560,
                name: "Santa Clara",
            },
            {
                id: 1840021864,
                name: "Santa Clarita",
            },
            {
                id: 1840019103,
                name: "Santa Cruz",
            },
            {
                id: 1840021865,
                name: "Santa Fe Springs",
            },
            {
                id: 1840019740,
                name: "Santa Maria",
            },
            {
                id: 1840019736,
                name: "Santa Monica",
            },
            {
                id: 1840010478,
                name: "Saratoga",
            },
            {
                id: 1840015961,
                name: "Satellite Beach",
            },
            {
                id: 1840022213,
                name: "Schertz",
            },
            {
                id: 1276129733,
                name: "Schwerin",
            },
            {
                id: 1276516691,
                name: "Schwetzingen",
            },
            {
                id: 1840021965,
                name: "Seal Beach",
            },
            {
                id: 1840021117,
                name: "Seattle",
            },
            {
                id: 1840026334,
                name: "Shavertown",
            },
            {
                id: 1840033408,
                name: "Short Hills",
            },
            {
                id: 1840021868,
                name: "Signal Hill",
            },
            {
                id: 1702341327,
                name: "Singapore",
            },
            {
                id: 1840090595,
                name: "Sioux",
            },
            {
                id: 1840011321,
                name: "Skokie",
            },
            {
                id: 1840021078,
                name: "Snohomish",
            },
            {
                id: 1840021119,
                name: "Snoqualmie",
            },
            {
                id: 1840021993,
                name: "Solana Beach",
            },
            {
                id: 1840021870,
                name: "South Gate",
            },
            {
                id: 1840015979,
                name: "South Pasadena",
            },
            {
                id: 1840022048,
                name: "Southlake",
            },
            {
                id: 1276575604,
                name: "Spangdahlem",
            },
            {
                id: 1840015482,
                name: "Spartanburg",
            },
            {
                id: 1840006501,
                name: "Spokane",
            },
            {
                id: 1840000466,
                name: "Springfield",
            },
            {
                id: 1840001651,
                name: "St. Louis",
            },
            {
                id: 1840006484,
                name: "St. Paul",
            },
            {
                id: 1840011982,
                name: "Stanford",
            },
            {
                id: 1840123202,
                name: "Staten",
            },
            {
                id: 1840003094,
                name: "Sterling Heights",
            },
            {
                id: 1752000183,
                name: "Stockholm",
            },
            {
                id: 1276171358,
                name: "Stuttgart",
            },
            {
                id: 1752355150,
                name: "Stuvsta",
            },
            {
                id: 1840021080,
                name: "Sultan",
            },
            {
                id: 1840021573,
                name: "Sunnyvale",
            },
            {
                id: 1124362993,
                name: "Sussex",
            },
            {
                id: 1840004033,
                name: "Swanton",
            },
            {
                id: 1036074917,
                name: "Sydney",
            },
            {
                id: 1076000849,
                name: "São Paulo",
            },
            {
                id: 1076144673,
                name: "São Salvador",
            },
            {
                id: 1356010485,
                name: "Sāngli",
            },
            {
                id: 1356292400,
                name: "Sūrat",
            },
            {
                id: 1840021129,
                name: "Tacoma",
            },
            {
                id: 1840081757,
                name: "Teaneck",
            },
            {
                id: 1376401542,
                name: "Tel Aviv-Yafo",
            },
            {
                id: 1840022536,
                name: "Telluride",
            },
            {
                id: 1840021937,
                name: "Temecula",
            },
            {
                id: 1840021942,
                name: "Tempe",
            },
            {
                id: 1840005392,
                name: "Temple",
            },
            {
                id: 1840002821,
                name: "Thiensville",
            },
            {
                id: 1840000562,
                name: "Titusville",
            },
            {
                id: 1392685764,
                name: "Tokyo",
            },
            {
                id: 1124279679,
                name: "Toronto",
            },
            {
                id: 1840021873,
                name: "Torrance",
            },
            {
                id: 1840022101,
                name: "Tucson",
            },
            {
                id: 1840021672,
                name: "Tulsa",
            },
            {
                id: 1840005563,
                name: "Tuscaloosa",
            },
            {
                id: 1840010953,
                name: "Tustin",
            },
            {
                id: 1276203240,
                name: "Tübingen",
            },
            {
                id: 1840006386,
                name: "Union",
            },
            {
                id: 1840010123,
                name: "Urbana",
            },
            {
                id: 1840022123,
                name: "Valley Mills",
            },
            {
                id: 1840023110,
                name: "Van Horn",
            },
            {
                id: 1124825478,
                name: "Vancouver",
            },
            {
                id: 1124000141,
                name: "Vaughan",
            },
            {
                id: 1124000196,
                name: "Victoria",
            },
            {
                id: 1040261752,
                name: "Vienna",
            },
            {
                id: 1840035239,
                name: "Villanova",
            },
            {
                id: 1356860296,
                name: "Vāranāsi",
            },
            {
                id: 1840010877,
                name: "Waco",
            },
            {
                id: 1840006175,
                name: "Waldorf",
            },
            {
                id: 1840010265,
                name: "Walnut",
            },
            {
                id: 1840017837,
                name: "Walnut Creek",
            },
            {
                id: 1840000724,
                name: "Washington",
            },
            {
                id: 1372267114,
                name: "Waterford",
            },
            {
                id: 1840010113,
                name: "Waukegan",
            },
            {
                id: 1840081754,
                name: "Weehawken",
            },
            {
                id: 1840022258,
                name: "Weslaco",
            },
            {
                id: 1840001436,
                name: "West Chester",
            },
            {
                id: 1840021876,
                name: "West Covina",
            },
            {
                id: 1840010185,
                name: "West Des Moines",
            },
            {
                id: 1840028098,
                name: "West Hollywood",
            },
            {
                id: 1840010430,
                name: "West Lafayette",
            },
            {
                id: 1840015993,
                name: "West Palm Beach",
            },
            {
                id: 1840003763,
                name: "Westerville",
            },
            {
                id: 1840000463,
                name: "Westfield",
            },
            {
                id: 1840037458,
                name: "Westlake Village",
            },
            {
                id: 1840004339,
                name: "Westminster",
            },
            {
                id: 1372349451,
                name: "Wexford",
            },
            {
                id: 1840009987,
                name: "Wheaton",
            },
            {
                id: 1840021878,
                name: "Whittier",
            },
            {
                id: 1840001686,
                name: "Wichita",
            },
            {
                id: 1372662642,
                name: "Wicklow",
            },
            {
                id: 1124224963,
                name: "Winnipeg",
            },
            {
                id: 1840015970,
                name: "Winter Haven",
            },
            {
                id: 1840015959,
                name: "Winter Springs",
            },
            {
                id: 1840015638,
                name: "Winterville",
            },
            {
                id: 1840021121,
                name: "Woodinville",
            },
            {
                id: 1840022142,
                name: "Woodway",
            },
            {
                id: 1840000434,
                name: "Worcester",
            },
            {
                id: 1840021970,
                name: "Yorba Linda",
            },
            {
                id: 1840021732,
                name: "Yucaipa",
            },
            {
                id: 1756539143,
                name: "Zürich",
            },
            {
                id: 1528012333,
                name: "’s-Hertogenbosch",
            },
        ],
    },
};

export const temporaryEducationViewData = {
    section1: {
        topUniversitiesAttended: {
            categories1: [
                "Georgia Institute of Technology",
                "The University of Georgia",
                "Embry-Riddle Aeronautical University",
                "Georgia State University",
                "Purdue University",
                "Kennesaw State University",
                "United States Air Force Academy",
                "University of Florida",
                "Georgia Southern University",
                "Auburn University",
                "University of Central Florida",
                "Emory University",
                "Wayne State University",
                "Florida State University",
                "United States Naval Academy",
                "Brigham Young University",
                "Metropolitan State University",
                "University of Minnesota-Twin Cities",
                "University of North Dakota",
                "Utah Valley University",
                "University of West Georgia",
                "University of Cincinnati",
                "University of Michigan",
                "Western Michigan University",
                "Penn State University",
                "University of Washington",
                "Michigan State University",
                "Valdosta State University",
                "Clayton State University",
                "Weber State University",
            ],
            categories2: [
                "Universitatea Politehnica din Timisoara",
                "Universität Konstanz",
                "Hochschule Offenburg",
                "Universität Hohenheim",
                "Universität Hamburg",
                "Ruprecht-Karls-Universität Heidelberg",
                "Universität Mannheim",
                "Universitatea de Vest din Timisoara",
                "Bauhaus-Universität Weimar",
                "Philipps-Universität Marburg",
                "Hochschule Pforzheim",
                "Freie Universität Berlin",
                "Otto-Friedrich-Universität Bamberg",
                "Friedrich-Schiller-Universität Jena",
                "Pädagogische Hochschule Karlsruhe",
                "FernUniversität in Hagen",
                "University of California, Los Angeles",
                "Eberhard Karls Universität Tübingen",
                "Università degli Studi di Padova",
                "Georg-August-Universität Göttingen",
                "Christian-Albrechts-Universität zu Kiel",
                "Univerzitet u Beogradu",
                "Friedrich-Alexander-Universität Erlangen-Nürnberg",
                "National Aviation University",
                "Mendeleev University of Chemical Technology of Russia",
                "Technische Universität Berlin",
                "Ludwig-Maximilians-Universität München",
                "Universidad Simón Bolívar",
                "Fachhochschule Wedel",
                "Westfälische Wilhelms-Universität Münster",
            ],
            stack1: {
                series: [
                    {
                        data: [
                            2.47, 1.68, 1.46, 1.42, 0.95, 0.89, 0.76, 0.63, 0.63, 0.57, 0.57, 0.54, 0.44, 0.41, 0.41,
                            0.41, 0.38, 0.38, 0.35, 0.35, 0.32, 0.32, 0.32, 0.32, 0.32, 0.28, 0.28, 0.25, 0.25, 0.25,
                        ],
                    },
                ],
            },
            stack2: {
                series: [
                    {
                        data: [
                            1.55, 1.16, 0.97, 0.58, 0.58, 0.39, 0.39, 0.39, 0.39, 0.39, 0.39, 0.39, 0.39, 0.39, 0.39,
                            0.39, 0.39, 0.39, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19, 0.19,
                        ],
                    },
                ],
            },
        },
        jobTitles: [
            {
                categories1: [
                    "Georgia State University",
                    "The University of Georgia",
                    "Kennesaw State University",
                    "Georgia Southern University",
                ],
                categories2: [
                    "Universitatea Politehnica din Timisoara",
                    "Universitatea din Craiova",
                    "Universitatea de Vest din Timisoara",
                    "Università degli Studi di Padova",
                ],
                stack1: {
                    name: "Transportation Services",
                    series: [
                        {
                            data: [0.47, 0.6, 0.32, 0.41],
                        },
                    ],
                },
                stack2: {
                    name: "Software Development",
                    series: [
                        {
                            data: [1.36, 0.19, 0.39, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Embry-Riddle Aeronautical University",
                    "United States Air Force Academy",
                    "Purdue University",
                    "Georgia Institute of Technology",
                ],
                categories2: [
                    "Hochschule Offenburg",
                    "Fachhochschule Münster",
                    "Universität Erfurt",
                    "Universidad de La Sabana",
                ],
                stack1: {
                    name: "Other Occupations",
                    series: [
                        {
                            data: [0.51, 0.41, 0.32, 0.35],
                        },
                    ],
                },
                stack2: {
                    name: "Marketing",
                    series: [
                        {
                            data: [0.58, 0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Dominican University",
                    "Embry-Riddle Aeronautical University",
                    "Arizona State University",
                    "Georgia Institute of Technology",
                ],
                categories2: [
                    "Universidad de Sevilla",
                    "Friedrich-Schiller-Universität Jena",
                    "Otto-Friedrich-Universität Bamberg",
                    "Christian-Albrechts-Universität zu Kiel",
                ],
                stack1: {
                    name: "Customer Services",
                    series: [
                        {
                            data: [0.03, 0.13, 0.03, 0.09],
                        },
                    ],
                },
                stack2: {
                    name: "Product Development",
                    series: [
                        {
                            data: [0.19, 0.19, 0.39, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Georgia State University",
                    "Dominican University",
                    "Georgia Institute of Technology",
                    "Embry-Riddle Aeronautical University",
                ],
                categories2: [
                    "Freie Universität Berlin",
                    "Ostbayerische Technische Hochschule Regensburg",
                    "Universität Konstanz",
                    "Hogeschool van Amsterdam",
                ],
                stack1: {
                    name: "Middle Management",
                    series: [
                        {
                            data: [0.16, 0.22, 0.16, 0],
                        },
                    ],
                },
                stack2: {
                    name: "Business Development",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19, 0],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "The University of Georgia",
                    "Georgia State University",
                    "Metropolitan State University",
                    "Kennesaw State University",
                ],
                categories2: [
                    "Universität Hohenheim",
                    "Universität Wien",
                    "Fachhochschule Vorarlberg",
                    "Swinburne University of Technology",
                ],
                stack1: {
                    name: "Marketing",
                    series: [
                        {
                            data: [0.32, 0.13, 0.03, 0.13],
                        },
                    ],
                },
                stack2: {
                    name: "Sales",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Auburn University",
                    "Universidad Rafael Landívar",
                    "Purdue University",
                    "Lewis University",
                ],
                categories2: [
                    "Universität Stuttgart",
                    "Rheinische Friedrich-Wilhelms-Universität Bonn",
                    "Universität Erfurt",
                    "Universität Rostock",
                ],
                stack1: {
                    name: "Sales",
                    series: [
                        {
                            data: [0.03, 0.03, 0.06, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Middle Management",
                    series: [
                        {
                            data: [0.19, 0.19, 0, 0],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Georgia Institute of Technology",
                    "Clayton State University",
                    "Metropolitan State University",
                    "Penn State University",
                ],
                categories2: [
                    "FOM Hochschule für Oekonomie und Management",
                    "VWA-Hochschule für berufsbegleitendes Studium",
                    "Ludwig-Maximilians-Universität München",
                    "Leuphana Universität Lüneburg",
                ],
                stack1: {
                    name: "Operations",
                    series: [
                        {
                            data: [0.09, 0.06, 0.06, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Human Resources",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Georgia Institute of Technology",
                    "University of Notre Dame",
                    "Georgia State University",
                    "Hult International Business School",
                ],
                categories2: ["Medizinische Universität Wien", "FernUniversität in Hagen"],
                stack1: {
                    name: "Data Science and Analysis",
                    series: [
                        {
                            data: [0.35, 0.03, 0.09, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Founders / Shareholders",
                    series: [
                        {
                            data: [0.19, 0],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Jawaharlal Nehru Technological University",
                    "Columbia College",
                    "St. Cloud State University",
                    "University of Florida",
                ],
                categories2: ["Hochschule für Bildende Künste Braunschweig", "Universidad Pontificia Bolivariana"],
                stack1: {
                    name: "Software Development",
                    series: [
                        {
                            data: [0.13, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Design",
                    series: [
                        {
                            data: [0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Georgia State University",
                    "Auburn University",
                    "University of Wisconsin-Eau Claire",
                    "University of Wisconsin-River Falls",
                ],
                categories2: [
                    "Fachhochschule Wedel",
                    "Hochschule Offenburg",
                    "Universität Konstanz",
                    "FernUniversität in Hagen",
                ],
                stack1: {
                    name: "Information Technology",
                    series: [
                        {
                            data: [0.06, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Information Technology",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19, 0],
                        },
                    ],
                },
            },
        ],
        universities: [
            {
                categories1: [
                    "Engineering",
                    "Data Science and Analysis",
                    "Other Occupations",
                    "Middle Management",
                    "Finance",
                    "Design",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "Top Management",
                    "Project Management",
                    "Transportation Services",
                    "Team lead / Technical lead",
                    "Research and Development",
                    "Operations",
                    "Marketing",
                    "Information Technology",
                    "Customer Services",
                    "Software Development",
                    "Sales",
                    "Retail Banking",
                    "Medical / Wellness",
                    "Media and Entertainment",
                    "Human Resources",
                    "Trainee",
                    "Student",
                    "Quality Management",
                    "Protection",
                    "Product Development",
                    "Legal Services",
                    "Investors",
                    "Consulting",
                    "C Level",
                    "Business Development",
                    "Business Administration",
                    "Associate",
                ],
                categories2: ["Software Development", "Engineering"],
                stack1: {
                    name: "Georgia Institute of Technology",
                    series: [
                        {
                            data: [
                                0.44, 0.41, 0.38, 0.25, 0.19, 0.16, 0.16, 0.16, 0.13, 0.13, 0.09, 0.09, 0.09, 0.09,
                                0.09, 0.09, 0.09, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Universitatea Politehnica din Timisoara",
                    series: [
                        {
                            data: [1.36, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Marketing",
                    "Other Occupations",
                    "Human Resources",
                    "Customer Services",
                    "Corporate Affairs",
                    "Top Management",
                    "Software Development",
                    "Project Management",
                    "Middle Management",
                    "Team lead / Technical lead",
                    "Social Services",
                    "Self Employed",
                    "Retail Banking",
                    "Operations",
                    "Media and Entertainment",
                    "Insurance",
                    "Information Technology",
                    "Business Development",
                    "Business Administration",
                ],
                categories2: [
                    "Team lead / Technical lead",
                    "Software Development",
                    "Marketing",
                    "Information Technology",
                    "Corporate Affairs",
                    "Business Development",
                ],
                stack1: {
                    name: "The University of Georgia",
                    series: [
                        {
                            data: [
                                0.6, 0.32, 0.19, 0.09, 0.09, 0.09, 0.06, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Universität Konstanz",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19, 0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Other Occupations",
                    "Transportation Services",
                    "Middle Management",
                    "Customer Services",
                    "Team lead / Technical lead",
                    "Operations",
                    "Founders / Shareholders",
                    "Engineering",
                    "Business Administration",
                    "Software Development",
                    "Project Management",
                    "Product Development",
                    "Marketing",
                    "Human Resources",
                    "Data Science and Analysis",
                    "Corporate Affairs",
                    "Coach / Professor / Mentor",
                    "Associate",
                ],
                categories2: ["Marketing", "Software Development", "Information Technology"],
                stack1: {
                    name: "Embry-Riddle Aeronautical University",
                    series: [
                        {
                            data: [
                                0.51, 0.28, 0.16, 0.13, 0.06, 0.06, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Hochschule Offenburg",
                    series: [
                        {
                            data: [0.58, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Middle Management",
                    "Marketing",
                    "Corporate Affairs",
                    "Human Resources",
                    "Finance",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Other Occupations",
                    "Operations",
                    "Information Technology",
                    "Team lead / Technical lead",
                    "Supply Chain Management / Logistics",
                    "Sports / Fitness / Trainer",
                    "Real Estate",
                    "Intern",
                    "Coach / Professor / Mentor",
                    "Associate",
                    "Accounting",
                ],
                categories2: ["Sales", "Marketing", "Human Resources"],
                stack1: {
                    name: "Georgia State University",
                    series: [
                        {
                            data: [
                                0.44, 0.16, 0.13, 0.13, 0.09, 0.09, 0.09, 0.09, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Universität Hohenheim",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Other Occupations",
                    "Transportation Services",
                    "Human Resources",
                    "Corporate Affairs",
                    "Sales",
                    "Operations",
                    "Data Science and Analysis",
                    "Top Management",
                    "Project Management",
                    "Marketing",
                    "Information Technology",
                    "Finance",
                ],
                categories2: ["Student", "Product Development", "Marketing"],
                stack1: {
                    name: "Purdue University",
                    series: [
                        {
                            data: [0.35, 0.13, 0.09, 0.09, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Universität Hamburg",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Media and Entertainment",
                    "Marketing",
                    "Data Science and Analysis",
                    "Team lead / Technical lead",
                    "Customer Services",
                    "Corporate Affairs",
                    "Technician",
                    "Sales",
                    "Retail",
                    "Real Estate",
                    "Product Development",
                    "Other Occupations",
                    "Operations",
                    "Middle Management",
                    "Human Resources",
                    "Consulting",
                    "Coach / Professor / Mentor",
                    "Business Development",
                    "Business Administration",
                ],
                categories2: ["Marketing", "Legal Services", "Consulting"],
                stack1: {
                    name: "Kennesaw State University",
                    series: [
                        {
                            data: [
                                0.32, 0.13, 0.13, 0.09, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Ruprecht-Karls-Universität Heidelberg",
                    series: [
                        {
                            data: [0.19, 0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Other Occupations",
                    "Transportation Services",
                    "Operations",
                    "Top Management",
                    "Software Development",
                    "Military",
                    "Coach / Professor / Mentor",
                ],
                categories2: ["Project Management", "Corporate Affairs"],
                stack1: {
                    name: "United States Air Force Academy",
                    series: [
                        {
                            data: [0.54, 0.16, 0.06, 0.03, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Universität Mannheim",
                    series: [
                        {
                            data: [0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Engineering",
                    "Other Occupations",
                    "Middle Management",
                    "Marketing",
                    "Top Management",
                    "Software Development",
                    "Sales",
                    "Product Development",
                    "Investors",
                    "Information Technology",
                    "Human Resources",
                    "Founders / Shareholders",
                    "Data Science and Analysis",
                    "Customer Services",
                    "Corporate Affairs",
                    "Consulting",
                    "C Level",
                    "Board Member",
                    "Art",
                ],
                categories2: ["Software Development"],
                stack1: {
                    name: "University of Florida",
                    series: [
                        {
                            data: [
                                0.09, 0.09, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                                0.03, 0.03, 0.03, 0.03, 0.03, 0.03,
                            ],
                        },
                    ],
                },
                stack2: {
                    name: "Universitatea de Vest din Timisoara",
                    series: [
                        {
                            data: [0.39],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Information Technology",
                    "Engineering",
                    "Corporate Affairs",
                    "Sales",
                    "Real Estate",
                    "Middle Management",
                    "Medical / Wellness",
                    "Media and Entertainment",
                    "Marketing",
                    "Human Resources",
                ],
                categories2: ["Marketing", "Corporate Affairs"],
                stack1: {
                    name: "Georgia Southern University",
                    series: [
                        {
                            data: [0.41, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Bauhaus-Universität Weimar",
                    series: [
                        {
                            data: [0.19, 0.19],
                        },
                    ],
                },
            },
            {
                categories1: [
                    "Transportation Services",
                    "Other Occupations",
                    "Finance",
                    "Data Science and Analysis",
                    "Sales",
                    "Product Development",
                    "Operations",
                    "Middle Management",
                    "Marketing",
                    "Intern",
                    "Information Technology",
                    "Coach / Professor / Mentor",
                    "C Level",
                    "Business Administration",
                ],
                categories2: ["Marketing", "Corporate Affairs"],
                stack1: {
                    name: "Auburn University",
                    series: [
                        {
                            data: [0.06, 0.06, 0.06, 0.06, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03],
                        },
                    ],
                },
                stack2: {
                    name: "Philipps-Universität Marburg",
                    series: [
                        {
                            data: [0.19, 0.19],
                        },
                    ],
                },
            },
        ],
        universityLocation: {
            stack1: [
                {
                    universityId: 10528,
                    title: "Georgia Institute of Technology",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 2.47,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 2888,
                    title: "The University of Georgia",
                    place: "Tbilisi, Georgia",
                    city: "Tbilisi, GE",
                    cityId: 1268203191,
                    percent: 1.68,
                    lat: 41.7225,
                    lng: 44.7925,
                },
                {
                    universityId: 10472,
                    title: "Embry-Riddle Aeronautical University",
                    place: "Daytona Beach, United States",
                    city: "Daytona Beach, US",
                    cityId: 1840015068,
                    percent: 1.46,
                    lat: 29.1994,
                    lng: -81.0982,
                },
                {
                    universityId: 10530,
                    title: "Georgia State University",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 1.42,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 10669,
                    title: "Purdue University",
                    place: "West Lafayette, United States",
                    city: "West Lafayette, US",
                    cityId: 1840010430,
                    percent: 0.95,
                    lat: 40.4432,
                    lng: -86.9239,
                },
                {
                    universityId: 10532,
                    title: "Kennesaw State University",
                    place: "Kennesaw, United States",
                    city: "Kennesaw, US",
                    cityId: 1840014756,
                    percent: 0.89,
                    lat: 34.026,
                    lng: -84.6177,
                },
                {
                    universityId: 10405,
                    title: "United States Air Force Academy",
                    place: "Colorado Springs, United States",
                    city: "Colorado Springs, US",
                    cityId: 1840018825,
                    percent: 0.76,
                    lat: 38.8674,
                    lng: -104.7606,
                },
                {
                    universityId: 10534,
                    title: "Georgia Southern University",
                    place: "Statesboro, United States",
                    city: "Statesboro, US",
                    cityId: 1840015801,
                    percent: 0.63,
                    lat: 32.4376,
                    lng: -81.775,
                },
                {
                    universityId: 10464,
                    title: "University of Florida",
                    place: "Gainesville, United States",
                    city: "Gainesville, US",
                    cityId: 1840004468,
                    percent: 0.63,
                    lat: 42.6521,
                    lng: -78.1326,
                },
                {
                    universityId: 10202,
                    title: "Auburn University",
                    place: "Auburn, United States",
                    city: "Auburn, US",
                    cityId: 1840000319,
                    percent: 0.57,
                    lat: 44.085,
                    lng: -70.2492,
                },
                {
                    universityId: 10467,
                    title: "University of Central Florida",
                    place: "Orlando, United States",
                    city: "Orlando, US",
                    cityId: 1840015099,
                    percent: 0.57,
                    lat: 28.4772,
                    lng: -81.3369,
                },
                {
                    universityId: 10531,
                    title: "Emory University",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 0.54,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 10954,
                    title: "Wayne State University",
                    place: "Detroit, United States",
                    city: "Detroit, US",
                    cityId: 1840003971,
                    percent: 0.44,
                    lat: 42.3834,
                    lng: -83.1024,
                },
                {
                    universityId: 11816,
                    title: "Brigham Young University",
                    place: "Provo, United States",
                    city: "Provo, US",
                    cityId: 1840001807,
                    percent: 0.41,
                    lat: 34.0376,
                    lng: -94.1077,
                },
                {
                    universityId: 10465,
                    title: "Florida State University",
                    place: "Tallahassee, United States",
                    city: "Tallahassee, US",
                    cityId: 1840015913,
                    percent: 0.41,
                    lat: 30.4551,
                    lng: -84.2526,
                },
                {
                    universityId: 10849,
                    title: "United States Naval Academy",
                    place: "Annapolis, United States",
                    city: "Annapolis, US",
                    cityId: 1840005928,
                    percent: 0.41,
                    lat: 38.9706,
                    lng: -76.5047,
                },
                {
                    universityId: 10997,
                    title: "University of Minnesota-Twin Cities",
                    place: "Minneapolis, United States",
                    city: "Minneapolis, US",
                    cityId: 1840003824,
                    percent: 0.38,
                    lat: 39.1243,
                    lng: -97.6997,
                },
                {
                    universityId: 11020,
                    title: "Metropolitan State University",
                    place: "St. Paul, United States",
                    city: "St. Paul, US",
                    cityId: 1840006484,
                    percent: 0.38,
                    lat: 36.9068,
                    lng: -82.3162,
                },
                {
                    universityId: 11389,
                    title: "University of North Dakota",
                    place: "Grand Forks, United States",
                    city: "Grand Forks, US",
                    cityId: 1840000101,
                    percent: 0.35,
                    lat: 47.9218,
                    lng: -97.0886,
                },
                {
                    universityId: 11819,
                    title: "Utah Valley University",
                    place: "Orem, United States",
                    city: "Orem, US",
                    cityId: 1840020171,
                    percent: 0.35,
                    lat: 40.2983,
                    lng: -111.6992,
                },
                {
                    universityId: 10951,
                    title: "University of Michigan",
                    place: "Ann Arbor, United States",
                    city: "Ann Arbor, US",
                    cityId: 1840003172,
                    percent: 0.32,
                    lat: 42.2755,
                    lng: -83.7312,
                },
                {
                    universityId: 11516,
                    title: "Penn State University",
                    place: "University Park, United States",
                    city: "University Park, US",
                    cityId: 1840003827,
                    percent: 0.32,
                    lat: 38.9719,
                    lng: -76.9445,
                },
                {
                    universityId: 10955,
                    title: "Western Michigan University",
                    place: "Kalamazoo, United States",
                    city: "Kalamazoo, US",
                    cityId: 1840003185,
                    percent: 0.32,
                    lat: 42.2749,
                    lng: -85.5882,
                },
                {
                    universityId: 11400,
                    title: "University of Cincinnati",
                    place: "Cincinnati, United States",
                    city: "Cincinnati, US",
                    cityId: 1840003814,
                    percent: 0.32,
                    lat: 39.1413,
                    lng: -84.5061,
                },
                {
                    universityId: 10537,
                    title: "University of West Georgia",
                    place: "Carrollton, United States",
                    city: "Carrollton, US",
                    cityId: 1840004806,
                    percent: 0.32,
                    lat: 33.2619,
                    lng: -88.0951,
                },
                {
                    universityId: 10952,
                    title: "Michigan State University",
                    place: "East Lansing, United States",
                    city: "East Lansing, US",
                    cityId: 1840003101,
                    percent: 0.28,
                    lat: 42.748,
                    lng: -84.4835,
                },
                {
                    universityId: 11882,
                    title: "University of Washington",
                    place: "Seattle, United States",
                    city: "Seattle, US",
                    cityId: 1840021117,
                    percent: 0.28,
                    lat: 47.6211,
                    lng: -122.3244,
                },
                {
                    universityId: 10544,
                    title: "Clark Atlanta University",
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    percent: 0.25,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    universityId: 10550,
                    title: "Clayton State University",
                    place: "Morrow, United States",
                    city: "Morrow, US",
                    cityId: 1840012614,
                    percent: 0.25,
                    lat: 39.35,
                    lng: -84.1231,
                },
                {
                    universityId: 11776,
                    title: "University of St. Thomas",
                    place: "Houston, United States",
                    city: "Houston, US",
                    cityId: 1840003657,
                    percent: 0.25,
                    lat: 40.2494,
                    lng: -80.211,
                },
            ],
            stack2: [
                {
                    universityId: 8530,
                    title: "Universitatea Politehnica din Timisoara",
                    place: "Timişoara, Romania",
                    city: "Timişoara, RO",
                    cityId: 1642603121,
                    percent: 1.55,
                    lat: 45.7597,
                    lng: 21.23,
                },
                {
                    universityId: 2921,
                    title: "Universität Konstanz",
                    place: "Konstanz, Germany",
                    city: "Konstanz, DE",
                    cityId: 1276601373,
                    percent: 1.16,
                    lat: 47.6633,
                    lng: 9.1753,
                },
                {
                    universityId: 2928,
                    title: "Hochschule Offenburg",
                    place: "Offenburg, Germany",
                    city: "Offenburg, DE",
                    cityId: 1276214401,
                    percent: 0.97,
                    lat: 48.4708,
                    lng: 7.9408,
                },
                {
                    universityId: 3067,
                    title: "Universität Hamburg",
                    place: "Hamburg, Germany",
                    city: "Hamburg, DE",
                    cityId: 1276041799,
                    percent: 0.58,
                    lat: 53.55,
                    lng: 10,
                },
                {
                    universityId: 2922,
                    title: "Universität Hohenheim",
                    place: "Stuttgart, Germany",
                    city: "Stuttgart, DE",
                    cityId: 1276171358,
                    percent: 0.58,
                    lat: 48.7761,
                    lng: 9.1775,
                },
                {
                    universityId: 3019,
                    title: "Freie Universität Berlin",
                    place: "Berlin, Germany",
                    city: "Berlin, DE",
                    cityId: 1276451290,
                    percent: 0.39,
                    lat: 52.5167,
                    lng: 13.3833,
                },
                {
                    universityId: 2938,
                    title: "Pädagogische Hochschule Karlsruhe",
                    place: "Karlsruhe, Germany",
                    city: "Karlsruhe, DE",
                    cityId: 1276229317,
                    percent: 0.39,
                    lat: 52.9145,
                    lng: 12.1873,
                },
                {
                    universityId: 8529,
                    title: "Universitatea de Vest din Timisoara",
                    place: "Timişoara, Romania",
                    city: "Timişoara, RO",
                    cityId: 1642603121,
                    percent: 0.39,
                    lat: 45.7597,
                    lng: 21.23,
                },
                {
                    universityId: 3265,
                    title: "Bauhaus-Universität Weimar",
                    place: "Weimar, Germany",
                    city: "Weimar, DE",
                    cityId: 1276053525,
                    percent: 0.39,
                    lat: 50.9833,
                    lng: 11.3167,
                },
                {
                    universityId: 2916,
                    title: "Eberhard Karls Universität Tübingen",
                    place: "Tübingen, Germany",
                    city: "Tübingen, DE",
                    cityId: 1276203240,
                    percent: 0.39,
                    lat: 48.52,
                    lng: 9.0556,
                },
                {
                    universityId: 10267,
                    title: "University of California, Los Angeles",
                    place: "Los Angeles, United States",
                    city: "Los Angeles, US",
                    cityId: 1840020491,
                    percent: 0.39,
                    lat: 34.1139,
                    lng: -118.4068,
                },
                {
                    universityId: 2919,
                    title: "Universität Mannheim",
                    place: "Mannheim, Germany",
                    city: "Mannheim, DE",
                    cityId: 1276150104,
                    percent: 0.39,
                    lat: 49.4878,
                    lng: 8.4661,
                },
                {
                    universityId: 2930,
                    title: "Hochschule Pforzheim",
                    place: "Pforzheim, Germany",
                    city: "Pforzheim, DE",
                    cityId: 1276732622,
                    percent: 0.39,
                    lat: 48.895,
                    lng: 8.705,
                },
                {
                    universityId: 2980,
                    title: "Otto-Friedrich-Universität Bamberg",
                    place: "Bamberg, Germany",
                    city: "Bamberg, DE",
                    cityId: 1276824274,
                    percent: 0.39,
                    lat: 49.8917,
                    lng: 10.8917,
                },
                {
                    universityId: 3148,
                    title: "FernUniversität in Hagen",
                    place: "Hägen, Germany",
                    city: "Hägen, DE",
                    cityId: 1276749552,
                    percent: 0.39,
                    lat: 54.2545,
                    lng: 9.1512,
                },
                {
                    universityId: 3085,
                    title: "Philipps-Universität Marburg",
                    place: "Marburg, Germany",
                    city: "Marburg, DE",
                    cityId: 1276012023,
                    percent: 0.39,
                    lat: 50.8167,
                    lng: 8.7667,
                },
                {
                    universityId: 3263,
                    title: "Friedrich-Schiller-Universität Jena",
                    place: "Jena, Germany",
                    city: "Jena, DE",
                    cityId: 1276659978,
                    percent: 0.39,
                    lat: 50.9272,
                    lng: 11.5864,
                },
                {
                    universityId: 2914,
                    title: "Ruprecht-Karls-Universität Heidelberg",
                    place: "Heidelberg, Germany",
                    city: "Heidelberg, DE",
                    cityId: 1276018738,
                    percent: 0.39,
                    lat: 50.6431,
                    lng: 13.4739,
                },
                {
                    universityId: 2918,
                    title: "Universität Stuttgart",
                    place: "Stuttgart, Germany",
                    city: "Stuttgart, DE",
                    cityId: 1276171358,
                    percent: 0.39,
                    lat: 48.7761,
                    lng: 9.1775,
                },
                {
                    universityId: 3264,
                    title: "Technische Universität Ilmenau",
                    place: "Ilmenau, Germany",
                    city: "Ilmenau, DE",
                    cityId: 1276538190,
                    percent: 0.19,
                    lat: 49.7945,
                    lng: 10.5118,
                },
                {
                    universityId: 5062,
                    title: "Mazandaran University of Science and Technology",
                    place: "Bābol, Iran",
                    city: "Bābol, IR",
                    cityId: 1364476070,
                    percent: 0.19,
                    lat: 36.55,
                    lng: 52.6833,
                },
                {
                    universityId: 2945,
                    title: "SRH Hochschule Heidelberg",
                    place: "Heidelberg, Germany",
                    city: "Heidelberg, DE",
                    cityId: 1276018738,
                    percent: 0.19,
                    lat: 50.6431,
                    lng: 13.4739,
                },
                {
                    universityId: 3141,
                    title: "Westfälische Wilhelms-Universität Münster",
                    place: "Munster, Germany",
                    city: "Munster, DE",
                    cityId: 1276787275,
                    percent: 0.19,
                    lat: 52.9857,
                    lng: 10.0876,
                },
                {
                    universityId: 426,
                    title: "Medizinische Universität Wien",
                    place: "Vienna, Austria",
                    city: "Vienna, AT",
                    cityId: 1040261752,
                    percent: 0.19,
                    lat: 48.2083,
                    lng: 16.3731,
                },
                {
                    universityId: 6524,
                    title: "American University of Beirut",
                    place: "Beirut, Lebanon",
                    city: "Beirut, LB",
                    cityId: 1422847713,
                    percent: 0.19,
                    lat: 33.8869,
                    lng: 35.5131,
                },
                {
                    universityId: 3869,
                    title: "Savitribai Phule Pune University",
                    place: "Pune, India",
                    city: "Pune, IN",
                    cityId: 1356081074,
                    percent: 0.19,
                    lat: 18.5196,
                    lng: 73.8553,
                },
                {
                    universityId: 8958,
                    title: "Univerzitet u Beogradu",
                    place: "Belgrade, Serbia",
                    city: "Belgrade, RS",
                    cityId: 1688374696,
                    percent: 0.19,
                    lat: 44.8167,
                    lng: 20.4667,
                },
                {
                    universityId: 3096,
                    title: "EBS Universität für Wirtschaft und Recht",
                    place: "Oestrich-Winkel, Germany",
                    city: "Oestrich-Winkel, DE",
                    cityId: 1276526238,
                    percent: 0.19,
                    lat: 50.0085,
                    lng: 8.0199,
                },
                {
                    universityId: 3140,
                    title: "Rheinische Friedrich-Wilhelms-Universität Bonn",
                    place: "Bonn, Germany",
                    city: "Bonn, DE",
                    cityId: 1276807850,
                    percent: 0.19,
                    lat: 50.7339,
                    lng: 7.0997,
                },
                {
                    universityId: 9216,
                    title: "Högskolan Kristianstad",
                    place: "Kristianstad, Sweden",
                    city: "Kristianstad, SE",
                    cityId: 1752606470,
                    percent: 0.19,
                    lat: 56.0337,
                    lng: 14.1333,
                },
            ],
            commonData: [],
        },
    },
    section2: {
        degreeDistribution: {
            comparisonChart: {
                categories: ["Bachelor's Degree", "Master's Degree", "Doctoral Degree", "Unknown"],
                stack1: {
                    series: {
                        data: [63.32, 12.73, 0.74, 23.21],
                    },
                },
                stack2: {
                    series: {
                        data: [55.04, 16.83, 1.11, 27.02],
                    },
                },
                benchmark: [
                    {
                        number: 703635,
                        percent: 52.87,
                    },
                    {
                        number: 197957,
                        percent: 14.88,
                    },
                    {
                        number: 17316,
                        percent: 1.3,
                    },
                    {
                        number: 411851,
                        percent: 30.95,
                    },
                ],
            },
            diffChart: {
                series: {
                    data: [-8.28, 4.1, 0.37, 3.81],
                },
            },
        },
        degreeDistributionPerGender: {
            tableChart: {
                tableHead: ["Edu/Stat", "Male", "Female", "Total"],
                tableBody: [
                    [
                        {
                            data: [
                                "Bachelor's Degree",
                                {
                                    series: [
                                        {
                                            data: [44.2, 41.89],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [35.6, 25.46],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [79.8, 67.35],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Master's Degree",
                                {
                                    series: [
                                        {
                                            data: [12.16, 16.84],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [6.96, 13.76],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [19.12, 30.6],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Doctoral Degree",
                                {
                                    series: [
                                        {
                                            data: [0.92, 2.05],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [0.16, 0],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [1.08, 2.05],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            data: [
                                "Total",
                                {
                                    series: [
                                        {
                                            data: [57.28, 60.78],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [42.72, 39.22],
                                        },
                                    ],
                                },
                                {
                                    series: [
                                        {
                                            data: [100, 100],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                ],
            },
        },
        languagesSpoken: {
            categories: [
                "English",
                "Spanish",
                "French",
                "German",
                "Chinese",
                "Hindi",
                "Portuguese",
                "Italian",
                "Arabic",
                "Japanese",
                "Korean",
                "Urdu",
                "Tagalog",
                "Russian",
                "Dutch",
                "Vietnamese",
                "Telugu",
                "Gujarati",
                "Bengali",
                "Panjabi",
            ],
            stack1: {
                series: [
                    {
                        data: [
                            38.53, 22.08, 8.2, 3.96, 3.27, 2.98, 2.92, 2.47, 1.49, 1.43, 1.15, 0.86, 0.75, 0.69, 0.69,
                            0.57, 0.52, 0.52, 0.52, 0.4,
                        ],
                    },
                ],
            },
            stack2: {
                series: [
                    {
                        data: [32.4, 11.2, 14, 24.4, 0, 0, 0.4, 3.47, 0.67, 0, 0, 0, 0, 1.07, 0.67, 0, 0, 0, 0, 0],
                    },
                ],
            },
        },
        numberOfSpokenLanguagesPerJobTitle: {
            categories: [
                "Investors",
                "Legal Services",
                "Data Science and Analysis",
                "Intern",
                "Finance",
                "Sports / Fitness / Trainer",
                "Self Employed",
                "Medical / Wellness",
                "Board Member",
                "Design",
                "Product Development",
                "Consulting",
                "Retail Banking",
                "Information Technology",
                "Accounting",
                "Protection",
                "Military",
                "Volunteering",
                "Manufacturing",
                "Middle Management",
                "Team lead / Technical lead",
                "Engineering",
                "Software Development",
                "Student",
                "Sales",
                "Coach / Professor / Mentor",
                "Human Resources",
                "Corporate Affairs",
                "Real Estate",
                "Quality Management",
                "Retail",
                "Marketing",
                "Operations",
                "Transportation Services",
                "Research and Development",
                "Customer Services",
                "Media and Entertainment",
                "Project Management",
                "Other Occupations",
                "C Level",
                "Founders / Shareholders",
                "Craft Trade Workers",
                "Associate",
                "Business Administration",
                "Supply Chain Management / Logistics",
                "Top Management",
                "Business Development",
                "Partner",
                "University / Academic Affairs",
                "Technician",
                "Production",
                "Art",
                "Insurance",
                "Automation",
                "Writer",
            ],
            stack1: {
                name: "Delta Air Lines",
                series: [
                    {
                        data: [
                            5.5, 5.25, 3.818, 3.7, 3.677, 3.667, 3.5, 3.444, 3.333, 3.3, 3.273, 3.2, 3.2, 3.087, 3, 3,
                            3, 3, 3, 2.891, 2.857, 2.857, 2.81, 2.8, 2.792, 2.722, 2.7, 2.667, 2.667, 2.667, 2.667,
                            2.571, 2.559, 2.373, 2.333, 2.317, 2.312, 2.308, 2.301, 2.273, 2.25, 2.25, 2.25, 2.2, 2.2,
                            2.125, 2, 2, 2, 1.75, 1.75, 1.75, 1.75, 1, 1,
                        ],
                    },
                ],
            },
            stack2: {
                name: "Haufe",
                series: [
                    {
                        data: [
                            0, 0, 5.429, 3, 2, 0, 4.667, 0, 7.25, 5.5, 3.353, 2.833, 0, 4, 9, 1, 0, 0, 0, 4.833, 5.4, 4,
                            4.13, 7.75, 2.667, 5, 4, 4.143, 0, 2, 0, 3.786, 2.667, 0, 0, 3, 4.25, 3, 4.667, 2.75, 3.667,
                            0, 0, 0, 0, 4, 3.545, 6, 0, 4, 0, 0, 0, 2, 5,
                        ],
                    },
                ],
            },
        },
    },
    filters: {
        jobTitles: [
            {
                id: 1,
                name: "Accounting",
            },
            {
                id: 4,
                name: "Art",
            },
            {
                id: 5,
                name: "Associate",
            },
            {
                id: 3,
                name: "Automation",
            },
            {
                id: 6,
                name: "Board Member",
            },
            {
                id: 2,
                name: "Business Administration",
            },
            {
                id: 7,
                name: "Business Development",
            },
            {
                id: 15,
                name: "C Level",
            },
            {
                id: 8,
                name: "Coach / Professor / Mentor",
            },
            {
                id: 9,
                name: "Consulting",
            },
            {
                id: 10,
                name: "Corporate Affairs",
            },
            {
                id: 11,
                name: "Craft Trade Workers",
            },
            {
                id: 12,
                name: "Customer Services",
            },
            {
                id: 14,
                name: "Data Science and Analysis",
            },
            {
                id: 18,
                name: "Design",
            },
            {
                id: 19,
                name: "Engineering",
            },
            {
                id: 20,
                name: "Finance",
            },
            {
                id: 17,
                name: "Founders / Shareholders",
            },
            {
                id: 22,
                name: "Human Resources",
            },
            {
                id: 23,
                name: "Information Technology",
            },
            {
                id: 24,
                name: "Insurance",
            },
            {
                id: 55,
                name: "Intern",
            },
            {
                id: 25,
                name: "Investors",
            },
            {
                id: 27,
                name: "Legal Services",
            },
            {
                id: 29,
                name: "Manufacturing",
            },
            {
                id: 30,
                name: "Marketing",
            },
            {
                id: 31,
                name: "Media and Entertainment",
            },
            {
                id: 32,
                name: "Medical / Wellness",
            },
            {
                id: 28,
                name: "Middle Management",
            },
            {
                id: 42,
                name: "Military",
            },
            {
                id: 64,
                name: "Movie",
            },
            {
                id: 34,
                name: "Operations",
            },
            {
                id: 67,
                name: "Other Occupations",
            },
            {
                id: 35,
                name: "Partner",
            },
            {
                id: 40,
                name: "Police",
            },
            {
                id: 36,
                name: "Product Development",
            },
            {
                id: 37,
                name: "Production",
            },
            {
                id: 38,
                name: "Project Management",
            },
            {
                id: 39,
                name: "Protection",
            },
            {
                id: 41,
                name: "Public Services",
            },
            {
                id: 43,
                name: "Quality Management",
            },
            {
                id: 44,
                name: "Real Estate",
            },
            {
                id: 45,
                name: "Research and Development",
            },
            {
                id: 46,
                name: "Retail",
            },
            {
                id: 47,
                name: "Retail Banking",
            },
            {
                id: 48,
                name: "Sales",
            },
            {
                id: 49,
                name: "Self Employed",
            },
            {
                id: 50,
                name: "Social Services",
            },
            {
                id: 58,
                name: "Software Development",
            },
            {
                id: 57,
                name: "Sound",
            },
            {
                id: 59,
                name: "Sports / Fitness / Trainer",
            },
            {
                id: 51,
                name: "Student",
            },
            {
                id: 53,
                name: "Supply Chain Management / Logistics",
            },
            {
                id: 61,
                name: "Team lead / Technical lead",
            },
            {
                id: 54,
                name: "Technician",
            },
            {
                id: 16,
                name: "Top Management",
            },
            {
                id: 56,
                name: "Trainee",
            },
            {
                id: 60,
                name: "Transportation Services",
            },
            {
                id: 62,
                name: "University / Academic Affairs",
            },
            {
                id: 63,
                name: "Visual Merchandising",
            },
            {
                id: 65,
                name: "Volunteering",
            },
            {
                id: 66,
                name: "Writer",
            },
            {
                id: 13,
                name: "Diplomacy",
            },
        ],
        schools: [
            {
                id: 10314,
                name: "Academy of Art University",
            },
            {
                id: 3461,
                name: "Acharya Nagarjuna University",
            },
            {
                id: 2576,
                name: "Addis Ababa University",
            },
            {
                id: 11226,
                name: "Adelphi University",
            },
            {
                id: 10538,
                name: "Agnes Scott College",
            },
            {
                id: 10553,
                name: "Albany State University",
            },
            {
                id: 10453,
                name: "American University",
            },
            {
                id: 21751,
                name: "Amity University",
            },
            {
                id: 4110,
                name: "Anna University",
            },
            {
                id: 11340,
                name: "Appalachian State University",
            },
            {
                id: 10234,
                name: "Arizona State University",
            },
            {
                id: 10244,
                name: "Arkansas State University",
            },
            {
                id: 10249,
                name: "Arkansas Tech University",
            },
            {
                id: 4991,
                name: "Art University of Isfahan",
            },
            {
                id: 10904,
                name: "Assumption College",
            },
            {
                id: 8178,
                name: "Ateneo de Davao University",
            },
            {
                id: 8215,
                name: "Ateneo de Manila University",
            },
            {
                id: 10572,
                name: "Atlanta Metropolitan State College",
            },
            {
                id: 10202,
                name: "Auburn University",
            },
            {
                id: 7484,
                name: "Auckland University of Technology",
            },
            {
                id: 11009,
                name: "Augsburg University",
            },
            {
                id: 10535,
                name: "Augusta University",
            },
            {
                id: 11669,
                name: "Augustana University",
            },
            {
                id: 11772,
                name: "Austin College",
            },
            {
                id: 2858,
                name: "Avignon Université",
            },
            {
                id: 10976,
                name: "Baker College",
            },
            {
                id: 10673,
                name: "Ball State University",
            },
            {
                id: 10484,
                name: "Barry University",
            },
            {
                id: 11371,
                name: "Barton College",
            },
            {
                id: 11730,
                name: "Baylor University",
            },
            {
                id: 1389,
                name: "Beijing Language and Culture University",
            },
            {
                id: 11895,
                name: "Bellevue College",
            },
            {
                id: 11113,
                name: "Bellevue University",
            },
            {
                id: 11685,
                name: "Belmont University",
            },
            {
                id: 11016,
                name: "Bemidji State University",
            },
            {
                id: 10759,
                name: "Benedictine College",
            },
            {
                id: 11361,
                name: "Bennett College",
            },
            {
                id: 10892,
                name: "Bentley University",
            },
            {
                id: 18597,
                name: "Berkeley College",
            },
            {
                id: 10880,
                name: "Berklee College of Music",
            },
            {
                id: 10757,
                name: "Bethel College",
            },
            {
                id: 11012,
                name: "Bethel University",
            },
            {
                id: 10705,
                name: "Bethel University",
            },
            {
                id: 10501,
                name: "Bethune-Cookman University",
            },
            {
                id: 10580,
                name: "Beulah Heights University",
            },
            {
                id: 10588,
                name: "Boise State University",
            },
            {
                id: 355,
                name: "Bond University",
            },
            {
                id: 10877,
                name: "Boston College",
            },
            {
                id: 10873,
                name: "Boston University",
            },
            {
                id: 10161,
                name: "Bournemouth University",
            },
            {
                id: 11403,
                name: "Bowling Green State University",
            },
            {
                id: 10611,
                name: "Bradley University",
            },
            {
                id: 10878,
                name: "Brandeis University",
            },
            {
                id: 10554,
                name: "Brenau University",
            },
            {
                id: 10796,
                name: "Brescia University",
            },
            {
                id: 10899,
                name: "Bridgewater State University",
            },
            {
                id: 11816,
                name: "Brigham Young University",
            },
            {
                id: 11267,
                name: "Brooklyn College",
            },
            {
                id: 11257,
                name: "Brooklyn Law School",
            },
            {
                id: 10479,
                name: "Broward College",
            },
            {
                id: 11622,
                name: "Brown University",
            },
            {
                id: 11627,
                name: "Bryant University",
            },
            {
                id: 3422,
                name: "Budapesti Muszaki és Gazdaságtudományi Egyetem",
            },
            {
                id: 11274,
                name: "Buffalo State College",
            },
            {
                id: 10336,
                name: "California Baptist University",
            },
            {
                id: 10326,
                name: "California Institute of Integral Studies",
            },
            {
                id: 10320,
                name: "California Lutheran University",
            },
            {
                id: 10281,
                name: "California State University, Long Beach",
            },
            {
                id: 10318,
                name: "California State University, Monterey Bay",
            },
            {
                id: 10278,
                name: "California State University, Northridge",
            },
            {
                id: 11582,
                name: "California University of Pennsylvania",
            },
            {
                id: 11954,
                name: "Cardinal Stritch University",
            },
            {
                id: 11517,
                name: "Carnegie Mellon University",
            },
            {
                id: 11943,
                name: "Carthage College",
            },
            {
                id: 10959,
                name: "Central Michigan University",
            },
            {
                id: 11442,
                name: "Central State University",
            },
            {
                id: 11889,
                name: "Central Washington University",
            },
            {
                id: 10287,
                name: "Chapman University",
            },
            {
                id: 11696,
                name: "Christian Brothers University",
            },
            {
                id: 11857,
                name: "Christopher Newport University",
            },
            {
                id: 9451,
                name: "Chulalongkorn University",
            },
            {
                id: 1918,
                name: "Civil Aviation University of China",
            },
            {
                id: 10319,
                name: "Claremont McKenna College",
            },
            {
                id: 10544,
                name: "Clark Atlanta University",
            },
            {
                id: 10550,
                name: "Clayton State University",
            },
            {
                id: 11632,
                name: "Clemson University",
            },
            {
                id: 11409,
                name: "Cleveland State University",
            },
            {
                id: 11635,
                name: "College of Charleston",
            },
            {
                id: 11311,
                name: "College of Staten Island",
            },
            {
                id: 10895,
                name: "College of the Holy Cross",
            },
            {
                id: 10403,
                name: "Colorado State University",
            },
            {
                id: 10413,
                name: "Colorado Technical University",
            },
            {
                id: 11060,
                name: "Columbia College",
            },
            {
                id: 10612,
                name: "Columbia College Chicago",
            },
            {
                id: 18514,
                name: "Columbia University in the City of New York",
            },
            {
                id: 10547,
                name: "Columbus State University",
            },
            {
                id: 11017,
                name: "Concordia College",
            },
            {
                id: 1278,
                name: "Concordia University",
            },
            {
                id: 10867,
                name: "Coppin State University",
            },
            {
                id: 11186,
                name: "Cornell University",
            },
            {
                id: 10053,
                name: "Cranfield University",
            },
            {
                id: 1759,
                name: "Dalian Maritime University",
            },
            {
                id: 1755,
                name: "Dalian University of Technology",
            },
            {
                id: 11781,
                name: "Dallas Baptist University",
            },
            {
                id: 9259,
                name: "Damascus University",
            },
            {
                id: 10973,
                name: "Davenport University",
            },
            {
                id: 10502,
                name: "Daytona State College",
            },
            {
                id: 8115,
                name: "De La Salle Lipa",
            },
            {
                id: 10600,
                name: "DePaul University",
            },
            {
                id: 10608,
                name: "DeVry University",
            },
            {
                id: 364,
                name: "Deakin University",
            },
            {
                id: 10933,
                name: "Dean College",
            },
            {
                id: 11039,
                name: "Delta State University",
            },
            {
                id: 23102,
                name: "Deutsche Sporthochschule Köln",
            },
            {
                id: 19148,
                name: "Dixie State University",
            },
            {
                id: 10623,
                name: "Dominican University",
            },
            {
                id: 11520,
                name: "Drexel University",
            },
            {
                id: 11337,
                name: "Duke University",
            },
            {
                id: 11527,
                name: "Duquesne University",
            },
            {
                id: 11343,
                name: "East Carolina University",
            },
            {
                id: 10567,
                name: "East Georgia State College",
            },
            {
                id: 11682,
                name: "East Tennessee State University",
            },
            {
                id: 10775,
                name: "Eastern Kentucky University",
            },
            {
                id: 10961,
                name: "Eastern Michigan University",
            },
            {
                id: 11888,
                name: "Eastern Washington University",
            },
            {
                id: 10489,
                name: "Eckerd College",
            },
            {
                id: 11567,
                name: "Edinboro University of Pennsylvania",
            },
            {
                id: 11344,
                name: "Elon University",
            },
            {
                id: 10472,
                name: "Embry-Riddle Aeronautical University",
            },
            {
                id: 10531,
                name: "Emory University",
            },
            {
                id: 7570,
                name: "Enugu State University of Science and Technology",
            },
            {
                id: 3054,
                name: "Europa-Universität Viadrina Frankfurt (Oder)",
            },
            {
                id: 10520,
                name: "Everglades University",
            },
            {
                id: 10431,
                name: "Fairfield University",
            },
            {
                id: 11158,
                name: "Fairleigh Dickinson University",
            },
            {
                id: 11266,
                name: "Farmingdale State College",
            },
            {
                id: 18535,
                name: "Fashion Institute of Technology",
            },
            {
                id: 3148,
                name: "FernUniversität in Hagen",
            },
            {
                id: 10962,
                name: "Ferris State University",
            },
            {
                id: 11693,
                name: "Fisk University",
            },
            {
                id: 10483,
                name: "Florida Agricultural and Mechanical University",
            },
            {
                id: 10470,
                name: "Florida Atlantic University",
            },
            {
                id: 10478,
                name: "Florida Gulf Coast University",
            },
            {
                id: 10474,
                name: "Florida Institute of Technology",
            },
            {
                id: 10468,
                name: "Florida International University",
            },
            {
                id: 10490,
                name: "Florida Southern College",
            },
            {
                id: 10465,
                name: "Florida State University",
            },
            {
                id: 18520,
                name: "Fordham University",
            },
            {
                id: 10557,
                name: "Fort Valley State University",
            },
            {
                id: 10918,
                name: "Framingham State University",
            },
            {
                id: 3019,
                name: "Freie Universität Berlin",
            },
            {
                id: 1843,
                name: "Fudan University",
            },
            {
                id: 11636,
                name: "Furman University",
            },
            {
                id: 11842,
                name: "George Mason University",
            },
            {
                id: 10451,
                name: "Georgetown University",
            },
            {
                id: 10542,
                name: "Georgia College and State University",
            },
            {
                id: 10560,
                name: "Georgia Gwinnett College",
            },
            {
                id: 10528,
                name: "Georgia Institute of Technology",
            },
            {
                id: 10534,
                name: "Georgia Southern University",
            },
            {
                id: 10530,
                name: "Georgia State University",
            },
            {
                id: 11532,
                name: "Gettysburg College",
            },
            {
                id: 11887,
                name: "Gonzaga University",
            },
            {
                id: 10901,
                name: "Gordon College",
            },
            {
                id: 10571,
                name: "Gordon State College",
            },
            {
                id: 10733,
                name: "Graceland University",
            },
            {
                id: 10237,
                name: "Grand Canyon University",
            },
            {
                id: 10956,
                name: "Grand Valley State University",
            },
            {
                id: 18333,
                name: "Gustavus Adolphus College",
            },
            {
                id: 11007,
                name: "Hamline University",
            },
            {
                id: 11856,
                name: "Hampton University",
            },
            {
                id: 2404,
                name: "Handelshøjskolen i København",
            },
            {
                id: 10248,
                name: "Harding University",
            },
            {
                id: 10872,
                name: "Harvard University",
            },
            {
                id: 10295,
                name: "Harvey Mudd College",
            },
            {
                id: 10583,
                name: "Hawaii Pacific University",
            },
            {
                id: 2619,
                name: "Helsingin yliopisto",
            },
            {
                id: 11953,
                name: "Herzing University",
            },
            {
                id: 11435,
                name: "Hiram College",
            },
            {
                id: 11212,
                name: "Hobart and William Smith Colleges",
            },
            {
                id: 2994,
                name: "Hochschule Weihenstephan-Triesdorf",
            },
            {
                id: 3025,
                name: "Hochschule für Technik und Wirtschaft Berlin",
            },
            {
                id: 11202,
                name: "Hofstra University",
            },
            {
                id: 7469,
                name: "Hogeschool Rotterdam",
            },
            {
                id: 8137,
                name: "Holy Angel University",
            },
            {
                id: 10454,
                name: "Howard University",
            },
            {
                id: 10897,
                name: "Hult International Business School",
            },
            {
                id: 3020,
                name: "Humboldt-Universität zu Berlin",
            },
            {
                id: 9122,
                name: "IE University",
            },
            {
                id: 3271,
                name: "IU Internationale Hochschule",
            },
            {
                id: 10590,
                name: "Idaho State University",
            },
            {
                id: 10601,
                name: "Illinois Institute of Technology",
            },
            {
                id: 10604,
                name: "Illinois State University",
            },
            {
                id: 10676,
                name: "Indiana State University",
            },
            {
                id: 10670,
                name: "Indiana University Bloomington",
            },
            {
                id: 10690,
                name: "Indiana University Southeast",
            },
            {
                id: 11530,
                name: "Indiana University of Pennsylvania",
            },
            {
                id: 10687,
                name: "Indiana Wesleyan University",
            },
            {
                id: 11260,
                name: "Iona College",
            },
            {
                id: 10713,
                name: "Iowa State University",
            },
            {
                id: 4986,
                name: "Isfahan University of Medical Sciences",
            },
            {
                id: 4984,
                name: "Isfahan University of Technology",
            },
            {
                id: 8903,
                name: "Islamic University of Madinah",
            },
            {
                id: 11036,
                name: "Jackson State University",
            },
            {
                id: 10209,
                name: "Jacksonville State University",
            },
            {
                id: 10488,
                name: "Jacksonville University",
            },
            {
                id: 11846,
                name: "James Madison University",
            },
            {
                id: 4164,
                name: "Jawaharlal Nehru Technological University",
            },
            {
                id: 1727,
                name: "Jiangxi University of Finance and Economics",
            },
            {
                id: 18610,
                name: "John Jay College of Criminal Justice",
            },
            {
                id: 11366,
                name: "Johnson C. Smith University",
            },
            {
                id: 11626,
                name: "Johnson and Wales University",
            },
            {
                id: 10975,
                name: "Kalamazoo College",
            },
            {
                id: 5829,
                name: "Kansai Gaidai University",
            },
            {
                id: 10747,
                name: "Kansas State University",
            },
            {
                id: 9452,
                name: "Kasetsart University",
            },
            {
                id: 11164,
                name: "Kean University",
            },
            {
                id: 11142,
                name: "Keene State College",
            },
            {
                id: 10532,
                name: "Kennesaw State University",
            },
            {
                id: 11402,
                name: "Kent State University",
            },
            {
                id: 10782,
                name: "Kentucky State University",
            },
            {
                id: 10968,
                name: "Kettering University",
            },
            {
                id: 9983,
                name: "Khalifa University",
            },
            {
                id: 11694,
                name: "King University",
            },
            {
                id: 10077,
                name: "Kingston University",
            },
            {
                id: 6332,
                name: "Korea National University of Transportation",
            },
            {
                id: 3637,
                name: "Kurukshetra University",
            },
            {
                id: 11741,
                name: "Lamar University",
            },
            {
                id: 11487,
                name: "Langston University",
            },
            {
                id: 443,
                name: "Lauder Business School",
            },
            {
                id: 11775,
                name: "LeTourneau University",
            },
            {
                id: 10900,
                name: "Lesley University",
            },
            {
                id: 3111,
                name: "Leuphana Universität Lüneburg",
            },
            {
                id: 10624,
                name: "Lewis University",
            },
            {
                id: 5327,
                name: "Libera Università di Lingue e Comunicazione IULM",
            },
            {
                id: 11845,
                name: "Liberty University",
            },
            {
                id: 8290,
                name: "Liceo de Cagayan University",
            },
            {
                id: 10551,
                name: "Life University",
            },
            {
                id: 18392,
                name: "Lindenwood University",
            },
            {
                id: 11381,
                name: "Livingstone College",
            },
            {
                id: 11855,
                name: "Longwood University",
            },
            {
                id: 10800,
                name: "Louisiana State University",
            },
            {
                id: 10805,
                name: "Louisiana Tech University",
            },
            {
                id: 10602,
                name: "Loyola University Chicago",
            },
            {
                id: 10802,
                name: "Loyola University New Orleans",
            },
            {
                id: 2976,
                name: "Ludwig-Maximilians-Universität München",
            },
            {
                id: 10719,
                name: "Luther College",
            },
            {
                id: 11002,
                name: "Macalester College",
            },
            {
                id: 10984,
                name: "Madonna University",
            },
            {
                id: 11930,
                name: "Marquette University",
            },
            {
                id: 11909,
                name: "Marshall University",
            },
            {
                id: 11876,
                name: "Mary Baldwin University",
            },
            {
                id: 10871,
                name: "Massachusetts Institute of Technology",
            },
            {
                id: 10930,
                name: "Massachusetts Maritime Academy",
            },
            {
                id: 10536,
                name: "Mercer University",
            },
            {
                id: 11252,
                name: "Mercy College",
            },
            {
                id: 11020,
                name: "Metropolitan State University",
            },
            {
                id: 10411,
                name: "Metropolitan State University of Denver",
            },
            {
                id: 10477,
                name: "Miami Dade College",
            },
            {
                id: 11406,
                name: "Miami University",
            },
            {
                id: 10952,
                name: "Michigan State University",
            },
            {
                id: 10556,
                name: "Middle Georgia State University",
            },
            {
                id: 11681,
                name: "Middle Tennessee State University",
            },
            {
                id: 470,
                name: "Milli Aviasiya Akademiyasi",
            },
            {
                id: 11001,
                name: "Minnesota State University, Mankato",
            },
            {
                id: 11032,
                name: "Mississippi State University",
            },
            {
                id: 11044,
                name: "Mississippi Valley State University",
            },
            {
                id: 11071,
                name: "Missouri Southern State University",
            },
            {
                id: 11055,
                name: "Missouri University of Science and Technology",
            },
            {
                id: 11021,
                name: "Mitchell Hamline School of Law",
            },
            {
                id: 11156,
                name: "Monmouth University",
            },
            {
                id: 18607,
                name: "Monroe College",
            },
            {
                id: 11155,
                name: "Montclair State University",
            },
            {
                id: 10781,
                name: "Morehead State University",
            },
            {
                id: 10540,
                name: "Morehouse College",
            },
            {
                id: 11431,
                name: "Muskingum University",
            },
            {
                id: 6295,
                name: "Myongji University",
            },
            {
                id: 1693,
                name: "Nanjing University",
            },
            {
                id: 1914,
                name: "Nankai University",
            },
            {
                id: 11672,
                name: "National American University",
            },
            {
                id: 17785,
                name: "National Defense University",
            },
            {
                id: 4114,
                name: "National Institute of Technology, Tiruchirappalli",
            },
            {
                id: 309,
                name: "National Polytechnic University of Armenia",
            },
            {
                id: 17643,
                name: "National University",
            },
            {
                id: 10309,
                name: "Naval Postgraduate School",
            },
            {
                id: 11265,
                name: "Nazareth College",
            },
            {
                id: 11182,
                name: "New Mexico Highlands University",
            },
            {
                id: 11236,
                name: "New York Institute of Technology",
            },
            {
                id: 18515,
                name: "New York University",
            },
            {
                id: 11348,
                name: "North Carolina Agricultural and Technical State University",
            },
            {
                id: 11349,
                name: "North Carolina Central University",
            },
            {
                id: 11338,
                name: "North Carolina State University",
            },
            {
                id: 11363,
                name: "North Carolina Wesleyan College",
            },
            {
                id: 11390,
                name: "North Dakota State University",
            },
            {
                id: 523,
                name: "North South University",
            },
            {
                id: 10876,
                name: "Northeastern University",
            },
            {
                id: 10236,
                name: "Northern Arizona University",
            },
            {
                id: 10777,
                name: "Northern Kentucky University",
            },
            {
                id: 10963,
                name: "Northern Michigan University",
            },
            {
                id: 11828,
                name: "Norwich University",
            },
            {
                id: 10471,
                name: "Nova Southeastern University",
            },
            {
                id: 10549,
                name: "Oglethorpe University",
            },
            {
                id: 11404,
                name: "Ohio University",
            },
            {
                id: 11422,
                name: "Ohio Wesleyan University",
            },
            {
                id: 11470,
                name: "Oklahoma State University",
            },
            {
                id: 11847,
                name: "Old Dominion University",
            },
            {
                id: 11493,
                name: "Oregon State University",
            },
            {
                id: 4161,
                name: "Osmania University",
            },
            {
                id: 18527,
                name: "Pace University",
            },
            {
                id: 10505,
                name: "Palm Beach Atlantic University",
            },
            {
                id: 10492,
                name: "Palm Beach State College",
            },
            {
                id: 3541,
                name: "Panjab University",
            },
            {
                id: 11064,
                name: "Park University",
            },
            {
                id: 11613,
                name: "Peirce College",
            },
            {
                id: 1374,
                name: "Peking University",
            },
            {
                id: 11516,
                name: "Penn State University",
            },
            {
                id: 10286,
                name: "Pepperdine University",
            },
            {
                id: 8245,
                name: "Philippine School of Business Administration",
            },
            {
                id: 10561,
                name: "Piedmont College",
            },
            {
                id: 5319,
                name: "Politecnico di Milano",
            },
            {
                id: 10506,
                name: "Polk State College",
            },
            {
                id: 11495,
                name: "Portland State University",
            },
            {
                id: 4006,
                name: "Punjab Technical University",
            },
            {
                id: 10669,
                name: "Purdue University",
            },
            {
                id: 10064,
                name: "Queen Mary University of London",
            },
            {
                id: 10427,
                name: "Quinnipiac University",
            },
            {
                id: 11850,
                name: "Radford University",
            },
            {
                id: 11167,
                name: "Ramapo College of New Jersey",
            },
            {
                id: 18326,
                name: "Rasmussen College",
            },
            {
                id: 10412,
                name: "Regis University",
            },
            {
                id: 1375,
                name: "Renmin University of China",
            },
            {
                id: 11194,
                name: "Rensselaer Polytechnic Institute",
            },
            {
                id: 3138,
                name: "Rheinisch-Westfälische Technische Hochschule Aachen",
            },
            {
                id: 11687,
                name: "Rhodes College",
            },
            {
                id: 10094,
                name: "Richmond, The American International University in London",
            },
            {
                id: 18896,
                name: "Robert Morris University",
            },
            {
                id: 11192,
                name: "Rochester Institute of Technology",
            },
            {
                id: 11104,
                name: "Rocky Mountain College",
            },
            {
                id: 11628,
                name: "Roger Williams University",
            },
            {
                id: 10481,
                name: "Rollins College",
            },
            {
                id: 10617,
                name: "Roosevelt University",
            },
            {
                id: 11245,
                name: "SUNY Polytechnic Institute",
            },
            {
                id: 10972,
                name: "Saginaw Valley State University",
            },
            {
                id: 11536,
                name: "Saint Joseph's University",
            },
            {
                id: 18379,
                name: "Saint Louis University",
            },
            {
                id: 11900,
                name: "Saint Martin's University",
            },
            {
                id: 10693,
                name: "Saint Mary's College",
            },
            {
                id: 11018,
                name: "Saint Mary's University of Minnesota",
            },
            {
                id: 11168,
                name: "Saint Peter's University",
            },
            {
                id: 10903,
                name: "Salem State University",
            },
            {
                id: 10854,
                name: "Salisbury University",
            },
            {
                id: 10277,
                name: "San Diego State University",
            },
            {
                id: 10280,
                name: "San Francisco State University",
            },
            {
                id: 10487,
                name: "Santa Fe College",
            },
            {
                id: 5306,
                name: "Sapienza Università di Roma",
            },
            {
                id: 10533,
                name: "Savannah College of Art and Design",
            },
            {
                id: 10555,
                name: "Savannah State University",
            },
            {
                id: 3869,
                name: "Savitribai Phule Pune University",
            },
            {
                id: 18536,
                name: "School of Visual Arts",
            },
            {
                id: 10614,
                name: "School of the Art Institute of Chicago",
            },
            {
                id: 11891,
                name: "Seattle Pacific University",
            },
            {
                id: 11886,
                name: "Seattle University",
            },
            {
                id: 1855,
                name: "Shanghai Normal University",
            },
            {
                id: 5128,
                name: "Sharif University of Technology",
            },
            {
                id: 10193,
                name: "Sheffield Hallam University",
            },
            {
                id: 11910,
                name: "Shepherd University",
            },
            {
                id: 11535,
                name: "Shippensburg University of Pennsylvania",
            },
            {
                id: 3878,
                name: "Shivaji University",
            },
            {
                id: 10987,
                name: "Siena Heights University",
            },
            {
                id: 9459,
                name: "Silpakorn University",
            },
            {
                id: 10726,
                name: "Simpson College",
            },
            {
                id: 11220,
                name: "Skidmore College",
            },
            {
                id: 5934,
                name: "Sophia University",
            },
            {
                id: 2719,
                name: "Sorbonne Université",
            },
            {
                id: 11643,
                name: "South Carolina State University",
            },
            {
                id: 11666,
                name: "South Dakota State University",
            },
            {
                id: 10807,
                name: "Southeastern Louisiana University",
            },
            {
                id: 10497,
                name: "Southeastern University",
            },
            {
                id: 10605,
                name: "Southern Illinois University Edwardsville",
            },
            {
                id: 11729,
                name: "Southern Methodist University",
            },
            {
                id: 11139,
                name: "Southern New Hampshire University",
            },
            {
                id: 11820,
                name: "Southern Utah University",
            },
            {
                id: 11083,
                name: "Southwest Baptist University",
            },
            {
                id: 10541,
                name: "Spelman College",
            },
            {
                id: 10977,
                name: "Spring Arbor University",
            },
            {
                id: 4149,
                name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya",
            },
            {
                id: 3469,
                name: "Sri Krishnadevaraya University",
            },
            {
                id: 10722,
                name: "St. Ambrose University",
            },
            {
                id: 21940,
                name: "St. Bonaventure University",
            },
            {
                id: 11241,
                name: "St. Bonaventure University",
            },
            {
                id: 11008,
                name: "St. Catherine University",
            },
            {
                id: 18332,
                name: "St. Cloud State University",
            },
            {
                id: 11305,
                name: "St. Francis College",
            },
            {
                id: 11215,
                name: "St. John's University",
            },
            {
                id: 11757,
                name: "St. Mary's University",
            },
            {
                id: 11945,
                name: "St. Norbert College",
            },
            {
                id: 11003,
                name: "St. Olaf College",
            },
            {
                id: 10265,
                name: "Stanford University",
            },
            {
                id: 11228,
                name: "State University of New York at New Paltz",
            },
            {
                id: 11748,
                name: "Stephen F. Austin State University",
            },
            {
                id: 11072,
                name: "Stephens College",
            },
            {
                id: 11163,
                name: "Stockton University",
            },
            {
                id: 11195,
                name: "Stony Brook University",
            },
            {
                id: 10457,
                name: "Strayer University",
            },
            {
                id: 10890,
                name: "Suffolk University",
            },
            {
                id: 2560,
                name: "Tallinna Tehnikaülikool",
            },
            {
                id: 5263,
                name: "Technion - Israel Institute of Technology",
            },
            {
                id: 22937,
                name: "Technische Hochschule Nürnberg Georg Simon Ohm",
            },
            {
                id: 7444,
                name: "Technische Universiteit Eindhoven",
            },
            {
                id: 3021,
                name: "Technische Universität Berlin",
            },
            {
                id: 2975,
                name: "Technische Universität München",
            },
            {
                id: 423,
                name: "Technische Universität Wien",
            },
            {
                id: 11519,
                name: "Temple University",
            },
            {
                id: 11725,
                name: "Texas A&M University",
            },
            {
                id: 11756,
                name: "Texas A&M University-Commerce",
            },
            {
                id: 11747,
                name: "Texas A&M University-Corpus Christi",
            },
            {
                id: 11759,
                name: "Texas Southern University",
            },
            {
                id: 11736,
                name: "Texas State University",
            },
            {
                id: 11731,
                name: "Texas Tech University",
            },
            {
                id: 11783,
                name: "Texas Wesleyan University",
            },
            {
                id: 11752,
                name: "Texas Woman's University",
            },
            {
                id: 16421,
                name: "The American University in Cairo",
            },
            {
                id: 2748,
                name: "The American University of Paris",
            },
            {
                id: 10455,
                name: "The Catholic University of America",
            },
            {
                id: 18609,
                name: "The City College of New York",
            },
            {
                id: 11013,
                name: "The College of St. Scholastica",
            },
            {
                id: 18523,
                name: "The New School",
            },
            {
                id: 11399,
                name: "The Ohio State University",
            },
            {
                id: 10201,
                name: "The University of Alabama",
            },
            {
                id: 10120,
                name: "The University of Edinburgh",
            },
            {
                id: 2888,
                name: "The University of Georgia",
            },
            {
                id: 10195,
                name: "The University of Hull",
            },
            {
                id: 11680,
                name: "The University of Memphis",
            },
            {
                id: 11684,
                name: "The University of Tennessee at Chattanooga",
            },
            {
                id: 11734,
                name: "The University of Texas at Arlington",
            },
            {
                id: 11724,
                name: "The University of Texas at Austin",
            },
            {
                id: 11733,
                name: "The University of Texas at San Antonio",
            },
            {
                id: 11172,
                name: "Thomas Edison State University",
            },
            {
                id: 10790,
                name: "Thomas More University",
            },
            {
                id: 10700,
                name: "Trine University",
            },
            {
                id: 10430,
                name: "Trinity College",
            },
            {
                id: 8238,
                name: "Trinity University of Asia",
            },
            {
                id: 10206,
                name: "Troy University",
            },
            {
                id: 1373,
                name: "Tsinghua University",
            },
            {
                id: 10874,
                name: "Tufts University",
            },
            {
                id: 10801,
                name: "Tulane University",
            },
            {
                id: 10207,
                name: "Tuskegee University",
            },
            {
                id: 2739,
                name: "Télécom Paris",
            },
            {
                id: 11223,
                name: "Union College",
            },
            {
                id: 10405,
                name: "United States Air Force Academy",
            },
            {
                id: 11254,
                name: "United States Merchant Marine Academy",
            },
            {
                id: 10849,
                name: "United States Naval Academy",
            },
            {
                id: 10222,
                name: "United States Sports Academy",
            },
            {
                id: 7212,
                name: "Universidad Autónoma de Yucatán",
            },
            {
                id: 256,
                name: "Universidad Blas Pascal",
            },
            {
                id: 9137,
                name: "Universidad Carlos III de Madrid",
            },
            {
                id: 2032,
                name: "Universidad Externado de Colombia",
            },
            {
                id: 3366,
                name: "Universidad Rafael Landívar",
            },
            {
                id: 221,
                name: "Universidad Torcuato di Tella",
            },
            {
                id: 222,
                name: "Universidad de Belgrano",
            },
            {
                id: 1320,
                name: "Universidad de Chile",
            },
            {
                id: 2450,
                name: "Universidad de Guayaquil",
            },
            {
                id: 9151,
                name: "Universidad de Navarra",
            },
            {
                id: 9115,
                name: "Universidad de Salamanca",
            },
            {
                id: 2054,
                name: "Universidad de San Buenaventura",
            },
            {
                id: 3365,
                name: "Universidad de San Carlos de Guatemala",
            },
            {
                id: 8029,
                name: "Universidad de San Martín de Porres",
            },
            {
                id: 211,
                name: "Universidad del Este",
            },
            {
                id: 3370,
                name: "Universidad del Valle de Guatemala",
            },
            {
                id: 1032,
                name: "Universidade Anhembi Morumbi",
            },
            {
                id: 1019,
                name: "Universidade Estadual de Campinas",
            },
            {
                id: 863,
                name: "Universidade Federal de Alagoas",
            },
            {
                id: 882,
                name: "Universidade Federal do Ceará",
            },
            {
                id: 1029,
                name: "Universidade Metodista de São Paulo",
            },
            {
                id: 701,
                name: "Universiteit Gent",
            },
            {
                id: 7442,
                name: "Universiteit Maastricht",
            },
            {
                id: 7477,
                name: "Universiteit Utrecht",
            },
            {
                id: 87,
                name: "Universiteti i Tiranës",
            },
            {
                id: 10203,
                name: "University of Alabama at Birmingham",
            },
            {
                id: 10242,
                name: "University of Arkansas",
            },
            {
                id: 7483,
                name: "University of Auckland",
            },
            {
                id: 10848,
                name: "University of Baltimore",
            },
            {
                id: 10197,
                name: "University of Bradford",
            },
            {
                id: 10266,
                name: "University of California, Berkeley",
            },
            {
                id: 10270,
                name: "University of California, Davis",
            },
            {
                id: 10271,
                name: "University of California, Irvine",
            },
            {
                id: 10267,
                name: "University of California, Los Angeles",
            },
            {
                id: 10274,
                name: "University of California, Riverside",
            },
            {
                id: 10273,
                name: "University of California, Santa Barbara",
            },
            {
                id: 10275,
                name: "University of California, Santa Cruz",
            },
            {
                id: 10048,
                name: "University of Cambridge",
            },
            {
                id: 9086,
                name: "University of Cape Town",
            },
            {
                id: 10467,
                name: "University of Central Florida",
            },
            {
                id: 11062,
                name: "University of Central Missouri",
            },
            {
                id: 10595,
                name: "University of Chicago",
            },
            {
                id: 11400,
                name: "University of Cincinnati",
            },
            {
                id: 10402,
                name: "University of Colorado Boulder",
            },
            {
                id: 10407,
                name: "University of Colorado Colorado Springs",
            },
            {
                id: 10426,
                name: "University of Connecticut",
            },
            {
                id: 11405,
                name: "University of Dayton",
            },
            {
                id: 10404,
                name: "University of Denver",
            },
            {
                id: 10969,
                name: "University of Detroit Mercy",
            },
            {
                id: 10729,
                name: "University of Dubuque",
            },
            {
                id: 10079,
                name: "University of East London",
            },
            {
                id: 10464,
                name: "University of Florida",
            },
            {
                id: 10529,
                name: "University of Georgia",
            },
            {
                id: 10433,
                name: "University of Hartford",
            },
            {
                id: 11727,
                name: "University of Houston",
            },
            {
                id: 11760,
                name: "University of Houston-Downtown",
            },
            {
                id: 10596,
                name: "University of Illinois at Urbana-Champaign",
            },
            {
                id: 10686,
                name: "University of Indianapolis",
            },
            {
                id: 10714,
                name: "University of Iowa",
            },
            {
                id: 4985,
                name: "University of Isfahan",
            },
            {
                id: 11396,
                name: "University of Jamestown",
            },
            {
                id: 10772,
                name: "University of Kentucky",
            },
            {
                id: 7610,
                name: "University of Lagos",
            },
            {
                id: 10190,
                name: "University of Leeds",
            },
            {
                id: 10042,
                name: "University of Leicester",
            },
            {
                id: 5256,
                name: "University of Limerick",
            },
            {
                id: 10054,
                name: "University of Lincoln",
            },
            {
                id: 10810,
                name: "University of Louisiana at Monroe",
            },
            {
                id: 10773,
                name: "University of Louisville",
            },
            {
                id: 11391,
                name: "University of Mary",
            },
            {
                id: 10875,
                name: "University of Massachusetts Amherst",
            },
            {
                id: 10882,
                name: "University of Massachusetts Boston",
            },
            {
                id: 10469,
                name: "University of Miami",
            },
            {
                id: 10951,
                name: "University of Michigan",
            },
            {
                id: 10958,
                name: "University of Michigan-Dearborn",
            },
            {
                id: 10997,
                name: "University of Minnesota-Twin Cities",
            },
            {
                id: 11033,
                name: "University of Mississippi",
            },
            {
                id: 11051,
                name: "University of Missouri-Kansas City",
            },
            {
                id: 10217,
                name: "University of Montevallo",
            },
            {
                id: 3872,
                name: "University of Mumbai",
            },
            {
                id: 1081,
                name: "University of National and World Economy",
            },
            {
                id: 11129,
                name: "University of Nevada, Reno",
            },
            {
                id: 10804,
                name: "University of New Orleans",
            },
            {
                id: 2311,
                name: "University of Nicosia",
            },
            {
                id: 11357,
                name: "University of North Carolina School of the Arts",
            },
            {
                id: 11352,
                name: "University of North Carolina at Asheville",
            },
            {
                id: 11336,
                name: "University of North Carolina at Chapel Hill",
            },
            {
                id: 11342,
                name: "University of North Carolina at Charlotte",
            },
            {
                id: 11341,
                name: "University of North Carolina at Greensboro",
            },
            {
                id: 11389,
                name: "University of North Dakota",
            },
            {
                id: 10545,
                name: "University of North Georgia",
            },
            {
                id: 11728,
                name: "University of North Texas",
            },
            {
                id: 10409,
                name: "University of Northern Colorado",
            },
            {
                id: 11023,
                name: "University of Northwestern - St. Paul",
            },
            {
                id: 10671,
                name: "University of Notre Dame",
            },
            {
                id: 10138,
                name: "University of Oxford",
            },
            {
                id: 11515,
                name: "University of Pennsylvania",
            },
            {
                id: 11518,
                name: "University of Pittsburgh",
            },
            {
                id: 10144,
                name: "University of Portsmouth",
            },
            {
                id: 11893,
                name: "University of Puget Sound",
            },
            {
                id: 11848,
                name: "University of Richmond",
            },
            {
                id: 11189,
                name: "University of Rochester",
            },
            {
                id: 8155,
                name: "University of San Carlos",
            },
            {
                id: 10285,
                name: "University of San Francisco",
            },
            {
                id: 10208,
                name: "University of South Alabama",
            },
            {
                id: 11633,
                name: "University of South Carolina",
            },
            {
                id: 10466,
                name: "University of South Florida",
            },
            {
                id: 10268,
                name: "University of Southern California",
            },
            {
                id: 10830,
                name: "University of Southern Maine",
            },
            {
                id: 20355,
                name: "University of St Andrews",
            },
            {
                id: 11000,
                name: "University of St. Thomas",
            },
            {
                id: 11776,
                name: "University of St. Thomas",
            },
            {
                id: 10127,
                name: "University of Stirling",
            },
            {
                id: 10142,
                name: "University of Surrey",
            },
            {
                id: 10141,
                name: "University of Sussex",
            },
            {
                id: 11410,
                name: "University of Toledo",
            },
            {
                id: 11840,
                name: "University of Virginia",
            },
            {
                id: 11882,
                name: "University of Washington",
            },
            {
                id: 10537,
                name: "University of West Georgia",
            },
            {
                id: 11931,
                name: "University of Wisconsin-Eau Claire",
            },
            {
                id: 11936,
                name: "University of Wisconsin-La Crosse",
            },
            {
                id: 11928,
                name: "University of Wisconsin-Madison",
            },
            {
                id: 11929,
                name: "University of Wisconsin-Milwaukee",
            },
            {
                id: 11947,
                name: "University of Wisconsin-Parkside",
            },
            {
                id: 11944,
                name: "University of Wisconsin-River Falls",
            },
            {
                id: 11938,
                name: "University of Wisconsin-Stout",
            },
            {
                id: 10187,
                name: "University of Worcester",
            },
            {
                id: 356,
                name: "University of the Sunshine Coast",
            },
            {
                id: 5323,
                name: "Università Cattolica del Sacro Cuore",
            },
            {
                id: 5320,
                name: "Università degli Studi di Milano",
            },
            {
                id: 5303,
                name: "Università degli Studi di Trieste",
            },
            {
                id: 2918,
                name: "Universität Stuttgart",
            },
            {
                id: 2920,
                name: "Universität Ulm",
            },
            {
                id: 422,
                name: "Universität Wien",
            },
            {
                id: 2990,
                name: "Universität der Bundeswehr München",
            },
            {
                id: 3023,
                name: "Universität der Künste Berlin",
            },
            {
                id: 2706,
                name: "Université Catholique de Lille",
            },
            {
                id: 684,
                name: "Université Libre de Bruxelles",
            },
            {
                id: 8958,
                name: "Univerzitet u Beogradu",
            },
            {
                id: 8339,
                name: "Uniwersytet Ekonomiczny w Krakowie",
            },
            {
                id: 15451,
                name: "Uniwersytet Ekonomiczny w Poznaniu",
            },
            {
                id: 9231,
                name: "Uppsala Universitet",
            },
            {
                id: 11817,
                name: "Utah State University",
            },
            {
                id: 11819,
                name: "Utah Valley University",
            },
            {
                id: 10539,
                name: "Valdosta State University",
            },
            {
                id: 10475,
                name: "Valencia College",
            },
            {
                id: 11677,
                name: "Vanderbilt University",
            },
            {
                id: 11312,
                name: "Vaughn College of Aeronautics and Technology",
            },
            {
                id: 11831,
                name: "Vermont Law School",
            },
            {
                id: 11523,
                name: "Villanova University",
            },
            {
                id: 10696,
                name: "Vincennes University",
            },
            {
                id: 11854,
                name: "Virginia Military Institute",
            },
            {
                id: 11872,
                name: "Virginia Union University",
            },
            {
                id: 11339,
                name: "Wake Forest University",
            },
            {
                id: 10985,
                name: "Walsh College",
            },
            {
                id: 5932,
                name: "Waseda University",
            },
            {
                id: 11883,
                name: "Washington State University",
            },
            {
                id: 18376,
                name: "Washington University in St. Louis",
            },
            {
                id: 11849,
                name: "Washington and Lee University",
            },
            {
                id: 11784,
                name: "Wayland Baptist University",
            },
            {
                id: 10954,
                name: "Wayne State University",
            },
            {
                id: 11818,
                name: "Weber State University",
            },
            {
                id: 18383,
                name: "Webster University",
            },
            {
                id: 10428,
                name: "Wesleyan University",
            },
            {
                id: 11911,
                name: "West Virginia State University",
            },
            {
                id: 11908,
                name: "West Virginia University",
            },
            {
                id: 10418,
                name: "Western Colorado University",
            },
            {
                id: 10774,
                name: "Western Kentucky University",
            },
            {
                id: 10955,
                name: "Western Michigan University",
            },
            {
                id: 11884,
                name: "Western Washington University",
            },
            {
                id: 11822,
                name: "Westminster College",
            },
            {
                id: 10609,
                name: "Wheaton College",
            },
            {
                id: 11537,
                name: "Widener University",
            },
            {
                id: 11455,
                name: "Wilberforce University",
            },
            {
                id: 11548,
                name: "Wilkes University",
            },
            {
                id: 11085,
                name: "William Woods University",
            },
            {
                id: 10881,
                name: "Williams College",
            },
            {
                id: 11014,
                name: "Winona State University",
            },
            {
                id: 11638,
                name: "Winthrop University",
            },
            {
                id: 424,
                name: "Wirtschaftsuniversität Wien",
            },
            {
                id: 11642,
                name: "Wofford College",
            },
            {
                id: 10345,
                name: "Woodbury University",
            },
            {
                id: 10879,
                name: "Worcester Polytechnic Institute",
            },
            {
                id: 11408,
                name: "Wright State University",
            },
            {
                id: 11413,
                name: "Xavier University",
            },
            {
                id: 10425,
                name: "Yale University",
            },
            {
                id: 1712,
                name: "Yangzhou University",
            },
            {
                id: 6368,
                name: "Yonsei University",
            },
            {
                id: 1253,
                name: "York University",
            },
            {
                id: 10573,
                name: "Young Harris College",
            },
            {
                id: 1581,
                name: "Zhengzhou University",
            },
            {
                id: 2700,
                name: "École des Hautes Études Commerciales du Nord",
            },
            {
                id: 10130,
                name: "Abertay University",
            },
            {
                id: 376,
                name: "Alpen-Adria-Universität Klagenfurt",
            },
            {
                id: 6524,
                name: "American University of Beirut",
            },
            {
                id: 3265,
                name: "Bauhaus-Universität Weimar",
            },
            {
                id: 3434,
                name: "Budapesti Gazdasági Egyetem",
            },
            {
                id: 3252,
                name: "Christian-Albrechts-Universität zu Kiel",
            },
            {
                id: 10044,
                name: "De Montfort University",
            },
            {
                id: 380,
                name: "Donau-Universität Krems",
            },
            {
                id: 3096,
                name: "EBS Universität für Wirtschaft und Recht",
            },
            {
                id: 2916,
                name: "Eberhard Karls Universität Tübingen",
            },
            {
                id: 3163,
                name: "FOM Hochschule für Oekonomie und Management",
            },
            {
                id: 3155,
                name: "Fachhochschule Münster",
            },
            {
                id: 445,
                name: "Fachhochschule Vorarlberg",
            },
            {
                id: 3256,
                name: "Fachhochschule Wedel",
            },
            {
                id: 3089,
                name: "Frankfurt University of Applied Sciences",
            },
            {
                id: 2977,
                name: "Friedrich-Alexander-Universität Erlangen-Nürnberg",
            },
            {
                id: 3263,
                name: "Friedrich-Schiller-Universität Jena",
            },
            {
                id: 21786,
                name: "Gautam Buddha University",
            },
            {
                id: 3107,
                name: "Georg-August-Universität Göttingen",
            },
            {
                id: 3143,
                name: "Heinrich-Heine-Universität Düsseldorf",
            },
            {
                id: 2960,
                name: "Hochschule Albstadt-Sigmaringen",
            },
            {
                id: 2926,
                name: "Hochschule Karlsruhe",
            },
            {
                id: 2928,
                name: "Hochschule Offenburg",
            },
            {
                id: 2930,
                name: "Hochschule Pforzheim",
            },
            {
                id: 3095,
                name: "Hochschule RheinMain",
            },
            {
                id: 3134,
                name: "Hochschule Wismar",
            },
            {
                id: 3123,
                name: "Hochschule für Bildende Künste Braunschweig",
            },
            {
                id: 3028,
                name: "Hochschule für Wirtschaft und Recht Berlin",
            },
            {
                id: 2987,
                name: "Hochschule für angewandte Wissenschaften Würzburg-Schweinfurt",
            },
            {
                id: 7455,
                name: "Hogeschool van Amsterdam",
            },
            {
                id: 7434,
                name: "Hogeschool van Arnhem en Nijmegen",
            },
            {
                id: 9216,
                name: "Högskolan Kristianstad",
            },
            {
                id: 10645,
                name: "Illinois College",
            },
            {
                id: 2978,
                name: "Julius-Maximilians-Universität Würzburg",
            },
            {
                id: 3088,
                name: "Justus-Liebig-Universität Giessen",
            },
            {
                id: 10063,
                name: "King's College London",
            },
            {
                id: 6592,
                name: "Kolpingo kolegija",
            },
            {
                id: 5062,
                name: "Mazandaran University of Science and Technology",
            },
            {
                id: 426,
                name: "Medizinische Universität Wien",
            },
            {
                id: 8670,
                name: "Mendeleev University of Chemical Technology of Russia",
            },
            {
                id: 10067,
                name: "Middlesex University",
            },
            {
                id: 9872,
                name: "National Aviation University",
            },
            {
                id: 2992,
                name: "Ostbayerische Technische Hochschule Regensburg",
            },
            {
                id: 2980,
                name: "Otto-Friedrich-Universität Bamberg",
            },
            {
                id: 3085,
                name: "Philipps-Universität Marburg",
            },
            {
                id: 2938,
                name: "Pädagogische Hochschule Karlsruhe",
            },
            {
                id: 23133,
                name: "Rheinische Fachhochschule Köln",
            },
            {
                id: 3140,
                name: "Rheinische Friedrich-Wilhelms-Universität Bonn",
            },
            {
                id: 3139,
                name: "Ruhr-Universität Bochum",
            },
            {
                id: 2914,
                name: "Ruprecht-Karls-Universität Heidelberg",
            },
            {
                id: 2945,
                name: "SRH Hochschule Heidelberg",
            },
            {
                id: 9218,
                name: "Stockholms universitet",
            },
            {
                id: 366,
                name: "Swinburne University of Technology",
            },
            {
                id: 2561,
                name: "Tallinna Ülikool",
            },
            {
                id: 3086,
                name: "Technische Universität Darmstadt",
            },
            {
                id: 3224,
                name: "Technische Universität Dresden",
            },
            {
                id: 3264,
                name: "Technische Universität Ilmenau",
            },
            {
                id: 3203,
                name: "Technische Universität Kaiserslautern",
            },
            {
                id: 7003,
                name: "Tecnológico de Monterrey",
            },
            {
                id: 2043,
                name: "Universidad Militar Nueva Granada",
            },
            {
                id: 1987,
                name: "Universidad Pontificia Bolivariana",
            },
            {
                id: 8030,
                name: "Universidad San Ignacio de Loyola",
            },
            {
                id: 7947,
                name: "Universidad San Ignacio de Loyola",
            },
            {
                id: 20006,
                name: "Universidad Simón Bolívar",
            },
            {
                id: 9098,
                name: "Universidad de Córdoba",
            },
            {
                id: 12077,
                name: "Universidad de La Laguna",
            },
            {
                id: 2122,
                name: "Universidad de La Sabana",
            },
            {
                id: 9095,
                name: "Universidad de Sevilla",
            },
            {
                id: 1323,
                name: "Universidad del Desarrollo",
            },
            {
                id: 8530,
                name: "Universitatea Politehnica din Timisoara",
            },
            {
                id: 7233,
                name: "Universitatea Tehnica a Moldovei",
            },
            {
                id: 8529,
                name: "Universitatea de Vest din Timisoara",
            },
            {
                id: 8511,
                name: "Universitatea din Craiova",
            },
            {
                id: 7460,
                name: "Universiteit Twente",
            },
            {
                id: 10291,
                name: "University of San Diego",
            },
            {
                id: 10159,
                name: "University of the West of England",
            },
            {
                id: 5364,
                name: "Università Ca' Foscari di Venezia",
            },
            {
                id: 5363,
                name: "Università degli Studi di Padova",
            },
            {
                id: 2982,
                name: "Universität Augsburg",
            },
            {
                id: 2981,
                name: "Universität Bayreuth",
            },
            {
                id: 3266,
                name: "Universität Erfurt",
            },
            {
                id: 3067,
                name: "Universität Hamburg",
            },
            {
                id: 2922,
                name: "Universität Hohenheim",
            },
            {
                id: 2921,
                name: "Universität Konstanz",
            },
            {
                id: 3225,
                name: "Universität Leipzig",
            },
            {
                id: 2919,
                name: "Universität Mannheim",
            },
            {
                id: 2985,
                name: "Universität Passau",
            },
            {
                id: 3132,
                name: "Universität Rostock",
            },
            {
                id: 3158,
                name: "Universität Witten/Herdecke",
            },
            {
                id: 2633,
                name: "Université Lumière Lyon 2",
            },
            {
                id: 2967,
                name: "VWA-Hochschule für berufsbegleitendes Studium",
            },
            {
                id: 3741,
                name: "Visvesvaraya Technological University",
            },
            {
                id: 3141,
                name: "Westfälische Wilhelms-Universität Münster",
            },
        ],
        languages: [
            {
                id: 9,
                name: "Afrikaans",
            },
            {
                id: 14,
                name: "Albanian",
            },
            {
                id: 20,
                name: "Amharic",
            },
            {
                id: 23,
                name: "Arabic",
            },
            {
                id: 58,
                name: "Bengali",
            },
            {
                id: 70,
                name: "Bokmål, Norwegian",
            },
            {
                id: 71,
                name: "Bosnian",
            },
            {
                id: 80,
                name: "Catalan",
            },
            {
                id: 95,
                name: "Chinese",
            },
            {
                id: 113,
                name: "Creoles and pidgins",
            },
            {
                id: 121,
                name: "Czech",
            },
            {
                id: 136,
                name: "Dutch",
            },
            {
                id: 146,
                name: "English",
            },
            {
                id: 159,
                name: "Finnish",
            },
            {
                id: 163,
                name: "French",
            },
            {
                id: 177,
                name: "German",
            },
            {
                id: 189,
                name: "Greek, Modern (1453-)",
            },
            {
                id: 191,
                name: "Guarani",
            },
            {
                id: 192,
                name: "Gujarati",
            },
            {
                id: 196,
                name: "Haitian Creole",
            },
            {
                id: 199,
                name: "Hebrew",
            },
            {
                id: 203,
                name: "Hindi",
            },
            {
                id: 207,
                name: "Hungarian",
            },
            {
                id: 212,
                name: "Igbo",
            },
            {
                id: 225,
                name: "Iranian languages",
            },
            {
                id: 230,
                name: "Italian",
            },
            {
                id: 231,
                name: "Japanese",
            },
            {
                id: 242,
                name: "Kannada",
            },
            {
                id: 266,
                name: "Korean",
            },
            {
                id: 281,
                name: "Lao",
            },
            {
                id: 282,
                name: "Latin",
            },
            {
                id: 312,
                name: "Malay",
            },
            {
                id: 313,
                name: "Malayalam",
            },
            {
                id: 325,
                name: "Marathi",
            },
            {
                id: 358,
                name: "Nepali",
            },
            {
                id: 372,
                name: "Norwegian",
            },
            {
                id: 405,
                name: "Panjabi",
            },
            {
                id: 410,
                name: "Persian",
            },
            {
                id: 416,
                name: "Polish",
            },
            {
                id: 417,
                name: "Portuguese",
            },
            {
                id: 428,
                name: "Romanian",
            },
            {
                id: 432,
                name: "Russian",
            },
            {
                id: 450,
                name: "Serbian",
            },
            {
                id: 467,
                name: "Slovak",
            },
            {
                id: 470,
                name: "Somali",
            },
            {
                id: 480,
                name: "Spanish",
            },
            {
                id: 489,
                name: "Swedish",
            },
            {
                id: 492,
                name: "Tagalog",
            },
            {
                id: 495,
                name: "Tajik",
            },
            {
                id: 497,
                name: "Tamil",
            },
            {
                id: 499,
                name: "Telugu",
            },
            {
                id: 502,
                name: "Thai",
            },
            {
                id: 512,
                name: "Tonga (Nyasa)",
            },
            {
                id: 513,
                name: "Tonga (Tonga Islands)",
            },
            {
                id: 519,
                name: "Turkish",
            },
            {
                id: 528,
                name: "Ukrainian",
            },
            {
                id: 533,
                name: "Urdu",
            },
            {
                id: 539,
                name: "Vietnamese",
            },
            {
                id: 551,
                name: "Wolof",
            },
            {
                id: 52,
                name: "Basque",
            },
            {
                id: 119,
                name: "Croatian",
            },
            {
                id: 123,
                name: "Danish",
            },
            {
                id: 219,
                name: "Indonesian",
            },
            {
                id: 373,
                name: "Norwegian Bokmål",
            },
            {
                id: 490,
                name: "Swiss German",
            },
        ],
        cities: [
            {
                id: 1288001666,
                name: "Accra",
            },
            {
                id: 1840013688,
                name: "Acworth",
            },
            {
                id: 1840003729,
                name: "Addison",
            },
            {
                id: 1840003226,
                name: "Adrian",
            },
            {
                id: 1840019221,
                name: "Agoura Hills",
            },
            {
                id: 1276256136,
                name: "Ahlen",
            },
            {
                id: 1840000417,
                name: "Albany",
            },
            {
                id: 1840026377,
                name: "Albrightsville",
            },
            {
                id: 1724923163,
                name: "Alicante",
            },
            {
                id: 1840019321,
                name: "Aliso Viejo",
            },
            {
                id: 1840004256,
                name: "Allen",
            },
            {
                id: 1840003967,
                name: "Allen Park",
            },
            {
                id: 1840001044,
                name: "Allentown",
            },
            {
                id: 1840013659,
                name: "Alpharetta",
            },
            {
                id: 1840018366,
                name: "Anacortes",
            },
            {
                id: 1840007066,
                name: "Ankeny",
            },
            {
                id: 1840003172,
                name: "Ann Arbor",
            },
            {
                id: 1756770881,
                name: "Appenzell",
            },
            {
                id: 1840002413,
                name: "Arlington",
            },
            {
                id: 1276069907,
                name: "Aschaffenburg",
            },
            {
                id: 1840013411,
                name: "Asheville",
            },
            {
                id: 1840002274,
                name: "Astoria",
            },
            {
                id: 1840000510,
                name: "Athens",
            },
            {
                id: 1840004026,
                name: "Atlanta",
            },
            {
                id: 1840005335,
                name: "Atlantic Beach",
            },
            {
                id: 1840015535,
                name: "Aubrey",
            },
            {
                id: 1840000319,
                name: "Auburn",
            },
            {
                id: 1276314100,
                name: "Augsburg",
            },
            {
                id: 1840000302,
                name: "Augusta",
            },
            {
                id: 1840000806,
                name: "Aurora",
            },
            {
                id: 1840013689,
                name: "Austell",
            },
            {
                id: 1840003306,
                name: "Austin",
            },
            {
                id: 1840013702,
                name: "Avondale Estates",
            },
            {
                id: 1840019423,
                name: "Azle",
            },
            {
                id: 1276964772,
                name: "Bad Krozingen",
            },
            {
                id: 1840001592,
                name: "Baltimore",
            },
            {
                id: 1356410365,
                name: "Bangalore",
            },
            {
                id: 1724594040,
                name: "Barcelona",
            },
            {
                id: 1840000385,
                name: "Batavia",
            },
            {
                id: 1840013941,
                name: "Baton Rouge",
            },
            {
                id: 1840013818,
                name: "Beaufort",
            },
            {
                id: 1840000723,
                name: "Belleville",
            },
            {
                id: 1840000308,
                name: "Bellevue",
            },
            {
                id: 1840005232,
                name: "Bellmore",
            },
            {
                id: 1276451290,
                name: "Berlin",
            },
            {
                id: 1840007451,
                name: "Beverly Hills",
            },
            {
                id: 1276112885,
                name: "Bielefeld",
            },
            {
                id: 1840019119,
                name: "Big Bear Lake",
            },
            {
                id: 1840001926,
                name: "Bismarck",
            },
            {
                id: 1840000415,
                name: "Bloomfield",
            },
            {
                id: 1840002439,
                name: "Bloomfield Hills",
            },
            {
                id: 1840002970,
                name: "Bloomington",
            },
            {
                id: 1840027142,
                name: "Boise",
            },
            {
                id: 1276807850,
                name: "Bonn",
            },
            {
                id: 1840018454,
                name: "Bonney Lake",
            },
            {
                id: 1840013790,
                name: "Bossier City",
            },
            {
                id: 1840000455,
                name: "Boston",
            },
            {
                id: 1840017449,
                name: "Boulder",
            },
            {
                id: 1840018737,
                name: "Bountiful",
            },
            {
                id: 1276880866,
                name: "Breisach am Rhein",
            },
            {
                id: 1840018410,
                name: "Bremerton",
            },
            {
                id: 1840081606,
                name: "Brick",
            },
            {
                id: 1840003099,
                name: "Brighton",
            },
            {
                id: 1840033999,
                name: "Bronx",
            },
            {
                id: 1840004949,
                name: "Bronxville",
            },
            {
                id: 1840000592,
                name: "Brooklyn",
            },
            {
                id: 1840018781,
                name: "Broomfield",
            },
            {
                id: 1276790584,
                name: "Buggingen",
            },
            {
                id: 1840007022,
                name: "Burbank",
            },
            {
                id: 1840000046,
                name: "Burlington",
            },
            {
                id: 1840006115,
                name: "Burnsville",
            },
            {
                id: 1076562452,
                name: "Caatiba",
            },
            {
                id: 1840003642,
                name: "California",
            },
            {
                id: 1840092506,
                name: "Camp Washington",
            },
            {
                id: 1840000512,
                name: "Canton",
            },
            {
                id: 1840010473,
                name: "Carleton",
            },
            {
                id: 1840004806,
                name: "Carrollton",
            },
            {
                id: 1840000201,
                name: "Carson",
            },
            {
                id: 1840006758,
                name: "Carver",
            },
            {
                id: 1840010109,
                name: "Cary",
            },
            {
                id: 1840001006,
                name: "Centerville",
            },
            {
                id: 1840005028,
                name: "Central Islip",
            },
            {
                id: 1156154785,
                name: "Changning",
            },
            {
                id: 1840016152,
                name: "Chapel Hill",
            },
            {
                id: 1840006196,
                name: "Charleston",
            },
            {
                id: 1840003107,
                name: "Charlotte",
            },
            {
                id: 1840006760,
                name: "Chaska",
            },
            {
                id: 1840000282,
                name: "Chelsea",
            },
            {
                id: 1840000690,
                name: "Chester",
            },
            {
                id: 1840031323,
                name: "Chevy Chase",
            },
            {
                id: 1840000494,
                name: "Chicago",
            },
            {
                id: 1840019115,
                name: "Chino Hills",
            },
            {
                id: 1840057308,
                name: "Church",
            },
            {
                id: 1840003814,
                name: "Cincinnati",
            },
            {
                id: 1840014776,
                name: "Clarkston",
            },
            {
                id: 1840005570,
                name: "Claymont",
            },
            {
                id: 1840006704,
                name: "Clearwater",
            },
            {
                id: 1840006887,
                name: "Clermont",
            },
            {
                id: 1642503974,
                name: "Cluj-Napoca",
            },
            {
                id: 1840018402,
                name: "Coeur d'Alene",
            },
            {
                id: 1840074067,
                name: "College Hill Station",
            },
            {
                id: 1840005971,
                name: "College Park",
            },
            {
                id: 1840019430,
                name: "Colleyville",
            },
            {
                id: 1276015998,
                name: "Cologne",
            },
            {
                id: 1840010999,
                name: "Columbiaville",
            },
            {
                id: 1840001715,
                name: "Columbus",
            },
            {
                id: 1840011429,
                name: "Compton",
            },
            {
                id: 1840067102,
                name: "Connecticut Avenue Estates",
            },
            {
                id: 1840019586,
                name: "Conroe",
            },
            {
                id: 1840001015,
                name: "Conway",
            },
            {
                id: 1840014806,
                name: "Conyers",
            },
            {
                id: 1840019439,
                name: "Coppell",
            },
            {
                id: 1840000264,
                name: "Corona",
            },
            {
                id: 1840019354,
                name: "Coronado",
            },
            {
                id: 1840002910,
                name: "Cottage Grove",
            },
            {
                id: 1840001688,
                name: "Covington",
            },
            {
                id: 1840007091,
                name: "Crown Point",
            },
            {
                id: 1840007103,
                name: "Cumming",
            },
            {
                id: 1840014743,
                name: "Dacula",
            },
            {
                id: 1840014672,
                name: "Dahlonega",
            },
            {
                id: 1840000364,
                name: "Dallas",
            },
            {
                id: 1840003379,
                name: "Dalton",
            },
            {
                id: 1840000854,
                name: "Danville",
            },
            {
                id: 1840000885,
                name: "Dayton",
            },
            {
                id: 1840015068,
                name: "Daytona Beach",
            },
            {
                id: 1840003969,
                name: "Dearborn",
            },
            {
                id: 1840003970,
                name: "Dearborn Heights",
            },
            {
                id: 1840001403,
                name: "Decatur",
            },
            {
                id: 1840015123,
                name: "Delray Beach",
            },
            {
                id: 1840005932,
                name: "Denton",
            },
            {
                id: 1840003714,
                name: "Denver",
            },
            {
                id: 1840003971,
                name: "Detroit",
            },
            {
                id: 1840004129,
                name: "Dexter",
            },
            {
                id: 1276107502,
                name: "Donaueschingen",
            },
            {
                id: 1840014804,
                name: "Douglasville",
            },
            {
                id: 1840003340,
                name: "Downey",
            },
            {
                id: 1840000330,
                name: "Draper",
            },
            {
                id: 1276561036,
                name: "Dresden",
            },
            {
                id: 1840001321,
                name: "Dublin",
            },
            {
                id: 1840007685,
                name: "Duluth",
            },
            {
                id: 1840003541,
                name: "Dumont",
            },
            {
                id: 1840018427,
                name: "Duvall",
            },
            {
                id: 1276615258,
                name: "Düsseldorf",
            },
            {
                id: 1840001982,
                name: "Eagle River",
            },
            {
                id: 1840089730,
                name: "East Fayetteville",
            },
            {
                id: 1840003698,
                name: "East Greenville",
            },
            {
                id: 1840005236,
                name: "East Meadow",
            },
            {
                id: 1840002043,
                name: "Eden",
            },
            {
                id: 1840007821,
                name: "Eden Prairie",
            },
            {
                id: 1840012021,
                name: "Edison",
            },
            {
                id: 1840016991,
                name: "Edwards",
            },
            {
                id: 1840150306,
                name: "El Adobe",
            },
            {
                id: 1840005226,
                name: "Elmont",
            },
            {
                id: 1276708263,
                name: "Emmendingen",
            },
            {
                id: 1840003717,
                name: "Ephrata",
            },
            {
                id: 1840013168,
                name: "Erlanger",
            },
            {
                id: 1756008843,
                name: "Eschlikon",
            },
            {
                id: 1276811337,
                name: "Estenfeld",
            },
            {
                id: 1276445594,
                name: "Ettenheim",
            },
            {
                id: 1276126657,
                name: "Ettlingen",
            },
            {
                id: 1840007823,
                name: "Excelsior",
            },
            {
                id: 1840023463,
                name: "Fairbanks",
            },
            {
                id: 1840002639,
                name: "Fairburn",
            },
            {
                id: 1840000865,
                name: "Fairview",
            },
            {
                id: 1356078517,
                name: "Faridābād",
            },
            {
                id: 1840003686,
                name: "Farmingdale",
            },
            {
                id: 1840002441,
                name: "Farmington",
            },
            {
                id: 1840004320,
                name: "Fayetteville",
            },
            {
                id: 1840019826,
                name: "Federal Way",
            },
            {
                id: 1840002948,
                name: "Fenton",
            },
            {
                id: 1840004748,
                name: "Fishkill",
            },
            {
                id: 1840003973,
                name: "Flat Rock",
            },
            {
                id: 1840002949,
                name: "Flint",
            },
            {
                id: 1840001660,
                name: "Florence",
            },
            {
                id: 1840004867,
                name: "Florida",
            },
            {
                id: 1840121450,
                name: "Flower",
            },
            {
                id: 1840013611,
                name: "Flowery Branch",
            },
            {
                id: 1840003799,
                name: "Folsom",
            },
            {
                id: 1840004636,
                name: "Forest Lake",
            },
            {
                id: 1840014236,
                name: "Fort Lauderdale",
            },
            {
                id: 1840016479,
                name: "Fort Mill",
            },
            {
                id: 1840014226,
                name: "Fort Myers",
            },
            {
                id: 1840014178,
                name: "Fort Pierce",
            },
            {
                id: 1840013923,
                name: "Fort Walton Beach",
            },
            {
                id: 1840020696,
                name: "Fort Worth",
            },
            {
                id: 1276054552,
                name: "Frankfurt",
            },
            {
                id: 1840002748,
                name: "Franklin",
            },
            {
                id: 1840001198,
                name: "Franklin Park",
            },
            {
                id: 1756399413,
                name: "Frauenfeld",
            },
            {
                id: 1276371228,
                name: "Freiburg im Breisgau",
            },
            {
                id: 1840020654,
                name: "Frisco",
            },
            {
                id: 1840004468,
                name: "Gainesville",
            },
            {
                id: 1840008311,
                name: "Garden Grove",
            },
            {
                id: 1840056116,
                name: "Georgia",
            },
            {
                id: 1840019855,
                name: "Gig Harbor",
            },
            {
                id: 1840003037,
                name: "Glendale",
            },
            {
                id: 1276142884,
                name: "Gottenheim",
            },
            {
                id: 1840020749,
                name: "Granbury",
            },
            {
                id: 1840020697,
                name: "Grapevine",
            },
            {
                id: 1840000719,
                name: "Greensboro",
            },
            {
                id: 1840000659,
                name: "Greenville",
            },
            {
                id: 1276215861,
                name: "Grevenbroich",
            },
            {
                id: 1840010823,
                name: "Griffin",
            },
            {
                id: 1840133971,
                name: "Grosse Ile",
            },
            {
                id: 1840003976,
                name: "Grosse Pointe",
            },
            {
                id: 1840003979,
                name: "Grosse Pointe Woods",
            },
            {
                id: 1840013748,
                name: "Grovetown",
            },
            {
                id: 1276398825,
                name: "Gröbenzell",
            },
            {
                id: 1840013920,
                name: "Gulf Breeze",
            },
            {
                id: 1356406239,
                name: "Gūrgaon",
            },
            {
                id: 1276905869,
                name: "Hannöver",
            },
            {
                id: 1840043425,
                name: "Hanscom AFB",
            },
            {
                id: 1840001115,
                name: "Hastings",
            },
            {
                id: 1840029479,
                name: "Hauula",
            },
            {
                id: 1840029572,
                name: "Hawaiian Acres",
            },
            {
                id: 1840003513,
                name: "Hawthorne",
            },
            {
                id: 1840018034,
                name: "Heber",
            },
            {
                id: 1840000198,
                name: "Hebron",
            },
            {
                id: 1840005293,
                name: "Hempstead",
            },
            {
                id: 1840005936,
                name: "Henderson",
            },
            {
                id: 1276492137,
                name: "Herbolzheim",
            },
            {
                id: 1840020155,
                name: "Herriman",
            },
            {
                id: 1276748088,
                name: "Hiddenhausen",
            },
            {
                id: 1124001665,
                name: "Hilton",
            },
            {
                id: 1840004281,
                name: "Hilton",
            },
            {
                id: 1840003599,
                name: "Hoboken",
            },
            {
                id: 1840053479,
                name: "Holliston",
            },
            {
                id: 1840015144,
                name: "Hollywood",
            },
            {
                id: 1344982653,
                name: "Hong Kong",
            },
            {
                id: 1840006749,
                name: "Hopkins",
            },
            {
                id: 1756005911,
                name: "Horn",
            },
            {
                id: 1840003657,
                name: "Houston",
            },
            {
                id: 1840001778,
                name: "Howell",
            },
            {
                id: 1840020578,
                name: "Huntington Beach",
            },
            {
                id: 1840005081,
                name: "Huntington Station",
            },
            {
                id: 1840005061,
                name: "Huntsville",
            },
            {
                id: 1840007577,
                name: "Hurst",
            },
            {
                id: 1840030084,
                name: "Indianapolis",
            },
            {
                id: 1840011457,
                name: "Inglewood",
            },
            {
                id: 1040038924,
                name: "Innsbruck",
            },
            {
                id: 1840007858,
                name: "Inver Grove Heights",
            },
            {
                id: 1840016723,
                name: "Irmo",
            },
            {
                id: 1840014344,
                name: "Irvine",
            },
            {
                id: 1840012637,
                name: "Irving",
            },
            {
                id: 1840018422,
                name: "Issaquah",
            },
            {
                id: 1840004335,
                name: "Jacksonville",
            },
            {
                id: 1840015032,
                name: "Jacksonville Beach",
            },
            {
                id: 1840014787,
                name: "Jersey",
            },
            {
                id: 1840008340,
                name: "Jonesboro",
            },
            {
                id: 1840004321,
                name: "Jordan",
            },
            {
                id: 1840017255,
                name: "Jupiter",
            },
            {
                id: 1276581014,
                name: "Kaiserstuhl",
            },
            {
                id: 1840012501,
                name: "Kansas",
            },
            {
                id: 1276229317,
                name: "Karlsruhe",
            },
            {
                id: 1840018738,
                name: "Kaysville",
            },
            {
                id: 1840006324,
                name: "Keller",
            },
            {
                id: 1840004386,
                name: "Kenmore",
            },
            {
                id: 1840014756,
                name: "Kennesaw",
            },
            {
                id: 1840007751,
                name: "Kent",
            },
            {
                id: 1840040003,
                name: "Kentucky",
            },
            {
                id: 1840014253,
                name: "Key Largo",
            },
            {
                id: 1840029510,
                name: "Koloa",
            },
            {
                id: 1840019866,
                name: "Lacey",
            },
            {
                id: 1840011054,
                name: "Lake Orion",
            },
            {
                id: 1840007859,
                name: "Lakeville",
            },
            {
                id: 1840002969,
                name: "Lancaster",
            },
            {
                id: 1840020364,
                name: "Las Vegas",
            },
            {
                id: 1840074639,
                name: "Latonia",
            },
            {
                id: 1840008523,
                name: "Lawrenceburg",
            },
            {
                id: 1840003299,
                name: "Lawrenceville",
            },
            {
                id: 1840015162,
                name: "Layton",
            },
            {
                id: 1840001040,
                name: "Lebanon",
            },
            {
                id: 1276563678,
                name: "Leipzig",
            },
            {
                id: 1840000320,
                name: "Lewiston",
            },
            {
                id: 1840007924,
                name: "Lewisville",
            },
            {
                id: 1840001689,
                name: "Lexington",
            },
            {
                id: 1840015613,
                name: "Lilburn",
            },
            {
                id: 1840000158,
                name: "Lincoln",
            },
            {
                id: 1840013740,
                name: "Lithia Springs",
            },
            {
                id: 1840015644,
                name: "Lithonia",
            },
            {
                id: 1840003985,
                name: "Livonia",
            },
            {
                id: 1840015705,
                name: "Locust Grove",
            },
            {
                id: 1840006336,
                name: "Logan",
            },
            {
                id: 1840001454,
                name: "Loganville",
            },
            {
                id: 1840000988,
                name: "Long Beach",
            },
            {
                id: 1840053448,
                name: "Longmeadow",
            },
            {
                id: 1840020491,
                name: "Los Angeles",
            },
            {
                id: 1840000964,
                name: "Louisville",
            },
            {
                id: 1276912330,
                name: "Ludwigshafen",
            },
            {
                id: 1756503816,
                name: "Lugano",
            },
            {
                id: 1040745157,
                name: "Lustenau",
            },
            {
                id: 1840015922,
                name: "Lynn Haven",
            },
            {
                id: 1840013690,
                name: "Mableton",
            },
            {
                id: 1840009411,
                name: "Macomb",
            },
            {
                id: 1840009485,
                name: "Macon",
            },
            {
                id: 1840000538,
                name: "Madison",
            },
            {
                id: 1724616994,
                name: "Madrid",
            },
            {
                id: 1276385664,
                name: "Mainz",
            },
            {
                id: 1840000430,
                name: "Malden",
            },
            {
                id: 1840005273,
                name: "Manhasset",
            },
            {
                id: 1840008844,
                name: "Manhattan Beach",
            },
            {
                id: 1608254376,
                name: "Manila",
            },
            {
                id: 1840008975,
                name: "Mankato",
            },
            {
                id: 1840019828,
                name: "Maple Valley",
            },
            {
                id: 1276898599,
                name: "March",
            },
            {
                id: 1840001545,
                name: "Marietta",
            },
            {
                id: 1840017920,
                name: "Marina del Rey",
            },
            {
                id: 1840000220,
                name: "Marion",
            },
            {
                id: 1840008349,
                name: "Maryville",
            },
            {
                id: 1840003104,
                name: "Mason",
            },
            {
                id: 1724901780,
                name: "Mataró",
            },
            {
                id: 1840015706,
                name: "McDonough",
            },
            {
                id: 1840000431,
                name: "Medford",
            },
            {
                id: 1840003986,
                name: "Melvindale",
            },
            {
                id: 1840002422,
                name: "Memphis",
            },
            {
                id: 1276315921,
                name: "Merdingen",
            },
            {
                id: 1840000585,
                name: "Middletown",
            },
            {
                id: 1840012091,
                name: "Midvale",
            },
            {
                id: 1840001041,
                name: "Milford",
            },
            {
                id: 1840000029,
                name: "Milton",
            },
            {
                id: 1840003046,
                name: "Milwaukee",
            },
            {
                id: 1840003824,
                name: "Minneapolis",
            },
            {
                id: 1840007831,
                name: "Minnetonka",
            },
            {
                id: 1840000053,
                name: "Minot",
            },
            {
                id: 1840122841,
                name: "Missouri Branch",
            },
            {
                id: 1840006009,
                name: "Mobile",
            },
            {
                id: 1840004978,
                name: "Montebello",
            },
            {
                id: 1124586170,
                name: "Montréal",
            },
            {
                id: 1840019685,
                name: "Moore",
            },
            {
                id: 1840007872,
                name: "Morgan",
            },
            {
                id: 1840001326,
                name: "Morrisville",
            },
            {
                id: 1840000888,
                name: "Mount Pleasant",
            },
            {
                id: 1840000338,
                name: "Mount Vernon",
            },
            {
                id: 1356226629,
                name: "Mumbai",
            },
            {
                id: 1276692352,
                name: "Munich",
            },
            {
                id: 1276925185,
                name: "Müllheim",
            },
            {
                id: 1840050164,
                name: "Mōʻiliʻili",
            },
            {
                id: 1840008651,
                name: "Nashville",
            },
            {
                id: 1840014591,
                name: "New Bern",
            },
            {
                id: 1840001645,
                name: "New Boston",
            },
            {
                id: 1356215164,
                name: "New Delhi",
            },
            {
                id: 1840004850,
                name: "New Haven",
            },
            {
                id: 1840001839,
                name: "New Orleans",
            },
            {
                id: 1840005704,
                name: "New Windsor",
            },
            {
                id: 1840034016,
                name: "New York",
            },
            {
                id: 1840014833,
                name: "Newnan",
            },
            {
                id: 1840002270,
                name: "Newport",
            },
            {
                id: 1528136727,
                name: "Nijmegen",
            },
            {
                id: 1356149101,
                name: "None",
            },
            {
                id: 1840007767,
                name: "Norcross",
            },
            {
                id: 1840003869,
                name: "Norfolk",
            },
            {
                id: 1840029449,
                name: "North Druid Hills",
            },
            {
                id: 1840069459,
                name: "North Easton",
            },
            {
                id: 1840014632,
                name: "North Little Rock",
            },
            {
                id: 1840015120,
                name: "North Port",
            },
            {
                id: 1840007843,
                name: "North St. Paul",
            },
            {
                id: 1840000799,
                name: "Northfield",
            },
            {
                id: 1840000288,
                name: "Northville",
            },
            {
                id: 1840002423,
                name: "Novi",
            },
            {
                id: 1276166609,
                name: "Nuremberg",
            },
            {
                id: 1840011503,
                name: "Oak Harbor",
            },
            {
                id: 1840005246,
                name: "Oceanside",
            },
            {
                id: 1840015098,
                name: "Ocoee",
            },
            {
                id: 1840008120,
                name: "Ogden",
            },
            {
                id: 1840061984,
                name: "Oklahoma",
            },
            {
                id: 1840034375,
                name: "Olde West Chester",
            },
            {
                id: 1276042758,
                name: "Olpe",
            },
            {
                id: 1840019865,
                name: "Olympia",
            },
            {
                id: 1840011374,
                name: "Onsted",
            },
            {
                id: 1191093484,
                name: "Opatija",
            },
            {
                id: 1840003405,
                name: "Orange",
            },
            {
                id: 1840019858,
                name: "Orting",
            },
            {
                id: 1840018308,
                name: "Palm Springs",
            },
            {
                id: 1840140102,
                name: "Palos Verdes Peninsula",
            },
            {
                id: 1250015082,
                name: "Paris",
            },
            {
                id: 1840009701,
                name: "Park",
            },
            {
                id: 1840000917,
                name: "Park Ridge",
            },
            {
                id: 1840028462,
                name: "Parlin",
            },
            {
                id: 1840005914,
                name: "Pasadena",
            },
            {
                id: 1840089287,
                name: "Peachtree",
            },
            {
                id: 1840015005,
                name: "Pensacola",
            },
            {
                id: 1840020244,
                name: "Petaluma",
            },
            {
                id: 1840000673,
                name: "Philadelphia",
            },
            {
                id: 1840004193,
                name: "Phoenix",
            },
            {
                id: 1840001254,
                name: "Pittsburgh",
            },
            {
                id: 1840001090,
                name: "Plainfield",
            },
            {
                id: 1276760945,
                name: "Planegg",
            },
            {
                id: 1840009258,
                name: "Plano",
            },
            {
                id: 1840009393,
                name: "Pleasanton",
            },
            {
                id: 1840000767,
                name: "Plymouth",
            },
            {
                id: 1840004983,
                name: "Pomona",
            },
            {
                id: 1840015143,
                name: "Pompano Beach",
            },
            {
                id: 1840028957,
                name: "Ponte Vedra Beach",
            },
            {
                id: 1840014755,
                name: "Powder Springs",
            },
            {
                id: 1840008956,
                name: "Prior Lake",
            },
            {
                id: 1840003289,
                name: "Providence",
            },
            {
                id: 1840001807,
                name: "Provo",
            },
            {
                id: 1840021944,
                name: "Queen Creek",
            },
            {
                id: 1840034002,
                name: "Queens",
            },
            {
                id: 1218441993,
                name: "Quito",
            },
            {
                id: 1840012975,
                name: "Raleigh",
            },
            {
                id: 1840020508,
                name: "Rancho Palos Verdes",
            },
            {
                id: 1840008959,
                name: "Red Wing",
            },
            {
                id: 1840020509,
                name: "Redondo Beach",
            },
            {
                id: 1276000325,
                name: "Regensburg",
            },
            {
                id: 1840020121,
                name: "Reno",
            },
            {
                id: 1840019827,
                name: "Renton",
            },
            {
                id: 1276256795,
                name: "Reutlingen",
            },
            {
                id: 1840000457,
                name: "Revere",
            },
            {
                id: 1840014913,
                name: "Richmond Hill",
            },
            {
                id: 1840011284,
                name: "River Forest",
            },
            {
                id: 1840000114,
                name: "Riverdale",
            },
            {
                id: 1840000848,
                name: "Riverside",
            },
            {
                id: 1840003961,
                name: "Riverview",
            },
            {
                id: 1840003858,
                name: "Roanoke",
            },
            {
                id: 1840020717,
                name: "Rockwall",
            },
            {
                id: 1840001930,
                name: "Rogers",
            },
            {
                id: 1840000349,
                name: "Rome",
            },
            {
                id: 1840003963,
                name: "Romulus",
            },
            {
                id: 1840006054,
                name: "Rosemount",
            },
            {
                id: 1840002541,
                name: "Roswell",
            },
            {
                id: 1840002430,
                name: "Royal Oak",
            },
            {
                id: 1840000410,
                name: "Salem",
            },
            {
                id: 1840082816,
                name: "Salt Lake",
            },
            {
                id: 1840015969,
                name: "San Antonio",
            },
            {
                id: 1840037414,
                name: "San Buenaventura",
            },
            {
                id: 1840021990,
                name: "San Diego",
            },
            {
                id: 1840021543,
                name: "San Francisco",
            },
            {
                id: 1840021991,
                name: "San Marcos",
            },
            {
                id: 1840021863,
                name: "San Marino",
            },
            {
                id: 1756343098,
                name: "Sankt Gallen",
            },
            {
                id: 1276113823,
                name: "Sankt Peter",
            },
            {
                id: 1276245699,
                name: "Sankt Wolfgang",
            },
            {
                id: 1840021829,
                name: "Santa Barbara",
            },
            {
                id: 1840019103,
                name: "Santa Cruz",
            },
            {
                id: 1840019736,
                name: "Santa Monica",
            },
            {
                id: 1840053670,
                name: "Saugus",
            },
            {
                id: 1840008957,
                name: "Savage",
            },
            {
                id: 1840009477,
                name: "Savannah",
            },
            {
                id: 1276569928,
                name: "Schallstadt",
            },
            {
                id: 1276002233,
                name: "Schopfheim",
            },
            {
                id: 1840001157,
                name: "Scottdale",
            },
            {
                id: 1840021940,
                name: "Scottsdale",
            },
            {
                id: 1840022197,
                name: "Seabrook",
            },
            {
                id: 1840021117,
                name: "Seattle",
            },
            {
                id: 1840001262,
                name: "Sharpsburg",
            },
            {
                id: 1840004840,
                name: "Shelton",
            },
            {
                id: 1840001864,
                name: "Sherwood",
            },
            {
                id: 1840005845,
                name: "Silver Spring",
            },
            {
                id: 1702341327,
                name: "Singapore",
            },
            {
                id: 1840090595,
                name: "Sioux",
            },
            {
                id: 1840041703,
                name: "Smithtown",
            },
            {
                id: 1840004512,
                name: "Smyrna",
            },
            {
                id: 1840015614,
                name: "Snellville",
            },
            {
                id: 1840021078,
                name: "Snohomish",
            },
            {
                id: 1840021119,
                name: "Snoqualmie",
            },
            {
                id: 1840015658,
                name: "Social Circle",
            },
            {
                id: 1840001056,
                name: "Somerville",
            },
            {
                id: 1840021385,
                name: "South Jordan",
            },
            {
                id: 1840008948,
                name: "South St. Paul",
            },
            {
                id: 1840002431,
                name: "Southfield",
            },
            {
                id: 1840022048,
                name: "Southlake",
            },
            {
                id: 1840018451,
                name: "Spanaway",
            },
            {
                id: 1840021398,
                name: "Spanish Fork",
            },
            {
                id: 1840000466,
                name: "Springfield",
            },
            {
                id: 1840004391,
                name: "Springville",
            },
            {
                id: 1840002267,
                name: "St. Albans",
            },
            {
                id: 1840002611,
                name: "St. Cloud",
            },
            {
                id: 1840001651,
                name: "St. Louis",
            },
            {
                id: 1840006484,
                name: "St. Paul",
            },
            {
                id: 1840003461,
                name: "St. Petersburg",
            },
            {
                id: 1840013874,
                name: "St. Simons",
            },
            {
                id: 1840123202,
                name: "Staten",
            },
            {
                id: 1840015801,
                name: "Statesboro",
            },
            {
                id: 1840000816,
                name: "Stillwater",
            },
            {
                id: 1840002248,
                name: "Stockbridge",
            },
            {
                id: 1840015645,
                name: "Stone Mountain",
            },
            {
                id: 1276278805,
                name: "Straubing",
            },
            {
                id: 1276171358,
                name: "Stuttgart",
            },
            {
                id: 1840003873,
                name: "Suffolk",
            },
            {
                id: 1840015616,
                name: "Suwanee",
            },
            {
                id: 1036074917,
                name: "Sydney",
            },
            {
                id: 1840000378,
                name: "Syracuse",
            },
            {
                id: 1840021129,
                name: "Tacoma",
            },
            {
                id: 1840015913,
                name: "Tallahassee",
            },
            {
                id: 1840003228,
                name: "Tecumseh",
            },
            {
                id: 1840021942,
                name: "Tempe",
            },
            {
                id: 1840015708,
                name: "Texarkana",
            },
            {
                id: 1840022005,
                name: "The Colony",
            },
            {
                id: 1356681152,
                name: "Thāne",
            },
            {
                id: 1642603121,
                name: "Timişoara",
            },
            {
                id: 1642421082,
                name: "Timuş",
            },
            {
                id: 1276001947,
                name: "Titisee-Neustadt",
            },
            {
                id: 1840000572,
                name: "Toledo",
            },
            {
                id: 1840022203,
                name: "Tomball",
            },
            {
                id: 1840021356,
                name: "Tooele",
            },
            {
                id: 1840021873,
                name: "Torrance",
            },
            {
                id: 1840001383,
                name: "Trenton",
            },
            {
                id: 1840000521,
                name: "Troy",
            },
            {
                id: 1840013701,
                name: "Tucker",
            },
            {
                id: 1840022101,
                name: "Tucson",
            },
            {
                id: 1840021533,
                name: "Turlock",
            },
            {
                id: 1840005563,
                name: "Tuscaloosa",
            },
            {
                id: 1276002212,
                name: "Ulm",
            },
            {
                id: 1840006386,
                name: "Union",
            },
            {
                id: 1840052612,
                name: "Utah",
            },
            {
                id: 1840002417,
                name: "Utica",
            },
            {
                id: 1840021498,
                name: "Vacaville",
            },
            {
                id: 1840011638,
                name: "Valparaiso",
            },
            {
                id: 1840021189,
                name: "Vancouver",
            },
            {
                id: 1840003865,
                name: "Victoria",
            },
            {
                id: 1840002150,
                name: "Vienna",
            },
            {
                id: 1840015679,
                name: "Villa Rica",
            },
            {
                id: 1840003871,
                name: "Virginia Beach",
            },
            {
                id: 1840010008,
                name: "Waconia",
            },
            {
                id: 1276011078,
                name: "Waldshut-Tiengen",
            },
            {
                id: 1840000652,
                name: "Warren",
            },
            {
                id: 1840001889,
                name: "Warwick",
            },
            {
                id: 1840000724,
                name: "Washington",
            },
            {
                id: 1840000305,
                name: "Waterville",
            },
            {
                id: 1840022083,
                name: "Waxahachie",
            },
            {
                id: 1840010000,
                name: "Wayzata",
            },
            {
                id: 1840038051,
                name: "West Bloomfield",
            },
            {
                id: 1840001436,
                name: "West Chester",
            },
            {
                id: 1840004852,
                name: "West Haven",
            },
            {
                id: 1840021388,
                name: "West Jordan",
            },
            {
                id: 1840015993,
                name: "West Palm Beach",
            },
            {
                id: 1840011333,
                name: "Western Springs",
            },
            {
                id: 1840000463,
                name: "Westfield",
            },
            {
                id: 1840037458,
                name: "Westlake Village",
            },
            {
                id: 1840005930,
                name: "Weston",
            },
            {
                id: 1840006923,
                name: "Whitmore Lake",
            },
            {
                id: 1840001686,
                name: "Wichita",
            },
            {
                id: 1276324633,
                name: "Wilhelmshaven",
            },
            {
                id: 1840005579,
                name: "Wilmington",
            },
            {
                id: 1840017238,
                name: "Windermere",
            },
            {
                id: 1840015966,
                name: "Winter Park",
            },
            {
                id: 1756362130,
                name: "Winterthur",
            },
            {
                id: 1840010013,
                name: "Winthrop",
            },
            {
                id: 1840000407,
                name: "Wixom",
            },
            {
                id: 1124375825,
                name: "Woodbridge",
            },
            {
                id: 1840004161,
                name: "Woodstock",
            },
            {
                id: 1276233514,
                name: "Würzburg",
            },
            {
                id: 1840006444,
                name: "Yorktown",
            },
            {
                id: 1840001166,
                name: "Youngstown",
            },
            {
                id: 1840000459,
                name: "Ypsilanti",
            },
            {
                id: 1756539143,
                name: "Zürich",
            },
        ],
    },
};

export const temporaryDemographicsSingleViewData = {
    section1: {
        topCitiesPerCompany: {
            categories: [
                "Atlanta",
                "New York",
                "Seattle",
                "Salt Lake",
                "Los Angeles",
                "Detroit",
                "Minneapolis",
                "Cincinnati",
                "St. Paul",
                "Fort Worth",
                "Boston",
                "Brooklyn",
                "Queens",
                "Marietta",
                "Peachtree",
                "Decatur",
                "Washington",
                "Chicago",
                "Dallas",
                "McDonough",
                "Newnan",
                "Smyrna",
                "Fayetteville",
                "San Francisco",
                "Alpharetta",
                "Lakeville",
                "Dearborn",
                "Indianapolis",
                "Nashville",
                "Long Beach",
            ],
            stack: {
                series: [
                    {
                        data: [
                            44.67, 8.77, 5.09, 4.83, 4.41, 4.41, 3.73, 2.78, 2.57, 2.47, 2.2, 1.94, 0.94, 0.94, 0.84,
                            0.84, 0.84, 0.84, 0.73, 0.68, 0.68, 0.68, 0.58, 0.52, 0.52, 0.52, 0.52, 0.47, 0.47, 0.47,
                        ],
                    },
                ],
            },
        },
        countriesAndCitiesDistribution: {
            stack: [
                {
                    country: "United States",
                    categories: [
                        "Atlanta",
                        "New York",
                        "Seattle",
                        "Salt Lake",
                        "Los Angeles",
                        "Detroit",
                        "Minneapolis",
                        "Cincinnati",
                        "St. Paul",
                        "Fort Worth",
                        "Boston",
                        "Brooklyn",
                        "Queens",
                        "Marietta",
                        "Washington",
                        "Peachtree",
                        "Decatur",
                        "Chicago",
                        "Dallas",
                        "Smyrna",
                        "Newnan",
                        "McDonough",
                        "Fayetteville",
                        "San Francisco",
                        "Lakeville",
                        "Dearborn",
                        "Alpharetta",
                        "Nashville",
                        "Long Beach",
                        "Indianapolis",
                        "Sharpsburg",
                        "Park",
                        "Hebron",
                        "Canton",
                        "Baltimore",
                        "Suwanee",
                        "Kennesaw",
                        "Houston",
                        "Fort Lauderdale",
                        "Columbus",
                        "Bronx",
                        "Bountiful",
                        "Ann Arbor",
                        "Stockbridge",
                        "South Jordan",
                        "Jacksonville",
                        "Charlotte",
                        "Athens",
                        "Ypsilanti",
                        "Virginia Beach",
                        "Roswell",
                        "Rosemount",
                        "North St. Paul",
                        "Norcross",
                        "Jonesboro",
                        "Inver Grove Heights",
                        "Gig Harbor",
                        "Federal Way",
                        "Fairburn",
                        "Conyers",
                        "Camp Washington",
                        "Arlington",
                        "West Palm Beach",
                        "Toledo",
                        "Southfield",
                        "Santa Monica",
                        "Redondo Beach",
                        "Providence",
                        "Prior Lake",
                        "Plymouth",
                        "Phoenix",
                        "Philadelphia",
                        "Orange",
                        "Ogden",
                        "Mableton",
                        "Livonia",
                        "Lawrenceville",
                        "Kansas",
                        "Hopkins",
                        "Hollywood",
                        "Herriman",
                        "Florence",
                        "Duluth",
                        "Draper",
                        "Denver",
                        "Cumming",
                        "Bellevue",
                        "Belleville",
                        "Woodstock",
                        "West Jordan",
                        "West Chester",
                        "Union",
                        "Tacoma",
                        "St. Petersburg",
                        "St. Louis",
                        "Silver Spring",
                        "San Diego",
                        "San Buenaventura",
                        "Olympia",
                        "Oceanside",
                        "Newport",
                        "Mason",
                        "Lithonia",
                        "Lilburn",
                        "Lexington",
                        "Jersey",
                        "Irving",
                        "Inglewood",
                        "Franklin",
                        "Florida",
                        "Douglasville",
                        "Covington",
                        "Corona",
                        "California",
                        "Burnsville",
                        "Austin",
                        "Auburn",
                        "Winter Park",
                        "Windermere",
                        "Wayzata",
                        "Trenton",
                        "Torrance",
                        "Tecumseh",
                        "Stone Mountain",
                        "Staten",
                        "Spanaway",
                        "Savannah",
                        "Santa Barbara",
                        "Royal Oak",
                        "Rome",
                        "Roanoke",
                        "Rancho Palos Verdes",
                        "Provo",
                        "Powder Springs",
                        "Pleasanton",
                        "Pensacola",
                        "Pasadena",
                        "Palm Springs",
                        "Olde West Chester",
                        "Novi",
                        "Northville",
                        "New Orleans",
                        "New Boston",
                        "M\u014d\u02bbili\u02bbili",
                        "Mount Vernon",
                        "Mount Pleasant",
                        "Morrisville",
                        "Minnetonka",
                        "Milton",
                        "Milford",
                        "Memphis",
                        "Maryville",
                        "Maple Valley",
                        "Malden",
                        "Madison",
                        "Macon",
                        "Louisville",
                        "Lithia Springs",
                        "Las Vegas",
                        "Lancaster",
                        "Keller",
                        "Issaquah",
                        "Huntsville",
                        "Howell",
                        "Hawthorne",
                        "Hastings",
                        "Griffin",
                        "Greenville",
                        "Greensboro",
                        "Grapevine",
                        "Glendale",
                        "Fort Myers",
                        "Forest Lake",
                        "Flowery Branch",
                        "Fenton",
                        "Farmington",
                        "Elmont",
                        "Edison",
                        "Eden Prairie",
                        "Denton",
                        "Daytona Beach",
                        "Dayton",
                        "Cottage Grove",
                        "Coppell",
                        "Conroe",
                        "Chino Hills",
                        "Chelsea",
                        "Chaska",
                        "Charleston",
                        "Chapel Hill",
                        "Centerville",
                        "Carrollton",
                        "Burlington",
                        "Boise",
                        "Beverly Hills",
                        "Anacortes",
                        "Allen",
                        "Youngstown",
                        "Yorktown",
                        "Wixom",
                        "Winthrop",
                        "Wilmington",
                        "Wichita",
                        "Whitmore Lake",
                        "Weston",
                        "Westlake Village",
                        "Westfield",
                        "Western Springs",
                        "West Haven",
                        "West Bloomfield",
                        "Waxahachie",
                        "Waterville",
                        "Warwick",
                        "Warren",
                        "Waconia",
                        "Villa Rica",
                        "Vienna",
                        "Victoria",
                        "Vancouver",
                        "Valparaiso",
                        "Vacaville",
                        "Utica",
                        "Utah",
                        "Tuscaloosa",
                        "Turlock",
                        "Tucson",
                        "Tucker",
                        "Troy",
                        "Tooele",
                        "Tomball",
                        "The Colony",
                        "Texarkana",
                        "Tempe",
                        "Tallahassee",
                        "Syracuse",
                        "Suffolk",
                        "Stillwater",
                        "Statesboro",
                        "St. Simons",
                        "St. Cloud",
                        "St. Albans",
                        "Springville",
                        "Springfield",
                        "Spanish Fork",
                        "Southlake",
                        "South St. Paul",
                        "Somerville",
                        "Social Circle",
                        "Snoqualmie",
                        "Snohomish",
                        "Snellville",
                        "Smithtown",
                        "Sioux",
                        "Sherwood",
                        "Shelton",
                        "Seabrook",
                        "Scottsdale",
                        "Scottdale",
                        "Savage",
                        "Saugus",
                        "Santa Cruz",
                        "San Marino",
                        "San Marcos",
                        "San Antonio",
                        "Salem",
                        "Romulus",
                        "Rogers",
                        "Rockwall",
                        "Riverview",
                        "Riverside",
                        "Riverdale",
                        "River Forest",
                        "Richmond Hill",
                        "Revere",
                        "Renton",
                        "Reno",
                        "Red Wing",
                        "Raleigh",
                        "Queen Creek",
                        "Ponte Vedra Beach",
                        "Pompano Beach",
                        "Pomona",
                        "Plano",
                        "Plainfield",
                        "Pittsburgh",
                        "Petaluma",
                        "Parlin",
                        "Park Ridge",
                        "Palos Verdes Peninsula",
                        "Orting",
                        "Onsted",
                        "Oklahoma",
                        "Ocoee",
                        "Oak Harbor",
                        "Northfield",
                        "North Port",
                        "North Little Rock",
                        "North Easton",
                        "North Druid Hills",
                        "Norfolk",
                        "New Windsor",
                        "New Haven",
                        "New Bern",
                        "Morgan",
                        "Moore",
                        "Montebello",
                        "Mobile",
                        "Missouri Branch",
                        "Minot",
                        "Milwaukee",
                        "Midvale",
                        "Middletown",
                        "Melvindale",
                        "Medford",
                        "Marion",
                        "Marina del Rey",
                        "Mankato",
                        "Manhattan Beach",
                        "Manhasset",
                        "Macomb",
                        "Lynn Haven",
                        "Longmeadow",
                        "Loganville",
                        "Logan",
                        "Locust Grove",
                        "Lincoln",
                        "Lewisville",
                        "Lewiston",
                        "Lebanon",
                        "Layton",
                        "Lawrenceburg",
                        "Latonia",
                        "Lake Orion",
                        "Lacey",
                        "Koloa",
                        "Key Largo",
                        "Kentucky",
                        "Kent",
                        "Kenmore",
                        "Kaysville",
                        "Jupiter",
                        "Jordan",
                        "Jacksonville Beach",
                        "Irvine",
                        "Irmo",
                        "Hurst",
                        "Huntington Station",
                        "Huntington Beach",
                        "Holliston",
                        "Hoboken",
                        "Hilton",
                        "Henderson",
                        "Hempstead",
                        "Heber",
                        "Hawaiian Acres",
                        "Hauula",
                        "Hanscom AFB",
                        "Gulf Breeze",
                        "Grovetown",
                        "Grosse Pointe Woods",
                        "Grosse Pointe",
                        "Grosse Ile",
                        "Granbury",
                        "Georgia",
                        "Garden Grove",
                        "Gainesville",
                        "Frisco",
                        "Franklin Park",
                        "Fort Walton Beach",
                        "Fort Pierce",
                        "Fort Mill",
                        "Folsom",
                        "Flower",
                        "Flint",
                        "Flat Rock",
                        "Fishkill",
                        "Farmingdale",
                        "Fairview",
                        "Fairbanks",
                        "Excelsior",
                        "Erlanger",
                        "Ephrata",
                        "El Adobe",
                        "Edwards",
                        "Eden",
                        "East Meadow",
                        "East Greenville",
                        "East Fayetteville",
                        "Eagle River",
                        "Duvall",
                        "Dumont",
                        "Dublin",
                        "Downey",
                        "Dexter",
                        "Delray Beach",
                        "Dearborn Heights",
                        "Danville",
                        "Dalton",
                        "Dahlonega",
                        "Dacula",
                        "Crown Point",
                        "Coronado",
                        "Conway",
                        "Connecticut Avenue Estates",
                        "Compton",
                        "Columbiaville",
                        "Colleyville",
                        "College Park",
                        "College Hill Station",
                        "Coeur d'Alene",
                        "Clermont",
                        "Clearwater",
                        "Claymont",
                        "Clarkston",
                        "Church",
                        "Chevy Chase",
                        "Chester",
                        "Central Islip",
                        "Cary",
                        "Carver",
                        "Carson",
                        "Carleton",
                        "Burbank",
                        "Broomfield",
                        "Bronxville",
                        "Brighton",
                        "Brick",
                        "Bremerton",
                        "Boulder",
                        "Bossier City",
                        "Bonney Lake",
                        "Bloomington",
                        "Bloomfield Hills",
                        "Bloomfield",
                        "Bismarck",
                        "Big Bear Lake",
                        "Bellmore",
                        "Beaufort",
                        "Baton Rouge",
                        "Batavia",
                        "Azle",
                        "Avondale Estates",
                        "Austell",
                        "Aurora",
                        "Augusta",
                        "Aubrey",
                        "Atlantic Beach",
                        "Astoria",
                        "Asheville",
                        "Ankeny",
                        "Allentown",
                        "Allen Park",
                        "Aliso Viejo",
                        "Albrightsville",
                        "Albany",
                        "Agoura Hills",
                        "Adrian",
                        "Addison",
                        "Acworth",
                    ],
                    series: {
                        data: [
                            31.05, 6.09, 3.54, 3.36, 3.06, 3.06, 2.59, 1.93, 1.79, 1.71, 1.53, 1.35, 0.66, 0.66, 0.58,
                            0.58, 0.58, 0.58, 0.51, 0.47, 0.47, 0.47, 0.4, 0.36, 0.36, 0.36, 0.36, 0.33, 0.33, 0.33,
                            0.29, 0.29, 0.29, 0.29, 0.29, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.26, 0.22, 0.22,
                            0.22, 0.22, 0.22, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18, 0.18,
                            0.18, 0.18, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15,
                            0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.15, 0.11, 0.11,
                            0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11,
                            0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.11, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07, 0.07,
                            0.07, 0.07, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                            0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04,
                        ],
                    },
                    value: 99.77,
                },
                {
                    country: "India",
                    categories: [
                        "Bangalore",
                        "Th\u0101ne",
                        "None",
                        "New Delhi",
                        "Mumbai",
                        "G\u016brgaon",
                        "Farid\u0101b\u0101d",
                    ],
                    series: { data: [0.18, 0.04, 0.04, 0.04, 0.04, 0.04, 0.04] },
                    value: 0.42,
                },
                { country: "Philippines", categories: ["Manila"], series: { data: [0.07] }, value: 0.07 },
                {
                    country: "Canada",
                    categories: ["Woodbridge", "Montr\u00e9al", "Hilton"],
                    series: { data: [0.04, 0.04, 0.04] },
                    value: 0.12,
                },
                { country: "Australia", categories: ["Sydney"], series: { data: [0.04] }, value: 0.04 },
                { country: "Singapore", categories: ["Singapore"], series: { data: [0.04] }, value: 0.04 },
                { country: "Ecuador", categories: ["Quito"], series: { data: [0.04] }, value: 0.04 },
                { country: "France", categories: ["Paris"], series: { data: [0.04] }, value: 0.04 },
                { country: "Spain", categories: ["Madrid"], series: { data: [0.04] }, value: 0.04 },
                { country: "Switzerland", categories: ["Lugano"], series: { data: [0.04] }, value: 0.04 },
                { country: "Hong Kong", categories: ["Hong Kong"], series: { data: [0.04] }, value: 0.04 },
                { country: "China", categories: ["Changning"], series: { data: [0.04] }, value: 0.04 },
                { country: "Brazil", categories: ["Caatiba"], series: { data: [0.04] }, value: 0.04 },
                { country: "Germany", categories: ["Berlin"], series: { data: [0.04] }, value: 0.04 },
                { country: "Ghana", categories: ["Accra"], series: { data: [0.04] }, value: 0.04 },
            ],
        },
        genderDistributionCurrent: {
            categories: ["Male", "Female", "Unknown"],
            stack: { series: { data: [55.64, 42.31, 2.06] } },
        },
    },
    section2: {
        topCitiesPerJobTitlePerCompany: [
            {
                categories: ["Atlanta", "New York", "Salt Lake", "Seattle", "Los Angeles"],
                stack: { series: [{ data: [3.94, 1.95, 1.25, 1.2, 0.95] }], name: "Transportation Services" },
            },
            {
                categories: ["Atlanta", "New York", "Seattle", "Detroit", "Salt Lake"],
                stack: { series: [{ data: [1.7, 0.82, 0.8, 0.57, 0.5] }], name: "Other Occupations" },
            },
            {
                categories: ["Atlanta", "Salt Lake", "Seattle", "New York", "Fort Worth"],
                stack: { series: [{ data: [0.97, 0.47, 0.37, 0.35, 0.35] }], name: "Customer Services" },
            },
            {
                categories: ["Atlanta", "New York", "Los Angeles", "Detroit", "Cincinnati"],
                stack: { series: [{ data: [2.32, 0.3, 0.3, 0.27, 0.17] }], name: "Middle Management" },
            },
            {
                categories: ["Atlanta", "Los Angeles", "New York", "Seattle", "Salt Lake"],
                stack: { series: [{ data: [1.87, 0.15, 0.12, 0.1, 0.07] }], name: "Marketing" },
            },
            {
                categories: ["Atlanta", "New York", "Fort Worth", "Los Angeles", "Minneapolis"],
                stack: { series: [{ data: [0.65, 0.4, 0.12, 0.12, 0.12] }], name: "Sales" },
            },
        ],
        topJobTitlesPerCityPerCompany: [
            {
                categories: [
                    "Transportation Services",
                    "Middle Management",
                    "Data Science and Analysis",
                    "Marketing",
                    "Other Occupations",
                ],
                stack: { series: [{ data: [3.94, 2.32, 2.04, 1.87, 1.7] }], name: "Atlanta" },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Sales",
                    "Customer Services",
                    "Middle Management",
                ],
                stack: { series: [{ data: [1.95, 0.82, 0.4, 0.35, 0.3] }], name: "New York" },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Customer Services",
                    "Middle Management",
                    "Software Development",
                ],
                stack: { series: [{ data: [1.25, 0.5, 0.47, 0.12, 0.1] }], name: "Salt Lake" },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Customer Services",
                    "Sales",
                    "Operations",
                ],
                stack: { series: [{ data: [1.2, 0.8, 0.37, 0.1, 0.1] }], name: "Seattle" },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Middle Management",
                    "Customer Services",
                    "Operations",
                ],
                stack: { series: [{ data: [0.95, 0.5, 0.3, 0.22, 0.2] }], name: "Los Angeles" },
            },
            {
                categories: [
                    "Transportation Services",
                    "Other Occupations",
                    "Operations",
                    "Customer Services",
                    "Middle Management",
                ],
                stack: { series: [{ data: [0.67, 0.57, 0.35, 0.3, 0.27] }], name: "Detroit" },
            },
        ],
        ageGroupDistribution: {
            categories: ["18-25", "25-35", "35-55", "55+"],
            stack: { series: [{ data: [5.27, 36.27, 43.6, 14.86] }] },
        },
    },
    section3: {
        topEmployeesLocations: {
            stack: [
                {
                    place: "Atlanta, United States",
                    city: "Atlanta, US",
                    cityId: 1840004026,
                    number: 851,
                    percent: 26.95,
                    lat: 45.0017,
                    lng: -84.1536,
                },
                {
                    place: "New York, United States",
                    city: "New York, US",
                    cityId: 1840034016,
                    number: 167,
                    percent: 5.29,
                    lat: 40.6943,
                    lng: -73.9249,
                },
                {
                    place: "Seattle, United States",
                    city: "Seattle, US",
                    cityId: 1840021117,
                    number: 97,
                    percent: 3.07,
                    lat: 47.6211,
                    lng: -122.3244,
                },
                {
                    place: "Salt Lake, United States",
                    city: "Salt Lake, US",
                    cityId: 1840082816,
                    number: 92,
                    percent: 2.91,
                    lat: 34.4534,
                    lng: -108.7698,
                },
                {
                    place: "Detroit, United States",
                    city: "Detroit, US",
                    cityId: 1840003971,
                    number: 84,
                    percent: 2.66,
                    lat: 42.3834,
                    lng: -83.1024,
                },
                {
                    place: "Los Angeles, United States",
                    city: "Los Angeles, US",
                    cityId: 1840020491,
                    number: 84,
                    percent: 2.66,
                    lat: 34.1139,
                    lng: -118.4068,
                },
                {
                    place: "Minneapolis, United States",
                    city: "Minneapolis, US",
                    cityId: 1840003824,
                    number: 71,
                    percent: 2.25,
                    lat: 39.1243,
                    lng: -97.6997,
                },
                {
                    place: "Cincinnati, United States",
                    city: "Cincinnati, US",
                    cityId: 1840003814,
                    number: 53,
                    percent: 1.68,
                    lat: 39.1413,
                    lng: -84.5061,
                },
                {
                    place: "St. Paul, United States",
                    city: "St. Paul, US",
                    cityId: 1840006484,
                    number: 49,
                    percent: 1.55,
                    lat: 36.9068,
                    lng: -82.3162,
                },
                {
                    place: "Fort Worth, United States",
                    city: "Fort Worth, US",
                    cityId: 1840020696,
                    number: 47,
                    percent: 1.49,
                    lat: 32.7811,
                    lng: -97.3473,
                },
                {
                    place: "Boston, United States",
                    city: "Boston, US",
                    cityId: 1840000455,
                    number: 42,
                    percent: 1.33,
                    lat: 42.3188,
                    lng: -71.0846,
                },
                {
                    place: "Brooklyn, United States",
                    city: "Brooklyn, US",
                    cityId: 1840000592,
                    number: 37,
                    percent: 1.17,
                    lat: 41.4349,
                    lng: -81.7498,
                },
                {
                    place: "Queens, United States",
                    city: "Queens, US",
                    cityId: 1840034002,
                    number: 18,
                    percent: 0.57,
                    lat: 40.7498,
                    lng: -73.7976,
                },
                {
                    place: "Marietta, United States",
                    city: "Marietta, US",
                    cityId: 1840001545,
                    number: 18,
                    percent: 0.57,
                    lat: 39.4241,
                    lng: -81.4465,
                },
                {
                    place: "Chicago, United States",
                    city: "Chicago, US",
                    cityId: 1840000494,
                    number: 16,
                    percent: 0.51,
                    lat: 41.8373,
                    lng: -87.6862,
                },
                {
                    place: "Peachtree, United States",
                    city: "Peachtree, US",
                    cityId: 1840089287,
                    number: 16,
                    percent: 0.51,
                    lat: 35.094,
                    lng: -83.9427,
                },
                {
                    place: "Decatur, United States",
                    city: "Decatur, US",
                    cityId: 1840001403,
                    number: 16,
                    percent: 0.51,
                    lat: 34.573,
                    lng: -86.9906,
                },
                {
                    place: "Washington, United States",
                    city: "Washington, US",
                    cityId: 1840000724,
                    number: 16,
                    percent: 0.51,
                    lat: 39.816,
                    lng: -97.0486,
                },
                {
                    place: "Dallas, United States",
                    city: "Dallas, US",
                    cityId: 1840000364,
                    number: 14,
                    percent: 0.44,
                    lat: 43.2379,
                    lng: -99.5175,
                },
                {
                    place: "Smyrna, United States",
                    city: "Smyrna, US",
                    cityId: 1840004512,
                    number: 13,
                    percent: 0.41,
                    lat: 42.6914,
                    lng: -75.6152,
                },
                {
                    place: "Newnan, United States",
                    city: "Newnan, US",
                    cityId: 1840014833,
                    number: 13,
                    percent: 0.41,
                    lat: 33.3766,
                    lng: -84.7761,
                },
                {
                    place: "McDonough, United States",
                    city: "McDonough, US",
                    cityId: 1840015706,
                    number: 13,
                    percent: 0.41,
                    lat: 33.4399,
                    lng: -84.1509,
                },
                {
                    place: "Fayetteville, United States",
                    city: "Fayetteville, US",
                    cityId: 1840004320,
                    number: 11,
                    percent: 0.35,
                    lat: 43.0308,
                    lng: -75.9985,
                },
                {
                    place: "San Francisco, United States",
                    city: "San Francisco, US",
                    cityId: 1840021543,
                    number: 10,
                    percent: 0.32,
                    lat: 37.7562,
                    lng: -122.443,
                },
                {
                    place: "Lakeville, United States",
                    city: "Lakeville, US",
                    cityId: 1840007859,
                    number: 10,
                    percent: 0.32,
                    lat: 44.6774,
                    lng: -93.252,
                },
                {
                    place: "Dearborn, United States",
                    city: "Dearborn, US",
                    cityId: 1840003969,
                    number: 10,
                    percent: 0.32,
                    lat: 42.3127,
                    lng: -83.2129,
                },
                {
                    place: "Alpharetta, United States",
                    city: "Alpharetta, US",
                    cityId: 1840013659,
                    number: 10,
                    percent: 0.32,
                    lat: 34.0704,
                    lng: -84.2739,
                },
                {
                    place: "Nashville, United States",
                    city: "Nashville, US",
                    cityId: 1840008651,
                    number: 9,
                    percent: 0.28,
                    lat: 38.3541,
                    lng: -89.377,
                },
                {
                    place: "Indianapolis, United States",
                    city: "Indianapolis, US",
                    cityId: 1840030084,
                    number: 9,
                    percent: 0.28,
                    lat: 39.7771,
                    lng: -86.1458,
                },
                {
                    place: "Long Beach, United States",
                    city: "Long Beach, US",
                    cityId: 1840000988,
                    number: 9,
                    percent: 0.28,
                    lat: 40.5887,
                    lng: -73.666,
                },
            ],
        },
        percentageOfEmployeesLocationChangeAfterUniversityPerCountry: {
            categories: [
                "Transportation Services",
                "Other Occupations",
                "Customer Services",
                "Middle Management",
                "Marketing",
            ],
            stack: { series: { data: [4.55, 3.61, 4.94, 7.36, 14.08] } },
        },
        percentageOfEmployeesLocationChangeAfterUniversityPerCity: {
            categories: [
                "Transportation Services",
                "Other Occupations",
                "Customer Services",
                "Middle Management",
                "Marketing",
            ],
            stack: { series: { data: [37.68, 40.11, 29.22, 35.5, 39.44] } },
        },
    },
    filters: {
        jobTitles: [
            { id: 1, name: "Accounting" },
            { id: 4, name: "Art" },
            { id: 5, name: "Associate" },
            { id: 3, name: "Automation" },
            { id: 6, name: "Board Member" },
            { id: 2, name: "Business Administration" },
            { id: 7, name: "Business Development" },
            { id: 15, name: "C Level" },
            { id: 8, name: "Coach / Professor / Mentor" },
            { id: 9, name: "Consulting" },
            { id: 10, name: "Corporate Affairs" },
            { id: 11, name: "Craft Trade Workers" },
            { id: 12, name: "Customer Services" },
            { id: 14, name: "Data Science and Analysis" },
            { id: 18, name: "Design" },
            { id: 19, name: "Engineering" },
            { id: 20, name: "Finance" },
            { id: 17, name: "Founders / Shareholders" },
            { id: 22, name: "Human Resources" },
            { id: 23, name: "Information Technology" },
            { id: 24, name: "Insurance" },
            { id: 55, name: "Intern" },
            { id: 25, name: "Investors" },
            { id: 27, name: "Legal Services" },
            { id: 29, name: "Manufacturing" },
            { id: 30, name: "Marketing" },
            { id: 31, name: "Media and Entertainment" },
            { id: 32, name: "Medical / Wellness" },
            { id: 28, name: "Middle Management" },
            { id: 42, name: "Military" },
            { id: 64, name: "Movie" },
            { id: 34, name: "Operations" },
            { id: 67, name: "Other Occupations" },
            { id: 35, name: "Partner" },
            { id: 40, name: "Police" },
            { id: 36, name: "Product Development" },
            { id: 37, name: "Production" },
            { id: 38, name: "Project Management" },
            { id: 39, name: "Protection" },
            { id: 41, name: "Public Services" },
            { id: 43, name: "Quality Management" },
            { id: 44, name: "Real Estate" },
            { id: 45, name: "Research and Development" },
            { id: 46, name: "Retail" },
            { id: 47, name: "Retail Banking" },
            { id: 48, name: "Sales" },
            { id: 49, name: "Self Employed" },
            { id: 50, name: "Social Services" },
            { id: 58, name: "Software Development" },
            { id: 57, name: "Sound" },
            { id: 59, name: "Sports / Fitness / Trainer" },
            { id: 51, name: "Student" },
            { id: 53, name: "Supply Chain Management / Logistics" },
            { id: 61, name: "Team lead / Technical lead" },
            { id: 54, name: "Technician" },
            { id: 16, name: "Top Management" },
            { id: 56, name: "Trainee" },
            { id: 60, name: "Transportation Services" },
            { id: 62, name: "University / Academic Affairs" },
            { id: 63, name: "Visual Merchandising" },
            { id: 65, name: "Volunteering" },
            { id: 66, name: "Writer" },
        ],
        schools: [
            { id: 10314, name: "Academy of Art University" },
            { id: 3461, name: "Acharya Nagarjuna University" },
            { id: 2576, name: "Addis Ababa University" },
            { id: 11226, name: "Adelphi University" },
            { id: 10538, name: "Agnes Scott College" },
            { id: 10553, name: "Albany State University" },
            { id: 10453, name: "American University" },
            { id: 21751, name: "Amity University" },
            { id: 4110, name: "Anna University" },
            { id: 11340, name: "Appalachian State University" },
            { id: 10234, name: "Arizona State University" },
            { id: 10244, name: "Arkansas State University" },
            { id: 10249, name: "Arkansas Tech University" },
            { id: 4991, name: "Art University of Isfahan" },
            { id: 10904, name: "Assumption College" },
            { id: 8178, name: "Ateneo de Davao University" },
            { id: 8215, name: "Ateneo de Manila University" },
            { id: 10572, name: "Atlanta Metropolitan State College" },
            { id: 10202, name: "Auburn University" },
            { id: 7484, name: "Auckland University of Technology" },
            { id: 11009, name: "Augsburg University" },
            { id: 10535, name: "Augusta University" },
            { id: 11669, name: "Augustana University" },
            { id: 11772, name: "Austin College" },
            { id: 2858, name: "Avignon Universit\u00e9" },
            { id: 10976, name: "Baker College" },
            { id: 10673, name: "Ball State University" },
            { id: 10484, name: "Barry University" },
            { id: 11371, name: "Barton College" },
            { id: 11730, name: "Baylor University" },
            { id: 1389, name: "Beijing Language and Culture University" },
            { id: 11895, name: "Bellevue College" },
            { id: 11113, name: "Bellevue University" },
            { id: 11685, name: "Belmont University" },
            { id: 11016, name: "Bemidji State University" },
            { id: 10759, name: "Benedictine College" },
            { id: 11361, name: "Bennett College" },
            { id: 10892, name: "Bentley University" },
            { id: 18597, name: "Berkeley College" },
            { id: 10880, name: "Berklee College of Music" },
            { id: 10757, name: "Bethel College" },
            { id: 10705, name: "Bethel University" },
            { id: 11012, name: "Bethel University" },
            { id: 10501, name: "Bethune-Cookman University" },
            { id: 10580, name: "Beulah Heights University" },
            { id: 10588, name: "Boise State University" },
            { id: 355, name: "Bond University" },
            { id: 10877, name: "Boston College" },
            { id: 10873, name: "Boston University" },
            { id: 10161, name: "Bournemouth University" },
            { id: 11403, name: "Bowling Green State University" },
            { id: 10611, name: "Bradley University" },
            { id: 10878, name: "Brandeis University" },
            { id: 10554, name: "Brenau University" },
            { id: 10796, name: "Brescia University" },
            { id: 10899, name: "Bridgewater State University" },
            { id: 11816, name: "Brigham Young University" },
            { id: 11267, name: "Brooklyn College" },
            { id: 11257, name: "Brooklyn Law School" },
            { id: 10479, name: "Broward College" },
            { id: 11622, name: "Brown University" },
            { id: 11627, name: "Bryant University" },
            { id: 3422, name: "Budapesti Muszaki \u00e9s Gazdas\u00e1gtudom\u00e1nyi Egyetem" },
            { id: 11274, name: "Buffalo State College" },
            { id: 10336, name: "California Baptist University" },
            { id: 10326, name: "California Institute of Integral Studies" },
            { id: 10320, name: "California Lutheran University" },
            { id: 10281, name: "California State University, Long Beach" },
            { id: 10318, name: "California State University, Monterey Bay" },
            { id: 10278, name: "California State University, Northridge" },
            { id: 11582, name: "California University of Pennsylvania" },
            { id: 11954, name: "Cardinal Stritch University" },
            { id: 11517, name: "Carnegie Mellon University" },
            { id: 11943, name: "Carthage College" },
            { id: 10959, name: "Central Michigan University" },
            { id: 11442, name: "Central State University" },
            { id: 11889, name: "Central Washington University" },
            { id: 10287, name: "Chapman University" },
            { id: 11696, name: "Christian Brothers University" },
            { id: 11857, name: "Christopher Newport University" },
            { id: 9451, name: "Chulalongkorn University" },
            { id: 1918, name: "Civil Aviation University of China" },
            { id: 10319, name: "Claremont McKenna College" },
            { id: 10544, name: "Clark Atlanta University" },
            { id: 10550, name: "Clayton State University" },
            { id: 11632, name: "Clemson University" },
            { id: 11409, name: "Cleveland State University" },
            { id: 11635, name: "College of Charleston" },
            { id: 11311, name: "College of Staten Island" },
            { id: 10895, name: "College of the Holy Cross" },
            { id: 10403, name: "Colorado State University" },
            { id: 10413, name: "Colorado Technical University" },
            { id: 11060, name: "Columbia College" },
            { id: 10612, name: "Columbia College Chicago" },
            { id: 18514, name: "Columbia University in the City of New York" },
            { id: 10547, name: "Columbus State University" },
            { id: 11017, name: "Concordia College" },
            { id: 1278, name: "Concordia University" },
            { id: 10867, name: "Coppin State University" },
            { id: 11186, name: "Cornell University" },
            { id: 10053, name: "Cranfield University" },
            { id: 1759, name: "Dalian Maritime University" },
            { id: 1755, name: "Dalian University of Technology" },
            { id: 11781, name: "Dallas Baptist University" },
            { id: 9259, name: "Damascus University" },
            { id: 10973, name: "Davenport University" },
            { id: 10502, name: "Daytona State College" },
            { id: 8115, name: "De La Salle Lipa" },
            { id: 10600, name: "DePaul University" },
            { id: 10608, name: "DeVry University" },
            { id: 364, name: "Deakin University" },
            { id: 10933, name: "Dean College" },
            { id: 11039, name: "Delta State University" },
            { id: 23102, name: "Deutsche Sporthochschule K\u00f6ln" },
            { id: 19148, name: "Dixie State University" },
            { id: 10623, name: "Dominican University" },
            { id: 11520, name: "Drexel University" },
            { id: 11337, name: "Duke University" },
            { id: 11527, name: "Duquesne University" },
            { id: 11343, name: "East Carolina University" },
            { id: 10567, name: "East Georgia State College" },
            { id: 11682, name: "East Tennessee State University" },
            { id: 10775, name: "Eastern Kentucky University" },
            { id: 10961, name: "Eastern Michigan University" },
            { id: 11888, name: "Eastern Washington University" },
            { id: 10489, name: "Eckerd College" },
            { id: 11567, name: "Edinboro University of Pennsylvania" },
            { id: 11344, name: "Elon University" },
            { id: 10472, name: "Embry-Riddle Aeronautical University" },
            { id: 10531, name: "Emory University" },
            { id: 7570, name: "Enugu State University of Science and Technology" },
            { id: 3054, name: "Europa-Universit\u00e4t Viadrina Frankfurt (Oder)" },
            { id: 10520, name: "Everglades University" },
            { id: 10431, name: "Fairfield University" },
            { id: 11158, name: "Fairleigh Dickinson University" },
            { id: 11266, name: "Farmingdale State College" },
            { id: 18535, name: "Fashion Institute of Technology" },
            { id: 3148, name: "FernUniversit\u00e4t in Hagen" },
            { id: 10962, name: "Ferris State University" },
            { id: 11693, name: "Fisk University" },
            { id: 10483, name: "Florida Agricultural and Mechanical University" },
            { id: 10470, name: "Florida Atlantic University" },
            { id: 10478, name: "Florida Gulf Coast University" },
            { id: 10474, name: "Florida Institute of Technology" },
            { id: 10468, name: "Florida International University" },
            { id: 10490, name: "Florida Southern College" },
            { id: 10465, name: "Florida State University" },
            { id: 18520, name: "Fordham University" },
            { id: 10557, name: "Fort Valley State University" },
            { id: 10918, name: "Framingham State University" },
            { id: 3019, name: "Freie Universit\u00e4t Berlin" },
            { id: 1843, name: "Fudan University" },
            { id: 11636, name: "Furman University" },
            { id: 11842, name: "George Mason University" },
            { id: 10451, name: "Georgetown University" },
            { id: 10542, name: "Georgia College and State University" },
            { id: 10560, name: "Georgia Gwinnett College" },
            { id: 10528, name: "Georgia Institute of Technology" },
            { id: 10534, name: "Georgia Southern University" },
            { id: 10530, name: "Georgia State University" },
            { id: 11532, name: "Gettysburg College" },
            { id: 11887, name: "Gonzaga University" },
            { id: 10901, name: "Gordon College" },
            { id: 10571, name: "Gordon State College" },
            { id: 10733, name: "Graceland University" },
            { id: 10237, name: "Grand Canyon University" },
            { id: 10956, name: "Grand Valley State University" },
            { id: 18333, name: "Gustavus Adolphus College" },
            { id: 11007, name: "Hamline University" },
            { id: 11856, name: "Hampton University" },
            { id: 2404, name: "Handelsh\u00f8jskolen i K\u00f8benhavn" },
            { id: 10248, name: "Harding University" },
            { id: 10872, name: "Harvard University" },
            { id: 10295, name: "Harvey Mudd College" },
            { id: 10583, name: "Hawaii Pacific University" },
            { id: 2619, name: "Helsingin yliopisto" },
            { id: 11953, name: "Herzing University" },
            { id: 11435, name: "Hiram College" },
            { id: 11212, name: "Hobart and William Smith Colleges" },
            { id: 2994, name: "Hochschule Weihenstephan-Triesdorf" },
            { id: 3025, name: "Hochschule f\u00fcr Technik und Wirtschaft Berlin" },
            { id: 11202, name: "Hofstra University" },
            { id: 7469, name: "Hogeschool Rotterdam" },
            { id: 8137, name: "Holy Angel University" },
            { id: 10454, name: "Howard University" },
            { id: 10897, name: "Hult International Business School" },
            { id: 3020, name: "Humboldt-Universit\u00e4t zu Berlin" },
            { id: 9122, name: "IE University" },
            { id: 3271, name: "IU Internationale Hochschule" },
            { id: 10590, name: "Idaho State University" },
            { id: 10601, name: "Illinois Institute of Technology" },
            { id: 10604, name: "Illinois State University" },
            { id: 10676, name: "Indiana State University" },
            { id: 10670, name: "Indiana University Bloomington" },
            { id: 10690, name: "Indiana University Southeast" },
            { id: 11530, name: "Indiana University of Pennsylvania" },
            { id: 10687, name: "Indiana Wesleyan University" },
            { id: 11260, name: "Iona College" },
            { id: 10713, name: "Iowa State University" },
            { id: 4986, name: "Isfahan University of Medical Sciences" },
            { id: 4984, name: "Isfahan University of Technology" },
            { id: 8903, name: "Islamic University of Madinah" },
            { id: 11036, name: "Jackson State University" },
            { id: 10209, name: "Jacksonville State University" },
            { id: 10488, name: "Jacksonville University" },
            { id: 11846, name: "James Madison University" },
            { id: 4164, name: "Jawaharlal Nehru Technological University" },
            { id: 1727, name: "Jiangxi University of Finance and Economics" },
            { id: 18610, name: "John Jay College of Criminal Justice" },
            { id: 11366, name: "Johnson C. Smith University" },
            { id: 11626, name: "Johnson and Wales University" },
            { id: 10975, name: "Kalamazoo College" },
            { id: 5829, name: "Kansai Gaidai University" },
            { id: 10747, name: "Kansas State University" },
            { id: 9452, name: "Kasetsart University" },
            { id: 11164, name: "Kean University" },
            { id: 11142, name: "Keene State College" },
            { id: 10532, name: "Kennesaw State University" },
            { id: 11402, name: "Kent State University" },
            { id: 10782, name: "Kentucky State University" },
            { id: 10968, name: "Kettering University" },
            { id: 9983, name: "Khalifa University" },
            { id: 11694, name: "King University" },
            { id: 10077, name: "Kingston University" },
            { id: 6332, name: "Korea National University of Transportation" },
            { id: 3637, name: "Kurukshetra University" },
            { id: 11741, name: "Lamar University" },
            { id: 11487, name: "Langston University" },
            { id: 443, name: "Lauder Business School" },
            { id: 11775, name: "LeTourneau University" },
            { id: 10900, name: "Lesley University" },
            { id: 3111, name: "Leuphana Universit\u00e4t L\u00fcneburg" },
            { id: 10624, name: "Lewis University" },
            { id: 5327, name: "Libera Universit\u00e0 di Lingue e Comunicazione IULM" },
            { id: 11845, name: "Liberty University" },
            { id: 8290, name: "Liceo de Cagayan University" },
            { id: 10551, name: "Life University" },
            { id: 18392, name: "Lindenwood University" },
            { id: 11381, name: "Livingstone College" },
            { id: 11855, name: "Longwood University" },
            { id: 10800, name: "Louisiana State University" },
            { id: 10805, name: "Louisiana Tech University" },
            { id: 10602, name: "Loyola University Chicago" },
            { id: 10802, name: "Loyola University New Orleans" },
            { id: 2976, name: "Ludwig-Maximilians-Universit\u00e4t M\u00fcnchen" },
            { id: 10719, name: "Luther College" },
            { id: 11002, name: "Macalester College" },
            { id: 10984, name: "Madonna University" },
            { id: 11930, name: "Marquette University" },
            { id: 11909, name: "Marshall University" },
            { id: 11876, name: "Mary Baldwin University" },
            { id: 10871, name: "Massachusetts Institute of Technology" },
            { id: 10930, name: "Massachusetts Maritime Academy" },
            { id: 10536, name: "Mercer University" },
            { id: 11252, name: "Mercy College" },
            { id: 11020, name: "Metropolitan State University" },
            { id: 10411, name: "Metropolitan State University of Denver" },
            { id: 10477, name: "Miami Dade College" },
            { id: 11406, name: "Miami University" },
            { id: 10952, name: "Michigan State University" },
            { id: 10556, name: "Middle Georgia State University" },
            { id: 11681, name: "Middle Tennessee State University" },
            { id: 470, name: "Milli Aviasiya Akademiyasi" },
            { id: 11001, name: "Minnesota State University, Mankato" },
            { id: 11032, name: "Mississippi State University" },
            { id: 11044, name: "Mississippi Valley State University" },
            { id: 11071, name: "Missouri Southern State University" },
            { id: 11055, name: "Missouri University of Science and Technology" },
            { id: 11021, name: "Mitchell Hamline School of Law" },
            { id: 11156, name: "Monmouth University" },
            { id: 18607, name: "Monroe College" },
            { id: 11155, name: "Montclair State University" },
            { id: 10781, name: "Morehead State University" },
            { id: 10540, name: "Morehouse College" },
            { id: 11431, name: "Muskingum University" },
            { id: 6295, name: "Myongji University" },
            { id: 1693, name: "Nanjing University" },
            { id: 1914, name: "Nankai University" },
            { id: 11672, name: "National American University" },
            { id: 17785, name: "National Defense University" },
            { id: 4114, name: "National Institute of Technology, Tiruchirappalli" },
            { id: 309, name: "National Polytechnic University of Armenia" },
            { id: 17643, name: "National University" },
            { id: 10309, name: "Naval Postgraduate School" },
            { id: 11265, name: "Nazareth College" },
            { id: 11182, name: "New Mexico Highlands University" },
            { id: 11236, name: "New York Institute of Technology" },
            { id: 18515, name: "New York University" },
            { id: 11348, name: "North Carolina Agricultural and Technical State University" },
            { id: 11349, name: "North Carolina Central University" },
            { id: 11338, name: "North Carolina State University" },
            { id: 11363, name: "North Carolina Wesleyan College" },
            { id: 11390, name: "North Dakota State University" },
            { id: 523, name: "North South University" },
            { id: 10876, name: "Northeastern University" },
            { id: 10236, name: "Northern Arizona University" },
            { id: 10777, name: "Northern Kentucky University" },
            { id: 10963, name: "Northern Michigan University" },
            { id: 11828, name: "Norwich University" },
            { id: 10471, name: "Nova Southeastern University" },
            { id: 10549, name: "Oglethorpe University" },
            { id: 11404, name: "Ohio University" },
            { id: 11422, name: "Ohio Wesleyan University" },
            { id: 11470, name: "Oklahoma State University" },
            { id: 11847, name: "Old Dominion University" },
            { id: 11493, name: "Oregon State University" },
            { id: 4161, name: "Osmania University" },
            { id: 18527, name: "Pace University" },
            { id: 10505, name: "Palm Beach Atlantic University" },
            { id: 10492, name: "Palm Beach State College" },
            { id: 3541, name: "Panjab University" },
            { id: 11064, name: "Park University" },
            { id: 11613, name: "Peirce College" },
            { id: 1374, name: "Peking University" },
            { id: 11516, name: "Penn State University" },
            { id: 10286, name: "Pepperdine University" },
            { id: 8245, name: "Philippine School of Business Administration" },
            { id: 10561, name: "Piedmont College" },
            { id: 5319, name: "Politecnico di Milano" },
            { id: 10506, name: "Polk State College" },
            { id: 11495, name: "Portland State University" },
            { id: 4006, name: "Punjab Technical University" },
            { id: 10669, name: "Purdue University" },
            { id: 10064, name: "Queen Mary University of London" },
            { id: 10427, name: "Quinnipiac University" },
            { id: 11850, name: "Radford University" },
            { id: 11167, name: "Ramapo College of New Jersey" },
            { id: 18326, name: "Rasmussen College" },
            { id: 10412, name: "Regis University" },
            { id: 1375, name: "Renmin University of China" },
            { id: 11194, name: "Rensselaer Polytechnic Institute" },
            { id: 3138, name: "Rheinisch-Westf\u00e4lische Technische Hochschule Aachen" },
            { id: 11687, name: "Rhodes College" },
            { id: 10094, name: "Richmond, The American International University in London" },
            { id: 18896, name: "Robert Morris University" },
            { id: 11192, name: "Rochester Institute of Technology" },
            { id: 11104, name: "Rocky Mountain College" },
            { id: 11628, name: "Roger Williams University" },
            { id: 10481, name: "Rollins College" },
            { id: 10617, name: "Roosevelt University" },
            { id: 11245, name: "SUNY Polytechnic Institute" },
            { id: 10972, name: "Saginaw Valley State University" },
            { id: 11536, name: "Saint Joseph's University" },
            { id: 18379, name: "Saint Louis University" },
            { id: 11900, name: "Saint Martin's University" },
            { id: 10693, name: "Saint Mary's College" },
            { id: 11018, name: "Saint Mary's University of Minnesota" },
            { id: 11168, name: "Saint Peter's University" },
            { id: 10903, name: "Salem State University" },
            { id: 10854, name: "Salisbury University" },
            { id: 10277, name: "San Diego State University" },
            { id: 10280, name: "San Francisco State University" },
            { id: 10487, name: "Santa Fe College" },
            { id: 5306, name: "Sapienza Universit\u00e0 di Roma" },
            { id: 10533, name: "Savannah College of Art and Design" },
            { id: 10555, name: "Savannah State University" },
            { id: 3869, name: "Savitribai Phule Pune University" },
            { id: 18536, name: "School of Visual Arts" },
            { id: 10614, name: "School of the Art Institute of Chicago" },
            { id: 11891, name: "Seattle Pacific University" },
            { id: 11886, name: "Seattle University" },
            { id: 1855, name: "Shanghai Normal University" },
            { id: 5128, name: "Sharif University of Technology" },
            { id: 10193, name: "Sheffield Hallam University" },
            { id: 11910, name: "Shepherd University" },
            { id: 11535, name: "Shippensburg University of Pennsylvania" },
            { id: 3878, name: "Shivaji University" },
            { id: 10987, name: "Siena Heights University" },
            { id: 9459, name: "Silpakorn University" },
            { id: 10726, name: "Simpson College" },
            { id: 11220, name: "Skidmore College" },
            { id: 5934, name: "Sophia University" },
            { id: 2719, name: "Sorbonne Universit\u00e9" },
            { id: 11643, name: "South Carolina State University" },
            { id: 11666, name: "South Dakota State University" },
            { id: 10807, name: "Southeastern Louisiana University" },
            { id: 10497, name: "Southeastern University" },
            { id: 10605, name: "Southern Illinois University Edwardsville" },
            { id: 11729, name: "Southern Methodist University" },
            { id: 11139, name: "Southern New Hampshire University" },
            { id: 11820, name: "Southern Utah University" },
            { id: 11083, name: "Southwest Baptist University" },
            { id: 10541, name: "Spelman College" },
            { id: 10977, name: "Spring Arbor University" },
            { id: 4149, name: "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya" },
            { id: 3469, name: "Sri Krishnadevaraya University" },
            { id: 10722, name: "St. Ambrose University" },
            { id: 21940, name: "St. Bonaventure University" },
            { id: 11241, name: "St. Bonaventure University" },
            { id: 11008, name: "St. Catherine University" },
            { id: 18332, name: "St. Cloud State University" },
            { id: 11305, name: "St. Francis College" },
            { id: 11215, name: "St. John's University" },
            { id: 11757, name: "St. Mary's University" },
            { id: 11945, name: "St. Norbert College" },
            { id: 11003, name: "St. Olaf College" },
            { id: 10265, name: "Stanford University" },
            { id: 11228, name: "State University of New York at New Paltz" },
            { id: 11748, name: "Stephen F. Austin State University" },
            { id: 11072, name: "Stephens College" },
            { id: 11163, name: "Stockton University" },
            { id: 11195, name: "Stony Brook University" },
            { id: 10457, name: "Strayer University" },
            { id: 10890, name: "Suffolk University" },
            { id: 2560, name: "Tallinna Tehnika\u00fclikool" },
            { id: 5263, name: "Technion - Israel Institute of Technology" },
            { id: 22937, name: "Technische Hochschule N\u00fcrnberg Georg Simon Ohm" },
            { id: 7444, name: "Technische Universiteit Eindhoven" },
            { id: 3021, name: "Technische Universit\u00e4t Berlin" },
            { id: 2975, name: "Technische Universit\u00e4t M\u00fcnchen" },
            { id: 423, name: "Technische Universit\u00e4t Wien" },
            { id: 11519, name: "Temple University" },
            { id: 11725, name: "Texas A&M University" },
            { id: 11756, name: "Texas A&M University-Commerce" },
            { id: 11747, name: "Texas A&M University-Corpus Christi" },
            { id: 11759, name: "Texas Southern University" },
            { id: 11736, name: "Texas State University" },
            { id: 11731, name: "Texas Tech University" },
            { id: 11783, name: "Texas Wesleyan University" },
            { id: 11752, name: "Texas Woman's University" },
            { id: 16421, name: "The American University in Cairo" },
            { id: 2748, name: "The American University of Paris" },
            { id: 10455, name: "The Catholic University of America" },
            { id: 18609, name: "The City College of New York" },
            { id: 11013, name: "The College of St. Scholastica" },
            { id: 18523, name: "The New School" },
            { id: 11399, name: "The Ohio State University" },
            { id: 10201, name: "The University of Alabama" },
            { id: 10120, name: "The University of Edinburgh" },
            { id: 2888, name: "The University of Georgia" },
            { id: 10195, name: "The University of Hull" },
            { id: 11680, name: "The University of Memphis" },
            { id: 11684, name: "The University of Tennessee at Chattanooga" },
            { id: 11734, name: "The University of Texas at Arlington" },
            { id: 11724, name: "The University of Texas at Austin" },
            { id: 11733, name: "The University of Texas at San Antonio" },
            { id: 11172, name: "Thomas Edison State University" },
            { id: 10790, name: "Thomas More University" },
            { id: 10700, name: "Trine University" },
            { id: 10430, name: "Trinity College" },
            { id: 8238, name: "Trinity University of Asia" },
            { id: 10206, name: "Troy University" },
            { id: 1373, name: "Tsinghua University" },
            { id: 10874, name: "Tufts University" },
            { id: 10801, name: "Tulane University" },
            { id: 10207, name: "Tuskegee University" },
            { id: 2739, name: "T\u00e9l\u00e9com Paris" },
            { id: 11223, name: "Union College" },
            { id: 10405, name: "United States Air Force Academy" },
            { id: 11254, name: "United States Merchant Marine Academy" },
            { id: 10849, name: "United States Naval Academy" },
            { id: 10222, name: "United States Sports Academy" },
            { id: 7212, name: "Universidad Aut\u00f3noma de Yucat\u00e1n" },
            { id: 256, name: "Universidad Blas Pascal" },
            { id: 9137, name: "Universidad Carlos III de Madrid" },
            { id: 2032, name: "Universidad Externado de Colombia" },
            { id: 3366, name: "Universidad Rafael Land\u00edvar" },
            { id: 221, name: "Universidad Torcuato di Tella" },
            { id: 222, name: "Universidad de Belgrano" },
            { id: 1320, name: "Universidad de Chile" },
            { id: 2450, name: "Universidad de Guayaquil" },
            { id: 9151, name: "Universidad de Navarra" },
            { id: 9115, name: "Universidad de Salamanca" },
            { id: 2054, name: "Universidad de San Buenaventura" },
            { id: 3365, name: "Universidad de San Carlos de Guatemala" },
            { id: 8029, name: "Universidad de San Mart\u00edn de Porres" },
            { id: 211, name: "Universidad del Este" },
            { id: 3370, name: "Universidad del Valle de Guatemala" },
            { id: 1032, name: "Universidade Anhembi Morumbi" },
            { id: 1019, name: "Universidade Estadual de Campinas" },
            { id: 863, name: "Universidade Federal de Alagoas" },
            { id: 882, name: "Universidade Federal do Cear\u00e1" },
            { id: 1029, name: "Universidade Metodista de S\u00e3o Paulo" },
            { id: 701, name: "Universiteit Gent" },
            { id: 7442, name: "Universiteit Maastricht" },
            { id: 7477, name: "Universiteit Utrecht" },
            { id: 87, name: "Universiteti i Tiran\u00ebs" },
            { id: 10203, name: "University of Alabama at Birmingham" },
            { id: 10242, name: "University of Arkansas" },
            { id: 7483, name: "University of Auckland" },
            { id: 10848, name: "University of Baltimore" },
            { id: 10197, name: "University of Bradford" },
            { id: 10266, name: "University of California, Berkeley" },
            { id: 10270, name: "University of California, Davis" },
            { id: 10271, name: "University of California, Irvine" },
            { id: 10267, name: "University of California, Los Angeles" },
            { id: 10274, name: "University of California, Riverside" },
            { id: 10273, name: "University of California, Santa Barbara" },
            { id: 10275, name: "University of California, Santa Cruz" },
            { id: 10048, name: "University of Cambridge" },
            { id: 9086, name: "University of Cape Town" },
            { id: 10467, name: "University of Central Florida" },
            { id: 11062, name: "University of Central Missouri" },
            { id: 10595, name: "University of Chicago" },
            { id: 11400, name: "University of Cincinnati" },
            { id: 10402, name: "University of Colorado Boulder" },
            { id: 10407, name: "University of Colorado Colorado Springs" },
            { id: 10426, name: "University of Connecticut" },
            { id: 11405, name: "University of Dayton" },
            { id: 10404, name: "University of Denver" },
            { id: 10969, name: "University of Detroit Mercy" },
            { id: 10729, name: "University of Dubuque" },
            { id: 10079, name: "University of East London" },
            { id: 10464, name: "University of Florida" },
            { id: 10529, name: "University of Georgia" },
            { id: 10433, name: "University of Hartford" },
            { id: 11727, name: "University of Houston" },
            { id: 11760, name: "University of Houston-Downtown" },
            { id: 10596, name: "University of Illinois at Urbana-Champaign" },
            { id: 10686, name: "University of Indianapolis" },
            { id: 10714, name: "University of Iowa" },
            { id: 4985, name: "University of Isfahan" },
            { id: 11396, name: "University of Jamestown" },
            { id: 10772, name: "University of Kentucky" },
            { id: 7610, name: "University of Lagos" },
            { id: 10190, name: "University of Leeds" },
            { id: 10042, name: "University of Leicester" },
            { id: 5256, name: "University of Limerick" },
            { id: 10054, name: "University of Lincoln" },
            { id: 10810, name: "University of Louisiana at Monroe" },
            { id: 10773, name: "University of Louisville" },
            { id: 11391, name: "University of Mary" },
            { id: 10875, name: "University of Massachusetts Amherst" },
            { id: 10882, name: "University of Massachusetts Boston" },
            { id: 10469, name: "University of Miami" },
            { id: 10951, name: "University of Michigan" },
            { id: 10958, name: "University of Michigan-Dearborn" },
            { id: 10997, name: "University of Minnesota-Twin Cities" },
            { id: 11033, name: "University of Mississippi" },
            { id: 11051, name: "University of Missouri-Kansas City" },
            { id: 10217, name: "University of Montevallo" },
            { id: 3872, name: "University of Mumbai" },
            { id: 1081, name: "University of National and World Economy" },
            { id: 11129, name: "University of Nevada, Reno" },
            { id: 10804, name: "University of New Orleans" },
            { id: 2311, name: "University of Nicosia" },
            { id: 11357, name: "University of North Carolina School of the Arts" },
            { id: 11352, name: "University of North Carolina at Asheville" },
            { id: 11336, name: "University of North Carolina at Chapel Hill" },
            { id: 11342, name: "University of North Carolina at Charlotte" },
            { id: 11341, name: "University of North Carolina at Greensboro" },
            { id: 11389, name: "University of North Dakota" },
            { id: 10545, name: "University of North Georgia" },
            { id: 11728, name: "University of North Texas" },
            { id: 10409, name: "University of Northern Colorado" },
            { id: 11023, name: "University of Northwestern - St. Paul" },
            { id: 10671, name: "University of Notre Dame" },
            { id: 10138, name: "University of Oxford" },
            { id: 11515, name: "University of Pennsylvania" },
            { id: 11518, name: "University of Pittsburgh" },
            { id: 10144, name: "University of Portsmouth" },
            { id: 11893, name: "University of Puget Sound" },
            { id: 11848, name: "University of Richmond" },
            { id: 11189, name: "University of Rochester" },
            { id: 8155, name: "University of San Carlos" },
            { id: 10285, name: "University of San Francisco" },
            { id: 10208, name: "University of South Alabama" },
            { id: 11633, name: "University of South Carolina" },
            { id: 10466, name: "University of South Florida" },
            { id: 10268, name: "University of Southern California" },
            { id: 10830, name: "University of Southern Maine" },
            { id: 20355, name: "University of St Andrews" },
            { id: 11776, name: "University of St. Thomas" },
            { id: 11000, name: "University of St. Thomas" },
            { id: 10127, name: "University of Stirling" },
            { id: 10142, name: "University of Surrey" },
            { id: 10141, name: "University of Sussex" },
            { id: 11410, name: "University of Toledo" },
            { id: 11840, name: "University of Virginia" },
            { id: 11882, name: "University of Washington" },
            { id: 10537, name: "University of West Georgia" },
            { id: 11931, name: "University of Wisconsin-Eau Claire" },
            { id: 11936, name: "University of Wisconsin-La Crosse" },
            { id: 11928, name: "University of Wisconsin-Madison" },
            { id: 11929, name: "University of Wisconsin-Milwaukee" },
            { id: 11947, name: "University of Wisconsin-Parkside" },
            { id: 11944, name: "University of Wisconsin-River Falls" },
            { id: 11938, name: "University of Wisconsin-Stout" },
            { id: 10187, name: "University of Worcester" },
            { id: 356, name: "University of the Sunshine Coast" },
            { id: 5323, name: "Universit\u00e0 Cattolica del Sacro Cuore" },
            { id: 5320, name: "Universit\u00e0 degli Studi di Milano" },
            { id: 5303, name: "Universit\u00e0 degli Studi di Trieste" },
            { id: 2918, name: "Universit\u00e4t Stuttgart" },
            { id: 2920, name: "Universit\u00e4t Ulm" },
            { id: 422, name: "Universit\u00e4t Wien" },
            { id: 2990, name: "Universit\u00e4t der Bundeswehr M\u00fcnchen" },
            { id: 3023, name: "Universit\u00e4t der K\u00fcnste Berlin" },
            { id: 2706, name: "Universit\u00e9 Catholique de Lille" },
            { id: 684, name: "Universit\u00e9 Libre de Bruxelles" },
            { id: 8958, name: "Univerzitet u Beogradu" },
            { id: 8339, name: "Uniwersytet Ekonomiczny w Krakowie" },
            { id: 15451, name: "Uniwersytet Ekonomiczny w Poznaniu" },
            { id: 9231, name: "Uppsala Universitet" },
            { id: 11817, name: "Utah State University" },
            { id: 11819, name: "Utah Valley University" },
            { id: 10539, name: "Valdosta State University" },
            { id: 10475, name: "Valencia College" },
            { id: 11677, name: "Vanderbilt University" },
            { id: 11312, name: "Vaughn College of Aeronautics and Technology" },
            { id: 11831, name: "Vermont Law School" },
            { id: 11523, name: "Villanova University" },
            { id: 10696, name: "Vincennes University" },
            { id: 11854, name: "Virginia Military Institute" },
            { id: 11872, name: "Virginia Union University" },
            { id: 11339, name: "Wake Forest University" },
            { id: 10985, name: "Walsh College" },
            { id: 5932, name: "Waseda University" },
            { id: 11883, name: "Washington State University" },
            { id: 18376, name: "Washington University in St. Louis" },
            { id: 11849, name: "Washington and Lee University" },
            { id: 11784, name: "Wayland Baptist University" },
            { id: 10954, name: "Wayne State University" },
            { id: 11818, name: "Weber State University" },
            { id: 18383, name: "Webster University" },
            { id: 10428, name: "Wesleyan University" },
            { id: 11911, name: "West Virginia State University" },
            { id: 11908, name: "West Virginia University" },
            { id: 10418, name: "Western Colorado University" },
            { id: 10774, name: "Western Kentucky University" },
            { id: 10955, name: "Western Michigan University" },
            { id: 11884, name: "Western Washington University" },
            { id: 11822, name: "Westminster College" },
            { id: 10609, name: "Wheaton College" },
            { id: 11537, name: "Widener University" },
            { id: 11455, name: "Wilberforce University" },
            { id: 11548, name: "Wilkes University" },
            { id: 11085, name: "William Woods University" },
            { id: 10881, name: "Williams College" },
            { id: 11014, name: "Winona State University" },
            { id: 11638, name: "Winthrop University" },
            { id: 424, name: "Wirtschaftsuniversit\u00e4t Wien" },
            { id: 11642, name: "Wofford College" },
            { id: 10345, name: "Woodbury University" },
            { id: 10879, name: "Worcester Polytechnic Institute" },
            { id: 11408, name: "Wright State University" },
            { id: 11413, name: "Xavier University" },
            { id: 10425, name: "Yale University" },
            { id: 1712, name: "Yangzhou University" },
            { id: 6368, name: "Yonsei University" },
            { id: 1253, name: "York University" },
            { id: 10573, name: "Young Harris College" },
            { id: 1581, name: "Zhengzhou University" },
            { id: 2700, name: "\u00c9cole des Hautes \u00c9tudes Commerciales du Nord" },
        ],
        languages: [
            { id: 9, name: "Afrikaans" },
            { id: 14, name: "Albanian" },
            { id: 20, name: "Amharic" },
            { id: 23, name: "Arabic" },
            { id: 58, name: "Bengali" },
            { id: 70, name: "Bokm\u00e5l, Norwegian" },
            { id: 71, name: "Bosnian" },
            { id: 80, name: "Catalan" },
            { id: 95, name: "Chinese" },
            { id: 113, name: "Creoles and pidgins" },
            { id: 121, name: "Czech" },
            { id: 136, name: "Dutch" },
            { id: 146, name: "English" },
            { id: 159, name: "Finnish" },
            { id: 163, name: "French" },
            { id: 177, name: "German" },
            { id: 189, name: "Greek, Modern (1453-)" },
            { id: 191, name: "Guarani" },
            { id: 192, name: "Gujarati" },
            { id: 196, name: "Haitian Creole" },
            { id: 199, name: "Hebrew" },
            { id: 203, name: "Hindi" },
            { id: 207, name: "Hungarian" },
            { id: 212, name: "Igbo" },
            { id: 225, name: "Iranian languages" },
            { id: 230, name: "Italian" },
            { id: 231, name: "Japanese" },
            { id: 242, name: "Kannada" },
            { id: 266, name: "Korean" },
            { id: 281, name: "Lao" },
            { id: 282, name: "Latin" },
            { id: 312, name: "Malay" },
            { id: 313, name: "Malayalam" },
            { id: 325, name: "Marathi" },
            { id: 358, name: "Nepali" },
            { id: 372, name: "Norwegian" },
            { id: 405, name: "Panjabi" },
            { id: 410, name: "Persian" },
            { id: 416, name: "Polish" },
            { id: 417, name: "Portuguese" },
            { id: 428, name: "Romanian" },
            { id: 432, name: "Russian" },
            { id: 450, name: "Serbian" },
            { id: 467, name: "Slovak" },
            { id: 470, name: "Somali" },
            { id: 480, name: "Spanish" },
            { id: 489, name: "Swedish" },
            { id: 492, name: "Tagalog" },
            { id: 495, name: "Tajik" },
            { id: 497, name: "Tamil" },
            { id: 499, name: "Telugu" },
            { id: 502, name: "Thai" },
            { id: 512, name: "Tonga (Nyasa)" },
            { id: 513, name: "Tonga (Tonga Islands)" },
            { id: 519, name: "Turkish" },
            { id: 528, name: "Ukrainian" },
            { id: 533, name: "Urdu" },
            { id: 539, name: "Vietnamese" },
            { id: 551, name: "Wolof" },
        ],
        cities: [
            { id: 1288001666, name: "Accra" },
            { id: 1840013688, name: "Acworth" },
            { id: 1840003729, name: "Addison" },
            { id: 1840003226, name: "Adrian" },
            { id: 1840019221, name: "Agoura Hills" },
            { id: 1840000417, name: "Albany" },
            { id: 1840026377, name: "Albrightsville" },
            { id: 1840019321, name: "Aliso Viejo" },
            { id: 1840004256, name: "Allen" },
            { id: 1840003967, name: "Allen Park" },
            { id: 1840001044, name: "Allentown" },
            { id: 1840013659, name: "Alpharetta" },
            { id: 1840018366, name: "Anacortes" },
            { id: 1840007066, name: "Ankeny" },
            { id: 1840003172, name: "Ann Arbor" },
            { id: 1840002413, name: "Arlington" },
            { id: 1840013411, name: "Asheville" },
            { id: 1840002274, name: "Astoria" },
            { id: 1840000510, name: "Athens" },
            { id: 1840004026, name: "Atlanta" },
            { id: 1840005335, name: "Atlantic Beach" },
            { id: 1840015535, name: "Aubrey" },
            { id: 1840000319, name: "Auburn" },
            { id: 1840000302, name: "Augusta" },
            { id: 1840000806, name: "Aurora" },
            { id: 1840013689, name: "Austell" },
            { id: 1840003306, name: "Austin" },
            { id: 1840013702, name: "Avondale Estates" },
            { id: 1840019423, name: "Azle" },
            { id: 1840001592, name: "Baltimore" },
            { id: 1356410365, name: "Bangalore" },
            { id: 1840000385, name: "Batavia" },
            { id: 1840013941, name: "Baton Rouge" },
            { id: 1840013818, name: "Beaufort" },
            { id: 1840000723, name: "Belleville" },
            { id: 1840000308, name: "Bellevue" },
            { id: 1840005232, name: "Bellmore" },
            { id: 1276451290, name: "Berlin" },
            { id: 1840007451, name: "Beverly Hills" },
            { id: 1840019119, name: "Big Bear Lake" },
            { id: 1840001926, name: "Bismarck" },
            { id: 1840000415, name: "Bloomfield" },
            { id: 1840002439, name: "Bloomfield Hills" },
            { id: 1840002970, name: "Bloomington" },
            { id: 1840027142, name: "Boise" },
            { id: 1840018454, name: "Bonney Lake" },
            { id: 1840013790, name: "Bossier City" },
            { id: 1840000455, name: "Boston" },
            { id: 1840017449, name: "Boulder" },
            { id: 1840018737, name: "Bountiful" },
            { id: 1840018410, name: "Bremerton" },
            { id: 1840081606, name: "Brick" },
            { id: 1840003099, name: "Brighton" },
            { id: 1840033999, name: "Bronx" },
            { id: 1840004949, name: "Bronxville" },
            { id: 1840000592, name: "Brooklyn" },
            { id: 1840018781, name: "Broomfield" },
            { id: 1840007022, name: "Burbank" },
            { id: 1840000046, name: "Burlington" },
            { id: 1840006115, name: "Burnsville" },
            { id: 1076562452, name: "Caatiba" },
            { id: 1840003642, name: "California" },
            { id: 1840092506, name: "Camp Washington" },
            { id: 1840000512, name: "Canton" },
            { id: 1840010473, name: "Carleton" },
            { id: 1840004806, name: "Carrollton" },
            { id: 1840000201, name: "Carson" },
            { id: 1840006758, name: "Carver" },
            { id: 1840010109, name: "Cary" },
            { id: 1840001006, name: "Centerville" },
            { id: 1840005028, name: "Central Islip" },
            { id: 1156154785, name: "Changning" },
            { id: 1840016152, name: "Chapel Hill" },
            { id: 1840006196, name: "Charleston" },
            { id: 1840003107, name: "Charlotte" },
            { id: 1840006760, name: "Chaska" },
            { id: 1840000282, name: "Chelsea" },
            { id: 1840000690, name: "Chester" },
            { id: 1840031323, name: "Chevy Chase" },
            { id: 1840000494, name: "Chicago" },
            { id: 1840019115, name: "Chino Hills" },
            { id: 1840057308, name: "Church" },
            { id: 1840003814, name: "Cincinnati" },
            { id: 1840014776, name: "Clarkston" },
            { id: 1840005570, name: "Claymont" },
            { id: 1840006704, name: "Clearwater" },
            { id: 1840006887, name: "Clermont" },
            { id: 1840018402, name: "Coeur d'Alene" },
            { id: 1840074067, name: "College Hill Station" },
            { id: 1840005971, name: "College Park" },
            { id: 1840019430, name: "Colleyville" },
            { id: 1840010999, name: "Columbiaville" },
            { id: 1840001715, name: "Columbus" },
            { id: 1840011429, name: "Compton" },
            { id: 1840067102, name: "Connecticut Avenue Estates" },
            { id: 1840019586, name: "Conroe" },
            { id: 1840001015, name: "Conway" },
            { id: 1840014806, name: "Conyers" },
            { id: 1840019439, name: "Coppell" },
            { id: 1840000264, name: "Corona" },
            { id: 1840019354, name: "Coronado" },
            { id: 1840002910, name: "Cottage Grove" },
            { id: 1840001688, name: "Covington" },
            { id: 1840007091, name: "Crown Point" },
            { id: 1840007103, name: "Cumming" },
            { id: 1840014743, name: "Dacula" },
            { id: 1840014672, name: "Dahlonega" },
            { id: 1840000364, name: "Dallas" },
            { id: 1840003379, name: "Dalton" },
            { id: 1840000854, name: "Danville" },
            { id: 1840000885, name: "Dayton" },
            { id: 1840015068, name: "Daytona Beach" },
            { id: 1840003969, name: "Dearborn" },
            { id: 1840003970, name: "Dearborn Heights" },
            { id: 1840001403, name: "Decatur" },
            { id: 1840015123, name: "Delray Beach" },
            { id: 1840005932, name: "Denton" },
            { id: 1840003714, name: "Denver" },
            { id: 1840003971, name: "Detroit" },
            { id: 1840004129, name: "Dexter" },
            { id: 1840014804, name: "Douglasville" },
            { id: 1840003340, name: "Downey" },
            { id: 1840000330, name: "Draper" },
            { id: 1840001321, name: "Dublin" },
            { id: 1840007685, name: "Duluth" },
            { id: 1840003541, name: "Dumont" },
            { id: 1840018427, name: "Duvall" },
            { id: 1840001982, name: "Eagle River" },
            { id: 1840089730, name: "East Fayetteville" },
            { id: 1840003698, name: "East Greenville" },
            { id: 1840005236, name: "East Meadow" },
            { id: 1840002043, name: "Eden" },
            { id: 1840007821, name: "Eden Prairie" },
            { id: 1840012021, name: "Edison" },
            { id: 1840016991, name: "Edwards" },
            { id: 1840150306, name: "El Adobe" },
            { id: 1840005226, name: "Elmont" },
            { id: 1840003717, name: "Ephrata" },
            { id: 1840013168, name: "Erlanger" },
            { id: 1840007823, name: "Excelsior" },
            { id: 1840023463, name: "Fairbanks" },
            { id: 1840002639, name: "Fairburn" },
            { id: 1840000865, name: "Fairview" },
            { id: 1356078517, name: "Farid\u0101b\u0101d" },
            { id: 1840003686, name: "Farmingdale" },
            { id: 1840002441, name: "Farmington" },
            { id: 1840004320, name: "Fayetteville" },
            { id: 1840019826, name: "Federal Way" },
            { id: 1840002948, name: "Fenton" },
            { id: 1840004748, name: "Fishkill" },
            { id: 1840003973, name: "Flat Rock" },
            { id: 1840002949, name: "Flint" },
            { id: 1840001660, name: "Florence" },
            { id: 1840004867, name: "Florida" },
            { id: 1840121450, name: "Flower" },
            { id: 1840013611, name: "Flowery Branch" },
            { id: 1840003799, name: "Folsom" },
            { id: 1840004636, name: "Forest Lake" },
            { id: 1840014236, name: "Fort Lauderdale" },
            { id: 1840016479, name: "Fort Mill" },
            { id: 1840014226, name: "Fort Myers" },
            { id: 1840014178, name: "Fort Pierce" },
            { id: 1840013923, name: "Fort Walton Beach" },
            { id: 1840020696, name: "Fort Worth" },
            { id: 1840002748, name: "Franklin" },
            { id: 1840001198, name: "Franklin Park" },
            { id: 1840020654, name: "Frisco" },
            { id: 1840004468, name: "Gainesville" },
            { id: 1840008311, name: "Garden Grove" },
            { id: 1840056116, name: "Georgia" },
            { id: 1840019855, name: "Gig Harbor" },
            { id: 1840003037, name: "Glendale" },
            { id: 1840020749, name: "Granbury" },
            { id: 1840020697, name: "Grapevine" },
            { id: 1840000719, name: "Greensboro" },
            { id: 1840000659, name: "Greenville" },
            { id: 1840010823, name: "Griffin" },
            { id: 1840133971, name: "Grosse Ile" },
            { id: 1840003976, name: "Grosse Pointe" },
            { id: 1840003979, name: "Grosse Pointe Woods" },
            { id: 1840013748, name: "Grovetown" },
            { id: 1840013920, name: "Gulf Breeze" },
            { id: 1356406239, name: "G\u016brgaon" },
            { id: 1840043425, name: "Hanscom AFB" },
            { id: 1840001115, name: "Hastings" },
            { id: 1840029479, name: "Hauula" },
            { id: 1840029572, name: "Hawaiian Acres" },
            { id: 1840003513, name: "Hawthorne" },
            { id: 1840018034, name: "Heber" },
            { id: 1840000198, name: "Hebron" },
            { id: 1840005293, name: "Hempstead" },
            { id: 1840005936, name: "Henderson" },
            { id: 1840020155, name: "Herriman" },
            { id: 1124001665, name: "Hilton" },
            { id: 1840004281, name: "Hilton" },
            { id: 1840003599, name: "Hoboken" },
            { id: 1840053479, name: "Holliston" },
            { id: 1840015144, name: "Hollywood" },
            { id: 1344982653, name: "Hong Kong" },
            { id: 1840006749, name: "Hopkins" },
            { id: 1840003657, name: "Houston" },
            { id: 1840001778, name: "Howell" },
            { id: 1840020578, name: "Huntington Beach" },
            { id: 1840005081, name: "Huntington Station" },
            { id: 1840005061, name: "Huntsville" },
            { id: 1840007577, name: "Hurst" },
            { id: 1840030084, name: "Indianapolis" },
            { id: 1840011457, name: "Inglewood" },
            { id: 1840007858, name: "Inver Grove Heights" },
            { id: 1840016723, name: "Irmo" },
            { id: 1840014344, name: "Irvine" },
            { id: 1840012637, name: "Irving" },
            { id: 1840018422, name: "Issaquah" },
            { id: 1840004335, name: "Jacksonville" },
            { id: 1840015032, name: "Jacksonville Beach" },
            { id: 1840014787, name: "Jersey" },
            { id: 1840008340, name: "Jonesboro" },
            { id: 1840004321, name: "Jordan" },
            { id: 1840017255, name: "Jupiter" },
            { id: 1840012501, name: "Kansas" },
            { id: 1840018738, name: "Kaysville" },
            { id: 1840006324, name: "Keller" },
            { id: 1840004386, name: "Kenmore" },
            { id: 1840014756, name: "Kennesaw" },
            { id: 1840007751, name: "Kent" },
            { id: 1840040003, name: "Kentucky" },
            { id: 1840014253, name: "Key Largo" },
            { id: 1840029510, name: "Koloa" },
            { id: 1840019866, name: "Lacey" },
            { id: 1840011054, name: "Lake Orion" },
            { id: 1840007859, name: "Lakeville" },
            { id: 1840002969, name: "Lancaster" },
            { id: 1840020364, name: "Las Vegas" },
            { id: 1840074639, name: "Latonia" },
            { id: 1840008523, name: "Lawrenceburg" },
            { id: 1840003299, name: "Lawrenceville" },
            { id: 1840015162, name: "Layton" },
            { id: 1840001040, name: "Lebanon" },
            { id: 1840000320, name: "Lewiston" },
            { id: 1840007924, name: "Lewisville" },
            { id: 1840001689, name: "Lexington" },
            { id: 1840015613, name: "Lilburn" },
            { id: 1840000158, name: "Lincoln" },
            { id: 1840013740, name: "Lithia Springs" },
            { id: 1840015644, name: "Lithonia" },
            { id: 1840003985, name: "Livonia" },
            { id: 1840015705, name: "Locust Grove" },
            { id: 1840006336, name: "Logan" },
            { id: 1840001454, name: "Loganville" },
            { id: 1840000988, name: "Long Beach" },
            { id: 1840053448, name: "Longmeadow" },
            { id: 1840020491, name: "Los Angeles" },
            { id: 1840000964, name: "Louisville" },
            { id: 1756503816, name: "Lugano" },
            { id: 1840015922, name: "Lynn Haven" },
            { id: 1840013690, name: "Mableton" },
            { id: 1840009411, name: "Macomb" },
            { id: 1840009485, name: "Macon" },
            { id: 1840000538, name: "Madison" },
            { id: 1724616994, name: "Madrid" },
            { id: 1840000430, name: "Malden" },
            { id: 1840005273, name: "Manhasset" },
            { id: 1840008844, name: "Manhattan Beach" },
            { id: 1608254376, name: "Manila" },
            { id: 1840008975, name: "Mankato" },
            { id: 1840019828, name: "Maple Valley" },
            { id: 1840001545, name: "Marietta" },
            { id: 1840017920, name: "Marina del Rey" },
            { id: 1840000220, name: "Marion" },
            { id: 1840008349, name: "Maryville" },
            { id: 1840003104, name: "Mason" },
            { id: 1840015706, name: "McDonough" },
            { id: 1840000431, name: "Medford" },
            { id: 1840003986, name: "Melvindale" },
            { id: 1840002422, name: "Memphis" },
            { id: 1840000585, name: "Middletown" },
            { id: 1840012091, name: "Midvale" },
            { id: 1840001041, name: "Milford" },
            { id: 1840000029, name: "Milton" },
            { id: 1840003046, name: "Milwaukee" },
            { id: 1840003824, name: "Minneapolis" },
            { id: 1840007831, name: "Minnetonka" },
            { id: 1840000053, name: "Minot" },
            { id: 1840122841, name: "Missouri Branch" },
            { id: 1840006009, name: "Mobile" },
            { id: 1840004978, name: "Montebello" },
            { id: 1124586170, name: "Montr\u00e9al" },
            { id: 1840019685, name: "Moore" },
            { id: 1840007872, name: "Morgan" },
            { id: 1840001326, name: "Morrisville" },
            { id: 1840000888, name: "Mount Pleasant" },
            { id: 1840000338, name: "Mount Vernon" },
            { id: 1356226629, name: "Mumbai" },
            { id: 1840050164, name: "M\u014d\u02bbili\u02bbili" },
            { id: 1840008651, name: "Nashville" },
            { id: 1840014591, name: "New Bern" },
            { id: 1840001645, name: "New Boston" },
            { id: 1356215164, name: "New Delhi" },
            { id: 1840004850, name: "New Haven" },
            { id: 1840001839, name: "New Orleans" },
            { id: 1840005704, name: "New Windsor" },
            { id: 1840034016, name: "New York" },
            { id: 1840014833, name: "Newnan" },
            { id: 1840002270, name: "Newport" },
            { id: 1356149101, name: "None" },
            { id: 1840007767, name: "Norcross" },
            { id: 1840003869, name: "Norfolk" },
            { id: 1840029449, name: "North Druid Hills" },
            { id: 1840069459, name: "North Easton" },
            { id: 1840014632, name: "North Little Rock" },
            { id: 1840015120, name: "North Port" },
            { id: 1840007843, name: "North St. Paul" },
            { id: 1840000799, name: "Northfield" },
            { id: 1840000288, name: "Northville" },
            { id: 1840002423, name: "Novi" },
            { id: 1840011503, name: "Oak Harbor" },
            { id: 1840005246, name: "Oceanside" },
            { id: 1840015098, name: "Ocoee" },
            { id: 1840008120, name: "Ogden" },
            { id: 1840061984, name: "Oklahoma" },
            { id: 1840034375, name: "Olde West Chester" },
            { id: 1840019865, name: "Olympia" },
            { id: 1840011374, name: "Onsted" },
            { id: 1840003405, name: "Orange" },
            { id: 1840019858, name: "Orting" },
            { id: 1840018308, name: "Palm Springs" },
            { id: 1840140102, name: "Palos Verdes Peninsula" },
            { id: 1250015082, name: "Paris" },
            { id: 1840009701, name: "Park" },
            { id: 1840000917, name: "Park Ridge" },
            { id: 1840028462, name: "Parlin" },
            { id: 1840005914, name: "Pasadena" },
            { id: 1840089287, name: "Peachtree" },
            { id: 1840015005, name: "Pensacola" },
            { id: 1840020244, name: "Petaluma" },
            { id: 1840000673, name: "Philadelphia" },
            { id: 1840004193, name: "Phoenix" },
            { id: 1840001254, name: "Pittsburgh" },
            { id: 1840001090, name: "Plainfield" },
            { id: 1840009258, name: "Plano" },
            { id: 1840009393, name: "Pleasanton" },
            { id: 1840000767, name: "Plymouth" },
            { id: 1840004983, name: "Pomona" },
            { id: 1840015143, name: "Pompano Beach" },
            { id: 1840028957, name: "Ponte Vedra Beach" },
            { id: 1840014755, name: "Powder Springs" },
            { id: 1840008956, name: "Prior Lake" },
            { id: 1840003289, name: "Providence" },
            { id: 1840001807, name: "Provo" },
            { id: 1840021944, name: "Queen Creek" },
            { id: 1840034002, name: "Queens" },
            { id: 1218441993, name: "Quito" },
            { id: 1840012975, name: "Raleigh" },
            { id: 1840020508, name: "Rancho Palos Verdes" },
            { id: 1840008959, name: "Red Wing" },
            { id: 1840020509, name: "Redondo Beach" },
            { id: 1840020121, name: "Reno" },
            { id: 1840019827, name: "Renton" },
            { id: 1840000457, name: "Revere" },
            { id: 1840014913, name: "Richmond Hill" },
            { id: 1840011284, name: "River Forest" },
            { id: 1840000114, name: "Riverdale" },
            { id: 1840000848, name: "Riverside" },
            { id: 1840003961, name: "Riverview" },
            { id: 1840003858, name: "Roanoke" },
            { id: 1840020717, name: "Rockwall" },
            { id: 1840001930, name: "Rogers" },
            { id: 1840000349, name: "Rome" },
            { id: 1840003963, name: "Romulus" },
            { id: 1840006054, name: "Rosemount" },
            { id: 1840002541, name: "Roswell" },
            { id: 1840002430, name: "Royal Oak" },
            { id: 1840000410, name: "Salem" },
            { id: 1840082816, name: "Salt Lake" },
            { id: 1840015969, name: "San Antonio" },
            { id: 1840037414, name: "San Buenaventura" },
            { id: 1840021990, name: "San Diego" },
            { id: 1840021543, name: "San Francisco" },
            { id: 1840021991, name: "San Marcos" },
            { id: 1840021863, name: "San Marino" },
            { id: 1840021829, name: "Santa Barbara" },
            { id: 1840019103, name: "Santa Cruz" },
            { id: 1840019736, name: "Santa Monica" },
            { id: 1840053670, name: "Saugus" },
            { id: 1840008957, name: "Savage" },
            { id: 1840009477, name: "Savannah" },
            { id: 1840001157, name: "Scottdale" },
            { id: 1840021940, name: "Scottsdale" },
            { id: 1840022197, name: "Seabrook" },
            { id: 1840021117, name: "Seattle" },
            { id: 1840001262, name: "Sharpsburg" },
            { id: 1840004840, name: "Shelton" },
            { id: 1840001864, name: "Sherwood" },
            { id: 1840005845, name: "Silver Spring" },
            { id: 1702341327, name: "Singapore" },
            { id: 1840090595, name: "Sioux" },
            { id: 1840041703, name: "Smithtown" },
            { id: 1840004512, name: "Smyrna" },
            { id: 1840015614, name: "Snellville" },
            { id: 1840021078, name: "Snohomish" },
            { id: 1840021119, name: "Snoqualmie" },
            { id: 1840015658, name: "Social Circle" },
            { id: 1840001056, name: "Somerville" },
            { id: 1840021385, name: "South Jordan" },
            { id: 1840008948, name: "South St. Paul" },
            { id: 1840002431, name: "Southfield" },
            { id: 1840022048, name: "Southlake" },
            { id: 1840018451, name: "Spanaway" },
            { id: 1840021398, name: "Spanish Fork" },
            { id: 1840000466, name: "Springfield" },
            { id: 1840004391, name: "Springville" },
            { id: 1840002267, name: "St. Albans" },
            { id: 1840002611, name: "St. Cloud" },
            { id: 1840001651, name: "St. Louis" },
            { id: 1840006484, name: "St. Paul" },
            { id: 1840003461, name: "St. Petersburg" },
            { id: 1840013874, name: "St. Simons" },
            { id: 1840123202, name: "Staten" },
            { id: 1840015801, name: "Statesboro" },
            { id: 1840000816, name: "Stillwater" },
            { id: 1840002248, name: "Stockbridge" },
            { id: 1840015645, name: "Stone Mountain" },
            { id: 1840003873, name: "Suffolk" },
            { id: 1840015616, name: "Suwanee" },
            { id: 1036074917, name: "Sydney" },
            { id: 1840000378, name: "Syracuse" },
            { id: 1840021129, name: "Tacoma" },
            { id: 1840015913, name: "Tallahassee" },
            { id: 1840003228, name: "Tecumseh" },
            { id: 1840021942, name: "Tempe" },
            { id: 1840015708, name: "Texarkana" },
            { id: 1840022005, name: "The Colony" },
            { id: 1356681152, name: "Th\u0101ne" },
            { id: 1840000572, name: "Toledo" },
            { id: 1840022203, name: "Tomball" },
            { id: 1840021356, name: "Tooele" },
            { id: 1840021873, name: "Torrance" },
            { id: 1840001383, name: "Trenton" },
            { id: 1840000521, name: "Troy" },
            { id: 1840013701, name: "Tucker" },
            { id: 1840022101, name: "Tucson" },
            { id: 1840021533, name: "Turlock" },
            { id: 1840005563, name: "Tuscaloosa" },
            { id: 1840006386, name: "Union" },
            { id: 1840052612, name: "Utah" },
            { id: 1840002417, name: "Utica" },
            { id: 1840021498, name: "Vacaville" },
            { id: 1840011638, name: "Valparaiso" },
            { id: 1840021189, name: "Vancouver" },
            { id: 1840003865, name: "Victoria" },
            { id: 1840002150, name: "Vienna" },
            { id: 1840015679, name: "Villa Rica" },
            { id: 1840003871, name: "Virginia Beach" },
            { id: 1840010008, name: "Waconia" },
            { id: 1840000652, name: "Warren" },
            { id: 1840001889, name: "Warwick" },
            { id: 1840000724, name: "Washington" },
            { id: 1840000305, name: "Waterville" },
            { id: 1840022083, name: "Waxahachie" },
            { id: 1840010000, name: "Wayzata" },
            { id: 1840038051, name: "West Bloomfield" },
            { id: 1840001436, name: "West Chester" },
            { id: 1840004852, name: "West Haven" },
            { id: 1840021388, name: "West Jordan" },
            { id: 1840015993, name: "West Palm Beach" },
            { id: 1840011333, name: "Western Springs" },
            { id: 1840000463, name: "Westfield" },
            { id: 1840037458, name: "Westlake Village" },
            { id: 1840005930, name: "Weston" },
            { id: 1840006923, name: "Whitmore Lake" },
            { id: 1840001686, name: "Wichita" },
            { id: 1840005579, name: "Wilmington" },
            { id: 1840017238, name: "Windermere" },
            { id: 1840015966, name: "Winter Park" },
            { id: 1840010013, name: "Winthrop" },
            { id: 1840000407, name: "Wixom" },
            { id: 1124375825, name: "Woodbridge" },
            { id: 1840004161, name: "Woodstock" },
            { id: 1840006444, name: "Yorktown" },
            { id: 1840001166, name: "Youngstown" },
            { id: 1840000459, name: "Ypsilanti" },
        ],
    },
};
