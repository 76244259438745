import React from "react";
import { Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Title } from "../../../components/Header/components/Title";
import GeneralSettingsComponent from "../../../components/Account/Settings/General";
import SecuritySettingsComponent from "../../../components/Account/Settings/Security";
import PreferenceSettingsComponent from "../../../components/Account/Settings/Preference";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },
    titleContainer: {
        padding: "19px 0 21px 24px",
    },
    divider: {
        margin: "0 24px",
    },
}));

function AccountSettingsPage() {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.titleContainer}>
                    <Title value="Account Settings" />
                </Box>
                <Divider className={classes.divider} />
            </Box>
            <GeneralSettingsComponent />
            <SecuritySettingsComponent />
            <PreferenceSettingsComponent />
        </>
    );
}

export default AccountSettingsPage;
