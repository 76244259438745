import React, { useEffect } from "react";
import { Button, Dialog, DialogContent, DialogActions, MenuItem, TextField, Box, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLE_OPTIONS } from "../../Filter/filterOptions";
import { inviteUserSchema } from "./inviteUserSchema";
import { Header } from "../../../../Header";
import { createUser } from "../../../../../actions/user/user";
import { clearFormErrors, userSliceSelectors } from "../../../../../slices/User/userSlice";
import { parseErrorsAndNotifyUser } from "../../../../../services/utils";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        width: 600,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    fieldContainer: {
        marginBottom: theme.spacing(3),
    },
    title: {
        margin: "24px 0 24px 16px",
    },
}));

export default function InviteUserModal({ open, handleClose, organizationId }) {
    const organizations = useSelector(userSliceSelectors.organizations);
    const formErrors = useSelector(userSliceSelectors.formErrors);
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        register,
        setValue,
        handleSubmit,
        control,
        setError,
        reset,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: { role: "" },
        resolver: yupResolver(inviteUserSchema),
    });

    const onClose = () => {
        dispatch(clearFormErrors());
        handleClose();
        setValue("email", "");
        setValue("role", "");
        clearErrors();
        reset();
    };

    const handleUserInvited = (error) => {
        if (error) {
            parseErrorsAndNotifyUser(error, dispatch);
        }
        onClose();
    };

    const onSubmit = (data) => {
        dispatch(
            createUser({
                userData: { ...data, organization_id: organizationId ?? organizations[0].id },
                onDone: handleUserInvited,
            }),
        );
    };

    useEffect(() => {
        if (open && formErrors && Object.keys(formErrors).length > 0) {
            for (const [key, value] of Object.entries(formErrors)) {
                setError(key, { message: value, type: "manual" }, { shouldFocus: true });
                setValue(key, "");
            }
        }
    }, [formErrors]);

    return (
        <Dialog onClose={onClose} open={open}>
            <Box className={classes.dialogBox}>
                <Header>
                    <Typography className={classes.title} color="primary" variant="h6">
                        Invite New User
                    </Typography>
                </Header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div className={classes.fieldContainer}>
                            <TextField
                                {...register("email")}
                                id="email"
                                variant="outlined"
                                label="Email"
                                fullWidth
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </div>

                        <div className={classes.fieldContainer}>
                            <Controller
                                name="role"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        id="role"
                                        variant="outlined"
                                        label="Role"
                                        fullWidth
                                        error={Boolean(errors.role)}
                                    >
                                        {USER_ROLE_OPTIONS.map(({ value, label }) => (
                                            <MenuItem key={value} value={value}>
                                                <Typography variant="body2">{label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            {errors.role && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.role.message}
                                </Typography>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Box>
        </Dialog>
    );
}
