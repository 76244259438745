import React, { useState, useEffect } from "react";
import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import CityAutocomplete from "../../AutocompleteInputs/CityAutocomplete";
import { citySliceSelectors, clearState } from "../../../slices/City/citySlice";
import TimeZoneAutocomplete from "../../AutocompleteInputs/TimeZoneAutocomplete";
import PhotoUpload from "../../PhotoUpload";
import { uploadProfilePhoto } from "../../../actions/user/user";
import { userSliceSelectors, USER_TYPES, setStep } from "../../../slices/User/userSlice";
import { updateSettingsGeneral } from "../../../actions/user/settings";
import { accountSchema } from "./accountSchema";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        textAlign: "center",
    },
    paper: {
        padding: theme.spacing(6),
    },
    subtitle: {
        marginTop: "16px",
        marginBottom: theme.spacing(4),
    },
    imageContainer: {
        width: "120px",
        height: "120px",
        flexGrow: 0,
        margin: "0 140px 16px",
        padding: "20px",
        borderRadius: "60px",
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    imagePreviewContainer: {
        overflow: "hidden",
        width: "120px",
        height: "120px",
        flexGrow: 0,
        margin: "0 140px 16px",
        borderRadius: "60px",
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
    },
    imagePreview: {
        top: 0,
        left: 0,
        transformOrigin: "top left",
    },
    accountIcon: {
        fontSize: 80,
        color: theme.palette.dividerAndOutline[700],
    },
    uploadText: {
        cursor: "pointer",
    },
    fullWidthField: {
        width: 400,
    },
    halfWidthField: {
        width: 195,
    },
    inputRow: {
        display: "flex",
        justifyContent: "space-between",
    },
    buttonRow: {
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "-16px",
        marginRight: "-16px",
    },
}));

export default function AccountPersonalInfoComponent() {
    const requiredFieldMessage = "Field is required";
    const [dialogOpen, setDialogOpen] = useState(false);
    const {
        id,
        profile_picture: profilePicture,
        first_name,
        last_name,
        title,
        department,
        city,
        timezone,
    } = useSelector(userSliceSelectors.defaultUserFields);
    const userType = useSelector(userSliceSelectors.type);
    const timeZone = useSelector(citySliceSelectors.timeZone);
    const [basedIn, setBasedIn] = useState(city);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ resolver: yupResolver(accountSchema) });

    useEffect(
        () => () => {
            dispatch(clearState());
        },
        [],
    );

    useEffect(() => {
        if (basedIn) {
            clearErrors("headquartersLocation");
        }
    }, [basedIn]);

    useEffect(() => {
        setValue("first_name", first_name);
        setValue("last_name", last_name);
        setValue("title", title);
        setValue("department", department === "0" ? "" : department);
    }, []);

    const onSubmit = (data) => {
        const currentCityId = basedIn ? basedIn?.id : null;
        if (!currentCityId) {
            return setError("headquartersLocation", { type: "required", message: requiredFieldMessage });
        }
        const userData = { ...data, ref_city_id: currentCityId, timezone: timeZone };
        let modifiedData = {};
        for (const [key, value] of Object.entries(userData)) {
            if (value) {
                modifiedData = { ...modifiedData, [key]: value };
            } else {
                modifiedData = {
                    ...modifiedData,
                    [key]:
                        key === "department"
                            ? "0"
                            : timeZone === null && timezone === "0"
                            ? "0"
                            : timeZone === "0"
                            ? timeZone
                            : timezone,
                };
            }
        }
        const onDone = () => {
            reset();
            if (userType === USER_TYPES.CLIENT_ADMIN) {
                dispatch(setStep(3));
                navigate("/organization/create");
            } else {
                dispatch(setStep(null));
                navigate("/");
            }
        };
        dispatch(updateSettingsGeneral({ id, userData: { user: { ...modifiedData } }, onDone }));
    };

    const onError = () => {
        if (!basedIn?.id) {
            setError("headquartersLocation", { type: "required", message: requiredFieldMessage });
        }
    };

    const handleImageUpload = (image) => {
        dispatch(uploadProfilePhoto({ image, id }));
    };

    const handleImageRemoved = () => {
        dispatch(updateSettingsGeneral({ id, userData: { user: { profile_picture: null } } }));
    };

    const disableEnter = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (
        <Box className={classes.root}>
            <Paper variant="secondary" className={classes.paper} elevation={1}>
                <Typography variant="h4" color="primary">
                    Welcome to Osterus
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
                    Do tell us something about yourself.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => disableEnter(e)}>
                    <Box mt={3} mb={1}>
                        {!profilePicture ? (
                            <div className={classes.imageContainer} onClick={() => setDialogOpen(true)}>
                                <AccountCircleIcon className={classes.accountIcon} />
                            </div>
                        ) : (
                            <div className={classes.imagePreviewContainer} onClick={() => setDialogOpen(true)}>
                                <img
                                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profilePicture}`}
                                    alt="profile"
                                />
                            </div>
                        )}
                        <Typography
                            className={classes.uploadText}
                            onClick={() => setDialogOpen(true)}
                            color="secondary.400"
                            variant="caption"
                        >
                            {!profilePicture ? "Upload Profile Picture" : "Re-Upload Profile Picture"}
                        </Typography>
                    </Box>
                    <Box mt={3} mb={1} className={classes.inputRow}>
                        <div>
                            <TextField
                                {...register("first_name")}
                                id="first-name-auth"
                                variant="outlined"
                                size="small"
                                label="First Name"
                                className={classes.halfWidthField}
                                error={Boolean(errors.first_name)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.first_name && (
                                <Typography color="error" variant="subtitle2" align="left">
                                    {requiredFieldMessage}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <TextField
                                {...register("last_name")}
                                id="last-name-auth"
                                variant="outlined"
                                size="small"
                                label="Last Name"
                                className={classes.halfWidthField}
                                error={Boolean(errors.last_name)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.last_name && (
                                <Typography color="error" variant="subtitle2" align="left">
                                    {requiredFieldMessage}
                                </Typography>
                            )}
                        </div>
                    </Box>
                    <Box mt={3} mb={1}>
                        <div>
                            <TextField
                                {...register("title")}
                                id="title-auth"
                                variant="outlined"
                                size="small"
                                label="Job Title in Organization"
                                className={classes.fullWidthField}
                                error={Boolean(errors.title)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.title && (
                                <Typography color="error" variant="subtitle2" align="left">
                                    {requiredFieldMessage}
                                </Typography>
                            )}
                        </div>
                    </Box>
                    <Box mt={3} mb={1}>
                        <TextField
                            {...register("department")}
                            id="department-auth"
                            variant="outlined"
                            size="small"
                            label="Department"
                            className={classes.fullWidthField}
                        />
                    </Box>
                    <Box mt={3} mb={1}>
                        <CityAutocomplete
                            className={classes.fullWidthField}
                            label="Based In"
                            error={Boolean(errors.headquartersLocation)}
                            required
                            defaultValue={basedIn && Object.keys(basedIn)?.length ? basedIn : null}
                            onChange={setBasedIn}
                        />
                        {errors.headquartersLocation && (
                            <Typography color="error" variant="subtitle2" align="left">
                                {requiredFieldMessage}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={3} mb={1}>
                        <TimeZoneAutocomplete label="Local Time" />
                    </Box>
                    <Box mt={3} mb={1} className={classes.buttonRow}>
                        <Button color="primary" variant="text" size="medium" type="submit">
                            Save & Continue
                        </Button>
                    </Box>
                </form>
            </Paper>
            <PhotoUpload
                open={dialogOpen}
                picture={profilePicture}
                handleClose={() => setDialogOpen(false)}
                handleSave={handleImageUpload}
                handleRemove={handleImageRemoved}
            />
        </Box>
    );
}
