import React, { useState, useEffect } from "react";
import { Paper, Typography, Button, TextField, Box, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { navigate, useParams } from "@gatsbyjs/reach-router";
import logo from "../../../osterus.svg";
import { registerSchema } from "./registerSchema";
import { getUserDetails } from "../../../actions/user/user";
import { userSliceSelectors, setStep } from "../../../slices/User/userSlice";
import TermsDialog from "./TermsDialog";
import { updateSettingsSecurity } from "../../../actions/user/settings";

const useStyles = makeStyles((theme) => ({
    authPaper: {
        minHeight: "90vh",
        padding: "60px",
    },
    logo: {
        width: "127px",
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: "16px",
        marginBottom: theme.spacing(6),
    },
    registerButton: {
        marginTop: theme.spacing(2),
    },
    fullWidthField: {
        width: "100%",
    },
    checkboxRow: {
        display: "flex",
        alignItems: "center",
    },
    needHelp: {
        marginTop: theme.spacing(6),
    },
    termsLink: {
        cursor: "pointer",
        fontWeight: 700,
        color: theme.palette.secondary.main,
    },
    columnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    icon: {
        fill: theme.palette.success.main,
    },
    helperText: {
        fontSize: 10,
        marginLeft: 0,
    },
}));

export default function RegisterComponent() {
    const [showPassword, setShowPassword] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { inviteCode } = useParams();
    const { id, email } = useSelector(userSliceSelectors.defaultUserFields);
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        clearErrors,
        watch,
    } = useForm({
        mode: "onSubmit",
        defaultValues: { email: " ", inviteCode },
        resolver: yupResolver(registerSchema),
    });

    const passwordWatch = watch("password");
    const passwordConfirmWatch = watch("password_confirmation");

    useEffect(() => {
        if (inviteCode) {
            localStorage.removeItem("token");
            dispatch(getUserDetails(inviteCode));
            setValue("inviteCode", inviteCode);
        }
    }, [inviteCode]);

    useEffect(() => {
        if (email?.length > 0) {
            setValue("email", email, { shouldValidate: true });
        }
    }, [email]);

    useEffect(() => {
        if (passwordWatch === passwordConfirmWatch) {
            clearErrors(["password", "password_confirmation"]);
        }
    }, [passwordWatch, passwordConfirmWatch]);

    const onSubmit = (data) => {
        const userData = {
            new_password: data.password,
            new_password_confirmation: data.password_confirmation,
        };
        const onDone = () => {
            dispatch(setStep(2));
            reset();
            navigate("/account/personal-information");
        };
        dispatch(updateSettingsSecurity({ id, userData: { user: { ...userData } }, onDone }));
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Paper className={classes.authPaper} elevation={4}>
            <div className={classes.columnContainer}>
                <div>
                    <img src={logo} className={classes.logo} alt="osterus logo" />
                    <Typography variant="h4" color="primary">
                        Complete Registration
                    </Typography>
                    <Typography className={classes.subtitle}>
                        We're happy to have you. Please fill out the form below.
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box mt={3} mb={1}>
                            <TextField
                                {...register("email")}
                                required
                                id="email-auth"
                                variant="outlined"
                                size="small"
                                label="Email"
                                className={classes.fullWidthField}
                                disabled
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={3} mb={1}>
                            <TextField
                                {...register("inviteCode")}
                                required
                                id="invite-code-auth"
                                variant="outlined"
                                size="small"
                                label="Invite Code"
                                className={classes.fullWidthField}
                                disabled
                            />
                        </Box>
                        <Box mt={3} mb={1}>
                            <TextField
                                {...register("password")}
                                required
                                id="password-auth"
                                variant="outlined"
                                size="small"
                                label="Password"
                                helperText="Password must have at least 8 characters, one number or symbol and one uppercase letter."
                                FormHelperTextProps={{
                                    className: classes.helperText,
                                }}
                                className={classes.fullWidthField}
                                type={showPassword ? "text" : "password"}
                                error={Boolean(errors.password)}
                                InputProps={
                                    !errors.password_confirmation &&
                                    !errors.password &&
                                    passwordWatch?.length > 0 &&
                                    passwordWatch === passwordConfirmWatch
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleClickShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleClickShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={3} mb={1}>
                            <TextField
                                {...register("password_confirmation")}
                                required
                                id="confirm-password-auth"
                                variant="outlined"
                                size="small"
                                label="Confirm Password"
                                style={{ marginTop: -7 }}
                                className={classes.fullWidthField}
                                type={showPassword ? "text" : "password"}
                                error={Boolean(errors.password_confirmation)}
                                InputProps={
                                    !errors.password_confirmation &&
                                    !errors.password &&
                                    passwordConfirmWatch?.length > 0 &&
                                    passwordWatch === passwordConfirmWatch
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleClickShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleClickShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOff /> : <Visibility />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.password_confirmation && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password_confirmation.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={3} mb={1}>
                            <div className={classes.checkboxRow}>
                                <Checkbox color="secondary" {...register("acceptTerms")} />
                                <Typography variant="body2">
                                    I accept the{" "}
                                    <span className={classes.termsLink} onClick={() => setDialogOpen(true)}>
                                        Terms and Conditions
                                    </span>
                                    .
                                </Typography>
                            </div>
                            {errors.acceptTerms && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.acceptTerms.message}
                                </Typography>
                            )}
                        </Box>
                        <Button
                            disabled={Object.keys(errors).length > 0}
                            color="primary"
                            variant="contained"
                            size="small"
                            type="submit"
                            className={classes.registerButton}
                            disableElevation
                        >
                            Register
                        </Button>
                    </form>
                </div>
                <div className={classes.needHelp}>
                    <Typography variant="subtitle1" color="secondary.400">
                        Need help?
                    </Typography>
                </div>
            </div>
            <TermsDialog open={dialogOpen} handleClose={() => setDialogOpen(false)} />
        </Paper>
    );
}
