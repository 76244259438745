import React from "react";
import { Dialog, Typography, Button, DialogTitle, Divider, DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialogActions: {
        marginTop: 10,
    },
}));

export function DeleteModal({ open, onCancel, onDelete, organization }) {
    const classes = useStyles();

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <div className={classes.container}>
                <DialogTitle>Delete Organization</DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                    <Typography variant="body2" color="textPrimary">
                        Are you sure you want to permanently delete {organization?.full_name} organization?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="primary" variant="outlined" onClick={() => onCancel()} disableElevation>
                        Cancel
                    </Button>
                    <Button onClick={() => onDelete()} color="primary" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}
