export const COMPANY_SIZE_OPTIONS = [
    {
        value: "< 10",
        label: "< 10",
    },
    {
        value: "11 - 50",
        label: "11 - 50",
    },
    {
        value: "51 - 250",
        label: "51 - 250",
    },
    {
        value: "251 - 500",
        label: "251 - 500",
    },
    {
        value: "501 - 1000",
        label: "501 - 1000",
    },
    {
        value: "1001 - 5000",
        label: "1001 - 5000",
    },
    {
        value: "5001 - 10000",
        label: "5001 - 10,000",
    },
    {
        value: "> 10001",
        label: "> 10,001",
    },
];

export const COMPANY_INDUSTRY_OPTIONS = [
    {
        value: "Accounting",
        label: "Accounting",
    },
    {
        value: "Administration & Office Support",
        label: "Administration & Office Support",
    },
    {
        value: "Advertising Arts & Media",
        label: "Advertising Arts & Media",
    },
    {
        value: "Banking & Financial Services",
        label: "Banking & Financial Services",
    },
    {
        value: "Call Center & Customer Service",
        label: "Call Center & Customer Service",
    },
    {
        value: "Community Services & Development",
        label: "Community Services & Development",
    },
    {
        value: "Construction",
        label: "Construction",
    },
    {
        value: "Consulting & Strategy",
        label: "Consulting & Strategy",
    },
    {
        value: "Design & Architecture",
        label: "Design & Architecture",
    },
    {
        value: "Education & Training",
        label: "Education & Training",
    },
    {
        value: "Engineering",
        label: "Engineering",
    },
    {
        value: "Farming Animals & Conservation",
        label: "Farming Animals & Conservation",
    },
    {
        value: "Government & Defense",
        label: "Government & Defense",
    },
    {
        value: "Healthcare & Medical",
        label: "Healthcare & Medical",
    },
    {
        value: "Hospitality & Tourism",
        label: "Hospitality & Tourism",
    },
    {
        value: "Human Resources & Recruitment",
        label: "Human Resources & Recruitment",
    },
    {
        value: "Information & Communication Technology",
        label: "Information & Communication Technology",
    },
    {
        value: "Insurance & Superannuation",
        label: "Insurance & Superannuation",
    },
    {
        value: "Legal",
        label: "Legal",
    },
    {
        value: "Manufacturing Transport & Logistics",
        label: "Manufacturing Transport & Logistics",
    },
    {
        value: "Marketing & Communications",
        label: "Marketing & Communications",
    },
    {
        value: "Mining Resources & Energy",
        label: "Mining Resources & Energy",
    },
    {
        value: "Real Estate & Property",
        label: "Real Estate & Property",
    },
    {
        value: "Retail & Consumer Products",
        label: "Retail & Consumer Products",
    },
    {
        value: "Sales",
        label: "Sales",
    },
    {
        value: "Science & Technology",
        label: "Science & Technology",
    },
    {
        value: "Sport & Recreation",
        label: "Sport & Recreation",
    },
    {
        value: "Trades & Services",
        label: "Trades & Services",
    },
];
