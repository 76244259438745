import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "debounce";
import { Autocomplete, TextField, Paper, Checkbox, Stack, Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Flag from "react-country-flag";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { getCountriesApi } from "../../../services/api/common";

const useStyles = makeStyles(() => ({
    icon: {
        width: 22,
        height: 16,
    },
}));

export default function CountryMultiselect({ defaultValues = [], onSelected, displayTags = true }) {
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([...defaultValues]);
    const classes = useStyles();

    useEffect(() => {
        setSelectedCountries([...defaultValues]);
    }, [defaultValues]);

    const getCountries = useMemo(
        () =>
            debounce(async (countryName) => {
                const { data } = await getCountriesApi({ q: { name: countryName }, sort_by: { name: "asc" } });
                setCountries(data.records);
            }, 300),
        [setCountries],
    );

    const handleOnInputChange = async (e) => {
        if (!e) return;

        const countryName = e.target.value;
        if (!countryName) {
            setCountries([]);
            return;
        }

        getCountries(countryName);
    };

    const handleOnChange = (e, selected, reason) => {
        if (!displayTags && reason === "removeOption") return;

        if (reason === "clear" || (reason === "removeOption" && !selected.length)) {
            setSelectedCountries([]);
            onSelected([]);
            return;
        }
        if (!selected) return;

        const lastSelected = selected[selected.length - 1];
        if (!lastSelected.name) return;

        setSelectedCountries(selected);
        if (onSelected) {
            onSelected(selected);
        }
    };

    return (
        <Autocomplete
            disablePortal
            multiple
            freeSolo
            disableClearable={!displayTags}
            popupIcon={false}
            value={selectedCountries}
            options={countries.filter(
                (element) => selectedCountries.findIndex((item) => item.name === element.name) < 0,
            )}
            getOptionLabel={(option) => option.name}
            fullWidth
            variant="outlined"
            size="small"
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
            renderInput={(params) => (
                <TextField
                    color="secondary"
                    {...params}
                    label="Country"
                    placeholder="Select country..."
                    margin="dense"
                />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} checked={selected} />
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Flag countryCode={option.iso2} svg className={classes.icon} />
                        <Typography>{option.name}</Typography>
                    </Stack>
                </li>
            )}
            renderTags={
                displayTags
                    ? (items, getTagProps) =>
                          items.map((item, index) => (
                              <Chip
                                  label={item.name}
                                  {...getTagProps({ index })}
                                  icon={<Flag countryCode={item.iso2} svg className={classes.icon} />}
                              />
                          ))
                    : () => null
            }
        />
    );
}
