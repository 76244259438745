import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    DialogTitle,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import moment from "moment";
import { deleteStacksApi } from "../../../../../services/api/organization/organization";
import { addMessage } from "../../../../../slices/NotificationSlice/GlobalNotificationSlice";
import { getOrganizations } from "../../../../../actions/Admin/Organizations";
import { adminSliceSelectors } from "../../../../../slices/Admin/adminSlice";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        marginTop: 32,
    },
    tableContainer: {
        maxHeight: 490,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    tableCell: {
        border: "none",
    },
    tableHead: {
        position: "sticky",
        top: 0,
        background: "#f9f9ff",
        zIndex: 1,
    },
    tableHeadDivider: {
        position: "absolute",
        top: 45,
        width: "98%",
        borderColor: "black",
    },

    dialogActions: {
        position: "relative",
        bottom: 40,
        marginRight: 30,
    },
    dialogActionsButton: {
        fontSize: 20,
    },
    createStackButton: {
        zIndex: 1,
        height: 30,
        top: 20,
        left: 10,
        "&:hover": {
            background: "#405CB9",
            boxShadow: "none",
        },
    },
}));

export default function RequestedStacksModal({ open, onSave, onCancel, organization }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector(adminSliceSelectors.organizationsFilter);
    const [deletedStacks, setDeletedStacks] = useState([]);

    const handleSave = async () => {
        try {
            await deleteStacksApi(organization.id, deletedStacks);
            dispatch(
                addMessage({
                    type: "success",
                    title: "Stacks deleted",
                    message: "Stacks successfully deleted.",
                    visibilityTime: 5000,
                }),
            );
            dispatch(
                getOrganizations({
                    search: filter.q.name || undefined,
                    start: 1,
                    asc: filter.sort || undefined,
                    industry: filter.industry || undefined,
                    organization: filter.organization || undefined,
                    price: filter.price || undefined,
                    plan: filter.plan || undefined,
                }),
            );
        } catch (e) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }
        onSave();
        setDeletedStacks([]);
    };

    const handleDeleteStacks = (id) => {
        setDeletedStacks(
            deletedStacks.includes(id)
                ? (selectedStacks) => selectedStacks.filter((stacks) => stacks !== id)
                : [...deletedStacks, id],
        );
    };

    return (
        <Dialog
            open={open}
            PaperProps={{
                elevation: 4,
                sx: {
                    width: "100%",
                    maxWidth: 1100,
                    paddingLeft: 2,
                    paddingRight: 2,
                },
            }}
            fullWidth
        >
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="h5">{organization?.full_name || ""} Stacks</Typography>
            </DialogTitle>
            <DialogContent>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1" noWrap>
                                        Requested Data
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1" noWrap>
                                        Requested Date
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1" noWrap>
                                        Requested by
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} noWrap>
                                    <Typography variant="body1">Import Date </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} noWrap>
                                    <Typography variant="body1">Delete </Typography>
                                </TableCell>
                            </TableRow>
                            <Divider className={classes.tableHeadDivider} />
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {organization?.stacks.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body1">
                                            {item.data_request?.company_name || "N/A"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {item.data_request?.created_at
                                            ? moment(item.data_request.created_at).format("DD.MM.YYYY")
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {item.data_request?.user
                                            ? `${item.data_request.user.first_name} ${item.data_request.user.last_name}`
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {item.data_request?.updated_at
                                            ? moment(item.data_request.updated_at).format("DD.MM.YYYY")
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <IconButton
                                            onClick={() => handleDeleteStacks(item.id)}
                                            sx={{ color: deletedStacks.includes(item.id) ? "red" : "#616061" }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    variant="contained"
                    onClick={() => navigate("/admin/stacks/create")}
                    className={classes.createStackButton}
                >
                    Create Stack
                </Button>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    color="primary"
                    onClick={() => {
                        onCancel();
                        setDeletedStacks([]);
                    }}
                >
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSave} disabled={!deletedStacks.length}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
