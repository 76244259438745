import React, { useState, useMemo } from "react";
import { Menu, MenuItem, IconButton, Typography, Avatar, Button } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { navigate } from "@gatsbyjs/reach-router";
import { logOut } from "../../../services/api/Public/Login/loginActions";
import { userSliceSelectors, USER_TYPES } from "../../../slices/User/userSlice";
import { formatRole, ROLE_MANAGER, ROLE_SUPER_ADMIN } from "../../../components/Settings/Users/utils";
import { getBillingPortal, getSubscriptionCheckout } from "../../../actions/user/user";

const useStyles = makeStyles((theme) => ({
    settingsMenu: {
        margin: 0,
        "& ul": {
            width: theme.spacing(31.25),
        },
    },
    imagePreviewContainer: {
        overflow: "hidden",
        width: "24px",
        height: "24px",
        flexGrow: 0,
        borderRadius: "60px",
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
    },
    profilePhoto: {
        width: 40,
        height: 40,
    },
    profileSection: {
        display: "flex",
        width: "100%",
        height: 52,
        alignItems: "center",
    },
    pictureContainer: {
        marginRight: theme.spacing(2),
    },
    activeIcon: {
        color: "#5073E8 !important",
    },
    button: {
        height: 38,
        width: 110,
        fontWeight: 400,
        fontSize: 14,
    },
    invisibleButton: {
        height: 38,
        width: 110,
        marginRight: 1,
    },
}));

export default function AppSettings() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [profileMenu, setProfileMenu] = useState(null);
    const [helpMenu, setHelpMenu] = useState(null);
    const [settingsMenu, setSettingsMenu] = useState(null);
    const { first_name, roles, profile_picture } = useSelector(userSliceSelectors.defaultUserFields);
    const organization = useSelector(userSliceSelectors.organization) || null;
    const hasTrialOrSubscriptionEnded = useSelector(userSliceSelectors.hasTrialOrSubscriptionEnded);
    const type = useSelector(userSliceSelectors.type);
    const role = useMemo(() => {
        if (roles) {
            return type === USER_TYPES.CLIENT_ADMIN ? ROLE_SUPER_ADMIN : Object.values(roles)[0];
        }
        return null;
    }, [roles, type]);

    const openProfileMenu = (event) => setProfileMenu(event.currentTarget);
    const closeProfileMenu = () => setProfileMenu(null);
    const openSettingsMenu = (event) => setSettingsMenu(event.currentTarget);
    const closeSettingsMenu = () => setSettingsMenu(null);
    const handleLogOutClick = () => dispatch(logOut());
    const onDone = (url) => navigate(url);
    const handleSubscribe = () => dispatch(getSubscriptionCheckout({ onDone }));
    const handleSubscriptionSettings = () => dispatch(getBillingPortal({ onDone }));
    const openHelpMenu = (event) => setHelpMenu(event.currentTarget);
    const closeHelpMenu = () => setHelpMenu(null);

    return (
        <div>
            {organization && !organization?.subscription_ends_on ? (
                <Button
                    className={classes.button}
                    disabled={!organization?.plan_price_id}
                    sx={{ marginRight: 1 }}
                    size="large"
                    variant="subscribe"
                    onClick={handleSubscribe}
                >
                    Subscribe
                </Button>
            ) : (
                <Button className={classes.invisibleButton} size="large" disabled />
            )}
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" disabled>
                <SearchIcon />
            </IconButton>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openHelpMenu}
            >
                <HelpOutlineIcon className={!helpMenu ? "" : classes.activeIcon} />
            </IconButton>
            <Menu
                className={classes.settingsMenu}
                id="profile-menu"
                anchorEl={helpMenu}
                keepMounted
                open={Boolean(helpMenu)}
                onClose={closeHelpMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={closeHelpMenu} disabled>
                    <Typography variant="body2">Initiate the Tutorial for this page</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/help/faq")}>
                    <Typography variant="body2">FAQ</Typography>
                </MenuItem>
            </Menu>
            <IconButton
                onClick={openSettingsMenu}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
            >
                <SettingsOutlinedIcon className={!settingsMenu ? "" : classes.activeIcon} />
            </IconButton>
            <Menu
                className={classes.settingsMenu}
                id="profile-menu"
                anchorEl={settingsMenu}
                keepMounted
                open={Boolean(settingsMenu)}
                onClose={closeSettingsMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {type === USER_TYPES.OSTERUS_ADMIN && (
                    <MenuItem onClick={() => navigate("/organization/settings")}>
                        <Typography variant="body2">Organization Settings</Typography>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => navigate("/settings/users")}
                    disabled={role === ROLE_MANAGER || hasTrialOrSubscriptionEnded}
                >
                    <Typography variant="body2">User Management</Typography>
                </MenuItem>
                <MenuItem onClick={handleSubscriptionSettings} disabled={role === ROLE_MANAGER}>
                    <Typography variant="body2">Subscription Settings</Typography>
                </MenuItem>
            </Menu>
            <IconButton onClick={openProfileMenu}>
                <AccountCircleIcon className={!profileMenu ? "" : classes.activeIcon} />
            </IconButton>
            <Menu
                className={classes.settingsMenu}
                id="profile-menu"
                anchorEl={profileMenu}
                keepMounted
                open={Boolean(profileMenu)}
                onClose={closeProfileMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem disabled sx={{ opacity: "1 !important" }}>
                    <div className={classes.profileSection}>
                        <div className={classes.pictureContainer}>
                            <Avatar
                                src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profile_picture}`}
                                className={classes.profilePhoto}
                            />
                        </div>
                        <div className={classes.userInfoColumn}>
                            <Typography color="text.headlines" variant="h6" translate="no">
                                {first_name || "Mysterious"}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                {formatRole(role, true)}
                            </Typography>
                        </div>
                    </div>
                </MenuItem>
                <MenuItem disabled={hasTrialOrSubscriptionEnded} onClick={() => navigate("/account/settings")}>
                    <Typography variant="body2">Account Settings</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogOutClick}>
                    <Typography variant="body2">Logout</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
}
