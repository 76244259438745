import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQualityOfLifeApi } from "../../../services/api/Intelligence/QualityOfLife/QualityOfLifeApi";

export const getQualityOfLife = createAsyncThunk(
    "qualityOfLife/getQualityOfLife",
    async ({ currentCityId, comparisonCityId }) => {
        try {
            const { data } = await getQualityOfLifeApi(currentCityId, comparisonCityId);

            return { data };
        } catch (error) {
            throw error;
        }
    },
);
