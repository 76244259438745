import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { getPlansApi } from "../../../services/api/Admin/organizations";

export default function PlansAutocomplete({ defaultValue, onSelected, color = "primary", disabled = false }) {
    const [plans, setPlans] = useState([]);
    const isPrimary = color === "primary";

    useEffect(async () => {
        const { data } = await getPlansApi();
        const allPlans = data.map((item) => ({
            name: item.name,
            id: item.prices[0].plan_id,
        }));
        setPlans(allPlans);
    }, []);

    const handleOnInputChange = async (e) => {
        if (!e) return;
        const value = e.target.value;
        setPlans((currentPlan) => currentPlan.filter((item) => item !== value));
    };

    const handleOnChange = (e, planValue) => {
        if (!planValue) {
            onSelected(null);
        }
        const plan = plans.filter((item) => item.name === planValue)[0];
        if (onSelected) {
            onSelected(plan);
        }
    };

    return (
        <Autocomplete
            options={plans.map((plan) => plan.name)}
            fullWidth
            freeSolo
            forcePopupIcon
            value={defaultValue || ""}
            variant="outlined"
            size="small"
            disabled={disabled}
            color={color}
            renderInput={(params) => (
                <TextField color={isPrimary ? "secondary" : "primary"} {...params} label="Plan" margin="dense" />
            )}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={({ children }) => (
                <Paper variant={isPrimary ? "autocompleteDropdown" : "elevation"} elevation={isPrimary ? 1 : 0}>
                    {children}
                </Paper>
            )}
        />
    );
}
