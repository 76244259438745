import React, { useEffect, useMemo, useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { debounce } from "debounce";

const useStyles = makeStyles(() => ({
    textField: {
        width: "100%",
    },
}));

export default function SearchComponent({ onSearch, value = "", tooltipTitle = "", label = "", disabled = false }) {
    const classes = useStyles();
    const delayTime = 500;
    const [name, setName] = useState(value);
    const [isHover, setHover] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const showTooltip = isHover && !isFocus;

    const debouncedChangeHandler = useMemo(() => debounce(onSearch, 500), [onSearch]);

    useEffect(
        () => () => {
            debouncedChangeHandler.clear();
        },
        [],
    );

    return (
        <Tooltip open={showTooltip} enterNextDelay={delayTime} title={tooltipTitle} placement="top">
            <TextField
                open={showTooltip}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onMouseOver={() => {
                    if (!isFocus) {
                        setHover(true);
                    }
                }}
                onMouseLeave={() => setHover(false)}
                label={label}
                variant="outlined"
                margin="normal"
                size="small"
                color="secondary"
                fullWidth
                onChange={({ target }) => {
                    const { value: targetValue } = target;
                    setName(targetValue);
                    debouncedChangeHandler(targetValue);
                }}
                value={name}
                className={classes.textField}
                disabled={disabled}
            />
        </Tooltip>
    );
}
