import React, { useState, useRef, useEffect } from "react";
import lottie from "lottie-web";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import welcomeLottie from "./assets/welcome.json";
import welcomeAnimationLottie from "./assets/welcome-animation.json";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        minHeight: "85vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        transition: "width .8s",
    },
    animationBox: {
        margin: "auto",
        width: "100%",
        background: "linear-gradient(to right, #4973EB, #4973EB 50%, #4944EB 75%, #4944EB 75%)",
        transition: "width .8s",
    },
    welcome: {
        justifyContent: "center",
        textAlign: "center",
    },
    welcomeAnimation: {
        height: ({ animationHeight }) => animationHeight,
    },
    lottieImage: {
        margin: "auto",
        width: 400,
    },
}));

export default function Welcome() {
    const props = {
        animationHeight: window.innerHeight - 66,
    };
    const classes = useStyles(props);
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(true);
    const welcomeAnimation = useRef(null);
    const image = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: image.current,
            render: "svg",
            loop: true,
            autoplay: true,
            animationData: welcomeLottie,
        });

        const animation = lottie.loadAnimation({
            container: welcomeAnimation.current,
            render: "svg",
            loop: false,
            autoplay: true,
            animationData: welcomeAnimationLottie,
        });
        setTimeout(() => {
            animation.destroy();
            setIsAnimationPlaying(false);
        }, 6300);
    }, []);

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <div style={{ width: isAnimationPlaying ? null : 0 }} className={classes.animationBox}>
                <Box ref={welcomeAnimation} className={classes.welcomeAnimation} />
            </div>
            <div style={{ width: isAnimationPlaying ? 0 : null, overflow: "hidden" }} className={classes.root}>
                <Box className={classes.welcome}>
                    <Box className={classes.lottieImage} ref={image} />
                    <Typography variant="h4">Welcome to Osterus</Typography>
                    <Typography> Click on a menu to start your journey.</Typography>
                </Box>
            </div>
        </Box>
    );
}
