import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReferralsApi } from "../../services/api/Admin/referrals";

export const getReferrals = createAsyncThunk("admin/referrals", async ({ note, start = 1 }) => {
    try {
        const { data } = await getReferralsApi({ note, start });
        return { data };
    } catch (error) {
        throw error;
    }
});
