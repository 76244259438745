import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Box, Checkbox, Chip, Paper, TextField, Tooltip, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { requestedCompaniesSliceSelectors, updateFilter } from "../../../../slices/Stacks/requestedCompaniesSlice";
import { setUserList, userSliceSelectors } from "../../../../slices/User/userSlice";
import { formatUserFirstAndLastName } from "../../../Settings/Users/utils";
import { getUsers } from "../../../../actions/user/user";

const useStyles = makeStyles(() => ({
    autocompleteContainer: {
        display: "flex",
        alignItems: "center",
    },
    autocompleteInput: {
        padding: "3.94px 4px 3.94px 6px !important",
    },
}));

const delayTime = 500;
function UsersField() {
    const users = useSelector(userSliceSelectors.users);
    const usersList = users?.length > 0 && users[0]?.records?.length > 0 ? users[0].records : [];
    const filter = useSelector(requestedCompaniesSliceSelectors.getFilter);
    const [isHover, setHover] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const showTooltip = isHover && !isFocus;
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(
        () => () => {
            dispatch(setUserList(null));
        },
        [],
    );

    const handleChange = (changeEvent, currentValue, reason, details) => {
        switch (reason) {
            case "clear":
                dispatch(updateFilter({ ...filter, user: [] }));

                break;
            case "removeOption": {
                const modifiedFilter = filter.user.filter((user) => user.id !== details.option.id);
                dispatch(updateFilter({ ...filter, user: modifiedFilter }));
                break;
            }
            default: {
                let selectedOptions = filter.user || [];
                selectedOptions = [...selectedOptions, details.option];
                dispatch(updateFilter({ ...filter, user: selectedOptions }));
                break;
            }
        }
        dispatch(setUserList(null));
    };

    const handleInputChange = (changeEvent, currentValue) => {
        if (currentValue.length > 2) {
            dispatch(getUsers({ name: currentValue }));
        }
    };

    return (
        <Tooltip
            open={showTooltip}
            enterNextDelay={delayTime}
            title="Choose one or more users to filter down the requested companies."
            placement="top"
        >
            <Autocomplete
                multiple
                id="requested-by-user-filter"
                options={usersList.filter((option) => !(filter.user || []).some((user) => user.id === option.id))}
                PaperComponent={({ children }) => (
                    <Paper variant="autocompleteDropdown" sx={{ minWidth: "100%", width: "max-content" }}>
                        {children}
                    </Paper>
                )}
                popupIcon={false}
                noOptionsText="No options found"
                includeInputInList
                filterSelectedOptions={false}
                value={filter.user || []}
                getOptionLabel={(option) => formatUserFirstAndLastName(option, true)}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            checked={selected}
                        />
                        <Box className={classes.autocompleteContainer}>
                            <Typography>{formatUserFirstAndLastName(option, true)}</Typography>
                        </Box>
                    </li>
                )}
                onChange={handleChange}
                onInputChange={handleInputChange}
                renderTags={(usersArray, getTagProps) =>
                    usersArray.map((user, index) => (
                        <Chip label={formatUserFirstAndLastName(user, true)} {...getTagProps({ index })} />
                    ))
                }
                size="small"
                renderInput={(params) => (
                    <TextField
                        color="secondary"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        onMouseOver={() => {
                            if (!isFocus) {
                                setHover(true);
                            }
                        }}
                        onMouseLeave={() => setHover(false)}
                        {...params}
                        variant="outlined"
                        label="Requested by"
                        placeholder="Select user..."
                        onClick={() => dispatch(setUserList(null))}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                input: classes.autocompleteInput,
                            },
                        }}
                    />
                )}
            />
        </Tooltip>
    );
}

export default UsersField;
