import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function EmployersSection({ data }) {
    const employersAndJobPositions = useMemo(() => {
        const employersLeft = data.employersAndJobPositions.topFrequentCompanies.data[0];
        const employersRight = data.employersAndJobPositions.topFrequentCompanies.data[1];
        const employersLongerList = employersLeft.length > employersRight.length ? employersLeft : employersRight;

        const jobPositionsLeft = data.employersAndJobPositions.topFrequentJobTitles.data[0];
        const jobPositionsRight = data.employersAndJobPositions.topFrequentJobTitles.data[1];
        const jobPositionsLongerList =
            jobPositionsLeft.length > jobPositionsRight.length ? jobPositionsLeft : jobPositionsRight;

        return {
            employers: {
                values: employersLongerList.map((_, index) => [
                    shortenText(employersLeft[index], 25),
                    shortenText(employersRight[index], 25),
                ]),
            },
            jobPositions: {
                values: jobPositionsLongerList.map((_, index) => [jobPositionsLeft[index], jobPositionsRight[index]]),
            },
        };
    });

    return (
        <MetricSection name="Employers and Job Positions">
            <Stack width="100%">
                <MetricGroup
                    name="Most Frequent Employers in Osterus Database in the city"
                    values={employersAndJobPositions.employers.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={CorporateFareIcon}
                />
            </Stack>
            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Most Frequent Job Positions in Osterus Database in the city"
                    values={employersAndJobPositions.jobPositions.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={SavedSearchIcon}
                />
            </Stack>
        </MetricSection>
    );
}
