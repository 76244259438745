import React, { useState } from "react";
import { Dialog, Stack, Typography, TextField, Button, FormControlLabel, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userInviteSchema } from "./UserInviteSchema";
import { adminSliceSelectors } from "../../../../slices/Admin/adminSlice";
import { getUsers } from "../../../../actions/Admin/Users";
import { createAndInviteUserApi } from "../../../../services/api/Admin/users";
import { USER_TYPES } from "../../../../slices/User/userSlice";
import OrganizationAutocomplete from "../../../AutocompleteInputs/OrganizationAutocomplete";
import { addMessage } from "../../../../slices/NotificationSlice/GlobalNotificationSlice";

export function UserInviteModal({ open, onCancel, onInvite }) {
    const [selectedOrganization, setSelectedOrganization] = useState({});
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
        watch,
    } = useForm({
        resolver: yupResolver(userInviteSchema),
        defaultValues: { isClientAdmin: true },
    });
    const isClientAdmin = watch("isClientAdmin");
    const filter = useSelector(adminSliceSelectors.usersFilter);
    const dispatch = useDispatch();

    const onCancelClicked = () => {
        reset();
        onCancel();
    };

    const onSubmit = async (data) => {
        const formatedData = isClientAdmin
            ? {
                  email: data.email,
                  type: isClientAdmin ? USER_TYPES.CLIENT_ADMIN : USER_TYPES.USER,
              }
            : {
                  email: data.email,
                  type: isClientAdmin ? USER_TYPES.CLIENT_ADMIN : USER_TYPES.USER,
                  organization_id: selectedOrganization.id,
              };

        try {
            await createAndInviteUserApi(formatedData);
            dispatch(
                addMessage({
                    type: "success",
                    title: "Invitation has been sent!",
                    message: "",
                    visibilityTime: 5000,
                }),
            );
            dispatch(
                getUsers({
                    name: filter.q.name || undefined,
                    status: filter.q.status || undefined,
                    type: filter.q.type || undefined,
                    organization_name: filter.q.organization?.full_name || undefined,
                    department: filter.q.department || undefined,
                    job_title: filter.q.job_title || undefined,
                    base_in: filter.q.base_in || undefined,
                    referral_note: filter.q.referral_note || undefined,
                    sort: filter.sort || undefined,
                    direction: filter.direction || undefined,
                }),
            );
            reset();
            onInvite();
        } catch (error) {
            const msg = error.response?.data?.errors?.email[0].replaceAll(".", "") || "Something went wrong";
            setError("email", { type: "custom", message: msg });
        }
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack justifyContent="center" mx={8} mt={2} mb={1.5} sx={{ width: 290 }}>
                    <Typography variant="h5" textAlign="center" mb={5}>
                        Invite New User
                    </Typography>
                    <TextField {...register("email")} required variant="outlined" size="small" label="Email" />
                    {errors.email && (
                        <Typography color="error" variant="subtitle2">
                            {errors.email.message}
                        </Typography>
                    )}
                    <FormControlLabel
                        label="Account Owner"
                        control={<Switch color="secondary" {...register("isClientAdmin")} defaultChecked />}
                    />
                    <TextField label="Access Level" margin="dense" size="small" fullWidth disabled />
                    <OrganizationAutocomplete
                        color="secondary"
                        disabled={isClientAdmin}
                        onSelected={(organizationValue) => setSelectedOrganization(organizationValue)}
                    />
                </Stack>
                <Stack direction="row" justifyContent="right" mt={3} sx={{ paddingRight: 6, paddingBottom: 2 }}>
                    <Button size="small" disableElevation onClick={onCancelClicked}>
                        Cancel
                    </Button>
                    <Button size="small" disableElevation type="submit">
                        Send
                    </Button>
                </Stack>
            </form>
        </Dialog>
    );
}
