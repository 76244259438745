import React, { useState, useEffect, useRef } from "react";
import { Dialog, Stack, Typography, Button, TextareaAutosize, TextField, Fade, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "none",
        padding: theme.spacing(1),
    },
}));

export function GenerateInviteTokenModal({ open, onCancel, onGenerate, onClose }) {
    const textareaRef = useRef(null);
    const [error, setError] = useState(null);
    const [referral, setReferral] = useState(null);
    const [recentlyCopied, setRecentlyCopied] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setError(null);
        setReferral(null);
    }, [open]);

    const handleGenerateClicked = async () => {
        const note = textareaRef.current.value;
        if (!validate()) return;

        if (onGenerate) {
            const generatedReferral = await onGenerate(note);
            setReferral(generatedReferral);
        }
    };

    const validate = () => {
        const note = textareaRef.current.value;
        let isValid;
        if (note.length > 255) {
            if (!error) setError("Note must not be greater than 255 characters");
            isValid = false;
        } else if (error) {
            setError(null);
            isValid = true;
        } else isValid = true;

        return isValid;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referral.url);
        setRecentlyCopied(true);
        setTimeout(() => {
            setRecentlyCopied(false);
        }, 2000);
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <Stack m={1} sx={{ width: 500 }}>
                <Typography variant="h6" mx={2} my={2}>
                    Generate Invite Link
                </Typography>

                <Stack mx={2}>
                    <TextareaAutosize
                        ref={textareaRef}
                        placeholder="Note..."
                        minRows={6}
                        maxRows={6}
                        className={classes.textarea}
                        onChange={validate}
                    />
                    {error && (
                        <Typography color="error" variant="subtitle2">
                            {error}
                        </Typography>
                    )}
                    {referral && (
                        <Fade in timeout={500}>
                            <Stack direction="row" mt={2} mb={1} spacing={2}>
                                <TextField variant="outlined" value={referral.url} fullWidth size="small" />
                                <Tooltip placement="top" open={recentlyCopied} title="Copied!">
                                    <Button variant="contained" size="small" disableElevation onClick={copyToClipboard}>
                                        Copy
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Fade>
                    )}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2} mr={2} mb={0.5}>
                    {!referral ? (
                        <>
                            <Button variant="outlined" onClick={() => onCancel()} disableElevation>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={handleGenerateClicked} disableElevation>
                                Generate
                            </Button>
                        </>
                    ) : (
                        <Button variant="outlined" onClick={() => onClose()} disableElevation>
                            Close
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Dialog>
    );
}
