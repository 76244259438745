import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStackInsightsDataApi } from "../../../../services/api/TheLab/Stacks/StackInsights";

export const getStackInsightsData = createAsyncThunk(
    "stackInsights/getStackInsightsData",
    async ({ ids, view, show, split, v, filters }) => {
        const { data } = await getStackInsightsDataApi(ids, view, show, split, v, filters);
        return { data, split, show, view, v };
    },
);
