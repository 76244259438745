import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationsApi, getPlansApi } from "../../services/api/Admin/organizations";

export const getOrganizations = createAsyncThunk("admin/organizations", async (params, start = 1) => {
    try {
        const { data } = await getOrganizationsApi(params, { start });
        return { data };
    } catch (error) {
        throw error;
    }
});

export const getPlans = createAsyncThunk("admin/plans/", async (params) => {
    try {
        const { data } = await getPlansApi(params);
        return { data };
    } catch (error) {
        throw error;
    }
});
