import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dashboardLotie from "./osterus-loader.json";

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: "center",
        textAlign: "center",
    },
    lottieImage: {
        marginLeft: "auto",
        marginRight: "auto",
        width: theme.spacing(15),
    },
}));

export default function OsterusLoader() {
    const classes = useStyles();
    const image = useRef(null);
    useEffect(() => {
        lottie.loadAnimation({
            container: image.current,
            render: "svg",
            loop: true,
            autoplay: true,
            animationData: dashboardLotie,
        });
    }, []);

    return (
        <Box className={classes.root}>
            <Box className={classes.lottieImage} ref={image} />
        </Box>
    );
}
