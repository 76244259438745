import { createSlice } from "@reduxjs/toolkit";
import { getAllByFilter } from "../../actions/City/CityAction";
import {
    COMPARISON_CITY,
    CURRENT_CITY,
    DEFAULT_CITY,
} from "../../components/AutocompleteInputs/CityAutocomplete/constants";

const SLICE_NAME = "city";

const initialState = {
    loading: false,
    currentCityOptions: [],
    comparisonCityOptions: [],
    defaultCitiesOptions: null,
    currentCity: null,
    comparisonCity: null,
    defaultCities: null,
    timeZone: null,
};

export const citySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setCurrentCity(state, action) {
            state.currentCity = action.payload;
        },
        setComparisonCity(state, action) {
            state.comparisonCity = action.payload;
        },
        setDefaultCity(state, { payload }) {
            const { cityIndex, city } = payload;
            const modifiedDefaultCities = { ...state.defaultCities, [cityIndex]: city };
            state.defaultCities = modifiedDefaultCities;
        },
        setTimeZone(state, action) {
            state.timeZone = action.payload;
        },
        swapCities(state) {
            const newCurrentCity = state.comparisonCity;
            const newComparisonCity = state.currentCity;
            state.currentCity = newCurrentCity;
            state.comparisonCity = newComparisonCity;
        },
        clearState(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getAllByFilter.pending]: (state) => {
            state.loading = true;
        },
        [getAllByFilter.fulfilled]: (state, { payload }) => {
            const { cities, type, cityIndex } = payload;

            switch (type) {
                case DEFAULT_CITY: {
                    const modifiedDefaultOptions = { ...state.defaultCitiesOptions, [cityIndex]: cities };
                    state.defaultCitiesOptions = modifiedDefaultOptions;
                    break;
                }
                case CURRENT_CITY:
                    state.currentCityOptions = cities;
                    break;
                case COMPARISON_CITY:
                    state.comparisonCityOptions = cities;
                    break;
                default:
                    state.defaultCitiesOptions = cities;
                    state.currentCityOptions = [];
                    state.comparisonCityOptions = [];
            }
            state.loading = false;
        },
    },
});

export const { setCurrentCity, setComparisonCity, setDefaultCity, clearState, setTimeZone, swapCities } =
    citySlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const citySliceSelectors = {
    currentCityOptions: (rootState) => {
        const appState = getAppState(rootState);
        if (appState.comparisonCity) {
            return appState.currentCityOptions.filter((item) => item.id !== appState.comparisonCity.id);
        }
        return appState.currentCityOptions;
    },
    comparisonCityOptions: (rootState) => {
        const appState = getAppState(rootState);
        if (appState.currentCity) {
            return appState.comparisonCityOptions.filter((item) => item.id !== appState.currentCity.id);
        }
        return appState.comparisonCityOptions;
    },
    defaultCityOptions: (rootState) => {
        const appState = getAppState(rootState);
        return appState.defaultCitiesOptions;
    },
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    currentCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCity;
    },
    comparisonCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCity;
    },
    defaultCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.defaultCities;
    },
    timeZone: (rootState) => {
        const appState = getAppState(rootState);
        return appState.timeZone;
    },
};
