import React, { useState } from "react";
import { Grid, Card, Typography, Tooltip, Rating } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import { updateUniversityRatingApi } from "../../../../../services/api/Intelligence/Universities";
import { getUniversities } from "../../../../../actions/intelligence/Universities";
import { universitiesSelectors } from "../../../../../slices/Intelligence/UniversitiesSlice";
import { Profiles } from "./Profiles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    card: {
        height: theme.spacing(9),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        cursor: "pointer",
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderRadius: theme.spacing(0.25),
    },
    name: {
        height: theme.spacing(5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    employee: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(1.25),
        display: "flex",
        flexDirection: "column",
    },
    rating: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(1.25),
        display: "flex",
        flexDirection: "column",
    },
    universityName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
    },
    emptyStar: {
        color: "#CCCCCC",
    },
}));

export default function List({ universities, university, onSelected }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isRatingHovered, setIsRatingHovered] = useState(false);
    const filter = useSelector(universitiesSelectors.filter);
    const delayTime = 500;

    const onUniversitySelected = (index) => {
        const uni = universities[index];
        if (onSelected && !isRatingHovered) onSelected(uni);
    };

    const updateRating = async (id, value) => {
        await updateUniversityRatingApi(id, value);
        if (filter.internal_rating.length > 0) {
            dispatch(
                getUniversities({
                    q: filter.search ? { name: filter.search } : undefined,
                    country: filter.countries.map((country) => country.iso2),
                    city: filter.cities.map((city) => city.id),
                    start: filter.start,
                    limit: filter.limit,
                    internal_rating: filter.internal_rating.length ? filter.internal_rating : undefined,
                }),
            );
        }
    };

    return (
        <div className={classes.root}>
            {universities.length > 0 &&
                universities.map((item, index) => (
                    <Card
                        className={classes.card}
                        key={item.id}
                        onClick={() => onUniversitySelected(index)}
                        elevation={0}
                        style={{ background: item.id === university.id ? "#D1DBFF" : null }}
                        translate="no"
                    >
                        <Grid container>
                            <Grid item xs={1}>
                                <img src={item.logo} className={classes.avatar} alt="company logo" />
                            </Grid>
                            <Grid item xs={6}>
                                <div className={classes.name}>
                                    <Tooltip enterNextDelay={delayTime} title={item.name} placement="top-start">
                                        <Typography variant="h6" className={classes.universityName}>
                                            {item.name}
                                        </Typography>
                                    </Tooltip>
                                    {item.city && (
                                        <Typography variant="body2" color="textSecondary">
                                            {item.city.country.name}, {item.city.name}
                                        </Typography>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={3} className={classes.rating} alignItems="left">
                                <Typography variant="body2" color="text.secondaryVariant2">
                                    Internal Rating
                                </Typography>
                                <Rating
                                    name="size-medium"
                                    defaultValue={item.internal_rating}
                                    emptyIcon={<StarIcon className={classes.emptyStar} />}
                                    onMouseOver={() => setIsRatingHovered(true)}
                                    onMouseLeave={() => setIsRatingHovered(false)}
                                    onChange={(e, value) => updateRating(item.id, value || item.internal_rating)}
                                />
                            </Grid>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title={
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            display: "flex",
                                            flexDirection: "column",
                                            textAlign: "left",
                                        }}
                                    >
                                        <div>
                                            The Osterus engine shows data based on the profiles in the database who went
                                            to this university. The precision of this data is determined by the amount
                                            of profiles currently in the database.
                                        </div>
                                        <br />
                                        <div>
                                            <strong style={{ color: "#fe3358" }}>Red</strong> (less than 100 profiles)
                                            means that the data shown is not accurate.
                                        </div>
                                        <br />
                                        <div>
                                            <strong style={{ color: "#f57c00" }}>Orange</strong> (100-500 profiles)
                                            means that the data is moderately accurate.
                                        </div>
                                        <br />
                                        <div>
                                            <strong style={{ color: "#388e3c" }}>Green</strong> (above 500 profiles)
                                            means that the data is accurate.
                                        </div>
                                    </div>
                                }
                                placement="top-start"
                            >
                                <Grid item xs={2} className={classes.employee} alignItems="left">
                                    <Typography variant="body2">Profiles ({item.profiles})</Typography>
                                    <Profiles value={item.profiles} />
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Card>
                ))}
        </div>
    );
}
