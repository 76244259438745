import React from "react";
import { Autocomplete, Checkbox, Typography, TextField, Stack } from "@mui/material";

export default function FilterAutocomplete({ options, selectedOptions, placeholder, onOptionChanged }) {
    return (
        <Autocomplete
            multiple
            options={options}
            getOptionLabel={(option) => option.name}
            disableClearable
            size="small"
            renderOption={(props, option) => (
                <Stack key={option.id} direction="row" justifyContent="flex-start" alignItems="center">
                    <Checkbox
                        color="secondary"
                        checked={selectedOptions.findIndex((opt) => opt.id === option.id) > -1}
                        onChange={(e) => onOptionChanged(option, e.target.checked)}
                    />
                    <Typography variant="body2">{option.name}</Typography>
                </Stack>
            )}
            renderInput={(params) => <TextField {...params} label={placeholder} margin="none" />}
        />
    );
}
