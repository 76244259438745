import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Tabs, Tab, Divider } from "@mui/material";
import CitySelect from "../components/CitySelect";
import NoContentQualityOfLife from "./NoContentQualityOfLife";
import OsterusLoader from "../../Loader";
import { qualityOfLifeSelectors, clearState, setCities } from "../../../slices/Intelligence/QualityOfLifeSlice";
import { getQualityOfLife } from "../../../actions/intelligence/qualityOfLife";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import LivingStandard from "./Tabs/LivingStandard";
import BusinessPotential from "./Tabs/BusinessPotential";
// import { testData } from "./testData";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
    },
    header: {
        width: "100%",
        position: "sticky",
        zIndex: 2,
        top: 0,
    },
    noContent: {
        minHeight: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tabs: {
        "& button": {
            textTransform: "none",
            fontSize: 16,
            fontWeight: 400,
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiTab-root.Mui-selected": {
            color: theme.palette.additional1.main,
            fontWeight: 500,
        },
        "& .MuiTab-root": {
            minHeight: 48,
            border: "none",
        },
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Quality of Life Comparison",
        color: "text.onPrimary",
    },
    breadcrumbs: [
        {
            text: "Intelligence",
            color: "textSecondary",
            link: null,
            icon: null,
        },
        {
            text: "Quality of Life Comparison",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

function QualityOfLifeComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(qualityOfLifeSelectors.loading);
    const data = useSelector(qualityOfLifeSelectors.qualityOfLife);
    const currentCity = useSelector(qualityOfLifeSelectors.currentCity);
    const comparisonCity = useSelector(qualityOfLifeSelectors.comparisonCity);
    const currentCityError = useSelector(qualityOfLifeSelectors.currentCityError);
    const comparisonCityError = useSelector(qualityOfLifeSelectors.comparisonCityError);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => () => dispatch(clearState()), []);

    useEffect(() => {
        if (!isLoading && currentCity && comparisonCity && !data) {
            dispatch(
                addMessage({
                    type: "info",
                    title: "Oh no!",
                    message: `The city you have selected doesn't have any data to be compared. 
                    Our elves are on it, but until then - please search for a different city!`,
                    visibilityTime: 5000,
                }),
            );
        }
    }, [isLoading]);

    const handleCitiesSelected = (curCity, compCity) => {
        setTabIndex(0);
        dispatch(setCities({ currentCity: curCity, comparisonCity: compCity }));
        dispatch(getQualityOfLife({ currentCityId: curCity.id, comparisonCityId: compCity.id }));
    };

    const handleTabChanged = (e, value) => {
        setTabIndex(value);
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <CitySelect
                    defaultCurrentCity={currentCity}
                    defaultComparisonCity={comparisonCity}
                    onCitiesSelected={handleCitiesSelected}
                    currentCityError={isLoading ? false : currentCityError}
                    comparisonCityError={isLoading ? false : comparisonCityError}
                    breadcrumbComponents={breadcrumbComponents}
                />
            </div>
            {isLoading ? (
                <Box className={classes.noContent}>
                    <OsterusLoader />
                </Box>
            ) : data ? (
                <>
                    <Stack width="100%" px="5%" mt={1}>
                        <Tabs className={classes.tabs} value={tabIndex} onChange={handleTabChanged}>
                            <Tab value={0} label="Living Standard" />
                            <Tab value={1} label="Business Potential" />
                        </Tabs>
                        <Divider />
                    </Stack>
                    {tabIndex === 0 ? (
                        <LivingStandard data={data.livingStandard} />
                    ) : (
                        <BusinessPotential data={data.businessPotential} />
                    )}
                </>
            ) : (
                <Box className={classes.noContent}>
                    <NoContentQualityOfLife />
                </Box>
            )}
        </div>
    );
}

export default QualityOfLifeComponent;
