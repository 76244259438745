import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDefaultCitiesApi } from "../../services/api/common/index";

export const getDefaultCities = createAsyncThunk("intelligence/getDefaultCities", async () => {
    try {
        const { data } = await getDefaultCitiesApi();

        return data;
    } catch (error) {
        return error.response.data;
    }
});
