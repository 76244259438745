import React from "react";
import Chart from "react-apexcharts";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    "@global": {
        "#pieChart": {
            "& .apexcharts-tooltip": {
                color: "black",
                background: "white",
                border: "0.5px solid black",
                borderRadius: 0,
                height: 30,
                paddingTop: 4,
                justifyContent: "center",
                alignItems: "center",
            },
        },
    },
}));

export default function PieChart({ series, labels, colors, stackname }) {
    const classes = useStyles();
    const options = {
        chart: {
            width: "100%",
            type: "pie",
        },
        labels,
        colors,
        stroke: {
            show: false,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -10,
                    minAngleToShowLabel: 15,
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                },
            },
        ],
        legend: {
            show: false,
        },
        tooltip: {
            fillSeriesColor: false,
        },
    };

    return (
        <Stack width="100%" height="100%" className={classes.pieChart} id="pieChart">
            {stackname && (
                <Typography variant="h6" color="textSecondary" align="center" mb={2}>
                    {stackname}
                </Typography>
            )}
            <Chart options={options} series={series} type="pie" width="100%" height="100%" />
        </Stack>
    );
}
