import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography, Stack, TextField, Box, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { MetricSection } from "../components/MetricSection";
import { MetricItem } from "../components/MetricItem";
import { metricNameToIcon, split3Digits, parse3Digits } from "./utils";
import { costOfLivingSelectors } from "../../../slices/Intelligence/CostOfLivingSlice";

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        ...theme.components.MuiFormControl.variants[0].MenuStyle,
    },
    pricierMetric: {
        color: theme.palette.primary[100],
    },
    cheaperMetric: {
        color: theme.palette.primary[400],
    },
}));

const CURRENT_CITY = 0;
const COMPARISON_CITY = 1;

export function LocalPurchasingPowerSection({ onChange }) {
    const currentCity = useSelector(costOfLivingSelectors.currentCity);
    const comparisonCity = useSelector(costOfLivingSelectors.comparisonCity);
    const data = useSelector(costOfLivingSelectors.data);
    const localPurchasingPower = data?.livingStandard?.localPurchasingPower;
    const currencies = data?.livingStandard?.currencies;
    const { currentCityScore, comparisonCityScore } = localPurchasingPower;
    const [currentIncome, setCurrentIncome] = useState(
        Math.round(localPurchasingPower.currentCitySalary.primary.value),
    );
    const [comparisonIncome, setComparisonIncome] = useState(
        Math.round(localPurchasingPower.comparisonCitySalary.primary.value),
    );
    const incomeMultipliers = useMemo(() => [currentIncome / comparisonIncome, comparisonIncome / currentIncome], []);
    const [currency, setCurrency] = useState(localPurchasingPower.currentCitySalary.primary.currency);
    const classes = useStyles();
    const theme = useTheme();

    const handleIncomeChanged = (city, e) => {
        const value = e.target.value;
        const lastChar = e.nativeEvent.data;
        if (lastChar && Number.isNaN(parseInt(lastChar))) return;

        if (value === "") {
            setCurrentIncome("");
            setComparisonIncome("");
            onChange(currency, 0, 0);
            return;
        }

        const parsedValue = parseInt(parse3Digits(value));
        let curIncome;
        let compIncome;
        if (city === CURRENT_CITY) {
            curIncome = Math.round(parsedValue);
            compIncome = Math.round(parsedValue * incomeMultipliers[1]);
        } else {
            curIncome = Math.round(parsedValue * incomeMultipliers[0]);
            compIncome = Math.round(parsedValue);
        }

        setCurrentIncome(curIncome);
        setComparisonIncome(compIncome);
        onChange(currency, curIncome, compIncome);
    };

    const handleCurrencyChanged = (e) => {
        const value = e.target.value;
        const curIncome = Math.round(localPurchasingPower.currentCitySalary.currencies[value]);
        const compIncome = Math.round(localPurchasingPower.comparisonCitySalary.currencies[value]);

        setCurrency(value);
        setCurrentIncome(curIncome);
        setComparisonIncome(compIncome);

        if (onChange) onChange(value, curIncome, compIncome);
    };

    const scoreToBarPercentage = (score) => {
        switch (score) {
            case "Very High":
                return 100;
            case "High":
                return 75;
            case "Medium":
                return 50;
            case "Low":
                return 25;
            // no default
        }
    };

    const scoreToColor = (score) => {
        switch (score) {
            case "Very High":
                return theme.palette.success.main;
            case "High":
                return theme.palette.success.main;
            case "Medium":
                return theme.palette.warning.main;
            case "Low":
                return theme.palette.alert.main;
            // no default
        }
    };

    return (
        <MetricSection name="General">
            <Stack>
                <Stack>
                    <MetricItem
                        name="Local Purchasing Power(LPP)"
                        values={[currentCityScore, comparisonCityScore]}
                        barPercentages={[
                            scoreToBarPercentage(currentCityScore),
                            scoreToBarPercentage(comparisonCityScore),
                        ]}
                        indicators={[
                            metricNameToIcon("Local Purchasing Power (LPP)", {
                                className: classes.pricierMetric,
                            }),
                            metricNameToIcon("Local Purchasing Power (LPP)", {
                                className: classes.cheaperMetric,
                            }),
                        ]}
                        colors={[scoreToColor(currentCityScore), scoreToColor(comparisonCityScore)]}
                        tooltip={{
                            title: "Local Purchasing Power Score",
                            message:
                                "Shows relative purchasing power in buying goods and services in a given city for the average net salary in that city.",
                        }}
                    />
                </Stack>

                <Typography align="center" variant="caption" color="textSecondary" mt={2}>
                    {`${comparisonCity.name} has ${localPurchasingPower.percentage} 
                    ${localPurchasingPower.status} local purchasing power than ${currentCity.name}`}
                </Typography>
                <Typography align="center" variant="subtitle2" mt={3} mb={1}>
                    Income Comparison
                </Typography>
                <Stack alignItems="center" justifyContent="center" direction="row">
                    <TextField
                        variant="outlined"
                        specialvariant="basicInput"
                        size="small"
                        sx={{ width: 80 }}
                        value={split3Digits(currentIncome)}
                        onChange={(e) => handleIncomeChanged(CURRENT_CITY, e)}
                    />
                    <Box mx={4} translate="no">
                        <Select
                            variant="standard"
                            value={currency}
                            onChange={handleCurrencyChanged}
                            MenuProps={{
                                classes: { paper: classes.menuPaper },
                            }}
                        >
                            {currencies &&
                                currencies.map((item) => (
                                    <MenuItem key={item} value={item} translate="no">
                                        {item}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                    <TextField
                        variant="outlined"
                        specialvariant="basicInput"
                        size="small"
                        sx={{ width: 80 }}
                        value={split3Digits(comparisonIncome)}
                        onChange={(e) => handleIncomeChanged(COMPARISON_CITY, e)}
                    />
                </Stack>
                <Typography variant="caption" mt={1} mx={3} align="center" color="textSecondary">
                    {`You would need around ${split3Digits(currentIncome) || 0} ${currency} in ${currentCity.name}, 
                    ${currentCity.country.name} to maintain the same quality of life that you can 
                    with ${split3Digits(comparisonIncome) || 0} ${currency} in ${comparisonCity.name}, 
                    ${comparisonCity.country.name}`}
                </Typography>
            </Stack>
        </MetricSection>
    );
}
