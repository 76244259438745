import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

/**
 * TODO: Temporary array. Data MUST be received via API.
 */
const options = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
];

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    variant: "menu",
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    indeterminateColor: {
        color: theme.primary,
    },
    selectAllText: {
        fontWeight: "bold",
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
        },
    },
}));

function JobPositions() {
    const classes = useStyles();
    const delayTime = 500;
    const [selected, setSelected] = useState([]);
    const isAllSelected = options.length > 0 && selected.length === options.length;
    const elementRef = useRef(null);

    const handleChange = (event) => {
        const { value } = event.target;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            return;
        }
        setSelected(value);
    };

    useEffect(() => {
        MenuProps.PaperProps.style.width = elementRef.current.getBoundingClientRect().width;
    }, []);

    return (
        <Tooltip
            enterNextDelay={delayTime}
            title="UPCOMING FEATURE: Chose one or more job positions to filter down the displayed CV Stacks by the job positions contained within them."
            placement="top"
        >
            <FormControl
                ref={elementRef}
                id="tags-form-control"
                variant="outlined"
                size="small"
                className={classes.formControl}
            >
                <InputLabel>Job Positions</InputLabel>
                <Select
                    multiple
                    value={selected}
                    onChange={handleChange}
                    renderValue={(selectedItem) => selectedItem.join(", ")}
                    MenuProps={MenuProps}
                    label="Job Positions"
                    disabled
                >
                    <MenuItem
                        value="all"
                        classes={{
                            root: isAllSelected ? classes.selectedAll : "",
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                classes={{ indeterminate: classes.indeterminateColor }}
                                checked={isAllSelected}
                                indeterminate={selected.length > 0 && selected.length < options.length}
                            />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.selectAllText }} primary="Select all" />
                    </MenuItem>
                    <Divider />
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <ListItemIcon>
                                <Checkbox checked={selected.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Tooltip>
    );
}

export default JobPositions;
