import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import StatusFilter from "./Status";
import OrganizationAutocomplete from "../../../AutocompleteInputs/OrganizationAutocomplete";
import {
    clearDataRequestsFilter,
    adminSliceSelectors,
    setDataRequestsFilter,
    setActiveStack,
} from "../../../../slices/Admin/adminSlice";
import { getDataRequests } from "../../../../actions/Admin/DataRequests";
import SearchComponent from "../../../Search";

export default function Filter() {
    const filter = useSelector(adminSliceSelectors.dataRequestsFilter);
    const dispatch = useDispatch();

    const handleClearAll = () => {
        dispatch(clearDataRequestsFilter());
    };

    const handleOrganizationSelected = (organization) => {
        if (!organization) {
            dispatch(clearDataRequestsFilter());
            return;
        }

        dispatch(setDataRequestsFilter({ q: { ...filter.q, organization } }));
        dispatch(getDataRequests({ organization_id: organization.id, name: filter.q.name }));
        dispatch(setActiveStack({ organization }));
    };

    const onSearch = (value) => {
        dispatch(setDataRequestsFilter({ q: { ...filter.q, name: value } }));

        if (!filter.q.organization.id) return;

        dispatch(getDataRequests({ organization_id: filter.q.organization.id, name: value }));
    };

    return (
        <Box p={2.5}>
            <SearchComponent value={filter?.q?.name} onSearch={onSearch} label="Search here..." />
            <Box mt={3} mb={3}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="text.onPrimary">
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                        <Button variant="text" color="primary" size="small" onClick={handleClearAll}>
                            CLEAR ALL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} mb={3}>
                <StatusFilter />
            </Box>
            <Box mt={3} mb={3}>
                <OrganizationAutocomplete
                    defaultValue={filter.q.organization.full_name}
                    onSelected={handleOrganizationSelected}
                />
            </Box>
        </Box>
    );
}
