import React from "react";
import { Dialog, Stack, Typography, Button } from "@mui/material";

export function ReferralDeleteModal({ open, onCancel, onDelete }) {
    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <Stack m={1} sx={{ width: 500 }}>
                <Typography variant="h6" mx={3} my={2}>
                    Delete Referral
                </Typography>
                <Typography variant="body2" mb={3} mx={3}>
                    Are you sure you want to delete this referral?
                </Typography>
                <Stack direction="row" justifyContent="flex-end" spacing={1} mr={1} mb={0.5}>
                    <Button variant="outlined" onClick={() => onCancel()} disableElevation>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => onDelete()} disableElevation>
                        Delete
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}
