import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import lisabonCity from "../../../images/lisabon.png";
import munichCity from "../../../images/munich.png";
import romeCity from "../../../images/rome.png";
import warsawCity from "../../../images/warsaw.png";
import manchesterCity from "../../../images/manchester.png";
import osloCity from "../../../images/oslo.png";
import { intelligenceSelectors } from "../../../slices/Intelligence/IntelligenceSlice";
import { getDefaultCities } from "../../../actions/intelligence";
import { setCities } from "../../../slices/Intelligence/QualityOfLifeSlice";
import { getQualityOfLife } from "../../../actions/intelligence/qualityOfLife";

const useStyles = makeStyles(() => ({
    root: {
        justifyContent: "center",
        textAlign: "center",
    },
    text: {
        color: "#7F7F7F",
        fontSize: 11,
    },
    boldText: {
        fontWeight: 500,
    },
    header: {
        textAlign: "left",
        paddingLeft: 5,
        paddingBottom: 2,
        color: "#7F7F7F",
        fontSize: 14,
        fontWeight: 400,
    },
    universitiesText: {
        textAlign: "left",
        paddingLeft: 7,
        fontSize: 11,
    },
    universitiesSubText: {
        textAlign: "left",
        paddingLeft: 7,
        fontWeight: 500,
        fontSize: 12,
    },
    textBox: {
        paddingTop: 140,
    },
    leftBox: {
        backgroundPosition: "center",
        width: 90,
        height: 180,
        backgroundSize: "cover",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    rightBox: {
        backgroundPosition: "center",
        width: 90,
        height: 180,
        backgroundSize: "cover",
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    link: {
        cursor: "pointer",
        textDecoration: "none",
        color: "inherit",
    },
}));

export default function NoContent() {
    const defaultCities = useSelector(intelligenceSelectors.defaultCities);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (defaultCities) return;
        dispatch(getDefaultCities());
    }, []);

    const handleLinkClicked = (linkIndex) => {
        let currentCity;
        let comparisonCity;
        if (linkIndex === 0) {
            currentCity = defaultCities.find((city) => city.name === "Lisbon");
            comparisonCity = defaultCities.find((city) => city.name === "Munich");
        } else if (linkIndex === 1) {
            currentCity = defaultCities.find((city) => city.name === "Rome");
            comparisonCity = defaultCities.find((city) => city.name === "Warsaw");
        } else if (linkIndex === 2) {
            currentCity = defaultCities.find((city) => city.name === "Manchester");
            comparisonCity = defaultCities.find((city) => city.name === "Oslo");
        }

        dispatch(setCities({ currentCity, comparisonCity }));
        dispatch(getQualityOfLife({ currentCityId: currentCity.id, comparisonCityId: comparisonCity.id }));
    };

    return (
        <Box className={classes.root}>
            <Typography variant="h4" className={classes.typographyPrimary}>
                Let’s Start Comparing The Quality Of Life
            </Typography>
            <Typography className={classes.typographyPrimary}>
                Please select cities in the menu above comparing them
            </Typography>
            <Typography pt={1} className={classes.text}>
                OR
            </Typography>
            <Typography pt={1} pb={6} className={classes.boldText}>
                start by selecting one of the suggested choices:
            </Typography>
            <Stack>
                <Typography className={classes.header}>City</Typography>
                <Stack width="100%" direction="row" mb={1} justifyContent="center">
                    <Link className={classes.link} onClick={() => handleLinkClicked(0)}>
                        <Stack direction="row">
                            <Stack
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${lisabonCity})`,
                                }}
                                className={classes.leftBox}
                            >
                                <Stack className={classes.textBox}>
                                    <Typography className={classes.universitiesSubText}>Lisbon</Typography>
                                    <Typography color="text.secondary" className={classes.universitiesText}>
                                        Portugal
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                style={{
                                    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${munichCity})`,
                                }}
                                className={classes.rightBox}
                            >
                                <Stack className={classes.textBox}>
                                    <Typography className={classes.universitiesSubText}>Munich</Typography>
                                    <Typography color="text.secondary" className={classes.universitiesText}>
                                        Germany
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Link>

                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleLinkClicked(1)}>
                            <Stack direction="row">
                                <Stack
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${romeCity})`,
                                    }}
                                    className={classes.leftBox}
                                >
                                    <Stack className={classes.textBox}>
                                        <Typography className={classes.universitiesSubText}>Rome</Typography>
                                        <Typography color="text.secondary" className={classes.universitiesText}>
                                            Italy
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${warsawCity})`,
                                    }}
                                    className={classes.rightBox}
                                >
                                    <Stack className={classes.textBox}>
                                        <Typography className={classes.universitiesSubText}>Warsaw</Typography>
                                        <Typography color="text.secondary" className={classes.universitiesText}>
                                            Poland
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Link>
                    </Stack>
                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleLinkClicked(2)}>
                            <Stack direction="row">
                                <Stack
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${manchesterCity})`,
                                    }}
                                    className={classes.leftBox}
                                >
                                    <Stack className={classes.textBox}>
                                        <Typography className={classes.universitiesSubText}>Manchester</Typography>
                                        <Typography color="text.secondary" className={classes.universitiesText}>
                                            United Kingdom
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9) 22.22%, rgba(255, 255, 255, 0) 100%), url(${osloCity})`,
                                    }}
                                    className={classes.rightBox}
                                >
                                    <Stack className={classes.textBox}>
                                        <Typography className={classes.universitiesSubText}>Oslo</Typography>
                                        <Typography color="text.secondary" className={classes.universitiesText}>
                                            Norway
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
