import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Divider, Switch, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { generateMetricItemsProps, split3Digits } from "../utils";
import { CityInfo } from "../../components/CityInfo";
import { MetricSection } from "../../components/MetricSection";
import { MetricItem } from "../../components/MetricItem";
import { costOfLivingSelectors } from "../../../../slices/Intelligence/CostOfLivingSlice";
import { LocalPurchasingPowerSection } from "../LocalPurchasingPowerSection";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        paddingBottom: "35px",
    },
    dataContainer: {
        flex: 2,
        maxWidth: 660,
        overflow: "auto",
        paddingBottom: 10,
    },
    pricierMetric: {
        color: theme.palette.primary[100],
        transition: "color .4s",
    },
    cheaperMetric: {
        color: theme.palette.primary[400],
        transition: "color .4s",
    },
}));

export default function LivingStandard({ data }) {
    const isLoading = useSelector(costOfLivingSelectors.loading);
    const currentCity = useSelector(costOfLivingSelectors.currentCity);
    const comparisonCity = useSelector(costOfLivingSelectors.comparisonCity);

    const [countInLPP, setCountInLPP] = useState(false);
    const [barModeOn, setBarModeOn] = useState(false);

    const [currency, setCurrency] = useState("");
    const [currentCityIncome, setCurrentCityIncome] = useState(0);
    const [comparisonCityIncome, setComparisonCityIncome] = useState(0);

    useEffect(() => {
        if (isLoading || !data) return;

        setCurrency(data.localPurchasingPower.currentCitySalary.primary.currency);
        setCurrentCityIncome(split3Digits(Math.round(data.localPurchasingPower.currentCitySalary.primary.value)));
        setComparisonCityIncome(split3Digits(Math.round(data.localPurchasingPower.comparisonCitySalary.primary.value)));
    }, [isLoading]);

    const theme = useTheme();
    const classes = useStyles();

    const sections = useMemo(() => {
        if (!data || !currency) return;
        return generateMetricItemsProps(data, countInLPP, currentCity, comparisonCity, currency, classes, theme);
    }, [data, countInLPP, currency]);

    useEffect(() => {
        setCountInLPP(false);
        setBarModeOn(false);
    }, [data]);

    const handleToggleLPP = (e) => {
        setCountInLPP(e.target.checked);
    };

    const handleToggleBars = (e) => {
        setBarModeOn(e.target.checked);
    };

    const handleLppOnChange = (selectedCurrency, currentIncome, comparisonIncome) => {
        setCurrency(selectedCurrency);
        setCurrentCityIncome(split3Digits(currentIncome));
        setComparisonCityIncome(split3Digits(comparisonIncome));
    };

    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="center" width="100%" height="100%">
            {data && sections ? (
                <div className={classes.mainContainer}>
                    <CityInfo
                        image={currentCity.image}
                        title="Salary"
                        subtitle={currency}
                        value={currentCityIncome}
                        name={currentCity.name}
                        iso2Code={currentCity?.country?.iso2}
                    />
                    <div className={classes.dataContainer}>
                        <LocalPurchasingPowerSection onChange={handleLppOnChange} />
                        <MetricSection name={sections.combinedMetrics.name}>
                            {sections.combinedMetrics.metrics.map((metric) => (
                                <MetricItem
                                    key={metric.name}
                                    name={metric.name}
                                    values={metric.values}
                                    barPercentages={metric.barPercentages}
                                    indicators={metric.indicators}
                                    colors={metric.colors}
                                    variant="bar"
                                    position="sticky"
                                />
                            ))}
                            <Stack
                                justifyContent="flex-end"
                                direction="row"
                                sx={{ width: "100%" }}
                                alignItems="center"
                                mt={3}
                                pr={2.5}
                            >
                                <Typography variant="caption" color="textSecondary" mr={1}>
                                    Count in LPP
                                </Typography>
                                <Switch size="small" onChange={handleToggleLPP} />
                            </Stack>
                        </MetricSection>
                        <MetricSection name={sections.individualComparison.name}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" pr={2.5}>
                                <Typography variant="caption" color="textSecondary" mr={1}>
                                    Switch to Bars
                                </Typography>
                                <Switch size="small" onChange={handleToggleBars} />
                            </Stack>
                            {sections.individualComparison.subsections.map((subsection, index) => (
                                <React.Fragment key={index}>
                                    <Box ml={7} mb={1}>
                                        <Typography variant="overline" color="textSecondary">
                                            {subsection.name}
                                        </Typography>
                                    </Box>
                                    {subsection.metrics.map((metric) => (
                                        <MetricItem
                                            key={metric.name}
                                            name={metric.name}
                                            values={metric.values}
                                            subvalues={metric.subvalues}
                                            barPercentages={metric.barPercentages}
                                            indicators={metric.indicators}
                                            colors={metric.colors}
                                            variant={barModeOn ? "bar" : "text"}
                                            indicatorAlignment="end"
                                        />
                                    ))}
                                    {index < sections.individualComparison.subsections.length - 1 && (
                                        <Divider sx={{ mx: 6.5, my: 3 }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </MetricSection>
                    </div>
                    <CityInfo
                        image={comparisonCity.image}
                        title="Salary"
                        subtitle={currency}
                        value={comparisonCityIncome}
                        name={comparisonCity.name}
                        iso2Code={comparisonCity?.country?.iso2}
                    />
                </div>
            ) : null}
        </Stack>
    );
}
