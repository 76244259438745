import { createSlice } from "@reduxjs/toolkit";
import { getQualityOfLife } from "../../actions/intelligence/qualityOfLife";

const SLICE_NAME = "qualityOfLife";

const initialState = {
    loading: false,
    currentCity: null,
    comparisonCity: null,
    currentCityError: false,
    comparisonCityError: false,
    currentCityGeneralScore: null,
    comparisonCityGeneralScore: null,
    qualityOfLife: null,
};

export const qualityOfLifeSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setCities: (state, { payload }) => {
            state.currentCity = payload.currentCity;
            state.comparisonCity = payload.comparisonCity;
        },
        clearState: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getQualityOfLife.pending]: (state) => {
            state.loading = true;
        },
        [getQualityOfLife.fulfilled]: (state, { payload }) => {
            const { data } = payload;
            const livingStandard = data.livingStandard;
            const noDataForCurrentCity =
                livingStandard.general.every((item) => item.currentCity === null) &&
                livingStandard.social.every((item) => item.currentCity === null) &&
                livingStandard.infrastructure.every((item) => item.currentCity === null);
            const noDataForComparisonCity =
                livingStandard.general.every((item) => item.comparisonCity === null) &&
                livingStandard.social.every((item) => item.comparisonCity === null) &&
                livingStandard.infrastructure.every((item) => item.comparisonCity === null);

            state.loading = false;
            if (noDataForCurrentCity || noDataForComparisonCity) {
                state.currentCityError = noDataForCurrentCity;
                state.comparisonCityError = noDataForComparisonCity;
                state.qualityOfLife = null;
            } else {
                const nomadScore = livingStandard.general.splice(0, 1)[0];
                const [score1, rank1] = nomadScore.currentCity.value.split(/\(|\)/);
                const [score2, rank2] = nomadScore.comparisonCity.value.split(/\(|\)/);
                state.currentCityGeneralScore = { score: score1.trim(), rank: rank1.trim() };
                state.comparisonCityGeneralScore = { score: score2.trim(), rank: rank2.trim() };

                state.currentCityError = false;
                state.comparisonCityError = false;
                state.qualityOfLife = data;
            }
        },
    },
});

export const { clearState, setCities } = qualityOfLifeSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const qualityOfLifeSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    currentCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCity;
    },
    comparisonCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCity;
    },
    currentCityError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCityError;
    },
    comparisonCityError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCityError;
    },
    qualityOfLife: (rootState) => {
        const appState = getAppState(rootState);
        return appState.qualityOfLife;
    },
    currentCityGeneralScore: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCityGeneralScore;
    },
    comparisonCityGeneralScore: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCityGeneralScore;
    },
};
