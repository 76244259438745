import { createSlice } from "@reduxjs/toolkit";
import { getRequestedCompanies } from "../../actions/Company/Data/request";

const SLICE_NAME = "requestedCompanies";

const initialState = {
    requestedCompaniesList: null,
    loading: true,
    filter: {
        name: "",
        user: [],
        status: [],
        start: 1,
        limit: 25,
    },
};

export const requestedCompaniesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        updateFilter(state, action) {
            state.filter = action.payload;
        },
        setSearchValue(state, action) {
            state.filter.start = 1;
            state.filter.name = action.payload;
        },
        clearFilter(state) {
            state.filter = { ...state.filter, status: [], user: [] };
        },
        clearState(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getRequestedCompanies.pending]: (state) => {
            state.loading = true;
        },
        [getRequestedCompanies.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.requestedCompaniesList = payload;
        },
    },
});

export const { updateFilter, setSearchValue, clearFilter, clearState } = requestedCompaniesSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const requestedCompaniesSliceSelectors = {
    requestedCompaniesList: (rootState) => {
        const appState = getAppState(rootState);
        return appState.requestedCompaniesList;
    },
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    getFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.filter;
    },
};
