import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialBarChart from "../../../Charts/RadialBarChart";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";

const useStyles = makeStyles(() => ({
    loyaltyScoreText: {
        background: "white",
        color: "grey",
        fontSize: 10,
        width: "90%",
    },
    paper: {
        height: "100%",
    },
    subHeader: {
        fontWeight: 400,
    },
}));

const tooltips = [
    "Diversity between the male and female population of a company. Unknown/unspecified genders are not being taken into consideration.",
    "Diversity among variety of universities as an education indicator.",
    "Diversity among a variety of spoken languages.",
    "Diversity among predefined work experience groups. 0-3, 3-5, 5-10, and 10+ years of experience.",
    "Diversity among predefined age groups. <18, 18-25, 25-35, 35-55, 55+ years.",
    "Diversity among variety of cities as a location indicator.",
];

export function Section2({ stacks, data }) {
    const classes = useStyles();

    return (
        <Stack flex={1}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25}>
                    Diversity Score
                </Typography>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Stack flex={4} height={255} justifyContent="flex-end">
                        <RadialBarChart
                            value={data?.diversityAverageFinalScoreSingle?.stack?.series?.value}
                            rangeType="diversity"
                            height={250}
                        />
                    </Stack>
                    <Stack flex={6} height={255} justifyContent="center" pr={8}>
                        <Typography variant="h5" color="textSecondary" pt={1.25} pl={8} className={classes.subHeader}>
                            Score Metrics
                        </Typography>
                        <StackedBarWithCategories
                            stacks={stacks}
                            titleStyles={{ display: "none" }}
                            series={[data.diversityAverageSubScoresSingle.stack.series]}
                            categories={data.diversityAverageSubScoresSingle.categories}
                            barColors={["#4973EB"]}
                            itemsPerPage={6}
                            inPercentage={false}
                            max={1}
                            tickAmount={4}
                            noFormats
                            isDistributed
                            showActions={false}
                            barHeight="65%"
                            categoriesStyle={{
                                textAlign: "right",
                                marginBottom: 8,
                            }}
                            tooltips={data.diversityAverageSubScoresSingle.categories.map((category, index) => ({
                                title: category,
                                message: tooltips[index],
                            }))}
                            enableCustomTooltip
                            v2
                        />
                    </Stack>
                </Stack>

                <Stack margin="auto" width="100%" backgroundColor="white" alignItems="center" mt={4} pb={4}>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        *Diversity Scores are calculated based on the Score Metrics, where each Score Metric has an
                        equal contribution to the overall Diversity Score.
                    </Typography>
                    <Typography variant="body3" align="center" className={classes.loyaltyScoreText} display="inline">
                        Diversity Score Metrics in the chart are calculated via Shannon Diversity Index, their values
                        normalized and their average represents the Diversity Score.
                    </Typography>
                </Stack>
            </Paper>
        </Stack>
    );
}
