import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Dialog, Divider, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialog: {
        backdropFilter: "blur(5px)",
    },
    boxContainer: {
        width: "100%",
    },
    titleContainer: {
        padding: "16px 0 16px 24px",
    },
    divider: {
        margin: "0 24px",
    },
    progressContainer: {
        padding: "16px 0 16px 24px",
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
    },
    progressBarWrapper: {
        width: "100%",
        marginRight: theme.spacing(1),
    },
    progressBar: {
        height: 10,
        borderRadius: 5,
    },
    progressValueWrapper: {
        minWidth: 35,
    },
    loaderRow: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
    loader: {
        width: 50,
    },
    buttonContainer: {
        padding: "16px 24px 16px 24px",
        display: "flex",
        justifyContent: "flex-end",
    },
}));

const messages = [
    "Cutting up the Pie Charts",
    "Straightening out Columns and Bars",
    "Powering up the Osterus ChartMaker 2000™",
    "Rounding off the dots",
    "Adding the “able” to Table Charts",
];

function ProgressDialog({ open, valueRef, cancelRef }) {
    const classes = useStyles();
    const [value, setValue] = useState(valueRef.current);

    useEffect(() => {
        const timeoutId = setInterval(() => {
            const val = valueRef.current;
            if (val !== value) setValue(val);
        }, 200);

        return () => clearInterval(timeoutId);
    }, []);

    const shuffledMessages = useMemo(
        () =>
            messages
                .map((msg) => ({ msg, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ msg }) => msg),
        [messages],
    );

    const loadingMessage = useMemo(() => {
        if (value >= 0 && value <= 20) {
            return shuffledMessages[0];
        }
        if (value > 20 && value <= 40) {
            return shuffledMessages[1];
        }
        if (value > 40 && value <= 60) {
            return shuffledMessages[2];
        }
        if (value > 60 && value <= 80) {
            return shuffledMessages[3];
        }
        if (value > 80 && value <= 100) {
            return shuffledMessages[4];
        }
        return messages[0];
    }, [value]);

    const handleCancel = () => {
        if (cancelRef) cancelRef.current = true;
    };

    return (
        <Dialog open={open} className={classes.dialog}>
            <div className={classes.container}>
                <Box className={classes.boxContainer}>
                    <Box className={classes.titleContainer}>
                        <Typography color="textPrimary" variant="h6">
                            PDF Export Progress
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                </Box>
                <div className={classes.loaderRow}>
                    <div>
                        <Typography color="textPrimary" variant="h6">
                            {loadingMessage}
                        </Typography>
                    </div>
                </div>
                <Box className={classes.progressContainer}>
                    <Box className={classes.progressBarWrapper}>
                        <LinearProgress
                            className={classes.progressBar}
                            color="secondary"
                            variant="determinate"
                            value={value}
                        />
                    </Box>
                    <Box className={classes.progressValueWrapper}>
                        <Typography variant="body2" color="textPrimary">{`${value}%`}</Typography>
                    </Box>
                </Box>
                <Box className={classes.buttonContainer}>
                    <Button onClick={handleCancel}>Cancel</Button>
                </Box>
            </div>
        </Dialog>
    );
}

export default ProgressDialog;
