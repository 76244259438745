import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Search from "./Search";
import { clearFilter } from "../../../../slices/Stacks/requestedCompaniesSlice";
import StatusField from "./Status";
import UsersField from "./Users";

function Filter() {
    const dispatch = useDispatch();

    const clearFilters = () => {
        dispatch(clearFilter());
    };
    return (
        <Box p={2.5}>
            <Search />
            <Box mt={3} mb={3}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="text.onPrimary">
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                        <Button variant="text" color="primary" size="small" onClick={clearFilters}>
                            CLEAR ALL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} mb={3}>
                <StatusField />
            </Box>
            <Box mt={3} mb={3}>
                <UsersField />
            </Box>
        </Box>
    );
}

export default Filter;
