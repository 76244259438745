import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RadialBarChart from "../../../Charts/RadialBarChart";
import StackedBarChartWithDifference from "../../../MixedCharts/StackedBarChartWithDifference";

const useStyles = makeStyles(() => ({
    loyaltyScoreText: {
        background: "white",
        color: "grey",
        fontSize: 10,
        width: "94%",
    },
    subHeader: {
        fontWeight: 400,
    },
}));

const tooltips = [
    "Diversity between the male and female population of a company. Unknown/unspecified genders are not being taken into consideration.",
    "Diversity among variety of universities as an education indicator.",
    "Diversity among a variety of spoken languages.",
    "Diversity among predefined work experience groups. 0-3, 3-5, 5-10, and 10+ years of experience.",
    "Diversity among predefined age groups. <18, 18-25, 25-35, 35-55, 55+ years.",
    "Diversity among variety of cities as a location indicator.",
];

export function Section2({ stacks, data }) {
    const classes = useStyles();

    return (
        <Grid pl={1}>
            <Paper>
                <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25}>
                    Diversity Score
                </Typography>
                <Stack>
                    <Stack width="100%" direction="row" mb={1} mt={3}>
                        <Stack flex={1} alignItems="center">
                            <Typography variant="h6" color="textSecondary" ml={5}>
                                {stacks[0].stack.name}
                            </Typography>
                        </Stack>

                        <Stack flex={1} alignItems="center">
                            <Typography variant="h6" color="textSecondary" mr={7}>
                                {stacks[1].stack.name}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="center">
                        <RadialBarChart
                            value={data.diversityAverageFinalScoreSingle.stack1.series.value}
                            rangeType="diversity"
                        />
                        <RadialBarChart
                            value={data.diversityAverageFinalScoreSingle.stack2.series.value}
                            rangeType="diversity"
                        />
                    </Stack>
                </Stack>
                <Stack>
                    <Stack height={255}>
                        <Typography variant="h5" color="textSecondary" pl={1.5} pt={1.25} className={classes.subHeader}>
                            Score Metrics
                        </Typography>
                        <StackedBarChartWithDifference
                            chartInfo={{ title: "", shortTitle: "" }}
                            stacks={stacks}
                            firstStackSeries={[data.diversityAverageSubScoresSingle.comparisonChart.stack1.series]}
                            secondStackSeries={[data.diversityAverageSubScoresSingle.comparisonChart.stack2.series]}
                            categories={data.diversityAverageSubScoresSingle.comparisonChart.categories}
                            differenceSeries={[{ data: data.diversityAverageSubScoresSingle.diffChart.series.data }]}
                            stackedBarColors={[["#4973EB"], ["#4BC186"]]}
                            diffBarColors={["#7645c3", "#8f5edd", "#9366d9", "#986cdc", "#a782e1", "#b692ef"]}
                            itemsPerPage={6}
                            distributed
                            inPercentage={false}
                            showLegend={false}
                            elevation={0}
                            tooltips={data.diversityAverageSubScoresSingle.comparisonChart.categories.map(
                                (category, index) => ({
                                    title: category,
                                    message: tooltips[index],
                                }),
                            )}
                            enableCustomTooltip
                            containerStyle={{ pt: 4.6, width: 146, mr: -1, px: 1 }}
                            padding={[null, null, { bottom: 5, top: -27 }]}
                            diffHeight="90%"
                            chartStyles={{ mt: 0, ml: 16 }}
                            flexRatios={[7, 2]}
                            max={1}
                            tickAmount={4}
                            noFormats
                            isDistributed
                            showActions={false}
                            v2
                        />
                    </Stack>
                    <Stack margin="auto" width="100%" backgroundColor="white" alignItems="center" mt={8} pb={4}>
                        <Typography
                            variant="body3"
                            align="center"
                            className={classes.loyaltyScoreText}
                            display="inline"
                        >
                            *Diversity Scores are calculated based on the Score Metrics, where each Score Metric has an
                            equal contribution to the overall Diversity Score.
                        </Typography>
                        <Typography
                            variant="body3"
                            align="center"
                            className={classes.loyaltyScoreText}
                            display="inline"
                        >
                            Diversity Score Metrics in the chart are calculated via Shannon Diversity Index, their
                            values normalized and their average represents the Diversity Score.
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </Grid>
    );
}
