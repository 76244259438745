import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Divider, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Title } from "../../../components/Header/components/Title";
import OsterusLoader from "../../../components/Loader";
import CityAutocomplete from "../../../components/AutocompleteInputs/CityAutocomplete";
import { userSliceSelectors } from "../../../slices/User/userSlice";
import EditableField, { FIELD_TYPES } from "../../../components/EditableField";
import PhotoUpload from "../../../components/PhotoUpload";
import { getOrganizationSettingsApi, updateOrganizationSettingsApi } from "../../../services/api/organization/settings";
import { COMPANY_SIZE_OPTIONS, COMPANY_INDUSTRY_OPTIONS } from "../../../components/Organization/Create/formOptions";
import { uploadLogo } from "../../../actions/organization/logo";
import { organizationSliceSelectors, removeLogo } from "../../../slices/Organizations/organizationSlice";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";

const useStyles = makeStyles(() => ({
    divider: {
        margin: "0 24px",
    },
}));

export default function OrganizationSettingsPage() {
    const organizations = useSelector(userSliceSelectors.organizations);
    const organization = organizations ? organizations[0] : null;
    const uploadedLogo = useSelector(organizationSliceSelectors.organizationLogo);
    const [settings, setSettings] = useState(null);
    const logo = uploadedLogo || settings?.logo || null;
    const headquarters = useMemo(() => {
        if (!settings?.locations?.length) return;
        return settings.locations.filter((item) => item.is_headquarter)[0]?.city;
    });
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(async () => {
        if (!organization) return;

        const { data } = await getOrganizationSettingsApi(organization.id);
        setSettings(data.data);
    }, [organization]);

    useEffect(async () => {
        if (!settings || logo === settings.logo) return;

        await handleSubmit(["logo", logo]);
    }, [logo, settings]);

    const handleSubmit = async (nameValuePair) => {
        const [fieldName, fieldValue] = nameValuePair;

        let payload;
        let newSettings;
        switch (fieldName) {
            case "owner_id":
                payload = { [fieldName]: fieldValue.id };
                newSettings = await updateSettings(fieldName, payload);
                if (newSettings) setSettings(newSettings);
                break;
            case "locations":
                payload = {
                    [fieldName]: settings.locations
                        .filter((item) => !item.is_headquarter)
                        .map((item) => ({
                            ref_city_id: item.city.id,
                            is_headquarter: false,
                            name: item.name || undefined,
                        })),
                };
                payload[fieldName].splice(0, 0, {
                    ref_city_id: fieldValue.ref_city_id,
                    is_headquarter: true,
                });

                newSettings = await updateSettings(fieldName, payload);
                if (newSettings) setSettings(newSettings);
                break;
            default:
                payload = { [fieldName]: fieldValue };
                newSettings = await updateSettings(fieldName, payload);
                if (newSettings) setSettings(newSettings);
        }
    };

    const updateSettings = async (fieldName, payload) => {
        try {
            const { data } = await updateOrganizationSettingsApi(organization.id, payload);
            return data.data;
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong :(",
                    visibilityTime: 5000,
                }),
            );
            return false;
        }
    };

    const handleImageUpload = (nameValuePair) => {
        const image = nameValuePair[1];
        dispatch(uploadLogo(image));
    };

    const handleImageRemoved = () => {
        dispatch(removeLogo());
        handleSubmit(["logo", null]);
    };

    return (
        <>
            <Stack width="100%">
                <Stack pt={2.4} pb={2.6} pl={3}>
                    <Title value="Organization Settings" />
                </Stack>
                <Divider className={classes.divider} />
                {settings ? (
                    <Stack pl={5} pt={6} gap={2} mb={2}>
                        <Stack direction="row" gap={5} alignItems="center">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Organization Logo</Typography>
                            </Stack>
                            <EditableField
                                name="logo"
                                hoverText="Upload"
                                displayComponent={
                                    <Avatar
                                        src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${settings.logo}`}
                                    />
                                }
                                inputComponent={
                                    <PhotoUpload
                                        open
                                        picture={settings.logo}
                                        title="Upload Logo"
                                        handleSave={handleImageUpload}
                                        handleRemove={handleImageRemoved}
                                    />
                                }
                                alignment="center"
                                dialog
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Official Organization Name</Typography>
                            </Stack>
                            <EditableField
                                name="full_name"
                                type={FIELD_TYPES.requiredText}
                                value={settings.full_name || "N/A"}
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Organization Display Name</Typography>
                            </Stack>
                            <EditableField
                                name="display_name"
                                type={FIELD_TYPES.text}
                                value={settings.display_name || "N/A"}
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline" mb={5}>
                            <Stack minWidth={200}>
                                <Typography variant="body2">Organization Email</Typography>
                            </Stack>
                            <EditableField
                                name="email"
                                type={FIELD_TYPES.email}
                                value={settings.email || "N/A"}
                                onSubmit={handleSubmit}
                            />
                        </Stack>

                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Organization Size</Typography>
                            </Stack>
                            <EditableField
                                name="size"
                                value={settings.size || "N/A"}
                                dropdownOptions={COMPANY_SIZE_OPTIONS}
                                alignment="center"
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Industry</Typography>
                            </Stack>
                            <EditableField
                                name="industry"
                                value={settings.industry || "N/A"}
                                dropdownOptions={COMPANY_INDUSTRY_OPTIONS}
                                alignment="center"
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline" mb={5}>
                            <Stack minWidth={200}>
                                <Typography variant="body2">Headquarters Location</Typography>
                            </Stack>
                            <EditableField
                                name="locations"
                                value={
                                    headquarters?.name ? `${headquarters.name}, ${headquarters.country.name}` : "N/A"
                                }
                                autocomplete
                                inputComponent={
                                    <CityAutocomplete
                                        defaultValue={headquarters}
                                        onChange={([name, value, { saved } = {}]) =>
                                            value && saved
                                                ? handleSubmit([name, { ref_city_id: value?.id }])
                                                : undefined
                                        }
                                        errorOnEmpty
                                        inputProps={{
                                            className: classes.input,
                                            startAdornment: undefined,
                                        }}
                                        showSave
                                    />
                                }
                            />
                        </Stack>

                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Website</Typography>
                            </Stack>
                            <EditableField
                                name="website"
                                type={FIELD_TYPES.text}
                                value={settings.website || "N/A"}
                                multiline
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline" mb={5}>
                            <Stack minWidth={200}>
                                <Typography variant="body2">LinkedIn</Typography>
                            </Stack>
                            <EditableField
                                name="linkedin"
                                type={FIELD_TYPES.text}
                                value={settings.linkedin || "N/A"}
                                multiline
                                onSubmit={handleSubmit}
                            />
                        </Stack>

                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Account Owner</Typography>
                            </Stack>
                            <EditableField
                                name="owner_id"
                                type={FIELD_TYPES.text}
                                value={
                                    settings.owner
                                        ? settings.owner.first_name || settings.owner.last_name
                                            ? `${settings.owner.first_name || ""} ${settings.owner.last_name || ""}`
                                            : "Mysterious"
                                        : "N/A"
                                }
                                dropdownOptions={settings.users.map((user) => {
                                    const name =
                                        user.first_name || user.last_name
                                            ? `${user.first_name || ""} ${user.last_name || ""}`
                                            : "Mysterious";
                                    return { value: user, label: name };
                                })}
                                alignment="center"
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Account Created</Typography>
                            </Stack>
                            <Typography variant="body2" color="textSecondary">
                                {dayjs(settings.created_at).format("MMM D, YYYY")}
                            </Typography>
                        </Stack>
                        <Stack direction="row" gap={5} alignItems="baseline" mb={5}>
                            <Stack minWidth={200}>
                                <Typography variant="body2">Number of Users</Typography>
                            </Stack>
                            <Typography variant="body2" color="textSecondary">
                                {settings.number_of_users}
                            </Typography>
                        </Stack>

                        <Stack direction="row" gap={5} alignItems="baseline">
                            <Stack minWidth={200}>
                                <Typography variant="body2">Organization Description</Typography>
                            </Stack>
                            <EditableField
                                name="description"
                                type={FIELD_TYPES.text}
                                value={settings.description || "N/A"}
                                multiline
                                alignment="center"
                                onSubmit={handleSubmit}
                            />
                        </Stack>
                    </Stack>
                ) : (
                    <Stack height={500} justifyContent="center">
                        <OsterusLoader />
                    </Stack>
                )}
            </Stack>
        </>
    );
}
