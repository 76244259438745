import React from "react";
import { Typography, Stack, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTooltip from "../../../../../CustomTooltip";

const useStyles = makeStyles(() => ({
    categories: {
        fontSize: 12,
        cursor: "pointer",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

export default function StackedBarCategories({
    categories,
    containerStyle,
    categoriesStyle,
    tooltips,
    align = "center",
    enableCustomTooltip = false,
    onClick,
    inactiveCategories,
}) {
    const classes = useStyles();
    const disabledColor = "#808080";

    return (
        <Stack sx={containerStyle}>
            {categories.map((item, index) =>
                enableCustomTooltip ? (
                    <CustomTooltip
                        title={
                            inactiveCategories && inactiveCategories.includes(index)
                                ? ""
                                : tooltips
                                ? typeof tooltips[index] === "string"
                                    ? tooltips[index]
                                    : tooltips[index].title
                                : item
                        }
                        message={tooltips ? tooltips[index].message : ""}
                        key={index}
                        element={
                            <Typography
                                variant="body2"
                                className={classes.categories}
                                style={categoriesStyle}
                                align={align}
                                color={
                                    !inactiveCategories
                                        ? "primary"
                                        : inactiveCategories.includes(index)
                                        ? disabledColor
                                        : "primary"
                                }
                                onClick={onClick ? () => onClick(index, item) : undefined}
                            >
                                {item.replace("Degree", "")}
                            </Typography>
                        }
                    />
                ) : (
                    <Tooltip
                        title={
                            inactiveCategories && inactiveCategories.includes(index)
                                ? ""
                                : tooltips
                                ? typeof tooltips[index] === "string"
                                    ? tooltips[index]
                                    : tooltips[index].title
                                : item
                        }
                        key={index}
                    >
                        <Typography
                            variant="body2"
                            className={classes.categories}
                            style={categoriesStyle}
                            align={align}
                            color={
                                !inactiveCategories
                                    ? "primary"
                                    : inactiveCategories.includes(index)
                                    ? disabledColor
                                    : "primary"
                            }
                            onClick={onClick ? () => onClick(index, item) : undefined}
                        >
                            {item.replace("Degree", "")}
                        </Typography>
                    </Tooltip>
                ),
            )}
        </Stack>
    );
}
