import { axiosInstance } from "../../../../config/api/Axios";

export const getUniversitiesApi = async (params = { start: 1 }) =>
    axiosInstance.get("/intelligence/universities", { params });

export const getUniversityApi = async (id) => axiosInstance.get(`/intelligence/universities/${id}`);

export const updateUniversityRatingApi = async (universityId, value) =>
    axiosInstance.post("/intelligence/ratings", {
        source_target_id: universityId,
        source_target: "university",
        source: "elasticsearch",
        value,
    });
