import React, { useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteAccountDialog from "./DeleteAccountDialog";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: "flex",
        alignItems: "center",
        padding: "9px 0 9px 0",
    },
    leftColumn: {
        width: "20%",
    },
    deleteAccountButton: {
        cursor: "pointer",
    },
    container: {
        width: "100%",
        padding: "24px 16px 0 40px",
    },
}));

function PreferenceSettingsComponent() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true);
    };
    const closeDialog = () => {
        setOpen(false);
    };

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <Typography color="textSecondary" fontWeight={500} variant="overline">
                    ACCOUNT PREFERENCES
                </Typography>
            </div>
            <div className={classes.row}>
                <div className={classes.leftColumn}>
                    <Typography variant="body2" color="textPrimary">
                        Email Notification
                    </Typography>
                </div>
                <Typography variant="body2" color="textSecondary">
                    Coming soon!
                </Typography>
            </div>
            <div className={classes.row}>
                <div className={classes.leftColumn}>
                    <Typography variant="body2" color="textPrimary">
                        Delete Account
                    </Typography>
                </div>
                <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.deleteAccountButton}
                    onClick={openDialog}
                >
                    Delete Account
                </Typography>
            </div>
            <DeleteAccountDialog open={open} handleClose={closeDialog} openDialog={openDialog} />
        </div>
    );
}

export default PreferenceSettingsComponent;
