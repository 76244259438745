import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { userSliceSelectors, setFilter } from "../../../../slices/User/userSlice";
import SearchComponent from "../../../Search";

const useStyles = makeStyles((theme) => ({
    filterPaper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        justifyContent: "center",
    },
}));

export default function Filter() {
    const filter = useSelector(userSliceSelectors.filter);
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSearch = (value) => {
        dispatch(setFilter({ ...filter, name: value === "" ? undefined : value, page: 1 }));
    };

    return (
        <Paper className={classes.filterPaper} elevation={0} variant="sidebar">
            <SearchComponent
                label="Search here..."
                value={filter?.name}
                onSearch={onSearch}
                tooltipTitle="Search for the name of the user to narrow down the list of users."
            />
        </Paper>
    );
}
