export const shortenText = (text, charLimit) => {
    if (!text) return text;

    const shortText = [];
    const words = text.split(" ");
    let length = 0;
    for (const word of words) {
        if (length + word.length <= charLimit) {
            shortText.push(word);
            length += word.length;
        } else break;
    }

    return shortText.join(" ");
};
