import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";

export const parseErrorsAndNotifyUser = (error, dispatch) => {
    const errorData = error?.response?.data;
    if (errorData.errors) {
        for (const field in errorData.errors) {
            if (Object.prototype.hasOwnProperty.call(errorData.errors, field)) {
                for (const err of errorData.errors[field]) {
                    dispatch(
                        addMessage({
                            type: "error",
                            title: err,
                        }),
                    );
                }
            }
        }
    } else {
        dispatch(
            addMessage({
                type: "error",
                title: errorData?.error?.message,
            }),
        );
    }
};
