import React from "react";
import DoubleSeriesHistogramLineChart from "./variants/double";
import SingleSeriesHistogramLineChart from "./variants/single";

export default function HistogramLineChart(props) {
    const { variant } = props;

    let chart = null;
    switch (variant) {
        case "double":
            chart = <DoubleSeriesHistogramLineChart {...props} />;
            break;
        case "single":
            chart = <SingleSeriesHistogramLineChart {...props} />;
            break;
        default:
            throw new Error("Invalid variant");
    }

    return chart;
}
