import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
    notifications: [],
};

const SLICE_NAME = "globalNotifications";

export const globalNotificationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        addMessage: (state, { payload }) => {
            const notification = {
                uuid: uuidv4(),
                type: payload.type,
                title: payload.title || "",
                message: payload.message || "",
                visibilityTime: payload.visibilityTime || 5000,
                source: payload.source,
            };
            state.notifications.push(notification);
        },
        deleteMessage: (state, { payload }) => {
            const index = state.notifications.findIndex((value) => value.uuid === payload);
            if (index === -1) {
                return;
            }

            state.notifications.splice(index, 1);
        },
        turnOffNotifications: (state) => {
            state.notifications = initialState.notifications;
        },
    },
});

export const { addMessage, deleteMessage, turnOffNotifications } = globalNotificationSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const globalNotificationSliceSelectors = {
    notifications: (rootState) => {
        const appState = getAppState(rootState);
        return appState.notifications;
    },
};
