import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { adminSliceSelectors, setReferralsFilter } from "../../../../slices/Admin/adminSlice";
import SearchComponent from "../../../Search";
import { getReferrals } from "../../../../actions/Admin/Referrals";

export default function Filter() {
    const filter = useSelector(adminSliceSelectors.referralsFilter);
    const dispatch = useDispatch();

    const onSearch = (value) => {
        dispatch(setReferralsFilter({ ...filter, q: { ...filter.q, name: value } }));
        dispatch(getReferrals({ note: value || undefined }));
    };

    return (
        <Box p={2.5}>
            <SearchComponent value={filter?.q?.name} onSearch={onSearch} label="Search notes..." />
        </Box>
    );
}
