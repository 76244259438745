import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Stack, Typography, Button, IconButton, TextField, Divider, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkIcon from "@mui/icons-material/Link";
import { generateSharedToken } from "../../../../../services/api/Shared";
import { addMessage } from "../../../../../slices/NotificationSlice/GlobalNotificationSlice";

const useStyles = makeStyles(() => ({
    textField: {
        width: 285,
    },
    icon: {
        width: 28,
        height: 28,
        transform: "rotate(-45deg)",
    },
}));

export function ShareModal({ stackIds, viewType, open, onClose }) {
    const [link, setLink] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        setLink(null);
    }, [...stackIds]);

    useEffect(async () => {
        if (!open || link) return;

        const type = "stack";
        const { data } = await generateSharedToken(type, stackIds);
        if (data) {
            setLink(`${window.location.host}/shared/${viewType}?code=${data.code}`);
        }
    }, [open, link]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        dispatch(
            addMessage({
                type: "success",
                title: "Link successfully copied to clipboard!",
                visibilityTime: 5000,
            }),
        );
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <Stack m={1} sx={{ width: 500 }}>
                <Typography variant="h5" mx={2} my={2}>
                    Share Osterus Insights
                </Typography>

                <Divider />

                {link && (
                    <Fade in timeout={500}>
                        <Stack mx={2} mt={4}>
                            <Stack direction="row" alignItems="center" mt={2} mb={1} spacing={2}>
                                <Stack justifyContent="center">
                                    <Typography variant="h6">Share Link</Typography>
                                </Stack>
                                <TextField variant="outlined" value={link} size="small" className={classes.textField} />
                                <IconButton onClick={copyToClipboard}>
                                    <LinkIcon className={classes.icon} />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Fade>
                )}

                <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2} mr={2} mb={1}>
                    <Button variant="text" onClick={() => onClose()} disableElevation>
                        Close
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}
