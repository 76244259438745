import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../../osterus.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "95vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    imgContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    logo: {
        width: 500,
        marginBottom: theme.spacing(6),
    },
}));

export default function Farewell() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box>
                <div className={classes.imgContainer}>
                    <img src={logo} className={classes.logo} alt="osterus logo" />
                </div>
                <Typography variant="h4">Sorry to see you go! We hope you will be back soon.</Typography>
            </Box>
        </Box>
    );
}
