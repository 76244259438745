import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";

export default function RankingSection({ data, currentCity, comparisonCity, classes, theme }) {
    const ranking = useMemo(() => {
        const rankingInCountry = data.qualityOfLifeRanking.qualityOfLifeRankingInTheCountry.data;
        const leftRankingInCountrySmaller = rankingInCountry[0] > rankingInCountry[1];
        const isEqual = rankingInCountry[0] === rankingInCountry[1];
        return {
            rankingInCountry: {
                values: rankingInCountry,

                barPercentages: isEqual
                    ? [100, 100]
                    : [
                          leftRankingInCountrySmaller ? (rankingInCountry[1] / rankingInCountry[0]) * 100 : 100,
                          leftRankingInCountrySmaller ? 100 : (rankingInCountry[0] / rankingInCountry[1]) * 100,
                      ],
                colors: isEqual
                    ? [theme.palette.success.main, theme.palette.success.main]
                    : [
                          leftRankingInCountrySmaller ? theme.palette.alert.main : theme.palette.success.main,
                          leftRankingInCountrySmaller ? theme.palette.success.main : theme.palette.alert.main,
                      ],
                indicators: isEqual
                    ? [<SyncIcon className={classes.higherScore} />, <SyncIcon className={classes.higherScore} />]
                    : [
                          <SyncIcon
                              className={leftRankingInCountrySmaller ? classes.smallerScore : classes.higherScore}
                          />,
                          <SyncIcon
                              className={leftRankingInCountrySmaller ? classes.higherScore : classes.smallerScore}
                          />,
                      ],
                message: `${comparisonCity.name} has ${
                    isEqual ? "equal" : leftRankingInCountrySmaller ? "higher" : "lower"
                } Quality of life ranking in the country ${isEqual ? "as" : "than"}  ${currentCity.name}`,
            },
        };
    }, [data]);

    return (
        <MetricSection name="Quality Of Life Ranking" padding="12px 0 26px 0">
            <MetricItem
                name="Quality of life ranking in the country"
                values={ranking.rankingInCountry.values}
                barPercentages={ranking.rankingInCountry.barPercentages}
                colors={ranking.rankingInCountry.colors}
                indicators={ranking.rankingInCountry.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {ranking.rankingInCountry.message}
                </Typography>
            </Stack>
        </MetricSection>
    );
}
