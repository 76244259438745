import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllByFilterApi, getSelectedStacksApi } from "../../../services/api/TheLab/Stacks/stackActions";

export const getAllByFilter = createAsyncThunk("stacks/getAllByFilter", async (filter = {}) => {
    const params = {
        ...filter,
    };

    if (filter.tags && filter.tags.length > 0) {
        params.tags = filter.tags.map((item) => item.id);
    }

    const { data } = await getAllByFilterApi(params);
    return data;
});

export const getSelectedStacks = createAsyncThunk("stacks/getSelectedStacks", async (params) => {
    const { data } = await getSelectedStacksApi(params);
    return data;
});
