import React from "react";
import AppBar from "@mui/material/AppBar";
// import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CssBaseline, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import AppMenu from "./components/Menu";
import AppSettings from "./components/Settings";
import { Dark as DarkMode } from "./mods/Dark";
import logo from "../../osterus-wht.svg";
import { GlobalToastNotificationsComponent } from "../../components/Notifications/GlobalToastNotifications/index";
import { userSliceSelectors } from "../../slices/User/userSlice";
import { formatTimeLeft } from "../../components/Settings/Users/utils";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        boxShadow: "none",
    },
    logoHover: {
        cursor: "pointer",
        width: 125,
    },
    blurredContainer: {
        filter: "blur(5px)",
        pointerEvents: "none",
        userSelect: "none",
        width: "100%",
        height: "100%",
    },
    container: {
        width: "100%",
        height: "100%",
    },
}));

export default function Default({ component, hideNavigation }) {
    const organization = useSelector(userSliceSelectors.organization) || {};
    const hasTrialOrSubscriptionEnded = useSelector(userSliceSelectors.hasTrialOrSubscriptionEnded);
    const classes = useStyles();
    const appliedTheme = createTheme(DarkMode);
    const Component = component;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appliedTheme}>
                <GlobalToastNotificationsComponent />
                <Grid container className={classes.root}>
                    <CssBaseline />
                    <AppBar className={classes.appBar} variant="regular" position="relative">
                        <Toolbar>
                            <Box mr={2} sx={{ display: "flex", alignItems: "center" }}>
                                <img
                                    width={150}
                                    className={classes.logoHover}
                                    src={logo}
                                    alt="logo"
                                    onClick={hideNavigation ? undefined : () => navigate("/")}
                                />
                            </Box>
                            {organization.is_trial ? (
                                <Typography variant="h7" color="textSecondary">
                                    Trial Version: {formatTimeLeft(organization.trial_ends_at)} days left
                                </Typography>
                            ) : null}
                            {!hideNavigation && (
                                <>
                                    <div className={classes.title}>
                                        <AppMenu />
                                    </div>
                                    <AppSettings />
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <div className={!hasTrialOrSubscriptionEnded ? classes.container : classes.blurredContainer}>
                        <Component />
                    </div>
                </Grid>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
