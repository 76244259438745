import React from "react";
import { useParams } from "@gatsbyjs/reach-router";
import {
    EMPLOYMENT_COMPARISON_INSIGHT,
    EMPLOYMENT_SINGLE_INSIGHT,
    EDUCATION_COMPARISON_INSIGHT,
    EDUCATION_SINGLE_INSIGHT,
    OVERVIEW_COMPARISON_INSIGHT,
    OVERVIEW_SINGLE_INSIGHT,
    DEMOGRAPHICS_COMPARISON_INSIGHT,
    DEMOGRAPHICS_SINGLE_INSIGHT,
} from "../../components/TheLab/Stacks/StackInsights/Views/viewConstants";
import SharedStackInsights from "./StackInsights";

export default function SharedView() {
    const view = useParams().view || "";

    switch (view) {
        case EMPLOYMENT_COMPARISON_INSIGHT:
        case EMPLOYMENT_SINGLE_INSIGHT:
        case EDUCATION_COMPARISON_INSIGHT:
        case EDUCATION_SINGLE_INSIGHT:
        case OVERVIEW_COMPARISON_INSIGHT:
        case OVERVIEW_SINGLE_INSIGHT:
        case DEMOGRAPHICS_COMPARISON_INSIGHT:
        case DEMOGRAPHICS_SINGLE_INSIGHT:
            return <SharedStackInsights view={view} />;
        // no default
    }
}
