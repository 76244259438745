import React, { useMemo } from "react";
import { Typography, Avatar, IconButton, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { formatLastActive, formatRole, ROLE_SUPER_ADMIN } from "../utils";
import { setIsInfo, setSelectedUser, userSliceSelectors } from "../../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(6),
    },
    basicInfoRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
    },
    profilePicture: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    close: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    email: {
        cursor: "pointer",
    },
    avatar: {
        height: 80,
        width: 80,
    },
    pictureColumn: {
        display: "flex",
        justifyContent: "flex-start",
        gap: theme.spacing(2),
    },
    additionalInfoRow: {
        marginBottom: theme.spacing(3.5),
        display: "flex",
        justifyContent: "space-between",
    },
    infoItem: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    singleItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    statusActive: {
        color: theme.palette.success.main,
    },
    statusInactive: {
        color: theme.palette.text.disabled,
    },
    link: {
        textDecoration: "none",
    },
    dividerBottom: {
        marginBottom: theme.spacing(2),
    },
}));

export default function UserInfo() {
    const selectedUser = useSelector(userSliceSelectors.selectedUser);
    const {
        first_name,
        last_name,
        email,
        department,
        roles,
        status,
        last_login_at,
        profile_picture,
        city,
        timezone,
        organizations,
        type,
    } = selectedUser;

    const role = useMemo(() => {
        if (roles) {
            return type === 2 ? ROLE_SUPER_ADMIN : Object.values(roles)[0];
        }
        return null;
    }, [roles, type]);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(setSelectedUser(null));
        dispatch(setIsInfo(false));
    };

    return (
        <div className={classes.container}>
            <div className={classes.basicInfoRow}>
                <div className={classes.pictureColumn}>
                    <div className={classes.profilePicture}>
                        {profile_picture ? (
                            <Avatar
                                src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profile_picture}`}
                                variant="square"
                                className={classes.avatar}
                            />
                        ) : (
                            <Avatar variant="square" className={classes.avatar} />
                        )}
                    </div>
                    <div className={classes.userInfo}>
                        <div translate="no">
                            <Typography color="text.headlines" variant="body1">
                                {first_name || last_name ? `${first_name || ""} ${last_name || ""}` : "--"}
                            </Typography>
                        </div>
                        <div>
                            <Typography color="textSecondary" variant="body2">
                                {organizations?.length > 0 ? organizations[0].display_name : "--"}
                            </Typography>
                        </div>
                        <div>
                            {email ? (
                                <a className={classes.link} href={`mailto:${email}`} target="_blank" rel="noreferrer">
                                    <Typography className={classes.email} color="secondary.light" variant="body3">
                                        {email}
                                    </Typography>
                                </a>
                            ) : (
                                <Typography variant="body2">--</Typography>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.close}>
                    <IconButton onClick={handleClose} size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <Divider className={classes.dividerBottom} />
            <div className={classes.additionalInfoRow}>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {formatRole(role, true)}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Role
                        </Typography>
                    </div>
                </div>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {formatLastActive(last_login_at)}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Last Active
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalInfoRow}>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {department || "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Department
                        </Typography>
                    </div>
                </div>
                <div className={classes.infoItem}>
                    <div>
                        <Typography
                            className={status === "active" ? classes.statusActive : classes.statusInactive}
                            variant="body4"
                        >
                            {status === "active" ? "Active" : "Inactive"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Status
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalInfoRow}>
                <div className={classes.singleItem}>
                    <div translate="no">
                        <Typography color="textPrimary" variant="body2">
                            {city && city.name && city.country ? `${city.name}, ${city.country.name}` : "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Based In
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalInfoRow}>
                <div className={classes.singleItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2" translate="no">
                            {timezone || "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Local Time
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
}
