import React, { useState, useEffect } from "react";
import { Grid, Paper, Stack, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MapChart from "../../../Charts/MapChart";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";

const useStyles = makeStyles(() => ({
    root: {
        margin: "auto",
        width: "100%",
        height: 553,
    },
    paper: {
        width: "100%",
        height: "100%",
    },
    degreesPaper: {
        height: "100%",
        width: "100%",
    },
}));

export default function Section2({ stacks, data }) {
    const classes = useStyles();
    const [formatedData, setFormatedData] = useState([]);

    const fetchData = () => {
        if (data.universityLocation) {
            const newData = [
                data.universityLocation.stack
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 0, stackname: stacks[0]?.stack?.name })),
            ];
            const formattedLong = newData.map((item) => item.map((el) => ({ ...el, long: el.lng })));
            setFormatedData(formattedLong);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data]);

    const numberOfSpokenLanguagesPerJobTitleFormated = [
        {
            name: stacks[0]?.stack?.name,
            data: data?.numberOfSpokenLanguagesPerJobTitle?.stack?.series[0]?.data,
        },
    ];

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <MapChart
                        variant="anychart"
                        id="TestAnychart"
                        data={formatedData}
                        stacks={stacks}
                        title="University Locations"
                        actionsOffset={{ y: -95, x: 50 }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column" height="100%" width="100%">
                    <Stack flex="initial" height={230} justifyContent="center">
                        <Paper className={classes.degreesPaper}>
                            <Stack flex={1}>
                                <Stack flex={1} pr="10%">
                                    <StackedBarWithCategories
                                        title="Top Spoken Languages"
                                        barColors={["#5073E8"]}
                                        barHeight="56%"
                                        categoriesFlex="initial"
                                        stackedBarFlex={7}
                                        itemsPerPage={5}
                                        stacks={stacks}
                                        categories={data.languagesSpoken.categories}
                                        series={data.languagesSpoken.stack.series}
                                        titleStyles={{
                                            position: "relative",
                                            top: 5,
                                            background: "transparent",
                                        }}
                                        titleTypographyStyle={{ paddingLeft: 10 }}
                                        containerStyle={{ marginTop: 0.3, width: 130 }}
                                        categoriesStyle={{ marginBottom: 12.4, textAlign: "right" }}
                                        chartStyle={{ mt: 3.75 }}
                                        gridXaxisLines={false}
                                        noXaxis
                                        paddingBottomPerItem={29.4}
                                        actionsOffset={{ y: -55, x: -15 }}
                                    />
                                </Stack>
                            </Stack>
                        </Paper>
                    </Stack>
                    <Divider />
                    <Stack flex={1}>
                        <Paper className={classes.degreesPaper}>
                            <Stack width="100%" height="100%" pb={2}>
                                <StandardVerticalBarChart
                                    variant="normalValue"
                                    title="Number of Spoken Languages"
                                    shortTitle="Number of Spoken Languages"
                                    categories={data.numberOfSpokenLanguagesPerJobTitle.categories}
                                    series={numberOfSpokenLanguagesPerJobTitleFormated}
                                    colors={{ defaultColors: ["#afbff2"] }}
                                    elevation={0}
                                    showTableView={false}
                                />
                            </Stack>
                        </Paper>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}
