import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestCompanyDataApi, getRequestedCompaniesApi } from "../../../services/api/Company/Data/request";
import { getMe } from "../../../services/api/Public/Login/loginActions";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";

export const COMPANY_STATUSES = {
    DONE: "done",
    IN_PROGRESS: "in_progress",
};

export const requestCompanyData = createAsyncThunk("stacks/requestCompanyData", async (companyData, { dispatch }) => {
    try {
        const { data } = await requestCompanyDataApi(companyData);
        dispatch(
            addMessage({
                type: "success",
                title: "Request successfully sent!",
                message: "Expect an email with the estimate of delivery soon(ish).",
                visibilityTime: 5000,
            }),
        );
        return { data };
    } catch {
        dispatch(
            addMessage({
                type: "error",
                title: "Something went wrong.",
                visibilityTime: 5000,
            }),
        );
        dispatch(getMe());
    }
});

export const getRequestedCompanies = createAsyncThunk(
    "requestedCompanies/getRequestedCompanies",
    async ({ orgId, filter = null }) => {
        const params = {
            ...filter,
        };

        if (filter.status && filter.status.length > 0) {
            if (filter.status.length === 1) {
                params.status = filter.status[0].id;
            } else {
                params.status = "";
            }
        }

        if (filter.user && filter.user.length > 0) {
            params.user = filter.user.map((item) => item.id);
        }

        const { data } = await getRequestedCompaniesApi(orgId, params);
        const inProgressRequests = [];
        const doneRequests = [];

        // sort requested companies by status
        for (const item of data.records) {
            if (item.status && item.status === COMPANY_STATUSES.DONE) {
                doneRequests.push(item);
            } else {
                inProgressRequests.push(item);
            }
        }

        const sortedData = {
            ...data,
            records: [...inProgressRequests, ...doneRequests],
        };

        return sortedData;
    },
);
