import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ShowModes, SplitModes } from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

dayjs.extend(duration);

export const sortGenderData = (data) => {
    const modifiedCategories = ["Female", "Male", "Undisclosed"];
    const modifiedSeries = [0, 0, 0];
    const { categories, series } = data;

    for (let i = 0; i < categories.length; i += 1) {
        const item = categories[i];

        if (item.toLowerCase() === "f" || item.toLowerCase() === "female") {
            const oldValue = modifiedSeries[0];
            modifiedSeries[0] = Math.round((oldValue + series[i]) * 100) / 100;
        } else if (item.toLowerCase() === "m" || item.toLowerCase() === "male") {
            const oldValue = modifiedSeries[1];
            modifiedSeries[1] = Math.round((oldValue + series[i]) * 100) / 100;
        } else {
            const oldValue = modifiedSeries[2];
            modifiedSeries[2] = Math.round((oldValue + series[i]) * 100) / 100;
        }
    }
    return { categories: modifiedCategories, series: modifiedSeries };
};

export const formatTimeValues = (durationValue) => {
    if (durationValue.includes("yrs") || durationValue.includes("y")) {
        const numberValue = durationValue.split(" ")[0];
        const yearMonthValues = numberValue.split(".");

        const years = yearMonthValues[0];
        const months = Number(`0.${yearMonthValues[1]}`);
        const formattedMonth = Math.floor(dayjs.duration({ years: months }).asMonths());

        return yearMonthValues[1] ? `${years}y ${formattedMonth}m` : `${years}y`;
    }
    return durationValue;
};

export const getColors = (seriesNames, showMode, splitMode) => {
    if (seriesNames.includes(undefined)) {
        return ["#5172E8", "#4BC186"];
    }
    let nameToColor;
    if (!showMode && !splitMode) {
        return ["#5172E8", "#4BC186"];
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.gender
    ) {
        nameToColor = { male: "#5172E8", female: "#BAA2E1", unknown: "#ececec", undisclosed: "#ececec" };
        return seriesNames.map((name) => nameToColor[name.toLowerCase()]);
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.none
    ) {
        return ["#5172E8", "#4BC186"];
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.degree
    ) {
        nameToColor = { "bachelor's degree": "#B0C7E9", "master's degree": "#7988E3", "doctoral degree": "#685FE1" };
        return seriesNames.filter((name) => name !== "Total").map((name) => nameToColor[name.toLowerCase()]);
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.group
    ) {
        nameToColor = { "10+": "#088953", "5-10": "#18c352", "3-5": "#b1db7c", "0-3": "#d7e4bb" };
        return seriesNames.map((name) => nameToColor[name.toLowerCase()]);
    }
};

export const getLegend = (showMode, splitMode, stacks) => {
    if ((showMode === ShowModes.percent || showMode === ShowModes.durationYears) && splitMode === SplitModes.gender) {
        return [
            { name: "Male", color: "#5172E8" },
            { name: "Female", color: "#BAA2E1" },
            { name: "Unknown", color: "#ececec" },
        ];
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.none
    ) {
        return [
            { name: stacks[0].stack.name, color: "#5172E8" },
            { name: stacks[1].stack.name, color: "#4BC186" },
        ];
    } else if (!showMode && !splitMode) {
        return [
            { name: stacks[0].stack.name, color: "#5172E8" },
            { name: stacks[1].stack.name, color: "#4BC186" },
        ];
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.degree
    ) {
        return [
            { name: "Bachelor's", color: "#B0C7E9" },
            { name: "Master's", color: "#7988E3" },
            { name: "Doctoral", color: "#685FE1" },
        ];
    } else if (
        (showMode === ShowModes.percent || showMode === ShowModes.durationYears) &&
        splitMode === SplitModes.group
    ) {
        return [
            { name: "0-3 yrs", color: "#d7e4bb" },
            { name: "3-5 yrs", color: "#b1db7c" },
            { name: "5-10 yrs", color: "#18c352" },
            { name: "10+ yrs", color: "#088953" },
        ];
    }
};

export const getMarkersBarHeight = (numberOfCategories, isSingle) => {
    const single = isSingle || false;
    if (!single) {
        switch (numberOfCategories) {
            case 1:
                return 50;
            case 2:
                return 73;
            case 3:
                return 98;
            default:
                return 123;
        }
    } else {
        const initialHeight = 17;
        const neededBarSpace = 26.5;
        return initialHeight + neededBarSpace * (numberOfCategories - 1);
    }
};

export const opacityAppliedToOpaqueColor = (color, opacity, backgroundColor = 255) => {
    let rgbArray = color;
    if (typeof color === "string") rgbArray = rgbStringToArray(color);

    const opaqueColor = [0, 0, 0];
    rgbArray.forEach((rgbComponent, index) => {
        opaqueColor[index] = Math.ceil(backgroundColor - opacity * (backgroundColor - rgbComponent));
    });

    return opaqueColor;
};

export const rgbStringToArray = (rgbString) => rgbString.match(/\d+/g).map(Number);
export const rgbArrayToString = (rgbArray) => `rgb(${rgbArray.join(", ")})`;

export const filterOutInactiveCategories = (chartOptions, inactiveIndices, includeYAxis = false) => {
    if (!inactiveIndices.length) return;

    const node = chartOptions?.globals?.dom?.Paper?.node;
    if (!node) return;

    const seriesClass = "apexcharts-series";
    const dataLabelsClass = "apexcharts-datalabels";
    const allBars = node.getElementsByClassName(seriesClass);
    const allDataLabels = node.getElementsByClassName(dataLabelsClass);
    const dataLabels = allDataLabels[allDataLabels.length - 1];

    const yAxisClass = "apexcharts-yaxis-texts-g";
    let allYAxis;
    let yAxisComplete;
    if (includeYAxis) {
        allYAxis = node.getElementsByClassName(yAxisClass);
        yAxisComplete = allYAxis[allYAxis.length - 1];
    }

    // Instead of using opaque colors, we could only set style.opacity, but that approach
    // results in grild lines being visible through bars, which doesn't look aesthetically pleasing.
    const opaqueColors = new Array(allBars.length);
    const opacity = 0.5;
    for (let i = 0; i < allBars.length; i += 1) {
        let barColor = "rgb(255, 255, 255)";
        for (const child of allBars[i].children) {
            const color = child.getAttribute("fill");
            if (color !== "none") {
                barColor = color;
                break;
            }
        }
        const opaqueColor = opacityAppliedToOpaqueColor(rgbStringToArray(barColor).slice(0, 3), opacity);
        opaqueColors[i] = rgbArrayToString(opaqueColor);
    }

    for (const index of inactiveIndices) {
        for (let barsIndex = 0; barsIndex < allBars.length; barsIndex += 1) {
            const bars = allBars[barsIndex];
            bars.children[index].style.fill = opaqueColors[barsIndex];
        }
        const text = dataLabels.children[index].firstChild;
        if (text) text.style.opacity = opacity;

        if (includeYAxis) {
            const yAxisText = yAxisComplete.children[index].firstChild;
            if (yAxisText) yAxisText.style.opacity = opacity;
        }
    }
};
