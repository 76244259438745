import React, { useMemo, useState, useEffect } from "react";
import { Stack, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SpeedDialMenu } from "../../../Charts/ChartContainer/SpeedDialMenu";
import HistogramLineChart from "../../../MixedCharts/HistogramLineChart";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";

const useStyles = makeStyles(() => ({
    grid: {
        "& .apexcharts-xaxis line": {
            transform: "scaleX(1.9) translateX(-11%)",
        },
        "& .apexcharts-customGrid .apexcharts-gridlines-horizontal line": {
            transform: "scaleX(1.9) translateX(-11%)",
        },
    },
}));

export function Section3({ data, stacks }) {
    const [zoomLevel, setZoomLevel] = useState(2);
    const [hovered, setHovered] = useState(false);
    const [barWidth, setBarWidth] = useState("80%");
    const [padding, setPadding] = useState({});

    const classes = useStyles();

    const series = useMemo(() => {
        if (zoomLevel === 1)
            return [
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperienceCurrent.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperienceCurrent.comparisonChart.stack2.series.data,
                    },
                ],
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperiencePrevious.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperiencePrevious.comparisonChart.stack2.series.data,
                    },
                ],
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperienceTotal.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperienceTotal.comparisonChart.stack2.series.data,
                    },
                ],
            ];
        else if (zoomLevel === 2) {
            return [
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperienceCurrentGrouped.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperienceCurrentGrouped.comparisonChart.stack2.series.data,
                    },
                ],
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperiencePreviousGrouped.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperiencePreviousGrouped.comparisonChart.stack2.series.data,
                    },
                ],
                [
                    {
                        name: stacks[0].stack.name,
                        data: data.workExperienceTotalGrouped.comparisonChart.stack1.series.data,
                    },
                    {
                        name: stacks[1].stack.name,
                        data: data.workExperienceTotalGrouped.comparisonChart.stack2.series.data,
                    },
                ],
            ];
        }
    }, [data, stacks, zoomLevel]);

    const colors = useMemo(() => {
        if (zoomLevel === 1) {
            return {
                barColors: ["#84afd7", "#afbff2", "#a3e0c1"],
                lineColors: ["#3b9168", "#465fb1"],
                markerColors: ["#6180ea", "#4cb781"],
            };
        } else if (zoomLevel === 2) {
            return { defaultColors: ["#afbff2", "#a3e0c1"] };
        }
    }, [zoomLevel]);

    const maxValue = useMemo(
        () =>
            Math.ceil(
                Math.max(
                    ...series[0][0].data,
                    ...series[0][1].data,
                    ...series[1][0].data,
                    ...series[1][1].data,
                    ...series[2][0].data,
                    ...series[2][1].data,
                ),
            ),
        [series, zoomLevel],
    );

    const categories = useMemo(() => {
        if (zoomLevel === 1) {
            return [
                data.workExperienceCurrent.comparisonChart.categories,
                data.workExperiencePrevious.comparisonChart.categories,
                data.workExperienceTotal.comparisonChart.categories,
            ];
        } else if (zoomLevel === 2) {
            return [
                data.workExperienceCurrentGrouped.comparisonChart.categories,
                data.workExperiencePreviousGrouped.comparisonChart.categories,
                data.workExperienceTotalGrouped.comparisonChart.categories,
            ];
        }
    }, [data, zoomLevel]);

    const handleZoomIn = () => {
        setZoomLevel(1);
    };
    const handleZoomOut = () => {
        setZoomLevel(2);
    };

    const handleChartEvents = (context, options) => {
        if (options) {
            const gridClass = "apexcharts-grid";
            const customGridClass = "apexcharts-customGrid";

            // Options differ slightly based on types of events.
            const svg = options?.globals?.dom?.Paper?.node || options?.w?.globals?.dom?.Paper?.node;
            if (!svg.getElementsByClassName(customGridClass)[0]) {
                // Workaround for a very annoying tooltip bug.
                // We have to transform grid lines(scale & translate) because adding left/right padding to
                // chartOptions.grid.padding makes grid lines shorter and centered.
                // Widening these lines will cause *parent g element*(apexcharts-grid) to widen as well,
                // but mouseover areas for *shared tooltip* depend on the width of this *g element*(if u change
                // its width, offsets of tooltips for data points will change as well, thus making it unusable).
                // One solution is to *only* widen gridlines of the *cloned g element* that can be inserted
                // right after the original *g element*.
                const grid = svg.getElementsByClassName(gridClass)[0];
                const grid2 = grid.cloneNode(true);
                grid2.id = "";
                grid2.classList.remove(gridClass);
                grid2.classList.add(customGridClass);
                grid.parentNode.insertBefore(grid2, grid.nextSibling);
            }
        }

        const viewportWidth = document.body.clientWidth;

        if (viewportWidth >= 1950 && barWidth !== "70%") setBarWidth("70%");
        else if (viewportWidth < 1950 && viewportWidth >= 1366 && barWidth !== "80%") setBarWidth("80%");
        else if (viewportWidth < 1366 && barWidth !== "90%") setBarWidth("90%");

        const xPadding = parseInt(viewportWidth / 14);
        if (padding?.left !== xPadding || padding?.right !== xPadding) {
            setPadding({ left: xPadding, right: xPadding });
        }
    };

    useEffect(() => {
        // Set chart options on first render. This is really only needed for development purposes.
        // (Hot realoading will reset the state, but won't trigget chart's "updated" event)
        handleChartEvents();
    }, [zoomLevel]);

    return (
        <Paper elevation={1} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <SpeedDialMenu
                open={hovered}
                closeMenu={() => setHovered(false)}
                actionsOffset={{ y: -30, x: 35 }}
                data={{ categories, series: [series[1], series[0], series[2]] }}
                tableViewProps={{
                    chartType: zoomLevel === 1 ? "HistogramLineChart" : "StandardVerticalBarChart-grouped",
                    chartTitle: "Average Work Experience in Companies",
                }}
                showTableView
                minLimit={1}
                maxLimit={2}
                loadedItems={zoomLevel}
                enableZoom
                zoomIn={handleZoomIn}
                zoomOut={handleZoomOut}
            />
            <Stack direction="row" width="100%" mt={1} mb={2}>
                {zoomLevel === 1 ? (
                    <>
                        <Stack width="100%">
                            <HistogramLineChart
                                variant="double"
                                categories={categories[0]}
                                series={series[0]}
                                title="Work Experience in Current Company"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                            />
                        </Stack>
                        <Stack width="100%">
                            <HistogramLineChart
                                variant="double"
                                categories={categories[1]}
                                series={series[1]}
                                title="Work Experience in Previous Companies"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                            />
                        </Stack>
                        <Stack width="100%">
                            <HistogramLineChart
                                variant="double"
                                categories={categories[2]}
                                series={series[2]}
                                title="Work Experience Total"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                            />
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[0]}
                                series={series[0]}
                                title="Work Experience in Current Company"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[1]}
                                series={series[1]}
                                title="Work Experience in Previous Companies"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[2]}
                                series={series[2]}
                                title="Work Experience Total"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                    </>
                )}
            </Stack>
        </Paper>
    );
}
