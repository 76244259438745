import React, { useState, useMemo, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    TableSortLabel,
    Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Row from "./Row";
import { COLUMN_TYPES } from "./columnTypes";
import { renderActions, renderTags } from "./CellRenderers";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 8,
    },
    table: {
        borderCollapse: (props) => props.tableBorderCollapse,
        backgroundColor: "inherit",
    },
    tableSpacing: {
        padding: theme.spacing(2),
        paddingLeft: ({ isSticky }) => (isSticky ? 0 : theme.spacing(2)),
        paddingTop: 0,
        backgroundColor: "inherit",
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    loader: {
        display: "flex",
        minHeight: 473,
        justifyContent: "center",
        alignItems: "center",
    },
    tableHead: {
        position: "sticky",
        top: 0,
        backgroundColor: "inherit",
        height: 64,
        zIndex: 2,
        "& .MuiTableCell-root": {
            border: (props) => props.cellBorder,
        },
        borderBottom: `1px solid ${theme.palette.dividerAndOutline[500]}`,
    },
    tableHeadRow: {
        height: 64,
    },
    selectedStack: {
        background: theme.palette.states.main,
        "&:last-child": {
            "& .MuiTableCell-root": {
                borderBottom: `1px solid ${theme.palette.dividerAndOutline[700]}`,
            },
        },
    },
    stickyTableBody: {
        position: "sticky",
        top: 64,
        zIndex: 1,
    },
    typographyPointer: {
        cursor: "pointer",
    },
}));

export default function TableComponent({
    id,
    columns,
    rows,
    className,
    style,
    selectedRows,
    sortBy,
    direction,
    onSortBy,
    onDragEnd,
    onSelected,
    actionsStyles,
    isSticky = false,
}) {
    const tableContainerRef = useRef(null);
    const [columnToSortBy, setColumnToSortBy] = useState(sortBy || null);
    const [sortDirection, setSortDirection] = useState(direction || null);
    const [isDragging, setIsDragging] = useState(false);
    const classes = useStyles({
        cellBorder: !isDragging ? "" : "none",
        tableBorderCollapse: !isDragging ? "separate" : "collapse",
        isSticky,
    });

    useEffect(() => {
        if (tableContainerRef.current) tableContainerRef.current.scrollTop = 0;
    }, [rows]);

    const filteredRows = useMemo(() => {
        if (!selectedRows || selectedRows?.length === 0) return rows;
        return rows.filter((row) => selectedRows.filter((item) => item.id === row.id).length === 0);
    }, [rows, selectedRows]);

    const sortByColumn = (columnName) => {
        let newDirection;
        if (columnToSortBy === columnName) {
            newDirection = sortDirection === "asc" ? "desc" : "asc";
        } else {
            newDirection = "asc";
        }
        setColumnToSortBy(columnName);
        setSortDirection(newDirection);
        if (onSortBy) {
            onSortBy(columnName, newDirection);
        }
    };

    const renderCell = (cell, columnIndex, rowIndex) => {
        if (cell === null || cell === undefined) return <TableCell key={columnIndex} align="right" />;
        const column = columns[columnIndex];
        if (column.renderer) {
            return column.renderer(cell, columnIndex, rowIndex);
        }

        const type = column.type;
        switch (type) {
            case COLUMN_TYPES.DEFAULT:
            case COLUMN_TYPES.ICON:
            case undefined:
                return (
                    <TableCell
                        key={columnIndex}
                        align={column.align || "right"}
                        sx={
                            isSticky && columnIndex === 0
                                ? {
                                      position: "sticky",
                                      left: 0,
                                      background: "#fff",
                                      zIndex: 1,
                                  }
                                : undefined
                        }
                    >
                        {cell}
                    </TableCell>
                );
            case COLUMN_TYPES.AVATAR:
                return (
                    <TableCell
                        key={columnIndex}
                        align={column.align || "right"}
                        sx={
                            isSticky && columnIndex === 0
                                ? {
                                      position: "sticky",
                                      left: 0,
                                      background: "#fff",
                                      zIndex: 1,
                                  }
                                : undefined
                        }
                    >
                        <Typography
                            variant="body2"
                            color="secondary"
                            className={classes.typographyPointer}
                            onClick={() =>
                                window.open(
                                    `https://${cell.website.replace("www.", "").replace("https://", "")}`,
                                    "_blank",
                                )
                            }
                        >
                            {cell.text}
                        </Typography>
                    </TableCell>
                );
            case COLUMN_TYPES.TOOLTIP:
                return (
                    <Tooltip title={cell.tooltip}>
                        <TableCell key={columnIndex} align={!columnIndex ? "inherit" : "right"}>
                            {cell.content}
                        </TableCell>
                    </Tooltip>
                );
            case COLUMN_TYPES.ACTION:
                return renderActions(cell, columnIndex);
            case COLUMN_TYPES.TAGS:
                return renderTags(cell, columnIndex);
            // no default
        }
        return null;
    };

    const handleDragEnd = (...rest) => {
        setIsDragging(false);
        if (onDragEnd) onDragEnd(...rest);
    };

    return (
        <Paper className={`${className} ${classes.root}`} elevation={1} style={{ ...style }}>
            <TableContainer className={classes.tableSpacing} ref={tableContainerRef}>
                <DragDropContext onBeforeDragStart={() => setIsDragging(true)} onDragEnd={handleDragEnd}>
                    <Table className={classes.table}>
                        <Droppable
                            droppableId={id || "droppable"}
                            direction="horizontal"
                            renderClone={(provided, snapshot, rubric) => (
                                <TableCell
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    align={columns[rubric.source.index].align || "right"}
                                    sx={{ border: "none" }}
                                >
                                    <Typography variant="body3" color="primary" noWrap>
                                        {columns[rubric.source.index].name}
                                    </Typography>
                                </TableCell>
                            )}
                        >
                            {(provider) => (
                                <TableHead className={classes.tableHead}>
                                    <TableRow
                                        className={classes.tableHeadRow}
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                    >
                                        {columns.map((column, index) => (
                                            <Draggable
                                                key={column.name}
                                                draggableId={column.name || `drag-${index}`}
                                                index={index}
                                                isDragDisabled={!column.draggable}
                                            >
                                                {(draggableProvider) =>
                                                    column.headerRenderer ? (
                                                        column.headerRenderer(
                                                            column,
                                                            index,
                                                            columnToSortBy,
                                                            sortDirection,
                                                            sortByColumn,
                                                            draggableProvider.innerRef,
                                                            {
                                                                ...draggableProvider.draggableProps,
                                                                ...draggableProvider.dragHandleProps,
                                                            },
                                                        )
                                                    ) : (
                                                        <TableCell
                                                            {...draggableProvider.draggableProps}
                                                            ref={draggableProvider.innerRef}
                                                            {...draggableProvider.dragHandleProps}
                                                            align={column.align || "right"}
                                                            sx={
                                                                isSticky && index === 0
                                                                    ? {
                                                                          position: "sticky",
                                                                          left: 0,
                                                                          background: "#fff",
                                                                          zIndex: 1,
                                                                      }
                                                                    : undefined
                                                            }
                                                        >
                                                            {column.sortKey ? (
                                                                <TableSortLabel
                                                                    active={columnToSortBy === column.sortKey}
                                                                    direction={
                                                                        columnToSortBy === column.sortKey
                                                                            ? sortDirection
                                                                            : "asc"
                                                                    }
                                                                    onClick={() => sortByColumn(column.sortKey)}
                                                                >
                                                                    <Typography variant="body3" color="primary" noWrap>
                                                                        {column.name}
                                                                    </Typography>
                                                                </TableSortLabel>
                                                            ) : (
                                                                <Typography variant="body3" color="primary" noWrap>
                                                                    {column.name}
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                    )
                                                }
                                            </Draggable>
                                        ))}
                                        {provider.placeholder}
                                    </TableRow>
                                </TableHead>
                            )}
                        </Droppable>
                        {selectedRows && selectedRows.length ? (
                            <TableBody className={classes.stickyTableBody}>
                                {selectedRows.map((row, index) => (
                                    <TableRow
                                        key={`selected-${index}`}
                                        className={classes.selectedStack}
                                        onClick={onSelected ? () => onSelected(row, index, true) : undefined}
                                    >
                                        {row.data.map((cell, idx) => renderCell(cell, idx))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : null}
                        <TableBody sx={{ pointerEvents: isDragging ? "none" : "auto" }}>
                            {filteredRows.map((row, index) => (
                                <Row
                                    actions={row.actions}
                                    key={index}
                                    onClick={onSelected ? () => onSelected(row, index, false) : undefined}
                                    actionsStyles={actionsStyles}
                                    isSticky={isSticky}
                                    sx={
                                        isSticky && index === 0
                                            ? {
                                                  position: "sticky",
                                                  left: 0,
                                                  background: "#fff",
                                                  zIndex: 1,
                                              }
                                            : undefined
                                    }
                                >
                                    {row.data.map((cell, idx) => renderCell(cell, idx))}
                                </Row>
                            ))}
                        </TableBody>
                    </Table>
                </DragDropContext>
            </TableContainer>
        </Paper>
    );
}
