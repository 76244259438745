import React, { useState, useEffect, useMemo } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Stack, Divider, Paper, Typography } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Table from "../../Table/Table";
import Filter from "./components/Filter";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import PaginationComponent from "../../Pagination";
import { adminSliceSelectors, setDataRequestsFilter, setActiveStack } from "../../../slices/Admin/adminSlice";
import { getDataRequests } from "../../../actions/Admin/DataRequests";
import OsterusLoader from "../../Loader";

const STATUSES = {
    backlog: "Backlog",
    selected_for_scraping: "Selected for Scraping",
    scraping_in_progress: "Scraping in Progress",
    ready_for_import: "Ready for Import",
    import_in_progress: "Import in Progress",
    ready_for_sync: "Ready for Sync",
    ready_for_stacks: "Ready for Stacks",
    done: "Done",
    on_hold: "On Hold",
    canceled: "Canceled",
};

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Data Requests",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Data Requests",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function DataRequestsComponent() {
    const filter = useSelector(adminSliceSelectors.dataRequestsFilter);
    const totalRecords = useSelector(adminSliceSelectors.dataRequestsTotalRecords);
    const loading = useSelector(adminSliceSelectors.dataRequestsLoading);
    const data = useSelector(adminSliceSelectors.dataRequestsData);
    const organization_id = useSelector(adminSliceSelectors.stacksActiveStack).organization.id;
    const [dataSlice, setDataSlice] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        setDataSlice(data.slice(0, Math.min(filter.limit, data.length)));
    }, [data]);

    const handlePageChanged = (pageNum, start, end) => {
        dispatch(setDataRequestsFilter({ ...filter, start: pageNum }));
        dispatch(getDataRequests({ organization_id, start: pageNum }));
        setDataSlice(data.slice(start, end));
    };

    const onCreateStack = (index) => {
        const dataRequest = dataSlice[index];
        dispatch(
            setActiveStack({
                company: { id: dataRequest.company_id, name: dataRequest.company_name },
                jira_ticket: dataRequest.jira_ticket,
            }),
        );
        navigate("/admin/stacks/create");
    };

    const columns = useMemo(
        () => [
            { name: "Company Name", align: "left" },
            { name: "Company URL" },
            { name: "Jira Link" },
            { name: "Status" },
            { name: "Organization" },
        ],
        [],
    );

    const rows = useMemo(
        () =>
            dataSlice.map((item, index) => {
                const status = item.status && STATUSES[item.status];
                const actions =
                    item.status && item.status === "ready_for_stacks"
                        ? [{ icon: <SaveAsIcon />, tooltip: "Create Stack", callback: () => onCreateStack(index) }]
                        : null;
                return {
                    data: [item.company_name, item.company_url, item.jira_link, status, item.organization.full_name],
                    actions,
                };
            }),
        [dataSlice],
    );

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Stack direction="column" px={3} pt={2}>
                    <Stack direction="column" mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                    {loading ? (
                        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                            <OsterusLoader />
                        </Stack>
                    ) : (
                        <>
                            {totalRecords !== null && totalRecords > 0 ? (
                                <>
                                    <Table columns={columns} rows={rows} />
                                    <PaginationComponent
                                        page={filter.start}
                                        totalRecords={totalRecords}
                                        limit={filter.limit}
                                        onChange={handlePageChanged}
                                    />
                                </>
                            ) : totalRecords === 0 ? (
                                <Table columns={columns} rows={rows} />
                            ) : (
                                <Typography variant="h6" color="primary">
                                    Please select an organization
                                </Typography>
                            )}
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}
