import React from "react";
import Chart from "react-apexcharts";
import { Stack, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "@gatsbyjs/reach-router";

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".tooltip": {
            width: 295,
            background: "white",
            color: "#2C2C2C",
        },
        ".unknownTooltip": {
            width: 295,
            background: "white",
            color: "#2C2C2C",
        },
        ".tooltipTitle": {
            display: "flex",
            height: 35,
            alignItems: "center",
            background: "#ECEEF0",
            padding: 10,
            borderBottom: `1px solid ${theme.palette.divider}`,
            fontFamily: "Helvetica, Arial, sans-serif !important",
            fontSize: 12,
        },
        ".container": {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 8,
            fontFamily: "Helvetica, Arial, sans-serif !important",
            fontSize: 12,
        },
        ".tooltipCircle": {
            width: 12,
            height: 12,
            borderRadius: "50%",
        },
        ".valueBox": {
            flex: 1,
            display: "flex",
            alignItems: "center",
        },
        ".valueTitle": {
            margin: 0,
            marginLeft: 12,
            maxWidth: 175,
            lineHeight: 1,
            whiteSpace: "normal",
        },
        ".value": {
            paddingTop: 1,
            marginLeft: 6,
            fontWeight: 600,
            whiteSpace: "nowrap",
        },
        ".unknownValue": {
            paddingTop: 1,
            fontWeight: 600,
            whiteSpace: "nowrap",
            marginLeft: 4,
        },
        ".noData": {
            marginLeft: 24,
            marginTop: -2,
            fontWeight: 400,
            color: theme.palette.text.secondary,
        },
        ".benchmark": {
            marginTop: 4,
        },
        ".benchmarkValue": {
            marginLeft: 4,
            fontWeight: 600,
        },
        ".benchmarkValueBox": {
            display: "flex",
            alignItems: "center",
            borderStyle: "dotted",
            border: 0,
            borderLeft: "2.25px  solid red",
            borderRadius: 2,
            borderSpacing: 10,
            height: 20,
            marginLeft: 5,
        },
        ".benchmarkValueContainer": {
            marginLeft: 17,
        },
    },
    barChartWithMarkers: {
        display: "inline-block",
        marginTop: 12,
        height: "100%",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
}));

export default function BarChartWithMarkers({
    series,
    categories,
    additionalCategories = categories,
    reversed = false,
    padding,
    title,
    colors,
    height,
    stackname,
    max,
    maxMultiplier = 1.3,
    inPercentage = false,
}) {
    const classes = useStyles();
    const location = useLocation();
    const maxValue =
        max !== undefined
            ? max
            : series[0].data.reduce((prev, cur) => {
                  if (cur.y !== undefined) return Math.max(prev, cur.y);
                  return Math.max(prev, cur);
              }, 0);

    const options = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        // Technically not needed, but we need something to change when title changes.
        // Otherwise apexcharts won't pick up the new custom title.
        noData: {
            text: title,
        },
        plotOptions: {
            bar: {
                barHeight: "62%",
                borderRadius: 2,
                horizontal: true,
                distributed: colors.length > 1,
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
            },
        },
        fill: {
            opacity: 1,
        },
        colors,
        dataLabels: {
            enabled: true,
            textAnchor: reversed ? "end" : "start",
            style: {
                colors: ["#7B7B7B"],
            },
            formatter: (val) =>
                val === 0
                    ? ""
                    : reversed
                    ? inPercentage
                        ? `${val}%\u2000`
                        : `${val}\u2000`
                    : inPercentage
                    ? `\u2000${val}%`
                    : `\u2000${val}`,
        },
        legend: {
            show: false,
        },
        grid: {
            show: false,
            padding: padding ?? {},
        },
        xaxis: {
            categories,
            max: maxValue * maxMultiplier,
            show: false,
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            reversed,
            show: false,
        },
        tooltip: {
            intersect: false,
            // eslint-disable-next-line
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                const color = colors.length === 1 ? colors[0] : colors[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];
                const isYears =
                    categories[dataPointIndex] === "Unknown" &&
                    !location.pathname.includes("/the-lab/stacks/stack-insights/education_comparison_insights") &&
                    !location.pathname.includes("/the-lab/stacks/stack-insights/education_single_insights")
                        ? " years"
                        : "%";
                if (
                    categories[dataPointIndex] !== "Unknown" &&
                    !location.pathname.includes("/the-lab/stacks/stack-insights/education_comparison_insights") &&
                    !location.pathname.includes("/the-lab/stacks/stack-insights/education_single_insights")
                ) {
                    return `<div class=tooltip>
                                <div class=tooltipTitle>${title}</div>
                                <div class=container>
                                    <div class=valueBox>
                                        <div class=tooltipCircle style="background: ${color}"></div>
                                        <span class=valueTitle>${additionalCategories[dataPointIndex]}</span>
                                        <span class=value>${value ? `${value} years` : ""}</span>
                                    </div>
                                    ${
                                        !value
                                            ? "<div class=noData><span>No results match filter criteria</span></div>"
                                            : ""
                                    }
                                    ${
                                        w.config.series[0].data[dataPointIndex].goals
                                            ? `<div class=${
                                                  additionalCategories[dataPointIndex].length > 23
                                                      ? "benchmark"
                                                      : "newLineBenchmark"
                                              }>
                                        <div class=benchmarkValueBox>
                                            <span class=benchmarkValueContainer>Benchmark </span>
                                            <span class=benchmarkValue> ${
                                                w.config.series[0].data[dataPointIndex]?.goals[0]?.value
                                            } years </span>
                                        </div>
                                    </div>`
                                            : ""
                                    }
                                    
                                </div>
                            </div>`;
                }
                return `<div class=unknownTooltip>
                            <div class=tooltipTitle>${title}</div>
                            <div class=container>
                                <div class=valueBox>
                                    <div class=tooltipCircle style="background: ${color}"></div>
                                    <span class=valueTitle>${categories[dataPointIndex]}</span>
                                    <span class=unknownValue>${value || ""}${value ? isYears : ""}</span>
                                </div>
                                ${!value ? "<div class=noData><span>No results match filter criteria</span></div>" : ""}
                            </div>
                        </div>`;
            },
        },
    };

    const noBars = series[0].data.every((item) => item === 0 || item.y === 0 || (!item.y && typeof item !== "number"));

    return (
        <Stack height="100%">
            {stackname && (
                <Tooltip title={stackname}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="textSecondary"
                        align={reversed ? "right" : "left"}
                        ml={reversed || 1.2}
                        mr={!reversed || 1.2}
                    >
                        {stackname}
                    </Typography>
                </Tooltip>
            )}
            {noBars ? (
                <Stack
                    width="50%"
                    height="60%"
                    mr={reversed ? 1.25 : "auto"}
                    ml={reversed ? "auto" : 1.25}
                    justifyContent="center"
                    textAlign={reversed ? "right" : "left"}
                >
                    <Typography variant="body5" color="textSecondary">
                        No results match filter criteria
                    </Typography>
                </Stack>
            ) : (
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height={height}
                    width="100%"
                    colors={colors}
                    stackname={stackname}
                    categories={categories}
                />
            )}
        </Stack>
    );
}
