import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    saveOrganizationApi,
    saveAdminOrganizationApi,
    updateOrganizationApi,
} from "../../services/api/organization/organization";
import { getMe } from "../../services/api/Public/Login/loginActions";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";

export const saveOrganization = createAsyncThunk(
    "organizations/saveOrganization",
    async ({ organizationData, onDone }, { dispatch, rejectWithValue }) => {
        try {
            const { data } = await saveOrganizationApi(organizationData);
            if (onDone) {
                onDone();
                dispatch(getMe());
            }
            return { data };
        } catch (err) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    },
);

export const saveAdminOrganization = createAsyncThunk(
    "organizations/saveOrganization",
    async ({ organizationData, onDone }, { dispatch, rejectWithValue }) => {
        try {
            const { data } = await saveAdminOrganizationApi(organizationData);
            if (onDone) {
                onDone();
                dispatch(getMe());
            }
            dispatch(
                addMessage({
                    type: "success",
                    title: "Organization created.",
                    message: "Organization successfully created.",
                    visibilityTime: 5000,
                }),
            );
            return { data };
        } catch (err) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong :(",
                    visibilityTime: 5000,
                }),
            );
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    },
);

export const updateOrganization = createAsyncThunk(
    "organizations/updateOrganization",
    async ({ organizationId, organization }) => {
        const { data } = await updateOrganizationApi(organizationId, organization);
        return { data };
    },
);
