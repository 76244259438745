import React, { useEffect } from "react";
import { Router, Redirect } from "@gatsbyjs/reach-router";
import { useSelector } from "react-redux";
import { DefaultLayout } from "../layouts/Default";
import { PublicLayout } from "../layouts/Public";
import { LoginPage } from "../pages/Public/Login";
import WelcomePage from "../pages/Welcome";
import { DashboardPage } from "../pages/Dashboard";
import AdminPage from "../pages/Admin";
import AdminStacksPage from "../pages/Admin/Stacks";
import SaveStack from "../components/Admin/Stacks/SaveStack";
import AdminDataRequestsPage from "../pages/Admin/DataRequests";
import AdminOrganizationsPage from "../pages/Admin/Organizations";
import AdminUsersPage from "../pages/Admin/Users";
import AdminReferralsPage from "../pages/Admin/Referrals";
import WorkforcePage from "../pages/Workforce";
import HiringPage from "../pages/Hiring";
import { Stacks } from "../pages/TheLab/Stacks";
import UniversitiesPage from "../pages/Intelligence/Universities";
import CompaniesPage from "../pages/Intelligence/Companies";
import { CostOfLivingPage } from "../pages/Intelligence/CostOfLiving";
import QualityOfLifePage from "../pages/Intelligence/QualityOfLife";
import AccountSettingsPage from "../pages/Account/Settings";
import AccountRegisterPage from "../pages/Account/Register";
import AccountPersonalInfoPage from "../pages/Account/PersonalInfo";
import OrganizationCreatePage from "../pages/Organization/Create";
import OrganizationSettingsPage from "../pages/Settings/Organization";
import NotFound from "../pages/404";
import FAQPage from "../pages/FAQ";
import UsersPage from "../pages/Settings/Users";
import { userSliceSelectors, USER_TYPES } from "../slices/User/userSlice";
import Farewell from "../pages/Public/Farewell";
import { StackInsights } from "../pages/TheLab/Stacks/StackInsights";
import ForgotPassword from "../components/Account/PasswordReset/ForgotPassword";
import CodeVerification from "../components/Account/PasswordReset/CodeVerification";
import NewPassword from "../components/Account/PasswordReset/NewPassword";
import Invited from "../components/Account/Invited/Invited";
import { RequestedCompanies } from "../pages/TheLab/RequestedCompanies";
import LoadingPage from "../pages/Loading";
import SharedView from "../pages/Shared";

const DEFAULT_TITLE = "Osterus";

const changeDocumentTitle = (title) => {
    document.title = !title ? DEFAULT_TITLE : `${title} - ${DEFAULT_TITLE}`;
};

function AuthorizedAdminRoute(props) {
    const { title } = props;

    useEffect(() => {
        changeDocumentTitle(title);
    }, []);

    const userToken = useSelector(userSliceSelectors.token);
    const userType = useSelector(userSliceSelectors.type);

    if (userToken === null) return <Redirect noThrow to="/login" />;
    // Return loading page until user object gets fetched.
    if (userType === null) return <DefaultLayout component={LoadingPage} />;
    if (userType !== USER_TYPES.OSTERUS_ADMIN) return <AuthorizedRoute title="Page not found" component={NotFound} />;
    return <DefaultLayout {...props} />;
}

function AuthorizedRoute(props) {
    const { title, component, path } = props;

    useEffect(() => {
        changeDocumentTitle(title);
    }, []);

    const userToken = useSelector(userSliceSelectors.token);
    const hasTrialOrSubscriptionEnded = useSelector(userSliceSelectors.hasTrialOrSubscriptionEnded);
    const step = useSelector(userSliceSelectors.step);
    const userType = useSelector(userSliceSelectors.type);

    const modifiedProps = {
        ...props,
        component: !hasTrialOrSubscriptionEnded ? component : WelcomePage,
    };
    if (userToken === null) {
        return <Redirect noThrow to="/login" />;
    }
    if (userType === null) return <DefaultLayout component={LoadingPage} />;
    else if (step === 2 && path !== "/account/personal-information") {
        return <Redirect noThrow to="/account/personal-information" />;
    } else if (step === 3 && userType === USER_TYPES.CLIENT_ADMIN && path !== "/organization/create") {
        return <Redirect noThrow to="/organization/create" />;
    }

    return <DefaultLayout {...modifiedProps} />;
}

function UnauthorizedRoute(props) {
    const { title, shareable } = props;
    const userToken = useSelector(userSliceSelectors.token);
    const { status } = useSelector(userSliceSelectors.defaultUserFields);

    useEffect(() => {
        changeDocumentTitle(title);
    }, []);

    if (userToken === null || status !== "active" || shareable) {
        return <PublicLayout {...props} />;
    }
    return <Redirect noThrow to="/" />;
}

function Routes() {
    const userToken = useSelector(userSliceSelectors.token);
    return (
        <Router>
            {userToken ? (
                <AuthorizedRoute title="Page not found" component={NotFound} default />
            ) : (
                <UnauthorizedRoute title="Page not found" component={NotFound} default />
            )}
            <UnauthorizedRoute title="Login" path="/login" component={LoginPage} />
            <AuthorizedRoute title="User Management" path="/settings/users" component={UsersPage} />
            <UnauthorizedRoute title="Register" path="/account/register/:inviteCode" component={AccountRegisterPage} />
            <UnauthorizedRoute title="Farewell" path="/farewell" component={Farewell} />
            <UnauthorizedRoute title="Password Reset" path="/account/send-password-reset" component={ForgotPassword} />
            <UnauthorizedRoute
                title="Password Reset Email Verification"
                path="/account/password-reset-code-verification"
                component={CodeVerification}
            />
            <UnauthorizedRoute
                title="Create New Password"
                path="/account/create-new-password"
                component={NewPassword}
            />
            <UnauthorizedRoute title="Invited" path="/account/invited" component={Invited} />

            <UnauthorizedRoute shareable path="/shared/:view" component={SharedView} />

            <AuthorizedRoute
                title="Personal Information"
                path="/account/personal-information"
                hideNavigation
                component={AccountPersonalInfoPage}
            />
            <AuthorizedRoute
                title="Create Organization"
                path="/organization/create"
                hideNavigation
                component={OrganizationCreatePage}
            />
            <AuthorizedRoute
                title="Organization Settings"
                path="/organization/settings"
                component={OrganizationSettingsPage}
            />
            <AuthorizedRoute title="Welcome" path="/" component={WelcomePage} />
            <AuthorizedRoute title="Dashboard" path="/dashboard" component={DashboardPage} />

            <AuthorizedAdminRoute title="Admin" path="/admin" component={AdminPage} />
            <AuthorizedAdminRoute title="Admin Stacks" path="/admin/stacks" component={AdminStacksPage} />
            <AuthorizedAdminRoute title="Admin Save Stack" path="/admin/stacks/:mode" component={SaveStack} />
            <AuthorizedAdminRoute
                title="Admin Data Requests"
                path="/admin/data-requests"
                component={AdminDataRequestsPage}
            />
            <AuthorizedAdminRoute
                title="Admin Organizations"
                path="/admin/organizations"
                component={AdminOrganizationsPage}
            />
            <AuthorizedAdminRoute title="Admin Users" path="/admin/users" component={AdminUsersPage} />
            <AuthorizedAdminRoute title="Admin Referrals" path="/admin/referrals" component={AdminReferralsPage} />

            <AuthorizedRoute title="Workfoce" path="/workforce" component={WorkforcePage} />
            <AuthorizedRoute title="Hiring" path="/hiring" component={HiringPage} />
            <AuthorizedRoute title="Stack Insights" path="/the-lab/stacks" component={Stacks} />
            <AuthorizedRoute
                title="Stack Insights"
                path="/the-lab/stacks/stack-insights/:view/:firstStack/:secondStack"
                component={StackInsights}
            />
            <AuthorizedRoute
                title="Stack Insights"
                path="/the-lab/stacks/stack-insights/:view/:stackId"
                component={StackInsights}
            />
            <AuthorizedRoute title="Universities" path="/intelligence/universities" component={UniversitiesPage} />
            <AuthorizedRoute title="Companies" path="/intelligence/companies" component={CompaniesPage} />
            <AuthorizedRoute title="Cost Of Living" path="/intelligence/cost-of-living" component={CostOfLivingPage} />
            <AuthorizedRoute
                title="Quality Of Life"
                path="/intelligence/quality-of-life"
                component={QualityOfLifePage}
            />
            <AuthorizedRoute title="Account Settings" path="/account/settings" component={AccountSettingsPage} />
            <AuthorizedRoute title="Companies" path="/the-lab/requested-companies" component={RequestedCompanies} />
            <AuthorizedRoute title="FAQ" path="/help/faq" component={FAQPage} />
        </Router>
    );
}

export default Routes;
