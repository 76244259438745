import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { getPlansApi } from "../../../services/api/Admin/organizations";

export default function PricingAutocomplete({ defaultValue, onSelected, color = "primary", disabled = false }) {
    const [pricing, setPricing] = useState([]);
    const isPrimary = color === "primary";

    useEffect(async () => {
        const { data } = await getPlansApi();
        let allPrices = [];
        data.forEach((item) => {
            allPrices = allPrices.concat(item.prices);
        });
        allPrices.sort((a, b) => {
            if (a.price > b.price) {
                return 1;
            }
            if (a.price < b.price) {
                return -1;
            }
            return 0;
        });
        setPricing(allPrices);
    }, []);

    const handleOnInputChange = async (e) => {
        if (!e) return;
        const value = e.target.value;
        setPricing((currentPricing) => currentPricing.filter((item) => item.price !== value));
    };

    const handleOnChange = (e, pricingValue) => {
        if (!pricingValue) {
            onSelected(null);
        }
        const convertedValue = parseInt(pricingValue?.substring(1));
        const price = pricing.filter((item) => item.price === convertedValue);
        if (onSelected) {
            onSelected(price);
        }
    };

    return (
        <Autocomplete
            options={pricing.map(({ price }) => `€${price}`)}
            fullWidth
            freeSolo
            forcePopupIcon
            value={defaultValue || ""}
            variant="outlined"
            size="small"
            disabled={disabled}
            color={color}
            renderInput={(params) => (
                <TextField
                    color={isPrimary ? "secondary" : "primary"}
                    {...params}
                    label="Current Pricing"
                    margin="dense"
                />
            )}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={({ children }) => (
                <Paper variant={isPrimary ? "autocompleteDropdown" : "elevation"} elevation={isPrimary ? 1 : 0}>
                    {children}
                </Paper>
            )}
        />
    );
}
