import { createSlice } from "@reduxjs/toolkit";
import { getTagsByFilter } from "../../actions/tags/tags";

const SLICE_NAME = "tags";

const initialState = {
    tags: null,
    loading: false,
};

export const tagsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearState(state) {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getTagsByFilter.pending]: (state) => {
            state.loading = true;
        },
        [getTagsByFilter.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.tags = payload;
        },
    },
});

export const { clearState } = tagsSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const tagsSliceSelectors = {
    tags: (rootState) => {
        const appState = getAppState(rootState);
        return appState.tags;
    },
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
};
