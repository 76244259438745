import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar, Stack, AppBar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../../osterus-wht.svg";
import {
    EMPLOYMENT_COMPARISON_INSIGHT,
    EMPLOYMENT_SINGLE_INSIGHT,
    EDUCATION_COMPARISON_INSIGHT,
    EDUCATION_SINGLE_INSIGHT,
    OVERVIEW_COMPARISON_INSIGHT,
    OVERVIEW_SINGLE_INSIGHT,
    DEMOGRAPHICS_COMPARISON_INSIGHT,
    DEMOGRAPHICS_SINGLE_INSIGHT,
} from "../../../components/TheLab/Stacks/StackInsights/Views/viewConstants";
import { JobsAndExperienceView } from "../../../components/TheLab/Stacks/StackInsights/Views/Comparison/JobsAndExperienceView";
import { EducationView } from "../../../components/TheLab/Stacks/StackInsights/Views/Comparison/EducationView";
import { DemographicsView } from "../../../components/TheLab/Stacks/StackInsights/Views/Comparison/DemographicsView";
import { SingleJobsAndExperienceView } from "../../../components/TheLab/Stacks/StackInsights/Views/Single/JobsAndExperienceView";
import { SingleEducationView } from "../../../components/TheLab/Stacks/StackInsights/Views/Single/EducationView";
import { SingleDemographicsView } from "../../../components/TheLab/Stacks/StackInsights/Views/Single/DemographicsView";
import { Overview } from "../../../components/TheLab/Stacks/StackInsights/Views/Comparison/Overview";
import { Overview as OverviewSingle } from "../../../components/TheLab/Stacks/StackInsights/Views/Single/Overview";
import { SharedTabHeader } from "../../../components/TheLab/Stacks/StackInsights/Headers/SharedTabHeader";
import { getSharedView } from "../../../services/api/Shared";
import { stackSliceSelectors, setSelectedStacks } from "../../../slices/Stacks/stackSlice";
import OsterusLoader from "../../../components/Loader";
import { setShowAndSplit, ShowModes, SplitModes } from "../../../slices/Stacks/StackInsights/stackInsightsSlice";

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: "none",
    },
    logoHover: {
        cursor: "pointer",
        width: 125,
    },
    content: {
        background: theme.palette.background.default,
        width: "100%",
        padding: "8px 16px",
    },
    demoButton: {
        textTransform: "none",
    },
    loader: {
        minHeight: 473,
        margin: "auto",
    },
}));

export default function SharedStackInsights({ view }) {
    const [insightsData, setInsightsData] = useState(null);
    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const [isLoading, setIsLoading] = useState(true);
    const demoPage = "https://osterus.com/";
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(async () => {
        if (insightsData !== null) return;
        setIsLoading(true);
        const code = new URLSearchParams(window.location.search).get("code");
        const ids = selectedStacks ? selectedStacks.map((stack) => stack.id) : undefined;
        const { data } = await getSharedView(code, view, ids, 2);
        dispatch(setShowAndSplit({ show: ShowModes.percent, split: SplitModes.none }));
        setInsightsData(data);

        if (!selectedStacks.length) {
            dispatch(setSelectedStacks(data.stacks));
        }
        setIsLoading(false);
    }, [selectedStacks, view]);

    const reset = () => {
        setInsightsData(null);
        setIsLoading(true);
    };

    const determineView = () => {
        if (!selectedStacks.length) return;

        switch (view) {
            case EMPLOYMENT_COMPARISON_INSIGHT:
                return (
                    <JobsAndExperienceView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case DEMOGRAPHICS_COMPARISON_INSIGHT:
                return (
                    <DemographicsView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case EMPLOYMENT_SINGLE_INSIGHT:
                return (
                    <SingleJobsAndExperienceView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case EDUCATION_COMPARISON_INSIGHT:
                return (
                    <EducationView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case EDUCATION_SINGLE_INSIGHT:
                return (
                    <SingleEducationView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case OVERVIEW_COMPARISON_INSIGHT:
                return (
                    <Overview
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case DEMOGRAPHICS_SINGLE_INSIGHT:
                return (
                    <SingleDemographicsView
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            case OVERVIEW_SINGLE_INSIGHT:
                return (
                    <OverviewSingle
                        insightsData={insightsData}
                        isLoading={isLoading}
                        stacks={selectedStacks}
                        HeaderComponent={SharedTabHeader}
                        headerProps={{ onViewSwitch: reset }}
                    />
                );
            // no default
        }
    };

    return (
        <>
            <AppBar className={classes.appBar} variant="regular" position="relative">
                <Toolbar>
                    <Stack mr={2} alignItems="center">
                        <img width={150} className={classes.logoHover} src={logo} alt="logo" />
                    </Stack>
                    <Stack flex={1} alignItems="flex-end">
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            onClick={() => window.open(demoPage, "_blank")}
                            className={classes.demoButton}
                        >
                            Schedule a Demo
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
            {isLoading ? (
                <Stack alignItems="center" justifyContent="center" className={classes.loader}>
                    <OsterusLoader />
                </Stack>
            ) : (
                <div className={classes.content}>{determineView()}</div>
            )}
        </>
    );
}
