import { createSlice } from "@reduxjs/toolkit";
import { getStackInsightsData } from "../../../actions/theLab/stacks/stackInsights";
import {
    EMPLOYMENT_VIEW,
    OVERVIEW_COMPARISON_INSIGHT,
} from "../../../components/TheLab/Stacks/StackInsights/Views/viewConstants";

export const ShowModes = {
    percent: "percent",
    durationYears: "durationYears",
};

export const ShowOptions = {
    percent: "Percentage of Profiles",
    durationYears: "Average Work Experience",
};

export const SplitModes = {
    none: "none",
    gender: "gender",
    degree: "degree",
    group: "group",
};

export const SplitOptions = {
    none: "None",
    gender: "Gender",
    degree: "Degree",
    group: "Work Experience Group",
};

const SLICE_NAME = "stackInsights";

const initialState = {
    insightsData: null,
    loadingInsights: true,
    activeView: EMPLOYMENT_VIEW,
    isExporting: false,
    show: undefined,
    split: undefined,
    nonFilteredInsightsData: null,
    appliedFilters: {
        jobTitles: [],
        universities: [],
        languages: [],
        cities: [],
    },
    allFilters: {
        jobTitles: [],
        universities: [],
        languages: [],
        cities: [],
    },
};

export const stackInsightsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setActiveView(state, { payload }) {
            state.activeView = payload;
        },
        setIsExporting(state, { payload }) {
            state.isExporting = payload;
        },
        clearState(state) {
            Object.assign(state, initialState);
        },
        setLoadingInsights(state, { payload }) {
            state.loadingInsights = payload;
        },
        setShowAndSplit(state, { payload }) {
            state.show = payload.show;
            state.split = payload.split;
        },
        setNonFilteredInsightsData(state, { payload }) {
            state.nonFilteredInsightsData = payload;
        },
        setAppliedFilters(state, { payload }) {
            state.appliedFilters = payload;
        },
        clearAppliedFilters(state) {
            state.appliedFilters = initialState.appliedFilters;
        },
        setAllFilters(state, { payload }) {
            state.allFilters = payload;
        },
        clearAllFilters(state) {
            state.allFilters = initialState.allFilters;
        },
    },
    extraReducers: {
        [getStackInsightsData.pending]: (state) => {
            state.loadingInsights = true;
        },
        [getStackInsightsData.fulfilled]: (state, { payload }) => {
            state.insightsData = payload.data;
            state.loadingInsights = false;
            state.split = payload.split;
            state.show = payload.show;

            const view = payload.view;
            const version = payload.v;
            if (version >= 2 && view !== OVERVIEW_COMPARISON_INSIGHT) {
                const totalAllFilters = Object.values(state.allFilters).reduce(
                    (curSum, val) => curSum + (val.length || 0),
                    0,
                );
                if (totalAllFilters === 0) {
                    state.allFilters.jobTitles = payload?.data?.filters?.jobTitles || [];
                    state.allFilters.universities = payload?.data?.filters?.schools || [];
                    state.allFilters.languages = payload?.data?.filters?.languages || [];
                    state.allFilters.cities = payload?.data?.filters?.cities || [];
                }
            }
        },
    },
});

export const {
    setActiveView,
    clearState,
    setIsExporting,
    setLoadingInsights,
    setShowAndSplit,
    setAppliedFilters,
    clearAppliedFilters,
    setAllFilters,
    clearAllFilters,
    setNonFilteredInsightsData,
} = stackInsightsSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const stackInsightsSliceSelector = {
    insightsData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.insightsData;
    },
    loadingInsights: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loadingInsights;
    },
    activeView: (rootState) => {
        const appState = getAppState(rootState);
        return appState.activeView;
    },
    isExporting: (rootState) => {
        const appState = getAppState(rootState);
        return appState.isExporting;
    },
    show: (rootState) => {
        const appState = getAppState(rootState);
        return appState.show;
    },
    split: (rootState) => {
        const appState = getAppState(rootState);
        return appState.split;
    },
    nonFilteredInsightsData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.nonFilteredInsightsData;
    },
    appliedFilters: (rootState) => {
        const appState = getAppState(rootState);
        return appState.appliedFilters;
    },
    allFilters: (rootState) => {
        const appState = getAppState(rootState);
        return appState.allFilters;
    },
};
