import React from "react";
import { Paper, Grid, Typography, Tooltip, Box, Divider } from "@mui/material/";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    universityInfo: {
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#cccccc",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccccc",
            borderRadius: 3,
        },
    },
    paper: {
        paddingBottom: 30,
    },
    universityGrid: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3.5),
        background: "transparent",
    },
    typographyDisabled: {
        color: theme.palette.text.disabled,
    },
}));

export default function UniversityInfo({ university }) {
    const classes = useStyles();
    const delayTime = 500;

    const universityInfo = university.universityInfo;
    const osterusAnalytics = university.osterusAnalytics;

    return (
        <div className={classes.universityInfo}>
            {university.name && (
                <Paper elevation={0} className={classes.paper}>
                    <Grid container className={classes.infoSectionGrid}>
                        <Grid container className={classes.universityGrid}>
                            <Typography fontWeight={700} variant="subtitle2" color="textPrimary">
                                EXTERNAL DATA
                            </Typography>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The Rankings represent the rank of the university according to different ranking sources (if available)."
                                placement="top-start"
                            >
                                <Grid item xs={12}>
                                    {universityInfo.externalData.rankings?.map((item) => (
                                        <Typography key="item" fontWeight={700} variant="body2" color="textPrimary">
                                            {item}
                                        </Typography>
                                    ))}
                                    <Typography variant="body2" color="textSecondary">
                                        Ranking
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The Quality of Life City Ranking is acquired from the Osterus Cost of Living and Quality of Life database shows ranking the city where university is located."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {universityInfo.externalData.qualityOfLifeCityRanking === 0
                                            ? `-`
                                            : `#${universityInfo.externalData.qualityOfLifeCityRanking}`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Quality of Life City Ranking
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The Cost of City Housing is acquired from the Osterus Cost of Living and Quality of Life database and are an average approximation of the housing cost."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {universityInfo.externalData.costOfCityHousing === 0
                                            ? `-`
                                            : `${universityInfo.externalData.costOfCityHousing}`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Cost of City Housing
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="Shows if the university is a private or public."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    {university.is_private === true ? (
                                        <Typography fontWeight={700} variant="body2" color="textPrimary">
                                            Private
                                        </Typography>
                                    ) : (
                                        <Typography fontWeight={700} variant="body2" color="textPrimary">
                                            Public
                                        </Typography>
                                    )}
                                    <Typography variant="body2" color="textSecondary">
                                        Private or Public
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="Shows the Average Monthly Net Salary in the city where university is located."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {universityInfo.externalData.avgMonthlyNetSalary === 0
                                            ? `-`
                                            : `${universityInfo.externalData.avgMonthlyNetSalary}`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Average Monthly Net Salary in the City
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Divider variant="middle" />
                    </Box>
                    <Grid container className={classes.infoSectionGrid}>
                        <Grid container className={classes.universityGrid} flexDirection="column">
                            <Typography fontWeight={700} variant="subtitle2" color="textPrimary">
                                OSTERUS ANALYTICS
                            </Typography>
                            {!osterusAnalytics.percentageOfMaleGraduates && (
                                <Typography variant="body2" color="textSecondary">
                                    Data on graduates of this university is currently not available in our database.
                                </Typography>
                            )}
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The Percentage of male graduates is calculated from all the graduates of this university which passed through the Osterus analysis. The health/accuracy of the data is based on the amount of processed CVs."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {!osterusAnalytics.percentageOfMaleGraduates
                                            ? `-`
                                            : `${parseFloat(osterusAnalytics.percentageOfMaleGraduates).toFixed(0)}%`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Percentage of male graduates
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The Percentage of female graduates is calculated from all the graduates of this university which passed through the Osterus analysis. The health/accuracy of the data is based on the amount of processed CVs."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {!osterusAnalytics.percentageOfFemaleGraduates
                                            ? `-`
                                            : `${parseFloat(osterusAnalytics.percentageOfFemaleGraduates).toFixed(0)}%`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Percentage of female graduates
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The top 5 most dominant languages that graduates speak."
                                placement="top"
                            >
                                <Grid item xs={12}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {osterusAnalytics.top5LanguagesSpoken.length === 0
                                            ? `-`
                                            : osterusAnalytics.top5LanguagesSpoken.join(", ")}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Top 5 dominant languages spoken
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="Average Education Time from all the studnets of this university and it is based on years."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {!osterusAnalytics.avgEducationTime
                                            ? `-`
                                            : `${parseFloat(osterusAnalytics.avgEducationTime).toFixed(0)} years`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Average Education Time
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="Median Education Time from all the studnets of this university and it is based on years."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {!osterusAnalytics.medianEducationTime
                                            ? `-`
                                            : `${parseFloat(osterusAnalytics.medianEducationTime).toFixed(0)} years`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Median Education Time
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The top 5 most popular comapnies among graduates that end up working in."
                                placement="top"
                            >
                                <Grid item xs={12}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {osterusAnalytics.top5CompaniesGraduatesWorkingIn.length === 0
                                            ? `-`
                                            : osterusAnalytics.top5CompaniesGraduatesWorkingIn.join(", ")}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Top 5 Companies graduates end up working in
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>

                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The 15 most common job titles that graduates end up having."
                                placement="top"
                            >
                                <Grid item xs={12}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {osterusAnalytics.top15GraduatesJobTitles.length === 0
                                            ? `-`
                                            : osterusAnalytics.top15GraduatesJobTitles.join(", ")}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Top 15 graduates job titles graduates currently hold
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>

                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title=" Average Employment Retention Rate shows how many years on average graduates stay in one company."
                                placement="top"
                            >
                                <Grid item xs={12}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {!osterusAnalytics.avgEmploymentRetentionRate
                                            ? `-`
                                            : `${parseFloat(osterusAnalytics.avgEmploymentRetentionRate).toFixed(
                                                  0,
                                              )} years`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Average Employment Retention Rate
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>

                        <Grid container className={classes.universityGrid}>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The living location of graduates is acquired from all the graduates of this university which passed through the Osterus analysis. The health/accuracy of the data is based on the amount of processed CVs."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {osterusAnalytics.alumniLive ? osterusAnalytics.alumniLive : `-`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Alumni (where do the graduates <strong>live</strong>)
                                    </Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="The workplace location of graduates is acquired from all the graduates of this university which passed through the Osterus analysis. The health/accuracy of the data is based on the amount of processed CVs."
                                placement="top-start"
                            >
                                <Grid item xs={6}>
                                    <Typography fontWeight={700} variant="body2" color="textPrimary">
                                        {osterusAnalytics.alumniWork ? osterusAnalytics.alumniWork : `-`}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Alumni (where do the graduates <strong>work</strong>)
                                    </Typography>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </div>
    );
}
