import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "@gatsbyjs/reach-router";
import dayjs from "dayjs";
import {
    createUser,
    getUserDetails,
    getUsers,
    uploadProfilePhoto,
    sendForgotPassword,
    getResetPasswordToken,
    resetPassword,
} from "../../actions/user/user";
import { getMe, logIn, logOut } from "../../services/api/Public/Login/loginActions";
import { updateSettingsGeneral, updateSettingsPreference, updateSettingsSecurity } from "../../actions/user/settings";
import { mapBackendValidationErrors } from "../../services/utils/form";

export const USER_TYPES = {
    OSTERUS_ADMIN: 1,
    CLIENT_ADMIN: 2,
    USER: 3,
};

export const USER_STATUS = {
    ACTIVE: "active",
    PENDING: "pending",
    DEACTIVATED: "deactivated",
};

const SLICE_NAME = "user";

const storageToken = localStorage.getItem("token");
const storageTrialEndsAt = localStorage.getItem("trialEndsAt");
const storageSubscriptionEndsOn = localStorage.getItem("subscriptionEndsOn");
const initialState = {
    loading: false,
    id: null,
    token: storageToken || null,
    last_name: "",
    first_name: "",
    timezone: "0",
    city: null,
    title: "",
    department: "",
    profile_picture: null,
    uploadPhotoLoading: false,
    email: "",
    users: null,
    usersLoading: true,
    selectedUser: null,
    isInfo: false,
    step: null,
    filter: {
        q: {},
        role: [],
        status: [],
        company: [],
        last_active: [],
        page: 1,
        perPage: 25,
        sort: undefined,
        direction: undefined,
    },
    editModalOpen: false,
    deleteModalOpen: false,
    password_last_changed: "",
    organizations: null,
    loginError: false,
    status: "inactive",
    created_at: "",
    password_changed_at: "",
    roles: null,
    formErrors: null,
    updateUser: null,
    passwordReset: {
        emailExists: null,
        token: null,
        resetted: null,
    },
    trialEndsAt: storageTrialEndsAt || null,
    subscriptionEndsOn: storageSubscriptionEndsOn || null,
};

export const userSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setFilter(state, { payload }) {
            state.filter = payload;
        },
        setRoleFilter(state, { payload }) {
            if (payload) {
                state.filter.role.push(payload);
            } else {
                state.filter.role = [];
            }
        },
        setCompanyFilter(state, { payload }) {
            state.filter.company = payload;
        },
        setStatusFilter(state, { payload }) {
            state.filter.status = payload;
        },
        setLastActiveFilter(state, { payload }) {
            state.filter.last_active = payload;
        },
        emptySearchField(state) {
            const modifiedFilter = { ...state.filter, q: {} };
            state.filter = modifiedFilter;
        },
        setSelectedUser(state, { payload }) {
            state.selectedUser = payload;
        },
        clearFilter(state) {
            state.filter = initialState.filter;
        },
        setIsInfo(state, { payload }) {
            state.isInfo = payload;
        },
        setStep(state, { payload }) {
            state.step = payload;
        },
        setEditModalOpen(state, { payload }) {
            state.editModalOpen = payload;
        },
        setDeleteModalOpen(state, { payload }) {
            state.deleteModalOpen = payload;
        },
        clearFormErrors(state) {
            state.formErrors = null;
        },
        setUpdateUser(state, { payload }) {
            state.updateUser = payload;
        },
        clearPasswordReset(state) {
            state.passwordReset = {
                emailExists: null,
                token: null,
                resetted: null,
            };
        },
        setUserList(state, action) {
            state.users = action.payload;
        },
    },
    extraReducers: {
        [getMe.pending]: (state) => {
            state.loading = true;
        },
        [getMe.fulfilled]: (state, { payload }) => {
            state.loading = false;

            state.id = payload.user.id;
            state.first_name = payload.user.first_name;
            state.last_name = payload.user.last_name;
            state.timezone = payload.user.timezone;
            state.city = payload.user.city;
            state.title = payload.user.title;
            state.department = payload.user.department;
            state.profile_picture = payload.user.profile_picture;
            state.email = payload.user.email;
            state.organizations = payload.user.organizations;
            state.status = payload.user.status;
            state.step = payload.user.step;
            state.created_at = payload.user.created_at;
            state.password_changed_at = payload.user.password_changed_at;
            state.type = payload.user.type;
            state.roles = payload.user.roles;

            if (state.organizations.length > 0) {
                const { trial_ends_at, subscription_ends_on } = state.organizations[0] || {};
                localStorage.setItem("trialEndsAt", trial_ends_at);
                localStorage.setItem("subscriptionEndsOn", subscription_ends_on);
            }
        },
        [logIn.pending]: (state) => {
            state.loading = true;
            state.loginError = false;
        },
        [logIn.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.token = payload.token;
            localStorage.setItem("token", payload.token);
        },
        [logIn.rejected]: (state) => {
            state.loading = false;
            state.loginError = true;
        },
        [logOut.pending]: (state) => {
            state.loading = true;
        },
        [logOut.fulfilled]: (state) => {
            state.loading = false;
            state.token = null;
            state.id = null;
            localStorage.removeItem("token");
            localStorage.removeItem("trialEndsAt");
            localStorage.removeItem("subscriptionEndsOn");
            navigate("/login");
        },
        [getUserDetails.rejected]: (state) => {
            state.email = "Email address not valid";
        },
        [getUserDetails.fulfilled]: (state, { payload }) => {
            state.token = payload.token;
            const { user } = payload;
            if (user) {
                state.id = user.id;
                state.first_name = user.first_name;
                state.last_name = user.last_name;
                state.timezone = user.timezone;
                state.city = user.city;
                state.title = user.title;
                state.department = user.department;
                state.profile_picture = user.profile_picture;
                state.email = user.email;
                state.organizations = user.organizations;
                state.status = user.status;
                state.created_at = user.created_at;
                state.password_changed_at = user.password_changed_at;
                state.type = user.type;
            }
        },
        [updateSettingsGeneral.fulfilled]: (state, { payload }) => {
            state.first_name = payload.first_name;
            state.last_name = payload.last_name;
            state.timezone = payload.timezone;
            state.city = payload.city;
            state.title = payload.title;
            state.department = payload.department;
            state.profile_picture = payload.profile_picture;
        },
        [updateSettingsSecurity.fulfilled]: (state, { payload }) => {
            state.email = payload.email;
            state.password_changed_at = payload.password_changed_at;
        },
        [updateSettingsSecurity.rejected]: (state, action) => {
            const modifiedErrors = mapBackendValidationErrors(action);
            state.formErrors = modifiedErrors;
        },
        [updateSettingsPreference.rejected]: (state, action) => {
            const modifiedErrors = mapBackendValidationErrors(action);
            state.formErrors = modifiedErrors;
        },
        [createUser.fulfilled]: (state, { payload }) => {
            state.users.push(payload.data);
        },
        [createUser.rejected]: (state, action) => {
            const modifiedErrors = mapBackendValidationErrors(action);
            state.formErrors = modifiedErrors;
        },
        [uploadProfilePhoto.fulfilled]: (state, { payload }) => {
            state.profile_picture = payload.data?.picture || null;
        },
        [getUsers.pending]: (state) => {
            state.usersLoading = true;
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            state.usersLoading = false;
            state.users = payload.data;
        },
        [sendForgotPassword.rejected]: (state) => {
            state.passwordReset.emailExists = false;
        },
        [sendForgotPassword.fulfilled]: (state) => {
            state.passwordReset.emailExists = true;
        },
        [getResetPasswordToken.rejected]: (state) => {
            state.passwordReset.token = false;
        },
        [getResetPasswordToken.fulfilled]: (state, { payload }) => {
            state.passwordReset.token = payload.data.token;
        },
        [resetPassword.rejected]: (state) => {
            state.passwordReset.resetted = false;
        },
        [resetPassword.fulfilled]: (state) => {
            state.passwordReset.resetted = true;
        },
    },
});

export const {
    setFilter,
    setRoleFilter,
    setCompanyFilter,
    setStatusFilter,
    setLastActiveFilter,
    emptySearchField,
    setSelectedUser,
    clearFilter,
    setIsInfo,
    setStep,
    setEditModalOpen,
    setDeleteModalOpen,
    clearFormErrors,
    setUpdateUser,
    clearPasswordReset,
    setUserList,
} = userSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const userSliceSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    organizations: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations;
    },
    organization: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations?.length > 0 ? appState.organizations[0] : null;
    },
    hasTrialOrSubscriptionEnded: (rootState) => {
        const appState = getAppState(rootState);
        return appState.trialEndsAt || appState.subscriptionEndsOn
            ? dayjs(appState.trialEndsAt).isBefore(dayjs()) || dayjs(appState.subscriptionEndsOn).isBefore(dayjs())
            : false;
    },
    defaultUserFields: (rootState) => {
        const appState = getAppState(rootState);
        return {
            id: appState.id,
            first_name: appState.first_name,
            last_name: appState.last_name,
            timezone: appState.timezone,
            city: appState.city,
            title: appState.title,
            department: appState.department,
            profile_picture: appState.profile_picture,
            email: appState.email,
            status: appState.status,
            password_changed_at: appState.password_changed_at,
            created_at: appState.created_at,
            roles: appState.roles,
        };
    },
    loginError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loginError;
    },
    users: (rootState) => {
        const appState = getAppState(rootState);
        return appState.users;
    },
    usersLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.usersLoading;
    },
    selectedUser: (rootState) => {
        const appState = getAppState(rootState);
        return appState.selectedUser;
    },
    isInfo: (rootState) => {
        const appState = getAppState(rootState);
        return appState.isInfo;
    },
    step: (rootState) => {
        const appState = getAppState(rootState);
        return appState.step;
    },
    filter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.filter;
    },
    editModalOpen: (rootState) => {
        const appState = getAppState(rootState);
        return appState.editModalOpen;
    },
    deleteModalOpen: (rootState) => {
        const appState = getAppState(rootState);
        return appState.deleteModalOpen;
    },
    token: (rootState) => {
        const appState = getAppState(rootState);
        return appState.token;
    },
    type: (rootState) => {
        const appState = getAppState(rootState);
        return appState.type || null;
    },
    formErrors: (rootState) => {
        const appState = getAppState(rootState);
        return appState.formErrors;
    },
    updateUser: (rootState) => {
        const appState = getAppState(rootState);
        return appState.updateUser;
    },
    passwordReset: (rootState) => {
        const appState = getAppState(rootState);
        return appState.passwordReset;
    },
};
