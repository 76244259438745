import React from "react";
import { Dialog, DialogContent, DialogActions, TextField, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { companyRequestSchema } from "./companyRequestSchema";
import { requestCompanyData } from "../../../../actions/Company/Data/request";
import { userSliceSelectors } from "../../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    titleContainer: {
        margin: "16px 24px",
    },
    dialogActions: {
        marginTop: 10,
    },
    industryField: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    asterisk: {
        color: "red",
    },
}));

export default function CompanyDataRequestModal({ open, handleClose, dataRequestLimit, setDataRequestLimit }) {
    const organizations = useSelector(userSliceSelectors.organizations);
    const dispatch = useDispatch();
    const classes = useStyles();

    const onSubmit = (data) => {
        const modifiedData = { ...data, organization_id: organizations[0]?.id };
        dispatch(requestCompanyData(modifiedData));
        if (dataRequestLimit) {
            setDataRequestLimit((prev) => (prev - 1).toString());
        }

        onClose();
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(companyRequestSchema),
        reValidateMode: "onChange",
    });

    const onClose = () => {
        reset();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <Typography variant="h6" color="primary">
                        Company Data Request
                    </Typography>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div>
                            <TextField
                                {...register("company_name")}
                                label="Name of the Company"
                                placeholder="eg. Apple, Tesla, Paypal..."
                                fullWidth
                                variant="outlined"
                                onBlur={(e) => setValue("company_name", e.target.value.trim())}
                                error={Boolean(errors.company_name)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.company_name && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.company_name.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.industryField}>
                            <TextField
                                {...register("company_url")}
                                label="Company URL"
                                placeholder="eg. Website, LinkedIn, Talentwunder..."
                                fullWidth
                                variant="outlined"
                                onBlur={(e) => setValue("company_url", e.target.value.trim())}
                                error={Boolean(errors.company_url)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.company_url && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.company_url.message}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <Typography variant="caption2" color="textSecondary">
                                Our data processing engine will automatically remove duplicates and ghost-profiles,
                                congregate jobs under one umbrella, connect the profiles data with the rest of the
                                Osterus database and, most importantly, make sure that the data you are seeing is
                                compliant with the GDPR rules.
                            </Typography>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Send Request
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
}
