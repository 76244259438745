import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
    Dialog,
    Stack,
    Typography,
    Button,
    TextField,
    Divider,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    ListItemText,
    DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { userSliceSelectors } from "../../../../../slices/User/userSlice";

const useStyles = makeStyles(() => ({
    inputLabel: {
        position: "absolute",
        top: -5,
    },
    textField: {
        width: 300,
        height: 37,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 350,
            minWidth: 350,
        },
    },
};

const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
const usersPages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const textBoxes = [1, 2, 3, 4];
const pageTitles = [
    "Workforce Analytics Report",
    "General Overview",
    "Employment Job Titles",
    "Employment Gender Diversity",
    "Employment Work Experience in Job Titles",
    "Employment Work Experience Distribution by Gender",
    "Employment Top Previous Companies",
    "Employment Average Retention in Previous Companies in Job Titles",
    "Education Degrees",
    "Education Top Universities Attended",
    "Languages Spoken",
    "Loyalty Score",
    "Diversity Score",
    "Data Points",
];

function AutoExpandTextField({ onChange, index, setExpandTextFieldIndex }) {
    const [rows, setRows] = useState();
    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            size="small"
            onBlur={() => {
                setExpandTextFieldIndex(null);
                setRows(1);
            }}
            onFocus={() => {
                setExpandTextFieldIndex(index);
                setRows(4);
            }}
            rows={rows}
            width={200}
            onChange={onChange}
            className={classes.textField}
            multiline
        />
    );
}

export function DownloadModal({ open, onClose, selectedStacks }) {
    const classes = useStyles();
    const userType = useSelector(userSliceSelectors.type);
    const [selectedPages, setSelectedPages] = useState(userType === 1 ? pages : usersPages);
    const [textboxes, setTextboxes] = useState(["", "", "", ""]);
    const [expandTextFieldIndex, setExpandTextFieldIndex] = useState();
    const [requestSent, setRequestSent] = useState(false);

    useEffect(
        () => () => {
            setTextboxes(["", "", "", ""]);
            setSelectedPages(userType === 1 ? pages : usersPages);
            setRequestSent(false);
        },
        [],
    );

    const downloadPresentation = async () => {
        const formattedPages = selectedPages.map((item) => ({
            id: `page${item}`,
            type: "text",
            value: "",
        }));
        formattedPages.push({
            id: "stacks",
            type: "text",
            value: "",
        });

        const unique_id = uuid();
        const data = {
            request_id: unique_id,
            textbox: textboxes,
            type: "stack",
            ids: [selectedStacks[0].id, selectedStacks[1].id],
            data: formattedPages,
        };

        setTextboxes(["", "", "", ""]);
        setSelectedPages(userType === 1 ? pages : usersPages);
        onClose(data);
    };

    const isAllSelected =
        userType === 1
            ? pages.length > 0 && selectedPages.length === pages.length
            : usersPages.length > 0 && selectedPages.length === usersPages.length;

    const changeSelectedPages = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            if (userType === 1) {
                setSelectedPages(selectedPages.length === pages.length ? [] : pages);
            } else {
                setSelectedPages(selectedPages.length === usersPages.length ? [] : usersPages);
            }

            return;
        }
        setSelectedPages(value.sort((cur, next) => cur - next));
    };

    const handleOnClose = () => {
        setTextboxes(["", "", "", ""]);
        setSelectedPages(userType === 1 ? pages : usersPages);
        setRequestSent(false);
        if (onClose) onClose(null);
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <Stack m={1} sx={{ width: 500 }}>
                <Typography variant="h5" mx={2} my={2}>
                    Download Presentation
                </Typography>

                <Divider />
                {userType === 1 ? (
                    <Stack mx={2} mt={4} overflow="auto">
                        <Stack direction="row" alignItems="center" mt={2} mb={1} spacing={2}>
                            <Stack justifyContent="center" width={100}>
                                <Typography variant="h6">Pages</Typography>
                            </Stack>
                            <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label" className={classes.inputLabel}>
                                    Select Pages
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedPages}
                                    onChange={changeSelectedPages}
                                    input={<OutlinedInput label="Select Pages" />}
                                    renderValue={(selected) => (isAllSelected ? "Select All" : selected.join(", "))}
                                    MenuProps={MenuProps}
                                    className={classes.textField}
                                >
                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? classes.selectedAll : "",
                                        }}
                                    >
                                        <Checkbox
                                            color="secondary"
                                            checked={isAllSelected}
                                            indeterminate={
                                                selectedPages.length > 0 && selectedPages.length < pages.length
                                            }
                                        />
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {pages.map((page, index) => (
                                        <MenuItem key={page} value={page}>
                                            <Stack direction="row" alignItems="center">
                                                <Checkbox
                                                    checked={selectedPages.indexOf(page) > -1}
                                                    color="secondary"
                                                />
                                                <Typography variant="body1" mr={1.75}>
                                                    {page}
                                                </Typography>
                                                <Typography variant="body1" whiteSpace="normal">
                                                    {pageTitles[index]}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                        {textBoxes.map((item, index) => (
                            <Stack
                                direction="row"
                                alignItems="center"
                                mt={2}
                                mb={expandTextFieldIndex === index && index !== 3 ? 9 : 1}
                                spacing={2}
                                key={index}
                            >
                                <Stack justifyContent="center" width={100}>
                                    <Typography variant="h6">Text Box {item}</Typography>
                                </Stack>
                                <AutoExpandTextField
                                    onChange={(e) => {
                                        const tempArr = textboxes;
                                        tempArr[index] = e.target.value;
                                        setTextboxes(tempArr);
                                    }}
                                    index={index}
                                    setExpandTextFieldIndex={setExpandTextFieldIndex}
                                />
                            </Stack>
                        ))}
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" mt={4} mx={2} mb={1} spacing={2}>
                        <Stack justifyContent="center" width={100}>
                            <Typography variant="h6">Pages</Typography>
                        </Stack>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" className={classes.inputLabel}>
                                Select Pages
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedPages}
                                onChange={changeSelectedPages}
                                input={<OutlinedInput label="Select Pages" />}
                                renderValue={(selected) => (isAllSelected ? "Select All" : selected.join(", "))}
                                MenuProps={MenuProps}
                                className={classes.textField}
                            >
                                <MenuItem
                                    value="all"
                                    classes={{
                                        root: isAllSelected ? classes.selectedAll : "",
                                    }}
                                >
                                    <Checkbox
                                        color="secondary"
                                        checked={isAllSelected}
                                        indeterminate={selectedPages.length > 0 && selectedPages.length < pages.length}
                                    />
                                    <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                                </MenuItem>
                                {usersPages.map((page, index) => (
                                    <MenuItem key={page} value={page}>
                                        <Stack direction="row" alignItems="center">
                                            <Checkbox checked={selectedPages.indexOf(page) > -1} color="secondary" />
                                            <Typography variant="body1" mr={1.75}>
                                                {page}
                                            </Typography>
                                            <Typography variant="body1" whiteSpace="normal">
                                                {pageTitles[index]}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                )}
                <DialogActions>
                    <Button variant="text" onClick={handleOnClose} disableElevation>
                        Close
                    </Button>
                    <Button
                        variant="text"
                        disableElevation
                        onClick={downloadPresentation}
                        disabled={requestSent || selectedPages.length === 0}
                    >
                        Download
                    </Button>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}
