import React from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PublicAuthImage from "../../../components/Account/Login/assets/PublicAuth.png";
import RegisterComponent from "../../../components/Account/Register";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh",
        display: "flex",
    },
    background: {
        backgroundColor: "#4973eb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    backgroundImage: {
        width: "60%",
        verticalAlign: "middle",
        marginTop: "-70px",
    },
}));

function AccountRegisterPage() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item mt={0} xs={4}>
                <RegisterComponent />
            </Grid>
            <Grid item xs={8} className={classes.background}>
                <Box textAlign="center" justifyContent="center">
                    <img src={PublicAuthImage} className={classes.backgroundImage} alt="Osterus" />
                </Box>
            </Grid>
        </Grid>
    );
}

export default AccountRegisterPage;
