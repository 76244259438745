import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    MenuItem,
    TextField,
    Typography,
    Avatar,
    Grid,
    Box,
    Stack,
    Tab,
    Checkbox,
} from "@mui/material";
import { TabList, TabContext } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateOrganizationApi, deleteOrganizationApi } from "../../../../../services/api/Admin/organizations";
import { adminSliceSelectors } from "../../../../../slices/Admin/adminSlice";
import { getPlans, getOrganizations } from "../../../../../actions/Admin/Organizations";
import { DeleteModal } from "./DeleteModal";
import { addMessage } from "../../../../../slices/NotificationSlice/GlobalNotificationSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 550,
        padding: theme.spacing(1),
    },
    titleContainer: {
        margin: "16px 24px",
    },
    dialogActions: {
        marginTop: 10,
    },
    industryField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    row: {
        display: "flex",
    },
    title: {
        fontWeight: 500,
    },
    deleteUserButton: {
        top: 4,
        left: 5,
        textTransform: "none",
        "&:hover": {
            background: "#405CB9",
            boxShadow: "none",
        },
    },
    textBox: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    profilePicture: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: 70,
        height: 70,
        borderRadius: "50%",
    },
    textField: {
        marginTop: theme.spacing(3),
    },
    fullName: {
        width: 400,
    },
    cityName: {
        marginRight: theme.spacing(0.5),
    },
    tab: {
        border: "none",
    },
    tabList: {
        borderBottom: "1px solid lightgrey",
        maxHeight: 41,
    },
    permissionsField: {
        width: 250,
    },
    limitField: {
        marginLeft: 40,
        width: 190,
    },
}));

export default function EditOrganizationsModal({ open, organization, onSave, onCancel }) {
    const [selectedPlan, setSelectedPlan] = useState(organization?.plan_price);
    const [selectedPriceId, setSelectedPriceId] = useState(organization?.plan_price?.id);
    const [selectedPriceInterval, setSelectedPriceInterval] = useState(organization?.plan_price?.interval);
    const defaultReportLimit = organization?.limits?.[0].initial_credits?.toString();
    const [reportLimit, setReportLimit] = useState(defaultReportLimit);
    const defaultRequestedDataLimit = organization?.limits[1]?.initial_credits?.toString();
    const [requestedDataLimit, setRequestedDataLimit] = useState(defaultRequestedDataLimit);
    const [databaseAccess, setDatabaseAccess] = useState(false);
    const [organizationOwner, setOrganizationOwner] = useState();
    const [value, setValue] = useState("1");
    const intervals = ["day", "month", "year"];
    const filter = useSelector(adminSliceSelectors.organizationsFilter);
    const plans = useSelector(adminSliceSelectors.organizationsPlans);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const onDeleteClicked = () => {
        setDeleteModalOpen(true);
    };

    const handleSave = async () => {
        const { data } = await updateOrganizationApi(organization.id, {
            plan_price_id: selectedPriceId,
            owner_id: organizationOwner.id,
            has_public_database_access: databaseAccess,
            limits: {
                company_data_requests: requestedDataLimit,
                automated_report_requests: reportLimit,
            },
        });

        onSave?.(data.data);
        setOrganizationOwner();
        setValue("1");
        if (filter.direction === "asc") {
            dispatch(
                getOrganizations({
                    search: filter.q.name || undefined,
                    start: 1,
                    asc: filter.sort || undefined,
                    industry: filter.industry || undefined,
                    organization: filter.organization || undefined,
                    price: filter.price || undefined,
                    plan: filter.plan || undefined,
                }),
            );
        } else {
            dispatch(
                getOrganizations({
                    search: filter.q.name || undefined,
                    start: 1,
                    desc: filter.sort || undefined,
                    industry: filter.industry || undefined,
                    organization: filter.organization || undefined,
                    price: filter.price || undefined,
                    plan: filter.plan || undefined,
                }),
            );
        }
    };

    useEffect(() => {
        dispatch(getPlans());
    }, []);

    useEffect(() => {
        setDatabaseAccess(organization?.has_public_database_access);
        setReportLimit(organization?.limits[0].initial_credits?.toString());
        setRequestedDataLimit(organization?.limits[1].initial_credits?.toString());
    }, [open, organization]);

    useEffect(() => {
        setSelectedPlan(organization?.plan_price);
        setSelectedPriceId(organization?.plan_price?.id);
        setSelectedPriceInterval(organization?.plan_price?.interval);
    }, [organization?.plan_price]);

    const onPlanSelected = (e) => {
        const planValue = e.target.value;
        const plan = plans.find((item) => item.name === planValue);
        setSelectedPlan(plan);
        setSelectedPriceId();
        setSelectedPriceInterval();
    };

    const onPriceSelected = (e) => {
        setSelectedPriceId(Number(e.target.value));
    };

    const onIntervalSelected = (e) => {
        setSelectedPriceInterval(e.target.value);
    };

    function OrganizationPhoto() {
        /*
        if (organization_picture)
            return (
                <Avatar
                    className={classes.profilePicture}
                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${organization_picture}`}
                />
            ); */
        return <Avatar className={classes.profilePicture} />;
    }

    const selectedPlanName = selectedPlan?.name ?? selectedPlan?.plan_name;
    const planPrices = plans.find((plan) => plan.name === selectedPlanName)?.prices ?? [];

    const handleDelete = async () => {
        try {
            await deleteOrganizationApi(organization.id);
            dispatch(getOrganizations({ name: filter.q.name, start: 1 }));
            dispatch(
                addMessage({
                    type: "success",
                    title: "Organization deleted.",
                    message: "Organization successfully deleted.",
                    visibilityTime: 5000,
                }),
            );
        } catch (e) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong :(",
                    visibilityTime: 5000,
                }),
            );
        }
        setDeleteModalOpen(false);
        onCancel();
        setValue("1");
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const isValidNumber = (e) => {
        const specialCharacters = /^[0-9]*$/;
        if (!specialCharacters.test(e.key)) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        setOrganizationOwner(organization?.owner);
    }, [open]);

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <Typography variant="h6" color="primary">
                        Edit Organization
                    </Typography>
                </div>
                <Divider variant="middle" />
                <form>
                    <DialogContent>
                        <Grid container alignItems="center">
                            <OrganizationPhoto />
                            <Box className={classes.textBox}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className={classes.fullName}
                                >
                                    <Typography variant="body1" color="textPrimary" display="inline">
                                        {organization?.full_name || "-"}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={classes.deleteUserButton}
                                        onClick={onDeleteClicked}
                                    >
                                        Delete organization
                                    </Button>
                                </Stack>

                                <Typography variant="body2" color="textSecondary" className={classes.title}>
                                    {organization?.owner
                                        ? `${organization?.owner?.first_name} ${organization?.owner?.last_name}`
                                        : "-"}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" className={classes.title}>
                                    {organization?.locations[0]?.city && organization?.locations[0].length
                                        ? `${organization.locations[0].city?.name},
                                        ${organization.locations[0].city.country?.name}`
                                        : "-"}
                                </Typography>
                            </Box>
                        </Grid>
                        <Box sx={{ width: "100%", typography: "body1", mb: 4 }}>
                            <TabContext value={value}>
                                <Stack className={classes.tabList}>
                                    <TabList
                                        onChange={handleTabChange}
                                        textColor="secondary"
                                        indicatorColor="secondary"
                                    >
                                        <Tab label="Billing Info" value="1" className={classes.tab} />
                                        <Tab label="Permissions" value="2" className={classes.tab} />
                                    </TabList>
                                </Stack>
                            </TabContext>
                        </Box>
                        {value === "1" ? (
                            <>
                                <div className={classes.industryField}>
                                    <TextField
                                        select
                                        label="Plan"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedPlanName ?? ""}
                                        onChange={onPlanSelected}
                                    >
                                        {plans.map(({ name }) => (
                                            <MenuItem key={name} value={name}>
                                                <Typography variant="body2">{name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                                <div className={classes.industryField}>
                                    <TextField
                                        disabled={!selectedPlan}
                                        select
                                        label="Current Pricing"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedPriceId ?? ""}
                                        onChange={onPriceSelected}
                                    >
                                        {planPrices?.map(({ id: priceId, price, currency, interval }) => (
                                            <MenuItem key={priceId} value={priceId}>
                                                <Typography variant="body2">
                                                    {`${Intl.NumberFormat().format(
                                                        price,
                                                    )} ${currency.toUpperCase()}/${interval}`}
                                                </Typography>
                                            </MenuItem>
                                        )) ?? []}
                                    </TextField>
                                </div>
                                <div className={classes.industryField}>
                                    <TextField
                                        disabled={!selectedPlan}
                                        select
                                        label="Subscription"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedPriceInterval ?? ""}
                                        onChange={onIntervalSelected}
                                    >
                                        {intervals.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Typography variant="body2">{name}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>

                                <div className={classes.industryField}>
                                    <TextField
                                        select
                                        label="Account Owner"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setOrganizationOwner(e.target.value)}
                                        defaultValue={organizationOwner}
                                        SelectProps={{
                                            renderValue: (user) => (
                                                <Typography variant="body2">
                                                    {user.first_name} {user.last_name}
                                                </Typography>
                                            ),
                                        }}
                                    >
                                        {organization?.users?.map((user, index) => (
                                            <MenuItem key={index} value={user}>
                                                <Typography variant="body2">
                                                    {user.first_name} {user.last_name}
                                                </Typography>
                                            </MenuItem>
                                        )) ?? []}
                                    </TextField>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={classes.industryField}>
                                    <TextField
                                        label="Number of Company Requests"
                                        variant="outlined"
                                        type="number"
                                        className={classes.permissionsField}
                                        defaultValue={requestedDataLimit || ""}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                        onKeyPress={isValidNumber}
                                        onChange={(e) => {
                                            const currentValue = e.target.value.replace(/\D/g, "");
                                            setRequestedDataLimit(currentValue);
                                        }}
                                    />

                                    <TextField
                                        label="Remaining Company Data Requests"
                                        variant="outlined"
                                        defaultValue={organization?.limits[1].credits.toString() || ""}
                                        className={classes.limitField}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>
                                <div className={classes.industryField}>
                                    <TextField
                                        label="Number of Automated Reports To Download"
                                        variant="outlined"
                                        type="number"
                                        className={classes.permissionsField}
                                        defaultValue={reportLimit || ""}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                        onKeyPress={isValidNumber}
                                        onChange={(e) => setReportLimit(e.target.value)}
                                    />
                                    <TextField
                                        label="Remaining Report Downloads"
                                        variant="outlined"
                                        defaultValue={organization?.limits[0].credits.toString() || ""}
                                        className={classes.limitField}
                                        inputProps={{ readOnly: true }}
                                    />
                                </div>

                                <Stack mt={6} direction="row" alignItems="center">
                                    <Checkbox
                                        color="secondary"
                                        onChange={(e) => setDatabaseAccess(e.target.checked)}
                                        defaultChecked={organization?.has_public_database_access}
                                    />
                                    <Typography variant="body2">
                                        Access to Osterus public database of companies
                                    </Typography>
                                </Stack>
                            </>
                        )}
                    </DialogContent>
                </form>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        color="primary"
                        onClick={() => {
                            onCancel();
                            setOrganizationOwner();
                            setValue("1");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleSave}
                        disabled={!selectedPlan || !selectedPriceInterval || !selectedPriceId}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </div>
            <DeleteModal
                open={deleteModalOpen}
                onCancel={() => setDeleteModalOpen(false)}
                organization={organization}
                onDelete={handleDelete}
            />
        </Dialog>
    );
}
