import React from "react";
import { MenuItem, Checkbox, TextField, ListItemText, Tooltip } from "@mui/material";

export default function JobPositionsFilter() {
    const positions = ["Include All"];
    const delayTime = 500;
    const [positionsName, setPositionsName] = React.useState([]);
    const changePositions = (event) => {
        const {
            target: { value },
        } = event;
        setPositionsName(typeof value === "string" ? value.split(",") : value);
    };
    return (
        <Tooltip
            enterNextDelay={delayTime}
            title="UPCOMING FEATURE: Choose one or more positions to show only the universities where graduates hold those positions."
            placement="top"
        >
            <TextField
                margin="normal"
                size="small"
                label="Job positions"
                variant="outlined"
                color="secondary"
                select
                SelectProps={{
                    value: positionsName,
                    onChange: changePositions,
                    multiple: true,
                    renderValue: (selected) => `${selected.join()} `,
                }}
                fullWidth
                disabled
            >
                {positions.map((name) => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={positionsName.indexOf(name) > -1} color="primary" />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </TextField>
        </Tooltip>
    );
}
