import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadLogoApi } from "../../services/api/organization/logo";

export const uploadLogo = createAsyncThunk("organizations/uploadLogo", async (logo) => {
    const bodyFormData = new FormData();
    bodyFormData.append("picture", logo);

    const { data } = await uploadLogoApi(bodyFormData);
    return { data };
});
