import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getUsersApi, getUsersWithoutOrganizations } from "../../../services/api/Admin/users";

const useStyles = makeStyles(() => ({
    paper: {
        background: "white",
        color: "black",
    },
    asterisk: {
        color: "red",
    },
}));

export default function UsersAutocomplete({
    label,
    defaultValue,
    onSelected,
    color = "primary",
    disabled = false,
    variant = "allUsers",
    required,
    errors,
    autocompleteStyle,
    filterData,
}) {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const isPrimary = color === "primary";

    useEffect(async () => {
        if (variant === "allUsers") {
            const { data } = await getUsersApi({ name: "" });
            setUsers(data.records);
        }
        if (variant === "noOrganizationUsers") {
            const { data } = await getUsersWithoutOrganizations({ name: "" });
            const filteredData = filterData ? data.records.filter((el) => !filterData.includes(el.id)) : data.records;
            setUsers(filteredData);
        }
    }, []);

    const handleOnInputChange = async (e) => {
        if (!e) return;
        const value = e.target.value;
        if (variant === "allUsers") {
            const { data } = await getUsersApi({ name: value });
            setUsers(data.records);
        }
        if (variant === "noOrganizationUsers") {
            const { data } = await getUsersWithoutOrganizations({ name: value });
            const filteredData = filterData ? data.records.filter((el) => !filterData.includes(el.id)) : data.records;
            setUsers(filteredData);
        }
    };

    const handleOnChange = (e, userName) => {
        if (!userName) {
            onSelected(null);
            return;
        }

        const user = users.filter((item) => {
            const formatedName = `${item.first_name} ${item.last_name}`;
            return formatedName === userName;
        });

        if (onSelected) {
            onSelected(user);
        }
    };

    return (
        <Autocomplete
            options={users.map((user) => `${user.first_name} ${user.last_name}`)}
            fullWidth
            freeSolo
            forcePopupIcon
            value={defaultValue || ""}
            variant="outlined"
            size="small"
            disabled={disabled}
            color={color}
            renderInput={(params) => (
                <TextField
                    color={isPrimary ? "secondary" : "primary"}
                    {...params}
                    label={label}
                    error={Boolean(errors?.user)}
                    margin="dense"
                    className={classes.textfield}
                    InputLabelProps={{
                        required,
                        classes: {
                            asterisk: classes.asterisk,
                        },
                    }}
                />
            )}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={({ children }) => (
                <Paper
                    variant={isPrimary ? "autocompleteDropdown" : "elevation"}
                    elevation={isPrimary ? 1 : 0}
                    className={classes.paper}
                >
                    {children}
                </Paper>
            )}
            style={autocompleteStyle}
        />
    );
}
