import React from "react";
import { Typography, Stack } from "@mui/material/";
import { Circle, Square } from "@mui/icons-material";

export default function ChartLegend({ legend, type = "circle", legendStyle }) {
    return (
        <Stack direction="row" alignItems="center" gap={1} {...legendStyle}>
            {legend.map((item) => (
                <React.Fragment key={item.name}>
                    {type === "circle" ? (
                        <>
                            <Circle sx={{ color: item.color, fontSize: 13 }} />
                            <Typography variant="body3" color="textPrimary">
                                {item.name}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Square sx={{ color: item.color, fontSize: 13 }} />
                            <Typography variant="body3" color="textPrimary" textTransform="capitalize">
                                {item.name}
                            </Typography>
                        </>
                    )}
                </React.Fragment>
            ))}
        </Stack>
    );
}
