import React, { useEffect, useState } from "react";
import { MenuItem, Checkbox, TextField, Rating, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { updateFilter } from "../../../../../../../slices/Intelligence/UniversitiesSlice";

const useStyles = makeStyles((theme) => ({
    paper: {
        background: `${theme.palette.primary[600]} !important`,
    },
}));

export default function InternalRatingFilter({ iRating, setIRating }) {
    const classes = useStyles();
    const delayTime = 500;
    const dispatch = useDispatch();
    const iRatings = [0, 1, 2, 3, 4, 5];
    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const changeIRating = (event) => {
        const {
            target: { value },
        } = event;
        setIRating(typeof value === "string" ? value.split(",") : value);
    };

    useEffect(() => {
        dispatch(updateFilter({ internal_rating: iRating }));
    }, [iRating]);

    return (
        <Tooltip
            open={!isFocused && isHovered}
            enterNextDelay={delayTime}
            title="Choose one or more internal ratings to narrow down the displayed universities."
            placement="top"
        >
            <TextField
                margin="normal"
                size="small"
                label="Internal rating"
                variant="outlined"
                select
                color="secondary"
                SelectProps={{
                    onClose: () =>
                        setTimeout(() => {
                            document.activeElement.blur();
                        }, 0),
                    value: isFocused ? iRating : [],
                    onChange: changeIRating,
                    multiple: true,
                    renderValue: () => [],

                    MenuProps: {
                        PaperProps: {
                            className: classes.paper,
                        },
                    },
                }}
                fullWidth
                onMouseOver={(e) => {
                    if (e.target.classList.contains("MuiSelect-select")) {
                        setIsHovered(true);
                    } else {
                        setIsHovered(false);
                    }
                }}
                onMouseLeave={() => setIsHovered(false)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                    setIsFocused(false);
                    setIsHovered(false);
                }}
            >
                {iRatings.map((value) => (
                    <MenuItem key={value} value={value}>
                        <Checkbox checked={iRating.indexOf(value) > -1} color="secondary" />
                        <Rating name="read-only" value={value} readOnly />
                    </MenuItem>
                ))}
            </TextField>
        </Tooltip>
    );
}
