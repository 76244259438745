import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, Paper, TableCell, Typography, Button, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Tune } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import { Title } from "../../Header/components/Title";
import { Header } from "../../Header";
import NoResult from "../../NoResult";
import OsterusLoader from "../../Loader";
import CompanyDataRequestModal from "../Stacks/CompanyDataRequestModal";
import {
    clearState,
    requestedCompaniesSliceSelectors,
    updateFilter,
} from "../../../slices/Stacks/requestedCompaniesSlice";
import { getRequestedCompanies } from "../../../actions/Company/Data/request";
import { Container } from "../../Container";
import List from "./List";
import Filter from "./Filter";
import PaginationComponent from "../../Pagination";
import FilterChips from "../../FilterChips";
import { userSliceSelectors } from "../../../slices/User/userSlice";
import { formatUserFirstAndLastName } from "../../Settings/Users/utils";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    table: {
        minWidth: 650,
        borderCollapse: "separate",
    },
    chipSpacing: {
        margin: theme.spacing(0.5),
    },
    tableIconSpacing: {
        marginRight: theme.spacing(2),
    },
    tableSpacing: {
        padding: theme.spacing(2),
        paddingTop: 0,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    stackInsightsButton: {
        width: theme.spacing(25),
        height: theme.spacing(5.5),
    },
    loader: {
        display: "flex",
        minHeight: 473,
        justifyContent: "center",
        alignItems: "center",
    },

    tableHead: {
        position: "sticky",
        top: 0,
        background: "white",
        height: 64,
    },
    tableHeadRow: {
        height: 64,
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
    icon: {
        width: 20,
        height: 20,
    },
    buttonInsights: {
        width: 220,
    },
    dropDownIcon: {
        color: theme.palette.text.secondary,
        cursor: "pointer",
    },
}));

const breadcrumbItems = {
    breadcrumbs: [
        {
            text: "Stacks",
            color: "textSecondary",
            link: null,
            icon: null,
        },
        {
            text: "Stack Insights - Requested Companies",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function RequestedCompanies() {
    const requestedCompanies = useSelector(requestedCompaniesSliceSelectors.requestedCompaniesList);
    const organizations = useSelector(userSliceSelectors.organizations);
    const loading = useSelector(requestedCompaniesSliceSelectors.loading);
    const filter = useSelector(requestedCompaniesSliceSelectors.getFilter);
    const noResult = filter.name !== "" && requestedCompanies.totalRecords === 0 && !loading;
    const [modalOpen, setModalOpen] = useState(false);
    const [isTitleMenuOpen, setIsTitleMenuOpen] = useState(false);
    const [titleMenuAnchorEl, setTitleMenuAnchorEl] = useState(null);
    const [showRow, setShowRow] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    // gather all filter data into one array
    const filterData = useMemo(() => {
        let filterArray = [];

        if (filter.status?.length > 0) {
            filterArray = [...filter.status];
        }
        if (filter.user?.length > 0) {
            filterArray = [
                ...filterArray,
                ...filter.user.map((el) => ({ id: el.id, name: formatUserFirstAndLastName(el, true) })),
            ];
        }

        return filterArray;
    }, [filter]);

    useEffect(() => dispatch(clearState()), []);

    useEffect(() => {
        if (organizations?.length > 0) {
            const orgId = organizations[0].id;
            const params =
                filter.name || filter.status?.length > 0 || filter.user?.length > 0
                    ? filter
                    : { start: filter.start, limit: filter.limit };
            dispatch(getRequestedCompanies({ orgId, filter: params }));
        }
    }, [filter, organizations]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleTitleMenuClicked = (event) => {
        if (isTitleMenuOpen) {
            setTitleMenuAnchorEl(null);
            setIsTitleMenuOpen(false);
        } else {
            setTitleMenuAnchorEl(event.currentTarget);
            setIsTitleMenuOpen(true);
        }
    };

    const onPageChange = (page) => {
        dispatch(updateFilter({ ...filter, start: page }));
    };

    const handleRemoveFilterItem = (filterItem) => {
        if (filterItem.name === "Done" || filterItem.name === "In Progress") {
            const modifiedFilter = filter?.status.filter((status) => status.id !== filterItem.id);
            dispatch(updateFilter({ ...filter, status: modifiedFilter }));
        } else {
            const modifiedFilter = filter?.user.filter((user) => user.id !== filterItem.id);
            dispatch(updateFilter({ ...filter, user: modifiedFilter }));
        }
    };

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Container>
                    <Header>
                        <Grid container>
                            <Grid item xs={5}>
                                <Grid
                                    container
                                    alignItems="center"
                                    onClick={handleTitleMenuClicked}
                                    sx={{ width: "max-content" }}
                                >
                                    <Title value="Stack Insights - Requested Companies" />
                                    <ArrowDropDownIcon className={classes.dropDownIcon} />
                                </Grid>
                                <Menu
                                    sx={{ mt: 0.5, ml: -1 }}
                                    anchorEl={titleMenuAnchorEl}
                                    open={isTitleMenuOpen}
                                    onClose={handleTitleMenuClicked}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <MenuItem sx={{ width: 200 }} onClick={() => navigate("/the-lab/stacks")}>
                                        Active Stacks
                                    </MenuItem>
                                </Menu>
                                <Breadcrumbs components={breadcrumbItems} />
                            </Grid>
                            <Grid item xs={7} container alignItems="center" justifyContent="flex-end">
                                <Box pr={2}>
                                    <Button size="large" variant="vip" onClick={() => setModalOpen(true)}>
                                        Request Company Data
                                    </Button>
                                </Box>
                                <IconButton size="large">
                                    <Tune className={classes.icon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Header>
                    <FilterChips
                        showRow={showRow}
                        setShowRow={setShowRow}
                        filterData={filterData}
                        onRemoveFilterItem={handleRemoveFilterItem}
                    />
                </Container>
                <Box m={3} mt={0}>
                    {noResult ? (
                        <NoResult />
                    ) : (
                        <Paper elevation={1}>
                            <Box m={1}>
                                <TableContainer
                                    className={classes.tableSpacing}
                                    sx={{
                                        maxHeight: filterData.length ? "calc(100vh - 310px)" : "calc(100vh - 255px)",
                                    }}
                                >
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead className={classes.tableHead}>
                                            <TableRow className={classes.tableHeadRow}>
                                                <TableCell>
                                                    <Typography variant="body3" color="primary">
                                                        Name
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body3" color="primary">
                                                        Status
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body3" color="primary">
                                                        Request Date
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body3" color="primary">
                                                        Requested by
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body3" color="primary">
                                                        Email
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{!loading && <List />}</TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {loading ? (
                                <Box className={classes.loader}>
                                    <OsterusLoader />
                                </Box>
                            ) : null}
                        </Paper>
                    )}
                    {requestedCompanies?.totalRecords > 0 && (
                        <PaginationComponent
                            onChange={onPageChange}
                            page={filter.start}
                            limit={filter.limit}
                            totalRecords={requestedCompanies.totalRecords}
                        />
                    )}
                </Box>
            </Grid>
            <CompanyDataRequestModal open={modalOpen} handleClose={handleCloseModal} />
        </Grid>
    );
}
