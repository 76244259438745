import { ROLE_ADMIN, ROLE_MANAGER, ROLE_SUPER_ADMIN } from "../utils";

export const USER_ROLE_OPTIONS = [
    {
        value: ROLE_SUPER_ADMIN,
        label: "Super Admin",
    },
    {
        value: ROLE_ADMIN,
        label: "Admin",
    },
    {
        value: ROLE_MANAGER,
        label: "Manager",
    },
];

export const USER_STATUS_OPTIONS = {
    active: "Activate",
    deactivated: "Deactivate",
};
