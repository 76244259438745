import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteModalOpen, setUpdateUser, userSliceSelectors } from "../../../../../slices/User/userSlice";
import { deleteUser, getUsers } from "../../../../../actions/user/user";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialogActions: {
        marginTop: 10,
    },
}));

export default function DeleteUserModal() {
    const { id } = useSelector(userSliceSelectors.updateUser) || {};
    const open = useSelector(userSliceSelectors.deleteModalOpen);
    const filter = useSelector(userSliceSelectors.filter);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(setDeleteModalOpen(false));
        dispatch(setUpdateUser(null));
    };

    const handleDelete = () => {
        const onDone = () => {
            dispatch(getUsers(filter));
            handleClose();
        };
        dispatch(deleteUser({ id, onDone }));
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <div className={classes.container}>
                <DialogTitle>Delete User</DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                    <Typography variant="body2" color="textPrimary">
                        Deleting this user cannot be undone and you will lose all of it's account data and will not able
                        to revoke his access using the same email address.
                    </Typography>
                    <br />
                    <Typography variant="body2" color="textPrimary" display="inline">
                        Are you sure you want to permanently delete this user?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button color="primary" variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}
