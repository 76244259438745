import React, { useState, useEffect } from "react";
import { Paper, Stack, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MapChart from "../../../Charts/MapChart";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";

const useStyles = makeStyles(() => ({
    paper: {
        width: "100%",
        height: "100%",
    },
    title: {
        cursor: "pointer",
    },
}));

const percentageOfEmployeeLocationTitle = {
    position: "relative",
    marginTop: -30,
    left: 36,
    top: 40,
    fontSize: 16,
};

export function Section3({ data, stacks }) {
    const classes = useStyles();
    const [formatedData, setFormatedData] = useState([]);

    const employeesRelocationCountry = [
        {
            name: stacks[0]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCountry.stack.series.data,
        },
    ];

    const employeesRelocationCity = [
        {
            name: stacks[0]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCity.stack.series.data,
        },
    ];

    const fetchData = () => {
        if (data.topEmployeesLocations) {
            const newData = [
                data.topEmployeesLocations.stack
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 0, stackname: stacks[0]?.stack?.name, title: item.place })),
            ];
            const formattedLong = newData.map((item) => item.map((el) => ({ ...el, long: el.lng })));
            setFormatedData(formattedLong);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data]);

    return (
        <Stack direction="row" height={553} spacing={1} my={1}>
            <Stack flex={2}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height="100%" position="relative">
                        <MapChart
                            variant="anychart"
                            id="TestAnychart"
                            data={formatedData}
                            stacks={stacks}
                            title="Employee Locations"
                            actionsOffset={{ y: -95, x: 40 }}
                        />
                    </Stack>
                </Paper>
            </Stack>
            <Stack flex={1}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height={60} pl={1.25} pt={1.25}>
                        <Tooltip title="Percentage of Employees Location Change after University">
                            <Typography variant="h5" color="textSecondary" className={classes.title}>
                                Employees Location Change after University
                            </Typography>
                        </Tooltip>
                    </Stack>
                    <Stack width="100%" height={490} pb={2}>
                        <Stack width="100%" height="100%" flex={1}>
                            <StandardVerticalBarChart
                                variant="normalValue"
                                title="Country"
                                shortTitle="Country"
                                categories={
                                    data.percentageOfEmployeesLocationChangeAfterUniversityPerCountry.categories
                                }
                                series={employeesRelocationCountry}
                                colors={{ defaultColors: ["#afbff2", "#A3E0C1"] }}
                                color={null}
                                elevation={0}
                                titleTypographyStyle={percentageOfEmployeeLocationTitle}
                                showLegend={Boolean(false)}
                                barWidth="20%"
                            />
                        </Stack>
                        <Stack width="100%" height="100%" flex={1}>
                            <StandardVerticalBarChart
                                variant="normalValue"
                                title="City"
                                shortTitle="City"
                                categories={data.percentageOfEmployeesLocationChangeAfterUniversityPerCity.categories}
                                series={employeesRelocationCity}
                                colors={{ defaultColors: ["#afbff2", "#A3E0C1"] }}
                                color={null}
                                elevation={0}
                                titleTypographyStyle={percentageOfEmployeeLocationTitle}
                                showLegend={Boolean(false)}
                                barWidth="20%"
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
