import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, IconButton, Breadcrumbs, Link, Divider, Tooltip } from "@mui/material";
import { Apps, FormatListBulleted, VerticalAlignBottom } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { isNumber } from "lodash";
import { universitiesSelectors, updateFilter } from "../../../../../slices/Intelligence/UniversitiesSlice";
import FilterChips from "../../../../FilterChips";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    chips: {
        paddingTop: theme.spacing(2.375),
    },
    chip: {
        marginRight: theme.spacing(2),
    },
    breadcrumbDivider: {
        marginTop: theme.spacing(1.25),
    },
    divider: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        height: "16px",
    },
}));

export default function Header({ showRow, setShowRow, university, iRating, setIRating }) {
    const delayTime = 500;
    const universitiesFilter = useSelector(universitiesSelectors.filter);
    const dispatch = useDispatch();
    const classes = useStyles();

    // gather all filter data into one array
    const filterData = useMemo(() => {
        let filterArray = [];

        if (universitiesFilter.countries?.length > 0) {
            filterArray = [...universitiesFilter.countries];
        }
        if (universitiesFilter.cities?.length > 0) {
            filterArray = [...filterArray, ...universitiesFilter.cities];
        }
        if (universitiesFilter.internal_rating?.length > 0) {
            filterArray = [...filterArray, ...universitiesFilter.internal_rating];
        }

        return filterArray;
    }, [universitiesFilter]);

    const handleRemoveFilterItem = (filterItem) => {
        if (filterItem.country) {
            const cities = universitiesFilter.cities.filter((city) => city.id !== filterItem.id);
            dispatch(updateFilter({ cities }));
        } else if (filterItem.iso3) {
            const countries = universitiesFilter.countries.filter((country) => country.iso3 !== filterItem.iso3);
            dispatch(updateFilter({ countries }));
        } else if (isNumber(filterItem)) {
            const internal_rating = universitiesFilter.internal_rating.filter((rating) => rating !== filterItem);
            dispatch(updateFilter({ internal_rating }));
            setIRating(internal_rating);
        }
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={4}>
                    <Typography variant="h5" color="primary">
                        Universities
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="textSecondary" underline="none">
                            <Typography color="textSecondary" variant="body3">
                                Intelligence
                            </Typography>
                        </Link>
                        <Link color="inherit" underline="none">
                            <Typography color="secondary" variant="body3">
                                Universities
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
                    {/* <Typography>Sort By: Country Name A-Z</Typography> */}
                    {/* <IconButton> */}
                    {/*    <ArrowDropDown /> */}
                    {/* </IconButton> */}
                </Grid>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
                    <Tooltip
                        enterNextDelay={delayTime}
                        title="Choose the List View to show the displayed universities in a list view. Click on a university to show additional information."
                        placement="top"
                    >
                        <IconButton size="large">
                            <FormatListBulleted color="primary" />
                        </IconButton>
                    </Tooltip>
                    <IconButton size="large">
                        <Tooltip
                            enterNextDelay={delayTime}
                            title="Choose the Table View to show the displayed universities in a table view. Use the additional options to customize the table view and export data."
                            placement="top"
                        >
                            <Apps />
                        </Tooltip>
                    </IconButton>
                    {!university && (
                        <>
                            <Divider orientation="vertical" className={classes.divider} />
                            <Tooltip
                                enterNextDelay={delayTime}
                                title="UPCOMING FEATURE: Download the data shown on the page in a .pdf file."
                                placement="top"
                            >
                                <IconButton size="large">
                                    <VerticalAlignBottom />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </Grid>
            </Grid>
            <Divider className={classes.breadcrumbDivider} />
            <FilterChips
                showRow={showRow}
                setShowRow={setShowRow}
                filterData={filterData}
                onRemoveFilterItem={handleRemoveFilterItem}
                iRating={iRating}
                setIRating={setIRating}
            />
        </div>
    );
}
