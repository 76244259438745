import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, Button, Divider, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import Table from "../../Table/Table";
import Filter from "./components/Filter";
import PaginationComponent from "../../Pagination";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import {
    adminSliceSelectors,
    setStacksFilter,
    setActiveStack,
    clearActiveStack,
} from "../../../slices/Admin/adminSlice";
import { getStacks } from "../../../actions/Admin/Stacks";
import { deleteStackApi } from "../../../services/api/Admin/stacks";
import OsterusLoader from "../../Loader";
import { COLUMN_TYPES } from "../../Table/columnTypes";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Stacks",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Stacks",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function AdminStacksComponent() {
    const filter = useSelector(adminSliceSelectors.stacksFilter);
    const totalRecords = useSelector(adminSliceSelectors.stacksTotalRecords);
    const data = useSelector(adminSliceSelectors.stacksData);
    const loading = useSelector(adminSliceSelectors.stacksLoading);
    const organization = useSelector(adminSliceSelectors.stacksActiveStack).organization;
    const [dataSlice, setDataSlice] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        setDataSlice(data.slice(0, Math.min(filter.limit, data.length)));
        setStacksFilter({ ...filter, page: 1 });
    }, [data]);

    const handlePageChanged = (pageNum, start, end) => {
        dispatch(setStacksFilter({ ...filter, page: pageNum }));
        dispatch(getStacks({ organization_id: organization.id, page: pageNum }));
        setDataSlice(data.slice(start, end));
    };

    const onCreateStackClicked = () => {
        navigate("/admin/stacks/create");
    };

    const onStackEdit = (index) => {
        const stackData = data[index];

        dispatch(
            setActiveStack({
                id: stackData.id,
                name: stackData.name,
                color: stackData.color,
                company: { id: stackData.company_id, name: "" },
                tags: stackData.tags,
            }),
        );
        navigate("/admin/stacks/edit");
    };

    const onStackDelete = async (index) => {
        const stack_id = data[index].id;
        const org = { ...organization };
        await deleteStackApi(org.id, stack_id);

        dispatch(clearActiveStack());
        dispatch(setActiveStack({ organization: org }));

        dispatch(getStacks({ organization_id: org.id }));
        dispatch(setStacksFilter({ ...filter, page: 1 }));
    };

    const columns = useMemo(
        () => [
            { name: "Name", align: "left" },
            { name: "Size Category" },
            { name: "Analyzed Profiles" },
            { name: "Organization" },
            { name: "Tags", type: COLUMN_TYPES.TAGS },
        ],
        [],
    );

    const rows = useMemo(
        () =>
            dataSlice.map((item, index) => {
                const actions = [
                    { icon: <EditIcon />, tooltip: "Edit Stack", callback: () => onStackEdit(index) },
                    { icon: <DeleteIcon />, tooltip: "Delete Stack", callback: () => onStackDelete(index) },
                ];
                return {
                    data: [item.name, item.size, item.number_of_employees, organization.full_name, item.tags],
                    actions,
                };
            }),
        [dataSlice],
    );

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Stack px={3} pt={2}>
                    <Stack mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    onClick={onCreateStackClicked}
                                >
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                    {loading ? (
                        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                            <OsterusLoader />
                        </Stack>
                    ) : (
                        <>
                            {totalRecords !== null && totalRecords > 0 ? (
                                <>
                                    <Table columns={columns} rows={rows} />
                                    <PaginationComponent
                                        page={filter.page}
                                        totalRecords={totalRecords}
                                        limit={filter.limit}
                                        onChange={handlePageChanged}
                                    />
                                </>
                            ) : totalRecords === 0 ? (
                                <Table columns={columns} rows={rows} />
                            ) : (
                                <Typography variant="h6" color="primary">
                                    Please select an organization
                                </Typography>
                            )}
                        </>
                    )}
                </Stack>
            </Grid>
        </Grid>
    );
}
