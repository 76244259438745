import React, { useEffect, useState, useMemo } from "react";
import { debounce } from "debounce";
import Flag from "react-country-flag";
import {
    Grid,
    Paper,
    Typography,
    TextField,
    IconButton,
    Tooltip,
    InputAdornment,
    Box,
    Autocomplete,
} from "@mui/material";
import { useSelector } from "react-redux";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isEmpty } from "lodash";
import { makeStyles } from "@mui/styles";
import { useLocation } from "@gatsbyjs/reach-router";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import * as CityService from "../../../services/api/City";
import { qualityOfLifeSelectors } from "../../../slices/Intelligence/QualityOfLifeSlice";
import { costOfLivingSelectors } from "../../../slices/Intelligence/CostOfLivingSlice";

const useStyles = makeStyles((theme) => ({
    countryPaper: {
        display: "flex",
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
    },
    currentCityAutocomplete: {
        marginTop: 3,
        paddingLeft: 14,
        paddingTop: 5,
        paddingRight: 10,
        width: 260,
        height: 40,
        border: ({ currentCityBorder }) => currentCityBorder,
        borderRadius: 4,
    },
    comparisonCityAutocomplete: {
        marginTop: 3,
        paddingLeft: 14,
        paddingTop: 5,
        paddingRight: 10,
        width: 260,
        height: 40,
        border: ({ comparisonCityBorder }) => comparisonCityBorder,
        borderRadius: 4,
    },
    syncAlt: {
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        width: 48,
        height: 48,
    },
    autocompleteFlagContainer: {
        display: "flex",
        width: 20,
        height: 14,
        overflow: "hidden",
    },
    autocompleteTypographyContainer: {
        display: "flex",
        width: "85%",
        paddingLeft: 10,
    },
    textField: {
        fontSize: 20,
        fontWeight: 500,
    },
    downloadIconGrid: {
        textAlign: "right",
    },
}));

const flags = {
    width: 20,
    height: 12,
};

export default function CitySelect({
    defaultCurrentCity,
    defaultComparisonCity,
    onCitiesSelected,
    currentCityError,
    comparisonCityError,
    breadcrumbComponents,
}) {
    const delayTime = 500;
    const location = useLocation();
    const isQualityOfLifeLoading = useSelector(qualityOfLifeSelectors.loading);
    const isCostOfLivingLoading = useSelector(costOfLivingSelectors.loading);
    const isLoading = isCostOfLivingLoading || isQualityOfLifeLoading;
    const [currentCityTextFieldValue, setCurrentCityTextFieldValue] = useState("");
    const [comparisonCityTextFieldValue, setComparisonCityTextFieldValue] = useState("");
    const [isHoverCurrentCity, setHoverCurrentCity] = useState(false);
    const [isFocusCurrentCity, setFocusCurrentCity] = useState(false);
    const showTooltipCurrentCity = isHoverCurrentCity && !isFocusCurrentCity;
    const [isHoverComparisonCity, setHoverComparisonCity] = useState(false);
    const [isFocusComparisonCity, setFocusComparisonCity] = useState(false);
    const showTooltipComparisonCity = isHoverComparisonCity && !isFocusComparisonCity;
    const [currentCity, setCurrentCity] = useState(defaultCurrentCity || {});
    const [comparisonCity, setComparisonCity] = useState(defaultComparisonCity || {});
    const [optionsCurrentCity, setOptionsCurrentCity] = useState([]);
    const [currentCityOptionsText, setCurrentCityOptionsText] = useState(null);
    const [optionsComparisonCity, setOptionsComparisonCity] = useState([]);
    const [comparisonCityOptionsText, setComparisonCityOptionsText] = useState(null);
    const [showCurrentCityError, setShowCurrentCityError] = useState(currentCityError);
    const [showComparisonCityError, setShowComparisonCityError] = useState(comparisonCityError);
    const props = {
        currentCityBorder: showCurrentCityError ? "1px solid red" : "none",
        comparisonCityBorder: showComparisonCityError ? "1px solid red" : "none",
    };
    const classes = useStyles(props);

    const handleOnChanged = (current, comparison) => {
        if (current !== currentCity) setCurrentCity(current);
        if (comparison !== comparisonCity) setComparisonCity(comparison);
        if (current && comparison && current.id && comparison.id) {
            onCitiesSelected(current, comparison);
        }
    };

    const getCities = useMemo(
        () =>
            debounce(async (currentValue, isCurrentCity) => {
                const options = await CityService.getAllByFilter({ q: currentValue });
                if (isCurrentCity) {
                    setOptionsCurrentCity(options.data.records);
                    setCurrentCityOptionsText("No options found");
                } else {
                    setOptionsComparisonCity(options.data.records);
                    setComparisonCityOptionsText("No options found");
                }
            }, 300),
        [],
    );

    useEffect(() => {
        setCurrentCity(defaultCurrentCity || {});
        setComparisonCity(defaultComparisonCity || {});
    }, [defaultCurrentCity, defaultComparisonCity]);

    useEffect(() => {
        if (showCurrentCityError !== currentCityError) {
            setShowCurrentCityError(currentCityError);
        }
        if (showComparisonCityError !== comparisonCityError) {
            setShowComparisonCityError(comparisonCityError);
        }
    }, [currentCityError, comparisonCityError]);

    const swapCities = () => {
        handleOnChanged(comparisonCity, currentCity);
    };

    const page = location.pathname === "/intelligence/cost-of-living" ? "Cost of Living" : "Quality of Life";
    const noSelectedCities = isEmpty(currentCity) || isEmpty(comparisonCity);
    const isSwitchButtonDisabled = noSelectedCities || isCostOfLivingLoading || isQualityOfLifeLoading;

    return (
        <Grid container>
            <Paper elevation={0} className={classes.countryPaper} variant="header">
                <Grid item xs={3}>
                    <Breadcrumbs components={breadcrumbComponents} />
                </Grid>
                <Grid item xs={6} container justifyContent="center">
                    <Tooltip
                        open={showTooltipCurrentCity}
                        enterNextDelay={delayTime}
                        title={`Search and select cities in both fields to start comparing the ${page}.`}
                        placement="top"
                    >
                        <Autocomplete
                            disabled={isLoading}
                            options={
                                optionsCurrentCity.filter((item) => item.id !== comparisonCity.id) || optionsCurrentCity
                            }
                            PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                            size="small"
                            variant="outlined"
                            disableClearable
                            blurOnSelect
                            popupIcon={<ArrowDropDownIcon />}
                            noOptionsText={currentCityOptionsText}
                            includeInputInList
                            value={currentCity}
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0
                                    ? `${option.name}, ${option.country.name}`
                                    : currentCityTextFieldValue
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderOption={(optionProps, option) => (
                                <li {...optionProps} translate="no">
                                    <Box className={classes.autocompleteFlagContainer}>
                                        <Flag countryCode={option.country.iso2} svg style={flags} />
                                    </Box>
                                    <Box className={classes.autocompleteTypographyContainer}>
                                        <Typography>
                                            {option.name}, {option.country.name}
                                        </Typography>
                                    </Box>
                                </li>
                            )}
                            onChange={(changeEvent, currentValue) => {
                                if (currentValue !== null) {
                                    handleOnChanged(currentValue, comparisonCity);
                                } else {
                                    setCurrentCity({});
                                }
                                setCurrentCityOptionsText(null);
                            }}
                            onInputChange={async (changeEvent, currentValue) => {
                                const values = currentValue.split(",");
                                if (values.length === 2) {
                                    // eslint-disable-next-line no-param-reassign
                                    currentValue = values[0];
                                }
                                if (currentValue.length < currentCity?.name?.length) {
                                    setCurrentCity({});
                                }
                                if (currentValue.length < 2) {
                                    setCurrentCityOptionsText(null);
                                    setOptionsCurrentCity([]);
                                } else {
                                    getCities(currentValue, true);
                                }
                                setCurrentCityTextFieldValue(currentValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    onFocus={() => setFocusCurrentCity(true)}
                                    onBlur={() => setFocusCurrentCity(false)}
                                    onMouseOver={() => {
                                        if (!isFocusCurrentCity) {
                                            setHoverCurrentCity(true);
                                        }
                                    }}
                                    onMouseLeave={() => setHoverCurrentCity(false)}
                                    {...params}
                                    variant="standard"
                                    placeholder="Select city"
                                    onClick={() => setShowCurrentCityError(false)}
                                    InputProps={{
                                        classes: {
                                            input: classes.textField,
                                        },
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currentCity.name && (
                                                    <Box className={classes.autocompleteFlagContainer}>
                                                        <Flag
                                                            countryCode={currentCity?.country?.iso2}
                                                            svg
                                                            style={flags}
                                                        />
                                                    </Box>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            className={classes.currentCityAutocomplete}
                        />
                    </Tooltip>
                    <IconButton
                        onClick={swapCities}
                        size="large"
                        className={classes.syncALt}
                        disabled={isSwitchButtonDisabled}
                    >
                        <SyncAltIcon />
                    </IconButton>
                    <Tooltip
                        open={showTooltipComparisonCity}
                        enterNextDelay={delayTime}
                        title={`Search and select cities in both fields to start comparing the ${page}.`}
                        placement="top"
                    >
                        <Autocomplete
                            disabled={isLoading}
                            options={
                                optionsComparisonCity.filter((item) => item.id !== currentCity.id) ||
                                optionsComparisonCity
                            }
                            PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                            variant="outlined"
                            size="small"
                            disableClearable
                            blurOnSelect
                            popupIcon={<ArrowDropDownIcon />}
                            noOptionsText={comparisonCityOptionsText}
                            includeInputInList
                            value={comparisonCity}
                            getOptionLabel={(option) =>
                                Object.keys(option).length > 0
                                    ? `${option.name}, ${option.country.name}`
                                    : comparisonCityTextFieldValue
                            }
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderOption={(optionProps, option) => (
                                <li {...optionProps} translate="no">
                                    <Box className={classes.autocompleteFlagContainer}>
                                        <Flag countryCode={option.country.iso2} svg style={flags} />
                                    </Box>
                                    <Box className={classes.autocompleteTypographyContainer}>
                                        <Typography>
                                            {option.name}, {option.country.name}
                                        </Typography>
                                    </Box>
                                </li>
                            )}
                            onChange={(changeEvent, currentValue) => {
                                if (currentValue !== null) {
                                    handleOnChanged(currentCity, currentValue);
                                } else {
                                    setComparisonCity({});
                                }
                                setComparisonCityOptionsText(null);
                            }}
                            onInputChange={async (changeEvent, currentValue) => {
                                const values = currentValue.split(",");
                                if (values.length === 2) {
                                    // eslint-disable-next-line no-param-reassign
                                    currentValue = values[0];
                                }
                                if (currentValue.length < comparisonCity?.name?.length) {
                                    setComparisonCity({});
                                }
                                if (currentValue.length < 2) {
                                    setComparisonCityOptionsText(null);
                                    setOptionsComparisonCity([]);
                                } else {
                                    getCities(currentValue, false);
                                }
                                setComparisonCityTextFieldValue(currentValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    onFocus={() => setFocusComparisonCity(true)}
                                    onBlur={() => setFocusComparisonCity(false)}
                                    onMouseOver={() => {
                                        if (!isFocusComparisonCity) {
                                            setHoverComparisonCity(true);
                                        }
                                    }}
                                    onMouseLeave={() => setHoverComparisonCity(false)}
                                    {...params}
                                    variant="standard"
                                    placeholder="Select city"
                                    onClick={() => setShowComparisonCityError(false)}
                                    InputProps={{
                                        classes: {
                                            input: classes.textField,
                                        },
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {comparisonCity.name && (
                                                    <Box className={classes.autocompleteFlagContainer}>
                                                        <Flag
                                                            countryCode={comparisonCity?.country?.iso2}
                                                            svg
                                                            style={flags}
                                                        />
                                                    </Box>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            className={classes.comparisonCityAutocomplete}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={3} className={classes.downloadIconGrid}>
                    <Tooltip
                        enterNextDelay={delayTime}
                        title="UPCOMING FEATURE: Download the data shown on the page in a .pdf file."
                        placement="top"
                    >
                        <IconButton size="large">
                            <VerticalAlignBottomIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Paper>
        </Grid>
    );
}
