import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    updateGeneralSettings,
    updatePreferenceSettings,
    updateSecuritySettings,
} from "../../services/api/user/settings";

export const updateSettingsGeneral = createAsyncThunk(
    "users/updateSettingsGeneral",
    async ({ id, userData, onDone }) => {
        try {
            const { data } = await updateGeneralSettings(id, userData);
            if (onDone) {
                onDone();
            }
            return data;
        } catch (error) {
            throw error;
        }
    },
);

export const updateSettingsSecurity = createAsyncThunk(
    "users/updateSettingsSecurity",
    async ({ id, userData, onDone }, { rejectWithValue }) => {
        try {
            const { data } = await updateSecuritySettings(id, userData);
            if (onDone) {
                onDone();
            }
            return data;
        } catch (err) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    },
);

export const updateSettingsPreference = createAsyncThunk(
    "users/updateSettingsPreference",
    async ({ id, userData, onDone }, { rejectWithValue }) => {
        try {
            const { data } = await updatePreferenceSettings(id, userData);
            if (onDone) {
                onDone();
            }
            return data;
        } catch (err) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    },
);
