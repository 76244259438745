import React, { useEffect, useState, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import anychart from "anychart";
import { getTooltip, tooltipStyles } from "./Tooltip";
import { SpeedDialMenu } from "../ChartContainer/SpeedDialMenu";

const useStyles = makeStyles((theme) => ({
    chart: {
        width: "100%",
        height: "100%",
        // Hide div that shows "AnyChart trial version", for now.
        "& .anychart-credits": {
            display: "none",
        },
        "& .anychart-tooltip": {
            background: "transparent",
        },
    },
    ...tooltipStyles(theme),
}));

const maxZoomLevel = 4;
const expectedZoomLevels = [1, 1.25, 2, 2.5, 4, 5, 8, 10];

// TODO: Add stacks and colors props
export default function AnyChart({ title, id, data, stacks, actionsOffset }) {
    const chartRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState(maxZoomLevel);
    const [hovered, setHovered] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        let map;
        if (!chartRef.current) {
            map = anychart.map();
            map.geoData("anychart.maps.world").padding([30, 0, 10, 0]);

            map.unboundRegions().enabled(true).fill("#fdfcfd").stroke("#aaaaaa");
        } else {
            map = chartRef.current;
        }

        const createSeries = (name, seriesData, color) => {
            const series = map.marker(seriesData);
            series
                .name(name)
                .fill(color)
                .stroke("1 #E1E1E1")
                .type("circle")
                .size(5)
                .labels(false)
                .selectionMode("none");
            series.hovered().stroke("2 #fff").size(12);
            series.legendItem().iconType("circle").iconFill(color).iconStroke("2 #E1E1E1");
        };

        if (chartRef.current) {
            // TODO: Use stacks and colors props
            map.removeAllSeries();
            createSeries(stacks[0]?.stack?.name || "", data[0], data[1] ? "#588fcbbb" : "#5073E8");
            if (data[1]?.length) {
                createSeries(stacks[1]?.stack?.name || "", data[1], "#9ed6b1bb");
            }
            if (data[2]?.length) {
                createSeries(`${stacks[0]?.stack?.name}/${stacks[1]?.stack?.name}` || "", data[2], "#FFFF8F");
            }
        }

        if (!chartRef.current) {
            // turns on the legend for the sample
            map.legend().enabled(true).position("bottom").padding([10, 0, 20, 0]);
            /* eslint-disable */
            map.tooltip()
                .useHtml(true)
                .fontSize(12)
                .separator(false)
                .title(false)
                .fontColor("#e6e6e6")
                .format(function () {
                    if (this.getData("percent")) {
                        return getTooltip("v2", {
                            title: this.getData("title"),
                            city: this.getData("stackname"),
                            circleColorClass: "workforceLocationTooltipCircle2",
                            customOptions: { colorAxis: { colors: [data[1] ? "#588fcbbb" : "#5073E8", "#9ed6b1bb"] } },
                            percent: this.getData("percent"),
                            markerColor: this.getData("series"),
                        });
                    }
                    return getTooltip("v3", {
                        title: this.getData("title"),
                        city1: this.getData("stackname1"),
                        city2: this.getData("stackname2"),
                        circleColorClass: "workforceLocationTooltipCircle2",
                        percent1: this.getData("percent1"),
                        percent2: this.getData("percent2"),
                        colors: ["#588fcbbb", "#9ed6b1bb"],
                    });
                });
            /* eslint-enable */

            map.container(id);
            map.draw();

            // Save the reference, because you can't draw the map twice.
            // Second draw won't update the existing map, it will create a new one.
            chartRef.current = map;
        }
    }, [data]);

    const handleZoomIn = () => {
        const currentZoomLevel = chartRef?.current.getZoomLevel();
        if (currentZoomLevel && !expectedZoomLevels.includes(currentZoomLevel)) {
            return;
        }
        chartRef.current.zoom(2);
        setZoomLevel(Math.max(zoomLevel - 1, 0));
    };

    const handleZoomOut = () => {
        const currentZoomLevel = chartRef?.current.getZoomLevel();
        if (currentZoomLevel && !expectedZoomLevels.includes(currentZoomLevel)) {
            return;
        }
        chartRef.current.zoom(0.5);
        setZoomLevel(Math.min(zoomLevel + 1, maxZoomLevel));
    };

    return (
        <Stack width="100%" height="100%" onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Stack fullWidth alignItems="left" mb={2} pr={2} mt={1}>
                <Typography variant="h5" color="textSecondary" pt={2} pl={2}>
                    {title}
                </Typography>
            </Stack>
            <SpeedDialMenu
                loadedItems={zoomLevel}
                minLimit={0}
                maxLimit={4}
                open={hovered}
                enableZoom
                zoomIn={handleZoomIn}
                zoomOut={handleZoomOut}
                actionsOffset={actionsOffset}
            />
            <div id={id} className={classes.chart} />
        </Stack>
    );
}
