import React, { useMemo, useState, useEffect } from "react";
import { Paper, Typography, Stack, IconButton, Divider } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import MapChart from "../../../Charts/MapChart";
import StackedBarWithActions from "../../../Charts/StackedBarWithActions";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors } from "../../../utils";
import ComparisonBarChart from "../../../MixedCharts/ComparisonBarChart";
import { SpeedDialMenu } from "../../../Charts/ChartContainer/SpeedDialMenu";
import NoData from "../../../assets/noData.svg";

const useStyles = makeStyles(() => ({
    root: {
        padding: "0px 12px 12px 12px",
    },
    divider: {
        position: "absolute",
        width: 3,
        left: "50%",
        height: "107%",
        backgroundColor: "darkgrey",
    },
    universityRoot: {
        padding: "0px 12px 12px 12px",
        background: "#fff",
    },
}));

const categoriesStyle = {
    marginBottom: 8.75,
    paddingRight: 6,
    paddingLeft: 6,
    height: 16,
};

export function Section1({ data, stacks }) {
    const classes = useStyles();
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    const loadingInsights = useSelector(stackInsightsSliceSelector.loadingInsights);
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [showJobTitles, setShowJobTitles] = useState(false);
    const chartData = showJobTitles ? data.jobTitles : data.universities;
    const [chart2BarColors, setChart2BarColors] = useState(
        chartData.length
            ? getColors(
                  chartData[0].stack1.series.map((serie) => serie.name),
                  show,
                  split,
              )
            : [],
    );
    const title = showJobTitles ? "Top Job Titles/Top Universities" : "Top Universities/Top Job Titles";

    useEffect(() => {
        setChart2BarColors(
            chartData.length
                ? getColors(
                      chartData[0].stack1.series.map((serie) => serie.name),
                      show,
                      split,
                  )
                : [],
        );
    }, [show, split, chartData]);

    const switchStacks = () => {
        setShowJobTitles(!showJobTitles);
    };

    const maxValue = useMemo(() => {
        const maxValues = chartData.slice(0, 4).map((item) => {
            let max1 = 0;
            let max2 = 0;
            for (let i = 0; i < item.categories1.length; i += 1) {
                const rowSum = item.stack1.series.reduce((prev, cur) => prev + cur.data[i], 0);
                max1 = Math.max(max1, rowSum);
            }
            for (let i = 0; i < item.categories2.length; i += 1) {
                const rowSum = item.stack2.series.reduce((prev, cur) => prev + cur.data[i], 0);
                max2 = Math.max(max2, rowSum);
            }
            return Math.max(max1, max2);
        });
        return Math.max(...maxValues);
    }, [data, chartData, stacks]);

    const chartInfo = { title: "Top Universities", shortTitle: "Top Universities" };

    const comparisonChartStack1 = {
        series: data.topUniversitiesAttended.stack1.series.filter(
            (serie) => serie.name !== "Total" && serie.name !== "Unknown",
        ),
        categories: data.topUniversitiesAttended.categories1,
    };

    const comparisonChartStack2 = {
        series: data.topUniversitiesAttended.stack2.series.filter(
            (serie) => serie.name !== "Total" && serie.name !== "Unknown",
        ),
        categories: data.topUniversitiesAttended.categories2,
    };

    const [chart1BarColors, setChart1BarColors] = useState(
        getColors(
            comparisonChartStack1.series.map((serie) => serie.name),
            show,
            split,
        ),
    );

    useEffect(() => {
        setChart1BarColors(
            getColors(
                comparisonChartStack1.series.map((serie) => serie.name),
                show,
                split,
            ),
        );
    }, [show, split, data]);

    const [formatedData, setFormatedData] = useState([
        [
            {
                title: "Bard College Berlin",
                place: "Berlin, Germany",
                city: "Berlin, DE",
                percent: 44,
                series: 0,
                lat: 52.52000659999999,
                long: 13.404954,
            },
            {
                title: "Arts et Métiers ParisTech",
                place: "Paris, France",
                city: "Paris, FR",
                percent: 31,
                series: 0,
                lat: 48.856614,
                long: 2.3522219,
            },
            {
                title: "Pontifícia Universidade Católica do Rio de Janeiro",
                place: "Rio de Janeiro, Brazil",
                city: "Rio de Janeiro, BR",
                percent: 12,
                series: 0,
                lat: -22.9068467,
                long: -43.1728965,
            },
        ],
        [
            {
                title: "New York Institute of Technology",
                place: "Old Westbury, United States",
                city: "Old Westbury, US",
                percent: 40,
                series: 1,
                lat: 40.7887113,
                long: -73.5995717,
            },
            {
                title: "University of Cape Town",
                place: "Cape Town, South Africa",
                city: "Cape Town, ZA",
                percent: 36,
                series: 1,
                lat: -33.9248685,
                long: 18.4240553,
            },
            {
                title: "Georgia Institute of Technology",
                place: "Atlanta, United States",
                city: "Atlanta, US",
                cityId: 1840004026,
                percent: 4.37,
                lat: 45.0017,
                long: -84.1536,
            },
        ],
    ]);

    const fetchData = () => {
        if (data.universityLocation) {
            const newData = [
                data.universityLocation.stack1
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 0, stackname: stacks[0].stack.name })),
                data.universityLocation.stack2
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 1, stackname: stacks[1].stack.name })),
                data.universityLocation.commonData.slice(0, 30).map((item) => ({
                    ...item,
                    series: 1,
                    stackname1: stacks[0].stack.name,
                    stackname2: stacks[1].stack.name,
                })),
            ];
            const formattedLong = newData.map((item) => item.map((el) => ({ ...el, long: el.lng })));
            setFormatedData(formattedLong);
        }
    };

    useEffect(() => {
        try {
            fetchData();
        } catch (e) {
            // empty
        }
    }, [data]);

    const [leftPage, setLeftPage] = useState(0);
    const [rightPage, setRightPage] = useState(0);
    const [leftSideHovered, setLeftSideHovered] = useState(false);
    const [rightSideHovered, setRightSideHovered] = useState(false);

    useEffect(() => {
        setLeftPage(0);
        setRightPage(0);
    }, [stacks, split, show, showJobTitles]);

    return (
        <>
            <Stack mb={1}>
                <ComparisonBarChart
                    stacks={stacks}
                    stack1={comparisonChartStack1}
                    stack2={comparisonChartStack2}
                    chartInfo={chartInfo}
                    barColors={chart1BarColors}
                    percentFormat
                    itemsPerPage={10}
                    height={380}
                    distributed={split === SplitModes.none}
                    showLegend={false}
                    activeCategories={loadingInsights ? null : appliedFilters.universities}
                />
            </Stack>
            <Paper
                className={classes.root}
                elevation={1}
                onMouseLeave={() => {
                    setLeftSideHovered(false);
                    setRightSideHovered(false);
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    onMouseOver={() => {
                        setLeftSideHovered(false);
                        setRightSideHovered(false);
                    }}
                >
                    <IconButton onClick={switchStacks} size="large" className={classes.syncALt}>
                        <SyncAltIcon />
                    </IconButton>
                    <Typography variant="h5" color="textSecondary">
                        {title}
                    </Typography>
                </Stack>
                <Stack width="100%" direction="row" mb={1}>
                    <Stack flex={1} alignItems="center">
                        <Typography variant="h6" color="textSecondary">
                            {stacks[0].stack.name}
                        </Typography>
                    </Stack>
                    <Stack flex={1} alignItems="center">
                        <Typography variant="h6" color="textSecondary">
                            {stacks[1].stack.name}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack height={390} position="relative">
                    <Stack
                        position="absolute"
                        width="50%"
                        right="50%"
                        height={390}
                        onMouseOver={() => {
                            setLeftSideHovered(true);
                            setRightSideHovered(false);
                        }}
                        onMouseLeave={() => setLeftSideHovered(false)}
                    >
                        <SpeedDialMenu
                            nextPage={() => setLeftPage(leftPage + 1)}
                            previousPage={() => setLeftPage(leftPage - 1)}
                            page={leftPage}
                            lastPage={Math.ceil(chartData.length / 2)}
                            open={leftSideHovered && chartData.length}
                            actionsOffset={{ y: -10 }}
                        />
                    </Stack>
                    <Stack
                        position="absolute"
                        width="50%"
                        height={390}
                        right={10}
                        onMouseOver={() => {
                            setLeftSideHovered(false);
                            setRightSideHovered(true);
                        }}
                        onMouseLeave={() => setRightSideHovered(false)}
                    >
                        <SpeedDialMenu
                            nextPage={() => setRightPage(rightPage + 1)}
                            previousPage={() => setRightPage(rightPage - 1)}
                            page={rightPage}
                            lastPage={Math.ceil(chartData.length / 2)}
                            open={rightSideHovered && chartData.length}
                            actionsOffset={{ y: -10 }}
                        />
                    </Stack>
                    {chartData.length
                        ? [0, 0].map((_, index) => (
                              <>
                                  <Stack key={index} direction="row" width="100%" position="relative">
                                      <Stack height={190} width="100%" pl={1}>
                                          {chartData[leftPage * 2 + index] ? (
                                              <StackedBarWithActions
                                                  title={chartData[leftPage * 2 + index].stack1.name}
                                                  stackname={stacks[0].stack.name}
                                                  barColors={
                                                      split === SplitModes.none ? [chart2BarColors[0]] : chart2BarColors
                                                  }
                                                  categories={chartData[leftPage * 2 + index].categories1}
                                                  series={chartData[leftPage * 2 + index].stack1.series.filter(
                                                      (serie) => serie.name !== "Total",
                                                  )}
                                                  categoriesStyle={categoriesStyle}
                                                  maxValue={maxValue}
                                                  itemsPerPage={showJobTitles ? 4 : 5}
                                                  showJobTitles={showJobTitles}
                                                  isHovered={() => {
                                                      setRightSideHovered(false);
                                                      setLeftSideHovered(true);
                                                  }}
                                              />
                                          ) : undefined}
                                      </Stack>
                                      <Divider className={classes.divider} />
                                      <Stack height={190} width="100%" pl={0} pr={1}>
                                          {chartData[rightPage * 2 + index] ? (
                                              <StackedBarWithActions
                                                  title={chartData[rightPage * 2 + index].stack2.name}
                                                  stackname={stacks[1].stack.name}
                                                  barColors={
                                                      split === SplitModes.none ? [chart2BarColors[1]] : chart2BarColors
                                                  }
                                                  reversed
                                                  series={chartData[rightPage * 2 + index].stack2.series.filter(
                                                      (serie) => serie.name !== "Total",
                                                  )}
                                                  categories={chartData[rightPage * 2 + index].categories2}
                                                  categoriesStyle={categoriesStyle}
                                                  maxValue={maxValue}
                                                  itemsPerPage={showJobTitles ? 4 : 5}
                                                  showJobTitles={showJobTitles}
                                                  isHovered={() => {
                                                      setLeftSideHovered(false);
                                                      setRightSideHovered(true);
                                                  }}
                                              />
                                          ) : undefined}
                                      </Stack>
                                  </Stack>
                              </>
                          ))
                        : undefined}

                    <Stack direction="row" width="100%" position="relative">
                        <Stack height={190} width="100%" pl={1}>
                            {!chartData[leftPage] ? (
                                <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                                    <img src={NoData} alt="no-data" />
                                    <Typography variant="body2" color="textSecondary">
                                        No results match filter criteria
                                    </Typography>
                                </Stack>
                            ) : undefined}
                        </Stack>
                        <Stack height={190} width="100%" pl={0} pr={1}>
                            {!chartData[rightPage] ? (
                                <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                                    <img src={NoData} alt="no-data" />
                                    <Typography variant="body2" color="textSecondary">
                                        No results match filter criteria
                                    </Typography>
                                </Stack>
                            ) : undefined}
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>

            <Paper className={classes.universityRoot} elevation={1}>
                <Stack height={540} width="100%" position="relative" mt={1}>
                    <MapChart
                        variant="anychart"
                        id="TestAnychart"
                        data={formatedData}
                        stacks={stacks}
                        title="University Locations"
                        actionsOffset={{ y: -95, x: 30 }}
                    />
                </Stack>
            </Paper>
        </>
    );
}
