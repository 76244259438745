import React, { useState, useRef } from "react";
import { Box, Typography, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        margin: "auto",
        marginTop: 60,
        width: 700,
    },
    title: {
        fontSize: 46,
    },
    question: {
        cursor: "pointer",
    },
    divider: {
        marginTop: 30,
        marginBottom: 30,
    },
    answerBox: {
        transition: "height .5s",
        height: 0,
        overflow: "hidden",
    },
    answer: {
        marginTop: 20,
        transition: "-webkit-transform 0.25s ease-in-out",
    },
    email: {
        color: "#0052cc",
        textDecoration: "none",
    },
    list: {
        paddingLeft: 20,
    },
    icon: {
        float: "right",
        fontSize: 30,
        cursor: "pointer",
    },
}));

export default function FAQPage() {
    const classes = useStyles();
    const questions = [
        {
            question: "What is the Osterus mission?",
            answers: [
                `Our mission is simple: offer clean and actionable data with informative and beautiful visualization. We
                aim to help clients with executive and managerial authority have a better insight into workforces and
                the overall market, as well as to aid them in making data-driven, informed decisions that shape the
                world.`,
                `Additionally, we offer our API for easy integration with other software so that data can meet data and
                the image of the future becomes sharper.`,
            ],
        },
        {
            question: "How is our profile data processed?",
            answers: [
                `When profiles are acquired our software takes great care to process them as to reduce clutter, noise,
                and misleading data to present the most objective and actionable insight. Duplicates are detected and
                eliminated, faulty profiles discarded, missing data points reconstructed where possible, and then
                depersonalized to be GDPR compliant.`,
                `By the time profiles reach the data display in Our software it has
            already gone through a number of sieves and polishes. Quality - check.`,
            ],
        },
        {
            question: "What are the Stacks?",
            answers: [
                `CV Stacks (or Stacks for short) is a virtual group of profiles that are filtered around one or more entities. A Stack can contain profiles that are current employees of a company, 
            or it can contain all the profiles in the database who attended a certain university or all the profiles that live in a certain city. 
            Additionally, when creating a Stack several entities can be grouped together, or filtered down according to certain criteria.`,
                `As an example: one can create a Stack that contains several companies in the same industry, region, and of the same company size category. 
            This is a perfect tool for due diligence/benchmarking or analysis of only a few job positions filtered for more precise comparison.`,
            ],
        },
        {
            question: "What is the Intelligence?",
            answers: [
                `Osterus collects data from various external sources and places it under the umbrella which is Intelligence. 
            Universities, Cities, Companies - data on these entities is collected and infused into the Osterus system, greatly expanding the web and complexity of information shown 
            and the value and depth of data point analysis. `,
            ],
        },
        {
            question: "What is the Osterus Core?",
            answers: [
                `Osterus accommodates a large number of depersonalized profiles. This wealth presents a strong foundation for exploring data through different lenses. 
            Obviously, the potential is limitless - assessing all profiles with a certain current or previous job position can give insight into career trajectories; 
            showing all profiles that attended a certain university can show, with precision, what are the most common industries graduates end up working in - the list goes on.`,
                `Additionally, integration of our API into your software is possible, where our extensive database can be used for benchmarking profiles in your hiring, banking, and other practices.`,
            ],
        },
        {
            question: "How do I reach the Osterus team?",
            answers: [`Easy. Here are some useful contacts:`],
            list: (
                <ul className={classes.list}>
                    <li>+49 (0)1783701238</li>
                    <li>
                        <a href="mailto:contact@osterus.com" className={classes.email}>
                            contact@osterus.com
                        </a>
                    </li>
                </ul>
            ),
            secondAnswers: "We'll get back to you as soon as possible.",
        },
    ];

    const [openQuestion, setOpenQuestion] = useState([]);
    const faqRef = useRef(null);

    const handleOpen = (index) => {
        const faq = faqRef.current;
        const answerBox = faq.querySelector(`[data-index="${index}"]`);
        if (!openQuestion.includes(index)) {
            answerBox.style.height = `${answerBox.scrollHeight}px`;
            setOpenQuestion([...openQuestion, index]);
        } else {
            setOpenQuestion((currentQuestions) => currentQuestions.filter((question) => question !== index));
            answerBox.style.height = 0;
        }
    };

    return (
        <Box className={classes.root} ref={faqRef}>
            <Typography variant="h3" className={classes.title}>
                Frequently Asked Questions (FAQ)
            </Typography>
            <Divider className={classes.divider} />
            {questions.map((element, index) => (
                <Box key={index}>
                    <Typography variant="h5" onClick={() => handleOpen(index)} className={classes.question}>
                        {element.question}
                        {openQuestion.includes(index) ? (
                            <CloseIcon className={classes.icon} />
                        ) : (
                            <AddIcon className={classes.icon} />
                        )}
                    </Typography>
                    <Box data-index={index} className={classes.answerBox}>
                        {element.answers.map((answer) => (
                            <Typography key={answer} variant="body2" className={classes.answer}>
                                {answer}
                            </Typography>
                        ))}
                        {element.list && element.list}
                        {element.secondAnswers && element.secondAnswers}
                    </Box>
                    <Divider className={classes.divider} />
                </Box>
            ))}
        </Box>
    );
}
