import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    stackedBar: {
        height: "100%",
        display: "inline-block",
    },
    title: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
    },
}));

export default function StackedBar({
    series,
    categories,
    title,
    reversed = false,
    padding,
    xAxisOffset,
    stackname,
    barColors,
    inPercentage = true,
    max,
    tickAmount = 5,
    noFormats,
    noXaxis = false,
    isDistributed,
    barHeight = "67%",
    gridXaxisLines = true,
    shared = true,
    v2 = false,
    showGrid = true,
    eventHandlers = {},
}) {
    const { onUpdated } = eventHandlers;
    const maxValue = useMemo(() => {
        if (max !== undefined) return max;

        let maxVal = 0;
        for (let i = 0; i < categories.length; i += 1) {
            const rowSum = series.reduce((prev, cur) => prev + cur.data[i], 0);
            maxVal = Math.max(maxVal, rowSum);
        }
        return maxVal;
    }, [series]);

    const classes = useStyles();
    const options = {
        colors: barColors,
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
            events: {
                updated: onUpdated,
            },
        },
        plotOptions: {
            bar: {
                barHeight,
                horizontal: true,
                borderRadius: [2],
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
                distributed: isDistributed,
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: reversed ? "end" : "start",
            enabledOnSeries: [series.length - 1],
            style: {
                colors: ["#7B7B7B"],
            },

            // Apexcharts won't update the chart according to specified options if nothing in those options
            // has changed. Therefore we need to change at least 1 thing in order to force the update.
            // In this particular case, it's needed because formatter needs to know the latest max value.
            offsetX: Math.random(),

            formatter: (value, { dataPointIndex: index, w }) => {
                const allSeries = w.config.series;
                const sumPercent = allSeries.reduce((prev, cur) => prev + cur.data[index], 0);
                const lastVal = allSeries[allSeries.length - 1].data[index];

                // \u2000 = one of the Unicode spaces(apexcharts library trims regular spaces).
                let spacing;
                if (sumPercent === 0) {
                    spacing = "";
                } else if (lastVal / maxValue < 0.01) {
                    spacing = "\u2000\u2000";
                } else {
                    spacing = "\u2000";
                }

                if (reversed)
                    return !inPercentage ? `${sumPercent.toFixed(2)}${spacing}` : `${sumPercent.toFixed(2)}%${spacing}`;
                return !inPercentage ? `${spacing}${sumPercent.toFixed(2)}` : `${spacing}${sumPercent.toFixed(2)}%`;
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: false,
        },
        grid: {
            show: showGrid,
            xaxis: {
                lines: {
                    show: gridXaxisLines,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: padding ?? {},
        },
        xaxis: noXaxis
            ? {
                  labels: {
                      show: false,
                      style: {
                          colors: ["#ABABAB"],
                      },
                  },
                  axisBorder: {
                      show: false,
                  },
                  axisTicks: {
                      show: false,
                  },
                  max: maxValue * 1.1,
              }
            : v2
            ? {
                  labels: {
                      show: true,
                      style: {
                          colors: ["#ABABAB"],
                      },
                      offsetY: xAxisOffset,
                      formatter: (value) =>
                          !inPercentage
                              ? value > 0 && value < 1
                                  ? value.toFixed(2)
                                  : Math.round(value.toFixed(1))
                              : value === 0 || value === 1
                              ? value.toFixed(0)
                              : `${Math.round(value.toFixed(1))}%`,
                  },
                  axisBorder: {
                      show: false,
                  },
                  axisTicks: {
                      show: false,
                  },
                  tickAmount,
                  max: 1,
              }
            : {
                  labels: {
                      show: true,
                      style: {
                          colors: ["#ABABAB"],
                      },
                      offsetY: xAxisOffset,
                      formatter: (value) => {
                          if (!inPercentage) {
                              return value > 0 && value < 1 ? value.toFixed(2) : Math.round(value.toFixed(1));
                          }
                          return value === 0 || value === 1
                              ? value.toFixed(0)
                              : value > 0 && value < 1
                              ? `${value.toFixed(2)}%`
                              : `${Math.round(value.toFixed(1))}%`;
                      },
                  },
                  axisBorder: {
                      show: false,
                  },
                  axisTicks: {
                      show: false,
                  },
                  tickAmount,
                  max: maxValue * 1.1,
              },
        yaxis: {
            reversed,
            show: false,
        },
        tooltip: {
            inverseOrder: true,
            shared,
            intersect: false,
            x: {
                formatter: (value, { dataPointIndex }) =>
                    barColors.length > 1 && !isDistributed ? categories[dataPointIndex] : stackname,
            },
            y: {
                formatter: (value) => (noFormats ? value : inPercentage ? `${value}%` : `${value} years`),
                title: {
                    formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
                        if (!series[seriesIndex].name) {
                            return categories[dataPointIndex];
                        }
                        return seriesName;
                    },
                },
            },
        },
        annotations: {
            xaxis: reversed
                ? [
                      {
                          x: -0.1,
                          strokeDashArray: 0,
                          borderColor: "#fff",
                          borderWidth: 1.4,
                          opacity: 1,
                      },
                      {
                          x: 0,
                          strokeDashArray: 0,
                          borderColor: "#e0e0e0",
                          borderWidth: 1.1,
                          opacity: 1,
                          offsetY: 2,
                      },
                  ]
                : [],
        },
    };

    return (
        <div id="chart" className={classes.stackedBar}>
            {title && (
                <Tooltip title={title}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                        color="textSecondary"
                        align={reversed ? "right" : "left"}
                        ml={reversed || 2}
                        mr={!reversed || 2}
                        mb={1}
                    >
                        {title}
                    </Typography>
                </Tooltip>
            )}
            <Chart options={options} series={series} type="bar" width="100%" height="100%" stackname={stackname} />
        </div>
    );
}
