import { axiosInstance } from "../../../../config/api/Axios";

export const getAllByFilterApi = (params) =>
    axiosInstance.get("/lab/stacks", {
        params,
    });
export const getSelectedStacksApi = (params) =>
    axiosInstance.get("/lab/stacks", {
        params,
    });
