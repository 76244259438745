import React, { useEffect } from "react";
import { Paper, Typography, Box, Avatar, Grid, Link, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
    paper: {
        maxHeight: 500,
        overflowY: "auto",
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2.25),
        color: theme.palette.text.secondary,
        justifyContent: "center",
        scrollbarWidth: "thin",
        scrollbarColor: "#cccccc",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cccccc",
            borderRadius: 3,
        },
    },
    universityAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    readMore: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
    },
}));

export default function UniversityBio({ university, onClose }) {
    const classes = useStyles();
    const delayTime = 500;
    const [isReadMore, setIsReadMore] = React.useState(true);

    useEffect(() => {
        setIsReadMore(true);
    }, [university]);

    const handleOnClose = () => {
        if (onClose) onClose();
    };

    return (
        <>
            {university.name && (
                <Paper className={classes.paper} elevation={0}>
                    <Grid container>
                        <Grid item container xs={11} sx={{ flexWrap: "nowrap" }}>
                            <Avatar className={classes.universityAvatar} variant="square" src={university.logo} />
                            <Box sx={{ width: "70%", ml: 1.5 }}>
                                <Typography color="text.headlines" translate="no">
                                    {university.name}
                                </Typography>
                                {university.city && (
                                    <Typography color="textSecondary" variant="body2">
                                        Located in{" "}
                                        <strong translate="no">
                                            {university.city.name}, {university.city.country.name}.{" "}
                                        </strong>
                                    </Typography>
                                )}
                                <Typography fontWeight={500} variant="body2" translate="no">
                                    <Link href={university.website} underline="none" target="_blank">
                                        {university.website}
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item container xs={1}>
                            <Typography style={{ cursor: "pointer" }}>
                                <CloseIcon onClick={handleOnClose} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Tooltip
                            enterNextDelay={delayTime}
                            title="UPCOMING FEATURE: The Osterus Country Factbook shows a detailed, factual overview of the country the university is in in a downloadable pdf format."
                            placement="top"
                        >
                            <Box>
                                <Typography color="primary" variant="body2" fontWeight={500}>
                                    Factbook{" "}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Typography variant="overline" color="text.secondaryVariant2" fontWeight={500}>
                            UNIVERSITY BIO
                        </Typography>
                    </Box>
                    {isReadMore ? (
                        <>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                className={classes.readMore}
                                translate="no"
                            >
                                {university.description}
                            </Typography>
                            <Typography
                                display="inline"
                                color="secondary"
                                style={{ cursor: "pointer", textTransform: "none" }}
                                onClick={() => setIsReadMore(false)}
                            >
                                read more
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="body2" color="textSecondary">
                                {university.description}{" "}
                            </Typography>
                            <Typography
                                display="inline"
                                color="secondary"
                                style={{ cursor: "pointer", textTransform: "none" }}
                                onClick={() => setIsReadMore(true)}
                            >
                                read less
                            </Typography>
                        </>
                    )}
                </Paper>
            )}
        </>
    );
}
