import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import LanguageIcon from "@mui/icons-material/Language";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function RetentionSection({ data, currentCity, comparisonCity, classes, theme }) {
    const retention = useMemo(() => {
        const average = data.retentionAndLoyalty.averageWorkExperienceRetention.data;
        const leftAverageSmaller = average[0] < average[1];
        const averageDiff = leftAverageSmaller ? average[1] - average[0] : average[0] - average[1];

        const companiesLeft = data.retentionAndLoyalty.averageWorkExperienceRetentionPerCompanies.data[0];
        const companiesRight = data.retentionAndLoyalty.averageWorkExperienceRetentionPerCompanies.data[1];
        const companiesLongerList = companiesLeft.length > companiesRight.length ? companiesLeft : companiesRight;

        const loyaltyLeft = data.retentionAndLoyalty.topHighestScorePerCompany.data[0];
        const loyaltyRight = data.retentionAndLoyalty.topHighestScorePerCompany.data[1];
        const loyaltyLongerList = loyaltyLeft.length > loyaltyRight.length ? loyaltyLeft : loyaltyRight;

        return {
            average: {
                values: average.map((value) => `${value || 0} years`),
                barPercentages: [
                    leftAverageSmaller ? (average[0] / average[1]) * 100 : 100,
                    leftAverageSmaller ? 100 : (average[1] / average[0]) * 100,
                ],
                colors: [
                    leftAverageSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAverageSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <GroupsIcon className={leftAverageSmaller ? classes.smallerScore : classes.higherScore} />,
                    <GroupsIcon className={leftAverageSmaller ? classes.higherScore : classes.smallerScore} />,
                ],
                message: `${comparisonCity.name} has ${parseFloat(averageDiff).toFixed(2)} years ${
                    leftAverageSmaller ? "higher" : "lower"
                } Average retention in companies than ${currentCity.name}`,
            },
            companies: {
                values: companiesLongerList.map((_, index) => [
                    shortenText(companiesLeft[index], 25),
                    shortenText(companiesRight[index], 25),
                ]),
            },
            loyalty: {
                values: loyaltyLongerList.map((_, index) => [
                    loyaltyLeft[index] ? shortenText(loyaltyLeft[index][0], 25) : undefined,
                    loyaltyRight[index] ? shortenText(loyaltyRight[index][0], 25) : undefined,
                ]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Retention And Loyalty">
            <MetricItem
                name="Average Retention in Companies in the city"
                values={retention.average.values}
                barPercentages={retention.average.barPercentages}
                colors={retention.average.colors}
                indicators={retention.average.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {retention.average.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Companies with the highest retention in the city"
                    values={retention.companies.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={LanguageIcon}
                />
            </Stack>

            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Companies with the highest Loyalty Score in the city"
                    values={retention.loyalty.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={VolunteerActivismIcon}
                />
            </Stack>
        </MetricSection>
    );
}
