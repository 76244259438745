import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Grid, CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import { Dark as DarkMode } from "../Default/mods/Dark";
import { GlobalToastNotificationsComponent } from "../../components/Notifications/GlobalToastNotifications";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
    title: {
        flexGrow: 1,
    },
    logoHover: {
        cursor: "pointer",
    },
}));

export default function Public({ component }) {
    const classes = useStyles();
    const appliedTheme = createTheme(DarkMode);
    const Component = component;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appliedTheme}>
                <GlobalToastNotificationsComponent />
                <Grid container className={classes.root}>
                    <CssBaseline />
                    <Component />
                </Grid>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
