import React from "react";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MetricItem } from "./MetricItem";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary[400],
    },
}));

export function MetricGroup({ name, values, barPercentages, colors, textStyles, Icon }) {
    const classes = useStyles();
    const opacityStep = 0.75 / values.length;

    return (
        <Stack>
            <Typography mb={1.2} variant="body2" textAlign="center">
                {name}
            </Typography>
            {values.map((value, index) => (
                <MetricItem
                    key={index}
                    values={value}
                    barPercentages={barPercentages ? barPercentages[index] : [0, 0]}
                    colors={colors}
                    indicators={[
                        <Icon className={classes.icon} sx={{ opacity: 1 - opacityStep * index }} />,
                        <Icon className={classes.icon} sx={{ opacity: 1 - opacityStep * index }} />,
                    ]}
                    textStyles={textStyles}
                />
            ))}
        </Stack>
    );
}
