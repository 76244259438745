import React, { useState, useEffect, useRef } from "react";
import { Stack, MenuItem, Menu, IconButton, Divider, Tooltip } from "@mui/material";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { withStyles, makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { useMatch } from "@gatsbyjs/reach-router";
import { stackSliceSelectors } from "../../../../../slices/Stacks/stackSlice";
import { stackInsightsSliceSelector } from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { userSliceSelectors } from "../../../../../slices/User/userSlice";
import { ShareModal } from "../Modals/ShareModal";
import { DownloadModal } from "../Modals/DownloadModal";
import { addMessage } from "../../../../../slices/NotificationSlice/GlobalNotificationSlice";
import ProgressDialog from "../ProgressDialog";
import { isReportFinished, downloadReport } from "../../../../../services/api/utils";
import NoRequestsAvailableModal from "../../NoRequestsAvailableModal";
import { getMe } from "../../../../../services/api/Public/Login/loginActions";

const useStyles = makeStyles(() => ({
    icon: {
        paddingRight: 10,
    },
}));

const DownloadButtonTooltip = withStyles({
    tooltip: {
        maxWidth: 320,
    },
})(Tooltip);

export function DownloadSelectionMenu({ reportLimit, setReportLimit }) {
    const delayTime = 500;
    const classes = useStyles();
    const loadingInsights = useSelector(stackInsightsSliceSelector.loadingInsights);
    const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState(null);
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [noRequestModalOpen, setNoRequestModalOpen] = useState(false);
    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const [progressDialogOpen, setProgressDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const progressValueRef = React.useRef(0);
    const intervalRef = useRef(null);
    const [pdfDownloaded, setPdfDownloaded] = useState(false);

    const userOrganizations = useSelector(userSliceSelectors.organizations);
    const isUserPartOfOsterus = userOrganizations && userOrganizations.filter((item) => item.id === 1).length > 0;
    const singleViewMatch = useMatch("/the-lab/stacks/stack-insights/:view/:stackId");
    const comparisonViewMatch = useMatch("/the-lab/stacks/stack-insights/:view/:firstStackId/:secondStackId");

    const viewType = singleViewMatch ? singleViewMatch.view : comparisonViewMatch.view;
    const isSingleView = singleViewMatch !== null;

    const handleDownloadMenuClicked = (event) => {
        if (isDownloadMenuOpen) {
            setDownloadMenuAnchorEl(null);
            setIsDownloadMenuOpen(false);
        } else {
            setDownloadMenuAnchorEl(event.currentTarget);
            setIsDownloadMenuOpen(true);
        }
    };

    const handleDownloadModalClosed = (data) => {
        if (data) {
            setTimeout(async () => {
                try {
                    await downloadReport(data);
                    setTimeout(async () => checkReportStatus(data.request_id), 2000);
                } catch {
                    dispatch(addMessage({ type: "error", title: "PDF generation failed." }));
                    dispatch(getMe());
                }
            }, 0);
            const numberOfPages = data.data.length;
            const updateRateReduction = numberOfPages * 90;
            intervalRef.current = setInterval(updateProgress, 1000 + updateRateReduction);
            setProgressDialogOpen(true);
        }

        setDownloadModalOpen(false);
        setIsDownloadMenuOpen(false);
    };

    const checkReportStatus = async (requestId) => {
        try {
            const response = await isReportFinished(requestId);
            const blob = new Blob([response.data], { type: "application/pdf" });
            const downloadUrl = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.setAttribute(
                "download",
                `${selectedStacks[0].stack.name} VS ${selectedStacks[1].stack.name} Osterus Report.pdf`,
            );
            a.setAttribute("href", downloadUrl);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setPdfDownloaded(true);
            if (reportLimit) {
                setReportLimit((prev) => (prev - 1).toString());
            }
        } catch (err) {
            setTimeout(async () => checkReportStatus(requestId), 2000);
        }
    };

    const updateProgress = () => {
        progressValueRef.current = Math.min(progressValueRef.current + 5, 100);
        if (pdfDownloaded) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setProgressDialogOpen(false);
            progressValueRef.current = 0;
            setPdfDownloaded(false);
        }
    };

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(updateProgress, 1000);
        }
    }, [pdfDownloaded]);

    return (
        <>
            <Stack direction="row" spacing={2} alignItems="center" className={classes.icon}>
                <div>
                    <DownloadButtonTooltip
                        enterNextDelay={delayTime}
                        title="Click here to download the presentation in a PDF format."
                        placement="top"
                    >
                        <IconButton
                            onClick={handleDownloadMenuClicked}
                            size="large"
                            disabled={loadingInsights || isSingleView}
                        >
                            <VerticalAlignBottomIcon />
                        </IconButton>
                    </DownloadButtonTooltip>
                    <Menu
                        anchorEl={downloadMenuAnchorEl}
                        open={isDownloadMenuOpen}
                        onClose={handleDownloadMenuClicked}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {isUserPartOfOsterus && (
                            <MenuItem
                                onClick={() => {
                                    setIsDownloadMenuOpen(false);
                                    setShareModalOpen(true);
                                }}
                            >
                                Share link to Data Insights
                            </MenuItem>
                        )}
                        {isUserPartOfOsterus && !isSingleView && <Divider />}
                        {!isSingleView && (
                            <MenuItem
                                onClick={() => {
                                    setIsDownloadMenuOpen(false);
                                    if (reportLimit === "0") {
                                        setNoRequestModalOpen(true);
                                    } else {
                                        setDownloadModalOpen(true);
                                    }
                                }}
                            >
                                Download Presentation
                            </MenuItem>
                        )}
                    </Menu>
                </div>
            </Stack>
            <ShareModal
                stackIds={isSingleView ? [selectedStacks[0]?.id] : [selectedStacks[0]?.id, selectedStacks[1]?.id]}
                viewType={viewType}
                open={shareModalOpen}
                onClose={() => setShareModalOpen(false)}
            />
            <NoRequestsAvailableModal
                open={noRequestModalOpen}
                title="Download Presentation"
                handleClose={() => setNoRequestModalOpen(false)}
                message="You have reached your limit for downloading Automated Reports. If you wish to download more please contact Osterus team."
            />
            <DownloadModal
                open={downloadModalOpen}
                onClose={handleDownloadModalClosed}
                selectedStacks={selectedStacks}
                setReportLimit={setReportLimit}
            />

            {progressDialogOpen && <ProgressDialog open={progressDialogOpen} valueRef={progressValueRef} />}
        </>
    );
}
