import React from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";

import { qualityOfLifeSelectors } from "../../../../../slices/Intelligence/QualityOfLifeSlice";
import { CityInfo } from "../../../components/CityInfo";
import RankingSection from "./Sections/RankingSection";
import ProfilesSection from "./Sections/ProfilesSection";
import DiversitySection from "./Sections/DiversitySection";
import JobTitlesSection from "./Sections/JobTitlesSection";
import RetentionSection from "./Sections/RetentionSection";

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        flex: 2,
        maxWidth: 660,
        overflow: "auto",
        paddingBottom: 10,
    },
    smallerScore: {
        color: theme.palette.primary[100],
    },
    higherScore: {
        color: theme.palette.primary[400],
    },
    icon: {
        fontSize: 50,
    },
}));

export default function BusinessPotential({ data }) {
    const currentCity = useSelector(qualityOfLifeSelectors.currentCity);
    const comparisonCity = useSelector(qualityOfLifeSelectors.comparisonCity);
    const currentCityGeneralScore = useSelector(qualityOfLifeSelectors.currentCityGeneralScore);
    const comparisonCityGeneralScore = useSelector(qualityOfLifeSelectors.comparisonCityGeneralScore);
    const theme = useTheme();
    const classes = useStyles();

    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="center" width="100%" height="100%" pb={4.5}>
            <CityInfo
                image={currentCity.image}
                title="Overall Score"
                subtitle={currentCityGeneralScore.rank}
                value={currentCityGeneralScore.score}
                name={currentCity.name}
                iso2Code={currentCity?.country?.iso2}
            />
            <div className={classes.dataContainer}>
                <RankingSection
                    data={data}
                    currentCity={currentCity}
                    comparisonCity={comparisonCity}
                    theme={theme}
                    classes={classes}
                />

                <ProfilesSection data={data} theme={theme} classes={classes} />

                <DiversitySection data={data} />

                <JobTitlesSection data={data} />

                <RetentionSection
                    data={data}
                    currentCity={currentCity}
                    comparisonCity={comparisonCity}
                    theme={theme}
                    classes={classes}
                />
            </div>
            <CityInfo
                image={comparisonCity.image}
                title="Overall Score"
                subtitle={comparisonCityGeneralScore.rank}
                value={comparisonCityGeneralScore.score}
                name={comparisonCity.name}
                iso2Code={comparisonCity?.country?.iso2}
            />
        </Stack>
    );
}
