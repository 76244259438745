import React, { useState, useMemo } from "react";
import { Box, Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { navigate, useLocation } from "@gatsbyjs/reach-router";
import { useSelector } from "react-redux";
import SchoolIcon from "@mui/icons-material/School";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { userSliceSelectors } from "../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    menuButton: {
        textTransform: "none",
        height: theme.spacing(5.25),
        marginRight: theme.spacing(2.25),
        color: "#303030",
    },
    menu: {
        margin: 0,
        "& ul": {
            width: theme.spacing(31.25),
        },
    },
    menuButtonContainer: {
        display: "flex",
        flexDirection: "column",
    },
    menuActive: {
        width: "24px",
        height: "2px",
        flexGrow: 0,
        backgroundColor: "#568fd2",
    },
    menuIcon: {
        fontSize: "15px",
        marginRight: "3px",
    },
}));

const delayTime = 500;
const DASHBOARD_MENU = "dashboard";
const WORKFORCE_MENU = "workforce";
const HIRING_MENU = "hiring";
const THE_LAB_MENU = "the-lab";
const INTELLIGENCE_MENU = "intelligence";
const ADMIN_MENU = "admin";

export default function AppMenu() {
    const hasTrialOrSubscriptionEnded = useSelector(userSliceSelectors.hasTrialOrSubscriptionEnded);
    const userType = useSelector(userSliceSelectors.type);
    const classes = useStyles();
    const path = useLocation().pathname;

    const menuActive = useMemo(() => {
        const splittedPath = path.split("/")[1];

        switch (splittedPath) {
            case DASHBOARD_MENU:
                return DASHBOARD_MENU;
            case WORKFORCE_MENU:
                return WORKFORCE_MENU;
            case HIRING_MENU:
                return HIRING_MENU;
            case THE_LAB_MENU:
                return THE_LAB_MENU;
            case INTELLIGENCE_MENU:
                return INTELLIGENCE_MENU;
            case ADMIN_MENU:
                return ADMIN_MENU;
            default:
                return null;
        }
    }, [path]);

    const [intelligenceMenu, setIntelligenceMenu] = useState(null);
    const openIntelligenceMenu = (event) => {
        setIntelligenceMenu(event.currentTarget);
    };
    const closeIntelligenceMenu = () => {
        setIntelligenceMenu(null);
    };

    return (
        <Box display="flex" justifyContent="center" pl={18.5}>
            <Tooltip
                title="Use the Dashboard for a quick overview of what is happening in your personal Osterus."
                enterNextDelay={delayTime}
                placement="bottom"
            >
                <Button variant="menuItem" disabled onClick={() => navigate("/dashboard")} component="div">
                    <div className={classes.menuButtonContainer}>
                        Dashboard
                        {menuActive === DASHBOARD_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            </Tooltip>
            {userType === 1 && (
                <Button
                    variant="menuItem"
                    aria-controls="admin-menu"
                    aria-haspopup="true"
                    onClick={() => navigate("/admin")}
                >
                    <div className={classes.menuButtonContainer}>
                        Admin
                        {menuActive === ADMIN_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            )}
            {/*
            <Tooltip
                title="Use the Workforce menu to upload, edit and analyze your workforce."
                enterNextDelay={delayTime}
                placement="bottom"
            >
                <Button
                    className={classes.unfinishedMenu}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={() => navigate("/workforce")}
                >
                    <div className={classes.menuButtonContainer}>
                        <Typography variant="body2">Workforce</Typography>
                        {menuActive === WORKFORCE_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            </Tooltip>
            <Tooltip
                title="Use the Hiring menu to upload, evaluate and choose the best candidates to join your team."
                enterNextDelay={delayTime}
                placement="bottom"
            >
                <Button
                    className={classes.unfinishedMenu}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={() => navigate("/hiring")}
                >
                    <div className={classes.menuButtonContainer}>
                        <Typography variant="body2">Hiring</Typography>
                        {menuActive === HIRING_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            </Tooltip>
            */}
            <Tooltip
                title="Use the Stacks menu to perform advanced analytics and get actionable insights."
                enterNextDelay={delayTime}
                placement="bottom"
            >
                <Button
                    variant="menuItem"
                    aria-controls="lab-menu"
                    aria-haspopup="true"
                    onClick={() => navigate("/the-lab/stacks")}
                    disabled={hasTrialOrSubscriptionEnded}
                >
                    <div className={classes.menuButtonContainer}>
                        Stacks
                        {menuActive === THE_LAB_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            </Tooltip>
            <Tooltip
                title="Use the Intelligence menu to access the vast Osterus database of actionable information."
                enterNextDelay={delayTime}
                placement="bottom"
            >
                <Button
                    variant="menuItem"
                    endIcon={<ArrowDropDownIcon />}
                    aria-controls="intelligence-menu"
                    aria-haspopup="true"
                    onClick={openIntelligenceMenu}
                    disabled={hasTrialOrSubscriptionEnded}
                >
                    <div className={classes.menuButtonContainer}>
                        Intelligence
                        {menuActive === INTELLIGENCE_MENU ? <div className={classes.menuActive} /> : null}
                    </div>
                </Button>
            </Tooltip>
            <Menu
                className={classes.menu}
                id="intelligence-menu"
                anchorEl={intelligenceMenu}
                keepMounted
                open={Boolean(intelligenceMenu)}
                onClose={closeIntelligenceMenu}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Tooltip
                    title="Use the Universities submenu to search and filter through Osterus’s vast Universities database and explore the collected data and aggregated insights."
                    enterNextDelay={delayTime}
                    placement="bottom"
                >
                    <MenuItem onClick={() => navigate("/intelligence/universities")}>
                        <SchoolIcon className={classes.menuIcon} />
                        <Typography variant="body2">Universities</Typography>
                    </MenuItem>
                </Tooltip>
                <Tooltip
                    title="Use the Cost of Living submenu to compare the cost of living in two different cities. Useful for establishing salaries of remote employees, leveraging negotiations with hiring candidates who are considering moving or exploring the cost of living for cities of competing companies."
                    enterNextDelay={delayTime}
                    placement="bottom"
                >
                    <MenuItem onClick={() => navigate("/intelligence/cost-of-living")}>
                        <AttachMoneyIcon className={classes.menuIcon} />
                        <Typography variant="body2">Cost of Living</Typography>
                    </MenuItem>
                </Tooltip>
                <Tooltip
                    title="Use the Quality of Life submenu to compare the quality of life in two different cities. Useful for leveraging negotiations with hiring candidates who are considering moving or exploring the quality of life for cities of competing companies."
                    enterNextDelay={delayTime}
                    placement="bottom"
                >
                    <MenuItem onClick={() => navigate("/intelligence/quality-of-life")}>
                        <BeachAccessIcon className={classes.menuIcon} />
                        <Typography variant="body2">Quality of Life</Typography>
                    </MenuItem>
                </Tooltip>
            </Menu>
        </Box>
    );
}
