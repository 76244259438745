import { axiosInstance } from "../../../../config/api/Axios";

export const getOrganizationSettingsApi = async (organization_id) =>
    axiosInstance.get(`/organizations/${organization_id}/settings/`);

export const updateOrganizationSettingsApi = async (
    organization_id,
    { full_name, display_name, logo, size, industry, website, linkedin, email, description, locations, owner_id },
) =>
    axiosInstance.patch(`/organizations/${organization_id}/settings/`, {
        full_name,
        display_name,
        logo,
        size,
        industry,
        website,
        linkedin,
        email,
        description,
        locations,
        owner_id,
    });
