import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function EducationSection({ data, currentCity, comparisonCity, theme, classes }) {
    const education = useMemo(() => {
        const educationLength = data.education.averageEducationTime.data.map((value) => value || 0);
        const leftEducationLengthSmaller = educationLength[0] < educationLength[1];

        const universitiesLeft = data.education.topUniversitiesAttended.data[0];
        const universitiesRight = data.education.topUniversitiesAttended.data[1];
        const universitiesLongerList =
            universitiesLeft.length > universitiesRight.length ? universitiesLeft : universitiesRight;

        return {
            educationLength: {
                values: educationLength.map((value) => `${value} years`),
                barPercentages: [
                    leftEducationLengthSmaller ? (educationLength[0] / educationLength[1]) * 100 : 100,
                    leftEducationLengthSmaller ? 100 : (educationLength[1] / educationLength[0]) * 100,
                ],
                colors: [
                    leftEducationLengthSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftEducationLengthSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <SchoolIcon className={leftEducationLengthSmaller ? classes.smallerScore : classes.higherScore} />,
                    <SchoolIcon className={leftEducationLengthSmaller ? classes.higherScore : classes.smallerScore} />,
                ],
                message: `${comparisonCity.name} has ${
                    leftEducationLengthSmaller
                        ? parseFloat(educationLength[1] - educationLength[0]).toFixed(2)
                        : parseFloat(educationLength[0] - educationLength[1]).toFixed(2)
                } years ${leftEducationLengthSmaller ? "higher" : "lower"} Average education length than ${
                    currentCity.name
                }`,
            },
            universities: {
                values: universitiesLongerList.map((_, index) => [
                    shortenText(universitiesLeft[index], 25),
                    shortenText(universitiesRight[index], 25),
                ]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Education">
            <MetricItem
                name="Average Education Length in the city"
                values={education.educationLength.values}
                barPercentages={education.educationLength.barPercentages}
                colors={education.educationLength.colors}
                indicators={education.educationLength.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {education.educationLength.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Most Attended Universities in the city"
                    values={education.universities.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={AccountBalanceIcon}
                />
            </Stack>
        </MetricSection>
    );
}
