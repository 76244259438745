import { createSlice } from "@reduxjs/toolkit";
import { getStacks } from "../../actions/Admin/Stacks";
import { getDataRequests } from "../../actions/Admin/DataRequests";
import { getOrganizations, getPlans } from "../../actions/Admin/Organizations";
import { getUsers } from "../../actions/Admin/Users";
import { getReferrals } from "../../actions/Admin/Referrals";

const SLICE_NAME = "admin";

const initialState = {
    stacks: {
        loading: false,
        data: [],
        totalRecords: null,
        filter: {
            q: {
                name: "",
                visibility: "",
                organization: "",
                tags: [],
            },
            start: 1,
            limit: 25,
        },
        activeStack: {
            id: null,
            name: "",
            color: "",
            organization: { name: "", id: null },
            company: { name: "", id: null },
            tags: [],
            jira_ticket: null,
        },
    },
    dataRequests: {
        loading: false,
        data: [],
        totalRecords: null,
        filter: {
            q: {
                name: "",
                status: "",
                organization: "",
            },
            start: 1,
            limit: 25,
        },
    },
    organizations: {
        loading: false,
        data: [],
        totalRecords: null,
        plans: [],
        filter: {
            q: {
                name: "",
                type: "",
                industry: "",
            },
            start: 1,
            limit: 25,
            sort: "",
            direction: "",
            organization: "",
            search: "",
            price: null,
            industry: null,
            plans: null,
        },
    },
    users: {
        loading: false,
        data: [],
        totalRecords: null,
        filter: {
            q: {
                name: "",
                type: "",
                organization: "",
                job_title: "",
                department: "",
                based_in: "",
            },
            start: 1,
            limit: 25,
            sort: "",
            direction: "",
        },
    },
    referrals: {
        loading: false,
        data: [],
        totalRecords: null,
        filter: {
            q: {},
            start: 1,
            limit: 25,
        },
    },
};

export const adminSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setStacksFilter(state, { payload }) {
            state.stacks.filter = { ...state.stacks.filter, ...payload };
            if (!payload.start) {
                state.stacks.filter.start = 1;
            }
        },
        clearStacksFilter(state) {
            state.stacks = initialState.stacks;
        },
        setActiveStack(state, { payload }) {
            state.stacks.activeStack = { ...state.stacks.activeStack, ...payload };
        },
        clearActiveStack(state) {
            state.stacks.activeStack = initialState.stacks.activeStack;
        },
        setDataRequestsFilter(state, { payload }) {
            state.dataRequests.filter = { ...state.dataRequests.filter, ...payload };
            if (!payload.start) {
                state.dataRequests.filter.start = 1;
            }
        },
        clearDataRequestsFilter(state) {
            state.dataRequests = initialState.dataRequests;
        },
        setUsersFilter(state, { payload }) {
            state.users.filter = payload;
        },
        setUsersSearchFilter(state, { payload }) {
            state.users.filter.q.name = payload;
        },
        updateFilter(state, action) {
            state.filter = action.payload;
        },
        clearUsersFilter(state) {
            state.users.filter = initialState.users.filter;
        },
        setReferralsFilter(state, { payload }) {
            state.referrals.filter = { ...state.referrals.filter, ...payload };
            if (!payload.start) {
                state.referrals.filter.start = 1;
            }
        },
        clearReferralsFilter(state) {
            state.referrals.filter = initialState.referrals.filter;
        },
        setReferralsTotalRecords(state, { payload }) {
            state.referrals.totalRecords = payload;
        },
        clearState(state) {
            Object.assign(state, initialState);
        },
        setOrganizationsFilter(state, { payload }) {
            state.organizations.filter = payload;
        },
        setOrganizationsSearchFilter(state, { payload }) {
            state.organizations.filter.q.name = payload;
        },
    },

    extraReducers: {
        [getStacks.pending]: (state) => {
            state.stacks.loading = true;
        },
        [getStacks.fulfilled]: (state, { payload }) => {
            state.stacks.loading = false;
            state.stacks.data = payload.data.records;
            state.stacks.totalRecords = payload.data.totalRecords;
        },
        [getDataRequests.pending]: (state) => {
            state.dataRequests.loading = true;
        },
        [getDataRequests.fulfilled]: (state, { payload }) => {
            state.dataRequests.loading = false;
            state.dataRequests.data = payload.data.records;
            state.dataRequests.totalRecords = payload.data.totalRecords;
        },
        [getOrganizations.pending]: (state) => {
            state.organizations.loading = true;
        },
        [getOrganizations.fulfilled]: (state, { payload }) => {
            state.organizations.loading = false;
            state.organizations.data = payload.data.records;
            state.organizations.totalRecords = payload.data.totalRecords;
        },
        [getPlans.fulfilled]: (state, { payload }) => {
            state.organizations.plans = payload.data;
        },
        [getUsers.pending]: (state) => {
            state.users.loading = true;
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            state.users.loading = false;
            state.users.data = payload.data.records;
            state.users.totalRecords = payload.data.totalRecords;
        },
        [getReferrals.pending]: (state) => {
            state.referrals.loading = true;
        },
        [getReferrals.fulfilled]: (state, { payload }) => {
            state.referrals.loading = false;
            state.referrals.data = payload.data.records;
            state.referrals.totalRecords = payload.data.totalRecords;
        },
    },
});

export const {
    setStacksFilter,
    setStacksSearchFilter,
    clearStacksFilter,
    setActiveStack,
    clearActiveStack,
    setDataRequestsFilter,
    clearDataRequestsFilter,
    setOrganizationsFilter,
    setOrganizationsSearchFilter,
    clearOrganizationsFilter,
    setUsersFilter,
    setUsersSearchFilter,
    clearUsersFilter,
    setReferralsFilter,
    clearReferralsFilter,
    setReferralsTotalRecords,
    clearState,
    updateFilter,
} = adminSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const adminSliceSelectors = {
    stacksLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stacks.loading;
    },
    stacksData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stacks.data;
    },
    stacksFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stacks.filter;
    },
    stacksActiveStack: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stacks.activeStack;
    },
    stacksTotalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stacks.totalRecords;
    },
    dataRequestsLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.dataRequests.loading;
    },
    dataRequestsData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.dataRequests.data;
    },
    dataRequestsFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.dataRequests.filter;
    },
    dataRequestsTotalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.dataRequests.totalRecords;
    },
    organizationsLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations.loading;
    },
    organizationsData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations.data;
    },
    organizationsFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations.filter;
    },
    organizationsTotalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations.totalRecords;
    },
    organizationsPlans: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizations.plans;
    },
    usersLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.users.loading;
    },
    usersData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.users.data;
    },
    usersFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.users.filter;
    },
    usersTotalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.users.totalRecords;
    },
    referralsLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.referrals.loading;
    },
    referralsData: (rootState) => {
        const appState = getAppState(rootState);
        return appState.referrals.data;
    },
    referralsFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.referrals.filter;
    },
    referralsTotalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.referrals.totalRecords;
    },
};
