import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostOfLivingApi } from "../../../services/api/Intelligence/CostOfLiving";

export const getCostOfLiving = createAsyncThunk(
    "costOfLiving/getCostOfLiving",
    async ({ currentCityId, comparisonCityId }) => {
        try {
            const { data } = await getCostOfLivingApi(currentCityId, comparisonCityId);

            return { data };
        } catch (error) {
            return error.response.data;
        }
    },
);
