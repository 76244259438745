import React, { useState, useEffect, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import StackedBar from "../Charts/StackedBar";
import DifferenceChart from "../Charts/DifferenceChart";
import StackedBarCategories from "../Charts/StackedBarCategories";
import { ChartContainer } from "../Charts/ChartContainer";
import ChartLegend from "../Charts/ChartLegend";
import {
    stackInsightsSliceSelector,
    ShowModes,
} from "../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getLegend, filterOutInactiveCategories } from "../utils";

const defaultContainerStyle = {
    pt: 4.6,
};

const defaultCategoriesStyle = {
    marginBottom: 7.75,
};

export default function StackedBarChartWithDifference({
    firstStackSeries,
    secondStackSeries,
    differenceSeries,
    stacks,
    categories,
    stackedBarColors,
    diffBarColors,
    itemsPerPage = 15,
    distributed,
    chartInfo,
    inPercentage = true,
    // onCategorySelected,
    showLegend = true,
    showTitles = true,
    titles,
    elevation = 1,
    tooltips,
    enableCustomTooltip,
    containerStyle,
    categoriesStyle,
    padding = [],
    chartStyles,
    flexRatios = [],
    max,
    tickAmount,
    noFormats,
    isDistributed,
    showActions = true,
    v2 = false,
    noXaxis,
    categoriesFlex = 2.2,
    diffHeight = "94.5%",
    diffBarHeight = "66%",
    actionsOffset = { y: -55 },
    showYears = false,
    showGrid,
    categoriesHeight = 439,
    activeCategories,
    paddingBottomPerItem = 24.02,
    xAxisOffsetPerItem = 12,
}) {
    const [page, setPage] = useState(0);
    const [newCategories, newFirstStackSeries, newSecondStackSeries, newDiffSeries] = useMemo(() => {
        if (!itemsPerPage) {
            return [categories, firstStackSeries, secondStackSeries, differenceSeries];
        }
        const tempCategories = [...categories].slice(page * itemsPerPage, (page + 1) * itemsPerPage);
        const tempFirstStackSeries = firstStackSeries.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        const tempSecondStackSeries = secondStackSeries.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        const tempDiffSeries = differenceSeries.map((item) => ({
            name: "Difference",
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        return [tempCategories, tempFirstStackSeries, tempSecondStackSeries, tempDiffSeries];
    });

    const missingItems1 = itemsPerPage - newFirstStackSeries[0].data.length;
    const missingItems2 = itemsPerPage - newSecondStackSeries[0].data.length;
    const missingItems3 = itemsPerPage - newDiffSeries[0].data.length;

    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [legend, setLegend] = useState([]);

    useEffect(() => {
        if (!stacks.length) {
            return;
        }
        setLegend(getLegend(show, split, stacks));
    }, [stacks, split, show]);

    useEffect(() => {
        setPage(0);
    }, [stacks, show, split]);

    const maxValue = useMemo(() => {
        if (max) return max;

        let maxVal = 0;
        for (let i = 0; i < categories.length; i += 1) {
            const rowSum = firstStackSeries.reduce((prev, cur) => prev + cur.data[i], 0);
            maxVal = Math.max(maxVal, rowSum);
        }
        for (let i = 0; i < categories.length; i += 1) {
            const rowSum = secondStackSeries.reduce((prev, cur) => prev + cur.data[i], 0);
            maxVal = Math.max(maxVal, rowSum);
        }

        return maxVal;
    }, [firstStackSeries, secondStackSeries]);

    const inactiveIndices = useMemo(() => {
        if (!activeCategories || !activeCategories.length) return [];

        const indicies = [];
        newCategories.forEach((category, index) => {
            if (activeCategories.findIndex((val) => val.name === category) === -1) indicies.push(index);
        });

        return indicies;
    }, [activeCategories, newCategories]);

    const handleOnUpdated = useMemo(
        () => (context, options) => filterOutInactiveCategories(options, inactiveIndices),
        [inactiveIndices],
    );

    return (
        <ChartContainer
            chartInfo={chartInfo}
            nextPage={() => setPage(page + 1)}
            previousPage={() => setPage(page - 1)}
            page={page}
            lastPage={parseInt(Math.ceil(categories.length / itemsPerPage))}
            data={{ categories, series: [firstStackSeries, secondStackSeries, differenceSeries] }}
            tableViewProps={{ chartType: "StackedBarChartWithDifference", chartTitle: chartInfo.shortTitle }}
            actionsOffset={actionsOffset}
            elevation={elevation}
            showActions={showActions}
        >
            {showLegend && legend && <ChartLegend legend={legend} />}
            <Stack direction="row" mt={2} height="86%" {...chartStyles}>
                <Stack flex={flexRatios[0] ? flexRatios[0] : 5} direction="row">
                    <Stack flex={6}>
                        <StackedBar
                            series={newFirstStackSeries}
                            categories={newCategories}
                            title={showTitles ? (titles ? titles[0] : stacks[0].stack.name) : null}
                            reversed
                            stackname={stacks[0].stack.name}
                            barColors={
                                !distributed
                                    ? stackedBarColors
                                    : typeof stackedBarColors[0] === "string"
                                    ? [stackedBarColors[0]]
                                    : stackedBarColors[0]
                            }
                            padding={
                                padding[0]
                                    ? padding[0]
                                    : { right: 0, bottom: missingItems1 * paddingBottomPerItem, left: 30, top: -25 }
                            }
                            xAxisOffset={missingItems1 * xAxisOffsetPerItem}
                            inPercentage={inPercentage}
                            max={maxValue}
                            tickAmount={tickAmount}
                            noFormats={noFormats}
                            isDistributed={isDistributed}
                            v2={v2}
                            noXaxis={noXaxis}
                            showGrid={showGrid}
                            eventHandlers={{ onUpdated: handleOnUpdated }}
                        />
                    </Stack>
                    <Stack
                        flex={categoriesFlex}
                        maxWidth={250}
                        justifyContent="space-between"
                        height={categoriesHeight}
                    >
                        <StackedBarCategories
                            categories={newCategories}
                            containerStyle={containerStyle || defaultContainerStyle}
                            categoriesStyle={categoriesStyle || defaultCategoriesStyle}
                            tooltips={tooltips}
                            // onClick={(index, name) => onCategorySelected(index, name)}
                            inactiveCategories={!inactiveIndices || !inactiveIndices.length ? null : inactiveIndices}
                            enableCustomTooltip={enableCustomTooltip}
                        />
                        {show === ShowModes.durationYears || showYears ? (
                            <Stack width="100%" alignItems="center">
                                <Typography variant="caption" color="textSecondary">
                                    years
                                </Typography>
                            </Stack>
                        ) : null}
                    </Stack>
                    <Stack flex={6}>
                        <StackedBar
                            series={newSecondStackSeries}
                            categories={newCategories}
                            title={showTitles ? (titles ? titles[1] : stacks[1].stack.name) : null}
                            padding={
                                padding[1]
                                    ? padding[1]
                                    : { left: 5, bottom: missingItems2 * paddingBottomPerItem, right: 40, top: -25 }
                            }
                            xAxisOffset={missingItems2 * xAxisOffsetPerItem}
                            stackname={stacks[1].stack.name}
                            barColors={
                                !distributed
                                    ? stackedBarColors
                                    : typeof stackedBarColors[1] === "string"
                                    ? [stackedBarColors[1]]
                                    : stackedBarColors[1]
                            }
                            inPercentage={inPercentage}
                            max={maxValue}
                            tickAmount={tickAmount}
                            noFormats={noFormats}
                            isDistributed={isDistributed}
                            v2={v2}
                            noXaxis={noXaxis}
                            showGrid={showGrid}
                            eventHandlers={{ onUpdated: handleOnUpdated }}
                        />
                    </Stack>
                </Stack>
                <Stack flex={flexRatios[1] ? flexRatios[1] : 1} height={diffHeight}>
                    <DifferenceChart
                        series={newDiffSeries}
                        barheight={diffBarHeight}
                        categories={newCategories}
                        padding={padding[2] ? padding[2] : { bottom: missingItems3 * paddingBottomPerItem, top: -27 }}
                        title="Difference"
                        inPercentage={inPercentage}
                        years={noFormats ? "" : show === ShowModes.durationYears ? " years" : "%"}
                        isDistributed={isDistributed}
                        colors={diffBarColors}
                        eventHandlers={{ onUpdated: handleOnUpdated }}
                    />
                </Stack>
            </Stack>
        </ChartContainer>
    );
}
