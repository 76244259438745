import React, { useEffect, useMemo } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    MenuItem,
    TextField,
    Divider,
    Box,
    Avatar,
    Typography,
    Grid,
    Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    setEditModalOpen,
    userSliceSelectors,
    setUpdateUser,
    setDeleteModalOpen,
} from "../../../../../slices/User/userSlice";
import { USER_ROLE_OPTIONS, USER_STATUS_OPTIONS } from "../../Filter/filterOptions";
import { editUserSchema } from "./editUserSchema";
import { getUsers, updateUser } from "../../../../../actions/user/user";
import { ROLE_SUPER_ADMIN } from "../../utils";

const useStyles = makeStyles((theme) => ({
    dialogBox: {
        width: 600,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(3),
    },
    profilePicture: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        width: 80,
        height: 80,
        borderRadius: "50%",
    },
    textBox: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    fullName: {
        width: 435,
    },
    row: {
        display: "flex",
    },
    title: {
        fontWeight: 500,
    },
    cityName: {
        marginRight: theme.spacing(0.5),
    },
    timezone: {
        textOverflow: "ellipsis",
        width: 350,
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    deleteUserButton: {
        top: 4,
        left: 5,
        textTransform: "none",
        "&:hover": {
            background: "#405CB9",
            boxShadow: "none",
        },
    },
}));

export default function EditUserModal() {
    const selectedUser = useSelector(userSliceSelectors.updateUser) || {};
    const { id, profile_picture, first_name, last_name, title, roles, status, city, type, department } = selectedUser;
    const filter = useSelector(userSliceSelectors.filter);
    const editModalOpen = useSelector(userSliceSelectors.editModalOpen);
    const role = useMemo(() => {
        if (roles) {
            return type === 2 ? ROLE_SUPER_ADMIN : Object.values(roles)[0];
        }
        return "";
    }, [roles, type]);

    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(setEditModalOpen(false));
        dispatch(setUpdateUser(null));
    };
    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: { role: "", status: "" },
        resolver: yupResolver(editUserSchema),
    });

    useEffect(() => {
        setValue("role", role);
        setValue("status", status);
    }, [role, status]);

    function UserPhoto() {
        if (profile_picture)
            return (
                <Avatar
                    className={classes.profilePicture}
                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profile_picture}`}
                />
            );
        return <Avatar className={classes.profilePicture} />;
    }

    const onSubmit = (data) => {
        const modifiedData = { ...selectedUser, roles: [data.role], status: data.status };
        const onDone = () => {
            dispatch(getUsers(filter));
            handleClose();
        };
        dispatch(updateUser({ id, userData: modifiedData, onDone }));
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        dispatch(setEditModalOpen(false));
        dispatch(setDeleteModalOpen(true));
    };

    return (
        <Dialog onClose={handleClose} open={editModalOpen}>
            <Box className={classes.dialogBox}>
                <DialogTitle>Edit User</DialogTitle>
                <Divider variant="middle" />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container alignItems="center">
                            <UserPhoto />
                            <Box className={classes.textBox}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    spacing={2}
                                    className={classes.fullName}
                                >
                                    <Typography variant="body1" color="textPrimary" display="inline">
                                        {first_name} {last_name}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={classes.deleteUserButton}
                                        onClick={handleDelete}
                                    >
                                        Delete user
                                    </Button>
                                </Stack>

                                <Typography variant="body2" color="textSecondary" className={classes.title}>
                                    {title || "-"}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" className={classes.title}>
                                    {department || "-"}
                                </Typography>
                                <div className={classes.row}>
                                    <Typography variant="body2" color="textSecondary" className={classes.cityName}>
                                        {city?.name || "-"}
                                    </Typography>
                                </div>
                            </Box>
                        </Grid>
                        <div>
                            <Controller
                                name="role"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        variant="outlined"
                                        label="Access Level"
                                        fullWidth
                                        error={Boolean(errors.role)}
                                        disabled
                                    >
                                        {USER_ROLE_OPTIONS.map(({ value, label }) => (
                                            <MenuItem key={value} value={value}>
                                                <Typography variant="body2">{label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            {errors.role && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.role.message}
                                </Typography>
                            )}
                        </div>
                        <div>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        className={classes.textField}
                                        variant="outlined"
                                        label="Status"
                                        fullWidth
                                        disabled={status === "pending"}
                                        error={Boolean(errors.status)}
                                        SelectProps={{
                                            renderValue: (value) => (
                                                <Typography variant="body2">
                                                    {value === "pending" ? "Pending" : USER_STATUS_OPTIONS[value]}
                                                </Typography>
                                            ),
                                        }}
                                    >
                                        {Object.keys(USER_STATUS_OPTIONS).map((value) => (
                                            <MenuItem key={value} value={value}>
                                                <Typography variant="body2">{USER_STATUS_OPTIONS[value]}</Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            {errors.status && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.status.message}
                                </Typography>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            Save Changes
                        </Button>
                    </DialogActions>
                </form>
            </Box>
        </Dialog>
    );
}
