import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    titleBox: {
        width: "100%",
        display: "flex",
        background: theme.palette.common.black,
        padding: "3.75px 8.5px 3.75px 11.25px",
        borderRadius: "0px 11.25px 0px 0px",
    },
    bodyBox: {
        width: "100%",
        display: "flex",
        padding: "7.5px 8.5px 11.25px 11.25px",
    },
    tooltipMessage: {
        whiteSpace: "pre-line",
    },
}));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterNextDelay={500} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.background.secondary,
        fontSize: 12,
        borderRadius: "0 10px 0 10px",
        display: "flex",
        flexDirection: "column",
        boxShadow: theme.shadows[2],
        maxWidth: 195,
        padding: 0,
        margin: 0,
    },
}));

export default function CustomizedTooltips({ title, message, placement, element }) {
    const classes = useStyles();

    return (
        <CustomTooltip
            translate="no"
            title={
                <>
                    <Box className={classes.titleBox}>
                        <Typography variant="tooltipTitle" color="common.white" align="left">
                            {title}
                        </Typography>
                    </Box>
                    <Box className={classes.bodyBox}>
                        <Typography
                            variant="tooltipBody"
                            color="common.black"
                            align="left"
                            className={classes.tooltipMessage}
                        >
                            {message}
                        </Typography>
                    </Box>
                </>
            }
            placement={placement}
        >
            {element}
        </CustomTooltip>
    );
}
