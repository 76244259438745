import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Avatar,
    Stack,
    Typography,
    TextField,
    IconButton,
    MenuItem,
    Divider,
    Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CheckIcon from "@mui/icons-material/Check";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import InviteUserModal from "../../../../Settings/Users/Modals/InviteUser";
import { editOrganizationUsers } from "../../../../../services/api/organization/organization";
import UsersAutocomplete from "../../../../AutocompleteInputs/UsersAutocomplete";
import { adminSliceSelectors } from "../../../../../slices/Admin/adminSlice";
import { getOrganizations } from "../../../../../actions/Admin/Organizations";
import { addMessage } from "../../../../../slices/NotificationSlice/GlobalNotificationSlice";

const useStyles = makeStyles(() => ({
    dialogTitle: {
        marginLeft: 20,
        marginTop: 32,
    },
    tableContainer: {
        maxHeight: 490,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    tableCell: {
        border: "none",
    },
    tableHead: {
        position: "sticky",
        top: 0,
        background: "#f9f9ff",
        zIndex: 1,
    },
    tableHeadDivider: {
        position: "absolute",
        top: 45,
        left: 20,
        width: "98%",
        borderColor: "black",
    },
    profilePicture: {
        width: 35,
        height: 35,
        marginRight: 8,
    },
    fullName: {
        paddingLeft: 52,
    },

    addUserIcon: {
        cursor: "pointer",
        fill: "#616061",
        width: 38,
        height: 38,
    },
    dialogActions: {
        position: "relative",
        bottom: 20,
        marginRight: 30,
    },
    dialogActionsButton: {
        fontSize: 20,
        marginTop: 70,
    },
    addUserTypography: {
        position: "absolute",
        left: 65,
        top: 12,
        background: "lightgrey",
        color: "white",
        width: 50,
        height: 12,
    },
    addExistingUser: {
        border: "none",
        position: "relative",
        maxWidth: 50,
    },
    addExistingUserCell: {
        border: "none",
        minWidth: 620,
        maxWidth: 620,
    },
    accessLevel: {
        minWidth: 109,
        maxWidth: 109,
    },
    accessLevelExistingUser: {
        marginTop: 5,
        left: 305,
        minWidth: 109,
        maxWidth: 109,
    },
    addExistingUserIcon: {
        cursor: "pointer",
        left: 395,
        fill: "#616061",
        width: 38,
        height: 38,
        "&:disabled": {
            color: "red",
        },
    },
    inviteUserButton: {
        bottom: 20,
        left: 30,
        zIndex: 1,
        height: 35,
        "&:hover": {
            background: "#405CB9",
            boxShadow: "none",
        },
        width: 160,
    },
    fullNameCell: {
        minWidth: 220,
        maxWidth: 220,
        border: "none",
    },
    createdAtCell: {
        border: "none",
        minWidth: 120,
        maxWidth: 120,
    },
    emailCell: {
        maxWidth: 360,
        minWidth: 360,
        border: "none",
    },
    statusTextfield: {
        width: 95,
        "& .MuiSelect-select ": {
            maxHeight: 37.13,
        },
    },
    statusTextfieldExistingUser: {
        marginTop: 5,
        left: 345,
        width: 95,
        "& .MuiSelect-select ": {
            maxHeight: 37.13,
        },
    },
}));

const usersAutocompleteStyle = {
    width: 400,
    marginLeft: 8,
};

const USER_STATUS = {
    active: "Active",
    deactivated: "Inactive",
};

export default function UserListModal({ open, onSave, onCancel, organization }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [autocompleteValue, setAutocompleteValue] = useState(null);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [isAddUserClicked, setIsAddUserClicked] = useState(false);
    const [editedUsers, setEditedUsers] = useState([]);
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [insertedUsers, setInsertedUsers] = useState([]);
    const [currentInsertedUser, setCurrentInsertedUser] = useState({ status: "Active" });
    const filter = useSelector(adminSliceSelectors.organizationsFilter);

    const clearState = () => {
        setDeletedUsers([]);
        setEditedUsers([]);
        setIsAddUserClicked(false);
        setCurrentInsertedUser();
        setInsertedUsers([]);
        setAutocompleteValue(null);
    };

    const handleSave = async () => {
        const formatedInsertedUsers = insertedUsers.map((userId) => ({ id: userId }));
        const formatedDeletedUsers = deletedUsers.map((userId) => ({ id: userId }));
        const insertedUsersPayload = {
            action: "insert",
            users: formatedInsertedUsers,
        };
        const deletedUsersPayload = {
            action: "delete",
            users: formatedDeletedUsers,
        };
        const updatedUsersPayload = {
            action: "update",
            users: editedUsers,
        };

        try {
            if (updatedUsersPayload.users.length) {
                await editOrganizationUsers(organization.id, updatedUsersPayload);
            }
            if (deletedUsersPayload.users.length) {
                await editOrganizationUsers(organization.id, deletedUsersPayload);
            }
            if (insertedUsersPayload.users.length) {
                await editOrganizationUsers(organization.id, insertedUsersPayload);
            }

            dispatch(
                addMessage({
                    type: "success",
                    title: "Users edited",
                    message: "Users successfully edited.",
                    visibilityTime: 5000,
                }),
            );
            dispatch(
                getOrganizations({
                    search: filter.q.name || undefined,
                    start: 1,
                    asc: filter.sort || undefined,
                    industry: filter.industry || undefined,
                    organization: filter.organization || undefined,
                    price: filter.price || undefined,
                    plan: filter.plan || undefined,
                }),
            );
        } catch (e) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }

        onSave();
        clearState();
    };

    function UserPhoto(profile_picture) {
        if (profile_picture)
            return (
                <Avatar
                    className={classes.profilePicture}
                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profile_picture}`}
                />
            );
        return <Avatar className={classes.profilePicture} />;
    }

    const handleDeleteUser = (id) => {
        setDeletedUsers(
            deletedUsers.includes(id)
                ? (selectedUser) => selectedUser.filter((user) => user !== id)
                : [...deletedUsers, id],
        );
    };

    const handleEditedUsers = (status, id) => {
        const findUser = editedUsers.find((user) => user.id === id);
        const modifiedArray = editedUsers.map((user) => {
            if (findUser && user.id === findUser.id) {
                return {
                    id: user.id,
                    status,
                };
            }
            return user;
        });
        setEditedUsers(
            editedUsers.length
                ? !findUser
                    ? [
                          ...editedUsers,
                          {
                              id,
                              status,
                          },
                      ]
                    : modifiedArray
                : [
                      {
                          id,
                          status,
                      },
                  ],
        );
    };

    const onUserSelected = (user) => {
        if (user) {
            setCurrentInsertedUser(user[0]);
            setAutocompleteValue(`${user[0]?.first_name} ${user[0]?.last_name}`);
        } else {
            setCurrentInsertedUser(null);
        }
    };

    const handleAddExistingUser = () => {
        setInsertedUsers([...insertedUsers, currentInsertedUser.id]);
        if (currentInsertedUser.statusChanged) {
            setCurrentInsertedUser({ ...currentInsertedUser, statusChanged: null });
            setEditedUsers([
                ...editedUsers,
                {
                    id: currentInsertedUser.id,
                    status: currentInsertedUser.status,
                },
            ]);
        }
        dispatch(
            addMessage({
                type: "success",
                title: "Users added",
                message: `${currentInsertedUser.first_name} ${currentInsertedUser.last_name} has been added to ${organization.full_name}.`,
                visibilityTime: 5000,
            }),
        );
        setCurrentInsertedUser();
        setAutocompleteValue(null);
    };

    return (
        <Dialog
            open={open}
            PaperProps={{
                elevation: 4,
                sx: {
                    width: "100%",
                    maxWidth: 1200,
                    paddingLeft: 2,
                    paddingRight: 2,
                },
            }}
            fullWidth
        >
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="h5">{organization?.full_name || ""} Users</Typography>
            </DialogTitle>
            <DialogContent>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell className={classes.fullNameCell}>
                                    <Typography variant="body1" className={classes.fullName}>
                                        Full Name
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.emailCell}>
                                    <Typography variant="body1">Email </Typography>
                                </TableCell>
                                <TableCell className={classes.createdAtCell}>
                                    <Typography variant="body1">Created at </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1" noWrap>
                                        Access Level
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">Status </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body1">Action </Typography>
                                </TableCell>
                            </TableRow>
                            <Divider className={classes.tableHeadDivider} />
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                            {organization?.users.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.fullNameCell}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            {UserPhoto(item.profile_picture)}
                                            <Typography variant="body1" className={classes.name}>
                                                {item.first_name} {item.last_name}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell className={classes.emailCell}>
                                        <Tooltip title={item.email}>
                                            <Typography variant="body1" color="secondary">
                                                {item.email}{" "}
                                            </Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className={classes.createdAtCell}>
                                        <Typography variant="body1">
                                            {moment(item.created_at).format("DD.MM.YYYY")}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <TextField
                                            value="Access Level"
                                            disabled
                                            size="small"
                                            className={classes.accessLevel}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <TextField
                                            select
                                            defaultValue={item.status}
                                            size="small"
                                            SelectProps={{
                                                renderValue: (value) => (
                                                    <Typography variant="body2">
                                                        {value === "pending" ? "Pending" : USER_STATUS[value]}
                                                    </Typography>
                                                ),
                                            }}
                                            onChange={(e) => handleEditedUsers(e.target.value, item.id)}
                                            className={classes.statusTextfield}
                                        >
                                            {Object.keys(USER_STATUS).map((key) => (
                                                <MenuItem value={key}>
                                                    <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                                                        {USER_STATUS[key]}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <IconButton
                                            onClick={() => handleDeleteUser(item.id)}
                                            sx={{ color: deletedUsers.includes(item.id) ? "red" : "#616061" }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" width="100%">
                    <Stack flex={1}>
                        {isAddUserClicked ? (
                            <Stack width="100%" mt={2} mb={6} ml={3.5} direction="row" alignItems="center">
                                <ControlPointIcon
                                    className={classes.addUserIcon}
                                    onClick={() => setIsAddUserClicked(true)}
                                />
                                <Stack direction="row" alignItems="center">
                                    <UsersAutocomplete
                                        variant="noOrganizationUsers"
                                        onSelected={onUserSelected}
                                        autocompleteStyle={usersAutocompleteStyle}
                                        label="Move Existing User to Organization"
                                        filterData={insertedUsers}
                                        color="secondary"
                                        defaultValue={autocompleteValue}
                                    />
                                    <TextField
                                        value="Access Level"
                                        disabled
                                        size="small"
                                        className={classes.accessLevelExistingUser}
                                    />
                                    <TextField
                                        select
                                        defaultValue="Active"
                                        size="small"
                                        SelectProps={{
                                            renderValue: (value) => (
                                                <Typography variant="body2">
                                                    {value === "pending"
                                                        ? "Pending"
                                                        : currentInsertedUser
                                                        ? USER_STATUS[currentInsertedUser?.status]
                                                        : ""}
                                                </Typography>
                                            ),
                                        }}
                                        className={classes.statusTextfieldExistingUser}
                                        onChange={(e) =>
                                            setCurrentInsertedUser({
                                                ...currentInsertedUser,
                                                status: e.target.value,
                                                statusChanged: true,
                                            })
                                        }
                                    >
                                        {Object.keys(USER_STATUS).map((key) => (
                                            <MenuItem value={key}>
                                                <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                                                    {USER_STATUS[key]}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <IconButton
                                        className={classes.addExistingUserIcon}
                                        disabled={
                                            !currentInsertedUser?.id ||
                                            !currentInsertedUser?.status ||
                                            Object.keys(currentInsertedUser).length === 0
                                        }
                                        onClick={handleAddExistingUser}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        ) : (
                            <Stack width="100%" mt={2} mb={6} ml={3.5} direction="row" alignItems="center">
                                <Typography variant="body5" align="center" className={classes.addUserTypography}>
                                    Add User
                                </Typography>
                                <ControlPointIcon
                                    className={classes.addUserIcon}
                                    onClick={() => setIsAddUserClicked(true)}
                                />
                            </Stack>
                        )}
                        <Button
                            variant="contained"
                            onClick={() => setInviteModalOpen(true)}
                            className={classes.inviteUserButton}
                        >
                            Invite new User
                        </Button>
                    </Stack>
                    <Stack flex={1} justifyContent="flex-end" direction="row">
                        <Button
                            color="primary"
                            onClick={() => {
                                onCancel();
                                clearState();
                            }}
                            className={classes.dialogActionsButton}
                        >
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleSave} className={classes.dialogActionsButton}>
                            Save Changes
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
            <InviteUserModal
                open={inviteModalOpen}
                handleClose={() => setInviteModalOpen(false)}
                organizationId={organization?.id}
            />
        </Dialog>
    );
}
