import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TagsField } from "./fields/Tags";
import { SizeField } from "./fields/Size";
import { ColorField } from "./fields/Colors";
import { IndustryField } from "./fields/Industry";
import { JobPositionsField } from "./fields/JobPositions";
import { clearFilter, setSearchValue, stackSliceSelectors } from "../../../../slices/Stacks/stackSlice";
import SearchComponent from "../../../Search";

function Filter() {
    const filter = useSelector(stackSliceSelectors.getFilter);
    const dispatch = useDispatch();

    const clearFilterAndSearch = () => {
        dispatch(clearFilter());
    };

    const onSearch = (value) => {
        dispatch(setSearchValue(value));
    };

    return (
        <Box p={2.5}>
            <SearchComponent
                value={filter?.q?.name}
                onSearch={onSearch}
                tooltipTitle="Search of the name of a CV Stack to narrow down the list of Stacks."
                label="Search here..."
            />
            <Box mt={3} mb={3}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="text.onPrimary">
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                        <Button variant="text" color="primary" size="small" onClick={clearFilterAndSearch}>
                            CLEAR ALL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} mb={3}>
                <TagsField />
            </Box>
            <Box mt={3} mb={3}>
                <SizeField />
            </Box>
            <Box mt={3} mb={3}>
                <ColorField />
            </Box>
            <Box mt={3} mb={3}>
                <IndustryField />
            </Box>
            <Box mt={3} mb={3}>
                <JobPositionsField />
            </Box>
        </Box>
    );
}

export default Filter;
