export const getColor = (stack) => {
    let color = stack.color;
    if (!color) {
        if (stack?.tags.indexOf("automotive") > -1) {
            color = "blue";
        } else if (stack?.tags.indexOf("consulting") > -1) {
            color = "yellow";
        }
        color = "grey";
    }

    return color;
};

export const getCategoryBySize = (companySize) => {
    if (companySize <= 50) return "1-50";
    else if (companySize > 50 && companySize <= 250) return "51-250";
    else if (companySize > 250 && companySize <= 500) return "251-500";
    else if (companySize > 500 && companySize <= 1000) return "501-1000";
    else if (companySize > 1000 && companySize <= 5000) return "1001-5000";
    else if (companySize > 5000 && companySize <= 10000) return "5001-10000";

    return "10000+";
};
