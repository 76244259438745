import React from "react";
import { Stack } from "@mui/material";
import OsterusLoader from "../../components/Loader";

export default function LoadingPage() {
    return (
        <Stack justifyContent="center" sx={{ width: "100%", height: "50vh" }}>
            <OsterusLoader />
        </Stack>
    );
}
