import React, { useState } from "react";
import { Button, Dialog, DialogContent, Typography, Slider, IconButton, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 350,
        height: 520,
        padding: theme.spacing(1),
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        overflowY: "unset",
    },
    dropzoneContainer: {
        cursor: "pointer",
        marginTop: theme.spacing(2),
        width: 300,
        height: 300,
        border: "solid 1px rgba(0, 0, 0, 0.23)",
    },
    input: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputActive: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgb(235, 244, 253)",
    },
    dropzoneText: {
        color: theme.palette.secondary[400],
    },
    bottomRow: {
        height: theme.spacing(5),
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    previewImageContainer: {
        width: "100%",
        height: "100%",
        position: "relative",
    },
    sliderRow: {
        width: 130,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    sliderContainer: {
        width: 60,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        display: "flex",
        alignItems: "center",
    },
    boxContainer: {
        width: "100%",
    },
    titleContainer: {
        padding: "16px 0 16px 16px",
    },
    divider: {
        margin: "0 16px",
    },
    dialogActionsButton: {
        width: 92,
    },
}));

export default function PhotoUpload({ open, picture, title, handleClose, handleSave, handleRemove }) {
    const [previewImage, setPreviewImage] = useState(null);
    const [cropValues, setCropValues] = useState({ x: 0, y: 0 });
    const [zoomValue, setZoomValue] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const classes = useStyles();

    const onDrop = (images) => {
        setPreviewImage(null);
        if (images.length > 0) {
            setPreviewImage(
                Object.assign(images[0], {
                    preview: URL.createObjectURL(images[0]),
                }),
            );
        }
    };

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        accept: "image/*",
        maxSize: 1050000,
    });

    const onCropChange = (crop) => {
        setCropValues(crop);
    };

    const onCropComplete = (croppedArea, croppedAreaPixelsValue) => {
        setCroppedAreaPixels(croppedAreaPixelsValue);
    };

    const onZoomChange = (zoom) => {
        if (zoom < 5 && zoom >= 1) {
            setZoomValue(zoom);
        }
    };

    const clearValues = () => {
        setPreviewImage(null);
        setCropValues({ x: 0, y: 0 });
        setZoomValue(1);
        setCroppedAreaPixels(null);
    };

    const onCancel = () => {
        clearValues();
        handleClose();
    };

    const onSave = async () => {
        if (!previewImage && !croppedAreaPixels) return;
        try {
            const croppedImageBlob = await getCroppedImg(previewImage.preview, croppedAreaPixels, previewImage.name);
            const imageFile = new File([croppedImageBlob], previewImage.name, {
                type: previewImage.type,
            });
            handleSave(imageFile);
        } catch (e) {
            throw e;
        }
        clearValues();
        handleClose();
    };

    const onRemove = () => {
        clearValues();
        handleRemove();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={onCancel} PaperProps={{ elevation: 4 }}>
            <div className={classes.container}>
                <Box className={classes.boxContainer}>
                    <Box className={classes.titleContainer}>
                        <Typography variant="h6" color="primary">
                            {title || "Profile Picture"}
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                </Box>
                <DialogContent className={classes.dialogContent}>
                    <Typography variant="caption">
                        For best results, upload images of at least 1000x1000 pixels. 1MB file-size limit.
                    </Typography>
                    <div className={classes.dropzoneContainer}>
                        {!previewImage ? (
                            <div className={!isDragActive ? classes.input : classes.inputActive} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div>
                                    <Typography variant="caption">
                                        Drag and drop an image here or <br />
                                        <span className={classes.dropzoneText}>click here to browse</span> your device.
                                    </Typography>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.previewImageContainer}>
                                <Cropper
                                    image={previewImage.preview}
                                    crop={cropValues}
                                    zoom={zoomValue}
                                    aspect={1}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropChange={onCropChange}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={onZoomChange}
                                    objectFit="horizontal-cover"
                                />
                            </div>
                        )}
                        <div className={classes.bottomRow}>
                            {!previewImage ? (
                                <div>
                                    {fileRejections.length > 0 && (
                                        <Typography color="error" variant="caption">
                                            Invalid file
                                        </Typography>
                                    )}
                                </div>
                            ) : (
                                <div className={classes.sliderRow}>
                                    <IconButton size="small" onClick={() => onZoomChange(zoomValue - 0.1)}>
                                        <RemoveIcon />
                                    </IconButton>
                                    <div className={classes.sliderContainer}>
                                        <Slider
                                            color="secondary"
                                            value={zoomValue}
                                            min={1}
                                            max={5}
                                            step={0.1}
                                            aria-labelledby="Zoom"
                                            onChange={(e, zoom) => onZoomChange(zoom)}
                                            sx={{
                                                "& .MuiSlider-thumb": {
                                                    width: 15,
                                                    height: 15,
                                                },
                                            }}
                                        />
                                    </div>
                                    <IconButton size="small" onClick={() => onZoomChange(zoomValue + 0.1)}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            )}
                            <div style={{ marginRight: -16 }}>
                                {!previewImage && picture && (
                                    <Button
                                        color="error"
                                        variant="text"
                                        className={classes.dialogActionsButton}
                                        onClick={onRemove}
                                    >
                                        Remove
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    variant="text"
                                    className={classes.dialogActionsButton}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="text"
                                    className={classes.dialogActionsButton}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </Dialog>
    );
}
