import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { split3Digits } from "../../utils";
import { CityInfo } from "../../../components/CityInfo";
import { costOfLivingSelectors } from "../../../../../slices/Intelligence/CostOfLivingSlice";
import RankingSection from "./Sections/RankingSection";
import ProfilesSection from "./Sections/ProfilesSection";
import LanguagesSection from "./Sections/LanguagesSection";
import EmployersSection from "./Sections/EmployersSection";
import ExperienceSection from "./Sections/ExperienceSection";
import EducationSection from "./Sections/EducationSection";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        paddingBottom: "35px",
    },
    dataContainer: {
        flex: 2,
        maxWidth: 660,
        overflow: "auto",
        paddingBottom: 10,
    },
    pricierMetric: {
        color: theme.palette.primary[100],
        transition: "color .4s",
    },
    cheaperMetric: {
        color: theme.palette.primary[400],
        transition: "color .4s",
    },
    smallerScore: {
        color: theme.palette.primary[100],
    },
    higherScore: {
        color: theme.palette.primary[400],
    },
    icon: {
        fontSize: 50,
    },
}));

export default function BusinessPotential({ data, localPurchasingPower }) {
    const isLoading = useSelector(costOfLivingSelectors.loading);
    const currentCity = useSelector(costOfLivingSelectors.currentCity);
    const comparisonCity = useSelector(costOfLivingSelectors.comparisonCity);

    const [currency, setCurrency] = useState("");
    const [currentCityIncome, setCurrentCityIncome] = useState(0);
    const [comparisonCityIncome, setComparisonCityIncome] = useState(0);

    useEffect(() => {
        if (isLoading || !data) return;

        setCurrency(localPurchasingPower.currentCitySalary.primary.currency);
        setCurrentCityIncome(split3Digits(Math.round(localPurchasingPower.currentCitySalary.primary.value)));
        setComparisonCityIncome(split3Digits(Math.round(localPurchasingPower.comparisonCitySalary.primary.value)));
    }, [isLoading]);

    const theme = useTheme();
    const classes = useStyles();

    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="center" width="100%" height="100%">
            {data && currentCity && comparisonCity ? (
                <div className={classes.mainContainer}>
                    <CityInfo
                        image={currentCity.image}
                        title="Salary"
                        subtitle={currency}
                        value={currentCityIncome}
                        name={currentCity.name}
                        iso2Code={currentCity?.country?.iso2}
                    />
                    <div className={classes.dataContainer}>
                        <RankingSection
                            data={data}
                            currentCity={currentCity}
                            comparisonCity={comparisonCity}
                            theme={theme}
                            classes={classes}
                        />

                        <ProfilesSection data={data} classes={classes} theme={theme} />

                        <LanguagesSection
                            data={data}
                            currentCity={currentCity}
                            comparisonCity={comparisonCity}
                            theme={theme}
                            classes={classes}
                        />

                        <EmployersSection data={data} />

                        <ExperienceSection
                            data={data}
                            currentCity={currentCity}
                            comparisonCity={comparisonCity}
                            theme={theme}
                            classes={classes}
                        />

                        <EducationSection
                            data={data}
                            currentCity={currentCity}
                            comparisonCity={comparisonCity}
                            theme={theme}
                            classes={classes}
                        />
                    </div>
                    <CityInfo
                        image={comparisonCity.image}
                        title="Salary"
                        subtitle={currency}
                        value={comparisonCityIncome}
                        name={comparisonCity.name}
                        iso2Code={comparisonCity?.country?.iso2}
                    />
                </div>
            ) : null}
        </Stack>
    );
}
