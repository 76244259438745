export const EMPLOYMENT_VIEW = "Employment View";
export const CUSTOMIZABLE_VIEW = "Customizable View";

export const OVERVIEW_COMPARISON_INSIGHT = "overview_comparison_insights";
export const OVERVIEW_SINGLE_INSIGHT = "overview_single_insights";

export const EMPLOYMENT_COMPARISON_INSIGHT = "employment_comparison_insights";
export const EMPLOYMENT_SINGLE_INSIGHT = "employment_single_insights";

export const EDUCATION_COMPARISON_INSIGHT = "education_comparison_insights";
export const EDUCATION_SINGLE_INSIGHT = "education_single_insights";
export const DEMOGRAPHICS_COMPARISON_INSIGHT = "demographic_comparison_insights";
export const DEMOGRAPHICS_SINGLE_INSIGHT = "demographic_single_insights";
