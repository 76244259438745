import React from "react";
import { Person } from "@mui/icons-material";
import { Stack } from "@mui/material";

export function Profiles({ value }) {
    const colors = ["#fe3358", "#f57c00", "#388e3c"];
    const persons = [0, 1, 2].map((person, index) => {
        if (value < 100) {
            return <Person key={person} sx={{ color: index === 0 ? colors[0] : "gray" }} />;
        }
        if (value < 500) {
            return <Person key={person} sx={{ color: index < 2 ? colors[1] : "gray" }} />;
        }
        return <Person key={person} sx={{ color: colors[2] }} />;
    });

    return (
        <Stack direction="row" spacing={2}>
            {persons}
        </Stack>
    );
}
