import React, { useState } from "react";
import { navigate } from "@gatsbyjs/reach-router";
import { Stack, Tabs, Tab, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    EMPLOYMENT_COMPARISON_INSIGHT,
    EMPLOYMENT_SINGLE_INSIGHT,
    EDUCATION_COMPARISON_INSIGHT,
    EDUCATION_SINGLE_INSIGHT,
    OVERVIEW_COMPARISON_INSIGHT,
    OVERVIEW_SINGLE_INSIGHT,
    DEMOGRAPHICS_COMPARISON_INSIGHT,
    DEMOGRAPHICS_SINGLE_INSIGHT,
} from "../Views/viewConstants";

const useStyles = makeStyles(() => ({
    tabs: {
        "& button": {
            border: 0,
            backgroundColor: "white",
            borderRadius: 10,
            textTransform: "none",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: "#5e60e1",
            color: "white",
            borderRadius: 10,
        },
        "& .MuiButtonBase-root": {
            padding: "0 5px",
            minHeight: "35px",
        },
        "& .MuiTab-root": {
            pointerEvents: "auto",
        },
    },
}));

export function SharedTabHeader({ viewType, selectedStacks, onViewSwitch, isSingle }) {
    const [currentTab, setCurrentTab] = useState(viewType);
    const classes = useStyles();

    const onTabChanged = (e, tab) => {
        if (onViewSwitch) onViewSwitch(tab);
        setCurrentTab(tab);
        const code = new URLSearchParams(window.location.search).get("code");
        navigate(`/shared/${tab}?code=${code}`);
    };

    return (
        <>
            {selectedStacks.length && (
                <Stack margin="auto" mt={1} mb={0} pb={0} mx={2}>
                    <Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingY={1}
                            position="relative"
                        >
                            <Tabs className={classes.tabs} value={currentTab} onChange={onTabChanged}>
                                <Tab
                                    value={isSingle ? OVERVIEW_SINGLE_INSIGHT : OVERVIEW_COMPARISON_INSIGHT}
                                    label="Overview"
                                />
                                <Tab
                                    value={isSingle ? EMPLOYMENT_SINGLE_INSIGHT : EMPLOYMENT_COMPARISON_INSIGHT}
                                    label="Jobs and Experience"
                                />
                                <Tab
                                    value={isSingle ? EDUCATION_SINGLE_INSIGHT : EDUCATION_COMPARISON_INSIGHT}
                                    label="Education"
                                />
                                <Tab
                                    value={isSingle ? DEMOGRAPHICS_SINGLE_INSIGHT : DEMOGRAPHICS_COMPARISON_INSIGHT}
                                    label="Demographics"
                                />
                                <Tab
                                    value={5}
                                    label={
                                        <Tooltip title="Coming soon.">
                                            <span>Skills and Salaries</span>
                                        </Tooltip>
                                    }
                                    disabled
                                />
                                <Tab
                                    value={6}
                                    label={
                                        <Tooltip title="Coming soon.">
                                            <span>Employees Turnover and Churn</span>
                                        </Tooltip>
                                    }
                                    disabled
                                />
                            </Tabs>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
}
