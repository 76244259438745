import React, { useState } from "react";
import { TableCell, Typography, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { updateOrganizationApi } from "../../services/api/organization/organization";
import { adminSliceSelectors } from "../../slices/Admin/adminSlice";
import { getOrganizations } from "../../actions/Admin/Organizations";

const useStyles = makeStyles(() => ({
    hoverableText: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    textField: {
        width: 180,
    },
}));

export function HoverableText({ cell, index }) {
    const classes = useStyles();

    return (
        <TableCell key={index} align="right">
            <Typography variant="body2" onClick={cell.callback} className={classes.hoverableText}>
                {cell.text}
            </Typography>
        </TableCell>
    );
}

export function NoteText({ cell, index }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isClicked, setIsClicked] = useState(false);
    const [note, setNote] = useState(cell.text);
    const filter = useSelector(adminSliceSelectors.organizationsFilter);

    const onClose = async () => {
        setIsClicked(false);
        await updateOrganizationApi(cell.organizationId, { note });
        dispatch(
            getOrganizations({
                search: filter.q.name || undefined,
                start: 1,
                asc: filter.sort || undefined,
                industry: filter.industry || undefined,
                organization: filter.organization || undefined,
                price: filter.price || undefined,
                plan: filter.plan || undefined,
            }),
        );
    };

    return (
        <TableCell
            key={index}
            onClick={() => {
                setIsClicked(true);
                cell.callback();
            }}
            align="right"
        >
            {isClicked ? (
                <TextField
                    id="outlined-multiline-flexible"
                    multiline
                    maxRows={4}
                    defaultValue={cell.text}
                    onChange={(e) => setNote(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            onClose();
                        }
                    }}
                    onClose={onClose}
                    className={classes.textField}
                />
            ) : (
                <Typography variant="body2">{cell.text}</Typography>
            )}
        </TableCell>
    );
}
