import React, { useState, useEffect, useRef } from "react";
import { Dialog, Stack, Typography, Button, TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "none",
        padding: theme.spacing(1),
    },
}));

export function ReferralEditModal({ referral, open, onSave, onCancel }) {
    const note = referral?.note;
    const textareaRef = useRef(null);
    const [error, setError] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        setError(null);
    }, [open]);

    const handleSaveClicked = () => {
        const newNote = textareaRef.current.value;
        if (!validate()) return;

        if (onSave) {
            onSave(newNote);
        }
    };

    const validate = () => {
        const newNote = textareaRef.current.value;

        let isValid;
        if (newNote.length > 255) {
            if (!error) setError("Note must not be greater than 255 characters");
            isValid = false;
        } else if (error) {
            setError(null);
            isValid = true;
        } else isValid = true;

        return isValid;
    };

    return (
        <Dialog open={open} PaperProps={{ elevation: 4 }}>
            <Stack m={1} sx={{ width: 500 }}>
                <Typography variant="h6" mx={2} my={2}>
                    Edit Referral
                </Typography>

                <Stack mx={2}>
                    <TextareaAutosize
                        ref={textareaRef}
                        defaultValue={note}
                        placeholder="Note..."
                        minRows={6}
                        maxRows={6}
                        className={classes.textarea}
                        onChange={validate}
                    />
                    {error && (
                        <Typography color="error" variant="subtitle2">
                            {error}
                        </Typography>
                    )}
                </Stack>

                <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2} mr={2} mb={0.5}>
                    <Button variant="outlined" onClick={() => onCancel()} disableElevation>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSaveClicked} disableElevation>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
}
