import React, { useState, useMemo, useEffect } from "react";
import Chart from "react-apexcharts";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ChartContainer } from "../Charts/ChartContainer";
import ChartLegend from "../Charts/ChartLegend";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
} from "../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getLegend, filterOutInactiveCategories } from "../utils";
import NoData from "../assets/noData.svg";

const generateChartOptions = ({
    categories1,
    categories2,
    series1,
    series2,
    barColors,
    padding,
    xAxisOffset,
    distributed,
    max,
    stacks,
    barHeight,
    onUpdatedLeftChart,
    onUpdatedRightChart,
}) => {
    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const options = {
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                barHeight,
                horizontal: true,
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
            },
        },
        legend: {
            show: false,
        },
        fill: {
            opacity: 1,
        },
    };

    const chartOptions1 = {
        ...options,
        chart: {
            ...options.chart,
            events: {
                updated: onUpdatedLeftChart,
            },
        },
        colors: !distributed ? barColors : [barColors[0]],
        plotOptions: {
            ...options.plotOptions,
            bar: {
                ...options.plotOptions.bar,
                borderRadius: [2],
            },
        },
        xaxis: {
            max: max * 1.1,
            tickAmount: categories1.length ? 5 : -1,
            categories: categories1,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                offsetY: xAxisOffset[0],
                formatter: (value) => {
                    if (show === ShowModes.durationYears) {
                        return value > 0 && value <= 2
                            ? value.toFixed(2)
                            : value > 0 && value < 10
                            ? value.toFixed(1)
                            : value.toFixed(0);
                    }
                    return value > 0 && value <= 2
                        ? `${value.toFixed(2)}%`
                        : value > 0 && value < 10
                        ? `${value.toFixed(1)}%`
                        : `${value.toFixed(0)}%`;
                },
                style: {
                    colors: ["#ABABAB"],
                },
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            enabledOnSeries: [series1.length - 1],
            style: {
                colors: ["#7B7B7B"],
            },
            offsetX: show === ShowModes.durationYears ? 0.1 : 0.2,
            formatter: (value, { dataPointIndex: index }) => {
                const sumPercent = series1.reduce((prev, cur) => prev + cur.data[index], 0);
                const lastVal = series1[series1.length - 1].data[index];

                // \u2000 = one of the Unicode spaces(apexcharts library trims regular spaces).
                let spacing;
                if (sumPercent === 0) {
                    spacing = "";
                } else if (lastVal / max < 0.01) {
                    spacing = "\u2000\u2000";
                } else {
                    spacing = "\u2000";
                }

                return show === ShowModes.durationYears
                    ? `${spacing}${sumPercent.toFixed(2)}`
                    : `${spacing}${sumPercent.toFixed(2)}%`;
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: !!categories1.length,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: -10,
                right: 15,
                ...padding[0],
            },
        },
        yaxis: {
            labels: {
                show: !!categories1.length,
                minWidth: 220,
                maxWidth: 220,
                align: "right",
            },
        },
        tooltip: {
            inverseOrder: true,
            shared: true,
            intersect: false,
            x: {
                formatter: (value, { dataPointIndex }) =>
                    split === SplitModes.none ? stacks[0].stack.name : categories1[dataPointIndex],
            },
            y: {
                formatter: (value) =>
                    show !== ShowModes.durationYears ? `${value.toFixed(2)}%` : `${value.toFixed(2)} years`,
                title: {
                    formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
                        if (!series1[seriesIndex].name) {
                            return categories1[dataPointIndex];
                        }
                        return seriesName;
                    },
                },
            },
        },
    };

    const chartOptions2 = {
        ...options,
        chart: {
            ...options.chart,
            events: {
                updated: onUpdatedRightChart,
            },
        },
        colors: !distributed ? barColors : [barColors[1]],
        plotOptions: {
            ...options.plotOptions,
            bar: {
                ...options.plotOptions.bar,
                borderRadius: [2],
            },
        },
        xaxis: {
            max: max * 1.1,
            tickAmount: categories2.length ? 5 : -1,
            categories: categories2,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                offsetY: xAxisOffset[1],
                formatter: (value) => {
                    if (show === ShowModes.durationYears) {
                        return value > 0 && value <= 2
                            ? value.toFixed(2)
                            : value > 0 && value < 10
                            ? value.toFixed(1)
                            : value.toFixed(0);
                    }
                    return value > 0 && value <= 2
                        ? `${value.toFixed(2)}%`
                        : value > 0 && value < 10
                        ? `${value.toFixed(1)}%`
                        : `${value.toFixed(0)}%`;
                },
                style: {
                    colors: ["#ABABAB"],
                },
            },
        },
        yaxis: {
            reversed: true,
            // Needed because yaxis will otherwise switch sides when data updates.
            opposite: true,
            labels: {
                show: !!categories2.length,
                minWidth: 220,
                maxWidth: 220,
            },
        },
        grid: {
            xaxis: {
                lines: {
                    show: !!categories2.length,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                top: -10,
                left: 15,
                right: -10,
                ...padding[1],
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: "end",
            enabledOnSeries: [series2.length - 1],
            style: {
                colors: ["#7B7B7B"],
            },
            offsetX: show === ShowModes.durationYears ? 0.1 : 0.2,
            formatter: (value, { dataPointIndex: index }) => {
                const sumPercent = series2.reduce((prev, cur) => prev + cur.data[index], 0);
                const lastVal = series2[series2.length - 1].data[index];

                // \u2000 = one of the Unicode spaces(apexcharts library trims regular spaces).
                let spacing;
                if (sumPercent === 0) {
                    spacing = "";
                } else if (lastVal / max < 0.01) {
                    spacing = "\u2000\u2000";
                } else {
                    spacing = "\u2000";
                }

                return show === ShowModes.durationYears
                    ? `${sumPercent.toFixed(2)}${spacing}`
                    : `${sumPercent.toFixed(2)}%${spacing}`;
            },
        },
        tooltip: {
            inverseOrder: true,
            shared: true,
            intersect: false,
            x: {
                formatter: (value, { dataPointIndex }) =>
                    split === SplitModes.none ? stacks[1].stack.name : categories2[dataPointIndex],
            },
            y: {
                formatter: (value) =>
                    show !== ShowModes.durationYears ? `${value.toFixed(2)}%` : `${value.toFixed(2)} years`,
                title: {
                    formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
                        if (!series2[seriesIndex].name) {
                            return categories2[dataPointIndex];
                        }
                        return seriesName;
                    },
                },
            },
        },
    };

    return [chartOptions1, chartOptions2];
};

export default function ComparisonBarChart({
    chartInfo,
    stack1,
    stack2,
    color = null,
    barColors,
    itemsPerPage = 10,
    height,
    distributed,
    stacks,
    showLegend = true,
    stackHeight = 380,
    barHeight = "55%",
    paddingBottomPerItem,
    elevation = 1,
    containerStyles,
    actionsOffset = { y: -55 },
    activeCategories,
}) {
    const categories1 = stack1.categories;
    const categories2 = stack2.categories;
    const series1 = stack1.series;
    const series2 = stack2.series;
    const [page, setPage] = useState(0);

    const maxValue = useMemo(() => {
        let max1 = 0;
        for (let i = 0; i < categories1.length; i += 1) {
            const rowSum = series1.reduce((prev, cur) => prev + cur.data[i], 0);
            max1 = Math.max(max1, rowSum);
        }
        let max2 = 0;
        for (let i = 0; i < categories2.length; i += 1) {
            const rowSum = series2.reduce((prev, cur) => prev + cur.data[i], 0);
            max2 = Math.max(max2, rowSum);
        }
        return Math.max(max1, max2);
    }, [series1, series2]);

    const [newCategories1, newCategories2, newSeries1, newSeries2] = useMemo(() => {
        if (!itemsPerPage) {
            return [categories1, categories2, series1, series2];
        }
        const tempCategories1 = [...categories1].slice(page * itemsPerPage, (page + 1) * itemsPerPage);
        const tempCategories2 = [...categories2].slice(page * itemsPerPage, (page + 1) * itemsPerPage);
        const tempSeries1 = series1.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        const tempSeries2 = series2.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        return [tempCategories1, tempCategories2, tempSeries1, tempSeries2];
    }, [page, series1, series2, categories1, categories2]);

    const dataPointPadding = paddingBottomPerItem ?? 31.23;
    const dataPointXAxisOffset = 15.61;

    const missingItems1 = itemsPerPage - newSeries1[0].data.length;
    const missingItems2 = itemsPerPage - newSeries2[0].data.length;

    const padding = [{ bottom: missingItems1 * dataPointPadding }, { bottom: missingItems2 * dataPointPadding }];
    const xAxisOffset = [missingItems1 * dataPointXAxisOffset, missingItems2 * dataPointXAxisOffset];

    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [legend, setLegend] = useState([]);

    useEffect(() => {
        setPage(0);
    }, [stacks, show, split]);

    useEffect(() => {
        if (!stacks.length) {
            return;
        }
        setLegend(getLegend(show, split, stacks));
    }, [stacks, split, show]);

    const inactiveIndicesLeft = useMemo(() => {
        if (!activeCategories || !activeCategories.length) return [];

        const indicies = [];
        newCategories1.forEach((category, index) => {
            if (activeCategories.findIndex((val) => val.name === category) === -1) indicies.push(index);
        });

        return indicies;
    }, [activeCategories, newCategories1]);

    const inactiveIndicesRight = useMemo(() => {
        if (!activeCategories || !activeCategories.length) return [];

        const indicies = [];
        newCategories2.forEach((category, index) => {
            if (activeCategories.findIndex((val) => val.name === category) === -1) indicies.push(index);
        });

        return indicies;
    }, [activeCategories, newCategories2]);

    const handleOnUpdatedLeft = useMemo(
        () => (context, options) => filterOutInactiveCategories(options, inactiveIndicesLeft, true),
        [inactiveIndicesLeft],
    );

    const handleOnUpdatedRight = useMemo(
        () => (context, options) => filterOutInactiveCategories(options, inactiveIndicesRight, true),
        [inactiveIndicesRight],
    );

    const [options1, options2] = generateChartOptions({
        categories1: newCategories1,
        categories2: newCategories2,
        series1: newSeries1,
        series2: newSeries2,
        barColors,
        padding,
        xAxisOffset,
        distributed,
        max: maxValue,
        stacks,
        barHeight,
        onUpdatedLeftChart: handleOnUpdatedLeft,
        onUpdatedRightChart: handleOnUpdatedRight,
    });

    return (
        <>
            <ChartContainer
                color={color}
                chartInfo={chartInfo}
                nextPage={() => setPage(page + 1)}
                previousPage={() => setPage(page - 1)}
                page={page}
                lastPage={parseInt(Math.ceil(Math.min(categories1.length, categories2.length) / itemsPerPage))}
                actionsOffset={actionsOffset || { y: -55 }}
                showTableView={false}
                elevation={elevation}
                containerStyles={containerStyles}
            >
                {legend && showLegend && <ChartLegend legend={legend} />}
                <Stack direction="row" gap={2.5} width="100%" height={stackHeight} mb={4} position="relative">
                    <Stack width="100%">
                        <Typography variant="h6" color="textSecondary" align="right" mr={5}>
                            {stacks[0].stack.name}
                        </Typography>
                        {series1[0].data.length ? (
                            <Chart type="bar" series={newSeries1} options={options1} width="100%" height={height} />
                        ) : (
                            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                                <img src={NoData} alt="no-data" />
                                <Typography variant="body2" color="textSecondary">
                                    No results match filter criteria
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" color="textSecondary" align="left" ml={5}>
                            {stacks[1].stack.name}
                        </Typography>
                        {series2[0].data.length ? (
                            <Chart type="bar" series={newSeries2} options={options2} width="100%" height={height} />
                        ) : (
                            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
                                <img src={NoData} alt="no-data" />
                                <Typography variant="body2" color="textSecondary">
                                    No results match filter criteria
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                    {show === ShowModes.durationYears && (
                        <Stack position="absolute" bottom={-27} width="100%" alignItems="center">
                            <Typography variant="caption" color="textSecondary">
                                years
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </ChartContainer>
        </>
    );
}
