import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/lab";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getTimeZones } from "@vvo/tzdb";
import { useDispatch, useSelector } from "react-redux";
import { setTimeZone } from "../../../slices/City/citySlice";
import { userSliceSelectors } from "../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: theme.spacing(50),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
    },
    button: {
        textTransform: "none",
    },
    hoverElement: {
        "&:hover": {
            backgroundColor: "#D1DBFF !important",
        },
    },
}));

const listStyle = {
    background: "white",
};

const options = getTimeZones();

export const formatTimeZone = (option) => {
    if (!option || typeof option === "string" || typeof option === "number") {
        return option;
    }
    const timeZoneData = option.currentTimeFormat.split(" - ");
    const timeZoneOffset = timeZoneData[0].split(" ")[0];
    return `(GMT${timeZoneOffset}) ${timeZoneData[1]}`;
};

function TimeZoneAutocomplete({ defaultValue = null, handleSave = null, label }) {
    const [value, setValue] = useState(defaultValue);
    const { timezone } = useSelector(userSliceSelectors.defaultUserFields);
    const dispatch = useDispatch();
    const classes = useStyles();

    const renderOptions = (props, option) => {
        const optionString = formatTimeZone(option);
        return (
            <li {...props} style={listStyle} translate="no">
                <Typography>{optionString}</Typography>
            </li>
        );
    };

    const onChange = (event, currentValue) => {
        setValue(currentValue);
        const formattedTimeZone = formatTimeZone(currentValue === null ? "0" : currentValue);
        dispatch(setTimeZone(formattedTimeZone));
    };

    const onSave = () => {
        const formattedTimeZone = formatTimeZone(value || "N/A");
        handleSave({ timezone: formattedTimeZone });
    };

    useEffect(() => {
        setValue(timezone && timezone !== "0" ? timezone : "N/A");
    }, []);

    const handleOnFocus = () => {
        if (value === "N/A") setValue("");
    };

    const handleOnBlur = () => {
        if (!value) setValue("N/A");
    };

    return (
        <Autocomplete
            options={options}
            size="small"
            freeSolo
            value={value}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            includeInputInList
            getOptionLabel={(option) => formatTimeZone(option)}
            isOptionEqualToValue={(option, valueEl) => option.value === valueEl.value}
            renderOption={renderOptions}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    InputProps={
                        handleSave === null
                            ? { ...params.InputProps }
                            : {
                                  ...params.InputProps,
                                  endAdornment: (
                                      <InputAdornment position="end">
                                          <Button className={classes.button} onClick={onSave}>
                                              <Typography color="secondary" fontWeight={500} variant="body2">
                                                  Save
                                              </Typography>
                                          </Button>
                                      </InputAdornment>
                                  ),
                              }
                    }
                />
            )}
            className={classes.textField}
            classes={{
                option: classes.hoverElement,
            }}
        />
    );
}

export default TimeZoneAutocomplete;
