import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";

export default function RankingSection({ data, currentCity, comparisonCity, theme, classes }) {
    const ranking = useMemo(() => {
        const left = data.costOfLivingRanking[0];
        const right = data.costOfLivingRanking[1];
        const leftPurchasingPowerSmaller = left.purchasing_power_ranking > right.purchasing_power_ranking;
        const leftAveragePriceSmaller = left.average_price < right.average_price;

        return {
            purchasingPowerRanking: {
                values: [left.purchasing_power_ranking, right.purchasing_power_ranking],
                barPercentages: [
                    100 - (left.purchasing_power_ranking / left.number_of_cities) * 100,
                    100 - (right.purchasing_power_ranking / right.number_of_cities) * 100,
                ],
                colors: [
                    leftPurchasingPowerSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftPurchasingPowerSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <ShoppingCartIcon
                        className={leftPurchasingPowerSmaller ? classes.smallerScore : classes.higherScore}
                    />,
                    <ShoppingCartIcon
                        className={leftPurchasingPowerSmaller ? classes.higherScore : classes.smallerScore}
                    />,
                ],
                message:
                    !left.purchasing_power_ranking && !right.purchasing_power_ranking
                        ? `Currently, there is insufficient data on the purchasing power of ${comparisonCity.name} and ${currentCity.name}`
                        : !left.purchasing_power_ranking
                        ? `${comparisonCity.name} takes ${right.purchasing_power_ranking} purchasing power ranking in the ${comparisonCity.country.name}. Currently, there is insufficient data on the purchasing power of ${currentCity.name}.`
                        : !right.purchasing_power_ranking
                        ? `${currentCity.name} takes ${left.purchasing_power_ranking} purchasing power ranking in the ${currentCity.country.name}. Currently, there is insufficient data on the purchasing power of ${comparisonCity.name}.`
                        : `${comparisonCity.name} has ${
                              leftPurchasingPowerSmaller ? "higher" : "lower"
                          } purchasing power ranking than ${currentCity.name}`,
            },
            averagePurchasingPower: {
                values: [left.average_price ? " " : null, right.average_price ? " " : null],
                barPercentages: [
                    ((left.average_price - left.min_average_price) /
                        (left.max_average_price - left.min_average_price)) *
                        100,
                    ((right.average_price - right.min_average_price) /
                        (right.max_average_price - right.min_average_price)) *
                        100,
                ],
                colors: [
                    leftAveragePriceSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAveragePriceSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <ArrowUpwardIcon
                        className={leftAveragePriceSmaller ? classes.smallerScore : classes.higherScore}
                    />,
                    <ArrowUpwardIcon
                        className={leftAveragePriceSmaller ? classes.higherScore : classes.smallerScore}
                    />,
                ],
                message:
                    !left.average_price && !right.average_price
                        ? `Currently, there is insufficient data on average purchasing power in the ${comparisonCity.country.name} and ${currentCity.country.name}`
                        : !left.average_price
                        ? `The ${comparisonCity.country.name} has great average purchasing power. Currently, there is insufficient data on average purchasing power in ${currentCity.country.name}.`
                        : !right.average_price
                        ? `The ${currentCity.country.name} has great average purchasing power. Currently, there is insufficient data on average purchasing power in ${comparisonCity.country.name}.`
                        : `${comparisonCity.name} has ${
                              leftAveragePriceSmaller
                                  ? parseInt(100 - (left.average_price / right.average_price) * 100)
                                  : parseInt((left.average_price / right.average_price) * 100 - 100)
                          }% ${
                              leftAveragePriceSmaller ? "higher" : "lower"
                          } average purchasing power in the country than ${currentCity.name}`,
            },
        };
    }, [data]);

    return (
        <MetricSection name="Cost of Living Ranking">
            <MetricItem
                name="Purchasing power ranking in the country"
                values={ranking.purchasingPowerRanking.values}
                barPercentages={ranking.purchasingPowerRanking.barPercentages}
                colors={ranking.purchasingPowerRanking.colors}
                indicators={ranking.purchasingPowerRanking.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1} pb={3} px={7}>
                <Typography variant="caption" color="textSecondary" textAlign="center">
                    {ranking.purchasingPowerRanking.message}
                </Typography>
            </Stack>
            <MetricItem
                name="Average purchasing power in the country"
                values={ranking.averagePurchasingPower.values}
                barPercentages={ranking.averagePurchasingPower.barPercentages}
                colors={ranking.averagePurchasingPower.colors}
                indicators={ranking.averagePurchasingPower.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1} px={7}>
                <Typography variant="caption" color="textSecondary" textAlign="center">
                    {ranking.averagePurchasingPower.message}
                </Typography>
            </Stack>
        </MetricSection>
    );
}
