import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userSlice } from "../../../slices/User/userSlice";
import { intelligenceSlice } from "../../../slices/Intelligence/IntelligenceSlice";
import { universitiesSlice } from "../../../slices/Intelligence/UniversitiesSlice";
import { stackSlice } from "../../../slices/Stacks/stackSlice";
import { citySlice } from "../../../slices/City/citySlice";
import { organizationSlice } from "../../../slices/Organizations/organizationSlice";
import { globalNotificationSlice } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { stackInsightsSlice } from "../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { qualityOfLifeSlice } from "../../../slices/Intelligence/QualityOfLifeSlice";
import { adminSlice } from "../../../slices/Admin/adminSlice";
import { costOfLivingSlice } from "../../../slices/Intelligence/CostOfLivingSlice";
import { tagsSlice } from "../../../slices/Tags/tagsSlice";
import { requestedCompaniesSlice } from "../../../slices/Stacks/requestedCompaniesSlice";

const combinedReducer = combineReducers({
    user: userSlice.reducer,
    intelligence: intelligenceSlice.reducer,
    universities: universitiesSlice.reducer,
    qualityOfLife: qualityOfLifeSlice.reducer,
    costOfLiving: costOfLivingSlice.reducer,
    stacks: stackSlice.reducer,
    stackInsights: stackInsightsSlice.reducer,
    city: citySlice.reducer,
    organization: organizationSlice.reducer,
    globalNotifications: globalNotificationSlice.reducer,
    admin: adminSlice.reducer,
    tags: tagsSlice.reducer,
    requestedCompanies: requestedCompaniesSlice.reducer,
});

const rootReducer = (state, action) => {
    if (action.type === "logout/fulfilled") {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
});
