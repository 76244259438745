import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Paper, Box, TextField, Link } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { navigate } from "@gatsbyjs/reach-router";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordSchema } from "./ForgotPasswordSchema";
import { sendForgotPassword } from "../../../actions/user/user";
import { userSliceSelectors, clearPasswordReset } from "../../../slices/User/userSlice";
import logo from "../../../osterus.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "100vh",
    },
    title: {
        fontWeight: 500,
    },
    card: {
        width: 285,
        height: "auto",
        margin: "10% auto",
        padding: "50px 60px",
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: 150,
        marginBottom: theme.spacing(2),
    },
    form: {
        width: "100%",
    },
    icon: {
        width: 15,
        height: 15,
    },
    backButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
}));

export default function ForgotPassword() {
    const { emailExists } = useSelector(userSliceSelectors.passwordReset);
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(forgotPasswordSchema),
    });
    const classes = useStyles();

    const onSubmit = (data) => {
        const email = data.email;
        dispatch(clearPasswordReset());
        dispatch(sendForgotPassword(email));
    };

    const onGoBack = () => {
        dispatch(clearPasswordReset());
        navigate("/login");
    };

    useEffect(() => {
        if (emailExists === true) navigate("/account/password-reset-code-verification");
        else if (emailExists === false) {
            setError("email", { type: "custom", message: "Email not found in the database" });
        }
    }, [emailExists]);

    return (
        <Box className={classes.container}>
            <Paper elevation={1} className={classes.card}>
                <Box className={classes.box}>
                    <img src={logo} className={classes.logo} alt="osterus logo" />
                    <Typography className={classes.title} variant="h4" color="primary" mb={1}>
                        Forgot Password?
                    </Typography>
                    <Typography variant="caption2" color="textSecondary">
                        No worries, we'll send you reset instructions
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Box mt={4} mb={1}>
                            <TextField
                                {...register("email")}
                                required
                                id="email"
                                variant="outlined"
                                size="small"
                                label="Enter your email"
                                sx={{ width: "100%" }}
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={2} className={classes.box}>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{ width: "100%" }}
                                disableElevation
                            >
                                Reset password
                            </Button>
                        </Box>
                        <Box mt={3} className={classes.backButton}>
                            <Link underline="none" component="button" onClick={onGoBack}>
                                <Box className={classes.backButton}>
                                    <Typography variant="caption2" color="textSecondary" mr={0.4} mt={0.2}>
                                        <ArrowBackIcon className={classes.icon} />
                                    </Typography>
                                    <Typography variant="caption2" color="textSecondary">
                                        Back to log in
                                    </Typography>
                                </Box>
                            </Link>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
}
