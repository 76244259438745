import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogActions, TextField, Button, Typography, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { changeEmailSchema } from "./validationSchemas";
import { clearFormErrors, userSliceSelectors } from "../../../../slices/User/userSlice";
import { updateSettingsSecurity } from "../../../../actions/user/settings";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialogActions: {
        marginTop: 10,
    },
    text: {
        margin: "24px 0 24px 0",
    },
    boxContainer: {
        width: "100%",
    },
    titleContainer: {
        padding: "16px 0 16px 24px",
    },
    divider: {
        margin: "0 24px",
    },
}));

export default function ChangeEmailDialog({ open, handleClose }) {
    const { id } = useSelector(userSliceSelectors.defaultUserFields);
    const formErrors = useSelector(userSliceSelectors.formErrors);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(changeEmailSchema),
    });

    useEffect(() => {
        if (open && formErrors && Object.keys(formErrors).length > 0) {
            for (const [key, value] of Object.entries(formErrors)) {
                setError(key, { message: value, type: "manual" }, { shouldFocus: true });
                setValue(key, "");
            }
        }
    }, [formErrors]);

    const onClose = () => {
        reset();
        dispatch(clearFormErrors());
        handleClose();
    };

    const onSubmit = (data) => {
        const onDone = () => {
            onClose();
        };
        dispatch(updateSettingsSecurity({ id, userData: { user: { ...data } }, onDone }));
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.container}>
                <Box className={classes.boxContainer}>
                    <Box className={classes.titleContainer}>
                        <Typography variant="h6" color="primary">
                            Change Account Email
                        </Typography>
                    </Box>
                    <Divider className={classes.divider} />
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div>
                            <TextField
                                {...register("email")}
                                label="New Account Email"
                                fullWidth
                                variant="outlined"
                                error={Boolean(errors.email)}
                                autoFocus
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.text}>
                            <Typography variant="body2" color="primary">
                                Confirm changes by entering your password.
                            </Typography>
                        </div>
                        <div>
                            <TextField
                                {...register("password")}
                                label="Password"
                                fullWidth
                                variant="outlined"
                                type="password"
                                error={Boolean(errors.password)}
                            />
                            {errors.password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password.message}
                                </Typography>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Button color="primary" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit" disabled={Object.keys(errors).length > 0}>
                            Save New Email
                        </Button>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
}
