import React, { useState, useEffect } from "react";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import EditableField from "../../../EditableField";
import CityAutocomplete from "../../../AutocompleteInputs/CityAutocomplete";
import TimeZoneAutocomplete from "../../../AutocompleteInputs/TimeZoneAutocomplete";
import { updateSettingsGeneral } from "../../../../actions/user/settings";
import { userSliceSelectors } from "../../../../slices/User/userSlice";
import PhotoUpload from "../../../PhotoUpload";
import { uploadProfilePhoto } from "../../../../actions/user/user";
import { clearState } from "../../../../slices/City/citySlice";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: "flex",
        alignItems: "center",
        padding: "9px 0 9px 0",
    },
    rowPicture: {
        display: "flex",
        alignItems: "center",
    },
    leftColumn: {
        width: "20%",
    },
    profilePicture: {
        height: 38,
        width: 38,
    },
    container: {
        width: "100%",
        padding: "24px 16px 0 40px",
    },
    valueRow: {
        display: "flex",
        cursor: "pointer",
    },
    valueText: {
        marginRight: theme.spacing(2),
    },
    uploadInput: {
        display: "none",
    },
    uploadContainer: {
        display: "none",
    },
    uploadLink: {
        cursor: "pointer",
    },
    input: {
        paddingRight: 0,
    },
}));

const EDITABLE_PROFILE_PICTURE = "profile_picture";
const EDITABLE_BASED_IN = "ref_city_id";
const EDITABLE_TIMEZONE = "timezone";

function GeneralSettingsComponent() {
    const { id, first_name, last_name, timezone, city, title, department, profile_picture } = useSelector(
        userSliceSelectors.defaultUserFields,
    );
    const [isEditable, setIsEditable] = useState(null);
    const [isHovered, setIsHovered] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleImageUpload = (image) => {
        dispatch(uploadProfilePhoto({ image, id }));
    };

    const handleImageRemoved = () => {
        dispatch(updateSettingsGeneral({ id, userData: { user: { profile_picture: null } } }));
    };

    useEffect(
        () => () => {
            dispatch(clearState());
        },
        [],
    );

    const handleSubmit = (data) => {
        let setting;
        if (data.length) {
            setting = { [data[0]]: data[1] };
        } else setting = data;

        if (setting.first_name || setting.last_name || setting.title || setting.ref_city_id) {
            dispatch(updateSettingsGeneral({ id, userData: { user: { ...setting } } }));
        } else {
            dispatch(updateSettingsGeneral({ id, userData: { user: { ...(setting || "") } } }));
        }
        setIsHovered(null);
        setIsEditable(null);
    };

    const handleOutsideClick = (e) => {
        // Prevent hiding autocomplete field when clicking on its options
        if (e.composedPath().find((el) => el.className && el.className.indexOf("MuiAutocomplete-popper") !== -1)) {
            return;
        }
        setIsHovered(null);
        setIsEditable(null);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.title}>
                    <Typography color="textSecondary" fontWeight={500} variant="overline">
                        GENERAL
                    </Typography>
                </div>
                <div className={classes.rowPicture}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Profile Picture</Typography>
                    </div>
                    <div
                        className={classes.valueRow}
                        onMouseOver={() => setIsHovered(EDITABLE_PROFILE_PICTURE)}
                        onMouseLeave={() => setIsHovered(null)}
                        onClick={() => setDialogOpen(true)}
                    >
                        <div className={`${classes.valueText} ${classes.uploadLink}`}>
                            {!profile_picture ? (
                                <Avatar className={classes.profilePicture} />
                            ) : (
                                <Avatar
                                    className={classes.profilePicture}
                                    onClick={() => setDialogOpen(true)}
                                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${profile_picture}`}
                                />
                            )}
                        </div>
                        <div
                            className={`${classes.rowPicture} ${
                                isHovered === EDITABLE_PROFILE_PICTURE ? "" : classes.uploadContainer
                            }`}
                        >
                            <Typography
                                className={classes.uploadLink}
                                color="secondary"
                                variant="body4"
                                onClick={() => setDialogOpen(true)}
                            >
                                Upload
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">First Name</Typography>
                    </div>
                    <EditableField
                        name="first_name"
                        type="requiredText"
                        value={first_name || "N/A"}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Last Name</Typography>
                    </div>
                    <EditableField
                        name="last_name"
                        type="requiredText"
                        value={last_name || "N/A"}
                        onSubmit={handleSubmit}
                    />
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Job Title in Organization</Typography>
                    </div>
                    <EditableField name="title" type="requiredText" value={title || "N/A"} onSubmit={handleSubmit} />
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Department</Typography>
                    </div>
                    <EditableField name="department" type="text" value={department || "N/A"} onSubmit={handleSubmit} />
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Based In</Typography>
                    </div>
                    {isEditable !== EDITABLE_BASED_IN ? (
                        <div
                            className={classes.valueRow}
                            onMouseOver={() => setIsHovered(EDITABLE_BASED_IN)}
                            onMouseLeave={() => setIsHovered(null)}
                            onClick={() => setIsEditable(EDITABLE_BASED_IN)}
                        >
                            <div className={classes.valueText}>
                                <Typography variant="body2">{city?.name || "N/A"}</Typography>
                            </div>
                            {isHovered === EDITABLE_BASED_IN ? (
                                <div>
                                    <Typography color="secondary" fontWeight={700} variant="body2">
                                        Edit
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                            <CityAutocomplete
                                defaultValue={city}
                                onChange={(value, { saved } = {}) =>
                                    value && saved ? handleSubmit({ ref_city_id: value?.id }) : undefined
                                }
                                errorOnEmpty
                                inputProps={{
                                    className: classes.input,
                                    startAdornment: undefined,
                                }}
                                showSave
                            />
                        </OutsideClickHandler>
                    )}
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Local Time</Typography>
                    </div>
                    {isEditable !== EDITABLE_TIMEZONE ? (
                        <div
                            className={classes.valueRow}
                            onMouseOver={() => setIsHovered(EDITABLE_TIMEZONE)}
                            onMouseLeave={() => setIsHovered(null)}
                            onClick={() => setIsEditable(EDITABLE_TIMEZONE)}
                        >
                            <div className={classes.valueText}>
                                <Typography variant="body2">{timezone || "N/A"}</Typography>
                            </div>
                            {isHovered === EDITABLE_TIMEZONE ? (
                                <div>
                                    <Typography color="secondary" fontWeight={700} variant="body2">
                                        Edit
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                            <TimeZoneAutocomplete defaultValue={timezone} handleSave={handleSubmit} />
                        </OutsideClickHandler>
                    )}
                </div>
            </div>
            <PhotoUpload
                open={dialogOpen}
                picture={profile_picture}
                handleClose={() => setDialogOpen(false)}
                handleSave={handleImageUpload}
                handleRemove={handleImageRemoved}
            />
        </>
    );
}

export default GeneralSettingsComponent;
