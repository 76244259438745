import React from "react";
import { Link, Typography, Box, Breadcrumbs as BreadcrumbsFromTheme } from "@mui/material";
import { navigate } from "@gatsbyjs/reach-router";

export default function Breadcrumbs({ components }) {
    const { title, breadcrumbs } = components;

    return (
        <>
            {title ? (
                <Typography variant="h5" color={title.color}>
                    {title.text}
                </Typography>
            ) : null}
            <Typography variant="h5">
                <BreadcrumbsFromTheme aria-label="breadcrumb">
                    {breadcrumbs.map((item) => (
                        <Link
                            key={item.text}
                            underline="none"
                            component={item.link ? "button" : "div"}
                            onClick={item.link ? () => navigate(item.link) : null}
                        >
                            {item.icon && item.icon}
                            <Box mb={item.link ? 0 : 0.2}>
                                <Typography variant="body3" color={item.color}>
                                    {item.text}
                                </Typography>
                            </Box>
                        </Link>
                    ))}
                </BreadcrumbsFromTheme>
            </Typography>
        </>
    );
}
