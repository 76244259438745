import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllByFilter as getAllCitiesByFilter } from "../../services/api/City/CityApi";

export const getAllByFilter = createAsyncThunk("city/getAllByFilter", async (data = {}) => {
    const { filter = {}, type, cityIndex } = data;
    const params = {
        q: filter.hasOwnProperty("q") ? { name: filter.q } : {},
    };

    if (filter.country && filter.country.length > 0) {
        params.country = filter.country;
    }

    if (filter.sort_by) {
        params.sort_by = filter.sort_by;
    }

    if (filter.start) {
        params.start = filter.start;
    }

    if (filter.limit) {
        params.limit = filter.limit;
    }

    const response = await getAllCitiesByFilter(params);
    return { cities: response.data.records, type, cityIndex };
});
