import { createSlice } from "@reduxjs/toolkit";
import { getCostOfLiving } from "../../actions/intelligence/CostOfLiving";

const SLICE_NAME = "costOfLiving";

const initialState = {
    data: null,
    loading: false,
    currentCity: null,
    comparisonCity: null,
    currentCityError: false,
    comparisonCityError: false,
};

export const costOfLivingSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setCities: (state, { payload }) => {
            state.currentCity = payload.currentCity;
            state.comparisonCity = payload.comparisonCity;
        },
        clearState: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: {
        [getCostOfLiving.pending]: (state) => {
            state.loading = true;
        },
        [getCostOfLiving.fulfilled]: (state, { payload }) => {
            if (payload.errors) {
                state.data = null;
                state.currentCityError = payload.errors.indexOf("currentCity") !== -1;
                state.comparisonCityError = payload.errors.indexOf("comparisonCity") !== -1;
            } else {
                state.data = payload.data;
                state.currentCityError = false;
                state.comparisonCityError = false;
            }

            state.loading = false;
        },
    },
});

export const { clearState, setCities } = costOfLivingSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const costOfLivingSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    currentCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCity;
    },
    comparisonCity: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCity;
    },
    currentCityError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.currentCityError;
    },
    comparisonCityError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.comparisonCityError;
    },
    data: (rootState) => {
        const appState = getAppState(rootState);
        return appState.data;
    },
};
