import React, { useState, useRef, useMemo } from "react";
import Chart from "react-apexcharts";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChartContainer } from "./ChartContainer";

const useStyles = makeStyles(() => ({
    container: {
        position: "relative",
        height: "100%",
    },
    yaxisTitle: {
        margin: 0,
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 400,
        fontSize: 9,
        color: "#4C4C4C",
        paddingLeft: 8,
        position: "relative",
        top: 45,
    },
    yaxisTitleSingle: {
        margin: 0,
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 400,
        fontSize: 9,
        color: "#4C4C4C",
        paddingLeft: 8,
        position: "relative",
        top: 10,
    },
}));

function generateChartOptions({
    categories,
    series,
    variant,
    colors,
    xAxisLabel,
    showLegend,
    maxValue,
    barWidth,
    padding,
    eventHandlers,
}) {
    const { onUpdated, onAnimationEnd } = eventHandlers;

    const sharedOptions = {
        chart: {
            id: "OsterusChart",
            fontFamily: '"Roboto", "Helvetica", "Arial"',
            zoom: {
                enabled: true,
            },
            toolbar: {
                show: false,
            },
            events: {
                updated: onUpdated,
                animationEnd: onAnimationEnd,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: barWidth ?? (series.length === 1 ? "35%" : "40%"),
                borderRadius: [2],
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: colors.defaultColors,
        fill: colors.gradientColors
            ? {
                  type: "gradient",
                  gradient: {
                      type: "vertical",
                      gradientToColors: colors.gradientColors,
                      stops: [0, 100],
                      shadeIntensity: 0,
                      opacityFrom: 1,
                      opacityTo: 1,
                  },
              }
            : {
                  opacity: 1,
              },
        grid: {
            padding: {
                ...padding,
            },
        },
    };

    let variantOptions;
    switch (variant) {
        case "normal": {
            variantOptions = {
                plotOptions: {
                    bar: {
                        columnWidth: barWidth || "50%",
                        borderRadius: [3],
                    },
                },
                stroke: {
                    width: 1,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories,
                    labels: {
                        show: true,
                        rotate: 0,
                        rotateAlways: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: true,
                        height: 2,
                        color: "#918f8f",
                    },
                },
                yaxis: {
                    min: 0,
                    max: Math.min(maxValue * 1.1, 100),
                    labels: {
                        formatter: (val) => `${val.toFixed(0)}%`,
                    },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    x: {
                        formatter: (val) => `${val} ${xAxisLabel}`,
                    },
                },
                legend: {
                    show: showLegend,
                    position: "top",
                    horizontalAlign: "left",
                    offsetY: -4,
                    offsetX: -27,
                    height: 30,
                    markers: {
                        width: 11,
                        height: 11,
                        radius: 50,
                        offsetX: -6,
                        offsetY: 2,
                    },
                    itemMargin: {
                        horizontal: 7,
                    },
                    fontWeight: 500,
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: 14,
                    labels: {
                        colors: "#2C2C2C",
                    },
                },
            };
            break;
        }
        case "normalValue": {
            variantOptions = {
                yaxis: {
                    labels: {
                        formatter: (value) => Math.round(value),
                        style: {
                            colors: "#4C4C4C",
                            fontWeight: 400,
                            fontSize: 9,
                        },
                    },
                },
                xaxis: {
                    categories,
                    labels: {
                        show: !!categories.length,
                        rotate: 0,
                        minHeight: 25,
                        maxHeight: 25,
                        hideOverlappingLabels: false,
                        trim: true,
                    },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    intersect: false,
                    y: {
                        formatter: (value) => value.toFixed(2),
                    },
                },
                legend: {
                    show: showLegend,
                    position: "top",
                    horizontalAlign: "left",
                    offsetY: 10,
                    offsetX: -25,
                    height: 30,
                    markers: {
                        width: 9,
                        height: 9,
                        radius: 50,
                    },
                    labels: {
                        colors: "#2C2C2C",
                    },
                    fontSize: 11,
                    onItemClick: {
                        toggleDataSeries: false,
                    },
                },
            };
            break;
        }
        default: {
            // eslint-disable-next-line
            console.error("Invalid variant.");
        }
    }

    return { ...sharedOptions, ...variantOptions };
}

export default function StandardVerticalBarChart({
    categories,
    series,
    variant,
    colors,
    title = "",
    xAxisLabel = undefined,
    showLegend = false,
    showActions = true,
    showTableView = true,
    containerStyles = undefined,
    defaultLimit = undefined,
    maxLimit,
    maxValue,
    elevation = 1,
    titleStyles,
    titleTypographyStyle,
    barWidth,
    padding,
    eventHandlers = {},
    actionsOffset = { x: 15, y: -30 },
}) {
    const [loadedItems, setLoadedItems] = useState(defaultLimit || categories.length);
    const [newCategories, newSeries] = useMemo(() => {
        if (!loadedItems) {
            return [categories, series];
        }
        const tempCategories = [...categories].slice(0, loadedItems);
        const tempSeries = series.map((item) => ({
            name: item.name,
            data: [...item.data].slice(0, loadedItems),
        }));
        return [tempCategories, tempSeries];
    });

    const classes = useStyles();
    const containerRef = useRef(null);
    const chartOptions = generateChartOptions({
        categories: newCategories,
        series: newSeries,
        variant,
        colors,
        xAxisLabel,
        showLegend,
        maxValue,
        barWidth,
        padding,
        eventHandlers,
    });

    const barchartref = useRef();

    return (
        <ChartContainer
            ref={containerRef}
            chartInfo={{ title, shortTitle: title }}
            containerStyles={containerStyles}
            data={{
                categories,
                series,
            }}
            zoomIn={() => setLoadedItems(loadedItems - 1)}
            zoomOut={() => setLoadedItems(loadedItems + 1)}
            maxLimit={maxLimit}
            loadedItems={loadedItems}
            barchartref={barchartref}
            showActions={showActions}
            tableViewProps={{
                chartType: `StandardVerticalBarChart-${variant}`,
                chartTitle: { title, shortTitle: title },
            }}
            showTableView={showTableView}
            elevation={elevation}
            titleStyles={titleStyles}
            titleTypographyStyle={titleTypographyStyle}
            actionsOffset={actionsOffset}
        >
            <div className={classes.container}>
                <Chart
                    type="bar"
                    options={chartOptions}
                    series={newSeries}
                    width="100%"
                    height="100%"
                    ref={barchartref}
                />
                {variant === "normal" && (
                    <Stack width="100%" alignItems="flex-end">
                        <Typography variant="caption" color="textSecondary" position="absolute" bottom={7} right={8}>
                            {xAxisLabel}
                        </Typography>
                    </Stack>
                )}
            </div>
        </ChartContainer>
    );
}
