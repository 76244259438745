import React, { useEffect, useMemo, useState } from "react";
import { Grid, Paper, Typography, TextField, IconButton, Tooltip, Box, Autocomplete } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { makeStyles } from "@mui/styles";
import { debounce } from "debounce";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { getAllByFilterApi as getStacksApi } from "../../../../../services/api/TheLab/Stacks/stackActions";
import { stackSliceSelectors, setSelectedStacks } from "../../../../../slices/Stacks/stackSlice";
import {
    setLoadingInsights,
    stackInsightsSliceSelector,
    setShowAndSplit,
    clearAppliedFilters,
    clearAllFilters,
} from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { userSliceSelectors } from "../../../../../slices/User/userSlice";
import { ShareModal } from "../Modals/ShareModal";
import { DownloadSelectionMenu } from "../DownloadSelectionMenu";

const useStyles = makeStyles((theme) => ({
    countryPaper: {
        display: "flex",
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
    },
    currentStackAutocomplete: {
        marginTop: 3,
        paddingLeft: 14,
        paddingTop: 5,
        paddingRight: 10,
        width: 200,
        height: 40,
        alignItems: "center",
        paddingBottom: 35,
    },
    syncAlt: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        width: 48,
        height: 48,
    },
    autocompleteFlagContainer: {
        display: "flex",
        width: 20,
        height: 14,
        overflow: "hidden",
    },
    autocompleteTypographyContainer: {
        display: "flex",
        width: "85%",
        paddingLeft: 10,
    },
    textField: {
        fontSize: 20,
        fontWeight: 500,
        textAlign: "center",
        color: theme.palette.secondary.main,
        ".MuiSvgIcon-root": {
            color: "white",
        },
    },
    downloadIconGrid: {
        textAlign: "right",
    },
    autocomplete: {
        button: {
            color: theme.palette.secondary.main,
        },
    },
    limitNumber: {
        position: "absolute",
        cursor: "pointer",
        left: "83.5%",
        top: -5,
        color: "#FDFDFD",
        background: ({ backgroundLimit }) => backgroundLimit,
        border: ({ borderLimit }) => borderLimit,
        borderRadius: "50%",
        minWidth: 25,
        minHeight: 25,
        textAlign: "center",
        alignItems: "center",
        padding: "3px 5px 3px 5px",
        zIndex: 1,
    },
}));

export default function StackSelect({ stacks, isLoading, viewType, isSingle }) {
    const delayTime = 500;
    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const organizations = useSelector(userSliceSelectors.organizations);
    const [isHoverCurrentStack, setHoverCurrentStack] = useState(false);
    const [isFocusCurrentStack, setFocusCurrentStack] = useState(false);
    const [reportLimit, setReportLimit] = useState();
    const showTooltipCurrentStack = isHoverCurrentStack && !isFocusCurrentStack;
    const [isHoverComparisonStack, setHoverComparisonStack] = useState(false);
    const [isFocusComparisonStack, setFocusComparisonStack] = useState(false);
    const showTooltipComparisonStack = isHoverComparisonStack && !isFocusComparisonStack;
    const [currentStack, setCurrentStack] = useState({});
    const [comparisonStack, setComparisonStack] = useState({});
    const [showCurrentStackError, setShowCurrentStackError] = useState(false);
    const [showComparisonStackError, setShowComparisonStackError] = useState(false);
    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const userOrganizations = useSelector(userSliceSelectors.organizations);
    const isUserPartOfOsterus = userOrganizations && userOrganizations.filter((item) => item.id === 1).length > 0;
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const props = {
        currentStackBorder: showCurrentStackError ? "1px solid red" : "none",
        comparisonStackBorder: showComparisonStackError ? "1px solid red" : "none",
        backgroundLimit: reportLimit === "0" ? "#F64949" : "#2C2C2C",
        borderLimit: reportLimit === "0" ? 0 : "1px solid rgba(233, 238, 242, 0.23)",
    };

    const [currentTab] = useState(viewType);
    const classes = useStyles(props);
    const dispatch = useDispatch();

    const handleOnStacksSelected = (stack1, stack2) => {
        // Make blur and loading a little bit smoother by delaying the URI and stacks update.
        dispatch(setLoadingInsights(true));
        setTimeout(async () => {
            dispatch(setShowAndSplit({ show, split }));
            const newSelectedStacks = isSingle ? [stack1] : [stack1, stack2];
            const url = isSingle
                ? `/the-lab/stacks/stack-insights/${currentTab}/${stack1.id}`
                : `/the-lab/stacks/stack-insights/${currentTab}/${stack1.id}/${stack2.id}`;
            // Wait for navigate to finish(for react to finish rendering the next screen) before continuing.
            // This guarantees that stacks won't be updated before the uri.
            await navigate(url);
            dispatch(clearAppliedFilters());
            dispatch(clearAllFilters());
            dispatch(setSelectedStacks(newSelectedStacks));
        }, 300);
    };

    const handleInputChanged = (current, comparison) => {
        if (current !== currentStack) setCurrentStack(current);
        if (comparison !== comparisonStack) setComparisonStack(comparison);
        if (current && comparison && current.id && comparison.id) {
            handleOnStacksSelected(comparison, current);
        }
    };

    const [stack1Options, setStack1Options] = useState([]);
    const [stack2Options, setStack2Options] = useState([]);
    const [stack1, setStack1] = useState(stacks[0] || null);
    const [stack2, setStack2] = useState(stacks[1] || null);

    useEffect(() => {
        setStack1(stacks[0] || null);
        setStack2(stacks[1] || null);
    }, [stacks]);

    useEffect(() => {
        if (
            organizations[0]?.limits &&
            organizations[0]?.limits?.length &&
            organizations[0].limits[0].initial_credits !== -1
        ) {
            setReportLimit(organizations[0].limits[0].credits.toString());
        }
    }, []);

    const getStacks = useMemo(
        () =>
            debounce(async (name, setter) => {
                if (name === null || name === undefined) {
                    setter([]);
                    return;
                }

                const { data } = await getStacksApi({ q: { name } });
                setter(data.records);
            }, 400),
        [],
    );

    useEffect(() => {
        if (!isSingle && (!stack1 || !stack2)) return;
        else if (isSingle && !stack1) return;

        getStacks("", (records) => {
            let filteredRecords;
            if (isSingle) {
                filteredRecords = records.slice(0, 5);
            } else {
                filteredRecords = records.filter((item) => item.id !== stack1.id && item.id !== stack2.id).slice(0, 5);
            }
            setStack1Options(filteredRecords);
            setStack2Options(filteredRecords);
        });
    }, [stack1, stack2]);

    const onStack1InputChange = (e, inputValue) => {
        if (!e) return;
        else if (!inputValue) {
            setStack1Options([]);
        }
        getStacks(inputValue, setStack1Options);
    };

    const onStack2InputChange = (e, inputValue) => {
        if (!e) return;
        else if (!inputValue) {
            setStack2Options([]);
        }
        getStacks(inputValue, setStack2Options);
    };

    const onStack1Change = async (e, value, reason) => {
        if (reason === "clear") {
            setStack1(null);
        } else if (reason === "selectOption") {
            setStack1(value);

            if (isSingle) {
                handleOnStacksSelected(value, null);
            } else if (stack2) {
                handleOnStacksSelected(value, stack2);
            }
        }
    };

    const onStack2Change = async (e, value, reason) => {
        if (reason === "clear") {
            setStack2(null);
        } else if (reason === "selectOption") {
            setStack2(value);

            if (stack1) {
                handleOnStacksSelected(stack1, value);
            }
        }
    };

    const swapStacks = () => {
        handleInputChanged(stack1, stack2);
    };

    return (
        <Grid container id="stack-select">
            <Paper elevation={0} className={classes.countryPaper} variant="header">
                <Grid item xs={1} />
                <Grid item container justifyContent="center" xs={10}>
                    <Tooltip
                        open={showTooltipCurrentStack}
                        enterNextDelay={delayTime}
                        title="Search and select stacks in both fields to start comparing the Data Insights."
                        placement="top"
                    >
                        <Autocomplete
                            classes={classes.autocomplete}
                            noOptionsText=""
                            disablePortal
                            disabled={isLoading}
                            options={stack2 ? stack1Options.filter((item) => item.id !== stack2.id) : stack1Options}
                            PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                            size="small"
                            variant="outlined"
                            disableClearable
                            blurOnSelect
                            includeInputInList
                            value={stack1}
                            getOptionLabel={(option) => option.stack.name}
                            renderOption={(renderProps, option) => (
                                <li {...renderProps} translate="no" key={option.id}>
                                    <Box className={classes.autocompleteTypographyContainer}>
                                        <Typography variant="body">{option.stack.name}</Typography>
                                    </Box>
                                </li>
                            )}
                            onInputChange={onStack1InputChange}
                            onChange={onStack1Change}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ input: { color: "#5172E8" } }}
                                    onFocus={() => setFocusCurrentStack(true)}
                                    onBlur={() => setFocusCurrentStack(false)}
                                    onMouseOver={() => {
                                        if (!isFocusCurrentStack) {
                                            setHoverCurrentStack(true);
                                        }
                                    }}
                                    onMouseLeave={() => setHoverCurrentStack(false)}
                                    {...params}
                                    variant="standard"
                                    placeholder="Select stack"
                                    onClick={() => setShowCurrentStackError(false)}
                                    InputProps={{
                                        classes: {
                                            input: classes.textField,
                                        },
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    }}
                                />
                            )}
                            className={classes.currentStackAutocomplete}
                        />
                    </Tooltip>
                    {!isSingle && (
                        <IconButton onClick={swapStacks} size="large" className={classes.syncAlt} disabled={isLoading}>
                            <SyncAltIcon />
                        </IconButton>
                    )}
                    {!isSingle && (
                        <Tooltip
                            open={showTooltipComparisonStack}
                            enterNextDelay={delayTime}
                            title="Search and select stacks in both fields to start comparing the Data Insights."
                            placement="top"
                        >
                            <Autocomplete
                                noOptionsText=""
                                disabled={isLoading}
                                disablePortal
                                options={stack1 ? stack2Options.filter((item) => item.id !== stack1.id) : stack2Options}
                                value={stack2}
                                PaperComponent={({ children }) => (
                                    <Paper variant="autocompleteDropdown">{children}</Paper>
                                )}
                                size="small"
                                variant="outlined"
                                disableClearable
                                blurOnSelect
                                includeInputInList
                                getOptionLabel={(option) => option.stack.name}
                                renderOption={(renderProps, option) => (
                                    <li {...renderProps} translate="no" key={option.id}>
                                        <Box className={classes.autocompleteTypographyContainer}>
                                            <Typography variant="body">{option.stack.name}</Typography>
                                        </Box>
                                    </li>
                                )}
                                onInputChange={onStack2InputChange}
                                onChange={onStack2Change}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ input: { color: "#4BC186" } }}
                                        onFocus={() => setFocusComparisonStack(true)}
                                        onBlur={() => setFocusComparisonStack(false)}
                                        onMouseOver={() => {
                                            if (!isFocusComparisonStack) {
                                                setHoverComparisonStack(true);
                                            }
                                        }}
                                        onMouseLeave={() => setHoverComparisonStack(false)}
                                        {...params}
                                        variant="standard"
                                        placeholder="Select stack"
                                        onClick={() => setShowComparisonStackError(false)}
                                        InputProps={{
                                            classes: {
                                                input: classes.textField,
                                            },
                                            ...params.InputProps,
                                            disableUnderline: true,
                                        }}
                                    />
                                )}
                                className={classes.currentStackAutocomplete}
                            />
                        </Tooltip>
                    )}
                </Grid>
                <Grid item container xs={1} justifyContent="flex-end" position="relative">
                    {reportLimit && (
                        <Tooltip
                            title={
                                reportLimit === "0"
                                    ? "You have no download reports available."
                                    : `You have ${reportLimit} more download reports available.`
                            }
                        >
                            {reportLimit && (
                                <Typography variant="body2" className={classes.limitNumber}>
                                    {reportLimit}
                                </Typography>
                            )}
                        </Tooltip>
                    )}
                    <Tooltip
                        enterNextDelay={delayTime}
                        title="Click here to share link to data insights."
                        placement="top"
                    >
                        <DownloadSelectionMenu reportLimit={reportLimit} setReportLimit={setReportLimit} />
                    </Tooltip>
                    {isUserPartOfOsterus && selectedStacks.length && (
                        <ShareModal
                            stackIds={selectedStacks.map((stack) => stack.id)}
                            viewType={viewType}
                            open={shareModalOpen}
                            onClose={() => setShareModalOpen(false)}
                        />
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
}
