import React, { useState, useEffect } from "react";
import { Box, Button, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@gatsbyjs/reach-router";
import CityAutocomplete from "../../AutocompleteInputs/CityAutocomplete";
import { clearState } from "../../../slices/City/citySlice";
import { setStep } from "../../../slices/User/userSlice";
import PhotoUpload from "../../PhotoUpload";
import { COMPANY_INDUSTRY_OPTIONS, COMPANY_SIZE_OPTIONS } from "./formOptions";
import { uploadLogo } from "../../../actions/organization/logo";
import { companyRegisterSchema } from "./companyRegisterSchema";
import { saveOrganization } from "../../../actions/organization/organization";
import {
    clearFormErrors,
    organizationSliceSelectors,
    saveTempOrganization,
} from "../../../slices/Organizations/organizationSlice";

const useStyles = makeStyles((theme) => ({
    row: {
        display: "flex",
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(5),
    },
    subtitle: {
        marginTop: "16px",
        marginBottom: theme.spacing(4),
    },
    imageContainer: {
        width: "120px",
        height: "120px",
        flexGrow: 0,
        margin: "0 140px 16px",
        padding: "20px",
        borderRadius: "60px",
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    imagePreviewContainer: {
        overflow: "hidden",
        width: "120px",
        height: "120px",
        flexGrow: 0,
        margin: "0 140px 16px",
        borderRadius: "60px",
        border: "solid 1px rgba(0, 0, 0, 0.23)",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
    },
    imagePreview: {
        top: 0,
        left: 0,
        transformOrigin: "top left",
    },
    accountIcon: {
        fontSize: 80,
        color: theme.palette.dividerAndOutline[700],
    },
    uploadText: {
        cursor: "pointer",
    },
    fullWidthField: {
        width: 400,
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
    },
    additionalLocationContainer: {
        display: "flex",
        marginTop: theme.spacing(1.5),
        paddingLeft: 8,
        paddingRight: 8,
        justifyContent: "flex-end",
    },
    additionalLocationPointer: {
        cursor: "pointer",
        paddingLeft: 4,
        paddingRight: 12,
    },
    asterisk: {
        color: "red",
    },
    additionalLocationItem: {
        margin: "0 16px",
    },
    labelStyle: {
        background: "#F9F9FF",
    },
}));

export default function OrganizationCreateComponent() {
    const classes = useStyles();
    const requiredFieldMessage = "Field is required";
    const [dialogOpen, setDialogOpen] = useState(false);
    const logo = useSelector(organizationSliceSelectors.organizationLogo);
    const tempOrganization = useSelector(organizationSliceSelectors.tempOrganization);
    const formErrors = useSelector(organizationSliceSelectors.formErrors);
    const dispatch = useDispatch();

    const [headquarters, setHeadquarters] = useState(tempOrganization?.headquarters || null);
    const [additionalLocations, setAdditionalLocations] = useState(tempOrganization?.additionalLocations || []);

    const handleLocationChanged = (value, index, isHeadquarter) => {
        if (isHeadquarter) {
            setHeadquarters(value);
        } else {
            additionalLocations[index] = value;
            setAdditionalLocations([...additionalLocations]);
        }
    };

    const {
        register,
        handleSubmit,
        reset,
        setError,
        setValue,
        clearErrors,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: { size: "", industry: "" },
        resolver: yupResolver(companyRegisterSchema),
    });

    useEffect(() => {
        if (!tempOrganization) return;

        const fields = getValues();
        for (const property in fields) {
            if (tempOrganization.hasOwnProperty(property)) {
                setValue(property, tempOrganization[property]);
            }
        }
    }, []);

    useEffect(
        () => () => {
            dispatch(clearState());
            dispatch(clearFormErrors());
        },
        [],
    );

    useEffect(() => {
        if (headquarters) {
            clearErrors("headquarters");
        }
        additionalLocations.forEach((value, index) => {
            if (value) clearErrors(`location-${index}`);
        });
    }, [additionalLocations, headquarters]);

    useEffect(() => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            for (const [key, value] of Object.entries(formErrors)) {
                setError(
                    key,
                    { message: key === "full_name" ? "Company name already exists" : value, type: "manual" },
                    { shouldFocus: true },
                );
                setValue(key, "");
            }
        }
    }, [formErrors]);

    const onSubmit = (data) => {
        let isError = false;
        if (!headquarters) {
            setError("headquarters", { type: "required", message: requiredFieldMessage });
            isError = true;
        }
        additionalLocations.forEach((value, index) => {
            if (!value) {
                isError = true;
                setError(`location-${index}`, { type: "required", message: requiredFieldMessage });
            }
        });

        if (isError) return;

        const locations = new Array(additionalLocations.length + 1);
        locations[0] = { ref_city_id: headquarters?.id, isHeadquarter: true };
        additionalLocations.forEach((value, index) => {
            const location = {
                ref_city_id: additionalLocations[index]?.id,
                isHeadquarter: false,
            };
            locations[index + 1] = location;
        });

        const organizationData = {
            full_name: data.full_name,
            display_name: data.companyDisplayName.length > 0 ? data.companyDisplayName : data.full_name,
            size: data.size,
            email: data.companyEmail,
            industry: data.industry,
            linkedin: data.linkedin,
            website: data.website,
            locations,
            logo,
            description: data.description,
        };

        const onDone = () => {
            dispatch(setStep(null));
            reset();
            navigate("/");
        };
        dispatch(saveOrganization({ organizationData, onDone }));
    };

    const onError = () => {
        if (!headquarters) {
            setError("headquarters", { type: "required", message: requiredFieldMessage });
        }
        additionalLocations.forEach((value, index) => {
            if (!value) {
                setError(`location-${index}`, { type: "required", message: requiredFieldMessage });
            }
        });
    };

    const handleImageUpload = (image) => {
        dispatch(uploadLogo(image));
    };

    const handleAddLocation = () => {
        setAdditionalLocations([...additionalLocations, null]);
    };

    const handleRemoveLocation = (index) => {
        const newLocations = [...additionalLocations];
        newLocations.splice(index, 1);
        setAdditionalLocations(newLocations);
        clearErrors(`location-${index}`);
    };

    const backToPersonalInfo = () => {
        dispatch(setStep(2));
        dispatch(saveTempOrganization({ ...getValues(), headquarters, additionalLocations }));
        navigate("/account/personal-information");
    };

    const disableEnter = (e) => {
        if (e.code === "Enter") e.preventDefault();
    };

    return (
        <Box>
            <Paper variant="secondary" className={classes.paper} elevation={1}>
                <div className={classes.row}>
                    <Typography variant="h4" color="primary">
                        Organization Creation
                    </Typography>
                </div>
                <form onSubmit={handleSubmit(onSubmit, onError)} onKeyDown={(e) => disableEnter(e)}>
                    <Box mt={3} mb={1} mx={2}>
                        {!logo ? (
                            <div className={classes.imageContainer} onClick={() => setDialogOpen(true)}>
                                <ApartmentIcon className={classes.accountIcon} />
                            </div>
                        ) : (
                            <div className={classes.imagePreviewContainer} onClick={() => setDialogOpen(true)}>
                                <img
                                    src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${logo}`}
                                    alt="organization-logo"
                                />
                            </div>
                        )}
                        <div className={classes.row}>
                            <Typography
                                className={classes.uploadText}
                                onClick={() => setDialogOpen(true)}
                                color="secondary.400"
                                variant="caption"
                            >
                                {!logo ? "Upload Logo" : "Re-Upload Logo"}
                            </Typography>
                        </div>
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <div>
                            <TextField
                                {...register("full_name")}
                                id="company-name"
                                variant="outlined"
                                size="small"
                                label="Official Organization Name"
                                fullWidth
                                error={Boolean(errors.full_name)}
                                InputLabelProps={{
                                    required: true,
                                    classes: {
                                        asterisk: classes.asterisk,
                                    },
                                }}
                            />
                            {errors.full_name && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.full_name.message}
                                </Typography>
                            )}
                        </div>
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <div>
                            <TextField
                                {...register("companyDisplayName")}
                                id="company-display-name"
                                variant="outlined"
                                size="small"
                                label="Organization Display Name"
                                fullWidth
                                error={Boolean(errors.companyDisplayName)}
                            />
                            {errors.companyDisplayName && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.companyDisplayName.message}
                                </Typography>
                            )}
                        </div>
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <TextField
                            {...register("companyEmail")}
                            id="company-display-name"
                            variant="outlined"
                            size="small"
                            label="Organization Email"
                            error={Boolean(errors.companyEmail)}
                            fullWidth
                            InputLabelProps={{
                                required: true,
                                classes: {
                                    asterisk: classes.asterisk,
                                },
                            }}
                        />
                        {errors.companyEmail && (
                            <Typography color="error" variant="subtitle2">
                                {errors.companyEmail.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <Controller
                            name="size"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    id="company-size"
                                    variant="outlined"
                                    size="small"
                                    label="Organization Size"
                                    fullWidth
                                    margin="dense"
                                    error={Boolean(errors.size)}
                                    InputLabelProps={{
                                        required: true,
                                        classes: {
                                            asterisk: classes.asterisk,
                                        },
                                    }}
                                >
                                    {COMPANY_SIZE_OPTIONS.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                        {errors.size && (
                            <Typography color="error" variant="subtitle2">
                                {errors.size.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <Controller
                            name="industry"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    id="company-industry"
                                    variant="outlined"
                                    size="small"
                                    label="Industry"
                                    fullWidth
                                    margin="dense"
                                    error={Boolean(errors.industry)}
                                    InputLabelProps={{
                                        required: true,
                                        classes: {
                                            asterisk: classes.asterisk,
                                        },
                                    }}
                                >
                                    {COMPANY_INDUSTRY_OPTIONS.map(({ value, label }) => (
                                        <MenuItem key={value} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                        {errors.industry && (
                            <Typography color="error" variant="subtitle2">
                                {errors.industry.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <CityAutocomplete
                            className={classes.fullWidthField}
                            label="Headquarters Location"
                            error={Boolean(errors.headquarters)}
                            required
                            labelStyle={classes.labelStyle}
                            onChange={(value) => handleLocationChanged(value, null, true)}
                            defaultValue={headquarters}
                        />
                        {errors.headquarters && (
                            <Typography color="error" variant="subtitle2">
                                {errors.headquarters.message}
                            </Typography>
                        )}
                    </Box>
                    {additionalLocations.map((_, index) => (
                        <div key={index} className={classes.additionalLocationItem}>
                            <Box mt={3} mb={1}>
                                <CityAutocomplete
                                    className={classes.fullWidthField}
                                    label={`Additional Location ${index + 1}`}
                                    required
                                    error={Boolean(errors[`location-${index}`])}
                                    onChange={(value) => handleLocationChanged(value, index, false)}
                                    defaultValue={additionalLocations[index]}
                                />
                                {errors[`location-${index}`] && (
                                    <Typography color="error" variant="subtitle2">
                                        {errors[`location-${index}`].message}
                                    </Typography>
                                )}
                            </Box>
                            <Typography
                                variant="caption"
                                color="error"
                                onClick={() => handleRemoveLocation(index)}
                                className={classes.additionalLocationPointer}
                            >
                                Remove Location
                            </Typography>
                        </div>
                    ))}
                    <div className={classes.additionalLocationContainer}>
                        <Typography
                            variant="caption"
                            color="secondary"
                            onClick={handleAddLocation}
                            className={classes.additionalLocationPointer}
                        >
                            + Additional Location
                        </Typography>
                    </div>
                    <Box mt={3} mb={1} mx={2}>
                        <TextField
                            {...register("website")}
                            id="company-website"
                            variant="outlined"
                            size="small"
                            label="Website"
                            fullWidth
                        />
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <TextField
                            {...register("linkedin")}
                            id="company-linkedin"
                            variant="outlined"
                            size="small"
                            label="Linkedin"
                            fullWidth
                        />
                        {errors.linkedin && (
                            <Typography color="error" variant="subtitle2">
                                {errors.linkedin.message}
                            </Typography>
                        )}
                    </Box>
                    <Box mt={3} mb={1} mx={2}>
                        <TextField
                            {...register("description")}
                            id="company-description"
                            label="Organization Description"
                            multiline
                            fullWidth
                            rows={4}
                            variant="outlined"
                        />
                    </Box>
                    <Box mt={3} mb={1} className={classes.buttonRow}>
                        <Button color="primary" variant="text" size="medium" onClick={backToPersonalInfo}>
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="text"
                            size="medium"
                            type="submit"
                            disabled={Object.keys(errors).length > 0}
                        >
                            Save & Continue
                        </Button>
                    </Box>
                </form>
            </Paper>
            <PhotoUpload
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
                handleSave={handleImageUpload}
                title="Upload Logo"
            />
        </Box>
    );
}
