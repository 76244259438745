import React, { useMemo, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { stackSliceSelectors } from "../../../../../slices/Stacks/stackSlice";
// import { formatTimeValues } from "../Views/utils";
import Table from "../../../../Table/Table";
import Pagination from "../../../../Pagination";
import { renderTableViewHeader } from "../../../../Table/HeaderRenderers";
import { renderMultiSeries, renderWideMultiSeries, renderTableViewText } from "../../../../Table/CellRenderers";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
} from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: (props) => props.minWidth || 800,
    },
    dialogContent: {
        overflow: "hidden",
    },
    firstStackColor: {
        color: "#6181EA",
    },
    secondStackColor: {
        color: "#4AC086",
    },
    title: {
        color: "#1D1D21",
        fontWeight: 500,
    },
    dialogButton: {
        marginRight: -15,
    },
    table: {
        boxShadow: "none",
        backgroundColor: theme.palette.background.secondary,
        padding: 0,
        "& tbody": {
            "& tr:nth-of-type(odd)": {
                background: "#f4f6f8",
            },
        },
    },
}));

export default function TableView({ open, handleClose, data, chartType, chartTitle }) {
    const colors = ["#4f77ec", "#5ec793"];
    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const [title, setTitle] = useState("");

    const [page, setPage] = useState(1);
    const [dataWindow, setDataWindow] = useState([]);
    const itemsPerPage = 10;
    const [subHeaderRow, setSubHeaderRow] = useState(null);

    const theme = useTheme();

    const [columns, rows, minWidth] = useMemo(() => {
        let tableViewColumns = [];
        let tableViewRows = [];
        let minViewWidth = 800;
        let newSubHeaderRow = null;

        // TODO: Refactor: All of this chart type specific configuration should be moved to a separate file, if possible.
        switch (chartType) {
            case "StackedBarChartWithDifference": {
                let renderer;
                if (split === SplitModes.none) renderer = renderTableViewText;
                else if (split === SplitModes.gender || split === SplitModes.degree) renderer = renderMultiSeries;
                else renderer = renderWideMultiSeries;
                tableViewColumns = [
                    {
                        name: "JOB TITLE",
                        align: "left",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                    {
                        name: selectedStacks[0].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer,
                        style: { color: colors[0] },
                    },
                    {
                        name: selectedStacks[1].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer,
                        style: { color: colors[1] },
                    },
                    {
                        name: "Difference",
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                ];
                tableViewRows = data.categories.map((category, index) => {
                    let stack1Data;
                    let stack2Data;
                    if (split === SplitModes.none) {
                        stack1Data = {
                            text:
                                show === ShowModes.percent
                                    ? `${data.series[0][0].data[index]}%`
                                    : `${data.series[0][0].data[index]}y`,
                            align: "center",
                        };
                        stack2Data = {
                            text:
                                show === ShowModes.percent
                                    ? `${data.series[1][0].data[index]}%`
                                    : `${data.series[1][0].data[index]}y`,
                            align: "center",
                        };
                    } else {
                        stack1Data = data.series[0].map((serie) =>
                            show === ShowModes.percent ? `${serie.data[index]}%` : `${serie.data[index]}y`,
                        );
                        stack2Data = data.series[1].map((serie) =>
                            show === ShowModes.percent ? `${serie.data[index]}%` : `${serie.data[index]}y`,
                        );
                    }
                    return {
                        data: [
                            { text: category },
                            stack1Data,
                            stack2Data,
                            {
                                text:
                                    show === ShowModes.percent
                                        ? `${Math.abs(data.series[2][0].data[index])}%`
                                        : `${Math.abs(data.series[2][0].data[index])}y`,
                                align: "center",
                            },
                        ],
                    };
                });
                if (split !== SplitModes.none) {
                    const subHeader = data.series[0].map((serie) => serie.name);
                    newSubHeaderRow = { data: ["", subHeader, subHeader, ""] };
                }
                if (show === ShowModes.percent) {
                    setTitle(`Percentage of Profiles in ${chartTitle}`);
                } else if (show === ShowModes.durationYears) {
                    setTitle(`Average Work Experience in ${chartTitle}`);
                }
                if (split === SplitModes.group) {
                    minViewWidth = 950;
                }
                break;
            }
            case "StandardVerticalBarChart-normalValue": {
                tableViewColumns = selectedStacks[1]
                    ? [
                          {
                              name: "JOB TITLE",
                              align: "left",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                          },
                          {
                              name: selectedStacks[0].stack.name,
                              align: "center",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                              style: { color: colors[0] },
                          },
                          {
                              name: selectedStacks[1].stack.name,
                              align: "center",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                              style: { color: colors[1] },
                          },
                          {
                              name: "Difference",
                              align: "center",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                          },
                      ]
                    : [
                          {
                              name: "JOB TITLE",
                              align: "left",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                          },
                          {
                              name: selectedStacks[0].stack.name,
                              align: "center",
                              headerRenderer: renderTableViewHeader,
                              renderer: renderTableViewText,
                              style: { color: colors[0] },
                          },
                      ];
                tableViewRows = data.categories.map((category, index) => {
                    const stack1Data = { text: data.series[0].data[index].toFixed(2), align: "center" };
                    const stack2Data = data.series[1] && {
                        text: data.series[1].data[index]?.toFixed(2),
                        align: "center",
                    };
                    const difference = {
                        text:
                            data.series[1] &&
                            Math.abs(data.series[0].data[index] - data.series[1].data[index])?.toFixed(2),
                        align: "center",
                    };

                    const result = data.series[1]
                        ? {
                              data: [{ text: category }, stack1Data, stack2Data, difference],
                          }
                        : {
                              data: [{ text: category }, stack1Data],
                          };

                    return result;
                });
                setTitle(chartTitle.title);
                minViewWidth = 650;
                break;
            }
            case "MarkersBarChartWithDifference": {
                tableViewColumns = [
                    {
                        name: "EDUCATION DEGREE",
                        align: "left",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                    {
                        name: selectedStacks[0].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                        style: { color: colors[0] },
                    },
                    {
                        name: selectedStacks[1].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                        style: { color: colors[1] },
                    },
                    {
                        name: "Difference",
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                ];
                tableViewRows = data.categories.map((category, index) => {
                    const stack1Data = { text: `${data.series[0].data[index].y}y`, align: "center" };
                    const stack2Data = { text: `${data.series[1].data[index].y}y`, align: "center" };
                    return {
                        data: [
                            { text: category },
                            stack1Data,
                            stack2Data,
                            {
                                text: `${Math.abs(data.series[2][0].data[index])}y`,
                                align: "center",
                            },
                        ],
                    };
                });
                setTitle(chartTitle);
                minViewWidth = 650;
                break;
            }
            case "HistogramLineChart": {
                tableViewColumns = [
                    {
                        name: "YEARS",
                        align: "left",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                    {
                        name: selectedStacks[0].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderMultiSeries,
                        style: { color: colors[0] },
                    },
                    {
                        name: selectedStacks[1].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderMultiSeries,
                        style: { color: colors[1] },
                    },
                ];

                let combinedCategories = {};
                data.categories.forEach((categoryList) =>
                    categoryList.forEach((category) => {
                        combinedCategories[category] = true;
                    }),
                );
                combinedCategories = Object.keys(combinedCategories)
                    .map((key) => parseInt(key))
                    .sort((a, b) => a - b);

                const sets = data.categories.map((categoryList, index) => {
                    const categoryToDataPoint = {};
                    categoryList.forEach((category, categoryIndex) => {
                        categoryToDataPoint[category] = data.series[index].map((serie) => serie.data[categoryIndex]);
                    });
                    return categoryToDataPoint;
                });

                tableViewRows = combinedCategories.map((category, index) => {
                    const stack1Data = sets.map((mapping) => `${mapping[category] ? mapping[category][0] || 0 : 0}%`);
                    const stack2Data = sets.map((mapping) => `${mapping[category] ? mapping[category][1] || 0 : 0}%`);

                    if (index === combinedCategories.length - 1) {
                        return {
                            data: [{ text: `${category}+` }, stack1Data, stack2Data],
                        };
                    }
                    return {
                        data: [{ text: `${category} - ${category + 1}` }, stack1Data, stack2Data],
                    };
                });
                const subHeader = ["Previous", "Current", "Total"];
                newSubHeaderRow = { data: ["", subHeader, subHeader] };

                setTitle(chartTitle);
                minViewWidth = 650;
                break;
            }
            case "StandardVerticalBarChart-grouped": {
                tableViewColumns = [
                    {
                        name: "YEARS",
                        align: "left",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderTableViewText,
                    },
                    {
                        name: selectedStacks[0].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderMultiSeries,
                        style: { color: colors[0] },
                    },
                    {
                        name: selectedStacks[1].stack.name,
                        align: "center",
                        headerRenderer: renderTableViewHeader,
                        renderer: renderMultiSeries,
                        style: { color: colors[1] },
                    },
                ];

                tableViewRows = data.categories[0].map((category, categoryIndex) => {
                    const stack1Data = data.series.map((serie) => `${serie[0].data[categoryIndex]}%`);
                    const stack2Data = data.series.map((serie) => `${serie[1].data[categoryIndex]}%`);

                    return {
                        data: [{ text: category }, stack1Data, stack2Data],
                    };
                });
                const subHeader = ["Previous", "Current", "Total"];
                newSubHeaderRow = { data: ["", subHeader, subHeader] };

                setTitle(chartTitle);
                minViewWidth = 650;
                break;
            }
            // no default
        }

        if (newSubHeaderRow) {
            setSubHeaderRow(newSubHeaderRow);
            setDataWindow([newSubHeaderRow, ...tableViewRows.slice(0, itemsPerPage)]);
        } else {
            setDataWindow(tableViewRows.slice(0, itemsPerPage));
        }

        return [tableViewColumns, tableViewRows, minViewWidth];
    }, []);

    const classes = useStyles({ minWidth });

    const handlePageChanged = (pageNum, start, end) => {
        setPage(pageNum);
        if (subHeaderRow) {
            setDataWindow([subHeaderRow, ...rows.slice(start, end)]);
        } else {
            setDataWindow(rows.slice(start, end));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            translate="no"
            PaperProps={{ elevation: 4 }}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle className={classes.title}>
                <Stack direction="row">
                    <Typography variant="h6">{`${title}`}</Typography>
                    <Typography variant="h6" mx={0.75}>
                        -
                    </Typography>
                    <Typography variant="h6" color="#4f77ec">{`${selectedStacks[0].stack.name}`}</Typography>
                    {selectedStacks[1]?.stack?.name && (
                        <>
                            <Typography variant="h6" mx={0.75}>
                                vs
                            </Typography>
                            <Typography variant="h6" color="#5ec793">{`${selectedStacks[1].stack.name}`}</Typography>
                        </>
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Table columns={columns} rows={dataWindow} className={classes.table} />
                <Pagination
                    page={page}
                    limit={itemsPerPage}
                    totalRecords={rows.length}
                    onChange={handlePageChanged}
                    backgroundColor="primary"
                    textColor={theme.palette.primary.main}
                    style={{ padding: "8px 22px 0 0", margin: 0 }}
                />

                <DialogActions>
                    <Button color="primary" onClick={handleClose} className={classes.dialogButton}>
                        Close
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
