import React, { useState, useEffect, useMemo } from "react";
import { TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "debounce";
import { requestedCompaniesSliceSelectors, setSearchValue } from "../../../../slices/Stacks/requestedCompaniesSlice";

const useStyles = makeStyles(() => ({
    textField: {
        width: "100%",
    },
}));

export default function Search() {
    const classes = useStyles();
    const delayTime = 500;
    const dispatch = useDispatch();
    const filter = useSelector(requestedCompaniesSliceSelectors.getFilter);
    const [name, setName] = useState(filter?.name || "");
    const [isHover, setHover] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const showTooltip = isHover && !isFocus;

    const onSearch = (value) => {
        dispatch(setSearchValue(value));
    };

    const debouncedChangeHandler = useMemo(() => debounce(onSearch, 500), []);

    useEffect(
        () => () => {
            debouncedChangeHandler.clear();
        },
        [],
    );

    return (
        <Tooltip
            open={showTooltip}
            enterNextDelay={delayTime}
            title="Search of the name of a CV Stack to narrow down the list of Stacks."
            placement="top"
        >
            <TextField
                open={showTooltip}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onMouseOver={() => {
                    if (!isFocus) {
                        setHover(true);
                    }
                }}
                onMouseLeave={() => setHover(false)}
                label="Search here..."
                variant="outlined"
                margin="normal"
                size="small"
                color="secondary"
                fullWidth
                onChange={({ target }) => {
                    const { value } = target;
                    setName(value);
                    debouncedChangeHandler(value);
                }}
                value={name}
                className={classes.textField}
            />
        </Tooltip>
    );
}
