import React from "react";
import { Grid, Paper, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { navigate } from "@gatsbyjs/reach-router";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import InsertChartRoundedIcon from "@mui/icons-material/InsertChartRounded";
import HorizontalSplitRoundedIcon from "@mui/icons-material/HorizontalSplitRounded";
import CallMadeIcon from "@mui/icons-material/CallMade";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
    },
    container: {
        margin: "5% auto",
    },
    card: {
        width: 250,
        height: 250,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 20px 20px 20px",
        cursor: "pointer",
        "&:hover .MuiSvgIcon-root": {
            marginBottom: 20,
        },
    },
    icon: {
        transition: "margin .3s",
        width: 60,
        height: 60,
        marginBottom: 0,
    },
}));

export default function AdminComponent() {
    const classes = useStyles();

    return (
        <Grid container className={classes.background}>
            <Stack className={classes.container} p={3} pt={2} spacing={7} alignItems="center">
                <Stack direction="row" spacing={7}>
                    <Paper elevation={1} className={classes.card} onClick={() => navigate("/admin/users")}>
                        <Typography variant="h6">Users</Typography>
                        <PeopleAltRoundedIcon className={classes.icon} />
                        <Typography align="center" variant="caption">
                            Interface to manage users
                        </Typography>
                    </Paper>
                    <Paper elevation={1} className={classes.card} onClick={() => navigate("/admin/organizations")}>
                        <Typography variant="h6">Organizations</Typography>
                        <BusinessRoundedIcon className={classes.icon} />
                        <Typography align="center" variant="caption">
                            Interface to manage organizations
                        </Typography>
                    </Paper>
                    <Paper elevation={1} className={classes.card} onClick={() => navigate("/admin/referrals")}>
                        <Typography variant="h6">Referrals</Typography>
                        <CallMadeIcon className={classes.icon} />
                        <Typography align="center" variant="caption">
                            Interface to manage referrals
                        </Typography>
                    </Paper>
                </Stack>
                <Stack direction="row" spacing={7}>
                    <Paper elevation={1} className={classes.card} onClick={() => navigate("/admin/data-requests")}>
                        <Typography variant="h6">Data Requests</Typography>
                        <InsertChartRoundedIcon className={classes.icon} />
                        <Typography align="center" variant="caption">
                            Interface to manage data requests
                        </Typography>
                    </Paper>
                    <Paper elevation={1} className={classes.card} onClick={() => navigate("/admin/stacks")}>
                        <Typography variant="h6">Stacks</Typography>
                        <HorizontalSplitRoundedIcon className={classes.icon} />
                        <Typography align="center" variant="caption">
                            Interface to manage stacks
                        </Typography>
                    </Paper>
                </Stack>
            </Stack>
        </Grid>
    );
}
