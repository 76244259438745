import React from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Header } from "../../Header";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    title: {
        margin: "24px 0 24px 16px",
    },
}));

export default function TermsDialog({ open, handleClose }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleClose}>
                <div className={classes.container}>
                    <Header>
                        <Typography className={classes.title} variant="h6">
                            Terms and Conditions
                        </Typography>
                    </Header>
                    <DialogContent>
                        <Typography variant="body2">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas placerat erat et dapibus
                            aliquam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                            himenaeos. Sed vestibulum feugiat magna quis cursus. Sed neque nisl, malesuada in erat ut,
                            molestie bibendum metus. Morbi ipsum metus, ultricies vel nisi ut, aliquam aliquam lacus.
                            Duis placerat pulvinar ex et ornare. Etiam at auctor lorem. Donec luctus nibh neque, non
                            viverra arcu dictum eget. Maecenas semper mattis convallis. Praesent cursus, elit et
                            dignissim rhoncus, magna magna semper nunc, vel blandit libero elit a massa. Maecenas sed
                            luctus neque, tincidunt mattis urna. Aliquam nunc ante, sodales vel faucibus ac, fringilla
                            eu justo. Nunc mattis ante ac massa tristique, nec gravida nulla dignissim. Donec maximus
                            nisi enim, a sollicitudin ante dignissim sit amet. Ut in velit fermentum, vulputate ante
                            eget, pulvinar leo. Sed hendrerit diam ac condimentum hendrerit. Donec imperdiet ligula non
                            pulvinar accumsan. Pellentesque habitant morbi tristique senectus et netus et malesuada
                            fames ac turpis egestas. Donec purus est, vulputate eget tempus nec, blandit id lacus.
                            Phasellus vel risus hendrerit, bibendum nulla vitae, rutrum justo. Suspendisse vulputate
                            magna at lectus faucibus, a aliquam lacus gravida. Nulla efficitur risus vel egestas
                            hendrerit. Donec id urna vel ex malesuada sollicitudin. Donec sit amet varius erat. Integer
                            sit amet consequat dolor. Sed placerat tristique ex, a maximus orci dictum a. Proin at dui
                            rhoncus, eleifend odio a, blandit urna. Vestibulum ultrices in diam non consectetur. Donec
                            sem massa, consectetur et tempor eu, convallis quis nulla. Aliquam erat volutpat. Interdum
                            et malesuada fames ac ante ipsum primis in faucibus. Quisque quis aliquet erat. Nunc
                            suscipit finibus enim vitae malesuada. Donec luctus accumsan neque, nec pulvinar sapien
                            cursus lacinia. Nam non lectus sollicitudin, tempor diam in, ultrices magna. Fusce vitae
                            tristique nulla. Pellentesque tristique metus nibh, quis porta lacus mollis at. Nunc sed mi
                            convallis, pharetra sem nec, venenatis mauris. Cras eleifend at massa et luctus. Nullam
                            rhoncus enim non lorem posuere aliquam. Suspendisse ultricies est at turpis tincidunt, ut
                            varius urna feugiat. Etiam tristique nunc in dictum ultrices. Quisque condimentum
                            sollicitudin hendrerit. Quisque lectus nulla, ultrices ut posuere elementum, tincidunt quis
                            ex. Suspendisse posuere tempor dolor, sed mollis ligula porttitor ac. Phasellus velit augue,
                            porttitor a velit quis, laoreet tempus sem. Etiam dignissim lorem sit amet ex rhoncus,
                            pulvinar sagittis augue tincidunt. Fusce mollis tortor justo, ut ornare ex rhoncus non.
                            Aliquam vulputate lectus eu ex bibendum molestie. Morbi sagittis mauris justo, vel maximus
                            nunc pretium quis. In hac habitasse platea dictumst. Morbi in varius nunc, eget suscipit
                            sapien. Donec egestas, massa in tempor fringilla, tortor diam pellentesque ante, eu
                            hendrerit felis arcu sit amet purus. Class aptent taciti sociosqu ad litora torquent per
                            conubia nostra, per inceptos himenaeos. Integer arcu diam, posuere vitae ultrices a,
                            fermentum porta tellus. Donec non convallis purus. Quisque ultricies tellus eget justo
                            luctus, sed vehicula massa lobortis. Maecenas erat metus, pharetra eu purus eget, imperdiet
                            sollicitudin neque. Maecenas eu vehicula lorem.
                        </Typography>
                    </DialogContent>
                </div>
            </Dialog>
    );
}
