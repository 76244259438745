import React from "react";
import { Stack, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    chip: {
        maxWidth: 140,
        fontSize: 12,
        height: 24,
        marginRight: 4,
        marginBottom: 8,
        backgroundColor: "#fff",
        color: theme.palette.additional1.main,
        border: `1px solid ${theme.palette.dividerAndOutline[300]}`,
        "&:hover": {
            border: `1px solid ${theme.palette.dividerAndOutline[300]}`,
            backgroundColor: theme.palette.additional1[500],
            color: "#fff",
            "& .MuiChip-deleteIcon": {
                fontSize: 20,
                color: `#ccd6f7 !important`,
            },
        },
        "& span": {
            padding: "0 9px",
        },
        "& .MuiChip-deleteIcon": {
            fontSize: 20,
            color: `${theme.palette.additional1.main} !important`,
        },
    },
    selectedChip: {
        maxWidth: 140,
        fontSize: 12,
        height: 24,
        marginRight: 4,
        marginBottom: 8,
        backgroundColor: theme.palette.additional1[800],
        color: "#fff",
        border: `1px solid ${theme.palette.dividerAndOutline[300]}`,
        "& .MuiChip-deleteIcon": {
            fontSize: 20,
            color: `#ccd6f7 !important`,
        },
        "&:hover": {
            border: `1px solid ${theme.palette.dividerAndOutline[300]}`,
            backgroundColor: theme.palette.additional1[800],
            color: "#fff",
            "& .MuiChip-deleteIcon": {
                fontSize: 20,
                color: `#ccd6f7 !important`,
            },
        },
    },
}));

export default function SelectedFilters({ filters, onRemoveCategory, onRemoveFilter }) {
    const classes = useStyles();

    return (
        <Stack maxWidth={300} spacing={3}>
            {!!filters.jobTitles.length && (
                <Stack direction="row" flexWrap="wrap">
                    <Chip
                        className={classes.selectedChip}
                        label="Job Titles"
                        onDelete={() => onRemoveCategory("jobTitles")}
                    />
                    {filters.jobTitles.map((jobTitle, index) => (
                        <Chip
                            key={jobTitle.id}
                            className={classes.chip}
                            label={jobTitle.name}
                            variant="outlined"
                            onDelete={() => onRemoveFilter("jobTitles", index)}
                        />
                    ))}
                </Stack>
            )}
            {!!filters.universities.length && (
                <Stack direction="row" flexWrap="wrap">
                    <Chip
                        className={classes.selectedChip}
                        label="Universities"
                        onDelete={() => onRemoveCategory("universities")}
                    />
                    {filters.universities.map((university, index) => (
                        <Chip
                            key={university.id}
                            className={classes.chip}
                            label={university.name}
                            variant="outlined"
                            onDelete={() => onRemoveFilter("universities", index)}
                        />
                    ))}
                </Stack>
            )}
            {!!filters.languages.length && (
                <Stack direction="row" flexWrap="wrap">
                    <Chip
                        className={classes.selectedChip}
                        label="Languages"
                        onDelete={() => onRemoveCategory("languages")}
                    />
                    {filters.languages.map((language, index) => (
                        <Chip
                            key={language.id}
                            className={classes.chip}
                            label={language.name}
                            variant="outlined"
                            onDelete={() => onRemoveFilter("languages", index)}
                        />
                    ))}
                </Stack>
            )}
            {!!filters.cities.length && (
                <Stack direction="row" flexWrap="wrap">
                    <Chip className={classes.selectedChip} label="Cities" onDelete={() => onRemoveCategory("cities")} />
                    {filters.cities.map((city, index) => (
                        <Chip
                            key={city.id}
                            className={classes.chip}
                            label={city.name}
                            variant="outlined"
                            onDelete={() => onRemoveFilter("cities", index)}
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
