import React from "react";
import { Typography, Paper, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    section: {
        width: "100%",
        padding: (props) => props.padding || "36px 0",
    },
    sectionName: {
        margin: "14px 0 13px 0",
        fontWeight: 500,
    },
}));

export function MetricSection({ children, name, padding }) {
    const classes = useStyles({ padding });

    return (
        <Stack alignItems="center">
            <Typography mt={1.75} mb={1.5} variant="overline" component="div" color="primary">
                {name}
            </Typography>
            <Paper elevation={1} className={classes.section}>
                {children}
            </Paper>
        </Stack>
    );
}
