import * as yup from "yup";

export const newPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, "Password must have at least 8 characters")
        .matches(/^(?=.*[a-z])/, "Your password must have at least one lowercase letter")
        .matches(/^(?=.*[A-Z])/, "Your password must have at least one uppercase letter")
        .matches(/^((?=.*[0-9])|(?=.*\W))/, "Your password must have at least one number or symbol")
        .oneOf([yup.ref("password_confirmation")], "Password doesn't match")
        .required("Field is required"),
    password_confirmation: yup
        .string()
        .min(8, "Password must have at least 8 characters")
        .matches(/^(?=.*[a-z])/, "Your password must have at least one lowercase letter")
        .matches(/^(?=.*[A-Z])/, "Your password must have at least one uppercase letter")
        .matches(/^((?=.*[0-9])|(?=.*\W))/, "Your password must have at least one number or symbol")
        .oneOf([yup.ref("password")], "Password doesn't match")
        .required("Field is required"),
});
