import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogActions, TextField, Button, Typography, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { updateSettingsPreference } from "../../../../actions/user/settings";
import { clearFormErrors, userSliceSelectors } from "../../../../slices/User/userSlice";
import { deleteAccountSchema } from "./deleteAccountSchema";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
        padding: theme.spacing(1),
    },
    dialogActions: {
        marginTop: 10,
    },
    textField: {
        marginTop: theme.spacing(4),
    },
    deleteAccountText: {
        marginTop: theme.spacing(2.5),
    },
    inputPasswordText: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(0.1),
        fontSize: 12,
    },
    subtitle: {
        fontSize: 12,
    },
    confirmText: {
        marginTop: theme.spacing(2),
    },
    boxContainer: {
        width: "100%",
    },
    titleContainer: {
        padding: "16px 0 16px 24px",
    },
    divider: {
        margin: "0 24px",
    },
}));

export default function DeleteAccountDialog({ open, handleClose, openDialog }) {
    const { id } = useSelector(userSliceSelectors.defaultUserFields);
    const formErrors = useSelector(userSliceSelectors.formErrors);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(deleteAccountSchema),
    });

    useEffect(() => {
        if (confirmDialogOpen && formErrors && Object.keys(formErrors).length > 0) {
            for (const [key, value] of Object.entries(formErrors)) {
                setConfirmDialogOpen(false);
                openDialog();
                setError(key, { message: value, type: "manual" }, { shouldFocus: true });
                setValue(key, "");
            }
        }
    }, [formErrors]);

    const onCloseDeleteDialog = () => {
        reset();
        dispatch(clearFormErrors());
        handleClose();
    };

    const onCloseConfirmDialog = () => {
        setConfirmDialogOpen(false);
        setSubmitData(null);
    };

    const onSubmit = (data) => {
        const onDone = () => {
            onCloseConfirmDialog();
            localStorage.removeItem("token");
            navigate("/farewell");
        };
        setSubmitData({ id, userData: { user: { delete: data.delete } }, onDone });
        onCloseDeleteDialog();
        setConfirmDialogOpen(true);
    };

    const onConfirm = () => {
        dispatch(updateSettingsPreference(submitData));
    };

    return (
        <>
            {open || confirmDialogOpen ? (
                !confirmDialogOpen ? (
                    <Dialog open={open} onClose={onCloseDeleteDialog}>
                        <div className={classes.container}>
                            <Box className={classes.boxContainer}>
                                <Box className={classes.titleContainer}>
                                    <Typography variant="h6" color="primary">
                                        Delete Account
                                    </Typography>
                                </Box>
                                <Divider className={classes.divider} />
                            </Box>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <DialogContent>
                                    <Typography gutterBottom variant="body2">
                                        Are you sure you want to delete your account?
                                    </Typography>
                                    <Typography
                                        gutterBottom
                                        variant="body3"
                                        className={classes.deleteAccountText}
                                        color="alert.main"
                                    >
                                        This cannot be undone and you will lose all of your account data.
                                    </Typography>
                                    <Box>
                                        <TextField
                                            {...register("delete")}
                                            className={classes.textField}
                                            fullWidth
                                            variant="outlined"
                                            label="Password"
                                            type="password"
                                            error={Boolean(errors.delete)}
                                        />
                                        {errors.delete && (
                                            <Typography color="error" variant="subtitle2">
                                                {errors.delete.message}
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box className={classes.inputPasswordText}>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            className={classes.subtitle}
                                        >
                                            Please input your password so we know it's really you and not some meanie.
                                        </Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions className={classes.dialogActions}>
                                    <Button onClick={onCloseDeleteDialog} color="primary">
                                        Cancel
                                    </Button>
                                    <Button color="primary" type="submit" disabled={Object.keys(errors).length > 0}>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </form>
                        </div>
                    </Dialog>
                ) : (
                    <Dialog open={confirmDialogOpen} onClose={onCloseConfirmDialog}>
                        <div className={classes.container}>
                            <Box className={classes.boxContainer}>
                                <Box className={classes.titleContainer}>
                                    <Typography variant="h6">Delete Account</Typography>
                                </Box>
                                <Divider className={classes.divider} />
                            </Box>
                            <DialogContent>
                                <Typography gutterBottom variant="h6" className={classes.confirmText}>
                                    Are you absolutely sure you want to delete your account? This cannot be undone.
                                </Typography>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button onClick={onCloseConfirmDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button color="primary" onClick={onConfirm}>
                                    Yeah, I'm out of here!
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                )
            ) : null}
        </>
    );
}
