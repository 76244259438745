import React from "react";
import { Paper, Box, Typography, Fade } from "@mui/material";
import Flag from "react-country-flag";
import { makeStyles } from "@mui/styles";
import cityImage from "../../../images/col_qol_placeholder.png";

const useStyles = makeStyles(() => ({
    cityContainer: {
        flex: 1,
        maxWidth: 344,
        margin: "10px 25px 0 25px",
        position: "sticky",
        top: 100,
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    cityImageWrapper: {
        width: "100%",
        height: 400,
    },
    cityImage: {
        position: "absolute",
        zIndex: -1,
        width: "100%",
        height: "400px",
        objectFit: "cover",
        borderRadius: 4,
    },
    cityGeneral: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
    },
    flagContainer: {
        width: 50,
        height: 30,
        margin: "24px 0 37px 0",
    },
}));

const TemporaryImagePlaceholderUrl = cityImage;

export function CityInfo({ image, title, subtitle, value, name, iso2Code }) {
    const classes = useStyles();

    return (
        <Fade in timeout={500}>
            <Paper elevation={1} className={classes.cityContainer}>
                <Box className={classes.cityImageWrapper}>
                    <img className={classes.cityImage} src={image || TemporaryImagePlaceholderUrl} alt="current city" />
                </Box>
                <Box className={classes.cityGeneral}>
                    <Typography variant="h5" mt={2.5}>
                        {title}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" translate="no">
                        {subtitle}
                    </Typography>
                    <Typography variant="h2Bold" color="success.700" mt={4} mb={1.25}>
                        {value}
                    </Typography>
                    <Typography variant="h3Bold" translate="no">
                        {name}
                    </Typography>
                    <Box className={classes.flagContainer}>
                        <Flag
                            style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 4 }}
                            countryCode={iso2Code}
                            svg
                        />
                    </Box>
                </Box>
            </Paper>
        </Fade>
    );
}
