import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { LocationProvider } from "@gatsbyjs/reach-router";
import { store } from "./config/core/Store";
import Osterus from "./Osterus";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/400.css";

import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

ReactDOM.render(
    <Provider store={store}>
        <LocationProvider>
            <Osterus />
        </LocationProvider>
    </Provider>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
