import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SpeedDialMenu } from "../SpeedDialMenu";

const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        width: "100%",
        minHeight: 40,
        background: "#F5F5F5",
        alignItems: "center",
    },
    titleBox: {
        width: "100%",
    },
    titleTypography: {
        width: "95%",
        cursor: "pointer",
        paddingLeft: ({ paddingLeft }) => paddingLeft,
        paddingRight: ({ paddingRight }) => paddingRight,
        textAlign: ({ textAlign }) => textAlign,
    },
    axisLabel: {
        color: "red",
        background: "green",
        fill: "green",
    },
}));

export const PlainContainer = React.forwardRef(
    (
        {
            children,
            barchartref,
            page,
            nextPage,
            previousPage,
            lastPage,
            title,
            showTitle,
            reversed,
            titleStyles,
            titleTypographyStyle,
            showActions = true,
            actionsOffset = { y: -43 },
        },
        ref,
    ) => {
        const [menuOpen, setMenuOpen] = useState(false);

        const titlePadding = {
            paddingLeft: reversed ? 0 : "15%",
            paddingRight: reversed ? "15%" : 0,
            textAlign: reversed ? "right" : "left",
        };
        const classes = useStyles(titlePadding);

        return (
            <Stack
                ref={ref}
                height="100%"
                onMouseOver={() => {
                    if (!menuOpen) setMenuOpen(true);
                }}
                onMouseLeave={() => {
                    setMenuOpen(false);
                }}
                width="100%"
            >
                {showTitle && (
                    <div className={classes.header} style={titleStyles}>
                        <div className={classes.titleBox}>
                            <Stack
                                direction="row"
                                alignItems="baseline"
                                width="100%"
                                justifyContent={reversed ? "flex-end" : "flex-start"}
                            >
                                <Typography
                                    variant="h5"
                                    color="textSecondary"
                                    className={classes.titleTypography}
                                    mr={0.5}
                                    noWrap
                                    style={titleTypographyStyle}
                                >
                                    {title}
                                </Typography>
                            </Stack>
                        </div>
                        <SpeedDialMenu
                            open={menuOpen && showActions}
                            closeMenu={() => setMenuOpen(false)}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            page={page}
                            lastPage={lastPage}
                            barchartref={barchartref}
                            actionsOffset={actionsOffset}
                        />
                    </div>
                )}
                {children}
            </Stack>
        );
    },
);
