import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsersApi } from "../../services/api/Admin/users";

export const getUsers = createAsyncThunk("admin/users", async (params) => {
    try {
        const { data } = await getUsersApi(params);
        return { data };
    } catch (err) {
        throw err;
    }
});
