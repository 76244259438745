import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, Paper, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import usFlag from "../../../../../images/us-flag.png";
import germanyFlag from "../../../../../images/germany-flag.png";
import ukFlag from "../../../../../images/uk-flag.png";
import berlinCity from "../../../../../images/berlin.png";
import bostonCity from "../../../../../images/boston.png";
import londonCity from "../../../../../images/london.png";
import { intelligenceSelectors } from "../../../../../slices/Intelligence/IntelligenceSlice";
import { getDefaultCities } from "../../../../../actions/intelligence";
import { updateFilter } from "../../../../../slices/Intelligence/UniversitiesSlice";

const useStyles = makeStyles(() => ({
    root: {
        justifyContent: "center",
        textAlign: "center",
    },
    text: {
        color: "#7F7F7F",
        fontSize: 11,
    },
    boldText: {
        fontWeight: 500,
    },
    header: {
        textAlign: "left",
        paddingLeft: 110,
        paddingBottom: 2,
        color: "#7F7F7F",
        fontSize: 14,
        fontWeight: 400,
    },
    image: {
        width: "179px",
        height: "159px",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    universitiesText: {
        textAlign: "left",
        paddingLeft: 7,
        fontSize: 14,
    },
    universitiesSubText: {
        textAlign: "left",
        paddingLeft: 7,
        fontWeight: 500,
        fontSize: 14,
    },
    box: {
        width: 180,
        height: 140,
        backgroundSize: "cover",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundPosition: "center",
    },
    link: {
        cursor: "pointer",
        textDecoration: "none",
    },
}));

export default function NoContent() {
    const defaultCities = useSelector(intelligenceSelectors.defaultCities);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (defaultCities) return;
        dispatch(getDefaultCities());
    }, []);

    const handleCountryLinkClicked = (linkIndex) => {
        let clickedCity;
        if (linkIndex === 0) {
            clickedCity = defaultCities.find((city) => city.country.name === "Germany");
        } else if (linkIndex === 1) {
            clickedCity = defaultCities.find((city) => city.country.name === "United Kingdom");
        } else if (linkIndex === 2) {
            clickedCity = defaultCities.find((city) => city.country.name === "United States");
        }

        dispatch(updateFilter({ countries: [clickedCity.country] }));
    };

    const handleCityLinkClicked = (linkIndex) => {
        let clickedCity;
        if (linkIndex === 0) {
            clickedCity = defaultCities.find((city) => city.name === "Berlin");
        } else if (linkIndex === 1) {
            clickedCity = defaultCities.find((city) => city.name === "London");
        } else if (linkIndex === 2) {
            clickedCity = defaultCities.find((city) => city.name === "Boston");
        }

        dispatch(updateFilter({ cities: [clickedCity], countries: [clickedCity.country] }));
    };

    return (
        <Box className={classes.root}>
            <Typography variant="h4" className={classes.typographyPrimary}>
                Let's Explore The Universities Of The World
            </Typography>
            <Typography>
                Use the search and filtering to browse through more than 18.000 universities and faculties in our
                database
            </Typography>
            <Typography pt={1} className={classes.text}>
                OR
            </Typography>
            <Typography pt={1} pb={6} className={classes.boldText}>
                start by selecting one of the suggested choices:
            </Typography>
            <Stack>
                <Typography className={classes.header}>Country</Typography>
                <Stack width="100%" direction="row" mb={1} justifyContent="center">
                    <Stack>
                        <Link className={classes.link} onClick={() => handleCountryLinkClicked(0)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${germanyFlag})`,
                                    }}
                                    className={classes.box}
                                />
                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>Germany</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleCountryLinkClicked(1)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${ukFlag})`,
                                    }}
                                    className={classes.box}
                                />

                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>United Kingdom</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleCountryLinkClicked(2)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${usFlag})`,
                                    }}
                                    className={classes.box}
                                />
                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>United States</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
            <Stack pt={3}>
                <Typography className={classes.header}>City</Typography>
                <Stack width="100%" direction="row" mb={1} justifyContent="center">
                    <Stack>
                        <Link className={classes.link} onClick={() => handleCityLinkClicked(0)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${berlinCity})`,
                                    }}
                                    className={classes.box}
                                />
                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>Berlin, Germany</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleCityLinkClicked(1)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${bostonCity})`,
                                    }}
                                    className={classes.box}
                                />
                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>London, United Kingdom</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                    <Stack pl={1}>
                        <Link className={classes.link} onClick={() => handleCityLinkClicked(2)}>
                            <Paper>
                                <Box
                                    style={{
                                        backgroundImage: `linear-gradient(0deg, #FFFFFF 19.3%, rgba(255, 255, 255, 0) 100%), url(${londonCity})`,
                                    }}
                                    className={classes.box}
                                />
                                <Typography color="text.secondary" className={classes.universitiesText}>
                                    Universities in
                                </Typography>
                                <Typography className={classes.universitiesSubText}>Boston, United States</Typography>
                            </Paper>
                        </Link>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
