import React from "react";
import { Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs/";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few sec",
        m: "1min ",
        mm: "%dmin",
        h: "1hr",
        hh: "%dhr",
        d: "1d",
        dd: "%dd",
        M: "1m",
        MM: "%dm",
        y: "1y",
        yy: "%dy",
    },
});

export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_MANAGER = "ROLE_MANAGER";

export const formatRole = (role, isItemView = false) => {
    switch (role) {
        case ROLE_SUPER_ADMIN:
            return !isItemView ? (
                <Tooltip placement="top" title="Super Admin">
                    <Typography variant="body2" color="text.secondaryVariant2">
                        SA
                    </Typography>
                </Tooltip>
            ) : (
                "Super Admin"
            );
        case ROLE_ADMIN:
            return !isItemView ? (
                <Tooltip placement="top" title="Admin">
                    <Typography variant="body2" color="text.secondaryVariant2">
                        A
                    </Typography>
                </Tooltip>
            ) : (
                "Admin"
            );
        case ROLE_MANAGER:
            return !isItemView ? (
                <Tooltip placement="top" title="Manager">
                    <Typography variant="body2" color="text.secondaryVariant2">
                        M
                    </Typography>
                </Tooltip>
            ) : (
                "Manager"
            );
        default:
            return "--";
    }
};

export const formatLastActive = (date) => (date ? dayjs().to(dayjs(date)) : "--");

export const formatOrganizationType = (type) => {
    if (type) {
        if (type === "PUBLIC") {
            return "Publicly held";
        }
        if (type === "PRIVATE") {
            return "Privately held";
        }
    }
    return "--";
};

export const formatTimeLeft = (date) => {
    if (date) {
        const daysLeft = dayjs(date).diff(dayjs(), "days");
        if (daysLeft > 0) {
            return daysLeft;
        }
    }
    return 0;
};

export const formatUserFirstAndLastName = (user, includeEmail = false) => {
    let formattedName = "";
    if (user?.first_name || user?.last_name) {
        if (user.first_name) {
            formattedName = user.first_name;
        }
        if (user.last_name) {
            formattedName = formattedName.length > 0 ? `${formattedName} ${user.last_name}` : user.last_name;
        }
        return formattedName;
    }
    return includeEmail && user.email ? user.email : "--";
};
