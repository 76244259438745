import { axiosInstance } from "../../../config/api/Axios";

export const updateOrganizationApi = async (id, data) => axiosInstance.put(`/admin/organizations/${id}`, data);

export const getOrganizationsApi = async (params = { start: 1 }) =>
    axiosInstance.get("/admin/organizations", { params });

export const getPlansApi = async (params) => axiosInstance.get("/admin/plans/", { params });

export const deleteOrganizationApi = async (id) => axiosInstance.delete(`/admin/organizations/${id}`);
