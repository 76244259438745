import * as yup from "yup";

export const companyRegisterSchema = yup.object().shape({
    full_name: yup.string().trim().required("Field is required"),
    companyDisplayName: yup.string(),
    companyEmail: yup.string().trim().email("Invalid email format").required("Field is required"),
    size: yup.string().required("Field is required"),
    industry: yup.string().required("Field is required"),
    linkedin: yup.string(),
    description: yup.string(),
});
