import React, { useEffect, useRef, useState, useMemo } from "react";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Paper, Box, Button, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
    clearFilter,
    setFilter,
    setIsInfo,
    setSelectedUser,
    userSliceSelectors,
    setEditModalOpen,
    setUpdateUser,
} from "../../../slices/User/userSlice";
import PaginationComponent from "../../Pagination";
import { getUsers } from "../../../actions/user/user";
import { Container } from "../../Container";
import { Header } from "../../Header";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import ItemView from "./ItemView";
import Filter from "./Filter";
import InviteUserModal from "./Modals/InviteUser";
import EditUserModal from "./Modals/EditUser";
import DeleteUserModal from "./Modals/DeleteUser";
import OsterusLoader from "../../Loader";
import Table from "../../Table/Table";
import { renderAvatar, renderLastActive, renderEmail } from "../../Table/CellRenderers";
import { COLUMN_TYPES } from "../../Table/columnTypes";
import { formatUserFirstAndLastName, formatRole, formatLastActive, ROLE_SUPER_ADMIN } from "./utils";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        minHeight: "calc(100vh - 65px)",
    },
    tableIconSpacing: {
        marginRight: theme.spacing(2),
    },
    tableSpacing: {
        padding: theme.spacing(2),
        paddingTop: 0,
        overflowY: "scroll",
        maxHeight: "calc(100vh - 250px)",
        "&::-webkit-scrollbar": {
            width: 6,
            borderRadius: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
        },
    },
    loader: {
        display: "flex",
        minHeight: 473,
        justifyContent: "center",
        alignItems: "center",
    },
    tableHead: {
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        height: theme.spacing(8),
        zIndex: 1,
    },
    tableContainer: {
        height: "90vh",
    },
}));

const breadcrumbComponents = {
    title: {
        text: "User Management",
        color: "primary",
    },
    breadcrumbs: [
        {
            text: "Settings",
            color: "textSecondary",
            link: null,
            icon: null,
        },
        {
            text: "User Management",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

const SORTABLE_COLUMNS = {
    fullName: "first_name",
    lastActive: "last_login_at",
    department: "department",
    createdAt: "created_at ",
    jobTitle: "title",
    status: "status",
    accessLevel: "access_level",
};

export default function UsersComponent() {
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const users = useSelector(userSliceSelectors.users) ?? [];
    const usersLoading = useSelector(userSliceSelectors.usersLoading);
    const selectedUser = useSelector(userSliceSelectors.selectedUser);
    const filter = useSelector(userSliceSelectors.filter);
    const isInfo = useSelector(userSliceSelectors.isInfo);
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const showLoader = usersLoading && users.length === 0;

    useEffect(
        () => () => {
            dispatch(clearFilter());
            dispatch(setIsInfo(false));
            dispatch(setSelectedUser(null));
        },
        [],
    );

    useEffect(() => {
        dispatch(
            getUsers({
                ...filter,
                sort: filter.sort || undefined,
                direction: filter.direction || undefined,
            }),
        );
    }, [filter]);

    const tableRef = useRef();

    const onPageChange = (page) => {
        dispatch(setFilter({ ...filter, page }));
        tableRef.current.scroll(0, 0);
    };

    const handleUserEdit = (user) => {
        dispatch(setUpdateUser(user));
        dispatch(setEditModalOpen(true));
    };

    const columns = useMemo(
        () => [
            { name: "", align: "left", renderer: renderAvatar },
            { name: "Full Name", align: "left", sortKey: SORTABLE_COLUMNS.fullName },
            { name: "Email", align: "left", renderer: renderEmail },
            { name: "Access Level", align: "left", sortKey: SORTABLE_COLUMNS.accessLevel },
            { name: "Status", align: "left", sortKey: SORTABLE_COLUMNS.status, type: COLUMN_TYPES.ICON },
            { name: "Last Active", align: "left", sortKey: SORTABLE_COLUMNS.lastActive, renderer: renderLastActive },
            { name: "Job Title", align: "left", sortKey: SORTABLE_COLUMNS.jobTitle },
            { name: "Department", align: "left", sortKey: SORTABLE_COLUMNS.department },
            { name: "Based in", align: "left" },
            { name: "Created at", align: "left", sortKey: SORTABLE_COLUMNS.createdAt },
        ],
        [],
    );

    const rows = useMemo(() => {
        if (!users || !users[0]) return [];
        return users[0].records.map((user) => ({
            data: [
                user.profile_picture || "",
                formatUserFirstAndLastName(user),
                user.email || "--",
                formatRole(user.roles ? (user.type === 2 ? ROLE_SUPER_ADMIN : Object.values(user.roles)[0]) : null),
                user.status === "active" ? (
                    <Tooltip title="Active User">
                        <CheckCircleIcon style={{ color: theme.palette.success.main }} />
                    </Tooltip>
                ) : user.status === "pending" ? (
                    <Tooltip title="User Registration Pending">
                        <CheckCircleIcon style={{ color: "#FFC700" }} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Deactivated User">
                        <CheckCircleIcon color="disabled" />
                    </Tooltip>
                ),
                {
                    title: dayjs(user.last_login_at).format("D MMMM, YYYY h:mm A"),
                    text: formatLastActive(user.last_login_at),
                },
                user.title || "--",
                user.department && user.department !== "0" ? user.department : "--",
                user.city?.name && user.city?.country?.name ? `${user.city.name}, ${user.city.country.name}` : "--",
                moment(user.created_at).format("DD.MM.YYYY"),
            ],
            actions: [{ icon: <EditIcon />, tooltip: "Edit User", callback: () => handleUserEdit(user) }],
        }));
    }, [users]);

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid className={classes.tableContainer} item xs={Boolean(selectedUser) && isInfo ? 6 : 10}>
                <Container>
                    <Header>
                        <Grid container>
                            <Grid item xs={6}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
                                <Button
                                    sx={{ fontWeight: 400 }}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setInviteModalOpen(true)}
                                    disableElevation
                                >
                                    Invite New User
                                </Button>
                            </Grid>
                        </Grid>
                    </Header>
                    {/* <Box mt={2} mb={2}>
                        Chips
                    </Box> */}
                </Container>
                <Box m={3} mt={0}>
                    <Paper elevation={1}>
                        <Box>
                            <Table
                                columns={columns}
                                rows={rows}
                                ref={tableRef}
                                onSortBy={(columnName, direction) =>
                                    dispatch(setFilter({ ...filter, sort: columnName, direction }))
                                }
                            />
                            {showLoader ? (
                                <Box className={classes.loader}>
                                    <OsterusLoader />
                                </Box>
                            ) : null}
                        </Box>
                    </Paper>
                    {users[0]?.totalRecords > 0 && (
                        <PaginationComponent
                            totalRecords={users[0]?.totalRecords}
                            onChange={onPageChange}
                            page={filter.page}
                            limit={filter.perPage}
                        />
                    )}
                </Box>
            </Grid>
            {Boolean(selectedUser) && isInfo && (
                <Grid item xs={4}>
                    <ItemView />
                </Grid>
            )}
            <InviteUserModal open={inviteModalOpen} handleClose={() => setInviteModalOpen(false)} />
            <EditUserModal />
            <DeleteUserModal />
        </Grid>
    );
}
