import React, { useState } from "react";
import { TableRow, Stack, Tooltip, Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    actionsContainer: {
        position: "absolute",
        right: 0,
        height: "100%",
        borderBottom: `1px solid ${theme.palette.dividerAndOutline[500]}`,
        background: "linear-gradient(to left, #fff 80%, transparent 120%)",
        minWidth: 140,
    },
}));

export default function Row({ actions, onClick, children, actionsStyles }) {
    const [hovered, setHovered] = useState(false);
    const classes = useStyles();

    const handleActionClicked = (e, callback) => {
        e.stopPropagation();
        callback();
    };

    const renderActions = (rowActions) => {
        const components = rowActions.map((action, index) => {
            if (!action) return undefined;

            let component;
            if (action.text) {
                component = (
                    <Tooltip key={index} title={action.tooltip || ""}>
                        <Button
                            type="text"
                            size="small"
                            disableElevation
                            startIcon={action.icon}
                            onClick={(e) => handleActionClicked(e, action.callback)}
                        >
                            {action.text}
                        </Button>
                    </Tooltip>
                );
            } else {
                component = (
                    <Tooltip key={index} title={action.tooltip || ""}>
                        <IconButton onClick={(e) => handleActionClicked(e, action.callback)}>{action.icon}</IconButton>
                    </Tooltip>
                );
            }

            return component;
        });

        return (
            <Stack height="100%" direction="row" justifyContent="flex-end" alignItems="center" ml="20%">
                {components}
            </Stack>
        );
    };

    return (
        <TableRow
            sx={{ position: "relative" }}
            onClick={onClick}
            onMouseOver={actions ? () => setHovered(true) : undefined}
            onMouseLeave={actions ? () => setHovered(false) : undefined}
        >
            {children}
            {actions && hovered && (
                <Stack className={classes.actionsContainer} style={{ ...actionsStyles }}>
                    {renderActions(actions)}
                </Stack>
            )}
        </TableRow>
    );
}
