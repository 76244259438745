import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { Stack, Tabs, Tab, Tooltip, Typography, IconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { makeStyles } from "@mui/styles";
import {
    EMPLOYMENT_COMPARISON_INSIGHT,
    EMPLOYMENT_SINGLE_INSIGHT,
    EDUCATION_COMPARISON_INSIGHT,
    EDUCATION_SINGLE_INSIGHT,
    OVERVIEW_COMPARISON_INSIGHT,
    OVERVIEW_SINGLE_INSIGHT,
    DEMOGRAPHICS_COMPARISON_INSIGHT,
    DEMOGRAPHICS_SINGLE_INSIGHT,
} from "../Views/viewConstants";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
    setShowAndSplit,
} from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

const useStyles = makeStyles(() => ({
    tabs: {
        "& button": {
            border: 0,
            backgroundColor: "white",
            borderRadius: 10,
            textTransform: "none",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: "#5e60e1",
            color: "white",
            borderRadius: 10,
        },
        "& .MuiButtonBase-root": {
            padding: "0 5px",
            minHeight: "35px",
        },
        "& .MuiTab-root": {
            pointerEvents: "auto",
        },
    },
}));

export function TabHeader({ viewType, selectedStacks, isLoading, onFilterClicked, isSingle }) {
    const [currentTab, setCurrentTab] = useState(viewType);
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    const totalFilters = Object.values(appliedFilters).reduce((curSum, val) => curSum + (val.length || 0), 0);

    const dispatch = useDispatch();

    const classes = useStyles();

    const onTabChanged = (e, tab) => {
        dispatch(setShowAndSplit({ show: ShowModes.percent, split: SplitModes.none }));
        setCurrentTab(tab);
        if (isSingle) {
            navigate(`/the-lab/stacks/stack-insights/${tab}/${selectedStacks[0].id}`);
        } else {
            navigate(`/the-lab/stacks/stack-insights/${tab}/${selectedStacks[0].id}/${selectedStacks[1].id}`);
        }
    };

    return (
        <>
            {selectedStacks.length && (
                <Stack margin="auto" mt={1} mb={0} pb={0} mx={2}>
                    <Stack>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            paddingY={1}
                            position="relative"
                        >
                            <Tabs className={classes.tabs} value={currentTab} onChange={onTabChanged}>
                                <Tab
                                    value={isSingle ? OVERVIEW_SINGLE_INSIGHT : OVERVIEW_COMPARISON_INSIGHT}
                                    label="Overview"
                                />
                                <Tab
                                    value={isSingle ? EMPLOYMENT_SINGLE_INSIGHT : EMPLOYMENT_COMPARISON_INSIGHT}
                                    label="Jobs and Experience"
                                />
                                <Tab
                                    value={isSingle ? EDUCATION_SINGLE_INSIGHT : EDUCATION_COMPARISON_INSIGHT}
                                    label="Education"
                                />
                                <Tab
                                    value={isSingle ? DEMOGRAPHICS_SINGLE_INSIGHT : DEMOGRAPHICS_COMPARISON_INSIGHT}
                                    label="Demographics"
                                />
                                <Tab
                                    value={5}
                                    label={
                                        <Tooltip title="Coming soon.">
                                            <span>Skills and Salaries</span>
                                        </Tooltip>
                                    }
                                    disabled
                                />
                                <Tab
                                    value={6}
                                    label={
                                        <Tooltip title="Coming soon.">
                                            <span>Employees Turnover and Churn</span>
                                        </Tooltip>
                                    }
                                    disabled
                                />
                            </Tabs>
                            {currentTab !== OVERVIEW_COMPARISON_INSIGHT ? (
                                <Stack direction="row" alignItems="center" position="absolute" right={0} top={6}>
                                    <Stack direction="row" alignItems="baseline">
                                        <Typography variant="body2" fontSize={13} color="textSecondary">
                                            Number of active filters
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color={totalFilters === 0 ? "textSecondary" : "secondary"}
                                            ml={0.5}
                                            mr={0.5}
                                        >
                                            {totalFilters}
                                        </Typography>
                                    </Stack>
                                    <IconButton size="medium" onClick={onFilterClicked} disabled={isLoading}>
                                        <TuneIcon color={totalFilters === 0 ? "disabled" : "secondary"} />
                                    </IconButton>
                                </Stack>
                            ) : null}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
}
