import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Section1 from "./Sections/Section1";
import Section2 from "./Sections/Section2";
import Section3 from "./Sections/Section3";
import OsterusLoader from "../../../../../../Loader";
import FilterDrawer from "../../../FilterDrawer";
import { DEMOGRAPHICS_COMPARISON_INSIGHT } from "../../viewConstants";

const useStyles = makeStyles(() => ({
    loader: {
        position: "absolute",
        top: "50%",
        left: "47%",
        margin: "auto",
    },
    sectionContainer: {
        padding: "0 16px",
        margin: "auto",
    },
    blur: {
        filter: "blur(4px)",
        WebkitFilter: "blur(4px)",
        pointerEvents: "none",
    },
}));

export function DemographicsView({
    insightsData,
    stacks,
    StackSelectComponent,
    HeaderComponent,
    headerProps,
    isLoading,
}) {
    const [canRenderCharts, setCanRenderCharts] = useState(false);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => setTimeout(() => setCanRenderCharts(true), 1), []);

    return (
        <>
            {StackSelectComponent}
            <HeaderComponent
                viewType={DEMOGRAPHICS_COMPARISON_INSIGHT}
                isSingle={false}
                selectedStacks={stacks}
                onFilterClicked={() => setFilterDrawerOpen(true)}
                isLoading={isLoading}
                {...headerProps}
            />
            <FilterDrawer
                anchorElementId="stack-select"
                open={filterDrawerOpen}
                stacks={stacks}
                view={DEMOGRAPHICS_COMPARISON_INSIGHT}
                onClose={() => setFilterDrawerOpen(false)}
                viewType={DEMOGRAPHICS_COMPARISON_INSIGHT}
            />
            <div className={`${classes.sectionContainer} ${isLoading ? classes.blur : ""}`} translate="no">
                {canRenderCharts && (
                    <>
                        <Section1 data={insightsData.section1} stacks={stacks} />
                        <Section2 data={insightsData.section2} stacks={stacks} />
                        <Section3 data={insightsData.section3} stacks={stacks} />
                    </>
                )}
            </div>
            {isLoading && (
                <Box className={classes.loader}>
                    <OsterusLoader />
                </Box>
            )}
        </>
    );
}
