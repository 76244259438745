import React, { useState, useEffect } from "react";
import { Grid, Paper, Stack, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MapChart from "../../../Charts/MapChart";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";
import ChartLegend from "../../../Charts/ChartLegend";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 0,
        height: 553,
    },
    paper: {
        width: "100%",
        height: "100%",
    },
    title: {
        cursor: "pointer",
    },
}));

const percentageOfEmployeeLocationTitle = {
    position: "relative",
    marginTop: -30,
    left: 36,
    top: 40,
    fontSize: 16,
};

export default function Section3({ data, stacks }) {
    const classes = useStyles();
    const [formatedData, setFormatedData] = useState([
        [
            {
                title: "Bard College Berlin",
                place: "Berlin, Germany",
                city: "Berlin, DE",
                percent: 44,
                series: 0,
                lat: 52.52000659999999,
                long: 13.404954,
            },
            {
                title: "Arts et Métiers ParisTech",
                place: "Paris, France",
                city: "Paris, FR",
                percent: 31,
                series: 0,
                lat: 48.856614,
                long: 2.3522219,
            },
            {
                title: "Pontifícia Universidade Católica do Rio de Janeiro",
                place: "Rio de Janeiro, Brazil",
                city: "Rio de Janeiro, BR",
                percent: 12,
                series: 0,
                lat: -22.9068467,
                long: -43.1728965,
            },
        ],
        [
            {
                title: "New York Institute of Technology",
                place: "Old Westbury, United States",
                city: "Old Westbury, US",
                percent: 40,
                series: 1,
                lat: 40.7887113,
                long: -73.5995717,
            },
            {
                title: "University of Cape Town",
                place: "Cape Town, South Africa",
                city: "Cape Town, ZA",
                percent: 36,
                series: 1,
                lat: -33.9248685,
                long: 18.4240553,
            },
            {
                title: "Georgia Institute of Technology",
                place: "Atlanta, United States",
                city: "Atlanta, US",
                cityId: 1840004026,
                percent: 4.37,
                lat: 45.0017,
                long: -84.1536,
            },
        ],
    ]);

    const percentageOfEmployeesLocationChangeAfterUniversityPerCity = [
        {
            name: stacks[0]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCity.stack1.series.data,
        },
        {
            name: stacks[1]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCity.stack2.series.data,
        },
    ];

    const percentageOfEmployeesLocationChangeAfterUniversityPerCountry = [
        {
            name: stacks[0]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCountry.stack1.series.data,
        },
        {
            name: stacks[1]?.stack?.name,
            data: data.percentageOfEmployeesLocationChangeAfterUniversityPerCountry.stack2.series.data,
        },
    ];

    const fetchData = () => {
        if (data && data.topEmployeesLocations) {
            const newData = [
                data.topEmployeesLocations.stack1
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 0, stackname: stacks[0].stack.name, title: item.place })),
                data.topEmployeesLocations.stack2
                    .slice(0, 30)
                    .map((item) => ({ ...item, series: 1, stackname: stacks[1].stack.name, title: item.place })),
            ];
            const formattedLong = newData.map((item) => item.map((el) => ({ ...el, long: el.lng })));
            setFormatedData(formattedLong);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data]);

    return (
        <Grid container className={classes.root} spacing={1.25}>
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height="100%" position="relative">
                        <MapChart
                            variant="anychart"
                            id="TestAnychart"
                            data={formatedData}
                            stacks={stacks}
                            actionsOffset={{ y: -95, x: 60 }}
                            title="Employee Locations"
                        />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height={70} pl={1} pt={1}>
                        <Tooltip title="Percentage of Employees Location Change after University">
                            <Typography variant="h5" color="textSecondary" className={classes.title}>
                                Employees Location Change after University
                            </Typography>
                        </Tooltip>
                        <Stack mt={0.5}>
                            <ChartLegend
                                legend={[
                                    { name: stacks[0].stack.name, color: "#AFBFF2" },
                                    { name: stacks[1].stack.name, color: "#A3E0C1" },
                                ]}
                            />
                        </Stack>
                    </Stack>
                    <Stack width="100%" height={480} pb={2}>
                        <Stack width="100%" height="100%" flex={1}>
                            <StandardVerticalBarChart
                                variant="normalValue"
                                title="Country"
                                shortTitle="Country"
                                categories={
                                    data.percentageOfEmployeesLocationChangeAfterUniversityPerCountry.categories
                                }
                                series={percentageOfEmployeesLocationChangeAfterUniversityPerCountry}
                                colors={{ defaultColors: ["#afbff2", "#A3E0C1"] }}
                                color={null}
                                elevation={0}
                                titleTypographyStyle={percentageOfEmployeeLocationTitle}
                                showLegend={Boolean(false)}
                                actionsOffset={{ x: 45, y: -15 }}
                            />
                        </Stack>
                        <Stack width="100%" height="100%" flex={1}>
                            <StandardVerticalBarChart
                                variant="normalValue"
                                title="City"
                                shortTitle="City"
                                categories={data.percentageOfEmployeesLocationChangeAfterUniversityPerCity.categories}
                                series={percentageOfEmployeesLocationChangeAfterUniversityPerCity}
                                colors={{ defaultColors: ["#afbff2", "#A3E0C1"] }}
                                color={null}
                                elevation={0}
                                titleTypographyStyle={percentageOfEmployeeLocationTitle}
                                showLegend={Boolean(false)}
                                actionsOffset={{ x: 45, y: -15 }}
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
