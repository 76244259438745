import { axiosInstance } from "../../../config/api/Axios";

export const getOrganizationsApi = (params) => axiosInstance.get("/organizations", { params: { ...params } });

export const saveOrganizationApi = (organization) => axiosInstance.post(`/organizations`, organization);

export const saveAdminOrganizationApi = (organization) => axiosInstance.post(`/admin/organizations`, organization);

export const updateOrganizationApi = (id, organization) => axiosInstance.put(`/organizations/${id}`, organization);

export const deleteStacksApi = (id, params) =>
    axiosInstance.patch(`/admin/organizations/${id}/stacks`, {
        ids: params,
    });

export const editOrganizationUsers = (id, params) => axiosInstance.patch(`admin/organizations/${id}/users`, params);
