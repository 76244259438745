import { axiosInstance } from "../../../config/api/Axios";

export const getCompanyApi = (id) => axiosInstance.get(`/utils/companies/${id}`);
export const getCompaniesApi = (params) => axiosInstance.get("/utils/companies", { params });
export const getTagsApi = (params) => axiosInstance.get("/utils/tags", { params });
export const createTagApi = (name, organization_id) => axiosInstance.post("/utils/tags", { name, organization_id });
export const downloadReport = (params) => axiosInstance.post(`/utils/report/`, params);
export const isReportFinished = (requestId) =>
    axiosInstance.get(`/utils/report/${requestId}`, { responseType: "blob", timeout: 2000 });
export const getTableColumns = (tableId) => axiosInstance.get(`/utils/column-arrangements/${tableId}`);
export const updateTableColumns = (tableId, columns) =>
    axiosInstance.put(`/utils/column-arrangements/${tableId}`, columns);
