import React from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ComparisonBarChart from "../../../MixedCharts/ComparisonBarChart";
import PieChart from "../../../Charts/PieChart";
import ChartLegend from "../../../Charts/ChartLegend";

const useStyles = makeStyles(() => ({
    root: {
        height: 326,
    },
    paper: {
        width: "100%",
        height: "100%",
    },
    overallGenderRationTitle: {
        paddingLeft: 11,
        paddingTop: 14,
    },
}));

export default function Section1({ data, stacks }) {
    const classes = useStyles();

    const comparisonChartStack1 = {
        series: data.topCitiesPerCompany.comparisonChart.stack1.series,
        categories: data.topCitiesPerCompany.comparisonChart.categories1,
    };

    const comparisonChartStack2 = {
        series: data.topCitiesPerCompany.comparisonChart.stack2.series,
        categories: data.topCitiesPerCompany.comparisonChart.categories2,
    };

    return (
        <Grid container className={classes.root} spacing={1}>
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height="100%">
                        <ComparisonBarChart
                            elevation={0}
                            height="100%"
                            barColors={["#5172E8", "#4BC186"]}
                            distributed
                            barHeight="56%"
                            stackHeight={205}
                            stacks={stacks}
                            chartInfo={{ title: "Top Cities", shortTitle: "Top Cities" }}
                            stack1={comparisonChartStack1}
                            stack2={comparisonChartStack2}
                            itemsPerPage={5}
                            actionsOffset={{ x: 60, y: -55 }}
                            customTitleMargin={[3, 10]}
                            containerStyles={{ paddingRight: 24 }}
                            paddingBottomPerItem={29.47}
                        />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <Stack width="100%" height={70} pl={1} pt={1}>
                        <Typography variant="h5" color="textSecondary">
                            Overall Gender Ratio
                        </Typography>
                        <Stack mt={0.5}>
                            <ChartLegend
                                legend={[
                                    { name: "Male", color: "#5172E8" },
                                    { name: "Female", color: "#BAA2E1" },
                                    { name: "Unknown", color: "#ececec" },
                                ]}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" margin="auto" width="80%" height={210}>
                        <Stack flex={1}>
                            <PieChart
                                series={[...data.genderDistributionCurrent.stack1.series.data].reverse()}
                                labels={[...data.genderDistributionCurrent.categories].reverse()}
                                colors={["#5172E8", "#BAA2E1", "#ECECEC"].reverse()}
                                stackname={stacks[0].stack.name}
                            />
                        </Stack>
                        <Stack flex={1}>
                            <PieChart
                                series={[...data.genderDistributionCurrent.stack2.series.data].reverse()}
                                labels={[...data.genderDistributionCurrent.categories].reverse()}
                                colors={["#5172E8", "#BAA2E1", "#ECECEC"].reverse()}
                                stackname={stacks[1].stack.name}
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
