import { createSlice } from "@reduxjs/toolkit";
import { getUniversities } from "../../actions/intelligence/Universities";

const SLICE_NAME = "universities";

const initialState = {
    data: null,
    totalRecords: 0,
    loading: false,
    filter: {
        search: "",
        countries: [],
        cities: [],
        start: 1,
        limit: 25,
        internal_rating: [],
    },
};

export const universitiesSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearState: (state) => {
            Object.assign(state, initialState);
        },
        clearData: (state) => {
            state.data = initialState.data;
        },
        setFilter: (state, { payload }) => {
            state.filter = { ...initialState.filter, ...payload };
            if (!payload.start) {
                state.filter.start = 1;
            }
        },
        updateFilter: (state, { payload }) => {
            state.filter = { ...state.filter, ...payload };
            const countries = state.filter.countries.map((country) => country.name);
            const modifiedCities = [...state.filter.cities].filter((city) => countries.includes(city.country.name));
            state.filter.cities = modifiedCities;
            if (!payload.start) {
                state.filter.start = 1;
            }
        },
        clearFilter: (state) => {
            state.filter = initialState.filter;
        },
    },
    extraReducers: {
        [getUniversities.pending]: (state) => {
            state.loading = true;
        },
        [getUniversities.fulfilled]: (state, { payload }) => {
            state.data = payload.data.records;
            state.totalRecords = payload.data.totalRecords;
            state.loading = false;
        },
    },
});

export const { clearState, clearData, updateFilter, setFilter, clearFilter } = universitiesSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const universitiesSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    data: (rootState) => {
        const appState = getAppState(rootState);
        return appState.data;
    },
    totalRecords: (rootState) => {
        const appState = getAppState(rootState);
        return appState.totalRecords;
    },
    filter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.filter;
    },
};
