import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChartContainer } from "../../../Charts/ChartContainer";
import ChartLegend from "../../../Charts/ChartLegend";

const useStyles = makeStyles(() => ({
    yearsLabel: {
        position: "absolute",
        bottom: 7,
        right: 8,
    },
}));

function generateChartOptions({ series, categories, barColors, lineColors, markerColors, maxValue }) {
    const peaksAndValleys = (() => {
        const points = [];
        const markerSize = categories.length < 20 ? 7 : 5;
        series.forEach((seriesItem, seriesIndex) => {
            const data = seriesItem.data;
            for (let i = 1; i < data.length - 1; i += 1) {
                const currentValue = data[i];
                if (data[i - 1] < currentValue && data[i + 1] < currentValue) {
                    points.push({
                        x: i,
                        y: currentValue,
                        marker: { size: markerSize, fillColor: markerColors[seriesIndex], strokeColor: "" },
                    });
                } else if (data[i - 1] > currentValue && data[i + 1] > currentValue) {
                    points.push({
                        x: i,
                        y: currentValue,
                        marker: { size: markerSize, fillColor: markerColors[seriesIndex], strokeColor: "" },
                    });
                }
            }
        });

        return points;
    })();

    const options = {
        chart: {
            type: "bar",
            // Make bars stacked. This is a workaround for lack of support for bars that overlap,
            // aka. showing smallest to greatest, all on top of each other.
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                columnWidth: "98%",
                borderRadius: [2],
            },
        },
        markers: {
            size: 0,
            // Bug workaround: Even though size is set to 0, marker is still visible for first data point
            // after a null value(for [1, 2, null, 5], it will be visible on 5).
            // It doesn't make any sense, but "showNullDataPoints: false" fixes it.
            showNullDataPoints: false,
        },
        stroke: {
            width: [0, 3.5],
            curve: "straight",
            colors: lineColors,
        },
        xaxis: {
            categories,
            axisBorder: {
                show: true,
                height: 2,
                color: "#918f8f",
            },
            axisTicks: {
                show: false,
            },
            labels: {
                rotate: 0,
                rotateAlways: false,
                formatter: (val) => (val < 20 ? `${val + 1}` : `${val}+`),
            },
        },
        yaxis: {
            // Because we can have negative values(due to workarounds), we need to set minimum y-axis value
            // to 0 in order to force apexcharts not to extend visible yaxis values to negative ones.
            // Also it prevents weird behaviour where second line goes negative on indices where first line
            // has values that are null.
            min: 0,
            max: Math.min(maxValue * 1.1, 100),
            labels: {
                formatter: (val) => `${val.toFixed(0)}%`,
            },
        },
        colors: barColors, // Used by tooltips
        tooltip: {
            shared: true,
            intersect: false,
            // Only show tooltips on original series
            enabledOnSeries: [0],
            // Show data from original series, not formatted ones.
            y: {
                // formatter: (val, { seriesIndex, dataPointIndex }) => {
                //     if (seriesIndex === 1) {
                //         return `${series[0].data[dataPointIndex]}%`;
                //     }
                //     return `${series[1].data[dataPointIndex]}%`;
                // },
                formatter: (val) => `${val}%`,
            },
            x: {
                formatter: (val, { dataPointIndex }) =>
                    dataPointIndex === categories.length - 1 ? `${val}+ years` : `${val} - ${val + 1} years`,
            },
        },
        fill: {
            colors: barColors,
            opacity: 1,
        },
        legend: {
            show: false,
        },
        annotations: {
            points: peaksAndValleys,
        },
    };

    return options;
}

export default function SingleSeriesHistogramLineChart({
    series,
    categories,
    title,
    subscript,
    color = null,
    barColors,
    lineColors,
    markerColors,
    maxValue,
    showLegend = true,
}) {
    const classes = useStyles();
    const chartInfo = { title, shortTitle: title, description: "", subscript };

    const chartOptions = useMemo(
        () =>
            generateChartOptions({
                series,
                categories,
                barColors,
                lineColors,
                markerColors,
                maxValue,
            }),
        [series, categories, barColors, lineColors, markerColors, maxValue],
    );

    const formattedSeries = useMemo(
        () => [
            { ...series[0], type: "column", data: series[0].data },
            { name: "", type: "line", data: series[0].data.map((item) => item || null) },
        ],
        [series],
    );

    return (
        <ChartContainer color={color} chartInfo={chartInfo} showActions={false}>
            {showLegend && (
                <Stack>
                    <ChartLegend legend={[{ name: series[0].name, color: barColors[1] }]} />
                </Stack>
            )}
            <Stack height={335} position="relative">
                <Chart type="bar" options={chartOptions} series={formattedSeries} width="100%" height="94%" />
                <Stack width="100%" alignItems="flex-end">
                    <Typography variant="caption" color="textSecondary" className={classes.yearsLabel}>
                        years
                    </Typography>
                </Stack>
            </Stack>
        </ChartContainer>
    );
}
