import { createSlice } from "@reduxjs/toolkit";
import { getDefaultCities } from "../../actions/intelligence";

const SLICE_NAME = "intelligence";

const initialState = {
    loading: false,
    defaultCities: null,
};

export const intelligenceSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: {
        [getDefaultCities.pending]: (state) => {
            state.loading = true;
        },
        [getDefaultCities.fulfilled]: (state, { payload }) => {
            state.defaultCities = payload.records;
            state.loading = false;
        },
    },
});

const getAppState = (state) => state[SLICE_NAME];

export const intelligenceSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    defaultCities: (rootState) => {
        const appState = getAppState(rootState);
        return appState.defaultCities;
    },
};
