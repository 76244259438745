import React from "react";
import { Typography, Avatar, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useSelector } from "react-redux";
import { userSliceSelectors } from "../../../../slices/User/userSlice";
import { formatLastActive, formatOrganizationType } from "../utils";

const useStyles = makeStyles((theme) => ({
    basicInfoRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
    },
    logoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    companyInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    website: {
        cursor: "pointer",
    },
    logo: {
        height: 80,
        width: 80,
    },
    companyIcon: {
        fontSize: 40,
    },
    logoColumn: {
        display: "flex",
        justifyContent: "flex-start",
        gap: theme.spacing(2),
    },
    additionalInfoRow: {
        marginBottom: theme.spacing(3.5),
        display: "flex",
        justifyContent: "space-between",
    },
    infoItem: {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    singleItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    link: {
        textDecoration: "none",
    },
    linkedin: {
        cursor: "pointer",
    },
    fullWidthField: {
        width: "100%",
    },
    additionalLocationContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2.5),
        display: "flex",
    },
    dividerTop: {
        marginBottom: theme.spacing(3),
    },
    dividerBottom: {
        marginBottom: theme.spacing(2),
    },
}));

export default function CompanyInfo() {
    const classes = useStyles();
    const selectedUser = useSelector(userSliceSelectors.selectedUser);
    const { logo, full_name, display_name, website, linkedin, industry, size, type, updated_at, locations } =
        selectedUser?.organizations?.length > 0 ? selectedUser.organizations[0] : {};

    return (
        <>
            <Divider className={classes.dividerTop} />
            <div className={classes.basicInfoRow}>
                <div className={classes.logoColumn}>
                    <div className={classes.logoContainer}>
                        {logo ? (
                            <Avatar
                                src={`${process.env.REACT_APP_API_BASE_URL}/media/picture/${logo}`}
                                variant="square"
                                className={classes.logo}
                            />
                        ) : (
                            <Avatar variant="square" className={classes.logo}>
                                <ApartmentIcon className={classes.companyIcon} color="primary" />
                            </Avatar>
                        )}
                    </div>
                    <div className={classes.companyInfo}>
                        <div translate="no">
                            <Typography color="textPrimary" variant="body1">
                                {display_name || "--"}
                            </Typography>
                        </div>
                        <div translate="no">
                            <Typography color="textSecondary" variant="body2">
                                {full_name || "--"}
                            </Typography>
                        </div>
                        <div>
                            {website ? (
                                <a className={classes.link} href={`//${website}`} target="_blank" rel="noreferrer">
                                    <Typography className={classes.website} color="secondary.light" variant="body3">
                                        {website}
                                    </Typography>
                                </a>
                            ) : (
                                <Typography variant="body2">--</Typography>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Divider className={classes.dividerBottom} />
            <div className={classes.additionalInfoRow}>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {size || "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Size of company
                        </Typography>
                    </div>
                </div>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {formatLastActive(updated_at)}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Last Active
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalInfoRow}>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {industry || "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Industry
                        </Typography>
                    </div>
                </div>
                <div className={classes.infoItem}>
                    <div>
                        <Typography color="textPrimary" variant="body2">
                            {formatOrganizationType(type)}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            Type
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalInfoRow}>
                <div className={classes.singleItem}>
                    <div translate="no">
                        <Typography color="textPrimary" variant="body2">
                            {locations[0] && locations[0]?.city
                                ? `${locations[0].city.name}, ${locations[0].city.country.name}`
                                : "--"}
                        </Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            HQ Location
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.additionalLocationContainer}>
                <Typography variant="body2" color="disabled">
                    + add location
                </Typography>
            </div>
            <div className={classes.additionalInfoRow}>
                {linkedin ? (
                    <a className={classes.link} href={`//${linkedin}`} target="_blank" rel="noreferrer">
                        <Typography className={classes.linkedin} color="secondary.light" variant="body4">
                            {linkedin}
                        </Typography>
                    </a>
                ) : (
                    <Typography variant="body2">--</Typography>
                )}
            </div>
        </>
    );
}
