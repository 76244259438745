import React, { useEffect } from "react";
import "./Osterus.css";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "./routes";
import { getMe } from "./services/api/Public/Login/loginActions";
import { userSliceSelectors } from "./slices/User/userSlice";

function Osterus() {
    const dispatch = useDispatch();
    // Check whether user exists in state, otherwise load it
    const { id } = useSelector(userSliceSelectors.defaultUserFields);
    const loading = useSelector(userSliceSelectors.loading);
    const token = localStorage.getItem("token");

    useEffect(() => {
        if (!id && !loading && token !== null) {
            dispatch(getMe());
        }
    }, [id, loading, token]);

    return <Routes />;
}

export default Osterus;
