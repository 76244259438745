import React, { useMemo, useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { ChartContainer } from "./ChartContainer";
import StackedBarCategories from "./StackedBarCategories";
import StackedBar from "./StackedBar";
import { stackInsightsSliceSelector } from "../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

export default function StackedBarWithCategories({
    stacks,
    categories,
    series,
    showActions,
    tickAmount = 4,
    padding = [],
    itemsPerPage = 15,
    containerStyle,
    categoriesStyle,
    barColors,
    max,
    noXaxis = false,
    title,
    titleStyles,
    titleTypographyStyle,
    chartStyle,
    barHeight = "54.5%",
    gridXaxisLines,
    isDistributed = true,
    enableCustomTooltip,
    tooltips,
    shared,
    actionsOffset = { y: -35 },
    categoriesFlex = 4,
    stackedBarFlex = 8,
    inPercentage,
    paddingBottomPerItem = 31.23,
    xAxisOffsetPerItem = 15.6,
    v2 = false,
    noFormats,
}) {
    const [page, setPage] = useState(0);
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [newCategories, newSeries] = useMemo(() => {
        if (!itemsPerPage) {
            return [categories, series];
        }
        const tempCategories = [...categories].slice(page * itemsPerPage, (page + 1) * itemsPerPage);
        const tempSeries = series.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        return [tempCategories, tempSeries];
    });

    const maxValue = useMemo(() => {
        if (max) return max;

        let maxVal = 0;
        for (let i = 0; i < categories.length; i += 1) {
            const rowSum = series.reduce((prev, cur) => prev + cur.data[i], 0);
            maxVal = Math.max(maxVal, rowSum);
        }

        return maxVal;
    }, [series]);

    const missingItems = itemsPerPage - newSeries[0].data.length;

    useEffect(() => {
        setPage(0);
    }, [stacks, split, show]);

    return (
        <ChartContainer
            showTitle
            title={title}
            titleTypographyStyle={titleTypographyStyle}
            variant="plain"
            nextPage={() => setPage(page + 1)}
            previousPage={() => setPage(page - 1)}
            page={page}
            lastPage={parseInt(Math.ceil(categories.length / itemsPerPage))}
            titleStyles={titleStyles}
            showActions={showActions}
            actionsOffset={actionsOffset}
        >
            <Stack direction="row" mt={4.25} height="100%" {...chartStyle}>
                <Stack flex={categoriesFlex} alignItems="flex-end">
                    <StackedBarCategories
                        categories={newCategories}
                        containerStyle={containerStyle}
                        categoriesStyle={categoriesStyle}
                        tooltips={tooltips}
                        enableCustomTooltip={enableCustomTooltip}
                    />
                </Stack>
                <Stack flex={stackedBarFlex} height="100%">
                    <StackedBar
                        series={newSeries}
                        categories={newCategories}
                        stackname={stacks[0]?.stack?.name}
                        barColors={barColors}
                        padding={
                            padding[0]
                                ? padding[0]
                                : { right: 35, bottom: missingItems * paddingBottomPerItem, left: 0, top: -35 }
                        }
                        xAxisOffset={missingItems * xAxisOffsetPerItem}
                        tickAmount={tickAmount}
                        barHeight={barHeight}
                        max={maxValue}
                        noXaxis={noXaxis}
                        gridXaxisLines={gridXaxisLines}
                        isDistributed={isDistributed}
                        shared={shared}
                        inPercentage={inPercentage}
                        v2={v2}
                        noFormats={noFormats}
                    />
                </Stack>
            </Stack>
        </ChartContainer>
    );
}
