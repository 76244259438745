import React from "react";
import { Dialog, DialogContent, DialogActions, Typography, Button, DialogTitle, Divider } from "@mui/material";

export default function NoRequestsAvailableModal({ open, handleClose, message, title }) {
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <Divider />
            <DialogContent>
                <Typography varaint="body2">{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
