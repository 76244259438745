import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";

import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SecurityIcon from "@mui/icons-material/Security";
import TrafficIcon from "@mui/icons-material/Traffic";
import SchoolIcon from "@mui/icons-material/School";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import GroupIcon from "@mui/icons-material/Group";
import WifiIcon from "@mui/icons-material/Wifi";
import LanguageIcon from "@mui/icons-material/Language";
import DomainIcon from "@mui/icons-material/Domain";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { qualityOfLifeSelectors } from "../../../../slices/Intelligence/QualityOfLifeSlice";
import { CityInfo } from "../../components/CityInfo";
import { MetricSection } from "../../components/MetricSection";
import { MetricItem } from "../../components/MetricItem";

const metricNameToIcon = (metricName, props) => {
    switch (metricName) {
        case "Family Score":
            return <SupervisorAccountIcon {...props} />;
        case "Lack of Crime":
            return <SecurityIcon {...props} />;
        case "Safety":
            return <SecurityIcon {...props} />;
        case "Traffic Safety":
            return <TrafficIcon {...props} />;
        case "Education Level":
            return <SchoolIcon {...props} />;
        case "Hospitals":
            return <LocalHospitalIcon {...props} />;
        case "Cost":
            return <AttachMoneyIcon {...props} />;

        case "Racial Tolerance":
            return <EmojiEventsIcon {...props} />;
        case "Female Friendly":
            return <EmojiEmotionsIcon {...props} />;
        case "LGBTQ+ Friendly":
            return <EmojiEmotionsIcon {...props} />;
        case "Friendly to Foreigners":
            return <EmojiEmotionsIcon {...props} />;
        case "English Speaking":
            return <EmojiEventsIcon {...props} />;
        case "Freedom of Speech":
            return <RecordVoiceOverIcon {...props} />;
        case "Peace":
            return <EmojiEventsIcon {...props} />;

        case "Walkability":
            return <DirectionsWalkIcon {...props} />;
        case "People Density":
            return <GroupIcon {...props} />;
        case "Free WiFi in City":
            return <WifiIcon {...props} />;
        case "Internet":
            return <LanguageIcon {...props} />;
        case "Places to Work From":
            return <DomainIcon {...props} />;
        case "A/C or Heating":
            return <Brightness7Icon {...props} />;

        default:
            return null;
    }
};

const formatValue = (name, value, score) => {
    switch (name) {
        case "Family Score":
        case "Lack of Crime":
        case "Safety":
        case "Traffic Safety":
        case "Education Level":
        case "Hospitals":
        case "Racial Tolerance":
        case "Female Friendly":
        case "LGBTQ+ Friendly":
        case "Friendly to Foreigners":
        case "English Speaking":
        case "Freedom of Speech":
        case "Peace":
        case "Walkability":
        case "Free WiFi in City":
        case "Places to Work From":
        case "A/C or Heating":
            return `${value} (${score}/5)`;

        default:
            return value;
    }
};

const useStyles = makeStyles((theme) => ({
    dataContainer: {
        flex: 2,
        maxWidth: 660,
        overflow: "auto",
        paddingBottom: 10,
    },
    equalScore: {
        color: theme.palette.primary[100],
    },
    higherScore: {
        color: theme.palette.primary[400],
    },
}));

export default function LivingStandard({ data }) {
    const currentCity = useSelector(qualityOfLifeSelectors.currentCity);
    const comparisonCity = useSelector(qualityOfLifeSelectors.comparisonCity);
    const currentCityGeneralScore = useSelector(qualityOfLifeSelectors.currentCityGeneralScore);
    const comparisonCityGeneralScore = useSelector(qualityOfLifeSelectors.comparisonCityGeneralScore);
    const theme = useTheme();
    const classes = useStyles();

    const getColorFromScore = (score) =>
        score <= 2 ? theme.palette.alert.main : score <= 3 ? theme.palette.warning[700] : theme.palette.success.main;

    const calculateBarPercentage = (score) => (score ? (100 / 5) * score : 0);

    const sections = useMemo(() => {
        if (!data) return;
        return Object.keys(data).map((sectionName) => {
            const section = data[sectionName];
            return {
                name: sectionName,
                metrics: section.map((metric) => {
                    const cur = metric.currentCity;
                    const comp = metric.comparisonCity;
                    return (
                        <MetricItem
                            key={metric.name}
                            name={metric.name}
                            values={[
                                cur ? formatValue(metric.name, cur.value, cur.score) : null,
                                comp ? formatValue(metric.name, comp.value, comp.score) : null,
                            ]}
                            barPercentages={[calculateBarPercentage(cur?.score), calculateBarPercentage(comp?.score)]}
                            indicators={[
                                cur &&
                                    comp &&
                                    (cur.score > comp.score
                                        ? metricNameToIcon(metric.name, { className: classes.higherScore })
                                        : cur.score === comp.score
                                        ? metricNameToIcon(metric.name, { className: classes.equalScore })
                                        : null),
                                cur &&
                                    comp &&
                                    (cur.score < comp.score
                                        ? metricNameToIcon(metric.name, { className: classes.higherScore })
                                        : cur.score === comp.score
                                        ? metricNameToIcon(metric.name, { className: classes.equalScore })
                                        : null),
                            ]}
                            colors={[cur && getColorFromScore(cur.score), comp && getColorFromScore(comp.score)]}
                            variant="bar"
                        />
                    );
                }),
            };
        });
    }, [data]);

    return (
        <Stack direction="row" alignItems="flex-start" justifyContent="center" width="100%" height="100%" pb={4.5}>
            <CityInfo
                image={currentCity.image}
                title="Overall Score"
                subtitle={currentCityGeneralScore.rank}
                value={currentCityGeneralScore.score}
                name={currentCity.name}
                iso2Code={currentCity?.country?.iso2}
            />
            <div className={classes.dataContainer}>
                {sections.map((section) => (
                    <MetricSection key={section.name} name={section.name}>
                        {section.metrics}
                    </MetricSection>
                ))}
            </div>
            <CityInfo
                image={comparisonCity.image}
                title="Overall Score"
                subtitle={comparisonCityGeneralScore.rank}
                value={comparisonCityGeneralScore.score}
                name={comparisonCity.name}
                iso2Code={comparisonCity?.country?.iso2}
            />
        </Stack>
    );
}
