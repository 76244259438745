import { createSlice } from "@reduxjs/toolkit";
import { requestCompanyData } from "../../actions/Company/Data/request";
import { getAllByFilter, getSelectedStacks } from "../../actions/theLab/stacks";

const SLICE_NAME = "stacks";

const initialState = {
    stackList: null,
    loading: true,
    switchLoading: false,
    selectedStacks: [],
    filter: {
        q: {
            name: "",
        },
        tags: [],
        start: 1,
        limit: 25,
        sort: "",
        direction: "",
    },
    searchValue: null,
    snackbarOpen: false,
    snackbarRequestOpen: false,
};

export const stackSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setSelectedStacks(state, { payload }) {
            state.selectedStacks = payload;
        },
        switchSelectedStacks(state) {
            state.switchLoading = true;
            const selectedStackPrimary = state.selectedStacks[0];
            const selectedStackSecondary = state.selectedStacks[1];
            state.selectedStacks = [selectedStackSecondary, selectedStackPrimary];
        },
        setSnackbarOpen(state, action) {
            state.snackbarOpen = action.payload;
        },
        updateFilter(state, action) {
            state.filter = action.payload;
        },
        clearFilter(state) {
            state.filter = { ...state.filter, tags: [] };
        },
        setStackList(state, action) {
            state.stackList = action.payload;
        },
        setSearchValue(state, action) {
            state.filter.start = 1;
            state.filter.q.name = action.payload;
        },
        setSnackbarRequestOpen(state, action) {
            state.snackbarRequestOpen = action.payload;
        },
        clearState(state) {
            Object.assign(state, initialState);
        },
        closeSwitchLoading(state) {
            state.switchLoading = false;
        },
    },
    extraReducers: {
        [getAllByFilter.pending]: (state) => {
            state.loading = true;
        },
        [getAllByFilter.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.stackList = payload;
        },
        [getSelectedStacks.fulfilled]: (state, action) => {
            const { payload } = action;
            if (payload.records.length === 1) {
                state.selectedStacks = payload.records;
            } else if (payload.records.length > 1) {
                let modifiedStacks = payload.records;
                if (Number(action.meta.arg.id[0]) !== modifiedStacks[0].id) {
                    modifiedStacks = [modifiedStacks[1], modifiedStacks[0]];
                }
                state.selectedStacks = modifiedStacks;
            }
        },
        [requestCompanyData.fulfilled]: (state) => {
            state.snackbarRequestOpen = true;
        },
    },
});

export const {
    setSelectedStacks,
    setSnackbarOpen,
    clearState,
    updateFilter,
    switchSelectedStacks,
    setStackList,
    setSearchValue,
    setSnackbarRequestOpen,
    closeSwitchLoading,
    clearFilter,
} = stackSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const stackSliceSelectors = {
    stackList: (rootState) => {
        const appState = getAppState(rootState);
        return appState.stackList;
    },
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    switchLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.switchLoading;
    },
    selectedStacks: (rootState) => {
        const appState = getAppState(rootState);
        return appState.selectedStacks;
    },
    snackbarOpen: (rootState) => {
        const appState = getAppState(rootState);
        return appState.snackbarOpen;
    },
    getFilter: (rootState) => {
        const appState = getAppState(rootState);
        return appState.filter;
    },
    snackbarRequestOpen: (rootState) => {
        const appState = getAppState(rootState);
        return appState.snackbarRequestOpen;
    },
};
