import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack, Divider, Paper } from "@mui/material";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";
import MarkersBarWithCategories from "../../../Charts/MarkersBarWithCategories";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors, getMarkersBarHeight } from "../../../utils";

const titleStyles = {
    background: "transparent",
};

const markerCategoriesStyle = {
    marginBottom: 9.7,
    textAlign: "right",
};
const markerContainerStyle = {
    marginTop: 0.1,
    minWidth: 150,
};

export function Section1({ data, stacks }) {
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);

    const barColors1 = useMemo(
        () =>
            data.jobTitles
                ? getColors(
                      data.jobTitles.stack.series[0]?.name
                          ? data.jobTitles.stack.series
                                .map((serie) => serie.name)
                                .filter((serie) => serie.name !== "Total")
                          : [],
                      show,
                      split,
                  )
                : [],
        [data],
    );

    const barColors2 = useMemo(
        () =>
            data.previousCompanies
                ? getColors(
                      data.previousCompanies.stack.series[0]?.name
                          ? data.previousCompanies.stack.series
                                .map((serie) => serie.name)
                                .filter((serie) => serie.name !== "Total")
                          : [],
                      show,
                      split,
                  )
                : [],
        [data],
    );

    const markersData = useMemo(() => {
        const data1 = {
            categories: data.averageWorkExperience.categories,
            series: [
                {
                    data: data.averageWorkExperience.stack.series.data.map((item, index) => ({
                        x: data.averageWorkExperience.categories[index],
                        y: item,
                        goals: !data.averageWorkExperience.benchmark[index]
                            ? undefined
                            : [
                                  {
                                      name: "Benchmark",
                                      value: data.averageWorkExperience.benchmark[index],
                                      strokeWidth: 2,
                                      strokeDashArray: 2,
                                      strokeColor: "#f00",
                                  },
                              ],
                    })),
                },
            ],
        };

        const data2 = {
            categories: data.averageWorkExperiencePerGender.categories,
            series: [
                {
                    data: data.averageWorkExperiencePerGender.stack.series.data.map((item, index) => ({
                        x: data.averageWorkExperiencePerGender.categories[index],
                        y: item,
                        goals: !data.averageWorkExperiencePerGender.benchmark[index]
                            ? undefined
                            : [
                                  {
                                      name: "Benchmark",
                                      value: data.averageWorkExperiencePerGender.benchmark[index],
                                      strokeWidth: 2,
                                      strokeDashArray: 2,
                                      strokeColor: "#f00",
                                  },
                              ],
                    })),
                },
            ],
        };

        const data3 = {
            categories: data.averageWorkExperiencePerDegree.categories,
            series: [
                {
                    data: data.averageWorkExperiencePerDegree.stack.series.data.map((item, index) => ({
                        x: data.averageWorkExperiencePerDegree.categories[index],
                        y: item,
                        goals: !data.averageWorkExperiencePerDegree.benchmark[index]
                            ? undefined
                            : [
                                  {
                                      name: "Benchmark",
                                      value: data.averageWorkExperiencePerDegree.benchmark[index],
                                      strokeWidth: 2,
                                      strokeDashArray: 2,
                                      strokeColor: "#f00",
                                  },
                              ],
                    })),
                },
            ],
        };

        return [data1, data2, data3];
    }, [data, stacks]);

    return (
        <Stack height={494} direction="row" spacing={1.25} mb={1.25}>
            <Stack flex={2} direction="row" spacing={1.25}>
                <Stack flex={1}>
                    <Paper sx={{ height: "100%", pb: 1, pr: 4.5 }}>
                        <StackedBarWithCategories
                            barColors={split === SplitModes.none ? [barColors1[0]] : barColors1}
                            title="Job Titles"
                            titleStyles={titleStyles}
                            titleTypographyStyle={{ paddingLeft: 10, paddingTop: 10 }}
                            stacks={stacks}
                            barHeight="65%"
                            categories={data.jobTitles.categories}
                            series={data.jobTitles.stack.series.filter((serie) => serie.name !== "Total")}
                            containerStyle={{ width: 153, ml: 7 }}
                            categoriesStyle={{ marginBottom: 8.2, textAlign: "right" }}
                            categoriesFlex="initial"
                            actionsOffset={{ y: -50, x: 5 }}
                            isDistributed={false}
                            paddingBottomPerItem={25.42}
                            xAxisOffsetPerItem={12.71}
                        />
                    </Paper>
                </Stack>
                <Stack flex={1}>
                    <Paper sx={{ height: "100%", pb: 1, pr: 4.5 }}>
                        <StackedBarWithCategories
                            barColors={split === SplitModes.none ? [barColors2[0]] : barColors2}
                            title="Top Previous Companies"
                            titleStyles={titleStyles}
                            titleTypographyStyle={{ paddingLeft: 10, paddingTop: 10 }}
                            stacks={stacks}
                            barHeight="52%"
                            categories={data.previousCompanies.categories}
                            series={data.previousCompanies.stack.series.filter((serie) => serie.name !== "Total")}
                            containerStyle={{ marginTop: 0.4, width: 123, ml: 7 }}
                            categoriesStyle={{ marginBottom: 14.5, textAlign: "right" }}
                            categoriesFlex="initial"
                            isDistributed={false}
                            actionsOffset={{ y: -50, x: 5 }}
                            itemsPerPage={12}
                        />
                    </Paper>
                </Stack>
            </Stack>
            <Stack flex={1}>
                <Stack flex={1} height={156}>
                    <MarkersBarWithCategories
                        title="Average Work Experience"
                        stackname={stacks[0].stack.name}
                        categories={markersData[0].categories}
                        series={markersData[0].series}
                        colors={["#5073E8"]}
                        chartStyle={{ pr: 12 }}
                        categoriesStyle={markerCategoriesStyle}
                        containerStyle={markerContainerStyle}
                        chartHeight={getMarkersBarHeight(markersData[0].categories.length, true)}
                        showActions={false}
                    />
                </Stack>
                <Divider />
                <Stack flex={1} height={156}>
                    <MarkersBarWithCategories
                        title="Average Work Experience"
                        subscript="per Gender"
                        stackname={stacks[0].stack.name}
                        categories={markersData[1].categories}
                        series={markersData[1].series}
                        colors={["#5073E8", "#BBA3E1", "#ECECEC"]}
                        chartStyle={{ pr: 12 }}
                        categoriesStyle={markerCategoriesStyle}
                        containerStyle={markerContainerStyle}
                        chartHeight={getMarkersBarHeight(markersData[1].categories.length, true)}
                        showActions={false}
                    />
                </Stack>
                <Divider />
                <Stack flex={1} height={182}>
                    <MarkersBarWithCategories
                        title="Average Work Experience"
                        subscript="per Degree"
                        stackname={stacks[0].stack.name}
                        categories={markersData[2].categories}
                        series={markersData[2].series}
                        colors={["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"]}
                        chartStyle={{ pr: 12 }}
                        categoriesStyle={markerCategoriesStyle}
                        containerStyle={markerContainerStyle}
                        chartHeight={getMarkersBarHeight(markersData[2].categories.length, true)}
                        showActions={false}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}
