import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { Grid, Stack, Divider, Paper, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import EditIcon from "@mui/icons-material/Edit";
import EditModal from "./components/modals/EditModal";
import UserListModal from "./components/modals/UserListModal";
import Table from "../../Table/Table";
import Filter from "./components/Filter";
import Pagination from "../../Pagination";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import { adminSliceSelectors, setActiveStack, setOrganizationsFilter } from "../../../slices/Admin/adminSlice";
import { getOrganizations } from "../../../actions/Admin/Organizations";
import OsterusLoader from "../../Loader";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { COLUMN_TYPES } from "../../Table/columnTypes";
import { renderEmail, renderHoverableText, renderNoteText } from "../../Table/CellRenderers";
import CreateModal from "./components/modals/CreateModal";
import RequestedStacksModal from "./components/modals/RequestedStacksModal";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Organizations",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Organizations",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function OrganizationsComponent() {
    const limit = useSelector(adminSliceSelectors.organizationsFilter).limit;
    const totalRecords = useSelector(adminSliceSelectors.organizationsTotalRecords);
    const loading = useSelector(adminSliceSelectors.organizationsLoading);
    const data = useSelector(adminSliceSelectors.organizationsData);
    const [dataSlice, setDataSlice] = useState([]);
    const filter = useSelector(adminSliceSelectors.organizationsFilter);
    const [activeOrganization, setActiveOrganization] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [userListModal, setUserListModal] = useState(false);
    const [requestedStacksModal, setRequestedStacksModal] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    useEffect(() => {
        setDataSlice(data.slice(0, Math.min(limit, data.length)));
    }, [data]);

    const handlePageChanged = (pageNum) => {
        setPage(pageNum);
        if (filter.direction === "asc") {
            dispatch(
                getOrganizations({
                    search: filter?.q?.name || undefined,
                    start: pageNum,
                    asc: filter?.sort || undefined,
                    industry: filter?.industry || undefined,
                    organization: filter?.organization?.id || undefined,
                    price: filter?.price || undefined,
                    plan: filter?.plan?.id || undefined,
                }),
            );
        } else {
            getOrganizations({
                search: filter?.q?.name || undefined,
                start: pageNum,
                desc: filter?.sort || undefined,
                industry: filter?.industry || undefined,
                organization: filter?.organization?.id || undefined,
                price: filter?.price || undefined,
                plan: filter?.plan?.id || undefined,
            });
        }
        dispatch(getOrganizations({ search: filter.q.name, start: pageNum }));
    };

    useEffect(() => {
        setPage(1);
        if (filter.direction === "asc") {
            dispatch(
                getOrganizations({
                    search: filter?.q?.name || undefined,
                    start: 1,
                    asc: filter?.sort || undefined,
                    industry: filter?.industry || undefined,
                    organization: filter?.organization?.id || undefined,
                    price: filter?.price || undefined,
                    plan: filter?.plan?.id || undefined,
                }),
            );
        } else {
            dispatch(
                getOrganizations({
                    search: filter?.q?.name || undefined,
                    start: 1,
                    desc: filter?.sort || undefined,
                    industry: filter?.industry || undefined,
                    organization: filter?.organization?.id || undefined,
                    price: filter?.price || undefined,
                    plan: filter?.plan?.id || undefined,
                }),
            );
        }
    }, [filter]);

    const onOrganizationEdit = (index) => {
        const organization = data[index];
        setActiveOrganization(organization);
        setEditModalOpen(true);
    };

    const onStackCreate = (index) => {
        const organization = data[index];
        dispatch(setActiveStack({ organization }));
        navigate("/admin/stacks/create");
    };

    const onOrganizationEdited = (organization) => {
        setDataSlice(
            dataSlice.map((item) => {
                if (item.id === organization.id) {
                    return organization;
                }
                return item;
            }),
        );
        dispatch(
            addMessage({
                type: "success",
                title: "Organization edited.",
                message: "Organization successfully edited.",
                visibilityTime: 5000,
            }),
        );
        setEditModalOpen(false);
    };

    const columns = useMemo(
        () => [
            { name: "Organization Name", type: COLUMN_TYPES.AVATAR, align: "left", sortKey: "display_name" },
            { name: "Account Owner", sortKey: "ownerName" },
            { name: "Organization Email", renderer: renderEmail },
            { name: "Industry", sortKey: "industry" },
            { name: "Created at", sortKey: "created_at" },
            { name: "Current Pricing", sortKey: "pricing" },
            { name: "Subscription", sortKey: "subscription" },
            { name: "Plan", sortKey: "plan" },
            { name: "Upcoming Billing Period", sortKey: "upcoming_billing_period" },
            { name: "Users", sortKey: "users_count", renderer: renderHoverableText },
            { name: "Stacks", sortKey: "stacks_count", renderer: renderHoverableText },
            { name: "Actions", type: COLUMN_TYPES.ACTION, align: "center" },
            { name: "Note", renderer: renderNoteText },
        ],
        [],
    );

    const rows = useMemo(
        () =>
            dataSlice.map((item, index) => {
                const actions = [
                    {
                        icon: <EditIcon />,
                        tooltip: "Edit Organization",
                        callback: () => onOrganizationEdit(index),
                    },
                    { icon: <PlaylistAddIcon />, tooltip: "Create Stack", callback: () => onStackCreate(index) },
                ];
                return {
                    data: [
                        {
                            text: item.display_name,
                            website: item.website,
                        },
                        item.owner && `${item.owner?.first_name} ${item.owner?.last_name}`,
                        item.email,
                        item.industry,
                        dayjs(item.created_at).format("DD/MM/YYYY"),
                        item.plan_price?.plan_name && `€${Intl.NumberFormat().format(item.plan_price?.price)}`,
                        "",
                        item.plan_price?.plan_name,
                        "",
                        {
                            text: item.number_of_users,
                            callback: () => {
                                const organization = dataSlice[index];
                                setActiveOrganization(organization);
                                setUserListModal(true);
                            },
                        },
                        {
                            text: item.number_of_stacks,
                            callback: () => {
                                const organization = dataSlice[index];
                                setActiveOrganization(organization);
                                setRequestedStacksModal(true);
                            },
                        },
                        actions,
                        {
                            text: item.note || "",
                            organizationId: dataSlice[index].id,
                            callback: () => {
                                const organization = dataSlice[index];
                                setActiveOrganization(organization);
                            },
                        },
                    ],
                };
            }),
        [dataSlice],
    );

    const onSortBy = async (sortKey, direction) => {
        dispatch(setOrganizationsFilter({ ...filter, asc: null, desc: null, sort: sortKey, direction }));
    };

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Stack direction="column" px={3} pt={2}>
                    <Stack direction="column" mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                                <Button
                                    variant="contained"
                                    className={classes.createOrganizationButton}
                                    onClick={() => setCreateModalOpen(true)}
                                >
                                    Create New Organization
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                    {loading && !dataSlice.length && (
                        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                            <OsterusLoader />
                        </Stack>
                    )}
                    <Stack display={loading && !dataSlice.length ? "none" : "flex"}>
                        <Table columns={columns} rows={rows} onSortBy={onSortBy} isSticky />
                        <Pagination
                            page={page}
                            totalRecords={totalRecords}
                            limit={limit}
                            onChange={handlePageChanged}
                        />
                    </Stack>
                </Stack>
            </Grid>
            <EditModal
                open={editModalOpen}
                organization={activeOrganization}
                onSave={onOrganizationEdited}
                onCancel={() => {
                    setEditModalOpen(false);
                    setActiveOrganization(null);
                }}
            />
            <UserListModal
                open={userListModal}
                organization={activeOrganization}
                onSave={() => setUserListModal(false)}
                onCancel={() => {
                    setUserListModal(false);
                    setActiveOrganization(null);
                }}
            />
            <RequestedStacksModal
                open={requestedStacksModal}
                organization={activeOrganization}
                onSave={() => setRequestedStacksModal(false)}
                onCancel={() => {
                    setRequestedStacksModal(false);
                    setActiveOrganization(null);
                }}
            />
            <CreateModal open={createModalOpen} onCancel={() => setCreateModalOpen(false)} />
        </Grid>
    );
}
