import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import { IndustryField } from "./fields/Industry";
import { InternalRatingField } from "./fields/InternalRating";
import SearchComponent from "../../../../Search";
import CountryMultiselect from "../../../../AutocompleteInputs/CountryMultiselect";
import CityMultiselect from "../../../../AutocompleteInputs/CityMultiselect";
import {
    universitiesSelectors,
    updateFilter,
    setFilter,
    clearFilter,
} from "../../../../../slices/Intelligence/UniversitiesSlice";
import { JobPositionsField } from "./fields/JobPositions";

const useStyles = makeStyles((theme) => ({
    filterPaper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        justifyContent: "center",
    },
    filterGrid: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    textField: {
        width: "100%",
    },
}));

export default function Filter({ iRating, setIRating }) {
    const filter = useSelector(universitiesSelectors.filter);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => () => dispatch(clearFilter()), []);

    const clearFilters = () => {
        dispatch(setFilter({ search: filter.search }));
        setIRating([]);
    };

    const handleCountriesSelected = (countries) => {
        dispatch(updateFilter({ countries }));
    };

    const handleCitiesSelected = (cities) => {
        dispatch(updateFilter({ cities }));
    };

    const onSearch = (value) => {
        if (!value) {
            dispatch(updateFilter({ search: "" }));
        }
        dispatch(updateFilter({ search: value }));
    };

    return (
        <Paper className={classes.filterPaper} elevation={0} variant="sidebar">
            <SearchComponent
                value={filter?.q?.name}
                onSearch={onSearch}
                tooltipTitle="Search for the name od the university to start displaying results."
                label="Search here..."
            />
            <Grid container alignItems="center" className={classes.filterGrid}>
                <Grid item>
                    <Typography variant="h6" color="text.onPrimary">
                        Filters
                    </Typography>
                </Grid>
                <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                    <Button variant="text" color="primary" size="small" onClick={clearFilters}>
                        CLEAR ALL
                    </Button>
                </Grid>
            </Grid>

            <CountryMultiselect
                defaultValues={filter.countries}
                onSelected={handleCountriesSelected}
                displayTags={false}
            />
            <CityMultiselect
                iso2Countries={filter.countries.map((country) => country.iso2)}
                defaultValues={filter.cities}
                displayTags={false}
                onSelected={handleCitiesSelected}
            />

            {/* <IndustryField /> */}
            <InternalRatingField iRating={iRating} setIRating={setIRating} />
            <JobPositionsField />
        </Paper>
    );
}
