import React from "react";
import { TableRow, TableCell, Grid, Typography, Tooltip } from "@mui/material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { requestedCompaniesSliceSelectors } from "../../../slices/Stacks/requestedCompaniesSlice";
import { formatUserFirstAndLastName } from "../../Settings/Users/utils";
import { COMPANY_STATUSES } from "../../../actions/Company/Data/request";

const useStyles = makeStyles((theme) => ({
    chipSpacing: {
        margin: theme.spacing(0.5),
    },
    tableIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

export default function List() {
    const classes = useStyles();
    const requestedCompanies = useSelector(requestedCompaniesSliceSelectors.requestedCompaniesList);

    const renderStatusIcon = (status) => {
        if (status && status === COMPANY_STATUSES.DONE) {
            return (
                <Tooltip title="Done">
                    <CheckCircleIcon color="success" />
                </Tooltip>
            );
        }
        return (
            <Tooltip title="In Progress">
                <PendingIcon color="warning" />
            </Tooltip>
        );
    };

    return (
        <>
            {requestedCompanies?.records?.length > 0 &&
                requestedCompanies.records.map((company) => (
                    <TableRow key={company.requested_at}>
                        <TableCell component="th" scope="row">
                            <Grid container direction="row" alignItems="center">
                                <ListAltIcon className={classes.tableIcon} />
                                <Typography variant="body2" color="textPrimary">
                                    {company.company_name}
                                </Typography>
                            </Grid>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="body2" color="text.secondaryVariant2">
                                {renderStatusIcon(company.status)}
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="body2" color="textSecondary">
                                {dayjs(company.requested_at).format("YYYY-MM-DD")}
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="body2" color="textPrimary">
                                {formatUserFirstAndLastName(company?.user)}
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="body2" color="text.secondaryVariant3">
                                {company.user?.email}
                            </Typography>
                        </TableCell>
                    </TableRow>
                ))}
        </>
    );
}
