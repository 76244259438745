import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "@gatsbyjs/reach-router";
import { makeStyles } from "@mui/styles";
import { JobsAndExperienceView } from "./Views/Comparison/JobsAndExperienceView";
import { Overview } from "./Views/Comparison/Overview";
import { Overview as OverviewSingle } from "./Views/Single/Overview";
import { EducationView } from "./Views/Comparison/EducationView";
import { SingleJobsAndExperienceView } from "./Views/Single/JobsAndExperienceView";
import { SingleEducationView } from "./Views/Single/EducationView";
import { SingleDemographicsView } from "./Views/Single/DemographicsView";
import { DemographicsView } from "./Views/Comparison/DemographicsView";
import {
    EMPLOYMENT_COMPARISON_INSIGHT,
    EMPLOYMENT_SINGLE_INSIGHT,
    EDUCATION_COMPARISON_INSIGHT,
    EDUCATION_SINGLE_INSIGHT,
    OVERVIEW_COMPARISON_INSIGHT,
    OVERVIEW_SINGLE_INSIGHT,
    DEMOGRAPHICS_COMPARISON_INSIGHT,
    DEMOGRAPHICS_SINGLE_INSIGHT,
} from "./Views/viewConstants";
import { getSelectedStacks } from "../../../../actions/theLab/stacks";
import { stackSliceSelectors } from "../../../../slices/Stacks/stackSlice";
import { getStackInsightsData } from "../../../../actions/theLab/stacks/stackInsights";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
} from "../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { TabHeader } from "./Headers/TabHeader";
// import { DefaultHeader } from "./Headers/DefaultHeader";
import StackSelect from "./StackSelect";
import {
    temporaryStacks,
    temporaryEmploymentViewData,
    temporaryEducationViewData,
    temporaryOverviewData,
    temporarySingleOverviewData,
    temporaryDemographicsViewData,
    temporaryEmploymentSingleViewData,
    temporaryEducationSingleViewData,
    temporaryDemographicsSingleViewData,
} from "../../../../slices/Stacks/StackInsights/temporaryInsightsLoadData";

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
    },

    header: {
        width: "100%",
        margin: 0,
        position: "sticky",
        zIndex: 3,
        top: 0,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Data Insights",
        color: "text.onPrimary",
    },
    breadcrumbs: [
        {
            text: "Stacks",
            color: "textSecondary",
            link: null,
            icon: null,
        },
        {
            text: "Data Insights",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function StackInsights() {
    const singleViewMatch = useMatch("/the-lab/stacks/stack-insights/:view/:stackId");
    const comparisonViewMatch = useMatch("/the-lab/stacks/stack-insights/:view/:firstStackId/:secondStackId");

    const view = singleViewMatch ? singleViewMatch.view : comparisonViewMatch.view;
    const uri = singleViewMatch ? singleViewMatch.uri : comparisonViewMatch.uri;
    const [previousUri, setPreviousUri] = useState(null);

    const selectedStacks = useSelector(stackSliceSelectors.selectedStacks);
    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    let loadingInsights = useSelector(stackInsightsSliceSelector.loadingInsights);
    loadingInsights = uri !== previousUri ? true : loadingInsights;
    const insightsData = useSelector(stackInsightsSliceSelector.insightsData);
    const promise = useRef(null);

    const viewSupportsFilters = view !== OVERVIEW_COMPARISON_INSIGHT;

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        // Has to handle cases where user goes back and forward in history(state won't update in those cases)
        if (singleViewMatch) {
            const stackId = parseInt(singleViewMatch.stackId);
            if (stackId !== selectedStacks[0]?.id) {
                dispatch(getSelectedStacks({ id: [stackId], start: 1, limit: 1 }));
            } else if (selectedStacks.length > 0 && uri !== previousUri) {
                setPreviousUri(uri);
                promise.current = dispatch(
                    getStackInsightsData({
                        ids: [stackId],
                        view,
                        v: 2,
                        show: show ?? ShowModes.percent,
                        split: split ?? SplitModes.none,
                        filters: viewSupportsFilters
                            ? {
                                  jobTitles: appliedFilters.jobTitles.map((jobTitle) => jobTitle.id),
                                  schools: appliedFilters.universities.map((university) => university.id),
                                  languages: appliedFilters.languages.map((language) => language.id),
                                  cities: appliedFilters.cities.map((city) => city.id),
                              }
                            : undefined,
                    }),
                );
            }
        } else if (comparisonViewMatch) {
            const firstStackId = parseInt(comparisonViewMatch.firstStackId);
            const secondStackId = parseInt(comparisonViewMatch.secondStackId);

            if (firstStackId !== selectedStacks[0]?.id || secondStackId !== selectedStacks[1]?.id) {
                dispatch(
                    getSelectedStacks({
                        id: [firstStackId, secondStackId],
                        start: 1,
                        limit: 2,
                    }),
                );
            } else if (selectedStacks.length > 0 && uri !== previousUri) {
                setPreviousUri(uri);
                promise.current = dispatch(
                    getStackInsightsData({
                        ids: [firstStackId, secondStackId],
                        view,
                        show: show ?? ShowModes.percent,
                        split: split ?? SplitModes.none,
                        v: 2,
                        filters: viewSupportsFilters
                            ? {
                                  jobTitles: appliedFilters.jobTitles.map((jobTitle) => jobTitle.id),
                                  schools: appliedFilters.universities.map((university) => university.id),
                                  languages: appliedFilters.languages.map((language) => language.id),
                                  cities: appliedFilters.cities.map((city) => city.id),
                              }
                            : undefined,
                    }),
                );
            }
        }
    }, [selectedStacks, uri]);

    useEffect(() => {
        if (uri !== previousUri) return;

        promise?.current?.abort();

        let ids;
        if (singleViewMatch) {
            ids = [parseInt(singleViewMatch.stackId)];
        } else {
            ids = [parseInt(comparisonViewMatch.firstStackId), parseInt(comparisonViewMatch.secondStackId)];
        }

        promise.current = dispatch(
            getStackInsightsData({
                ids,
                view,
                show: show ?? ShowModes.percent,
                split: split ?? SplitModes.none,
                v: 2,
                filters: viewSupportsFilters
                    ? {
                          jobTitles: appliedFilters.jobTitles.map((jobTitle) => jobTitle.id),
                          schools: appliedFilters.universities.map((university) => university.id),
                          languages: appliedFilters.languages.map((language) => language.id),
                          cities: appliedFilters.cities.map((city) => city.id),
                      }
                    : undefined,
            }),
        );
    }, [appliedFilters]);

    useEffect(
        () => () => {
            // reject previously sent request when leaving component
            promise?.current?.abort();
        },
        [uri],
    );

    const determineView = () => {
        const isSingleView = singleViewMatch !== null;

        const stackSelector = (
            <div className={classes.header}>
                <StackSelect
                    breadcrumbComponents={breadcrumbComponents}
                    stacks={selectedStacks}
                    viewType={view}
                    isLoading={loadingInsights}
                    isSingle={isSingleView}
                />
            </div>
        );
        switch (view) {
            case EMPLOYMENT_COMPARISON_INSIGHT:
                return (
                    <JobsAndExperienceView
                        insightsData={loadingInsights ? temporaryEmploymentViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case DEMOGRAPHICS_COMPARISON_INSIGHT:
                return (
                    <DemographicsView
                        insightsData={loadingInsights ? temporaryDemographicsViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case OVERVIEW_COMPARISON_INSIGHT:
                return (
                    <Overview
                        insightsData={loadingInsights ? temporaryOverviewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case OVERVIEW_SINGLE_INSIGHT:
                return (
                    <OverviewSingle
                        insightsData={loadingInsights ? temporarySingleOverviewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case EDUCATION_COMPARISON_INSIGHT:
                return (
                    <EducationView
                        insightsData={loadingInsights ? temporaryEducationViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case EMPLOYMENT_SINGLE_INSIGHT:
                return (
                    <SingleJobsAndExperienceView
                        insightsData={loadingInsights ? temporaryEmploymentSingleViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case EDUCATION_SINGLE_INSIGHT:
                return (
                    <SingleEducationView
                        insightsData={loadingInsights ? temporaryEducationSingleViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            case DEMOGRAPHICS_SINGLE_INSIGHT:
                return (
                    <SingleDemographicsView
                        insightsData={loadingInsights ? temporaryDemographicsSingleViewData : insightsData}
                        isLoading={loadingInsights}
                        stacks={selectedStacks.length ? selectedStacks : temporaryStacks}
                        StackSelectComponent={stackSelector}
                        HeaderComponent={TabHeader}
                    />
                );
            // no default
        }
    };

    return <div className={classes.container}>{determineView()}</div>;
}
