import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

export default function RadialBarChart({
    value,
    rangeType = "loyalty",
    height = 200,
    customLabelAndColor,
    hollowSize = "70%",
}) {
    const [labelAndColor, setLabelAndColor] = useState({});
    const [range, setRange] = useState();

    useEffect(() => {
        switch (rangeType) {
            case "loyalty":
                setRange([0, 0.205, 0.34, 0.475, 0.65]);
                break;
            case "diversity":
                setRange([0, 0.29, 0.454, 0.6, 0.755]);
                break;
            case "competitiveness":
                setRange([0, 0.235, 0.354, 0.472, 0.64]);
                break;
            //  no default
        }
    }, [value, rangeType]);

    useEffect(() => {
        if (customLabelAndColor) {
            setLabelAndColor({
                label: customLabelAndColor.label,
                color: customLabelAndColor.color,
            });
        } else {
            const rangeIndex = range?.findIndex((threshold) => value <= threshold);
            switch (rangeIndex) {
                case 0:
                    setLabelAndColor({
                        label: "Very Low",
                        color: "#F64949",
                    });
                    break;
                case 1:
                    setLabelAndColor({
                        label: "Very Low",
                        color: "#F64949",
                    });
                    break;
                case 2:
                    setLabelAndColor({
                        label: "Low",
                        color: "#FFB03A",
                    });
                    break;
                case 3:
                    setLabelAndColor({
                        label: "Medium",
                        color: "#F5E174",
                    });
                    break;
                case 4:
                    setLabelAndColor({
                        label: "High",
                        color: "#9AE36D",
                    });
                    break;
                case 5:
                    setLabelAndColor({
                        label: "Very High ",
                        color: "#64CE62",
                    });
                    break;
                default:
                    setLabelAndColor({
                        label: "Very High ",
                        color: "#64CE62",
                    });
                    break;
            }
        }
    }, [value, range, customLabelAndColor]);

    const options = {
        chart: {
            height,
            type: "radialBar",
            toolbar: {
                show: false,
            },
        },
        colors: [labelAndColor.color],
        series: customLabelAndColor ? [value] : [value === 0 ? 0 : (value / 1) * 100],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 15,
                    size: hollowSize,
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: 0,
                        show: true,
                        color: "#888",
                        fontSize: "15px",
                    },
                    value: {
                        color: "#111",
                        fontSize: "30px",
                        show: false,
                    },
                },
            },
        },
        legend: {
            show: false,
        },
        stroke: {
            lineCap: "round",
        },
        labels: [labelAndColor.label],
    };

    return <Chart options={options} type="radialBar" series={options.series} height={height} />;
}
