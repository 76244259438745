import React, { useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
    Checkbox,
    Divider,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tagsSliceSelectors } from "../../../../../../slices/Tags/tagsSlice";
import { stackSliceSelectors, updateFilter } from "../../../../../../slices/Stacks/stackSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    indeterminateColor: {
        "& .MuiSvgIcon-root": {
            color: `${theme.palette.secondary[500]} !important`,
        },
    },
    menuPaper: {
        ...theme.components.MuiFormControl.variants[0].FilterMenuStyle,
    },
}));

const delayTime = 500;
function Tags() {
    const tagsList = useSelector(tagsSliceSelectors.tags)?.records || [];
    const filter = useSelector(stackSliceSelectors.getFilter);
    const selectedTags = filter?.tags.map((tag) => tag.id) || [];
    const isAllSelected = tagsList.length > 0 && selectedTags.length === tagsList.length;
    const elementRef = useRef(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
        },
        classes: { paper: classes.menuPaper },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        variant: "menu",
    };

    const handleChange = (event) => {
        const { value } = event.target;

        if (value[value.length - 1] === "all") {
            dispatch(
                updateFilter({
                    ...filter,
                    tags: selectedTags.length === tagsList.length ? [] : tagsList,
                }),
            );
            return;
        }
        let duplicateRemoved = [];

        value.forEach((item) => {
            if (duplicateRemoved.findIndex((elem) => elem.id === item.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id !== item.id);
            } else {
                duplicateRemoved.push(tagsList.find((tag) => tag.id === item));
            }
        });

        dispatch(updateFilter({ ...filter, tags: duplicateRemoved, start: 1 }));
    };

    useEffect(() => {
        MenuProps.PaperProps.style.width = elementRef.current.getBoundingClientRect().width;
    }, []);

    return (
        <Tooltip
            enterNextDelay={delayTime}
            title="Choose one or more tags to filter down the displayed CV Stacks."
            placement="top"
        >
            <FormControl
                ref={elementRef}
                id="tags-form-control"
                variant="outlined"
                size="small"
                className={classes.formControl}
            >
                <InputLabel>Tags</InputLabel>
                <Select
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
                    renderValue={(selectedItems) =>
                        selectedItems.map((item) => tagsList.find((tag) => tag.id === item).name).join(", ")
                    }
                    color="secondary"
                    MenuProps={MenuProps}
                    label="Tags"
                >
                    <MenuItem value="all">
                        <ListItemIcon>
                            <Checkbox
                                classes={{
                                    indeterminate: classes.indeterminateColor,
                                    checked: classes.indeterminateColor,
                                }}
                                checked={isAllSelected}
                                indeterminate={selectedTags.length > 0 && selectedTags.length < tagsList.length}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select all" />
                    </MenuItem>
                    <Divider />
                    {tagsList.length > 0 &&
                        tagsList.map((tag) => (
                            <MenuItem key={tag.id} value={tag.id} translate="no">
                                <ListItemIcon>
                                    <Checkbox checked={selectedTags.includes(tag.id)} />
                                </ListItemIcon>
                                <ListItemText primary={tag.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Tooltip>
    );
}

export default Tags;
