import { axiosInstance } from "../../../config/api/Axios";

export const uploadLogoApi = (logo) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    return axiosInstance.post(`/media/picture`, logo, config);
};
