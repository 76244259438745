import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers } from "../user/user";
import { getAllByFilter } from "../City/CityAction";

export const bootstrapAction = createAsyncThunk("users/bootstrapAction", async (args, { dispatch }) => {
    try {
        await dispatch(getAllByFilter());
        await dispatch(getUsers());
    } catch (e) {
        throw e;
    }
});
