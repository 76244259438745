import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataRequestsApi } from "../../services/api/Admin/dataRequests";

export const getDataRequests = createAsyncThunk("admin/data-requests", async ({ organization_id, name, start = 1 }) => {
    try {
        const { data } = await getDataRequestsApi(organization_id, { name, start });
        return { data };
    } catch (error) {
        throw error;
    }
});
