import React from "react";
import Chart from "react-google-charts";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { ChartContainer } from "../ChartContainer";

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".workforceLocationTooltip": {
            background: theme.palette.background.secondary,
            fontSize: 12,
            borderRadius: "0 10px 0 10px",
            display: "flex",
            flexDirection: "column",
            boxShadow: theme.shadows[2],
            minWidth: "140px",
            height: "100%",
            padding: 0,
            margin: 0,
        },
        ".workforceLocationTooltipTitle": {
            width: "100%",
            display: "flex",
            background: theme.palette.common.black,
            padding: "3.75px 8.5px 3.75px 11.25px",
            borderRadius: "0px 11.25px 0px 0px",
        },
        ".workforceLocationTooltipCircle1": {
            width: "9.66px",
            height: "9.66px",
            borderRadius: "50%",
            background: theme.palette.additional1.main,
        },
        ".workforceLocationTooltipCircle2": {
            width: "9.66px",
            height: "9.66px",
            borderRadius: "50%",
            background: theme.palette.additional2.main,
        },
        ".workforceLocationTooltipBody": {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "7.5px 8.5px 11.25px 11.25px",
        },
        ".tooltipTitleText": {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "9px",
            lineHeight: "11px",
            letterSpacing: "0.05em",
            color: theme.palette.common.white,
        },
        ".tooltipTextRow": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
        },
        ".tooltipSubRow": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "40%",
            height: "100%",
        },
        ".tooltipBodyText1": {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "14.3px",
            letterSpacing: "3%",
            color: theme.palette.common.black,
        },
        ".tooltipBodyText2": {
            fontWeight: 400,
            fontSize: "9px",
            lineHeight: "12px",
            letterSpacing: "5%",
            color: theme.palette.common.black,
        },
        ".google-visualization-tooltip": {
            border: "none !important",
            background: "transparent !important",
            boxShadow: "none !important",
        },
    },
}));

const tooltipVersion1 = ({ city, circleColorClass, customOptions, profiles, percent, markerColor }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitle>
        <div class=tooltipTitleText>${city}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow>
                <span class=${circleColorClass} ${
    customOptions.colorAxis ? `style='background: ${customOptions.colorAxis.colors[markerColor]}'` : ""
}></span>
                <span class=tooltipBodyText1>${profiles}</span>
            </span>
            <span class=tooltipBodyText2>Profiles</span>
        </div>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow>
                <span class=${circleColorClass} ${
    customOptions.colorAxis ? `style='background: ${customOptions.colorAxis.colors[markerColor]}'` : ""
}></span>
                <span class=tooltipBodyText1>${percent}%</span>
            </span>
            <span class=tooltipBodyText2>Percentages</span>
        </div>
    </div>
</div>`;

const tooltipVersion2 = ({ title, city, circleColorClass, customOptions, percent, markerColor }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitle>
        <div class=tooltipTitleText>${title}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow style="width: auto;">
                <span class=${circleColorClass} ${
    customOptions.colorAxis
        ? `style='background: ${customOptions.colorAxis.colors[markerColor]}; margin-right: 5px;'`
        : ""
}></span>
                <span class=tooltipBodyText1 style="margin-right: 12px;">${city}</span>
            </span>
            <span class=tooltipBodyText1>${percent}%</span>
        </div>
    </div>
</div>`;

export default function GoogleChart({
    color = 0,
    data = [],
    title = "",
    width = "auto",
    height = "auto",
    customOptions = {},
    containerStyles = {},
    multiSeries = false,
    tooltipVersion = 1,
    seriesTitles = [],
}) {
    // eslint-disable-next-line no-unused-vars
    const classes = useStyles();
    const theme = useTheme();

    const formattedData = data.map((item, index) => {
        if (index === 0) return ["City", "Profiles", { role: "tooltip", type: "string", p: { html: true } }];
        const markerColor = !multiSeries ? color : item[1];
        const circleColorClass =
            markerColor === 1 ? "workforceLocationTooltipCircle2" : "workforceLocationTooltipCircle1";
        const city = item[0].replace(" ,", ",");
        const profiles = !multiSeries ? item[1] : item[2];
        const percent = !multiSeries ? item[2] : item[3];
        const tooltip =
            tooltipVersion === 1
                ? tooltipVersion1({ city, circleColorClass, customOptions, profiles, percent, markerColor })
                : tooltipVersion2({
                      title: seriesTitles[markerColor],
                      city,
                      circleColorClass,
                      customOptions,
                      percent,
                      markerColor,
                  });
        return [item[0], item[1], tooltip];
    });

    const commonOptions = {
        sizeAxis: { minValue: 0, maxValue: 100, minSize: 5, maxSize: 5 },
        displayMode: "markers",
        legend: undefined,
        ...customOptions,
    };
    const mapOptions = [
        {
            colorAxis: { colors: [theme.palette.additional1[600], theme.palette.additional1[900]] },
            tooltip: { isHtml: true, trigger: "hover", showTitle: false },
            datalessRegionColor: "#B8C7F6",
            ...commonOptions,
        },
        {
            colorAxis: { colors: [theme.palette.additional2.main, theme.palette.additional2[900]] },
            tooltip: { isHtml: true, trigger: "hover", showTitle: false },
            datalessRegionColor: "#B5E5CE",
            ...commonOptions,
        },
    ];

    return (
        <ChartContainer
            color={color}
            chartInfo={{ title, shortTitle: title }}
            showActions={false}
            containerStyles={containerStyles}
        >
            <Chart
                chartType="GeoChart"
                width={width}
                height={height}
                data={formattedData}
                options={mapOptions[color]}
                mapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
            />
        </ChartContainer>
    );
}
