import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

export default function Container({ children }) {
    const classes = useStyles();

    return (
        <Box p={3} pt={2} className={classes.root}>
            {children}
        </Box>
    );
}
