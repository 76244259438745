import React from "react";
import { BoxContainer } from "./variants/BoxContainer";
import { PlainContainer } from "./variants/PlainContainer";

export const ChartContainer = React.forwardRef((props, ref) => {
    const { variant = "box" } = props;

    let container = null;
    switch (variant) {
        case "box":
            container = <BoxContainer ref={ref} {...props} />;
            break;
        case "plain":
            container = <PlainContainer ref={ref} {...props} />;
            break;
        default:
            throw new Error("Invalid variant");
    }

    return container;
});
