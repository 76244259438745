import axios from "axios";
import Qs from "qs";
import { store } from "../../core/Store";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: "brackets" });
    },
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const notificationSource = "Axios";

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response.status;
        if (status >= 500 && status <= 599) {
            error.response.data = {
                error: {
                    message: "Internal server error. Please contact your administrator!",
                },
            };
        } else if (status === 401) {
            localStorage.removeItem("token");
        } else if (status === 429) {
            const notifications = store.getState().globalNotifications.notifications;
            if (!notifications.filter((notif) => notif.source === notificationSource).length) {
                store.dispatch(
                    addMessage({
                        type: "error",
                        title: "Too many requests :(",
                        message: "You are sending too many requests, try again in a bit.",
                        visibilityTime: 5000,
                        source: notificationSource,
                    }),
                );
            }
        }

        return Promise.reject(error);
    },
);
