import React, { useState, useMemo, useEffect } from "react";
import { Paper, Stack, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import StackedBar from "../../../Charts/StackedBar";
import StackedBarCategories from "../../../Charts/StackedBarCategories";
import { ChartContainer } from "../../../Charts/ChartContainer";
import MarkersBarChartWithDifference from "../../../MixedCharts/MarkersBarChartWithDifference";
import TableChart from "../../../Charts/TableChart";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";
import { stackInsightsSliceSelector } from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getMarkersBarHeight, filterOutInactiveCategories } from "../../../utils";

const useStyles = makeStyles(() => ({
    root: {
        padding: "24px 24px 12px 12px",
        background: "#fff",
        marginLeft: "8px",
    },
    divider: {
        width: "100%",
        position: "relative",
    },
    sectionDivider: {
        borderWidth: 1.5,
        background: "black",
        marginBottom: 16,
        marginLeft: -14,
        marginRight: -24,
    },
    typographyTitle: {
        color: "#7F7F7F",
        fontWeight: 500,
        fontSize: 20,
    },
}));

const categoriesStyle = {
    marginBottom: 10,
    paddingRight: 12,
    paddingLeft: 12,
    height: 16,
};

const titleStyles = {
    position: "relative",
    right: 66,
    background: "transparent",
};

const degreeStyle = {
    fontSize: 12,
    marginBottom: 9.2,
};

export function Section2({ data, stacks }) {
    const classes = useStyles();
    const itemsPerPage = 10;
    const [page, setPage] = useState(0);
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    const activeCategories = appliedFilters.languages;
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const [newCategories, newFirstStackSeries, newSecondStackSeries] = useMemo(() => {
        const tempCategories = [...data.languagesSpoken.categories].slice(
            page * itemsPerPage,
            (page + 1) * itemsPerPage,
        );
        const tempFirstStackSeries = data.languagesSpoken.stack1.series.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        const tempSecondStackSeries = data.languagesSpoken.stack2.series.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        return [tempCategories, tempFirstStackSeries, tempSecondStackSeries];
    }, [data, page]);

    const dataPointPadding = 26;
    const dataPointXAxisOffset = 12.75;

    const missingItemsFirstStack = itemsPerPage - newFirstStackSeries[0].data.length;
    const paddingFirstStack = { top: -25, left: 40, right: 0, bottom: missingItemsFirstStack * dataPointPadding };
    const xAxisOffsetFirstStack = missingItemsFirstStack * dataPointXAxisOffset;

    const missingItemsSecondStack = itemsPerPage - newSecondStackSeries[0].data.length;
    const paddingSecondStack = { top: -25, left: 0, right: 40, bottom: missingItemsSecondStack * dataPointPadding };
    const xAxisOffsetSecondStack = missingItemsSecondStack * dataPointXAxisOffset;

    const maxValue = useMemo(() => {
        let max = 0;
        for (let i = 0; i < newCategories.length; i += 1) {
            const rowSum = newFirstStackSeries.reduce((prev, cur) => prev + cur.data[i], 0);
            max = Math.max(max, rowSum);
        }
        for (let i = 0; i < newCategories.length; i += 1) {
            const rowSum = newSecondStackSeries.reduce((prev, cur) => prev + cur.data[i], 0);
            max = Math.max(max, rowSum);
        }

        return max;
    }, [data, page]);

    const educationDegrees = [
        {
            data: data.degreeDistribution.comparisonChart.stack1.series.data.map((item, index) => ({
                x: data.degreeDistribution.comparisonChart.categories[index],
                y: item,
            })),
        },
        {
            data: data.degreeDistribution.comparisonChart.stack2.series.data.map((item, index) => ({
                x: data.degreeDistribution.comparisonChart.categories[index],
                y: item,
            })),
        },
    ];

    const tableChartLegend = [
        {
            name: stacks[0].stack.name,
            color: "#6181ea",
        },
        {
            name: stacks[1].stack.name,
            color: "#4ac086",
        },
    ];

    useEffect(() => {
        setPage(0);
    }, [stacks, show, split]);

    const numberOfSpokenLanguagesPerJobTitleFormated = [
        {
            name: stacks[0].stack.name,
            data: data.numberOfSpokenLanguagesPerJobTitle.stack1.series[0].data,
        },
        {
            name: stacks[1].stack.name,
            data: data.numberOfSpokenLanguagesPerJobTitle.stack2.series[0].data,
        },
    ];

    const inactiveIndices = useMemo(() => {
        if (!activeCategories || !activeCategories.length) return [];

        const indicies = [];
        newCategories.forEach((category, index) => {
            if (activeCategories.findIndex((val) => val.name === category) === -1) indicies.push(index);
        });

        return indicies;
    }, [activeCategories, newCategories]);

    const handleOnUpdated = useMemo(
        () => (context, options) => filterOutInactiveCategories(options, inactiveIndices),
        [inactiveIndices],
    );

    return (
        <>
            <Stack height={197} ml={1}>
                <MarkersBarChartWithDifference
                    series={educationDegrees}
                    stacks={stacks}
                    categories={data.degreeDistribution.comparisonChart.categories}
                    title="Education Degrees"
                    shortTitle="Education Degrees"
                    colors={[
                        ["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"],
                        ["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"],
                    ]}
                    differenceSeries={[
                        {
                            data: data?.degreeDistribution?.diffChart.series.data,
                        },
                    ]}
                    containerStyle={{ pt: 4 }}
                    height={getMarkersBarHeight(data.degreeDistribution.comparisonChart.categories.length)}
                    categoriesStyle={degreeStyle}
                    barheight="66%"
                    inPercentage
                />
                <Divider className={classes.divider} />
            </Stack>

            <Stack ml={1}>
                <TableChart
                    title="Education Degrees per Gender"
                    tableData={data?.degreeDistributionPerGender?.tableChart}
                    categories={tableChartLegend.map((item) => item.name)}
                    legend={tableChartLegend}
                    tooltips={[
                        "",
                        "Education Degrees in Male Population",
                        "Education Degrees in Female Population",
                        "Education Degrees in Total",
                    ]}
                    tooltip="%"
                    chartPadding={{ top: -13, left: -10, right: 0, bottom: -13 }}
                    barHeight="70%"
                    chartHeight={65}
                    stacks={stacks}
                />
            </Stack>

            <Paper className={classes.root} elevation={1}>
                <Stack direction="row" width="100%" height={400}>
                    <Stack flex={2} alignItems="center" width="100%" height="100%" position="relative">
                        <ChartContainer
                            showTitle
                            title="Top Spoken Languages"
                            variant="plain"
                            nextPage={() => setPage(page + 1)}
                            previousPage={() => setPage(page - 1)}
                            page={page}
                            lastPage={parseInt(Math.ceil(data.languagesSpoken.categories.length / itemsPerPage))}
                            titleStyles={titleStyles}
                        >
                            <Stack flex={5} direction="row" width="100%" px={4}>
                                <Stack height={280} width="100%" direction="row" mt={3}>
                                    <Stack flex={4} width="100%">
                                        <StackedBar
                                            series={newFirstStackSeries}
                                            categories={newCategories}
                                            barColors={["#5172E8"]}
                                            title={stacks[0].stack.name}
                                            stackname={stacks[0].stack.name}
                                            reversed
                                            padding={paddingFirstStack}
                                            xAxisOffset={xAxisOffsetFirstStack}
                                            max={maxValue}
                                            noXaxis
                                            eventHandlers={{ onUpdated: handleOnUpdated }}
                                        />
                                    </Stack>
                                    <Stack flex={1} maxWidth={100}>
                                        <StackedBarCategories
                                            categories={newCategories}
                                            categoriesStyle={categoriesStyle}
                                            containerStyle={{ pt: 4.5, mr: -1.75 }}
                                            inactiveCategories={
                                                !inactiveIndices || !inactiveIndices.length ? null : inactiveIndices
                                            }
                                        />
                                    </Stack>
                                    <Stack flex={4}>
                                        <StackedBar
                                            series={newSecondStackSeries}
                                            categories={newCategories}
                                            barColors={["#4BC186"]}
                                            title={stacks[1].stack.name}
                                            stackname={stacks[1].stack.name}
                                            padding={paddingSecondStack}
                                            xAxisOffset={xAxisOffsetSecondStack}
                                            max={maxValue}
                                            noXaxis
                                            eventHandlers={{ onUpdated: handleOnUpdated }}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </ChartContainer>
                    </Stack>
                </Stack>
                <Stack height={410} mt={1}>
                    <StandardVerticalBarChart
                        variant="normalValue"
                        title="Number of Spoken Languages"
                        shortTitle="Number of Spoken Languages"
                        categories={data.numberOfSpokenLanguagesPerJobTitle.categories}
                        series={numberOfSpokenLanguagesPerJobTitleFormated}
                        showLegend
                        colors={{ defaultColors: ["#afbff2", "#a3e0c1"] }}
                        elevation={0}
                    />
                </Stack>
            </Paper>
        </>
    );
}
