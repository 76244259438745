import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Section1 } from "./Sections/Section1";
import { Section2 } from "./Sections/Section2";
import { Section3 } from "./Sections/Section3";
import { Section4 } from "./Sections/Section4";
import OsterusLoader from "../../../../../../Loader";
import { OVERVIEW_COMPARISON_INSIGHT } from "../../viewConstants";

const useStyles = makeStyles(() => ({
    loader: {
        position: "absolute",
        top: "50%",
        left: "47%",
        margin: "auto",
    },
    sectionContainer: {
        margin: "auto",
        padding: "0 16px",
        marginTop: -8,
        marginBottom: 8,
    },
    blur: {
        filter: "blur(4px)",
        WebkitFilter: "blur(4px)",
        pointerEvents: "none",
    },
}));

export function Overview({ isLoading, StackSelectComponent, HeaderComponent, headerProps, stacks, insightsData }) {
    const classes = useStyles();

    const [canRenderCharts, setCanRenderCharts] = useState(false);

    useEffect(() => setTimeout(() => setCanRenderCharts(true), 1), []);

    return (
        <>
            {StackSelectComponent}
            <HeaderComponent
                viewType={OVERVIEW_COMPARISON_INSIGHT}
                isSingle={false}
                selectedStacks={stacks}
                isLoading={isLoading}
                {...headerProps}
            />
            <div className={`${classes.sectionContainer} ${isLoading ? classes.blur : ""}`} translate="no">
                {canRenderCharts && (
                    <Grid container>
                        <Grid xs={6}>
                            <Section1 stacks={stacks} data={insightsData.section1} />
                        </Grid>
                        <Grid xs={6}>
                            <Section2 stacks={stacks} data={insightsData.section2} />
                        </Grid>
                        <Grid xs={6}>
                            <Section3 stacks={stacks} data={insightsData.section3} />
                        </Grid>
                        <Grid xs={6}>
                            <Section4 />
                        </Grid>
                    </Grid>
                )}
            </div>
            {isLoading && (
                <Box className={classes.loader}>
                    <OsterusLoader />
                </Box>
            )}
        </>
    );
}
