export const tooltipStyles = (theme) => ({
    "@global": {
        ".workforceLocationTooltip": {
            background: theme.palette.background.secondary,
            fontSize: 12,
            borderRadius: "0 10px 0 10px",
            display: "flex",
            flexDirection: "column",
            boxShadow: theme.shadows[2],
            minWidth: "140px",
            height: "100%",
            padding: 0,
            margin: 0,
        },
        ".workforceLocationTooltipTitle": {
            width: "100%",
            display: "flex",
            background: theme.palette.common.black,
            padding: "3.75px 8.5px 3.75px 11.25px",
            borderRadius: "0px 11.25px 0px 0px",
        },
        ".workforceLocationTooltipTitleLight": {
            width: "100%",
            display: "flex",
            height: 35,
            background: "#ECEEF0",
            padding: "3.75px 8.5px 3.75px 11.25px",
            borderBottom: `1px solid ${theme.palette.divider}`,
            alignItems: "center",
        },
        ".workforceLocationTooltipCircle1": {
            width: "9.66px",
            height: "9.66px",
            borderRadius: "50%",
            background: theme.palette.additional1.main,
        },
        ".workforceLocationTooltipCircle2": {
            width: "9.66px",
            height: "9.66px",
            borderRadius: "50%",
            background: theme.palette.additional2.main,
        },
        ".workforceLocationTooltipBody": {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "7.5px 8.5px 11.25px 11.25px",
        },
        ".tooltipTitleText": {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "9px",
            lineHeight: "11px",
            letterSpacing: "0.05em",
            color: theme.palette.common.white,
        },
        ".tooltipTitleTextLight": {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "11px",
            letterSpacing: "0.05em",
            color: "#2C2C2C",
        },
        ".tooltipTextRow": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
        },
        ".tooltipSubRow": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "40%",
            height: "100%",
        },
        ".tooltipBodyText1": {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: "14.3px",
            letterSpacing: "3%",
            color: theme.palette.common.black,
        },
        ".tooltipBodyText2": {
            fontWeight: 400,
            fontSize: "9px",
            lineHeight: "12px",
            letterSpacing: "5%",
            color: theme.palette.common.black,
        },
        ".google-visualization-tooltip": {
            border: "none !important",
            background: "transparent !important",
            boxShadow: "none !important",
        },
    },
});

const tooltipVersion1 = ({ city, circleColorClass, customOptions, profiles, percent, markerColor }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitle>
        <div class=tooltipTitleText>${city}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow>
                <span class=${circleColorClass} ${
    customOptions.colorAxis ? `style='background: ${customOptions.colorAxis.colors[markerColor]}'` : ""
}></span>
                <span class=tooltipBodyText1>${profiles}</span>
            </span>
            <span class=tooltipBodyText2>Profiles</span>
        </div>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow>
                <span class=${circleColorClass} ${
    customOptions.colorAxis ? `style='background: ${customOptions.colorAxis.colors[markerColor]}'` : ""
}></span>
                <span class=tooltipBodyText1>${percent}%</span>
            </span>
            <span class=tooltipBodyText2>Percentages</span>
        </div>
    </div>
</div>`;

const tooltipVersion2 = ({ title, city, circleColorClass, customOptions, percent, markerColor }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitle>
        <div class=tooltipTitleText>${title}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow style="width: auto;">
                <span class=${circleColorClass} ${
    customOptions.colorAxis
        ? `style='background: ${customOptions.colorAxis.colors[markerColor]}; margin-right: 5px;'`
        : ""
}></span>
                <span class=tooltipBodyText1 style="margin-right: 12px;">${city}</span>
            </span>
            <span class=tooltipBodyText1>${percent}%</span>
        </div>
    </div>
</div>`;

const tooltipVersion3 = ({ title, city1, city2, circleColorClass, percent1, percent2, colors }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitle>
        <div class=tooltipTitleText>${title}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        <div class=tooltipTextRow>
            <span class=tooltipSubRow style="width: auto;">
                <span class=${circleColorClass} style='background: ${colors[0]}; margin-right: 5px;'
}></span>
                <span class=tooltipBodyText1 style="margin-right: 12px;">${city1}</span>
            </span>
            <span class=tooltipBodyText1>${percent1}%</span>
        </div>
                <div class=tooltipTextRow>
            <span class=tooltipSubRow style="width: auto;">
               <span class=${circleColorClass} style='background: ${colors[1]}; margin-right: 5px;'
}></span>
                <span class=tooltipBodyText1 style="margin-right: 12px;">${city2}</span>
            </span>
            <span class=tooltipBodyText1>${percent2}%</span>
        </div>
    </div>
</div>`;

const tooltipVersion4 = ({ title, cities, circleColorClass, stackValues, colors }) => `
<div class=workforceLocationTooltip>
    <div class=workforceLocationTooltipTitleLight>
        <div class=tooltipTitleTextLight>${title}</div>
    </div>
    <div class=workforceLocationTooltipBody>
        ${cities
            .map(
                (city, index) => `
            <div class=tooltipTextRow>
                <span class=tooltipSubRow style="width: auto;">
                    <span class=${circleColorClass} style='background: ${colors[index]}; margin-right: 5px;'}></span>
                    <span class=tooltipBodyText1 style="margin-right: 12px;">${city}</span>
                </span>
                <span class=tooltipBodyText1>${stackValues[index]}</span>
            </div>
            `,
            )
            .join("")}
    </div>
</div>`;

export function getTooltip(variant, props) {
    switch (variant) {
        case "v1":
            return tooltipVersion1(props);
        case "v2":
            return tooltipVersion2(props);
        case "v3":
            return tooltipVersion3(props);
        case "v4":
            return tooltipVersion4(props);
        default:
            throw new Error("Invalid variant");
    }
}
