import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Paper, Box, TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { navigate } from "@gatsbyjs/reach-router";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { newPasswordSchema } from "./NewPasswordSchema";
import { resetPassword } from "../../../actions/user/user";
import { userSliceSelectors, clearPasswordReset } from "../../../slices/User/userSlice";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import logo from "../../../osterus.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        height: "100vh",
    },
    title: {
        fontWeight: 500,
    },
    card: {
        width: 285,
        height: "auto",
        margin: "10% auto",
        padding: "50px 60px",
    },
    box: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: 150,
        marginBottom: theme.spacing(2),
    },
    form: {
        width: "100%",
    },
    icon: {
        fill: theme.palette.success.main,
    },
}));

export default function NewPassword() {
    const { token, resetted } = useSelector(userSliceSelectors.passwordReset);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        watch,
        clearErrors,
        formState: { isValid, errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(newPasswordSchema),
    });

    const passwordWatch = watch("password");
    const passwordConfirmWatch = watch("password_confirmation");

    useEffect(() => {
        if (passwordWatch === passwordConfirmWatch) {
            clearErrors(["password", "password_confirmation"]);
        }
    }, [passwordWatch, passwordConfirmWatch]);

    const onSubmit = (data) => {
        dispatch(resetPassword({ token, password: data.password, password_confirmation: data.password_confirmation }));
    };

    useEffect(() => {
        if (resetted) {
            dispatch(clearPasswordReset());
            navigate("/login");
        } else if (resetted === false) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Password reset request failed.",
                    visibilityTime: 6000,
                }),
            );
        }
    }, [resetted]);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box className={classes.container}>
            <Paper elevation={1} className={classes.card}>
                <Box className={classes.box}>
                    <img src={logo} className={classes.logo} alt="osterus logo" />
                    <Typography className={classes.title} variant="h4" color="primary" mb={1}>
                        Create New Password
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Box mt={4} mb={1}>
                            <TextField
                                {...register("password")}
                                required
                                id="password"
                                variant="outlined"
                                size="small"
                                label="Password"
                                sx={{ width: "100%" }}
                                error={Boolean(errors.password)}
                                type={showPassword ? "text" : "password"}
                                InputProps={
                                    isValid
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={2} mb={1}>
                            <TextField
                                {...register("password_confirmation")}
                                required
                                id="password_confirmation"
                                variant="outlined"
                                size="small"
                                label="Confirm Password"
                                sx={{ width: "100%" }}
                                error={Boolean(errors.password_confirmation)}
                                type={showPassword ? "text" : "password"}
                                InputProps={
                                    isValid
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <CheckCircleIcon className={classes.icon} />
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                        : {
                                              endAdornment: (
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="Toggle password visibility"
                                                          onClick={handleShowPassword}
                                                          edge="end"
                                                      >
                                                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                      </IconButton>
                                                  </InputAdornment>
                                              ),
                                          }
                                }
                            />
                            {errors.password_confirmation && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password_confirmation.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={2} className={classes.box}>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                type="submit"
                                sx={{ width: "100%" }}
                                disableElevation
                            >
                                Reset password
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Box>
    );
}
