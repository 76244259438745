import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTagsByFilterApi } from "../../services/api/tags/tags";

export const getTagsByFilter = createAsyncThunk("tags/getTagsByFilter", async (filter = {}) => {
    const params = {
        q: filter.hasOwnProperty("q") ? { name: filter.q } : {},
        start: 1,
        limit: 500,
    };

    let response = await getTagsByFilterApi(params);
    const data = response.data;
    const totalRecords = data.totalRecords;
    /* eslint-disable no-await-in-loop */
    while (data.records.length < totalRecords) {
        params.start += 1;
        response = await getTagsByFilterApi(params);
        data.records.push(...response.data.records);
    }
    /* eslint-enable no-await-in-loop */

    return data;
});
