import React, { useState, useEffect } from "react";
import { Paper, Typography, Button, TextField, Box, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "@gatsbyjs/reach-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { logIn } from "../../../services/api/Public/Login/loginActions";
import { autoInvite } from "../../../actions/user/user";
import logo from "../../../osterus.svg";
import { loginSchema } from "./validation/loginSchema";
import { inviteSchema } from "./validation/inviteSchema";
import { userSliceSelectors } from "../../../slices/User/userSlice";

const useStyles = makeStyles(() => ({
    authPaper: {
        height: "100%",
        minHeight: "95vh",
        padding: "60px",
    },
    logo: {
        width: "127px",
        marginBottom: "121px",
    },
    subtitle: {
        marginTop: "16px",
        marginBottom: "60px",
    },
    fullWidthField: {
        width: "100%",
    },
    buttons: {
        display: "flex",
        justifyContent: "space-between",
    },
    form: {
        pointerEvents: "none",
        opacity: 0.4,
    },
}));

export default function Auth() {
    const loginError = useSelector(userSliceSelectors.loginError);
    const { id } = useSelector(userSliceSelectors.defaultUserFields);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const [isInvited, setIsInvited] = useState(false);
    const [inviteCode, setInviteCode] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        if (code) {
            setIsInvited(true);
            setInviteCode(code);
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        resolver: yupResolver(!isInvited ? loginSchema : inviteSchema),
    });

    useEffect(() => {
        if (loginError) {
            setError("email", { message: "Invalid credentials", type: "manual" });
            setError("password", { message: "Invalid credentials", type: "manual" });
        }
    }, [loginError]);

    const onSubmit = async (data) => {
        if (!isInvited) {
            setIsSubmitting(true);
            dispatch(logIn([data.email, data.password]));
            setIsSubmitting(false);
        } else {
            dispatch(autoInvite({ email: data.email, code: inviteCode, onDone: onInvited }));
        }
    };

    const onInvited = (failed) => {
        if (failed) {
            setError("email", { type: "custom", message: failed });
        } else {
            navigate("/account/invited");
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordReset = () => {
        navigate("/account/send-password-reset");
    };

    return (
        <Paper className={classes.authPaper} elevation={4}>
            <img src={logo} className={classes.logo} alt="osterus logo" />
            {!isInvited ? (
                <>
                    <Typography variant="h4">Log In to Osterus</Typography>
                    <Typography className={classes.subtitle}>
                        Happy to see you again! Please enter your credentials.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography variant="h4">Get Access to Osterus</Typography>
                    <Typography className={classes.subtitle}>
                        Welcome! Please enter your email address to receive an invite.
                    </Typography>
                </>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className={isSubmitting || id ? classes.form : null}>
                {!isInvited ? (
                    <>
                        <Box mt={3} mb={1}>
                            <TextField
                                {...register("email")}
                                required
                                id="email-auth"
                                variant="outlined"
                                size="small"
                                label="Email"
                                className={classes.fullWidthField}
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mt={3} mb={2}>
                            <TextField
                                {...register("password")}
                                required
                                id="password-auth"
                                variant="outlined"
                                size="small"
                                label="Password"
                                className={classes.fullWidthField}
                                type={showPassword ? "text" : "password"}
                                error={Boolean(errors.password)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabindex="-1"
                                                aria-label="Toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                                size="large"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errors.password && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.password.message}
                                </Typography>
                            )}
                        </Box>
                        <Box mb={2}>
                            <Link variant="pointer" underline="none" onClick={handlePasswordReset}>
                                <Typography color="secondary.400" variant="caption2">
                                    Forgot Password?
                                </Typography>
                            </Link>
                        </Box>
                        <Box className={classes.buttons}>
                            <Button
                                disabled={
                                    (Object.keys(errors).length > 0 &&
                                        !(errors?.password?.type === "manual" || errors?.email?.type === "manual")) ||
                                    isSubmitting ||
                                    id
                                }
                                color="primary"
                                variant="contained"
                                size="small"
                                type="submit"
                                disableElevation
                            >
                                Log In
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box mt={3} mb={4}>
                            <TextField
                                {...register("email")}
                                id="email-auth"
                                required
                                variant="outlined"
                                size="small"
                                label="Email"
                                className={classes.fullWidthField}
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <Typography color="error" variant="subtitle2">
                                    {errors.email.message}
                                </Typography>
                            )}
                        </Box>
                        <Box className={classes.buttons}>
                            <Button color="primary" variant="contained" size="small" type="submit" disableElevation>
                                Send Invite
                            </Button>
                        </Box>
                    </>
                )}
            </form>
        </Paper>
    );
}
