import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Paper, Stack } from "@mui/material";
import CustomTooltip from "../../../../../../../CustomTooltip";
import { SpeedDialMenu } from "../SpeedDialMenu";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: "#fff",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "4px 4px 8px 8px",
        padding: "10.5px 12px 15px 10px",
        paddingTop: 12,
        paddingLeft: 12,
        paddingBottom: 6,
        transition: "filter .1s",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        borderRadius: "5px 5px 0 0",
    },
    chartContainer: {
        width: "100%",
        height: "100%",
        paddingTop: "15px",
    },
    titleBox: {
        maxWidth: "75%",
    },
    titleTypography: {
        cursor: "pointer",
    },
}));

export const BoxContainer = React.forwardRef(
    (
        {
            containerStyles,
            chartInfo,
            children,
            showActions = true,
            data = {},
            loadedItems,
            zoomIn,
            zoomOut,
            minLimit,
            maxLimit,
            barchartref,
            showTableView = true,
            page,
            nextPage,
            previousPage,
            lastPage,
            elevation = 1,
            tableViewProps,
            titleStyles,
            titleTypographyStyle,
            actionsOffset,
        },
        ref,
    ) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [menuOpen, setMenuOpen] = useState(false);
        const { title, shortTitle, description, subscript } = chartInfo;
        const classes = useStyles();

        return (
            <Paper
                ref={ref}
                elevation={elevation}
                className={classes.container}
                onMouseOver={() => setMenuOpen(true)}
                onMouseLeave={() => {
                    setAnchorEl(null);
                    setMenuOpen(false);
                }}
                onClick={() => anchorEl && setAnchorEl(null)}
            >
                <div className={classes.header} style={titleStyles}>
                    <div className={classes.titleBox}>
                        {description ? (
                            <CustomTooltip
                                title={title}
                                message={description}
                                placement="bottom-end"
                                element={
                                    <Stack direction="row" alignItems="baseline">
                                        <Typography
                                            variant="h5"
                                            color="textSecondary"
                                            className={classes.titleTypography}
                                            mr={0.5}
                                            noWrap
                                        >
                                            {shortTitle}
                                        </Typography>

                                        <Typography variant="subscript" color="textSecondary" noWrap>
                                            {subscript}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        ) : (
                            <Stack direction="row" alignItems="baseline">
                                <Typography
                                    variant="h5"
                                    color="textSecondary"
                                    className={classes.titleTypography}
                                    mr={0.5}
                                    noWrap
                                    style={titleTypographyStyle}
                                >
                                    {shortTitle}
                                </Typography>

                                <Typography variant="subscript" color="textSecondary" noWrap>
                                    {subscript}
                                </Typography>
                            </Stack>
                        )}
                    </div>
                    {showActions && (
                        <SpeedDialMenu
                            open={menuOpen}
                            data={data}
                            loadedItems={loadedItems}
                            zoomIn={zoomIn}
                            zoomOut={zoomOut}
                            minLimit={minLimit}
                            maxLimit={maxLimit}
                            barchartref={barchartref}
                            showTableView={showTableView}
                            closeMenu={() => setMenuOpen(false)}
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            page={page}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            lastPage={lastPage}
                            tableViewProps={tableViewProps}
                            actionsOffset={actionsOffset}
                        />
                    )}
                </div>
                <div className={classes.chartContainer} style={containerStyles}>
                    {children}
                </div>
            </Paper>
        );
    },
);
