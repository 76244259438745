import React, { createRef, useEffect, useState } from "react";
import { Chip, Typography, Rating } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isNumber } from "lodash";
import Flag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
    filterSection: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    chipsRow: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    chipsRowCollapsed: {
        display: "flex",
        width: "100%",
        alignItems: "space-between",
        flexWrap: "wrap",
        marginTop: theme.spacing(0.5),
        gap: theme.spacing(0.5),
    },
    chip: {
        marginRight: theme.spacing(1),
    },

    viewAll: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
    viewCollapse: {
        cursor: "pointer",
    },
    rating: {
        marginTop: 9,
    },
}));

const iconStyle = {
    marginLeft: 8,
};

const useRefRowProps = (ref) => {
    const [rowWidth, setRowWidth] = useState(0);
    const [rowChildren, setRowChildren] = useState([]);
    useEffect(() => {
        if (ref.current) {
            const { current } = ref;
            const width = current.getBoundingClientRect().width;
            const children = current.children;
            setRowWidth(width);
            setRowChildren(children);
        }
    }, [ref]);
    return [rowWidth, rowChildren];
};

function FilterChips({ showRow, setShowRow, filterData, onRemoveFilterItem }) {
    const [isRowFull, setIsRowFull] = useState(false);
    const [filterChips, setFilterChips] = useState(null);
    const rowRef = createRef();
    const [rowWidth, rowChildren] = useRefRowProps(rowRef);

    const classes = useStyles();

    // determine and divide filter data in two arrays if row needs to break
    const formatFilterData = (data) => {
        if (data.length > 3) {
            let rowBreakIndex = 0;
            let totalChipsWidth = 0;
            for (let index = 0; index < data.length; index += 1) {
                const childEl = rowChildren[index];
                if (isNumber(data[index])) {
                    totalChipsWidth += 116 + 8;
                } else {
                    totalChipsWidth += childEl
                        ? childEl.getBoundingClientRect().width + 4
                        : data[index].name.length * 9.25 + 8;
                }
                const spaceLeft = rowWidth - 100 - totalChipsWidth;
                if (spaceLeft < 0) {
                    rowBreakIndex = index;
                    break;
                }
                if (!childEl && showRow) {
                    rowBreakIndex = index;
                    break;
                }
            }

            if (rowBreakIndex !== 0) {
                setIsRowFull(true);
                return {
                    firstRow: [...data.slice(0, rowBreakIndex)],
                    secondRow: [...data.slice(rowBreakIndex)],
                };
            }
        }
        setIsRowFull(false);
        setShowRow(false);
        return { firstRow: data };
    };

    // format filter data every time row width or filter data changes
    useEffect(() => {
        if (filterData instanceof Array) {
            const data = formatFilterData(filterData);
            setFilterChips(data);
        }
    }, [rowChildren, filterData]);

    return (
        <>
            {filterChips?.firstRow?.length > 0 && (
                <div className={classes.filterSection}>
                    <div ref={rowRef} className={classes.chipsRow}>
                        {filterChips.firstRow.map((item, index) => (
                            <Chip
                                key={index}
                                className={classes.chip}
                                size="small"
                                label={
                                    item.name || (
                                        <Rating value={item} className={classes.rating} size="small" readOnly />
                                    )
                                }
                                onDelete={() => onRemoveFilterItem(item)}
                                icon={
                                    item?.iso2 || item?.country?.iso2 ? (
                                        <Flag countryCode={item.iso2 || item.country.iso2} svg style={iconStyle} />
                                    ) : null
                                }
                            />
                        ))}
                        {isRowFull && !showRow && (
                            <div onClick={() => setShowRow(true)} className={classes.viewAll}>
                                <Typography variant="caption" color="secondary" className={classes.viewCollapse}>
                                    view all
                                </Typography>
                            </div>
                        )}
                    </div>
                    {showRow && (
                        <div className={classes.chipsRowCollapsed}>
                            {filterChips.secondRow?.length > 0 &&
                                filterChips.secondRow.map((item, index) => (
                                    <Chip
                                        key={index}
                                        className={classes.chip}
                                        size="small"
                                        label={
                                            item.name || (
                                                <Rating value={item} className={classes.rating} size="small" readOnly />
                                            )
                                        }
                                        onDelete={() => onRemoveFilterItem(item)}
                                        icon={
                                            item?.iso2 || item?.country?.iso2 ? (
                                                <Flag countryCode={item.iso2 || item.country.iso2} svg />
                                            ) : null
                                        }
                                    />
                                ))}
                            <div onClick={() => setShowRow(false)}>
                                <Typography variant="caption" color="secondary" className={classes.viewCollapse}>
                                    collapse
                                </Typography>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default FilterChips;
