import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { ChartContainer } from "./ChartContainer";
import StackedBarCategories from "./StackedBarCategories";
import BarChartWithMarkers from "./BarChartWithMarkers";
import ChartLegend from "./ChartLegend";

export default function MarkersBarWithCategories({
    categories,
    series,
    title = "",
    subscript = "",
    stackname,
    colors,
    chartStyle,
    categoriesStyle,
    containerStyle,
    chartHeight,
    showActions,
    inPercentage = false,
    legend,
    legendStyle,
    categoriesLabel,
}) {
    const maxValue = useMemo(
        () =>
            Math.max(
                ...series.map((item) =>
                    item.data.reduce((prev, cur) => {
                        if (cur.y !== undefined) return Math.max(prev, cur.y);
                        return Math.max(prev, cur);
                    }, 0),
                ),
            ),
        [series],
    );

    return (
        <ChartContainer
            chartInfo={{ title, shortTitle: title, subscript }}
            containerStyles={{ padding: 0, margin: 0 }}
            showActions={showActions}
            actionsOffset={{ y: -55 }}
        >
            <Stack flex={1}>
                {legend && <ChartLegend legend={legend} legendStyle={legendStyle} />}
                <Stack direction="row" mt={3.5} {...chartStyle}>
                    <Stack>
                        <StackedBarCategories
                            categories={categories}
                            categoriesStyle={categoriesStyle}
                            containerStyle={containerStyle}
                        />
                        {categoriesLabel && (
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                textAlign={categoriesStyle?.textAlign || "center"}
                            >
                                {categoriesLabel}
                            </Typography>
                        )}
                    </Stack>
                    <Stack flex={1}>
                        <BarChartWithMarkers
                            title={stackname}
                            colors={colors}
                            categories={categories}
                            series={series}
                            padding={{ left: -3, top: -35, bottom: -20, right: 15 }}
                            height={chartHeight}
                            max={maxValue}
                            maxMultiplier={1.1}
                            inPercentage={inPercentage}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </ChartContainer>
    );
}
