import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import SearchComponent from "../../../Search";
import OrganizationAutocomplete from "../../../AutocompleteInputs/OrganizationAutocomplete";
import { adminSliceSelectors, setOrganizationsFilter } from "../../../../slices/Admin/adminSlice";
import IndustryAutocomplete from "../../../AutocompleteInputs/IndustryAutocomplete";
import PricingAutocomplete from "../../../AutocompleteInputs/PricingAutocomplete.js";
import PlansAutocomplete from "../../../AutocompleteInputs/PlansAutocomplete";

export default function Filter() {
    const filter = useSelector(adminSliceSelectors.organizationsFilter);
    const dispatch = useDispatch();

    const handleClearAll = () => {
        dispatch(
            setOrganizationsFilter({
                ...filter,
                search: filter.search,
                organization: "",
                price: "",
                plan: "",
                industry: "",
            }),
        );
    };

    const onSearch = (value) => {
        dispatch(setOrganizationsFilter({ ...filter, q: { ...filter.q, name: value } }));
    };

    const onOrganizationSelected = (organization) => {
        dispatch(setOrganizationsFilter({ ...filter, organization }));
    };

    const onIndustrySelected = (industry) => {
        dispatch(setOrganizationsFilter({ ...filter, industry }));
    };

    const onPriceSelected = (plan) => {
        dispatch(setOrganizationsFilter({ ...filter, price: plan }));
    };

    const onPlanSelected = (plan) => {
        dispatch(setOrganizationsFilter({ ...filter, plan }));
    };

    return (
        <Box p={2.5}>
            <SearchComponent value={filter?.q?.name} onSearch={onSearch} label="Search here..." />
            <Box mt={3} mb={3}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="text.onPrimary">
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                        <Button variant="text" color="primary" size="small" onClick={handleClearAll}>
                            CLEAR ALL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} mb={3}>
                <OrganizationAutocomplete
                    onSelected={onOrganizationSelected}
                    defaultValue={filter?.organization?.full_name}
                />
            </Box>
            <Box mt={3} mb={3}>
                <IndustryAutocomplete onSelected={onIndustrySelected} defaultValue={filter.industry} />
            </Box>
            <Box mt={3} mb={3}>
                <PricingAutocomplete
                    onSelected={onPriceSelected}
                    defaultValue={filter?.price && filter?.price[0]?.price ? `€${filter?.price[0]?.price}` : ""}
                />
            </Box>
            <Box mt={3} mb={3}>
                <PlansAutocomplete onSelected={onPlanSelected} defaultValue={filter?.plan?.name} />
            </Box>
        </Box>
    );
}
