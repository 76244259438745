import React, { useState, useEffect, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Button, InputAdornment, TextField, Typography, Stack, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OutsideClickHandler from "react-outside-click-handler";
import { yupResolver } from "@hookform/resolvers/yup";
import { textSchema, requiredTextSchema, emailSchema } from "./schemas";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 300,
    },
    containerMultiline: {
        width: 450,
    },
    valueRow: {
        display: "flex",
        cursor: "pointer",
    },
    valueText: {
        marginRight: theme.spacing(2),
    },
    button: {
        textTransform: "none",
    },
    icon: {
        width: 20,
        height: 20,
    },
}));

export const FIELD_TYPES = {
    text: "text",
    requiredText: "requiredText",
    email: "email",
};

export default function EditableField({
    name,
    type,
    value,
    onSubmit,
    hoverText,
    displayComponent,
    inputComponent,
    dropdownOptions,
    dialog,
    autocomplete,
    multiline,
    alignment = "baseline",
}) {
    const [isEditable, setIsEditable] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const fieldRef = useRef(null);
    const checkOutsideClick = !dropdownOptions;
    const inputValue = value === "N/A" ? null : value;
    const classes = useStyles();

    const schema = useMemo(() => {
        switch (type) {
            case FIELD_TYPES.text:
                return textSchema;
            case FIELD_TYPES.requiredText:
                return requiredTextSchema;
            case FIELD_TYPES.email:
                return emailSchema;
            default:
                return null;
        }
    }, [type]);

    const hookedInputComponent = useMemo(() => {
        if (!dialog && !autocomplete) return;

        const closeCallback = inputComponent.props.handleClose;
        const saveCallback = inputComponent.props.handleSave;
        const onChangeCallback = inputComponent.props.onChange;
        return {
            ...inputComponent,
            props: {
                ...inputComponent.props,
                handleClose: (...rest) => {
                    handleOutsideClick();
                    if (closeCallback) closeCallback(...rest);
                },
                handleSave: (...rest) => {
                    handleOutsideClick();
                    if (saveCallback) saveCallback([name, ...rest]);
                },
                onChange: (val, saved) => {
                    if (saved) {
                        handleOutsideClick();
                    }
                    if (onChangeCallback) onChangeCallback([name, val, saved]);
                },
            },
        };
    }, [inputComponent, dialog, autocomplete]);

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            [type]: inputValue,
        },
    });

    useEffect(() => {
        setValue(name, inputValue);
    }, [value]);

    const handleOutsideClick = (e) => {
        if (
            e?.composedPath &&
            e.composedPath().find((el) => el.className && el.className.indexOf("MuiAutocomplete-popper") !== -1)
        ) {
            return;
        }

        setIsHovered(false);
        setIsEditable(false);
    };

    const handleSave = (data) => {
        if (type) {
            const newValue = data[type];
            onSubmit([name, newValue]);
        } else {
            onSubmit([name, data]);
        }
        handleOutsideClick();
    };

    const handleMenuItemClicked = (selectedValue) => {
        onSubmit([name, selectedValue]);
        handleOutsideClick();
    };

    return (
        <div ref={fieldRef} className={inputValue && multiline ? classes.containerMultiline : classes.container}>
            {isEditable && dialog && hookedInputComponent}
            {!isEditable || dialog ? (
                <div
                    className={classes.valueRow}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => {
                        setIsEditable(true);
                        setValue(type, inputValue);
                        clearErrors();
                    }}
                >
                    <Stack direction="row" translate="no" alignItems={alignment}>
                        <Stack maxWidth={400}>
                            {displayComponent || <Typography variant="body2">{value}</Typography>}
                        </Stack>
                        {isHovered &&
                            (!dropdownOptions ? (
                                <Typography ml={2} color="secondary" fontWeight={700} variant="body2">
                                    {hoverText || "Edit"}
                                </Typography>
                            ) : (
                                <ArrowDropDownIcon className={classes.icon} />
                            ))}
                    </Stack>
                </div>
            ) : (
                <OutsideClickHandler onOutsideClick={checkOutsideClick ? handleOutsideClick : () => {}}>
                    {dropdownOptions ? (
                        <>
                            <Stack direction="row" translate="no" alignItems="center">
                                <Typography variant="body2">{value}</Typography>
                                <ArrowDropUpIcon className={classes.icon} />
                            </Stack>
                            <Menu
                                anchorEl={fieldRef.current}
                                open={isEditable}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                sx={{ maxHeight: 300 }}
                                onClose={handleOutsideClick}
                            >
                                {dropdownOptions.map((item, index) => (
                                    <MenuItem
                                        onClick={() => handleMenuItemClicked(item.value)}
                                        key={index}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                    ) : (
                        hookedInputComponent ||
                        inputComponent || (
                            <form onSubmit={handleSubmit(handleSave)}>
                                <TextField
                                    {...register(type)}
                                    multiline={multiline}
                                    fullWidth
                                    autoFocus
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Button type="submit" className={classes.button}>
                                                    <Typography
                                                        color={Object.keys(errors).length > 0 ? "error" : "secondary"}
                                                        fontWeight={500}
                                                        variant="body2"
                                                    >
                                                        Save
                                                    </Typography>
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={Object.keys(errors).length > 0}
                                    onClose={() => clearErrors()}
                                />
                            </form>
                        )
                    )}
                </OutsideClickHandler>
            )}
        </div>
    );
}
