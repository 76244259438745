import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        width: 950,
        margin: "auto",
        marginTop: 75,
        textAlign: "center",
        alignItems: "center",
    },
    title: {
        paddingBottom: 8,
    },
    suggestions: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    suggestionsList: {
        textAlign: "left",
        paddingLeft: 100,
    },
}));

export default function NoResult() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography variant="h4" className={classes.title}>
                Sorry, no results were found.
            </Typography>

            <Box className={classes.suggestionsList}>
                <Typography variant="body2" className={classes.suggestions}>
                    Search Suggestions:
                </Typography>
                <Typography>- Try shorter searches.</Typography>
                <Typography>- Check your spelling.</Typography>
                <Typography>- Try turning off active filters (if you set any).</Typography>
                <Typography>
                    - Try using the name of your desired search in its native language (for example “universität”
                    instead of “university”).
                </Typography>
            </Box>
        </Box>
    );
}
