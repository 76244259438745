import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import StackedBarChartWithDifference from "../../../MixedCharts/StackedBarChartWithDifference";
import MarkersBarChartWithDifference from "../../../MixedCharts/MarkersBarChartWithDifference";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors, getMarkersBarHeight } from "../../../utils";

const categoriesStyle = {
    fontSize: 12,
    marginBottom: 10.2,
};

const degreeStyle = {
    fontSize: 12,
    marginBottom: 9.4,
};

export function Section1({ data, stacks }) {
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);
    const appliedFilters = useSelector(stackInsightsSliceSelector.appliedFilters);
    const loadingInsights = useSelector(stackInsightsSliceSelector.loadingInsights);

    const [barColors, setBarColors] = useState(
        getColors(
            data.jobTitles.comparisonChart.stack1.series.map((serie) => serie.name),
            show,
            split,
        ),
    );
    const chartInfo = { title: "Job Title", shortTitle: "Job Titles" };

    useEffect(() => {
        setBarColors(
            getColors(
                data.jobTitles.comparisonChart.stack1.series.map((serie) => serie.name),
                show,
                split,
            ),
        );
    }, [show, split, data]);

    const averageWorkExperienceFormatedData = useMemo(
        () => [
            {
                data: data.averageWorkExperience.comparisonChart.stack1.series.data.map((item, index) => ({
                    x: data.averageWorkExperience.comparisonChart.categories[index],
                    y: item,
                    goals: !data.averageWorkExperience.comparisonChart.benchmark[index]
                        ? undefined
                        : [
                              {
                                  name: "Benchmark",
                                  value: data.averageWorkExperience.comparisonChart.benchmark[index],
                                  strokeWidth: 2,
                                  strokeDashArray: 2,
                                  strokeColor: "#f00",
                              },
                          ],
                })),
            },
            {
                data: data.averageWorkExperience.comparisonChart.stack2.series.data.map((item, index) => ({
                    x: data.averageWorkExperience.comparisonChart.categories[index],
                    y: item,
                    goals: !data.averageWorkExperience.comparisonChart.benchmark[index]
                        ? undefined
                        : [
                              {
                                  name: "Benchmark",
                                  value: data.averageWorkExperience.comparisonChart.benchmark[index],
                                  strokeWidth: 2,
                                  strokeDashArray: 2,
                                  strokeColor: "#f00",
                              },
                          ],
                })),
            },
        ],
        [data],
    );

    const averageWorkExperiencePerGenderFormatedData = useMemo(
        () => [
            {
                data: data.averageWorkExperiencePerGender.comparisonChart.stack1.series.data.map((item, index) => ({
                    x: data.averageWorkExperiencePerGender.comparisonChart.categories[index],
                    y: item,
                    goals:
                        data.averageWorkExperiencePerGender.comparisonChart.categories[index] === "Unknown" ||
                        !data.averageWorkExperiencePerGender.comparisonChart.benchmark[index]
                            ? undefined
                            : [
                                  {
                                      name: "Benchmark",
                                      value: data.averageWorkExperiencePerGender.comparisonChart.benchmark[index],
                                      strokeWidth: 2,
                                      strokeDashArray: 2,
                                      strokeColor: "#f00",
                                  },
                              ],
                })),
            },
            {
                data: data.averageWorkExperiencePerGender.comparisonChart.stack2.series.data.map((item, index) => ({
                    x: data.averageWorkExperiencePerGender.comparisonChart.categories[index],
                    y: item,
                    goals:
                        data.averageWorkExperiencePerGender.comparisonChart.categories[index] === "Unknown" ||
                        !data.averageWorkExperiencePerGender.comparisonChart.benchmark[index]
                            ? undefined
                            : [
                                  {
                                      name: "Benchmark",
                                      value: data.averageWorkExperiencePerGender.comparisonChart.benchmark[index],
                                      strokeWidth: 2,
                                      strokeDashArray: 2,
                                      strokeColor: "#f00",
                                  },
                              ],
                })),
            },
        ],
        [data],
    );

    const averageWorkExperiencePerDegreeFormatedData = useMemo(
        () => [
            {
                data: data.averageWorkExperiencePerDegree.comparisonChart.stack1.series.data.map((item, index) => ({
                    x: data.averageWorkExperiencePerDegree.comparisonChart.categories[index],
                    y: item,
                    goals: !data.averageWorkExperiencePerDegree.comparisonChart.benchmark[index]
                        ? undefined
                        : [
                              {
                                  name: "Benchmark",
                                  value: data.averageWorkExperiencePerDegree.comparisonChart.benchmark[index],
                                  strokeWidth: 2,
                                  strokeDashArray: 2,
                                  strokeColor: "#f00",
                              },
                          ],
                })),
            },
            {
                data: data.averageWorkExperiencePerDegree.comparisonChart.stack2.series.data.map((item, index) => ({
                    x: data.averageWorkExperiencePerDegree.comparisonChart.categories[index],
                    y: item,
                    goals: !data.averageWorkExperiencePerDegree.comparisonChart.benchmark[index]
                        ? undefined
                        : [
                              {
                                  name: "Benchmark",
                                  value: data.averageWorkExperiencePerDegree.comparisonChart.benchmark[index],
                                  strokeWidth: 2,
                                  strokeDashArray: 2,
                                  strokeColor: "#f00",
                              },
                          ],
                })),
            },
        ],
        [data],
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={8} height={540} style={{ paddingTop: 0 }}>
                <StackedBarChartWithDifference
                    chartInfo={chartInfo}
                    stacks={stacks}
                    firstStackSeries={data.jobTitles.comparisonChart.stack1.series.filter(
                        (serie) => serie.name !== "Total",
                    )}
                    secondStackSeries={data.jobTitles.comparisonChart.stack2.series.filter(
                        (serie) => serie.name !== "Total",
                    )}
                    categories={data.jobTitles.comparisonChart.categories}
                    differenceSeries={[data.jobTitles.diffChart.series]}
                    stackedBarColors={barColors}
                    distributed={split === SplitModes.none}
                    itemsPerPage={15}
                    inPercentage={show === ShowModes.percent}
                    activeCategories={loadingInsights ? null : appliedFilters.jobTitles}
                    paddingBottomPerItem={24.69}
                    xAxisOffsetPerItem={12.35}
                    categoriesStyle={{ marginBottom: 7.6 }}
                />
            </Grid>
            <Grid item xs={4} style={{ paddingTop: 0 }}>
                <Paper elevation={1} sx={{ height: 540 }}>
                    <Stack height="100%" justifyContent="flex-start">
                        <Stack height={170}>
                            <MarkersBarChartWithDifference
                                series={averageWorkExperienceFormatedData}
                                stacks={stacks}
                                categories={data.averageWorkExperience.comparisonChart.categories}
                                title="Average Work Experience"
                                shortTitle="Average Work Experience"
                                colors={[["#6080EA"], ["#4AC086"]]}
                                differenceSeries={[data.averageWorkExperience.diffChart.series]}
                                height={getMarkersBarHeight(
                                    data.averageWorkExperience.comparisonChart.categories.length,
                                )}
                                containerStyle={{ pt: 4 }}
                                categoriesStyle={categoriesStyle}
                                barheight="65%"
                                tooltips={[
                                    "Average Work Experience in Current Company",
                                    "Average Work Experience in Previous Companies",
                                    "Average Work Experience",
                                ]}
                                additionalCategories={[
                                    "Average Work Experience in Current Company",
                                    "Average Work Experience in Previous Companies",
                                    "Average Work Experience",
                                ]}
                            />
                        </Stack>
                        <Divider />
                        <Stack height={170}>
                            <MarkersBarChartWithDifference
                                series={averageWorkExperiencePerGenderFormatedData}
                                stacks={stacks}
                                categories={data.averageWorkExperiencePerGender.comparisonChart.categories}
                                title="Average Work Experience"
                                shortTitle="Average Work Experience"
                                subscript="per Gender"
                                colors={[
                                    getColors(
                                        data.averageWorkExperiencePerGender.comparisonChart.categories,
                                        ShowModes.percent,
                                        SplitModes.gender,
                                    ),
                                    getColors(
                                        data.averageWorkExperiencePerGender.comparisonChart.categories,
                                        ShowModes.percent,
                                        SplitModes.gender,
                                    ),
                                ]}
                                differenceSeries={[data.averageWorkExperiencePerGender.diffChart.series]}
                                height={getMarkersBarHeight(
                                    data.averageWorkExperiencePerGender.comparisonChart.categories.length,
                                )}
                                containerStyle={{ pt: 4 }}
                                categoriesStyle={categoriesStyle}
                                barheight="65%"
                            />
                        </Stack>
                        <Divider />
                        <Stack height={197}>
                            <MarkersBarChartWithDifference
                                series={averageWorkExperiencePerDegreeFormatedData}
                                stacks={stacks}
                                categories={data.averageWorkExperiencePerDegree.comparisonChart.categories}
                                title="Average Work Experience"
                                shortTitle="Average Work Experience"
                                subscript="per Degree"
                                colors={[
                                    ["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"],
                                    ["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"],
                                ]}
                                differenceSeries={[data.averageWorkExperiencePerDegree.diffChart.series]}
                                height={getMarkersBarHeight(
                                    data.averageWorkExperiencePerDegree.comparisonChart.categories.length,
                                )}
                                containerStyle={{ pt: 4 }}
                                categoriesStyle={degreeStyle}
                                barheight="66%"
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
