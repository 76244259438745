export const mapBackendValidationErrors = (action) => {
    if (action.payload?.errors && Object.keys(action.payload.errors).length > 0) {
        const {errors} = action.payload;
        let modifiedErrors = {};

        for (const [key, value] of Object.entries(errors)) {
            modifiedErrors = { ...modifiedErrors, [key.includes(".") ? key.split(".")[1] : key]: value[0] };
        }

        return modifiedErrors;
    } 
        return null;
    
};
