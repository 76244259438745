import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Box, Button, Grid, Typography, Autocomplete, TextField } from "@mui/material";
import OrganizationAutocomplete from "../../../AutocompleteInputs/OrganizationAutocomplete";
import SearchComponent from "../../../Search";
import { adminSliceSelectors, setUsersFilter } from "../../../../slices/Admin/adminSlice";
import { USER_TYPES, USER_STATUS } from "../../../../slices/User/userSlice";
import CityAutocomplete from "../../../AutocompleteInputs/CityAutocomplete";
import { clearState } from "../../../../slices/City/citySlice";

const typeOptions = ["Osterus Admin", "Client Admin", "User"];

const statusOptions = ["Active", "Pending", "Deactivated"];

export default function Filter() {
    const filter = useSelector(adminSliceSelectors.usersFilter);
    const [userType, setUserType] = useState("");
    const [userStatus, setUserStatus] = useState("");
    const dispatch = useDispatch();

    const [jobTitle, setJobTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [referralNote, setReferralNote] = useState("");

    useEffect(() => {
        dispatch(clearState());
    }, []);

    const onStatusChanged = (e, selectedStatus) => {
        let status;
        switch (selectedStatus) {
            case "Active":
                status = USER_STATUS.ACTIVE;
                break;
            case "Pending":
                status = USER_STATUS.PENDING;
                break;
            case "Deactivated":
                status = USER_STATUS.DEACTIVATED;
                break;
            // no default
        }
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, status } }));
        setUserStatus(selectedStatus);
    };

    const onTypeChanged = (e, selectedType) => {
        let type;
        switch (selectedType) {
            case "Osterus Admin":
                type = USER_TYPES.OSTERUS_ADMIN;
                break;
            case "Client Admin":
                type = USER_TYPES.CLIENT_ADMIN;
                break;
            case "User":
                type = USER_TYPES.USER;
                break;
            // no default
        }
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, type } }));
        setUserType(selectedType);
    };

    useEffect(() => {
        if (!filter.q.type) setUserType("");
    }, [filter]);

    const onOrganizationSelected = (organization) => {
        if (!organization) {
            dispatch(setUsersFilter({ ...filter, q: { ...filter.q, organization: "" } }));
            return;
        }

        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, organization } }));
    };

    const onSearch = (value) => {
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, name: value } }));
    };

    const onJobTitleSearch = (value) => {
        setJobTitle(value);
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, job_title: value } }));
    };

    const onDepartmentSearch = (value) => {
        setDepartment(value);
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, department: value } }));
    };

    const onReferralSearch = (value) => {
        setReferralNote(value);
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, referral_note: value } }));
    };

    const onBasedInChange = (value) => {
        dispatch(setUsersFilter({ ...filter, q: { ...filter.q, base_in: value.id } }));
    };

    const handleClearAll = () => {
        const name = filter.q.name;
        dispatch(
            setUsersFilter({
                ...filter,
                q: {
                    ...filter.q,
                    name,
                    status: "",
                    type: "",
                    organization: "",
                    job_title: "",
                    department: "",
                    base_in: "",
                    referral_note: "",
                },
            }),
        );
        dispatch(clearState());
        setDepartment("");
        setJobTitle("");
        setReferralNote("");
        setUserStatus("");
    };

    return (
        <Box p={2.5}>
            <SearchComponent value={filter?.q?.name} onSearch={onSearch} label="Search here..." />
            <Box mt={3} mb={3}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h6" color="text.onPrimary">
                            Filters
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="column" alignItems="flex-end" justifyContent="flex-start">
                        <Button variant="text" color="primary" size="small" onClick={handleClearAll}>
                            CLEAR ALL
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} mb={3}>
                <TextField color="secondary" label="Access Level" margin="dense" size="small" fullWidth disabled />
            </Box>
            <Box mt={3} mb={3}>
                <Autocomplete
                    size="small"
                    fullWidth
                    options={statusOptions}
                    PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                    onChange={onStatusChanged}
                    value={userStatus}
                    renderInput={(params) => <TextField {...params} color="secondary" label="Status" margin="dense" />}
                />
            </Box>
            <Box mt={3} mb={3}>
                <OrganizationAutocomplete
                    defaultValue={filter.q.organization.full_name}
                    onSelected={onOrganizationSelected}
                />
            </Box>
            <Box mt={3} mb={3}>
                <TextField
                    value={jobTitle}
                    onChange={(e) => onJobTitleSearch(e.target.value)}
                    label="Job Title"
                    size="small"
                    color="secondary"
                    fullWidth
                />
            </Box>
            <Box mt={3} mb={3}>
                <TextField
                    value={department}
                    onChange={(e) => onDepartmentSearch(e.target.value)}
                    label="Department"
                    size="small"
                    color="secondary"
                    fullWidth
                />
            </Box>
            <Box mt={3} mb={3}>
                <CityAutocomplete
                    label="Based in"
                    showError={false}
                    color="secondary"
                    fullWidth
                    className={undefined}
                    PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                    onChange={onBasedInChange}
                />
            </Box>
            <Box mt={3} mb={3}>
                <TextField
                    color="secondary"
                    label="Referral note"
                    margin="dense"
                    size="small"
                    fullWidth
                    disabled
                    value={referralNote}
                    onChange={(e) => onReferralSearch(e.target.value)}
                />
            </Box>
            <Box mt={3} mb={3}>
                <Autocomplete
                    size="small"
                    fullWidth
                    options={typeOptions}
                    PaperComponent={({ children }) => <Paper variant="autocompleteDropdown">{children}</Paper>}
                    onChange={onTypeChanged}
                    value={userType}
                    renderInput={(params) => <TextField {...params} color="secondary" label="Type" margin="dense" />}
                />
            </Box>
        </Box>
    );
}
