import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import image from "./assets/404600px.png";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginTop: 150,
        lineHeight: 2,
    },
    image: {
        margin: "auto",
        width: 250,
    },
    redirect: {
        color: "black",
    },
    textContent: {
        marginTop: theme.spacing(3),
    },
}));

export default function NotFound() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <img src={image} className={classes.logo} alt="404 page" />
            <Box className={classes.textContent}>
                <Typography variant="h3">Ooops! This doesn't look like anything to me.</Typography>
                <Typography variant="h4">
                    Go back to Osterus by clicking{" "}
                    <a href="/dashboard" className={classes.redirect}>
                        here.
                    </a>
                </Typography>
            </Box>
        </Box>
    );
}
