import * as yup from "yup";

export const textSchema = yup.object().shape({
    text: yup.string().trim(),
});

export const requiredTextSchema = yup.object().shape({
    requiredText: yup.string().trim().required(),
});

export const emailSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid Email address").required("Field is required"),
});
