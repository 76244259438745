import React, { useState, useEffect } from "react";
import { Typography, Box, Stack, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTooltip from "../../CustomTooltip";

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    item: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        marginBottom: 8,
    },
    blockWrapper: {
        position: "relative",
        width: "100%",
        maxWidth: 230,
        height: 22,
        backgroundColor: theme.palette.primary[50],
    },
    block: {
        position: "absolute",
        height: 22,
        textIndent: 3,
        whiteSpace: "nowrap",
        overflow: "hidden",
        transition: "width .4s, left .4s",
        zIndex: 1,
    },
    noContent: {
        width: "100%",
        maxWidth: 230,
        height: 22,
        backgroundColor: "#fcdad9",
    },
    value: {
        fontSize: (props) => props.fontSize || "8px",
        position: "absolute",
        top: (props) => props.top || "6px",
    },
    indicatorStart: {
        width: 24,
        height: 24,
    },
    indicatorEnd: {
        width: 50,
        height: 24,
    },
}));

const POS_FIXED = "fixed";
const POS_STICKY = "sticky";
const INDICATOR_START = "start";
const INDICATOR_END = "end";

export function MetricItem({
    name,
    values,
    subvalues,
    indicators,
    colors,
    barPercentages,
    variant = "bar",
    position = POS_FIXED,
    indicatorAlignment = INDICATOR_START,
    noContentStyle,
    tooltip,
    textStyles,
}) {
    const [animationStart, setAnimationStart] = useState(false);
    const classes = useStyles(textStyles);

    useEffect(() => {
        if (variant === "bar") {
            if (animationStart) setAnimationStart(false);
            setTimeout(() => {
                setAnimationStart(true);
            }, 1);
        } else setAnimationStart(false);
    }, [variant]);

    const leftBlock =
        variant === "bar" ? (
            <Stack mr={0.25} sx={{ maxWidth: 230, width: "100%" }} alignItems={subvalues ? "end" : "center"}>
                <Box className={classes.blockWrapper}>
                    <Box
                        className={classes.block}
                        sx={{
                            width: values[0] ? "100%" : "0%",
                            textAlign: "right",
                            display: values[0] ? "block" : "none",
                        }}
                    >
                        {values[0] && typeof values[0] !== "object" ? (
                            <Typography
                                variant="body3"
                                className={classes.value}
                                sx={{ right: "5px" }}
                                color={colors[0]}
                            >
                                {values[0]}
                            </Typography>
                        ) : values[0] ? (
                            <Stack direction="row" className={classes.value} right={5}>
                                {values[0].map((value, index) => (
                                    <Typography
                                        variant="body3"
                                        fontSize={textStyles.fontSize || 8}
                                        color={colors[0][index]}
                                        mr={0.25}
                                    >
                                        {value}
                                    </Typography>
                                ))}
                            </Stack>
                        ) : null}
                    </Box>
                    {values[0] && typeof values[0] !== "object" ? (
                        <Box
                            className={classes.block}
                            sx={{
                                width: values[0] && animationStart ? `${barPercentages[0]}%` : "0%",
                                backgroundColor: colors[0],
                                left: values[0] && animationStart ? `${100 - barPercentages[0]}%` : "100%",
                            }}
                        >
                            <Typography
                                variant="body3"
                                className={classes.value}
                                sx={{
                                    right: position === POS_FIXED ? "5px" : null,
                                    left: position === POS_STICKY ? "5px" : null,
                                }}
                                color="#fff"
                            >
                                {values[0]}
                            </Typography>
                        </Box>
                    ) : values[0] ? (
                        <Box
                            className={classes.block}
                            sx={{
                                width: values[0] && animationStart ? `${barPercentages[0]}%` : "0%",
                                backgroundColor: colors[0][0],
                                left: values[0] && animationStart ? `${100 - barPercentages[0]}%` : "100%",
                            }}
                        >
                            <Stack
                                direction="row"
                                className={classes.value}
                                right={position === POS_FIXED ? "5px" : null}
                                left={position === POS_STICKY ? "5px" : null}
                            >
                                {values[0].map((value, index) => (
                                    <Typography
                                        variant="body3"
                                        fontSize={textStyles.fontSize || 8}
                                        color={index === 0 ? "#fff" : colors[0][index]}
                                        mr={0.25}
                                    >
                                        {value}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    ) : null}
                    {!values[0] && (
                        <Fade in timeout={400}>
                            <Box className={classes.noContent} mr={0.25} style={noContentStyle} />
                        </Fade>
                    )}
                </Box>
                {subvalues && (
                    <Typography variant="caption3" color="textSecondary" mr={1}>
                        {subvalues[0]}
                    </Typography>
                )}
            </Stack>
        ) : (
            <Stack alignItems="end" mr={1.5} sx={{ maxWidth: 220, width: "100%" }}>
                <Stack alignItems="end">
                    <Typography variant="caption2" color={colors[0]}>
                        {values[0]}
                    </Typography>
                    {subvalues && (
                        <Typography variant="caption" color="textSecondary">
                            {subvalues[0]}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        );

    const rightBlock =
        variant === "bar" ? (
            <Stack ml={0.25} sx={{ maxWidth: 230, width: "100%" }} alignItems={subvalues ? "start" : "center"}>
                <Box className={classes.blockWrapper}>
                    <Box
                        className={classes.block}
                        sx={{
                            width: values[1] ? "100%" : "0%",
                            color: colors[1],
                        }}
                    >
                        {values[1] && typeof values[1] !== "object" ? (
                            <Typography variant="body3" className={classes.value} color={colors[1]}>
                                {values[1]}
                            </Typography>
                        ) : values[1] ? (
                            <Stack direction="row" className={classes.value}>
                                {values[1].map((value, index) => (
                                    <Typography
                                        variant="body3"
                                        fontSize={textStyles.fontSize || 8}
                                        color={colors[1][index]}
                                        mr={0.25}
                                    >
                                        {value}
                                    </Typography>
                                ))}
                            </Stack>
                        ) : null}
                    </Box>
                    {values[1] && typeof values[1] !== "object" ? (
                        <Box
                            className={classes.block}
                            sx={{
                                width: values[1] && animationStart ? `${barPercentages[1]}%` : "0%",
                                color: "#fff",
                                backgroundColor: colors[1],
                            }}
                        >
                            <Typography
                                variant="body3"
                                className={classes.value}
                                color="#fff"
                                sx={{ right: position === POS_STICKY ? "5px" : null }}
                            >
                                {values[1]}
                            </Typography>
                        </Box>
                    ) : values[1] ? (
                        <Box
                            className={classes.block}
                            sx={{
                                width: values[1] && animationStart ? `${barPercentages[1]}%` : "0%",
                                color: "#fff",
                                backgroundColor: colors[1][0],
                            }}
                        >
                            <Stack
                                direction="row"
                                className={classes.value}
                                right={position === POS_STICKY ? "5px" : null}
                            >
                                {values[1].map((value, index) => (
                                    <Typography
                                        variant="body3"
                                        fontSize={textStyles.fontSize || 8}
                                        color={index === 0 ? "#fff" : colors[1][index]}
                                        mr={0.25}
                                    >
                                        {value}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    ) : null}
                    {!values[1] && (
                        <Fade in timeout={400}>
                            <Box className={classes.noContent} ml={0.25} style={noContentStyle} />
                        </Fade>
                    )}
                </Box>
                {subvalues && (
                    <Typography variant="caption3" color="textSecondary" ml={1}>
                        {subvalues[1]}
                    </Typography>
                )}
            </Stack>
        ) : (
            <Stack ml={1.5} sx={{ maxWidth: 220, width: "100%" }}>
                <Stack>
                    <Typography variant="caption2" color={colors[1]}>
                        {values[1]}
                    </Typography>
                    {subvalues && (
                        <Typography variant="caption" color="textSecondary">
                            {subvalues[1]}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        );

    return (
        <div className={classes.itemContainer}>
            {tooltip ? (
                <CustomTooltip
                    title={tooltip.title}
                    message={tooltip.message}
                    placement="right"
                    element={
                        <Typography mb={1.2} variant="body2">
                            {name}
                        </Typography>
                    }
                />
            ) : name ? (
                <Typography mb={1.2} variant="body2">
                    {name}
                </Typography>
            ) : null}
            <div className={classes.item}>
                {indicatorAlignment === INDICATOR_END ? (
                    <Stack mr={2.5} direction="row" className={classes.indicatorEnd}>
                        {indicators && indicators[0]}
                    </Stack>
                ) : (
                    <Box mr={2.5} className={classes.indicatorStart}>
                        {indicators && indicators[0]}
                    </Box>
                )}

                {leftBlock}
                {rightBlock}

                {indicatorAlignment === INDICATOR_END ? (
                    <Stack ml={2.5} direction="row" className={classes.indicatorEnd}>
                        {indicators && indicators[1]}
                    </Stack>
                ) : (
                    <Box ml={2.5} className={classes.indicatorStart}>
                        {indicators && indicators[1]}
                    </Box>
                )}
            </div>
        </div>
    );
}
