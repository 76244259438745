import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, Button, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyIcon from "@mui/icons-material/Key";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table/Table";
import Filter from "./components/Filter";
import PaginationComponent from "../../Pagination";
import { GenerateInviteTokenModal } from "./components/GenerateInviteTokenModal";
import { ReferralDeleteModal } from "./components/ReferralDeleteModal";
import { ReferralEditModal } from "./components/ReferralEditModal";
import { Breadcrumbs } from "../../Header/components/Breadcrumbs";
import { adminSliceSelectors, setReferralsFilter, setReferralsTotalRecords } from "../../../slices/Admin/adminSlice";
import OsterusLoader from "../../Loader";
import { getReferrals } from "../../../actions/Admin/Referrals";
import { createReferralApi, updateReferralApi, deleteReferralApi } from "../../../services/api/Admin/referrals";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { COLUMN_TYPES } from "../../Table/columnTypes";

const useStyles = makeStyles((theme) => ({
    filtersPaper: {
        height: "100%",
        minHeight: "calc(100vh - 65px)",
    },
    background: {
        backgroundColor: theme.palette.background.default,
    },
}));

const breadcrumbComponents = {
    title: {
        text: "Referrals",
        color: "text.primary",
    },
    breadcrumbs: [
        {
            text: "Admin",
            color: "textSecondary",
            link: "/admin",
            icon: null,
        },
        {
            text: "Referrals",
            color: "text.selected",
            link: null,
            icon: null,
        },
    ],
};

export default function AdminReferralsComponent() {
    const loading = useSelector(adminSliceSelectors.referralsLoading);
    const data = useSelector(adminSliceSelectors.referralsData);
    const [dataSlice, setDataSlice] = useState([]);
    const totalRecords = useSelector(adminSliceSelectors.referralsTotalRecords);
    const filter = useSelector(adminSliceSelectors.referralsFilter);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [activeReferral, setActiveReferral] = useState(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(getReferrals({ start: 1 }));
    }, []);

    useEffect(() => {
        setDataSlice(data.slice(0, Math.min(filter.limit, data.length)));
    }, [data]);

    const handlePageChanged = (pageNum, start, end) => {
        dispatch(setReferralsFilter({ ...filter, start: pageNum }));
        dispatch(getReferrals({ start: pageNum }));
        setDataSlice(data.slice(start, end));
    };

    const onGenerateInviteLinkClicked = async (note) => {
        const response = await createReferralApi({ note });
        const referral = response.data;
        const displayedIndex = (filter.start - 1) * filter.limit;
        if (totalRecords > displayedIndex && totalRecords < displayedIndex + filter.limit) {
            setDataSlice([...dataSlice, referral]);
        }
        dispatch(setReferralsTotalRecords(totalRecords + 1));

        dispatch(
            addMessage({
                type: "success",
                title: "Invite link generated",
                message: "Invite link successfully generated.",
                visibilityTime: 5000,
            }),
        );

        return response.data;
    };

    const onReferralEdit = async (index) => {
        const referral = dataSlice[index];
        setActiveReferral(referral);
        setEditModalOpen(true);
    };

    const onReferralDelete = async (index) => {
        const referral = dataSlice[index];
        setActiveReferral(referral);
        setDeleteModalOpen(true);
    };

    const onReferralEdited = async (newNote) => {
        try {
            const response = await updateReferralApi(activeReferral.id, { note: newNote });
            dispatch(
                addMessage({
                    type: "success",
                    title: "Referral updated",
                    message: "Referral successfully updated.",
                    visibilityTime: 5000,
                }),
            );
            setDataSlice(
                dataSlice.map((item) => {
                    if (item.id === activeReferral.id) return response.data;
                    return item;
                }),
            );
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Failed to update the referral",
                    message: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }

        setEditModalOpen(false);
    };

    const onReferralDeleted = async () => {
        try {
            await deleteReferralApi(activeReferral.id);
            dispatch(
                addMessage({
                    type: "success",
                    title: "Referral deleted",
                    message: "Referral successfully deleted.",
                    visibilityTime: 5000,
                }),
            );
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "Failed to deleted the referral",
                    message: "Something went wrong.",
                    visibilityTime: 5000,
                }),
            );
        }

        setDeleteModalOpen(false);
        const pageToFetch = dataSlice.length === 1 ? Math.max(filter.start - 1, 1) : filter.start;
        if (pageToFetch === Math.ceil(totalRecords / filter.limit)) {
            // Last page, we don't have fetch data again
            setDataSlice(dataSlice.filter((item) => item.id !== activeReferral.id));
            dispatch(setReferralsTotalRecords(totalRecords - 1));
        } else {
            dispatch(setReferralsFilter({ ...filter, start: pageToFetch }));
            dispatch(getReferrals({ start: pageToFetch }));
        }
    };

    const columns = useMemo(
        () => [{ name: "Note", align: "left" }, { name: "Status", type: COLUMN_TYPES.ICON }, { name: "Invite Link" }],
        [],
    );

    const rows = useMemo(
        () =>
            dataSlice.map((item, index) => {
                const actions = [
                    {
                        icon: <ContentCopyIcon />,
                        tooltip: "Copy Invite Link",
                        callback: () => navigator.clipboard.writeText(dataSlice[index].url),
                    },
                    { icon: <EditIcon />, tooltip: "Edit Referral", callback: () => onReferralEdit(index) },
                    { icon: <DeleteIcon />, tooltip: "Delete Referral", callback: () => onReferralDelete(index) },
                ];
                const expiryIcon = !item.is_expired ? <KeyIcon color="success" /> : <KeyOffIcon color="error" />;
                return { data: [item.note, expiryIcon, item.url], actions };
            }),
        [dataSlice],
    );

    return (
        <Grid container>
            <Grid item xs={2}>
                <Paper className={classes.filtersPaper} elevation={0} variant="sidebar">
                    <Filter />
                </Paper>
            </Grid>
            <Grid item xs={10} className={classes.background}>
                <Stack px={3} pt={2}>
                    <Stack mb={3}>
                        <Grid container mb={1}>
                            <Grid item xs={9}>
                                <Breadcrumbs components={breadcrumbComponents} />
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    disableElevation
                                    onClick={() => setInviteModalOpen(true)}
                                >
                                    Generate Invite Link
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                    </Stack>
                    {loading ? (
                        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 473 }}>
                            <OsterusLoader />
                        </Stack>
                    ) : (
                        <>
                            {totalRecords !== null && totalRecords > 0 ? (
                                <>
                                    <Table columns={columns} rows={rows} actionsStyles={{ minWidth: 180 }} />
                                    <PaginationComponent
                                        page={filter.start}
                                        totalRecords={totalRecords}
                                        limit={filter.limit}
                                        onChange={handlePageChanged}
                                    />
                                </>
                            ) : (
                                <Table columns={columns} rows={rows} />
                            )}
                        </>
                    )}
                </Stack>
            </Grid>
            <GenerateInviteTokenModal
                open={inviteModalOpen}
                onGenerate={onGenerateInviteLinkClicked}
                onClose={() => setInviteModalOpen(false)}
                onCancel={() => setInviteModalOpen(false)}
            />
            <ReferralDeleteModal
                open={deleteModalOpen}
                onDelete={onReferralDeleted}
                onCancel={() => setDeleteModalOpen(false)}
            />
            <ReferralEditModal
                referral={activeReferral}
                open={editModalOpen}
                onSave={onReferralEdited}
                onCancel={() => setEditModalOpen(false)}
            />
        </Grid>
    );
}
