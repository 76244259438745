import React, { useMemo, useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import StackedBarCategories from "./StackedBarCategories";
import { ChartContainer } from "./ChartContainer";
import {
    stackInsightsSliceSelector,
    ShowModes,
    SplitModes,
} from "../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        width: "100%",
        height: 50,
        background: "#F5F5F5",
        alignItems: "center",
    },
    titleBox: {
        width: "100%",
    },
    titleTypography: {
        cursor: "pointer",
        paddingLeft: ({ paddingLeft }) => paddingLeft,
        paddingRight: ({ paddingRight }) => paddingRight,
    },
    axisLabel: {
        color: "red",
        background: "green",
        fill: "green",
    },
}));

const generateChartOptions = ({
    series,
    reversed,
    barColors,
    show,
    categories,
    stackname,
    padding,
    xAxisOffset,
    showTitle,
    maxValue,
}) => {
    const classes = useStyles();
    const split = useSelector(stackInsightsSliceSelector.split);

    const chartMaxValue = useMemo(() => {
        if (maxValue) return maxValue;

        let max = 0;
        for (let i = 0; i < categories.length; i += 1) {
            const rowSum = series.reduce((prev, cur) => prev + cur.data[i], 0);
            max = Math.max(max, rowSum);
        }
        return max;
    }, [series]);

    return {
        colors: barColors,
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                colors: {
                    backgroundBarColors: new Array(categories.length)
                        .fill("transparent")
                        .fill(showTitle ? "transparent" : "transparent", 0, 1),
                },
                barHeight: "67%",
                horizontal: true,
                borderRadius: [2],
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
            },
        },
        dataLabels: {
            enabled: showTitle,
            textAnchor: reversed ? "end" : "start",
            enabledOnSeries: [series.length - 1],
            style: {
                colors: ["#7B7B7B"],
            },

            // Apexcharts won't update the chart according to specified options if nothing in those options
            // has changed. Therefore we need to change at least 1 thing in order to force the update.
            // In this particular case, it's needed because formatter needs to know the latest max value.
            offsetX: Math.random(),

            formatter: (value, { dataPointIndex: index, w }) => {
                const allSeries = w.config.series;
                const sumPercent = allSeries.reduce((prev, cur) => prev + cur.data[index], 0);
                const lastVal = allSeries[allSeries.length - 1].data[index];

                // \u2000 = one of the Unicode spaces(apexcharts library trims regular spaces).
                let spacing;
                if (sumPercent === 0) {
                    spacing = "";
                } else if (lastVal / chartMaxValue < 0.01) {
                    spacing = "\u2000\u2000";
                } else {
                    spacing = "\u2000";
                }

                if (reversed)
                    return show === ShowModes.durationYears
                        ? `${sumPercent.toFixed(2)}${spacing}`
                        : `${sumPercent.toFixed(2)}%${spacing}`;
                return show === ShowModes.durationYears
                    ? `${spacing}${sumPercent.toFixed(2)}`
                    : `${spacing}${sumPercent.toFixed(2)}%`;
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: false,
        },
        grid: {
            padding: {
                ...padding,
                top: showTitle ? -33.5 : -33,
                right: reversed ? -15 : 13,
                left: reversed ? 4 : -5,
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
        xaxis: {
            categories,
            tickAmount: 2,
            max: Math.ceil(chartMaxValue * 1.1),
            labels: {
                offsetY: xAxisOffset,
                show: true,
                style: {
                    colors: ["#ABABAB"],
                },
                formatter: (value) =>
                    show === ShowModes.durationYears
                        ? value === 0 || value === 1
                            ? value.toFixed(0)
                            : value
                        : value === 0 || value === 1
                        ? `${value.toFixed(0)}%`
                        : `${value}%`,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            tickAmount: 2,
            max: Math.ceil(chartMaxValue * 1.1),
            reversed,
            opposite: reversed,
            show: true,
            labels: {
                show: false,
                formatter: (value) => value,
                offsetY: 3,
                style: {
                    cssClass: classes.axisLabel,
                },
            },
        },
        tooltip: {
            inverseOrder: true,
            shared: true,
            intersect: false,
            x: {
                formatter: (value, { dataPointIndex }) =>
                    split === SplitModes.none ? stackname : categories[dataPointIndex],
            },
            y: {
                formatter: (value) => (show === ShowModes.percent ? `${value}%` : `${value} years`),
                title: {
                    formatter: (seriesName, { seriesIndex, dataPointIndex }) => {
                        if (!series[seriesIndex].name) {
                            return categories[dataPointIndex];
                        }
                        return seriesName;
                    },
                },
            },
        },
        annotations: {
            xaxis: [
                {
                    x: reversed ? -0.2 : 0,
                    strokeDashArray: 0,
                    borderColor: "#fff",
                    borderWidth: 3,
                    opacity: 1,
                },
            ],
        },
    };
};

export default function StackedBarWithActions({
    series,
    categories,
    reversed = false,
    stackname,
    barColors,
    title,
    itemsPerPage,
    showTitle = true,
    pagination = true,
    categoriesStyle,
    maxValue,
    showJobTitles,
    isHovered,
    zIndex = 1,
}) {
    const show = useSelector(stackInsightsSliceSelector.show);
    const split = useSelector(stackInsightsSliceSelector.split);
    const [page, setPage] = useState(0);
    const [newCategories, newSeries] = useMemo(() => {
        if (!itemsPerPage || !pagination) {
            return [categories, series];
        }
        const tempCategories = [...categories].slice(page * itemsPerPage, (page + 1) * itemsPerPage);
        const tempSeries = series.map((item) => ({
            name: item.name,
            data: [...item.data].slice(page * itemsPerPage, (page + 1) * itemsPerPage),
        }));
        return [tempCategories, tempSeries];
    }, [page, series, categories]);

    const missingItems = itemsPerPage - newSeries[0].data.length;
    const dataPointPadding = itemsPerPage === 5 ? 24.6 : 24.46;
    const dataPointXAxisOffset = 12.25;
    const padding = { bottom: missingItems * dataPointPadding };
    const xAxisOffset = missingItems * dataPointXAxisOffset;

    const options = generateChartOptions({
        series,
        reversed,
        barColors,
        show,
        categories,
        stackname,
        padding,
        xAxisOffset,
        showTitle,
        maxValue,
    });

    const containerStyleStandard = {
        width: 150,
        zIndex: 1,
        marginRight: -1.35,
    };

    const containerStyleReversed = {
        width: 150,
        zIndex: 1,
        marginLeft: -1.35,
    };

    useEffect(() => {
        setPage(0);
    }, [stackname, split, show, showJobTitles]);

    return (
        <ChartContainer variant="plain" title={title} reversed={reversed} showTitle>
            <Stack height="100%" direction="row" onMouseOver={isHovered} zIndex={zIndex}>
                {!reversed && (
                    <StackedBarCategories
                        categories={newCategories}
                        containerStyle={containerStyleStandard}
                        categoriesStyle={categoriesStyle}
                        align="right"
                    />
                )}
                <Stack flex={1}>
                    <Chart
                        options={options}
                        series={newSeries}
                        type="bar"
                        width="100%"
                        height={itemsPerPage === 5 ? "100%" : 132.9}
                        stackname={stackname}
                    />
                </Stack>
                {reversed && (
                    <StackedBarCategories
                        categories={newCategories}
                        containerStyle={containerStyleReversed}
                        categoriesStyle={categoriesStyle}
                        align="left"
                    />
                )}
            </Stack>
        </ChartContainer>
    );
}
