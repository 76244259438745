import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
    SpeedDial,
    SpeedDialAction,
    Box,
    Popper,
    List,
    ListItemButton,
    ListItemText,
    ClickAwayListener,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
// import DownloadIcon from "@mui/icons-material/Download";
import TableViewIcon from "@mui/icons-material/TableView";
// import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import SortIcon from "@mui/icons-material/Sort";
import TableView from "../../../TableView";

const useStyles = makeStyles((theme) => ({
    list: {
        boxShadow: theme.shadows[3],
        marginTop: 5,
        background: "white",
    },
    listButton: {
        "&:hover": {
            background: "#D1DBFF",
        },
    },
}));

export function SpeedDialMenu({
    open,
    data,
    zoomIn,
    zoomOut,
    loadedItems,
    minLimit,
    maxLimit,
    enableZoom = false,
    barchartref,
    closeMenu,
    anchorEl,
    // setAnchorEl,
    showTableView,
    showOptions,
    onShowOptionClicked,
    splitByOptions,
    onSplitByOptionClicked,
    page,
    nextPage,
    previousPage,
    lastPage,
    tableViewProps,
    actionsOffset = { x: 25, y: -70, left: "unset" },
}) {
    const classes = useStyles();
    const [tableViewModal, setTableViewModal] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState("svg");
    const popperOpen = Boolean(anchorEl);
    const donwloadId = open ? "simple-popper" : undefined;
    const showAnchorRef = useRef(null);
    const [showOpen, setShowOpen] = useState(false);
    const splitByAnchorRef = useRef(null);
    const [splitByOpen, setSplitByOpen] = useState(false);

    const handleDownloadChart = () => {
        switch (downloadFormat) {
            case "svg":
                barchartref.current.chart.ctx.exports.exportToSVG();
                break;
            case "png":
                barchartref.current.chart.ctx.exports.exportToPng();
                break;
            case "csv":
                barchartref.current.chart.ctx.exports.exportToCSV({
                    series: barchartref.current.props.series,
                    columnDelimiter: ",",
                });
                break;
            // no default
        }
    };

    useEffect(() => {
        if (!open) {
            setShowOpen(false);
        }
    }, [open]);

    const handleTableView = () => {
        closeMenu();
        setTableViewModal(!tableViewModal);
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setShowOpen(false);
                setSplitByOpen(false);
            }}
        >
            <Box sx={{ transform: "translateZ(0px)", flexGrow: 1, zIndex: 1 }}>
                <SpeedDial
                    ariaLabel="SpeedDial chart menu"
                    direction="left"
                    sx={{
                        position: "absolute",
                        top: actionsOffset.y || -70,
                        right: actionsOffset.x || 25,
                        left: actionsOffset.left,
                        "& .MuiSpeedDial-actions": {
                            padding: 0,
                        },
                    }}
                    hidden
                    open={open}
                >
                    {/* <SpeedDialAction
                            icon={<DownloadIcon />}
                            tooltipTitle="Download Chart"
                            onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
                            // disabled={location.pathname.includes("employment_comparison")}
                        /> */}

                    {showTableView && (
                        <SpeedDialAction
                            translate="no"
                            icon={<TableViewIcon />}
                            tooltipTitle="Table View"
                            onClick={() => handleTableView()}
                            disabled={!data || Object.keys(data).length < 1}
                        />
                    )}

                    {enableZoom && !Number.isNaN(maxLimit) && (
                        <SpeedDialAction
                            translate="no"
                            icon={<ZoomOutIcon />}
                            tooltipTitle="Zoom Out"
                            onClick={() => zoomOut()}
                            disabled={loadedItems >= maxLimit}
                        />
                    )}
                    {enableZoom && !Number.isNaN(minLimit) && (
                        <SpeedDialAction
                            translate="no"
                            icon={<ZoomInIcon />}
                            tooltipTitle="Zoom In"
                            onClick={() => zoomIn()}
                            disabled={loadedItems <= minLimit}
                        />
                    )}
                    {/* <SpeedDialAction
                        ref={showAnchorRef}
                        disabled={!showOptions}
                        icon={<SortIcon />}
                        tooltipTitle="Show"
                        onClick={() => setShowOpen(!showOpen)}
                    />
                    <SpeedDialAction
                        ref={splitByAnchorRef}
                        disabled={!splitByOptions}
                        icon={<HorizontalSplitIcon />}
                        tooltipTitle="Split By"
                        onClick={() => setSplitByOpen(!splitByOpen)}
                    /> */}

                    {page !== undefined && (
                        <SpeedDialAction
                            icon={<ArrowForwardIcon />}
                            tooltipTitle="Forward"
                            onClick={() => nextPage()}
                            disabled={!nextPage || page === lastPage - 1}
                        />
                    )}

                    {page !== undefined && (
                        <SpeedDialAction
                            icon={<ArrowBackIcon />}
                            tooltipTitle="Back"
                            onClick={() => previousPage()}
                            disabled={!previousPage || page === 0}
                        />
                    )}
                </SpeedDial>
                {tableViewModal && (
                    <TableView
                        open={tableViewModal}
                        handleClose={() => handleTableView()}
                        data={data}
                        {...tableViewProps}
                    />
                )}
                <Popper
                    id={donwloadId}
                    open={popperOpen}
                    anchorEl={anchorEl}
                    style={{ margin: 0, padding: 0, boxShadow: 10 }}
                >
                    <List className={classes.list} disablePadding>
                        <ListItemButton
                            onClick={() => handleDownloadChart()}
                            onMouseOver={() => setDownloadFormat("svg")}
                            className={classes.listButton}
                        >
                            <ListItemText primary="svg" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => handleDownloadChart()}
                            onMouseOver={() => setDownloadFormat("png")}
                            className={classes.listButton}
                        >
                            <ListItemText primary="png" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => handleDownloadChart()}
                            onMouseOver={() => setDownloadFormat("csv")}
                            className={classes.listButton}
                        >
                            <ListItemText primary="csv" />
                        </ListItemButton>
                    </List>
                </Popper>
                <Popper
                    onClick={() => setShowOpen(false)}
                    open={showOpen}
                    anchorEl={open ? showAnchorRef.current : undefined}
                    style={{ margin: 0, padding: 0, boxShadow: 10 }}
                >
                    <List className={classes.list} disablePadding>
                        {showOptions &&
                            showOptions.map((option) => (
                                <ListItemButton
                                    onClick={() => {
                                        onShowOptionClicked(option);
                                        setShowOpen(false);
                                    }}
                                    className={classes.listButton}
                                >
                                    <ListItemText primary={option} />
                                </ListItemButton>
                            ))}
                    </List>
                </Popper>
                <Popper
                    onClick={() => setSplitByOpen(false)}
                    open={splitByOpen}
                    anchorEl={open ? splitByAnchorRef.current : undefined}
                    style={{ margin: 0, padding: 0, boxShadow: 10 }}
                >
                    <List className={classes.list} disablePadding>
                        {splitByOptions &&
                            splitByOptions.map((option) => (
                                <ListItemButton
                                    onClick={() => {
                                        onSplitByOptionClicked(option);
                                        setSplitByOpen(false);
                                    }}
                                    className={classes.listButton}
                                >
                                    <ListItemText primary={option} />
                                </ListItemButton>
                            ))}
                    </List>
                </Popper>
            </Box>
        </ClickAwayListener>
    );
}
