import React, { useMemo, useState, useEffect } from "react";
import debounce from "debounce";
import { Autocomplete } from "@mui/lab";
import Flag from "react-country-flag";
import { InputAdornment, TextField, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getAllByFilter as getAllCitiesByFilter } from "../../../services/api/City/CityApi";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: theme.spacing(30),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
    },
    optionText: {
        paddingLeft: 10,
    },
    button: {
        textTransform: "none",
    },
    input: {
        paddingRight: 0,
    },
    asterisk: {
        color: "red",
    },
    hoverElement: {
        "&:hover": {
            backgroundColor: "#D1DBFF !important",
        },
    },
}));

const searchParams = {
    limit: 50,
};
function CityAutocomplete({
    defaultValue = null,
    handleSave,
    label,
    required = false,
    error = false,
    errorOnEmpty = false,
    labelStyle,
    showError = true,
    color = "primary",
    onChange,
    showSave,
    inputProps,
    ...props
}) {
    const classes = useStyles();
    const [cityOptions, setCityOptions] = useState([]);
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const renderOptions = (renderProps, option) => (
        <li {...renderProps} translate="no">
            <Flag countryCode={option.country.iso2} svg style={{ width: 30, height: 16 }} />
            <Typography className={classes.optionText}>
                {option.name}, {option.country.name}
            </Typography>
        </li>
    );

    const handleChange = (event, currentValue) => {
        setValue(currentValue);
        if (onChange) {
            onChange(currentValue);
        }
    };

    const getCities = useMemo(
        () =>
            debounce(async (searchValue) => {
                if (searchValue) {
                    const cities = await getAllCitiesByFilter({ q: { name: searchValue }, ...searchParams });
                    setCityOptions(cities.data.records);
                } else {
                    setCityOptions([]);
                }
            }, 300),
        [],
    );

    const handleInputChange = (event, currentValue) => {
        if (currentValue === "") {
            setValue("");
            if (onChange) onChange("");
        }
        getCities(currentValue);
    };

    return (
        <Autocomplete
            options={cityOptions}
            size="small"
            freeSolo
            value={value || null}
            includeInputInList
            getOptionLabel={(option) =>
                Object.keys(option).length > 0 ? `${option.name}, ${option.country.name}` : ""
            }
            isOptionEqualToValue={(option, valueEl) => option.value === valueEl.value}
            renderOption={renderOptions}
            onChange={handleChange}
            onInputChange={handleInputChange}
            renderInput={(params) => {
                const textFieldInputProps = {
                    ...params.InputProps,
                    endAdornment: showSave ? (
                        <InputAdornment position="end">
                            <Button
                                className={classes.button}
                                onClick={() => (onChange ? onChange(value, { saved: true }) : undefined)}
                                variant="autocompleteButton"
                                disabled={!value}
                            >
                                Save
                            </Button>
                        </InputAdornment>
                    ) : (
                        params.InputProps.endAdornment
                    ),
                    startAdornment: value && (
                        <InputAdornment position="start">
                            <Flag countryCode={value?.country?.iso2} svg style={{ width: 30, height: 16 }} />
                        </InputAdornment>
                    ),
                    ...inputProps,
                };
                return (
                    <TextField
                        {...params}
                        InputProps={{
                            ...textFieldInputProps,
                        }}
                        variant="outlined"
                        label={label}
                        error={showError ? (errorOnEmpty ? !value || error : error) : false}
                        InputLabelProps={{
                            required,
                            classes: {
                                asterisk: classes.asterisk,
                                root: labelStyle,
                            },
                        }}
                        color={color}
                    />
                );
            }}
            className={classes.textField}
            classes={{
                option: classes.hoverElement,
            }}
            {...props}
        />
    );
}

export default CityAutocomplete;
