import { axiosInstance } from "../../../../../config/api/Axios";

export const getStackInsightsDataApi = (ids, view, show, split, v, filters) =>
    axiosInstance.get("/lab/stacks/insights", {
        params: {
            id: ids,
            view,
            show,
            split: split !== "none" ? split : undefined,
            v,
            filters,
        },
    });
