import React, { useState, useEffect, useMemo } from "react";
import { Stack, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SpeedDialMenu } from "../../../Charts/ChartContainer/SpeedDialMenu";
import HistogramLineChart from "../../../MixedCharts/HistogramLineChart";
import StandardVerticalBarChart from "../../../Charts/StandardVerticalBarChart";

const useStyles = makeStyles(() => ({
    container: {
        height: 396,
        marginBottom: 2,
    },
    grid: {
        "& .apexcharts-xaxis line": {
            transform: "scaleX(2.2) translateX(-11%)",
        },
        "& .apexcharts-customGrid .apexcharts-gridlines-horizontal line": {
            transform: "scaleX(2.2) translateX(-11%)",
        },
    },
}));

export function Section3({ data, stacks }) {
    const [zoomLevel, setZoomLevel] = useState(2);
    const [hovered, setHovered] = useState(false);
    const [barWidth, setBarWidth] = useState("50%");
    const [padding, setPadding] = useState({});

    const classes = useStyles();

    const handleZoomIn = () => {
        setZoomLevel(1);
    };
    const handleZoomOut = () => {
        setZoomLevel(2);
    };

    const series = useMemo(() => {
        const stackname = stacks[0].stack.name;

        if (zoomLevel === 1) {
            return [
                [{ name: stackname, data: data.workExperienceCurrent.stack.series.data }],
                [{ name: stackname, data: data.workExperiencePrevious.stack.series.data }],
                [{ name: stackname, data: data.workExperienceTotal.stack.series.data }],
            ];
        } else if (zoomLevel === 2) {
            return [
                [{ name: stackname, data: data.workExperienceCurrentGrouped.stack.series.data }],
                [{ name: stackname, data: data.workExperiencePreviousGrouped.stack.series.data }],
                [{ name: stackname, data: data.workExperienceTotalGrouped.stack.series.data }],
            ];
        }
    }, [data, stacks, zoomLevel]);

    const categories = useMemo(() => {
        if (zoomLevel === 1) {
            return [
                data.workExperienceCurrent.categories,
                data.workExperiencePrevious.categories,
                data.workExperienceTotal.categories,
            ];
        } else if (zoomLevel === 2) {
            return [
                data.workExperienceCurrentGrouped.categories,
                data.workExperiencePreviousGrouped.categories,
                data.workExperienceTotalGrouped.categories,
            ];
        }
    }, [data, zoomLevel]);

    const maxValue = useMemo(
        () => Math.max(...series[0][0].data, ...series[1][0].data, ...series[2][0].data),
        [series, zoomLevel],
    );

    const colors =
        zoomLevel === 1
            ? { barColors: ["#AFBFF2"], lineColors: ["#405DBD"], markerColors: ["#5073E8"] }
            : { defaultColors: ["#AFBFF2"] };

    const handleChartEvents = (context, options) => {
        if (options) {
            const gridClass = "apexcharts-grid";
            const customGridClass = "apexcharts-customGrid";

            // Options differ slightly based on types of events.
            const svg = options?.globals?.dom?.Paper?.node || options?.w?.globals?.dom?.Paper?.node;
            if (!svg.getElementsByClassName(customGridClass)[0]) {
                // Workaround for a very annoying tooltip bug.
                // We have to transform grid lines(scale & translate) because adding left/right padding to
                // chartOptions.grid.padding makes grid lines shorter and centered.
                // Widening these lines will cause *parent g element*(apexcharts-grid) to widen as well,
                // but mouseover areas for *shared tooltip* depend on the width of this *g element*(if u change
                // its width, offsets of tooltips for data points will change as well, thus making it unusable).
                // One solution is to *only* widen gridlines of the *cloned g element* that can be inserted
                // right after the original *g element*.
                const grid = svg.getElementsByClassName(gridClass)[0];
                const grid2 = grid.cloneNode(true);
                grid2.id = "";
                grid2.classList.remove(gridClass);
                grid2.classList.add(customGridClass);
                grid.parentNode.insertBefore(grid2, grid.nextSibling);
            }
        }

        const viewportWidth = document.body.clientWidth;

        if (viewportWidth >= 1950 && barWidth !== "40%") setBarWidth("40%");
        else if (viewportWidth < 1950 && viewportWidth >= 1366 && barWidth !== "50%") setBarWidth("50%");
        else if (viewportWidth < 1366 && barWidth !== "60%") setBarWidth("60%");

        const xPadding = parseInt(viewportWidth / 12);
        if (padding?.left !== xPadding || padding?.right !== xPadding) {
            setPadding({ left: xPadding, right: xPadding });
        }
    };

    useEffect(() => {
        // Set chart options on first render. This is really only needed for development purposes.
        // (Hot realoading will reset the state, but won't trigget chart's "updated" event)
        handleChartEvents();
    }, [zoomLevel]);

    return (
        <Paper
            elevation={1}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={classes.container}
        >
            <SpeedDialMenu
                open={hovered}
                closeMenu={() => setHovered(false)}
                actionsOffset={{ y: -30, x: 40 }}
                data={{ categories, series: [series[1], series[0], series[2]] }}
                minLimit={1}
                maxLimit={2}
                loadedItems={zoomLevel}
                enableZoom
                zoomIn={handleZoomIn}
                zoomOut={handleZoomOut}
            />
            <Stack direction="row" width="100%">
                {zoomLevel === 1 ? (
                    <>
                        <Stack width="100%" height={395}>
                            <HistogramLineChart
                                variant="single"
                                categories={categories[0]}
                                series={series[0]}
                                title="Work Experience in Current Company"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                                showLegend={false}
                            />
                        </Stack>
                        <Stack width="100%" height={395}>
                            <HistogramLineChart
                                variant="single"
                                categories={categories[1]}
                                series={series[1]}
                                title="Work Experience in Previous Companies"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                                showLegend={false}
                            />
                        </Stack>
                        <Stack width="100%" height={395}>
                            <HistogramLineChart
                                variant="single"
                                categories={categories[2]}
                                series={series[2]}
                                title="Work Experience Total"
                                barColors={colors.barColors}
                                lineColors={colors.lineColors}
                                markerColors={colors.markerColors}
                                maxValue={maxValue}
                                showLegend={false}
                            />
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[0]}
                                series={series[0]}
                                title="Work Experience in Current Company"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[1]}
                                series={series[1]}
                                title="Work Experience in Previous Companies"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                        <Stack width="100%" height={395} className={classes.grid}>
                            <StandardVerticalBarChart
                                variant="normal"
                                categories={categories[2]}
                                series={series[2]}
                                title="Work Experience Total"
                                colors={colors}
                                showActions={false}
                                showLegend
                                maxValue={maxValue}
                                xAxisLabel="years"
                                padding={padding}
                                barWidth={barWidth}
                                eventHandlers={{ onUpdated: handleChartEvents, onAnimationEnd: handleChartEvents }}
                            />
                        </Stack>
                    </>
                )}
            </Stack>
        </Paper>
    );
}
