import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "@gatsbyjs/reach-router";
import BarChartWithMarkers from "../Charts/BarChartWithMarkers";
import DifferenceChart from "../Charts/DifferenceChart";
import StackedBarCategories from "../Charts/StackedBarCategories";
import { ChartContainer } from "../Charts/ChartContainer";

const useStyles = makeStyles(() => ({
    differenceChart: {
        "@global": {
            ".averageWorkExperience": {
                maxWidth: 143,
                whiteSpace: "normal",
                lineHeight: 1.4,
            },
        },
        "& .apexcharts-tooltip": {
            marginLeft: -95,
        },
    },
}));

export default function MarkersBarChartWithDifference({
    series,
    categories,
    additionalCategories,
    title,
    shortTitle,
    subscript,
    colors,
    differenceSeries,
    height,
    barheight,
    containerStyle,
    categoriesStyle,
    stacks,
    selectedCategories,
    tooltips,
    inPercentage = false,
}) {
    const classes = useStyles();
    const location = useLocation();
    const maxValue = useMemo(
        () => Math.max(...series.map((item) => item.data.reduce((prev, cur) => Math.max(prev, cur.y), 0))),
        [series],
    );

    const differenceTooltip = location.pathname.includes("/stack-insights/employment_comparison_insights")
        ? " years"
        : "%";

    return (
        <ChartContainer
            chartInfo={{ title, shortTitle, subscript }}
            containerStyles={{ padding: 0, margin: 0 }}
            data={{ categories, series: [...series, differenceSeries] }}
            tableViewProps={{
                chartType: "MarkersBarChartWithDifference",
                chartTitle: `${shortTitle} ${subscript || ""}`,
            }}
            actionsOffset={{ y: -55 }}
        >
            <Stack direction="row" mt={1.5}>
                <Stack flex={10} direction="row">
                    <Stack width="100%">
                        <BarChartWithMarkers
                            reversed
                            title={stacks[0].stack.name}
                            colors={colors[0]}
                            series={[series[0]]}
                            height={height}
                            stackname={stacks[0].stack.name}
                            padding={{ left: 0, top: -22, bottom: -4 }}
                            categories={categories}
                            max={maxValue}
                            additionalCategories={additionalCategories}
                            inPercentage={inPercentage}
                        />
                    </Stack>
                    <Stack>
                        <StackedBarCategories
                            categories={categories}
                            categoriesStyle={categoriesStyle}
                            containerStyle={containerStyle}
                            selectedCategories={selectedCategories}
                            tooltips={tooltips}
                        />
                    </Stack>
                    <Stack width="100%">
                        <BarChartWithMarkers
                            title={stacks[1].stack.name}
                            colors={colors[1]}
                            series={[series[1]]}
                            height={height}
                            stackname={stacks[1].stack.name}
                            padding={{ left: -3, top: -22, bottom: -4 }}
                            categories={categories}
                            max={maxValue}
                            additionalCategories={additionalCategories}
                            inPercentage={inPercentage}
                        />
                    </Stack>
                </Stack>
                <Stack flex={5} position="relative" className={classes.differenceChart}>
                    <DifferenceChart
                        series={differenceSeries}
                        categories={tooltips || categories}
                        barheight={barheight}
                        height={height - 20}
                        padding={{ top: -31, bottom: -12 }}
                        title="Difference"
                        inPercentage={inPercentage}
                        years={differenceTooltip}
                    />
                </Stack>
            </Stack>
        </ChartContainer>
    );
}
