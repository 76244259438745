import React, { useState } from "react";
import { Autocomplete, TextField, Paper } from "@mui/material";
import { COMPANY_INDUSTRY_OPTIONS } from "../../Organization/Create/formOptions";

export default function IndustryAutocomplete({ defaultValue, onSelected, color = "primary", disabled = false }) {
    const [industry, setIndustry] = useState(COMPANY_INDUSTRY_OPTIONS || []);
    const isPrimary = color === "primary";

    const handleOnInputChange = async (e) => {
        if (!e) return;
        const value = e.target.value;
        setIndustry((currentIndustry) => currentIndustry.filter((item) => item.value !== value));
    };

    const handleOnChange = (e, industryName) => {
        if (!industryName) {
            onSelected(null);
        }
        const org = industry.filter((item) => item.label === industryName)[0];
        if (onSelected) {
            onSelected(org);
        }
    };

    return (
        <Autocomplete
            options={industry.map((element) => element.label)}
            fullWidth
            freeSolo
            forcePopupIcon
            value={defaultValue || ""}
            variant="outlined"
            size="small"
            disabled={disabled}
            color={color}
            renderInput={(params) => (
                <TextField color={isPrimary ? "secondary" : "primary"} {...params} label="Industry" margin="dense" />
            )}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            PaperComponent={({ children }) => (
                <Paper variant={isPrimary ? "autocompleteDropdown" : "elevation"} elevation={isPrimary ? 1 : 0}>
                    {children}
                </Paper>
            )}
        />
    );
}
