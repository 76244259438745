import { axiosInstance } from "../../../config/api/Axios";

export const uploadProfilePhotoApi = (imageData) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    return axiosInstance.post(`/media/picture`, imageData, config);
};

export const getUserDetailsApi = (token) => axiosInstance.get(`/auth/me/${token}`);

export const getUsersApi = (filter = {}) => axiosInstance.get(`/users`, { params: { ...filter } });

export const createUserApi = (userData) => axiosInstance.post(`/users`, userData);
export const updateUserApi = (id, userData) => axiosInstance.put(`/users/${id}`, userData);
export const deleteUserApi = (id) => axiosInstance.delete(`/users/${id}`);

export const sendForgotPasswordApi = (email) => axiosInstance.post(`/auth/forgot-password`, { email });
export const getResetPasswordTokenApi = (code) => axiosInstance.post(`/auth/reset-password-token`, { code });
export const resetPasswordApi = (formData) => axiosInstance.post(`/auth/new-password`, formData);
export const autoInviteApi = (email, code) => axiosInstance.post("auth/auto-invite", { email, code });
export const getBillingPortalApi = () => axiosInstance.get(`/billing-portal`);
export const getSubscriptionCheckoutApi = () => axiosInstance.get(`/subscription-checkout`);
