import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccountPersonalInfoComponent from "../../../components/Account/PersonalInfo";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function AccountPersonalInfoPage() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <AccountPersonalInfoComponent />
        </Box>
    );
}
