import React, { useRef, useState } from "react";
import { Stack, Typography, Button, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
    ShowModes,
    ShowOptions,
    SplitModes,
    SplitOptions,
} from "../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { DEMOGRAPHICS_COMPARISON_INSIGHT, DEMOGRAPHICS_SINGLE_INSIGHT } from "../Views/viewConstants";

const useStyles = makeStyles(() => ({
    dropdownButton: {
        backgroundColor: "#5e60e1",
        textTransform: "none",
        borderRadius: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "4px 18px 4px 10px",
        display: "block",
        "& .MuiButton-endIcon": {
            position: "absolute",
            top: 5,
            right: 3,
        },
    },
}));

export default function ShowAndSplitBy({ show, split, onShowSelected, onSplitBySelected, onMenuToggled, viewType }) {
    const classes = useStyles();
    const showElementRef = useRef(null);
    const splitElementRef = useRef(null);
    const [showMenuOpen, setShowMenuOpen] = useState(false);
    const [splitMenuOpen, setSplitMenuOpen] = useState(false);
    const splitOptionsMenu =
        viewType === DEMOGRAPHICS_COMPARISON_INSIGHT || viewType === DEMOGRAPHICS_SINGLE_INSIGHT
            ? {
                  none: SplitOptions.none,
              }
            : SplitOptions;

    const showOptionsMenu =
        viewType === DEMOGRAPHICS_COMPARISON_INSIGHT || viewType === DEMOGRAPHICS_SINGLE_INSIGHT
            ? {
                  percent: ShowOptions.percent,
              }
            : ShowOptions;

    return (
        <Stack direction="row" spacing={1} mb={3.5} width={300} justifyContent="space-between">
            <Stack minWidth={0}>
                <Typography variant="caption">Show</Typography>
                <Button
                    ref={showElementRef}
                    className={classes.dropdownButton}
                    size="small"
                    endIcon={<ArrowDropDownIcon />}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                        if (onMenuToggled) onMenuToggled(true);
                        setShowMenuOpen(true);
                    }}
                    component={Typography}
                >
                    {ShowOptions[show]}
                </Button>
                <Menu
                    anchorEl={showElementRef.current}
                    open={showMenuOpen}
                    onClose={() => {
                        if (onMenuToggled) onMenuToggled(false);
                        setShowMenuOpen(false);
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    {Object.entries(showOptionsMenu).map(([key, option]) => (
                        <MenuItem
                            key={key}
                            onClick={() => {
                                if (onMenuToggled) onMenuToggled(false);
                                setShowMenuOpen(false);
                                onShowSelected(ShowModes[key]);
                            }}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
            <Stack minWidth={0}>
                <Typography variant="caption">Split by</Typography>
                <Button
                    ref={splitElementRef}
                    className={classes.dropdownButton}
                    size="small"
                    endIcon={<ArrowDropDownIcon />}
                    variant="contained"
                    disableElevation
                    onClick={() => {
                        if (onMenuToggled) onMenuToggled(true);
                        setSplitMenuOpen(true);
                    }}
                    component={Typography}
                >
                    {SplitOptions[split]}
                </Button>
                <Menu
                    anchorEl={splitElementRef.current}
                    open={splitMenuOpen}
                    onClose={() => {
                        if (onMenuToggled) onMenuToggled(false);
                        setSplitMenuOpen(false);
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {Object.entries(splitOptionsMenu).map(([key, option]) => (
                        <MenuItem
                            key={key}
                            onClick={() => {
                                if (onMenuToggled) onMenuToggled(false);
                                setSplitMenuOpen(false);
                                onSplitBySelected(SplitModes[key]);
                            }}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
        </Stack>
    );
}
