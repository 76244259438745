import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UserInfo from "./UserInfo";
import CompanyInfo from "./CompanyInfo";

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2.25),
        color: theme.palette.text.secondary,
        height: "calc(100vh - 65px)",
        overflow: "auto",
    },
}));

export default function ItemView() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={0}>
            <UserInfo />
            <CompanyInfo />
        </Paper>
    );
}
