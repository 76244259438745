import * as yup from "yup";

export const changeEmailSchema = yup.object().shape({
    email: yup.string().email("Invalid Email address").required("Field is required"),
    password: yup.string().required("Field is required"),
});

export const changePasswordSchema = yup.object().shape({
    password: yup.string().required("Field is required"),
    new_password: yup
        .string()
        .min(8, "Password must have at least 8 characters")
        .matches(/^(?=.*[a-z])/, "Your password must have at least one lowercase letter")
        .matches(/^(?=.*[A-Z])/, "Your password must have at least one uppercase letter")
        .matches(/^((?=.*[0-9])|(?=.*\W))/, "Your password must have at least one number or symbol")
        .required("Field is required"),
    new_password_confirmation: yup
        .string()
        .oneOf([yup.ref("new_password")], "New password doesn’t match")
        .required("Field is required"),
});
