import React from "react";
import { Stack, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Chart from "react-apexcharts";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const options = {
    series: [50],
    chart: {
        toolbar: {
            show: false,
        },
    },
    colors: ["#c9c9c9"],
    states: {
        hover: {
            filter: {
                type: "none",
            },
        },
    },
    plotOptions: {
        radialBar: {
            hollow: {
                size: "70%",
            },
            dataLabels: {
                showOn: "always",
                name: {
                    offsetY: -15,
                    show: true,
                    color: "#c9c9c9",
                    fontSize: "20px",
                },
                value: {
                    color: "#c9c9c9",
                    fontSize: "30px",
                    show: true,
                    formatter: () => "Coming soon...",
                },
            },
        },
    },
    legend: {
        show: false,
    },
    labels: ["Company Health Score"],
};

export function Section4() {
    const classes = useStyles();

    return (
        <Stack flex={1} alignItems="center" justifyContent="center">
            <Paper className={classes.root}>
                <Chart options={options} type="radialBar" series={options.series} height={400} />
            </Paper>
        </Stack>
    );
}
