import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack, Paper, Typography } from "@mui/material";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";
import RadialChartWithBars from "../../../MixedCharts/RadialChartWithBars";
import PieChart from "../../../Charts/PieChart";
import ChartLegend from "../../../Charts/ChartLegend";
import { getColors } from "../../../utils";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";

const titleStyles = {
    background: "transparent",
};

export function Section1({ data, stacks }) {
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);

    const barColors1 = useMemo(
        () =>
            data.topCitiesPerCompany
                ? getColors(
                      data.topCitiesPerCompany.stack.series[0]?.name
                          ? data.topCitiesPerCompany.stack.series
                                .map((serie) => serie.name)
                                .filter((serie) => serie.name !== "Total")
                          : [],
                      show,
                      split,
                  )
                : [],
        [data],
    );

    const [countries, categories, series, values] = useMemo(
        () => [
            data.countriesAndCitiesDistribution.stack.map((item) => item.country),
            data.countriesAndCitiesDistribution.stack.map((item) => item.categories),
            data.countriesAndCitiesDistribution.stack.map((item) => [item.series]),
            data.countriesAndCitiesDistribution.stack.map((item) => item.value),
        ],
        [data],
    );

    const maxValue = useMemo(() => {
        let max = 0;
        for (let i = 0; i < series.length; i += 1) {
            const rowSum = series[i].reduce((prev, cur) => prev + cur.data[0], 0);
            max = Math.max(max, rowSum);
        }

        return max;
    });

    return (
        <Stack direction="row" spacing={1} height={275} mb={1}>
            <Stack flex={2} direction="row" spacing={1}>
                <Stack flex={1}>
                    <Paper sx={{ height: "100%", pr: 4.5 }}>
                        <StackedBarWithCategories
                            barColors={split === SplitModes.none ? [barColors1[0]] : barColors1}
                            title="Top Cities"
                            titleStyles={titleStyles}
                            titleTypographyStyle={{ paddingLeft: 10, paddingTop: 10 }}
                            stacks={stacks}
                            barHeight="49%"
                            categories={data.topCitiesPerCompany.categories}
                            series={data.topCitiesPerCompany.stack.series.filter((serie) => serie.name !== "Total")}
                            containerStyle={{ width: 153, ml: 7, mt: 0.4 }}
                            categoriesStyle={{ marginBottom: 16.7, textAlign: "right" }}
                            categoriesFlex="initial"
                            actionsOffset={{ y: -50, x: 5 }}
                            isDistributed={false}
                            paddingBottomPerItem={25.42}
                            xAxisOffsetPerItem={12.71}
                            itemsPerPage={5}
                        />
                    </Paper>
                </Stack>
                <Stack flex={1}>
                    <Paper sx={{ height: "100%" }}>
                        <Typography variant="h5" color="textSecondary" pl={1.25} pt={1.25}>
                            Countries and Cities Distribution
                        </Typography>
                        <RadialChartWithBars
                            countries={countries}
                            categories={categories}
                            series={series}
                            barColors={["#4973EB"]}
                            height={170}
                            radialBarHeight={250}
                            radialBarValues={values}
                            radialBarColor="#4973EB"
                            max={maxValue}
                            stackname={stacks[0].stack.name}
                            showStackname={false}
                        />
                    </Paper>
                </Stack>
            </Stack>
            <Stack flex={1}>
                <Paper sx={{ height: "100%" }}>
                    <Stack width="100%" height="100%">
                        <Typography variant="h5" color="textSecondary" pl={1.25} pt={1.25}>
                            Overall Gender Ratio
                        </Typography>
                        <Stack mt={0.5}>
                            <ChartLegend
                                legend={[
                                    { name: "Male", color: "#5172E8" },
                                    { name: "Female", color: "#BAA2E1" },
                                    { name: "Unknown", color: "#ececec" },
                                ]}
                                legendStyle={{ pl: 1 }}
                            />
                        </Stack>
                        <Stack flex={1}>
                            <PieChart
                                labels={data.genderDistributionCurrent.categories}
                                series={data.genderDistributionCurrent.stack.series.data}
                                colors={["#5172E8", "#BAA2E1", "#ECECEC"]}
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
