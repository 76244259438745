import { axiosInstance } from "../../../config/api/Axios";

export function getAllByFilter(filter = {}) {
    const params = {
        q: filter.hasOwnProperty("q") ? { name: filter.q } : {},
    };

    if (filter.country && filter.country.length > 0) {
        params.country = filter.country;
    }

    if (filter.sort_by) {
        params.sort_by = filter.sort_by;
    }

    if (filter.start) {
        params.start = filter.start;
    }

    if (filter.limit) {
        params.limit = filter.limit;
    }
    return axiosInstance.get("/common/cities", {
        params,
    });
}
