import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import GroupsIcon from "@mui/icons-material/Groups";
import BadgeIcon from "@mui/icons-material/Badge";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricItem } from "../../../../components/MetricItem";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function ExperienceSection({ data, currentCity, comparisonCity, theme, classes }) {
    const experience = useMemo(() => {
        const averageExperience = data.workExperienceAndEmployees.averageWorkExperienceCurrent.data.map(
            (value) => value || 0,
        );
        const leftAverageExperienceSmaller = averageExperience[0] < averageExperience[1];

        const mostExperienceLeft = data.workExperienceAndEmployees.averageWorkExperiencePerCompany.data[0];
        const mostExperienceRight = data.workExperienceAndEmployees.averageWorkExperiencePerCompany.data[1];
        const mostExperienceLongerList =
            mostExperienceLeft.length > mostExperienceRight.length ? mostExperienceLeft : mostExperienceRight;
        const mostExperienceMax = Math.max(
            ...mostExperienceLeft.map((list) => list[1]),
            ...mostExperienceRight.map((list) => list[1]),
        );

        const averageRetention = data.workExperienceAndEmployees.averageWorkExperienceRetention.data;
        const leftAverageRetentionSmaller = averageRetention[0] < averageRetention[1];

        const higherRetentionLeft = data.workExperienceAndEmployees.averageWorkExperienceRetentionPerCompany.data[0];
        const higherRetentionRight = data.workExperienceAndEmployees.averageWorkExperienceRetentionPerCompany.data[1];
        const higherRetentionLongerList =
            higherRetentionLeft.length > higherRetentionRight.length ? higherRetentionLeft : higherRetentionRight;
        const higherRetentionMax = Math.max(
            ...higherRetentionLeft.map((list) => list[1]),
            ...higherRetentionRight.map((list) => list[1]),
        );

        const averageLoyalty = data.workExperienceAndEmployees.averageScore.data.map((value) => value || 0);
        const leftAverageLoyaltySmaller = averageLoyalty[0] < averageLoyalty[1];

        const highestLoyaltyLeft = data.workExperienceAndEmployees.topHighestScorePerCompany.data[0];
        const highestLoyaltyRight = data.workExperienceAndEmployees.topHighestScorePerCompany.data[1];
        const highestLoyaltyLongerList =
            highestLoyaltyLeft.length > highestLoyaltyRight.length ? highestLoyaltyLeft : highestLoyaltyRight;

        return {
            averageExperience: {
                values: averageExperience.map((value) => `${value} years`),
                barPercentages: [
                    leftAverageExperienceSmaller ? (averageExperience[0] / averageExperience[1]) * 100 : 100,
                    leftAverageExperienceSmaller ? 100 : (averageExperience[1] / averageExperience[0]) * 100,
                ],
                colors: [
                    leftAverageExperienceSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAverageExperienceSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                message: `${comparisonCity.name} has ${
                    leftAverageExperienceSmaller
                        ? parseFloat(averageExperience[1] - averageExperience[0]).toFixed(2)
                        : parseFloat(averageExperience[0] - averageExperience[1]).toFixed(2)
                } years ${
                    leftAverageExperienceSmaller ? "higher" : "lower"
                } Average work experience in companies than ${currentCity.name}`,
            },
            mostExperience: {
                values: mostExperienceLongerList.map((_, index) => [
                    mostExperienceLeft[index]
                        ? `${shortenText(mostExperienceLeft[index][0], 21)} (${mostExperienceLeft[index][1]}y)`
                        : undefined,
                    mostExperienceRight[index]
                        ? `${shortenText(mostExperienceRight[index][0], 21)} (${mostExperienceRight[index][1]}y)`
                        : undefined,
                ]),
                barPercentages: mostExperienceLongerList.map((_, index) => [
                    mostExperienceLeft[index] ? (mostExperienceLeft[index][1] / mostExperienceMax) * 100 : 0,
                    mostExperienceRight[index] ? (mostExperienceRight[index][1] / mostExperienceMax) * 100 : 0,
                ]),
            },
            averageRetention: {
                values: averageRetention.map((value) => `${value || 0} years`),
                barPercentages: [
                    leftAverageRetentionSmaller ? (averageRetention[0] / averageRetention[1]) * 100 : 100,
                    leftAverageRetentionSmaller ? 100 : (averageRetention[1] / averageRetention[0]) * 100,
                ],
                colors: [
                    leftAverageRetentionSmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAverageRetentionSmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                indicators: [
                    <GroupsIcon className={leftAverageRetentionSmaller ? classes.smallerScore : classes.higherScore} />,
                    <GroupsIcon className={leftAverageRetentionSmaller ? classes.higherScore : classes.smallerScore} />,
                ],
                message: `${comparisonCity.name} has ${
                    leftAverageRetentionSmaller
                        ? parseFloat(averageExperience[1] - averageExperience[0]).toFixed(2)
                        : parseFloat(averageExperience[0] - averageExperience[1]).toFixed(2)
                } years ${leftAverageRetentionSmaller ? "higher" : "lower"} Average retention in companies than ${
                    currentCity.name
                }`,
            },
            higherRetention: {
                values: higherRetentionLongerList.map((_, index) => [
                    higherRetentionLeft[index]
                        ? `${shortenText(higherRetentionLeft[index][0], 21)} (${higherRetentionLeft[index][1]}y)`
                        : undefined,
                    higherRetentionRight[index]
                        ? `${shortenText(higherRetentionRight[index][0], 21)} (${higherRetentionRight[index][1]}y)`
                        : undefined,
                ]),
                barPercentages: higherRetentionLongerList.map((_, index) => [
                    higherRetentionLeft[index] ? (higherRetentionLeft[index][1] / higherRetentionMax) * 100 : 0,
                    higherRetentionRight[index] ? (higherRetentionRight[index][1] / higherRetentionMax) * 100 : 0,
                ]),
            },
            averageLoyalty: {
                values: averageLoyalty.map((value) => `${value.toFixed(2) || 0} years`),
                barPercentages: [
                    leftAverageLoyaltySmaller ? (averageLoyalty[0] / averageLoyalty[1]) * 100 : 100,
                    leftAverageLoyaltySmaller ? 100 : (averageLoyalty[1] / averageLoyalty[0]) * 100,
                ],
                colors: [
                    leftAverageLoyaltySmaller ? theme.palette.alert.main : theme.palette.success.main,
                    leftAverageLoyaltySmaller ? theme.palette.success.main : theme.palette.alert.main,
                ],
                message: `${comparisonCity.name} has ${
                    leftAverageLoyaltySmaller
                        ? parseFloat(averageLoyalty[1] - averageLoyalty[0]).toFixed(2)
                        : parseFloat(averageLoyalty[0] - averageLoyalty[1]).toFixed(2)
                } ${leftAverageLoyaltySmaller ? "higher" : "lower"} Average loyalty score in companies than ${
                    currentCity.name
                }`,
            },
            highestLoyalty: {
                values: highestLoyaltyLongerList.map((_, index) => [
                    highestLoyaltyLeft[index] ? shortenText(highestLoyaltyLeft[index][0], 25) : undefined,
                    highestLoyaltyRight[index] ? shortenText(highestLoyaltyRight[index][0], 25) : undefined,
                ]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Work Experience and Employees">
            <MetricItem
                name="Average Work Experience in Companies in the city"
                values={experience.averageExperience.values}
                barPercentages={experience.averageExperience.barPercentages}
                colors={experience.averageExperience.colors}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {experience.averageExperience.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4} pb={3}>
                <MetricGroup
                    name="Companies with the most experienced employees"
                    values={experience.mostExperience.values}
                    barPercentages={experience.mostExperience.barPercentages}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={BadgeIcon}
                />
            </Stack>
            <MetricItem
                name="Average Retention in Companies in the city"
                values={experience.averageRetention.values}
                barPercentages={experience.averageRetention.barPercentages}
                colors={experience.averageRetention.colors}
                indicators={experience.averageRetention.indicators}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {experience.averageRetention.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4} pb={3}>
                <MetricGroup
                    name="Companies with the highest retention in the city"
                    values={experience.higherRetention.values}
                    barPercentages={experience.higherRetention.barPercentages}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={WorkIcon}
                />
            </Stack>

            <MetricItem
                name="Average Loyalty Score in Companies in the city"
                values={experience.averageLoyalty.values}
                barPercentages={experience.averageLoyalty.barPercentages}
                colors={experience.averageLoyalty.colors}
                textStyles={{ fontSize: 10, top: 5 }}
            />
            <Stack alignItems="center" mt={1}>
                <Typography variant="caption" color="textSecondary">
                    {experience.averageLoyalty.message}
                </Typography>
            </Stack>

            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Companies with the highest Loyalty Score in the city"
                    values={experience.highestLoyalty.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={VolunteerActivismIcon}
                />
            </Stack>
        </MetricSection>
    );
}
