import React, { useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import ChangeEmailDialog from "./ChangeEmailDialog";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { userSliceSelectors } from "../../../../slices/User/userSlice";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
    },
    row: {
        display: "flex",
        alignItems: "center",
        padding: "9px 0 9px 0",
    },
    leftColumn: {
        width: "20%",
    },
    valueText: {
        marginRight: theme.spacing(2),
        cursor: "pointer",
    },
    valueRow: {
        display: "flex",
    },
    container: {
        width: "100%",
        padding: "24px 16px 0 40px",
    },
}));

const CHANGE_EMAIL_DIALOG = 1;
const CHANGE_PASSWORD_DIALOG = 2;

function SecuritySettingsComponent() {
    const { email, password_changed_at, created_at } = useSelector(userSliceSelectors.defaultUserFields);
    const [dialogOpen, setDialogOpen] = useState(0);
    const [isHovered, setIsHovered] = useState(0);
    const classes = useStyles();

    const handleCloseDialog = () => {
        setDialogOpen(0);
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.title}>
                    <Typography color="textSecondary" fontWeight={500} variant="overline">
                        SECURITY SETTINGS
                    </Typography>
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Account Password</Typography>
                    </div>
                    <div
                        className={classes.valueRow}
                        onMouseOver={() => setIsHovered(CHANGE_PASSWORD_DIALOG)}
                        onMouseLeave={() => setIsHovered(0)}
                        onClick={() => setDialogOpen(CHANGE_PASSWORD_DIALOG)}
                    >
                        <div className={classes.valueText}>
                            <Typography color="text.secondaryVariant2" variant="body2">
                                {password_changed_at
                                    ? `Password last changed on ${dayjs(password_changed_at).format("MMMM D, YYYY")}`
                                    : created_at
                                    ? `Password last changed on ${dayjs(created_at).format("MMMM D, YYYY")}`
                                    : "N/A"}
                            </Typography>
                        </div>
                        {isHovered === CHANGE_PASSWORD_DIALOG ? (
                            <div className={classes.valueText}>
                                <Typography color="secondary" fontWeight={500} variant="body2">
                                    Change Password
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Account Email</Typography>
                    </div>
                    <div
                        className={classes.valueRow}
                        onMouseOver={() => setIsHovered(CHANGE_EMAIL_DIALOG)}
                        onMouseLeave={() => setIsHovered(0)}
                        onClick={() => setDialogOpen(CHANGE_EMAIL_DIALOG)}
                    >
                        <div className={classes.valueText}>
                            <Typography variant="body2">{email || "N/A"}</Typography>
                        </div>
                        {isHovered === CHANGE_EMAIL_DIALOG ? (
                            <div className={classes.valueText}>
                                <Typography color="secondary" fontWeight={500} variant="body2">
                                    Change Email
                                </Typography>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.leftColumn}>
                        <Typography variant="body2">Enable Two-factor Authentication</Typography>
                    </div>
                    <Typography color="text.secondaryVariant2" variant="body2">
                        Coming soon!
                    </Typography>
                </div>
            </div>
            <ChangeEmailDialog open={dialogOpen === CHANGE_EMAIL_DIALOG} handleClose={handleCloseDialog} />
            <ChangePasswordDialog open={dialogOpen === CHANGE_PASSWORD_DIALOG} handleClose={handleCloseDialog} />
        </>
    );
}

export default SecuritySettingsComponent;
