import React from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    container: {
        height: "100%",
        "& .apexcharts-xaxis-annotations": {
            "& line": {
                transform: ({ noData }) => (noData ? "scale(0)" : undefined),
            },
        },
    },
}));

export default function DifferenceChart({
    series,
    categories,
    containerStyle,
    title,
    height = "100%",
    barheight,
    padding,
    inPercentage = true,
    years = "",
    isDistributed,
    colors = ["#6737C8"],
    eventHandlers = {},
}) {
    const { onUpdated } = eventHandlers;
    const absoluteMaxValue = Math.max(...series[0].data.map((val) => Math.abs(val)));
    const classes = useStyles({ noData: series[0].data.length === 0 });

    const options = {
        colors,
        chart: {
            type: "bar",
            stacked: true,
            toolbar: {
                show: false,
            },
            events: {
                updated: onUpdated,
            },
        },
        fill: {
            opacity: 1,
        },
        grid: {
            show: false,
            padding: {
                ...padding,
            },
        },
        legend: {
            show: false,
        },
        plotOptions: {
            bar: {
                horizontal: true,
                borderRadius: 2,
                barHeight: barheight,
                borderRadiusOnAllStackedSeries: true,
                dataLabels: {
                    position: "middle",
                    hideOverflowingLabels: false,
                },
                distributed: isDistributed,
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: "start",
            style: {
                colors: ["#7B7B7B"],
                maxHeight: 100,
            },
            formatter: (value) =>
                !inPercentage
                    ? value > 0
                        ? `\u2000${Math.abs(value)}`
                        : `${Math.abs(value)}\u2000`
                    : value > 0
                    ? `\u2000${Math.abs(value)}%`
                    : `${Math.abs(value)}%\u2000`,
        },

        xaxis: {
            categories,
            show: false,
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            max: absoluteMaxValue * 2,
            min: -absoluteMaxValue * 2,
        },

        yaxis: {
            show: false,
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        annotations: {
            xaxis: [
                {
                    y: 0,
                    strokeDashArray: 0,
                    borderColor: "#a9a9a9",
                },
            ],
        },
        tooltip: {
            intersect: false,
            x: {
                formatter: (value) => {
                    if (value.includes("Average Work Experience")) {
                        return `<div class=averageWorkExperience>${value}</div>`;
                    }
                    return value;
                },
            },
            y: {
                formatter: (value) => `+${Math.abs(value)}${years}`,
                title: {
                    formatter: (seriesName) => {
                        if (seriesName === "series-1") {
                            return "Difference";
                        }

                        return seriesName;
                    },
                },
            },
        },
    };

    return (
        <div className={classes.container} style={containerStyle}>
            {title && (
                <Typography variant="h6" color="textSecondary" align="center" mb={1.25}>
                    {title}
                </Typography>
            )}
            <Chart options={options} series={series} type="bar" height={height} barheight={barheight} width="100%" />
        </div>
    );
}
