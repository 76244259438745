import React from "react";
import GoogleChart from "./GoogleChart";
import AnyChart from "./AnyChart";

export default function MapChart(props) {
    const { variant = "google" } = props;

    switch (variant) {
        case "google":
            return <GoogleChart {...props} />;
        case "anychart":
            return <AnyChart {...props} />;
        default:
            throw new Error("Invalid variant");
    }
}
