import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack, Paper, IconButton, Typography } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";
import MarkersBarWithCategories from "../../../Charts/MarkersBarWithCategories";
import { SpeedDialMenu } from "../../../Charts/ChartContainer/SpeedDialMenu";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors, getMarkersBarHeight } from "../../../utils";

const titleStyles = {
    background: "#F5F5F5",
    height: 45,
};

const chartStyle = {
    mt: 3,
};

const titleTypographyStyle = {
    margin: 0,
    padding: 0,
    width: "100%",
    fontSize: 14,
    textAlign: "center",
};

const categoriesStyle = {
    marginBottom: 13,
    textAlign: "right",
};

const ageGroupsCategories = ["<18 years", "18-25 years", "25-35 years", "35-55 years", "55+ years"];
const ageGroupsColors = ["#D3E3E8", "#AEC7CF", "#7FA7B3", "#587F8B", "#3D636E"];
const ageGroupsLegend = ageGroupsCategories.map((category, index) => ({
    name: category,
    color: ageGroupsColors[index],
}));

export function Section2({ data, stacks }) {
    const [showingCities, setShowingCities] = useState(true);
    const title = showingCities ? "Top Cities/Top Job Titles" : "Top Job Titles/Top Cities";
    const topChartData = showingCities ? data.topJobTitlesPerCityPerCompany : data.topCitiesPerJobTitlePerCompany;
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);

    const [hovered, setHovered] = useState(false);
    const [page, setPage] = useState(0);
    const barColors = useMemo(
        () =>
            topChartData.length
                ? getColors(
                      topChartData[0].stack.series[0]?.name
                          ? topChartData[0].stack.series
                                .map((serie) => serie.name)
                                .filter((serie) => serie.name !== "Total")
                          : [],
                      show,
                      split,
                  )
                : [],
        [topChartData],
    );

    const switchCharts = () => {
        setShowingCities(!showingCities);
    };

    return (
        <Stack height={328} direction="row" spacing={1}>
            <Stack flex={2} direction="row">
                <Paper
                    sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    <Stack direction="row" gap={0} alignItems="center" height={30} mt={1.75} mb={4.75}>
                        <IconButton size="large" onClick={switchCharts}>
                            <SyncAltIcon />
                        </IconButton>
                        <Typography variant="h5" color="textSecondary">
                            {title}
                        </Typography>
                    </Stack>
                    <Stack maxHeight={0}>
                        <SpeedDialMenu
                            open={hovered}
                            closeMenu={() => setHovered(false)}
                            actionsOffset={{ y: -110, x: 40 }}
                            nextPage={() => setPage(page + 1)}
                            previousPage={() => setPage(page - 1)}
                            page={page}
                            lastPage={Math.ceil(topChartData.length / 2)}
                        />
                    </Stack>
                    <Stack direction="row" flex={1} spacing={9.375} px={9.375}>
                        <Stack flex={1} position="relative">
                            <Stack position="absolute" width="100%" height="100%">
                                {!!topChartData[page * 2] && (
                                    <StackedBarWithCategories
                                        barColors={split === SplitModes.none ? [barColors[0]] : barColors}
                                        barHeight="55%"
                                        showActions={Boolean(false)}
                                        itemsPerPage={5}
                                        stacks={stacks}
                                        title={topChartData[page * 2].stack.name}
                                        titleStyles={titleStyles}
                                        titleTypographyStyle={titleTypographyStyle}
                                        chartStyle={chartStyle}
                                        categories={topChartData[page * 2].categories}
                                        series={topChartData[page * 2].stack.series.filter(
                                            (serie) => serie.name !== "Total",
                                        )}
                                        containerStyle={{ marginTop: 0.25, width: 134 }}
                                        categoriesStyle={categoriesStyle}
                                        categoriesFlex="initial"
                                        isDistributed={false}
                                        paddingBottomPerItem={29.87}
                                        xAxisOffsetPerItem={14.8}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        <Stack flex={1} position="relative">
                            <Stack position="absolute" width="100%" height="100%">
                                {!!topChartData[page * 2 + 1] && (
                                    <StackedBarWithCategories
                                        barColors={split === SplitModes.none ? [barColors[0]] : barColors}
                                        barHeight="55%"
                                        showActions={Boolean(false)}
                                        itemsPerPage={5}
                                        stacks={stacks}
                                        title={topChartData[page * 2 + 1].stack.name}
                                        titleStyles={titleStyles}
                                        titleTypographyStyle={titleTypographyStyle}
                                        chartStyle={chartStyle}
                                        categories={topChartData[page * 2 + 1].categories}
                                        series={topChartData[page * 2 + 1].stack.series.filter(
                                            (serie) => serie.name !== "Total",
                                        )}
                                        containerStyle={{ marginTop: 0.25, width: 147 }}
                                        categoriesStyle={categoriesStyle}
                                        categoriesFlex="initial"
                                        isDistributed={false}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Stack>
            <Stack flex={1}>
                <MarkersBarWithCategories
                    categories={data.ageGroupDistribution.categories}
                    series={data.ageGroupDistribution.stack.series}
                    title="Age Groups"
                    inPercentage
                    colors={ageGroupsColors}
                    chartHeight={getMarkersBarHeight(data.ageGroupDistribution.categories.length, true)}
                    stackname="test"
                    showActions={false}
                    chartStyle={{ pr: 12 }}
                    categoriesStyle={{ marginBottom: 9.7, textAlign: "right" }}
                    containerStyle={{ marginTop: 0.1, minWidth: 136 }}
                    legend={ageGroupsLegend}
                    legendStyle={{ mb: 3.25, mt: 1.75 }}
                    categoriesLabel="years"
                />
            </Stack>
        </Stack>
    );
}
