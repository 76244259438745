import React from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AuthSection from "./AuthSection";
import PublicAuthImage from "./assets/PublicAuth.png";

const useStyles = makeStyles(() => ({
    "@global": {
        body: {
            overflow: "hidden",
        },
    },
    root: {
        height: "100vh",
        display: "flex",
    },
    background: {
        backgroundColor: "#4973eb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    backgroundImage: {
        width: "60%",
        verticalAlign: "middle",
        marginTop: "-70px",
    },
}));

export default function Login() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item mt={0} xs={4}>
                <AuthSection />
            </Grid>
            <Grid item xs={8} className={classes.background}>
                <Box textAlign="center" justifyContent="center">
                    <img src={PublicAuthImage} className={classes.backgroundImage} alt="Osterus" />
                </Box>
            </Grid>
        </Grid>
    );
}
