import { createSlice } from "@reduxjs/toolkit";
import { uploadLogo } from "../../actions/organization/logo";
import { saveOrganization, updateOrganization } from "../../actions/organization/organization";
import { mapBackendValidationErrors } from "../../services/utils/form";

const SLICE_NAME = "organization";

const initialState = {
    organization: null,
    tempOrganization: null,
    tempLocation: {
        0: null,
    },
    additionalLocations: [],
    organizationLogo: null,
    isLoading: false,
    formErrors: null,
};

export const organizationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearFormErrors(state) {
            state.formErrors = null;
        },
        saveTempOrganization(state, { payload }) {
            state.tempOrganization = payload;
        },
        saveTempLocation(state, { payload }) {
            const { locationIndex, city } = payload;
            const modifiedDefaultLocation = { ...state.tempLocation, [locationIndex]: city };
            state.tempLocation = modifiedDefaultLocation;
        },
        addAdditionalLocation(state) {
            state.additionalLocations = [...state.additionalLocations, state.additionalLocations.length + 1];
            const tempLength = state.additionalLocations.length;
            const modifiedDefaultLocation = { ...state.tempLocation, [tempLength]: null };
            state.tempLocation = modifiedDefaultLocation;
        },
        removeAdditionalLocation(state, { payload }) {
            const { index } = payload;
            state.additionalLocations.splice(index, 1);
            delete state.tempLocation[index + 1];
            const values = Object.values(state.tempLocation);
            state.tempLocation = {};
            state.additionalLocations.forEach((el, elIndex) => {
                state.additionalLocations[elIndex] = elIndex + 1;
            });
            values.forEach((item, valueIndex) => {
                state.tempLocation[valueIndex] = item;
            });
        },
        removeLogo(state) {
            state.organizationLogo = initialState.organizationLogo;
        },
    },
    extraReducers: {
        [saveOrganization.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.organization = payload;
        },
        [saveOrganization.rejected]: (state, action) => {
            const modifiedErrors = mapBackendValidationErrors(action);
            state.formErrors = modifiedErrors;
        },
        [updateOrganization.fulfilled]: (state, { payload }) => {
            state.organization = payload;
        },
        [uploadLogo.fulfilled]: (state, { payload }) => {
            state.organizationLogo = payload.data?.picture || null;
        },
    },
});

export const {
    clearFormErrors,
    saveTempOrganization,
    saveTempLocation,
    addAdditionalLocation,
    removeAdditionalLocation,
    removeLogo,
} = organizationSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const organizationSliceSelectors = {
    isLoading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.isLoading;
    },
    organization: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organization;
    },
    tempOrganization: (rootState) => {
        const appState = getAppState(rootState);
        return appState.tempOrganization;
    },
    tempLocation: (rootState) => {
        const appState = getAppState(rootState);
        return appState.tempLocation;
    },
    additionalLocations: (rootState) => {
        const appState = getAppState(rootState);
        return appState.additionalLocations;
    },
    organizationLogo: (rootState) => {
        const appState = getAppState(rootState);
        return appState.organizationLogo;
    },
    formErrors: (rootState) => {
        const appState = getAppState(rootState);
        return appState.formErrors;
    },
};
