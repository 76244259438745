import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createUserApi,
    deleteUserApi,
    getUsersApi,
    updateUserApi,
    uploadProfilePhotoApi,
    getUserDetailsApi,
    sendForgotPasswordApi,
    getResetPasswordTokenApi,
    resetPasswordApi,
    autoInviteApi,
    getBillingPortalApi,
    getSubscriptionCheckoutApi,
} from "../../services/api/user/user";
import { updateSettingsGeneral } from "./settings";
import { getMe } from "../../services/api/Public/Login/loginActions";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";

export const uploadProfilePhoto = createAsyncThunk("users/uploadProfilePhoto", async ({ image, id }, { dispatch }) => {
    const bodyFormData = new FormData();
    bodyFormData.append("picture", image);

    const { data } = await uploadProfilePhotoApi(bodyFormData);
    if (data.picture) {
        dispatch(updateSettingsGeneral({ id, userData: { user: { profile_picture: data.picture } } }));
    }
    return { data };
});

export const getUserDetails = createAsyncThunk("user/getUserDetails", async (token, { dispatch }) => {
    const { data } = await getUserDetailsApi(token);

    if (data) {
        localStorage.setItem("token", data.token);
        const { data: userData } = await dispatch(getMe());
        return { ...data, ...userData };
    }

    return { data };
});

export const getUsers = createAsyncThunk("user/getUsers", async (filter = null) => {
    const { data } = await getUsersApi(filter);
    return { data };
});

export const createUser = createAsyncThunk(
    "user/createUser",
    async ({ userData, onDone }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await createUserApi(userData);
            if (onDone) {
                onDone();
                dispatch(
                    addMessage({
                        type: "success",
                        title: "Invitation has been sent!",
                        visibilityTime: 5000,
                    }),
                );
            }
            return { data };
        } catch (err) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            onDone(err);
            return rejectWithValue(error.response.data);
        }
    },
);

export const updateUser = createAsyncThunk("user/updateUser", async ({ id, userData, onDone }) => {
    try {
        const { data } = await updateUserApi(id, userData);
        if (onDone) {
            onDone();
        }
        return { data };
    } catch (error) {
        throw error;
    }
});

export const deleteUser = createAsyncThunk("user/deleteUser", async ({ id, onDone }) => {
    try {
        const { data } = await deleteUserApi(id);
        if (onDone) {
            onDone();
        }
        return { data };
    } catch (error) {
        throw error;
    }
});

export const sendForgotPassword = createAsyncThunk("forgot-password", async (email) => {
    try {
        const { data } = await sendForgotPasswordApi(email);
        return { data };
    } catch (error) {
        throw error;
    }
});

export const getResetPasswordToken = createAsyncThunk("reset-password-token", async (code) => {
    try {
        const { data } = await getResetPasswordTokenApi(code);
        return { data };
    } catch (error) {
        throw error;
    }
});

export const resetPassword = createAsyncThunk("reset-password", async (formData) => {
    try {
        const { data } = await resetPasswordApi(formData);
        return { data };
    } catch (error) {
        throw error;
    }
});

export const autoInvite = createAsyncThunk("auto-invite", async ({ email, code, onDone }) => {
    try {
        await autoInviteApi(email, code);
        if (onDone) {
            onDone(false);
        }
    } catch (error) {
        if (onDone) {
            onDone(error.response?.data?.errors?.email[0] || "Invalid invite code");
        }
        throw error;
    }
});

export const getBillingPortal = createAsyncThunk("get-billing-portal", async ({ onDone }) => {
    try {
        const { data } = await getBillingPortalApi();
        if (onDone) {
            onDone(data.billing_portal_url);
        }
    } catch (error) {
        throw error;
    }
});
export const getSubscriptionCheckout = createAsyncThunk("get-subscription-checkout", async ({ onDone }) => {
    try {
        const { data } = await getSubscriptionCheckoutApi();
        if (onDone) {
            onDone(data.url);
        }
    } catch (error) {
        throw error;
    }
});
