import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUniversitiesApi } from "../../../services/api/Intelligence/Universities";

export const getUniversities = createAsyncThunk("universities/getUniversities", async (params) => {
    try {
        const { data } = await getUniversitiesApi(params);

        return { data };
    } catch (error) {
        return error.response.data;
    }
});
