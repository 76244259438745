import React from "react";
import { Box, Divider } from "@mui/material";

export default function Header({ children }) {
    return (
        <Box>
            <Box mb={1} mx={2}>
                {children}
            </Box>
            <Divider />
        </Box>
    );
}
