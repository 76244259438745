import React from "react";
import { TableCell, TableSortLabel, Typography } from "@mui/material";

export const renderTableViewHeader = (column, columnIndex, sortBy, direction, onSortBy, ref, otherProps) => {
    const { color, fontSize } = column.style || {};

    return (
        <TableCell
            ref={ref}
            {...otherProps}
            align={column.align || "right"}
            sx={columnIndex === 0 ? { paddingLeft: 1 } : null}
        >
            {column.sortKey ? (
                <TableSortLabel
                    active={sortBy === column.sortKey}
                    direction={sortBy === column.sortKey ? direction : "asc"}
                    onClick={() => onSortBy(column.sortKey)}
                >
                    <Typography variant="body3" color={color} fontSize={fontSize}>
                        {column.name}
                    </Typography>
                </TableSortLabel>
            ) : (
                <Typography variant="body3" color={color} fontSize={fontSize}>
                    {column.name}
                </Typography>
            )}
        </TableCell>
    );
};
