import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function DiversitySection({ data }) {
    const diversity = useMemo(() => {
        const companiesLeft = data.genderDiversity.topGenderDiverseCompanies.data[0];
        const companiesRight = data.genderDiversity.topGenderDiverseCompanies.data[1];
        const companiesLongerList = companiesLeft.length > companiesRight.length ? companiesLeft : companiesRight;

        const maleLeft = data.genderDiversity.topFrequentCompaniesPerGenderMale.data[0];
        const maleRight = data.genderDiversity.topFrequentCompaniesPerGenderMale.data[1];
        const maleLongerList = maleLeft.length > maleRight.length ? maleLeft : maleRight;

        const femaleLeft = data.genderDiversity.topFrequentCompaniesPerGenderFemale.data[0];
        const femaleRight = data.genderDiversity.topFrequentCompaniesPerGenderFemale.data[1];
        const femaleLongerList = femaleLeft.length > femaleRight.length ? femaleLeft : femaleRight;

        return {
            companies: {
                values: companiesLongerList.map((_, index) => [
                    shortenText(companiesLeft[index], 25),
                    shortenText(companiesRight[index], 25),
                ]),
            },
            male: {
                values: maleLongerList.map((_, index) => [
                    shortenText(maleLeft[index], 25),
                    shortenText(maleRight[index], 25),
                ]),
            },
            female: {
                values: femaleLongerList.map((_, index) => [
                    shortenText(femaleLeft[index], 25),
                    shortenText(femaleRight[index], 25),
                ]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Gender Diversity">
            <Stack width="100%">
                <MetricGroup
                    name="Most Gender Diverse Companies"
                    values={diversity.companies.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={FamilyRestroomIcon}
                />
            </Stack>
            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Top Male Employers, highest ratio toward male population"
                    values={diversity.male.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={ManIcon}
                />
            </Stack>
            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Top Female Employers, highest ration toward female population"
                    values={diversity.female.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={WomanIcon}
                />
            </Stack>
        </MetricSection>
    );
}
