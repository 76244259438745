import React from "react";
import { Stack, Typography } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import DomainIcon from "@mui/icons-material/Domain";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import LocalBarOutlinedIcon from "@mui/icons-material/LocalBarOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CustomTooltip from "../../CustomTooltip";

export const metricNameToIcon = (metricName, props) => {
    switch (metricName) {
        case "Local Purchasing Power (LPP)":
            return <AddShoppingCartIcon {...props} />;
        case "Consumer Prices":
            return <ShoppingCartOutlinedIcon {...props} />;
        case "Consumer Prices Including Rent":
            return <DomainIcon {...props} />;
        case "Rent Prices":
            return <HomeOutlinedIcon {...props} />;
        case "Groceries Prices":
            return <ShoppingBasketOutlinedIcon {...props} />;
        case "Restaurant Prices":
            return <LocalBarOutlinedIcon {...props} />;
        default:
            return null;
    }
};

export const split3Digits = (number) => {
    let value = number;
    if (typeof value === "number") value = value.toString();

    const [integer, decimal] = value.split(".");
    value = integer;

    const digitGroups = [];
    let i;
    for (i = value.length; i - 3 > 0; i -= 3) {
        digitGroups.push(value.substring(i - 3, i));
    }

    digitGroups.push(value.substring(0, i));

    if (decimal) {
        return `${digitGroups.reverse().join(" ")}.${decimal}`;
    }
    return digitGroups.reverse().join(" ");
};

export const parse3Digits = (value) => value.replaceAll(" ", "");

export const getPercentage = (value1, value2) => `${Math.round(Math.abs(1 - value1 / value2) * 100)}%`;

const combinedMetricsMaxPercentage = (data, localPurchasingPower) => {
    const max = data.combinedMetrics.reduce((prev, cur) => {
        let curPercentage = parseInt(cur.percentage);
        if (cur.status === "higher") curPercentage *= -1;
        curPercentage += localPurchasingPower;

        if (Number.isNaN(parseInt(prev))) {
            let prevPercentage = parseInt(prev.percentage);
            if (prev.status === "higher") prevPercentage *= -1;
            prevPercentage += localPurchasingPower;

            return Math.max(Math.abs(prevPercentage), Math.abs(curPercentage));
        }
        return Math.max(prev, Math.abs(curPercentage));
    });

    return Math.log(max);
};

export const generateMetricItemsProps = (data, adjustLPP, currentCity, comparisonCity, currency, classes, theme) => {
    let lpp = adjustLPP ? parseInt(data.localPurchasingPower.percentage) : 0;
    if (data.localPurchasingPower.status === "lower") lpp *= -1;

    const maxPercentage = combinedMetricsMaxPercentage(data, lpp);

    return {
        combinedMetrics: {
            name: "Combined Metrics",
            metrics: data.combinedMetrics.map((metric) => {
                let percentage = parseInt(metric.percentage);
                if (metric.status === "higher") percentage *= -1;
                percentage += lpp;
                const curCheaper = percentage <= 0;
                percentage = Math.abs(percentage);
                const percentString = `${percentage}%`;

                return {
                    name: metric.name,
                    values: [curCheaper && percentString, !curCheaper && percentString],
                    barPercentages: [
                        curCheaper ? Math.max((Math.log(percentage) / maxPercentage) * 100, 1) : null,
                        !curCheaper ? Math.max((Math.log(percentage) / maxPercentage) * 100, 1) : null,
                    ],
                    indicators: [
                        metricNameToIcon(metric.name, {
                            className: curCheaper ? classes.cheaperMetric : classes.pricierMetric,
                        }),
                        metricNameToIcon(metric.name, {
                            className: !curCheaper ? classes.cheaperMetric : classes.pricierMetric,
                        }),
                    ],
                    colors: [
                        curCheaper ? theme.palette.success.main : null,
                        !curCheaper ? theme.palette.success.main : null,
                    ],
                };
            }),
        },
        individualComparison: {
            name: "Individual Comparison",
            subsections: data.individualMetrics.map((subsection, index) => ({
                name: subsection.name,
                metrics: data.individualMetrics[index].records.filter(Boolean).map((metric) => {
                    const curCheaper = metric.status === "higher";
                    const curPercentage = getPercentage(
                        metric.currentCityPrice.secondary.value,
                        metric.comparisonCityPrice.secondary.value,
                    );
                    const compPercentage = getPercentage(
                        metric.comparisonCityPrice.secondary.value,
                        metric.currentCityPrice.secondary.value,
                    );
                    const curCityPrice = metric.currentCityPrice.currencies[currency];
                    const compCityPrice = metric.comparisonCityPrice.currencies[currency];
                    const maxPrice = metric.maxPrice.currencies[currency];
                    const minPrice = metric.minPrice.currencies[currency];

                    let priceGap;
                    let takeLog;
                    if (maxPrice - minPrice < 1 || curCityPrice - minPrice < 1 || compCityPrice - minPrice < 1) {
                        priceGap = maxPrice - minPrice;
                        takeLog = false;
                    } else {
                        priceGap = Math.log(maxPrice - minPrice);
                        takeLog = true;
                    }

                    return {
                        name: metric.name,
                        values: [
                            `${split3Digits(curCityPrice.toFixed(2))} ${currency}`,
                            `${split3Digits(compCityPrice.toFixed(2))} ${currency}`,
                        ],
                        subvalues: [
                            `(${split3Digits(metric.currentCityPrice.secondary.value.toFixed(2))} ${
                                metric.currentCityPrice.secondary.currency
                            })`,
                            `(${split3Digits(metric.comparisonCityPrice.secondary.value.toFixed(2))} ${
                                metric.currentCityPrice.secondary.currency
                            })`,
                        ],
                        barPercentages: [
                            takeLog
                                ? (Math.log(curCityPrice - minPrice) / priceGap) * 100
                                : ((curCityPrice - minPrice) / priceGap) * 100,
                            takeLog
                                ? (Math.log(compCityPrice - minPrice) / priceGap) * 100
                                : ((compCityPrice - minPrice) / priceGap) * 100,
                        ],
                        colors: [
                            curCheaper ? theme.palette.success.main : theme.palette.alert.main,
                            !curCheaper ? theme.palette.success.main : theme.palette.alert.main,
                        ],
                        indicators: [
                            <CustomTooltip
                                title={metric.name}
                                message={`${metric.name} in ${currentCity.name} is ${curPercentage} ${
                                    curCheaper ? "cheaper" : "more expensive"
                                } than in ${comparisonCity.name}`}
                                element={
                                    <Stack direction="row" alignItems="end" justifyContent="flex-end">
                                        {curCheaper ? (
                                            <ArrowDropDownIcon sx={{ color: theme.palette.success.main }} />
                                        ) : (
                                            <ArrowDropUpIcon sx={{ color: theme.palette.alert.main }} />
                                        )}
                                        <Typography variant="caption" color="textSecondary">
                                            {curPercentage}
                                        </Typography>
                                    </Stack>
                                }
                            />,
                            <CustomTooltip
                                title={metric.name}
                                message={`${metric.name} in ${comparisonCity.name} is ${compPercentage} ${
                                    !curCheaper ? "cheaper" : "more expensive"
                                } than in ${currentCity.name}`}
                                element={
                                    <Stack direction="row" alignItems="end" justifyContent="flex-start">
                                        {!curCheaper ? (
                                            <ArrowDropDownIcon sx={{ color: theme.palette.success.main }} />
                                        ) : (
                                            <ArrowDropUpIcon sx={{ color: theme.palette.alert.main }} />
                                        )}
                                        <Typography variant="caption" color="textSecondary">
                                            {compPercentage}
                                        </Typography>
                                    </Stack>
                                }
                            />,
                        ],
                    };
                }),
            })),
        },
    };
};

export const shortenText = (text, charLimit) => {
    if (!text) return text;

    const shortText = [];
    const words = text.split(" ");
    let length = 0;
    for (const word of words) {
        if (length + word.length <= charLimit) {
            shortText.push(word);
            length += word.length;
        } else break;
    }

    return shortText.join(" ");
};
