import React, { useRef, useEffect } from "react";
import lottie from "lottie-web";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import hiringLottie from "./assets/hiring.json";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "85vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
    },
    hiring: {
        justifyContent: "center",
        textAlign: "center",
    },
    lottieImage: {
        margin: "auto",
        width: theme.spacing(50),
    },
}));

export default function Hiring() {
    const classes = useStyles();
    const image = useRef(null);

    useEffect(() => {
        lottie.loadAnimation({
            container: image.current,
            render: "svg",
            loop: true,
            autoplay: true,
            animationData: hiringLottie,
        });
    }, []);

    return (
        <Box className={classes.root}>
            <Box className={classes.hiring}>
                <Box className={classes.lottieImage} ref={image} />
                <Typography variant="h4">USE THE HIRING MENU</Typography>
                <Typography>
                    to upload, evaluate, analyze and ultimately choose the best candidate for you - all bias-free.
                </Typography>
                <Typography>• coming soon •</Typography>
            </Box>
        </Box>
    );
}
