import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { MetricSection } from "../../../../components/MetricSection";
import { MetricGroup } from "../../../../components/MetricGroup";
import { shortenText } from "../../../utils";

export default function JobTitlesSection({ data }) {
    const jobTitles = useMemo(() => {
        const genderLeft = data.jobTitlesAndGenderDiversity.topGenderDiverseJobTitles.data[0];
        const genderRight = data.jobTitlesAndGenderDiversity.topGenderDiverseJobTitles.data[1];
        const genderLongerList = genderLeft.length > genderRight.length ? genderLeft : genderRight;

        const maleLeft = data.jobTitlesAndGenderDiversity.topFrequentJobTitlesPerGenderMale.data[0];
        const maleRight = data.jobTitlesAndGenderDiversity.topFrequentJobTitlesPerGenderMale.data[1];
        const maleLongerList = maleLeft.length > maleRight.length ? maleLeft : maleRight;

        const femaleLeft = data.jobTitlesAndGenderDiversity.topFrequentJobTitlesPerGenderFemale.data[0];
        const femaleRight = data.jobTitlesAndGenderDiversity.topFrequentJobTitlesPerGenderFemale.data[1];
        const femaleLongerList = femaleLeft.length > femaleRight.length ? femaleLeft : femaleRight;

        return {
            gender: {
                values: genderLongerList.map((_, index) => [
                    shortenText(genderLeft[index], 25),
                    shortenText(genderRight[index], 25),
                ]),
            },
            male: {
                values: maleLongerList.map((_, index) => [
                    shortenText(maleLeft[index], 25),
                    shortenText(maleRight[index], 25),
                ]),
            },
            female: {
                values: femaleLongerList.map((_, index) => [
                    shortenText(femaleLeft[index], 25),
                    shortenText(femaleRight[index], 25),
                ]),
            },
        };
    }, [data]);

    return (
        <MetricSection name="Job Titles And Gender Diversity">
            <Stack width="100%">
                <MetricGroup
                    name="Most Gender Diverse Job Titles"
                    values={jobTitles.gender.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={AssignmentIcon}
                />
            </Stack>
            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Most Frequest Male Job Titles"
                    values={jobTitles.male.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={ManIcon}
                />
            </Stack>
            <Stack width="100%" mt={4}>
                <MetricGroup
                    name="Most Frequest Female Job Titles"
                    values={jobTitles.female.values}
                    textStyles={{ fontSize: 13, top: 3 }}
                    colors={["#27cd90", "#27cd90"]}
                    Icon={WomanIcon}
                />
            </Stack>
        </MetricSection>
    );
}
