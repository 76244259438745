import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, IconButton, Typography } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import StackedBarWithCategories from "../../../Charts/StackedBarWithCategories";
import MarkersBarWithCategories from "../../../Charts/MarkersBarWithCategories";
import TableChart from "../../../Charts/TableChart";
import { SpeedDialMenu } from "../../../Charts/ChartContainer/SpeedDialMenu";
import {
    stackInsightsSliceSelector,
    SplitModes,
} from "../../../../../../../../slices/Stacks/StackInsights/stackInsightsSlice";
import { getColors, getMarkersBarHeight } from "../../../utils";

const useStyles = makeStyles(() => ({
    root: {
        margin: "auto",
        width: "100%",
    },
    paper: {
        width: "100%",
        height: 581,
        zIndex: 1,
    },
    degreesPaper: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
}));

const topUniversitiesTitleStyle = {
    background: "transparent",
};

const topUniversitiesContainerStyle = {
    marginTop: 0.25,
    width: 200,
    ml: 2.75,
};

const topUniversitiesCategoriesStyle = {
    marginBottom: 14,
    textAlign: "right",
};

const topUniversitiesTopJobTitlesTitleStyle = {
    position: "relative",
    background: "#F5F5F5",
    width: "95%",
    marginLeft: 46,
    height: 45,
    top: 15,
};

const topUniversitiesTopJobTitlesSecondTitleStyle = {
    position: "relative",
    background: "#F5F5F5",
    width: "95%",
    marginLeft: 46,
    height: 45,
    top: 15,
};

const topUniversitiesTopJobTitlesTypographyTitleStyle = {
    margin: 0,
    padding: 0,
    width: "100%",
    fontSize: 14,
    textAlign: "center",
};

const topUniversitiesTopJobTitlesContainerStyle = {
    marginTop: 0.3,
    maxWidth: 117,
};

const topUniversitiesTopJobTitlesCategoriesStyle = {
    position: "relative",
    marginBottom: 12.4,
    textAlign: "right",
};

export default function Section1({ data, stacks }) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [showJobTitles, setShowJobTitles] = useState(false);
    const [chartData, setChartData] = useState(data.universities);
    const title = showJobTitles ? "Top Job Titles/Top Universities" : "Top Universities/Top Job Titles";
    const split = useSelector(stackInsightsSliceSelector.split);
    const show = useSelector(stackInsightsSliceSelector.show);

    const barColors1 = useMemo(
        () =>
            data.topUniversitiesAttended
                ? getColors(
                      data.topUniversitiesAttended.stack.series[0]?.name
                          ? data.topUniversitiesAttended.stack.series.map((serie) => serie.name)
                          : [],
                      show,
                      split,
                  )
                : [],
        [data],
    );
    const barColors2 = useMemo(
        () =>
            chartData.length
                ? getColors(
                      chartData[0].stack.series[0]?.name ? chartData[0].stack.series.map((serie) => serie.name) : [],
                      show,
                      split,
                  )
                : [],
        [chartData],
    );

    const switchJobTitles = () => {
        setShowJobTitles(!showJobTitles);
    };

    useEffect(() => {
        if (data) {
            setChartData([...data.universities]);
        }
    }, [data, stacks]);

    useEffect(() => {
        if (data.universities && data.jobTitles) {
            setChartData(showJobTitles ? [...data.jobTitles] : [...data.universities]);
        }
    }, [showJobTitles, data, stacks]);

    useEffect(() => {
        setPage(0);
    }, [stacks, split, show, showJobTitles]);

    const maxValue = useMemo(() => {
        const maxValues = chartData?.map((item) => {
            let max = 0;
            for (let i = 0; i < item.categories.length; i += 1) {
                const rowSum = item.stack.series.reduce((prev, cur) => prev + cur.data[i], 0);
                max = Math.max(max, rowSum);
            }
            return max;
        });
        return [Math.max(...maxValues)];
    }, [data, chartData, stacks]);

    const educationDegrees = {
        categories: data.degreeDistribution.categories,
        series: [
            {
                data: data.degreeDistribution.stack.series.data.map((value, index) => ({
                    x: data.degreeDistribution.categories[index],
                    y: value,
                    goals: [
                        {
                            name: "Benchmark",
                            value: data.degreeDistribution.benchmark[index].percent,
                            strokeWidth: 2,
                            strokeDashArray: 2,
                            strokeColor: "#f00",
                        },
                    ],
                })),
            },
        ],
    };

    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={4} container justifyContent="center">
                <Paper className={classes.paper} sx={{ pb: 1, pr: 4 }}>
                    <StackedBarWithCategories
                        barColors={split === SplitModes.none ? [barColors1[0]] : barColors1}
                        isDistributed={false}
                        title="Top Universities"
                        titleStyles={topUniversitiesTitleStyle}
                        titleTypographyStyle={{ paddingLeft: 10, paddingTop: 22 }}
                        stacks={stacks}
                        barHeight="53%"
                        categories={data.topUniversitiesAttended.categories}
                        series={data.topUniversitiesAttended.stack.series.filter((serie) => serie.name !== "Total")}
                        containerStyle={topUniversitiesContainerStyle}
                        categoriesStyle={topUniversitiesCategoriesStyle}
                        categoriesFlex="initial"
                        paddingBottomPerItem={31.23}
                        xAxisOffsetPerItem={15.6}
                        actionsOffset={{ y: -45 }}
                    />
                </Paper>
            </Grid>
            <Grid item xs={4} container justifyContent="center">
                <Paper className={classes.paper} onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                    <Stack direction="row" gap={0} alignItems="center" height={30} mt={2}>
                        <IconButton onClick={switchJobTitles} size="large">
                            <SyncAltIcon />
                        </IconButton>
                        <Typography variant="h5" color="textSecondary">
                            {title}
                        </Typography>
                    </Stack>
                    <SpeedDialMenu
                        nextPage={() => setPage(page + 1)}
                        previousPage={() => setPage(page - 1)}
                        page={page}
                        lastPage={Math.ceil(chartData.length / 2)}
                        open={open}
                        actionsOffset={{ y: -75, x: 45 }}
                    />
                    <Stack pr={10} height="88%" width="100%" mt={4.25}>
                        <Stack flex={1} position="relative">
                            <Stack position="absolute" width="100%" top={-20} height="100%">
                                {!!chartData[page * 2] && (
                                    <StackedBarWithCategories
                                        barColors={split === SplitModes.none ? [barColors2[0]] : barColors2}
                                        isDistributed={false}
                                        barHeight="56%"
                                        showActions={Boolean(false)}
                                        itemsPerPage={5}
                                        stacks={stacks}
                                        title={chartData[page * 2].stack.name}
                                        titleStyles={topUniversitiesTopJobTitlesTitleStyle}
                                        titleTypographyStyle={topUniversitiesTopJobTitlesTypographyTitleStyle}
                                        categories={chartData[page * 2].categories}
                                        series={chartData[page * 2].stack.series.filter(
                                            (serie) => serie.name !== "Total",
                                        )}
                                        containerStyle={topUniversitiesTopJobTitlesContainerStyle}
                                        categoriesStyle={topUniversitiesTopJobTitlesCategoriesStyle}
                                        chartStyle={{ mt: 4.5 }}
                                        paddingBottomPerItem={29.5}
                                        xAxisOffsetPerItem={14.7}
                                        max={maxValue}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        <Stack flex={1} position="relative">
                            <Stack position="absolute" width="100%" top={-20} height="100%">
                                {!!chartData[page * 2 + 1] && (
                                    <StackedBarWithCategories
                                        barColors={split === SplitModes.none ? [barColors2[0]] : barColors2}
                                        isDistributed={false}
                                        barHeight="56%"
                                        showActions={Boolean(false)}
                                        itemsPerPage={5}
                                        stacks={stacks}
                                        title={chartData[page * 2 + 1].stack.name}
                                        titleStyles={topUniversitiesTopJobTitlesSecondTitleStyle}
                                        titleTypographyStyle={topUniversitiesTopJobTitlesTypographyTitleStyle}
                                        categories={chartData[page * 2 + 1].categories}
                                        series={chartData[page * 2 + 1].stack.series.filter(
                                            (serie) => serie.name !== "Total",
                                        )}
                                        containerStyle={topUniversitiesTopJobTitlesContainerStyle}
                                        categoriesStyle={topUniversitiesTopJobTitlesCategoriesStyle}
                                        chartStyle={{ mt: 4.5 }}
                                        paddingBottomPerItem={29.5}
                                        xAxisOffsetPerItem={14.7}
                                        max={maxValue}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Stack direction="column" height="100%" width="100%">
                    <Stack width="100%" height={193} mb={1} justifyContent="center">
                        <Stack flex={1}>
                            <MarkersBarWithCategories
                                title="Education Degrees"
                                stackname={stacks[0]?.stack?.name}
                                categories={educationDegrees.categories}
                                colors={["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"]}
                                series={educationDegrees.series}
                                chartStyle={{ pr: 12 }}
                                categoriesStyle={{ marginBottom: 9.7, textAlign: "right" }}
                                containerStyle={{ marginTop: 0.1, minWidth: 150 }}
                                chartHeight={getMarkersBarHeight(educationDegrees.categories.length, true)}
                                showActions={false}
                                inPercentage
                            />
                        </Stack>
                    </Stack>
                    <Stack flex={1}>
                        <Paper className={classes.degreesPaper}>
                            <TableChart
                                title="Work Experience per Degree"
                                barHeight="47%"
                                customColors={["#B0C7E9", "#7988E3", "#685FE1", "#6438E3"]}
                                tableData={data?.degreeDistributionPerGender?.tableChart}
                                categories={stacks.map((item) => item.stack.name)}
                                stacks={stacks}
                                tooltips={[
                                    "",
                                    "Education Degrees in Male Population",
                                    "Education Degrees in Female Population",
                                    "Education Degrees in Total",
                                ]}
                                tooltip="%"
                                chartHeight={40}
                                containerStyles={{ paddingTop: 28 }}
                            />
                        </Paper>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}
